import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

function MenuItemContainer(props) {
    const {
        children,
        backgroundColor = '',
        width = '',
        padding = '',
        height = '',
        display = '',
        marginBottom = '',
        classMarginBottom = '',
        justifyContent = '',
        alignItems = '',
        margin = '',
        classView = '',
    } = props
    const classes = useStyles()
    const classProps = props.className || ''
    return (
        <div
            style={{backgroundColor, width, padding, height, display, marginBottom, justifyContent, alignItems, margin}}
            className={`${classes.root} ${classMarginBottom} ${classProps} ${classView}`}>
            {children}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        borderRadius: 20,
        // padding: "2px 0px",
        margin: "0 5px",
        height: '40px',
        display: 'flex',
        "& .MuiTabs-flexContainer": {
            justifyContent: 'space-between !important',
        }
    }
}))
export default MenuItemContainer;
