import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid, TextField, Typography} from '@material-ui/core'
import SelectProjectColor from "./SelectColor";
import {db} from '../../../config/firebaseConfig'
import {CALENDAR_COLLECTION, GOOGLE_EMAIL_WATCH} from "../../../config/constants";
import {ggTokenChecked} from "../../../config/auth";
import {cloneDeep, findIndex} from 'lodash'
import TextInputCustom from "../../Custom/TextInputCustom";
import TextInputURLCustom from "../../Custom/TextInputURLCustom";
import {Spin} from 'antd';
import axios from "axios";
import CancelButton from "../../Dialog/CancelButton";
import SaveButton from "../../Dialog/SaveButton";
import {msgError, msgSuccess} from '../../../utils/msg';
import CreateIcon from '@material-ui/icons/Create';
import isURL from "validator/es/lib/isURL";
import {refetchAll} from "../../../actions/refetchSourcce"
import {proxy, useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {projectsProxy} from '../../../store/proxy/projects.proxy';
import {getIntlId} from '../../../hooks/useIntlId';
import {eventsProxy} from '../../../store/proxy/events.proxy';

function EditProject({project, setProject, setIsEdit, otherURLOld}) {

    const classes = useStyles()
    const [edited, setEdited] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const {user, calendarUser, googleAuth} = useSnapshot(userProxy)
    const {geniamEvents} = useSnapshot(eventsProxy)
    const {googleProjects, setGoogleProjects, geniamProjects, setGeniamProjects, setProjectSelected} = useSnapshot(projectsProxy)
    const [otherURL, setOtherURL] = useState([])
    let checkErrorUrl = 0
    const linkItems = [{
        name: "Slack",
        logo: "/icons/slackIcon.svg",
        id: "slack"
    }, {
        name: "Trello",
        logo: "/icons/trelloIcon.svg",
        id: "trello"
    }
    ];

    useEffect(() => {
        setEdited({...project})
        if (project?.otherURL?.length) {
            setOtherURL([...project.otherURL])
        } else {
            setOtherURL(linkItems)
        }
    }, [])

    const onCancel = () => {
        setIsEdit(false)
        setOtherURL(otherURLOld)
        setProject({...project, otherURL: otherURLOld})
    }

    const delUrl = async (item) => {
        let sliceItem
        sliceItem = otherURL.filter((e) => {
            return e.id !== item.id
        })
        setOtherURL(sliceItem)
    }

    const updateColorEvents = async () => {
        const _geniamEvents = cloneDeep(geniamEvents)
        const events = _geniamEvents[project.id]
        if (events.length) {
            let dbBatch = db.batch()
            events.forEach(item => {
                let itemRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${item.id}`)
                dbBatch.set(itemRef, {
                    ...item,
                    borderColor: edited?.color || '',
                }, {merge: true})
            })
            await dbBatch.commit()
        }
    }

    const onUpdate = async () => {
        let _otherURL = cloneDeep(otherURL)
        // validate project name , code
        if (!edited.name) {
            return msgError("name cannot empty")
        }
        try {
            setLoading(true)
            // valid icon
            await Promise.all(_otherURL.map(async item => {
                let logo = '/icons/linkURL.svg'
                if (item.url) {
                    try {
                        const URL = new window.URL(item.url)
                        const origin = URL.origin
                        await axios.get(`${origin}/favicon.ico`)
                        logo = `${origin}/favicon.ico`
                    } catch (e) {
                    }
                    // item.logo = logo
                }
            }))
            if (!edited.is_google) {
                // update geniam project

                const refProject = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/projects/${project.id}`)
                await refProject.set({
                        otherURL: _otherURL || [],
                        color: edited.color || '',
                        name: edited.name || ''
                    },
                    {merge: true})
                edited.otherURL = _otherURL
                let _geniamProjects = cloneDeep(geniamProjects)
                let idx = findIndex(_geniamProjects, {id: edited.id})
                if (idx !== -1) {
                    _geniamProjects[idx].color = edited.color
                    _geniamProjects[idx].name = edited.name
                    _geniamProjects[idx].otherURL = edited.otherURL
                    setProject(_geniamProjects[idx])
                    await setGeniamProjects([..._geniamProjects])
                    updateColorEvents()
                }
                await refetchAll()
                await msgSuccess(getIntlId('common.saved'))
                return
            }

            // google Project update
            if (edited.name !== project.name) {
                // update data to google calendar
                const data = await ggTokenChecked()
                if (!data) {
                    return msgError("google auth error. try again late")
                }
                const request = window.gapi.client.calendar.calendars.patch({
                    calendarId: project.id,
                    summary: edited.name,
                })
                const result = await request.then()
                if (result.status !== 200) {
                    return msgError("update color google error")
                }
            }
            if (edited.color !== project.color) {
                // update data to google calendar
                const data = await ggTokenChecked()
                if (!data) {
                    return msgError("google auth error. try again late")
                }
                const request = window.gapi.client.calendar.calendarList.update({
                    calendarId: project.id,
                    "colorRgbFormat": true,
                    // summary: edited.name,
                    backgroundColor: edited.color,
                    foregroundColor: "#000000",
                    selected: Boolean(edited.selected)
                })
                const result = await request.then()
                if (result.status !== 200) {
                    return msgError("update color google error")
                }
            }

            // other info stored on firebase
            const googleSettings = cloneDeep(calendarUser?.googleSettings)
            googleSettings[edited.id] = {
                otherURL: _otherURL || []
            }
            edited.otherURL = _otherURL
            setProjectSelected({...edited, otherURL: _otherURL});
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            await userRef.set({googleSettings}, {merge: true})
            // update local calendar List
            let _googleProjects = cloneDeep(googleProjects)
            let idx = findIndex(_googleProjects, {id: edited.id})
            if (idx !== -1) {
                _googleProjects[idx].backgroundColor = edited.color
                _googleProjects[idx].summary = edited.name
                await setGoogleProjects([..._googleProjects])
            }
            await db.collection(GOOGLE_EMAIL_WATCH)
                .doc(googleAuth?.email)
                .update({created_at: new Date().toISOString()})
            await refetchAll()
            await msgSuccess(getIntlId('common.saved'))
        } catch (e) {
            console.log(e);
            msgError("Update error. try again late")
        } finally {
            setLoading(false)
            setIsEdit(false)
        }

    }


    return (
        <div className={classes.root}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <TextInputCustom
                    name={'Name'}
                    contentName={project.name}
                    isEditComponent={true}
                    editComponent={
                        <div className={`clf-flex-center ${classes.textEdit}`}>
                            <TextField
                                autoFocus
                                value={edited.name || ''}
                                onChange={e => {
                                    setEdited({...edited, name: e.target.value})
                                }}
                                disabled={edited?.accessRole === "reader"}
                                fullWidth
                            />
                            {
                                edited?.accessRole === "reader" ?
                                    <Typography color={"secondary"}>reader access role</Typography>
                                    :
                                    null
                            }
                        </div>
                    }/>

                <div className={classes.colorEdit}>
                    <div className={classes.colorCircle}
                         style={{backgroundColor: edited.color}}
                         onClick={event => setAnchorEl(event.currentTarget)}
                    >
                        Color
                        <CreateIcon className={classes.iconColor} style={{color: edited.color}}/>
                    </div>
                    <SelectProjectColor anchorEl={anchorEl} setAnchorEl={setAnchorEl} editInfo={edited}
                                        setEditInfo={setEdited}/>
                </div>
            </div>
            <div className={classes.listItems}>
                {
                    otherURL.map((item, index) => {
                        if (item?.url && !isURL(item.url, {protocols: ['http', 'https'], require_protocol: true})) {
                            checkErrorUrl++
                        }
                        return (
                            <Grid container key={index} className={classes.divGrid}>
                                <TextInputURLCustom
                                    contentName={`URL: ${project[item.id] ? project[item.id] : ''}`}
                                    urlImg={item.logo}
                                    isURL={true}
                                    item={item}
                                    project={project}
                                    setProject={setProject}
                                    otherURL={otherURL}
                                    setOtherURL={setOtherURL}
                                    isEditComponent={true}
                                    delUrl={delUrl}
                                    iconDel={true}
                                    editComponent={
                                        <div className={`clf-flex-center ${classes.textEdit}`}>

                                            <TextField
                                                error={item.url ? !isURL(`${item.url}`, {
                                                    protocols: ['http', 'https'],
                                                    require_protocol: true
                                                }) : ''}
                                                helperText={item.url ? (!isURL(`${item.url}`, {
                                                    protocols: ['http', 'https'],
                                                    require_protocol: true
                                                }) ? 'Invalid Url' : '') : ''}
                                                value={item.url || ''}
                                                className={classes.textField}
                                                onChange={(e) => {
                                                    let _otherURL = cloneDeep(otherURL)
                                                    _otherURL[index].url = e.target.value || ''
                                                    setOtherURL([..._otherURL])
                                                }}
                                                fullWidth
                                            />
                                        </div>
                                    }
                                    titleMaxWidth={110}
                                />
                            </Grid>
                        )
                    })
                }

            </div>
            <div className={classes.action}>
                <CancelButton
                    onClick={onCancel}
                />
                <Spin spinning={loading}>
                    <SaveButton
                        onClick={onUpdate}
                        disable={loading || checkErrorUrl}
                    />
                </Spin>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        marginTop: 40,
        padding: '30px 10px',
        borderRadius: 20,
        position: 'relative'
    },
    content: {
        textAlign: "center",
        fontSize: 14
    },
    divGrid: {
        marginBottom: 15
    },
    listItems: {
        marginTop: 20,
    },
    title: {
        fontWeight: "bold"
    },
    action: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center'
    },
    itemHeader: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    iconColor: {
        width: 28,
        height: 28,
        background: '#fff',
        borderRadius: '50%',
        padding: 4,
        position: 'absolute',
        right: 3
        // '& .MuiSvgIcon-root':{
        //     fill:
        // }
    },
    textField: {
        marginLeft: 10
    },
    colorCircle: {
        position: 'relative',
        cursor: 'pointer',
        textTransform: 'uppercase',
        color: '#fff',
        fontWeight: 700,
        display: "flex",
        paddingLeft: 10,
        alignItems: 'center',
        width: '100%',

        // minWidth: 25,
        height: 40,
        borderRadius: '24px',
        padding: 0,
        border: '1px solid #D5D5D5',
        margin: "auto"
    },
    colorEdit: {
        paddingRight: 5,
        alignItems: 'flex-end',
        width: '100%',
        maxWidth: 120
    },
    projectColor: {
        borderRadius: 24,
        maxWidth: 150,
        minHeight: 33,
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#FFF',
        border: 'none',
        width: "calc(100% - 10px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        position: 'absolute',
        top: -15,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    saveIconSecondary: {
        fill: theme.props.secondaryColor,
        marginLeft: -24,
        marginRight: 10,
        marginTop: 1
    },
    textEdit: {
        width: '95%'
    },
    codeWidth: {
        width: '80%'
    },
    saveIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -41,
        marginRight: 10,
        marginTop: 1
    },
    iconAddURL: {
        fill: theme.props.primaryColor,
        transform: 'scale(1.5)',
    },
    divIconAddURL: {
        marginLeft: '16%',
        textAlign: 'left'
    },
    iconBtnAddURL: {
        padding: 0
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            marginTop: 1
        }
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            marginTop: 1
        }
    }
}))

export default EditProject;
