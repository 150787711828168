import copy from 'clipboard-copy';
import {
    isSameDate,
    momentdd,
    momentHmm,
    momentMD,
    subtractDate,
    zoneObj
} from '../../../utils/time';
import {conferenceDataJoinUrl} from '../handlers/infoEvent';

export const copyTimeFmt = ({event = {}, start, end, hover = false}) => {
    start = (event.start) || (start)
    end = event?.allDay ? (subtractDate(event.end, 1, "day")) : (event.end) || (end)

    if (hover) {
        if (event?.allDay) {
            if (isSameDate(zoneObj(start), zoneObj(end)))
                return `
${momentMD(start)} (${momentdd(start)})`
            return `
${momentMD(start)} (${momentdd(start)})
~
${momentMD(end)} (${momentdd(end)})`
        }
        if (isSameDate(zoneObj(event.start), zoneObj(event.end)))
            return `
${momentMD(start)} (${momentdd(start)})
${momentHmm(start)} ~ ${momentHmm(end)}`
        return `
${momentMD(start)} (${momentdd(start)}) ${momentHmm(start)}
~
${momentMD(end)} (${momentdd(end)}) ${momentHmm(end)}`
    }
    if (event?.allDay) {
        if (isSameDate(zoneObj(start), zoneObj(end)))
            return `
${momentMD(start)} (${momentdd(start)})`
        return `
${momentMD(start)} (${momentdd(start)}) ~ ${momentMD(end)} (${momentdd(end)})`
    }
    if (momentMD(start) === momentMD(end) && momentdd(start) === momentdd(end))
        return `
${momentMD(start)} (${momentdd(start)}) ${momentHmm(start)} ~ ${momentHmm(end)}`
    return `
${momentMD(start)} (${momentdd(start)}) ${momentHmm(start)} ~ ${momentMD(end)} (${momentdd(end)}) ${momentHmm(end)}`
}

export const copyEvent = async (event) => {
    if (!event) {
        // msgError('Event not found!')
        return;
    }
    return await copy(`
${event.title}${copyTimeFmt({event})}
${conferenceDataJoinUrl(event) || ''}
`)
}
