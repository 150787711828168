import React, {memo, useDeferredValue, useRef} from 'react';
import {Paper} from "@material-ui/core"
import FocusChartApp from "./FocusChartApp";
import {makeStyles} from "@material-ui/core/styles";
import SliderBoard from '../SliderBoard/SliderBoard';
import LoadCalendar from './LoadCalendar';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {chartProxy} from '../../store/proxy/chart.proxy';
import {isEmpty} from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        height: "100%",
    },
    paper: {
        height: "calc(100% - 265px)",
        display: 'flex'
    },
    slideBoard: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
    },
}))


function Focus(props) {
    const {
        numberId,
        deferredChartData,
    } = props
    const {currentTheme} = useSnapshot(themesProxy)
    const classes = useStyles()
    const ref = useRef()
    const heightFocus = document.getElementById(`calendarDiv${numberId}`)?.offsetHeight;
    if (isEmpty(deferredChartData)) return null
    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}
                   ref={ref}
                // style={{maxHeight: currentTheme?.rows === 1 ? `${board?.display ? heightChartApp : (heightChartApp + 268)}px` : "unset"}}
            >
                <FocusChartApp
                    calendarheight={heightFocus}
                    idFocus={numberId}
                    data={deferredChartData}
                />
            </Paper>

            {
                LoadCalendar(currentTheme).length === 1 &&
                <div className={classes.slideBoard}>
                    <SliderBoard/>
                </div>
            }

        </div>

    )
}

export default memo(Focus)
