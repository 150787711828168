const ctrlKeyDescriptor = Object.getOwnPropertyDescriptor(
    MouseEvent.prototype,
    'ctrlKey'
);

// Always return false for event.ctrlKey when event is of type MouseEvent
ctrlKeyDescriptor.get = function () {
    return false;
};

Object.defineProperty(MouseEvent.prototype, 'ctrlKey', ctrlKeyDescriptor);
// End of the workaround
