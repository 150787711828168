import React from 'react'
import {Grid} from "@material-ui/core";
import {orderBy} from "lodash";
import {makeStyles} from "@material-ui/core/styles";
import {getIcon} from "../Calendar/handlers/getIcon";
import {compareData} from "../Custom/LineChart";


function RateOfTimeTask(props) {
    const classes = useStyles()

    // get props
    const {
        data,
    } = props

    // create variable
    let count = loadCount(data)
    let sortingData = orderBy(data, 'sum', 'desc')

    // create function
    function loadCount(data) {
        let count = 0
        data.forEach((e) => {
            count += e.hour
        })
        return count
    }

    // result
    return (
        <div className={classes.root}>

            {
                sortingData.map((data, index) => {
                    // console.log(data);
                    let setWidth = (data.hour / count) * 100
                    // let labelHour = data.hour.toFixed(1) || 0
                    const rowPercent = setWidth.toFixed(1)
                    // const day = Math.ceil(data.hour) / 24
                    return (
                        <Grid key={index} item style={{width: setWidth + '%'}} className={classes.item}>
                            <div className={classes.percent}>
                                {`${rowPercent}%`}
                            </div>
                            <div className={`${classes.rateOfTask} rateOfTask`}>
                                {
                                    data?.type === "file" ?
                                        <div className={"taskImgContainer"}>
                                            <img alt={''} src={data.url} className={"taskImgEvt"}/>
                                        </div>
                                        :
                                        <span className={"material-icons material-icons-fix  md-18"}>
                                {
                                    getIcon(data.id, "rgb(68, 68, 68)")
                                }
                                </span>
                                }

                            </div>
                            {/*<div className={classes.timeRow}>*/}
                            {/*    {isMilestone ?*/}
                            {/*        `${day}d` :*/}
                            {/*        `${simpleDuration(labelHour)}h`*/}
                            {/*    }*/}
                            {/*</div>*/}

                        </Grid>)
                })
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '25px 6px 6px',
        margin: "0px 0px 6px 0px",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#fff",
        borderRadius: "25px",
        fontSize: 12,
    },
    titleH: {
        position: 'absolute',
        left: 5,
        top: 1,
        color: 'black',
        fontSize: '14px',
    },
    titleH1: {
        display: "block",
        textAlign: "left",
        color: 'black',
        fontSize: '14px',
    },
    title: {
        color: '#BEBEBE',
        position: 'inherit',
        top: 2,
    },
    labelText: {
        position: 'absolute',
        bottom: 3,
        fontSize: 12,
        color: '#3A3A3A',
        fontWeight: 'bold',
        textShadow: '0.5px -0.8px 0 #3A3A3A, 1px 0.2px 0 #3A3A3A'
    },
    labelText2: {
        position: 'static',
        fontSize: 12,
        color: '#3A3A3A',
        lineHeight: 1,
        textShadow: '0.5px -0.8px 0 #3A3A3A, 1px 0.2px 0 #3A3A3A'
    },
    process: {
        backgroundColor: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        height: 10,
    },
    labelHour: {
        fontSize: '0.95rem',
        color: "#000",
    },
    item: {
        height: 23,
        position: "relative",
        borderRadius: "25px",
        display: "flex",
        flex: "auto",
        minWidth: 40,
        justifyContent: "center",
        alignItems: "center",
        "&:not(:last-child)": {
            borderRight: "solid 1px #000",
        }
    },
    percent: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "100%",
        marginTop: 1,
        fontWeight: "bold"
    },
    timeRow: {
        position: "absolute",
        left: 0,
        right: 0,
        top: "100%",
        marginTop: 1,
        fontWeight: "bold"
    },
    rateOfTask: {
        height: "21px",
        "& svg": {
            borderRadius: "50%",
            width: 21,
            height: 21,
            background: "#f1f1f1",
        }
    }
}));

const areEqual = (prevProps, nextProps) => {
    return prevProps.isMilestone === nextProps.isMilestone &&
        compareData(prevProps.data, nextProps.data)
}

export default React.memo(RateOfTimeTask, areEqual)
