import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, getCookie} from "./cookies";
import {tokenProxy} from '../store/proxy/token.proxy';
import {snapshot} from 'valtio'

export default () => {
    const {accessToken} = snapshot(tokenProxy)
    // const geniam_accessToken = localStorage.getItem('accessToken')
    const geniam_refreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
    return {geniam_accessToken: accessToken, geniam_refreshToken, token: accessToken, refreshToken: geniam_refreshToken}
}
