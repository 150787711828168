import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {CardElement, injectStripe} from 'react-stripe-elements';
import Typography from '@material-ui/core/Typography';
import {stripeAddCard} from '../../common/stripeAddCard';
import {msgError} from '../../utils/msg';
import {Button} from 'antd'
import useIntlId from '../../hooks/useIntlId';

function StripeAddCardDialog({open, setOpen, stripe, onUpdate}) {
    const classes = useStyles()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [cardInfoIntl, cancelIntl, addIntl] = useIntlId(['annoucement.card.information', 'settings.archiveDialogCancel', 'app.setting.add'])
    const handleSubmit = async () => {
        try {
            setLoading(true)
            const result = await stripe.createToken()
            if (result.error) {
                setError(result.error.message)
                return;
            }
            setError('')
            await stripeAddCard(result.token.id)
            onUpdate()
            setOpen(false)

        } catch (e) {
            msgError(e.toString())
            setOpen(false)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className={`${classes.title} fontSourceHanSans`} id="stripe-input-dialog-title">{cardInfoIntl}</div>
            <DialogContent>
                <CardElement className={classes.element}/>
                <div className={classes.cards}>
                    <div className={classes.img}>
                        <img src="/image/listCard/American Express.png" alt=''/>
                    </div>
                    <div className={classes.img}>
                        <img src="/image/listCard/Diners Club.png" alt=''/>
                    </div>
                    <div className={classes.img}>
                        <img src="/image/listCard/Discover.png" alt=''/>
                    </div>
                    <div className={classes.img}>
                        <img src="/image/listCard/JCB.png" alt=''/>
                    </div>
                    <div className={classes.img}>
                        <img src="/image/listCard/MasterCard.png" alt=''/>
                    </div>
                    <div className={classes.img}>
                        <img src="/image/listCard/visa.png" alt=''/>
                    </div>
                </div>
                <Typography variant="body1">{error}</Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    className={classes.action}
                    disabled={loading}
                    onClick={() => setOpen(false)}
                >{cancelIntl}</Button>
                <Button
                    className={`${classes.action} ${classes.buyBtn}`}
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    {addIntl}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    element: {
        borderBottom: '1px solid #999',
        marginBottom: 20,
    },
    cards: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center"
    },
    img: {
        width: 65,
        height: 40,
        margin: "0 3px",
        position: "relative",
        borderRadius: "5px",
        backgroundColor: "#f1f1f1",
        "& img": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "90%"
        }
    },
    action: {
        fontWeight: "700 !important",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#158FFF !important",
            color: "#fff !important",
        }
    },
    buyBtn: {
        background: "#158FFF !important",
        color: "#fff !important",
        border: "none"
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        color: "#158FFF",
        padding: 15,
        textAlign: "center"
    }
}))

export default injectStripe(StripeAddCardDialog)
