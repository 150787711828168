import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core'
import {useGlobal} from 'reactn';
import {remove} from 'lodash'
import {getNaviCollRef} from '../../../common/firebaseRef/globalnavi';
import {msgError, msgSuccess} from '../../../utils/msg';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';

function RemoveAppDialog({app, setApp}) {

    const [loading, setLoading] = useState(false)
    const [list] = useGlobal('navigationsApps')
    const {user} = useSnapshot(userProxy)

    const handleDelete = async () => {
        try {
            setLoading(true)
            const nextList = [...list]
            remove(nextList, item => item.id === app.id)
            const ref = getNaviCollRef(user.user_id)
            await ref.set({
                data: nextList
            }, {merge: true})
            msgSuccess("正常に削除")
        } catch (e) {
            msgError(e.toString())
        } finally {
            setLoading(false)
            setApp(null)
        }
    }
    return (
        <div>
            <Dialog open={Boolean(app)}
                    onClose={() => setApp(null)}
            >
                <DialogContent>
                    <Typography>このアプリを削除しますか？</Typography>
                    <Typography color={"secondary"}>{app?.name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setApp(null)}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        disabled={loading}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default RemoveAppDialog;
