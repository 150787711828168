// import './firstRun/wdyr'
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import './config/globalState';
import './config/firebaseConfig';
import {LanguageMessageProvider} from './context/LanguageMessageContext';
import ErrorBoundary from './components/ErrorBounDary';
import {CustomThemeProvider} from './context/ThemeContext';
import appConfig from './config/index';
import {StripeProvider} from 'react-stripe-elements';
import {STRIPE_PUBLISH_KEY} from './config/constants';
import {TutorialProvider} from './context/TutorialContext';
import {ServiceGuideProvider} from './context/ServiceGuideContext';
import ServiceGuide from './components/Application/ServiceGuide';

appConfig()
createRoot(document.getElementById('root')).render(
    <>
        <ErrorBoundary>
            <BrowserRouter>
                <StripeProvider apiKey={STRIPE_PUBLISH_KEY}>
                        <ServiceGuideProvider>
                            <ServiceGuide/>
                            <CustomThemeProvider>
                                <LanguageMessageProvider>
                                    <TutorialProvider>
                                        <App/>
                                    </TutorialProvider>
                                </LanguageMessageProvider>
                            </CustomThemeProvider>
                        </ServiceGuideProvider>
                </StripeProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </>
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();


reportWebVitals()
