import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Collapse, IconButton, List, ListItem, ListItemText, Typography} from "@material-ui/core"
import {ExpandLess, ExpandMore, Settings,} from '@material-ui/icons'
import {cloneDeep, findIndex, orderBy} from 'lodash';
import {ItemCheckedCustom} from "item-checked-custom";
import useIntlId from '../../hooks/useIntlId';
import {getUserProjectsRef} from '../../common/firebaseRef/calendar';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {userProxy} from '../../store/proxy/user.proxy';
import {futureEventsProxy} from '../../store/proxy/futureEvents.proxy';

const styles = theme => ({
    root: {
        position: "relative",
        background: "#f1f1f1",
        borderRadius: 10,
        // margin: "10px 0",
        paddingBottom: 10,
        marginRight: 5
    },
    iconSetting: {
        "& .MuiButtonBase-root": {
            padding: 10
        }
    },
    topList: {
        padding: "0 0 0  10px"
    },
    titleGeniam: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 5,
    },
    nav: {
        // overflowY: 'auto',
        // maxHeight: '15vh',
        padding: "0",
        "& .MuiList-root": {
            padding: "0px 0 0px 10px",
        },
        // "&::-webkit-scrollbar": {
        //     width: 8,
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     borderRadius: 10,
        //     backgroundColor: '#ccc',
        // },
    },
    listItemText: {
        fontWeight: "bold",
        fontSize: 16,
        // color: theme.props.primaryColor,
    }
});

function GroupCalendar(props) {
    const {classes} = props;
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {geniamProjects} = useSnapshot(projectsProxy)
    const [disable, setDisable] = useState(false)
    const {user} = useSnapshot(userProxy)
    const [MyCalendar] = useIntlId(['leftHomeApp.MyCalendar'])
    const {geniam} = useSnapshot(futureEventsProxy)
    const onOpenSetting = React.useCallback(() => {
        handleOpen(2)
    }, [])
    const Toggle = (source) => {
        let index = findIndex(geniamProjects, p => p.id === source.id)
        if (index !== -1) {
            return updateSelected(geniamProjects[index].id, !geniamProjects[index].selected)
        }
    };

    const handleClick = () => {
        setDisable(!disable)
    };

    const updateSelected = async (id, check) => {
        if (!id || !user?.user_id)
            return
        try {
            await getUserProjectsRef(id).set({selected: check}, {merge: true})
        } catch (e) {
            console.log(e);
        }
    };


    const sortProjects = () => {
        let arr = cloneDeep(geniamProjects)
        return orderBy(arr, p => !p.selected);
    };
    return (
        <div id={'geniam-calendar-list'} className={`${classes.root}`}>
            <div className={classes.titleGeniam}>
                <ListItem button onClick={handleClick} className={classes.topList}>
                    <ListItemText>
                        <Typography className={classes.listItemText}>
                            {MyCalendar}
                        </Typography>
                    </ListItemText>
                    {!disable ? <ExpandLess id={'geniam-calendar-list-expand'}/> :
                        <ExpandMore id={'geniam-calendar-list-expand'}/>}
                </ListItem>
                <div className={classes.iconSetting} id={'geniam-calendar-list-setting'}>
                    <IconButton disableRipple={true} onClick={onOpenSetting}>
                        <Settings/>
                    </IconButton>
                </div>
            </div>

            <List component="nav" className={classes.nav}>
                <Collapse in={!disable} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {sortProjects().map((source, index) => {
                            let newGeniam = cloneDeep(geniam)
                            let next = newGeniam[source.id]
                            if (!source?.id)
                                return null
                            if (!source.is_sync)
                                return (
                                    <ItemCheckedCustom
                                        id={'source-calendar-name' + source.id}
                                        key={source.id}
                                        name={source.name}
                                        color={source.color}
                                        checked={source.selected}
                                        onClick={() => Toggle(source)}
                                        height={'33px'}
                                        margin={'unset'}
                                        isLeft={next !== 0}
                                        leftNumber={next > 1000 ? "999+" : next}
                                        hiddenAvatar={!next || next === 0}
                                        offTooltip={true}
                                        className={"ItemCheckedCustom"}
                                    />

                                )
                            return null
                        })}
                    </List>
                </Collapse>
            </List>
        </div>
    )
}

export default React.memo(withStyles(styles)(GroupCalendar))
