import React, {useState} from 'react';
import {Col, Row} from "antd";
import {makeStyles} from '@material-ui/core';
import {calenviewProd} from '../../../common/calenviewPlan';
import PaymentList from '../../Cart/PaymentList';
import MyPlan from './MyPlan';
import useIntlId, {getIntlId} from '../../../hooks/useIntlId';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

const getRows = () => {
    return [
        {
            name: null,
            type: null,
            checked: false,
            plan: null,
            prop: 'name',
        },
        {
            name: null,
            type: 'price',
            checked: false,
            plan: 'month',
            prop: null
        },
        {
            name: getIntlId("setting.plan.numberOfCalendar"),
            type: null,
            checked: false,
            plan: null,
            prop: 'Cal'
        },
        {
            name: getIntlId("setting.plan.numberOfView"),
            type: null,
            checked: true,
            plan: null,
            prop: 'view'
        },
        {
            name: getIntlId("setting.plan.function"),
            type: null,
            checked: true,
            plan: null,
            prop: 'future'
        },
        {
            name: getIntlId("setting.plan.hideGeniamNews"),
            type: null,
            checked: true,
            plan: null,
            prop: 'hiddenNews'
        },
        {
            name: null,
            type: 'price',
            checked: false,
            plan: 'year',
            prop: null
        },
    ]
}


function CalenviewPlan(props) {
    const classes = useStyles()
    const {plan} = useSnapshot(announcementProxy);
    const {user} = useSnapshot(userProxy)
    const [buyProd, setBuyProd] = useState(null)
    const [usingPlan] = useIntlId(['calenview.usingPlan'])
    const formatAmount = (amount) => {
        return new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'JPY',
            currencyDisplay: 'symbol',
        }).format(amount)
    }
    const calPlan = calenviewProd()
    const rows = getRows()
    const [tax] = useIntlId(['setting.plan.tax.included'])
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {
                    rows.map((row, idx) => (
                        <Row key={idx}
                             className={`${classes.row} ${!row.name ? classes.row1 : classes.row2}`}>
                            <Col
                                className={`${classes.col} ${classes.colTitle}`}>{row.name} </Col>
                            {
                                calPlan.map((item, index) => {
                                    if (row.type === 'price')
                                        return (
                                            <Col
                                                key={index}
                                                className={`${classes.col} ${classes.colName} `}
                                                style={{
                                                    background: item.backgroundColor
                                                }}
                                                onClick={() => {
                                                    if (plan?.hxPlan)
                                                        return;
                                                    if (!item.id || !user?.user_id)
                                                        return null
                                                    if (plan.price === item.price[row.plan]) {
                                                        alert(usingPlan)
                                                        return;
                                                    }
                                                    setBuyProd({...item, plan: row.plan})
                                                }}
                                            >
                                                <div>
                                                    <div className={classes.topTitle}
                                                    >{item.id ? `${formatAmount(item.amount[row.plan])}/${row.plan === 'month' ? getIntlId('appbar.month') : getIntlId('appbar.year')}` : (row.plan === 'month' ? "FREE" : getIntlId("setting.plan.startFreePlan"))}</div>
                                                    {
                                                        item.id &&
                                                        <div
                                                            className={classes.subTitle}>（{tax}{Math.ceil(item.amount[row.plan] * 1.1)}円/{row.plan === 'month' ? getIntlId('appbar.month') : getIntlId('appbar.year')})</div>
                                                    }
                                                </div>
                                                {
                                                    item.id &&
                                                    <img className={classes.cart}
                                                         src={"/image/cardIcon.svg"}
                                                         alt={'cart'}/>
                                                }
                                            </Col>
                                        )
                                    return (
                                        <Col
                                            key={index}
                                            className={`${classes.col} ${classes.colName}`}
                                            // onClick={onClickToCalenview}
                                        >
                                            <div className={`${row.checked ? classes.checkedContainer : ''}`}>
                                                {
                                                    row.checked ?
                                                        <div className={classes.checkImg}>
                                                            {
                                                                item[row.prop] ?
                                                                    <img alt={'check'}
                                                                         src={"/image/iconCheck.svg"}/>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        :
                                                        null
                                                }

                                                <div className={classes.flex1}>
                                                    <div className={classes.topTitle}
                                                         style={{
                                                             color: row.prop === 'name' ? item.color : "#000",
                                                             textAlign: 'center',
                                                             fontSize: row.prop === 'name' ? 18 : 13,
                                                             fontWeight: row.prop === 'name' ? 700 : 400,
                                                         }}
                                                         dangerouslySetInnerHTML={{__html: item[row.prop] || ''}}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    ))
                }
                <Row className={`${classes.row} ${classes.row1}`}>
                    <Col
                        className={`${classes.col} ${classes.colTitle}`}/>
                    {
                        calPlan.map((item, index) => {
                            if (plan.info.id === item.id)
                                return (
                                    <Col
                                        key={index}
                                        className={`${classes.col} ${classes.colName}`}

                                    > <img alt={'check'}
                                           src={"/image/iconCheck.svg"}/> </Col>
                                )
                            return (
                                <Col
                                    key={index}
                                    className={`${classes.col} ${classes.colName}`}
                                />
                            )
                        })
                    }
                </Row>
            </div>
            <MyPlan/>
            <PaymentList open={buyProd} setOpen={setBuyProd}/>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        // width: '100%',
        background: '#fff',
        margin: '0 15px',
        // marginTop: 20,
        fontSize: 10,
        borderRadius: '15px'
    },
    container: {
        padding: '10px',
        borderRadius: 25
    },
    row: {
        display: "flex",
        justifyContent: 'space-between',
        flexFrow: 'row wrap',
    },
    row2: {
        background: "#F5FCFF",
        borderBottom: "2px dashed #C1E1FF"
    },
    row1: {
        background: "#fff",
    },
    col: {
        flex: "1 0",
        display: "flex",
        position: "relative",
        alignItems: 'center',
        justifyContent: 'center',
    },
    topTitle: {
        fontWeight: 700,
        fontSize: 15
    },
    price: {
        fontSize: 20
    },
    colName: {
        color: "#fff",
        textAlign: "center",
        padding: '15px 5px',
        cursor: 'pointer'
    },
    checkedContainer: {
        width: "100%",
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: "center"
    },
    checkImg: {
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& img": {
            width: 20
        }
    },
    colTitle: {
        fontWeight: 700,
        padding: 5,
        fontSize: 13,
        textAlign: 'center'
    },
    cart: {
        position: "absolute",
        cursor: 'pointer',
        top: '25%',
        right: 8,
        transform: "translateY(-50%)",
        width: 30
    },
    subTitle: {
        fontSize: 14,
        fontWeight: 700
    },
    flex1: {
        flexGrow: 1
    },
    whiteBg: {
        backgroundColor: "#fff"
    }

}))

export default CalenviewPlan;
