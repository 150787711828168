import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Cached, Delete, Unarchive} from '@material-ui/icons';
import {GCalendarList, Sync, updateListGgCalenviewShow,} from '../../actions/googleCalendar';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import {cloneDeep, findIndex, orderBy, remove} from 'lodash';
import {withRouter} from 'react-router-dom';
import {onLogOut} from '../../common/googleCal/logOutGoogle';
import {Modal} from 'antd';
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import MenuItemContainer from './MenuItemContainer';
import {ReactComponent as SaveSvg} from '../../asSvg/copy.svg';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import {ItemCheckedCustom} from 'item-checked-custom';
import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import SpinCustom from '../Custom/CLFSpinCustom';
import OkButton from '../Dialog/OkButton';
import CancelButton from '../Dialog/CancelButton';
import DeleteButton from '../Dialog/DeleteButton';
import {ggTokenChecked} from '../../config/auth';
import useIntlId from '../../hooks/useIntlId';
import {msgError, msgSuccess} from '../../utils/msg';
import {publicIcon} from '../Calendar/handlers/infoEvent';
import {getEventByProduct, getEventVoteByProduct} from '../../common/getEventByProduct';
import {getDate} from '../Calendar/handlers/getDate';
import store from 'store';
import {useSnapshot} from 'valtio';
import {eventsProxy} from '../../store/proxy/events.proxy';
import {userProxy} from '../../store/proxy/user.proxy';
import {futureEventsProxy} from '../../store/proxy/futureEvents.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {getUserProjectsRef} from '../../common/firebaseRef/calendar';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',

        // paddingTop: '40px'
    },
    list: {
        maxWidth: 360
    },
    archiveIcon: {
        fontSize: 20
    },
    title: {
        fontSize: "1.3rem",
        color: '#000',
        textAlign: 'center',
        marginBottom: 10
    },
    titleItem: {
        '& span': {
            fontSize: "1.2rem !important",
            fontWeight: 700
        }
    },
    moveText: {
        "&:hover": {
            cursor: "move"
        }
    },
    email: {
        color: theme.props.primaryColor,
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginLeft: 10,
        paddingRight: 50,
        borderRight: '1px solid #f1f1f1'
    },
    userEmail: {
        // background: '#F1F1F1',
        // borderRadius: 25,
        fontWeight: 700,
        textTransform: 'lowercase',
        fontSize: '1.3rem',
        color: '#000',
        width: '350px',
        height: '35px'
    },
    gridRoot: {
        width: '100%',
        padding: 10
    },
    gridItem: {
        background: '#FFF',
        borderRadius: 15,
        padding: '10px 0',
        margin: '0 10px',
        overflowY: 'auto',
        // maxHeight: 600,
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
    },
    gridItem2: {
        background: '#FFF',
        borderRadius: 15,
        paddingTop: 10,
        margin: '0 10px',
        overflowY: 'auto',
        // maxHeight: 600,
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
    },
    box: {
        position: "relative",
        borderRadius: "5px",
        width: 20,
        height: 20,
        marginRight: 7,
        "& img": {
            position: "absolute",
            left: "50%",
            right: 0,
            bottom: 0,
            top: "50%",
            maxWidth: "100%",
            transform: "translate(-50%,-50%)",
        }
    },
    listItem: {
        display: 'block',
        padding: 15
    },
    reloadIcon: {
        color: theme.props.primaryColor,
        fontSize: '1.8rem',
    },
    listItemIcon: {
        justifyContent: 'flex-end',
        minWidth: 'unset',
    },
    primary: {
        margin: 0,
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            maxWidth: "25px !important"
        }
    },
    spin: {
        marginTop: 20
    },
    iconDisplay: {
        display: "flex",
        position: "relative",

    },
    icon: {
        margin: 'auto 5px'
    },
    iconGgCalendar: {
        width: 15,
        height: 15,
        alignSelf: 'center',
    },
    freeLimit: {
        color: '#FFF',
        background: 'black',
        cursor: "pointer"
    },
    container: {
        paddingLeft: 10,
        paddingRight: 30,
        display: "flex",
        width: '100%',
        height: 39,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        margin: '0 10px'
    },
    input: {
        width: '100%',
        maxWidth: 750,
        height: 45,
        borderRadius: 15,
        background: '#fff',
        display: "flex",
        flexDirection: 'row',
        alignItems: "center"
    },
    numberEvent: {
        position: "absolute",
        width: 25,
        height: 25,
        backgroundColor: "#ddd",
        color: "#fff",
        borderRadius: "50%",
        right: 40,
        top: 2,
        lineHeight: "25px",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 12,
    }
}));


function GoogleList(props) {
    const {plan} = useSnapshot(announcementProxy)
    const {googleAuth} = useSnapshot(userProxy)
    const [listProjects, setListProjects] = useState([])
    const [arrIndGe, setArrIndGe] = useState([])

    const [googleCalendarList, setGoogleCalendarList] = useGlobal('googleCalendarList')
    const [listGg, setListGg] = useState([])

    const [insync] = useGlobal('insync')
    const [sync_uuid, setSync_uuid] = useGlobal('sync_uuid')
    const [archive, setArchive] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingList, setLoadingList] = useState(false)
    const [unArchive, setUnArchive] = useState(null)
    const [deleting, setDeleting] = useState(null)
    const [openLogOut, setOpenLogOut] = useState(false)
    const [loadSave, setLoadSave] = useState(false)
    const [canRestoreArchive, setCanReStoreArchive] = useState([])
    const [heightDiv, setHeightDiv] = useState(window.innerHeight - 300)
    const classes = useStyles()
    const {user, calendarUser, setCalendarUser} = useSnapshot(userProxy)
    const {geniamProjects} = useSnapshot(projectsProxy)
    const {google} = useSnapshot(futureEventsProxy)
    const {handleClose, handleOpen} = useSnapshot(settingDialogProxy)
    const {listGgCalenviewShow = [], idCalenderHidden = []} = calendarUser
    const [googleListing] = useGlobal('googleListing')
    const [savedIntl, deletedIntl, displayOnCalendar, productIsHidden, btnRemoveProductDisplay] = useIntlId(['common.saved', 'common.deleted', 'calendar.setting.displayOnCalendar', 'calendar.setting.productIsHidden', 'calendar.setting.btnRemoveProductDisplay'])
    const [idCalHid, setIdCalHid] = useState([])
    const [googleCalendarSelect, logout, ok, archiveProject, noticePlan] = useIntlId(['settings.googleCalendarSelect',
        'settings.logout', 'settings.ok',
        'settings.archiveProject', 'settings.noticePlan'
    ])
    const [calendarTab1] = useGlobal('calendarTab1');
    const {date, endDate} = getDate(calendarTab1 - 1)
    const {geniamEvents, googleEvents} = useSnapshot(eventsProxy)
    const [arrListUpdate, setArrListUpdate] = useState([])

    window.onresize = function () {
        setHeightDiv(window.innerHeight - 300);
    }

    const toggleCalendar = async (id) => {
        let index = findIndex(listGg, {'id': id})
        let _listGg = listGg
        if (index !== -1) {
            let nextValue = !_listGg[index].check
            _listGg[index].check = nextValue

            let ind = findIndex(arrListUpdate, g => g.id === id);
            if (ind > -1) {
                arrListUpdate[ind].check = nextValue
            } else {
                arrListUpdate.push({
                    id: listGg[index].id,
                    check: true
                })
            }
            setArrListUpdate(arrListUpdate)
            setListGg([..._listGg])
        }
    }

    const checkRestoreValid = async list => {
        try {
            await ggTokenChecked()
            const result = await Promise.all(list.map(async pro => {
                try {
                    const request = window.gapi.client.calendar.calendars.get({
                        "calendarId": pro.sync_id
                    })
                    await request.then()
                    return pro
                } catch (e) {
                    console.log(e);
                    return null
                }

            }))
            setCanReStoreArchive(result.filter(x => x))
        } catch (e) {
            setCanReStoreArchive([])
        }
    }

    const onUnArchive = async () => {
        if (!unArchive || !unArchive.sync_id) {
            msgError('error load calendar try again late')
            return
        }
        try {
            const project = {...unArchive}
            const backgroundColor = project.color;
            setLoading(true)
            let request = window.gapi.client.calendar.calendarList.insert({
                "id": project.sync_id
            })
            let data = await request.then()
            if (data.status !== 200) {
                return null
            }
            const calendarId = data.result.id
            request = window.gapi.client.calendar.calendarList.patch({
                "calendarId": calendarId,
                "colorRgbFormat": true,
                backgroundColor
            })

            data = await request.then()
            if (data.status !== 200) {
                msgError("update color fail")
                return null
            }

            await GCalendarList()
            remove(archive, s => s.sync_id === project.sync_id)
            setArchive([...archive])
            msgSuccess(savedIntl)
        } catch (e) {
            console.log(e)
            msgError('Project has been completely deleted from google account. Unable to restore')
        } finally {
            setUnArchive(null)
            setLoading(false)
        }
    }

    const updateIdCalenderHidden = async (listId) => {
        setLoadingList(true)
        try {
            await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
                .set({
                    idCalenderHidden: idCalHid
                }, {merge: true})
        } catch (e) {

        } finally {
            setLoadingList(false)
        }
    }

    const displayCalendar = async (id) => {
        setLoadingList(true)
        let arrIdCalenderHidden = [...idCalHid];
        const index = arrIdCalenderHidden.indexOf(id);
        if (index > -1) {
            arrIdCalenderHidden.splice(index, 1);
        }
        setIdCalHid(arrIdCalenderHidden);
    }

    const hiddenCalendar = async (id) => {
        setLoadingList(true)
        let arrIdCalenderHidden = [...idCalHid];
        if (arrIdCalenderHidden?.indexOf(id) === -1) arrIdCalenderHidden.push(id);
        setIdCalHid(arrIdCalenderHidden);
    }

    const onDelete = async () => {
        if (!deleting || !deleting.sync_id || !deleting.uuid)
            return
        try {
            const pro = {...deleting}
            setLoading(true)
            // Is_Delete firestore true
            const idx = listProjects.findIndex((p) => p.uuid === pro.uuid);
            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection("projects")
                .doc(listProjects[idx].uuid)
                .set({
                    is_deleted: true
                }, {merge: true})

            listProjects.splice(idx, 1);
            await setListProjects([...listProjects])
            msgSuccess(`${deletedIntl}`)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        } finally {
            setDeleting(null)
            setLoading(false)
        }
    }

    const toggle = (source) => {
        setLoadingList(true)
        let _listProjects = cloneDeep(listProjects)
        let _arrIndGe = cloneDeep(arrIndGe)
        let index = findIndex(_listProjects, p => p.id === source.id)
        if (index !== -1) {
            if (!_arrIndGe.includes(index)) {
                _arrIndGe.push(index)
                setArrIndGe(_arrIndGe)
            }
            _listProjects[index].selected = !_listProjects[index].selected;
            setListProjects([..._listProjects]);
        }
    };

    const update = () => {
        arrIndGe.forEach(ind => {
            if (geniamProjects[ind].selected !== listProjects[ind].selected) {
                updateSelected(listProjects[ind].id, listProjects[ind].selected)
            }
        })
    }

    const updateSelected = async (id, check) => {
        if (!id || !user?.user_id)
            return
        try {
            await getUserProjectsRef(id).set({selected: check}, {merge: true})
        } catch (e) {
            console.log(e);
        }
    };

    const sortGoogleCalendar = () => {
        const selected = listGg?.filter(g => g.check).sort((a, b) => google[b.id] - google[a.id])
        const noSelect = listGg?.filter(g => !g.check && typeof g.check !== 'undefined').sort((a, b) => google[b.id] - google[a.id])
        const nonSelect = listGg?.filter(g => typeof g.check === 'undefined').sort((a, b) => google[b.id] - google[a.id])
        return [...selected, ...noSelect, {}, ...nonSelect]
    };

    const sortProjects = () => {
        return orderBy(listProjects, p => !p.selected);
    };

    const onSave = async () => {
        setLoadSave(true)
        await Promise.all([
            update(),
            updateIdCalenderHidden(idCalHid),
            updateListGgCalenviewShow(arrListUpdate),
            // GCalendarList(),
        ])
        setCalendarUser({
            ...calendarUser,
            listGgCalenviewShow: arrListUpdate,
            idCalenderHidden: idCalHid
        })
        setGoogleCalendarList(listGg)
        await handleClose()
        await setLoadSave(false)
    }

    useEffect(() => {
        if (!googleAuth || googleCalendarList?.length === 0)
            return
        let nextArchive = []
        listProjects.forEach((pro) => {
            if (pro.is_sync && pro.sync_id && pro.sync_email === googleAuth?.email) {
                let idx = findIndex(googleCalendarList, {id: pro.sync_id})
                if (idx === -1) {
                    nextArchive.push(pro)
                }
            }
        })
        setArchive([...nextArchive])
        // eslint-disable-next-line
    }, [listProjects, googleCalendarList])

    useEffect(() => {
        if (!archive.length) {
            setCanReStoreArchive([])
            return;
        }
        checkRestoreValid(archive)
    }, [archive])

    useEffect(() => {
        if (loadSave) return
        setListGg(cloneDeep(googleCalendarList))
        setIdCalHid(cloneDeep(idCalenderHidden) || [])
        setArrListUpdate(cloneDeep(listGgCalenviewShow) || [])
        setListProjects(cloneDeep(geniamProjects))
    }, [listGgCalenviewShow, idCalenderHidden, geniamProjects, googleCalendarList])

    return (
        <div className={classes.root}>
            <MenuItemContainer
                backgroundColor={'#f1f1f1'}
                className={classes.container}>
                <div className={classes.input}>
                    <div className={classes.email}>E-Mail</div>
                    <div className={`clf-flex-center ${classes.userEmail}`}>{googleAuth?.email}</div>
                </div>
                <CLFButtonSVG
                    onClick={() => setOpenLogOut(true)}
                    name={logout}
                    size={'default'}
                    className={classes.primary}
                />

                {
                    openLogOut &&
                    <LogoutDialog open={openLogOut} setOpen={setOpenLogOut} loading={loading}/>
                }
            </MenuItemContainer>
            <div className={classes.spin}>
                {
                    googleListing ? <SpinCustom/> : null
                }
            </div>
            {listGg &&
                <Grid container className={classes.gridRoot}>
                    <Grid item xs={6}>
                        <Typography className={classes.title}>
                            {googleCalendarSelect}
                        </Typography>
                        <div className={`${classes.gridItem}`} style={{height: `${heightDiv}px`}}>
                            <List className={classes.listItem}>
                                <>
                                    <Typography className={classes.title}>
                                        {displayOnCalendar}
                                    </Typography>
                                    {sortProjects().map((p, index) => {
                                        // let next = getEventVoteByProduct(p, date, endDate, geniamEvents, view, voteEvents)
                                        let next = google[p.id]
                                        if (p.is_sync) {
                                            return null
                                        }
                                        return (
                                            <div className={classes.iconDisplay} key={index}>
                                                <img src={'favicon.ico'} alt=""
                                                     className={classes.iconGgCalendar}/>
                                                {!p.isDefault ? <ListItemIcon className={classes.listItemIcon}>
                                                    <IconButton
                                                        disableRipple={true}
                                                        disabled={insync}
                                                        onClick={async () => {
                                                            setSync_uuid(p.uuid)
                                                            Sync(p.uuid)
                                                        }}
                                                        style={{padding: 0}}
                                                    >
                                                        {
                                                            insync && p.uuid === sync_uuid ?
                                                                <CircularProgress
                                                                    color={"primary"}
                                                                    size={30}
                                                                /> :
                                                                <Cached className={classes.reloadIcon}/>
                                                        }

                                                    </IconButton>
                                                </ListItemIcon> : <div style={{width: 21}}></div>}
                                                <ItemCheckedCustom
                                                    id={index}
                                                    key={`${index}`}
                                                    onClick={() => toggle(p)}
                                                    name={p.name}
                                                    color={p.color}
                                                    checked={p.selected}
                                                    isRevert={true}
                                                    hiddenAvatar={true}
                                                    isBold={true}
                                                    isTextColor={true}
                                                    height={'30px'}
                                                    margin={'unset'}
                                                    className={"ItemCheckedCustom"}
                                                />
                                                {
                                                    next?.length > 0 &&
                                                    <span className={classes.numberEvent}>{next?.length}</span>
                                                }
                                            </div>
                                        )
                                    })}

                                    {sortGoogleCalendar().map((calendar, index) => {
                                        if (!calendar?.id) return (
                                            <Grid
                                                key={index}
                                                className={classes.freeLimit}
                                                onClick={() => {
                                                    handleOpen(12)
                                                }}
                                                align="center">
                                                {noticePlan ? noticePlan.replace('**', plan.numOfCal) : ''}
                                            </Grid>
                                        )
                                        let next = google[calendar.id]

                                        if (!calendar?.id || (idCalHid !== null && idCalHid?.indexOf(calendar.id) > -1))
                                            return null
                                        return (
                                            <div key={index}>
                                                <div className={classes.iconDisplay} key={index}>
                                                    <img src={publicIcon('iconGgCalendar.png')} alt=""
                                                         className={classes.iconGgCalendar}/>
                                                    <EyeOutlined
                                                        onClick={() => typeof calendar.check === 'undefined' ? '' : hiddenCalendar(calendar.id)}
                                                        className={classes.icon}
                                                        style={{color: `${typeof calendar.check === 'undefined' ? '#ccc' : calendar.backgroundColor}`}}
                                                        title={btnRemoveProductDisplay}
                                                        checked={Boolean(calendar.check)}
                                                    />
                                                    <ItemCheckedCustom
                                                        id={index}
                                                        key={`${index}`}
                                                        onClick={() => typeof calendar.check === 'undefined' ? '' : toggleCalendar(calendar.id)}
                                                        name={calendar.summary}
                                                        isRevert={true}
                                                        hiddenAvatar={true}
                                                        isBold={true}
                                                        isTextColor={true}
                                                        height={'30px'}
                                                        margin={'unset'}
                                                        color={typeof calendar.check === 'undefined' ? '#ccc !important' : calendar.backgroundColor}
                                                        checked={Boolean(calendar.check)}
                                                        disabled={typeof calendar.check === 'undefined'}
                                                        offTooltip={true}
                                                        className={"ItemCheckedCustom"}
                                                    />
                                                    {
                                                        next?.length > 0 &&
                                                        <span className={classes.numberEvent}>{next?.length}</span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            </List>
                        </div>
                        <ListItem className={`clf-flex-center`}>
                            <CLFButtonSVG
                                onClick={onSave}
                                iconComponent={<SaveSvg/>}
                                name={ok}
                                size={'default'}
                                className={classes.primary}
                                loading={loadSave}
                                minHeight={"31px"}
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.title}>
                            &nbsp;
                        </Typography>
                        <div className={`${classes.gridItem2}`} style={{height: `${heightDiv / 2 - 2}px`}}>
                            <List className={classes.listItem}>
                                <Typography className={classes.title}>
                                    {productIsHidden}
                                </Typography>
                                {sortGoogleCalendar().map((calendar, index) => {
                                    if (!calendar?.id) return null;
                                    let next = google[calendar.id]
                                    return (
                                        idCalHid?.indexOf(calendar.id) !== -1 &&
                                        <div className={classes.iconDisplay} key={index}>
                                            <img src={publicIcon('iconGgCalendar.png')} alt=""
                                                 className={classes.iconGgCalendar}/>
                                            <EyeInvisibleOutlined
                                                onClick={() => displayCalendar(calendar.id)}
                                                className={classes.icon}
                                                style={{color: `${calendar.backgroundColor}`}}
                                                title={btnRemoveProductDisplay}
                                            />
                                            <ItemCheckedCustom
                                                id={index}
                                                key={`${index}`}
                                                name={calendar.summary}
                                                color={calendar.backgroundColor}
                                                checked={calendar.check}
                                                isRevert={true}
                                                hiddenAvatar={true}
                                                isBold={true}
                                                isTextColor={true}
                                                height={'30px'}
                                                margin={'unset'}
                                                className={"ItemCheckedCustom"}
                                                onClick={() => toggleCalendar(calendar.id)}
                                            />
                                            {
                                                next?.length > 0 &&
                                                <span className={classes.numberEvent}>{next?.length}</span>
                                            }
                                        </div>
                                    )
                                })}
                            </List>
                        </div>
                        <div className={`${classes.gridItem2}`}
                             style={{marginTop: '4px', height: `${heightDiv / 2 - 2}px`}}>
                            <Typography className={classes.title}>
                                {archiveProject}
                            </Typography>
                            <List className={classes.list}>
                                {
                                    canRestoreArchive.map(p => (
                                        <ListItem key={p.sync_id} style={{padding: '5px 15px'}}>
                                            <ListItemText style={{color: `${p.color}`}} primary={p.name}/>
                                            <ListItemIcon>
                                                <IconButton
                                                    disableRipple={true}
                                                    onClick={() => setUnArchive(p)}
                                                    disabled={loading}
                                                >
                                                    <Unarchive/>
                                                </IconButton>
                                                <IconButton
                                                    disableRipple={true}
                                                    disabled={loading}
                                                    onClick={() => setDeleting(p)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItem>
                                    ))
                                }
                                {
                                    Boolean(unArchive) &&
                                    <ArchiveDialog unArchive={unArchive} setUnArchive={setUnArchive}
                                                   onDone={onUnArchive}
                                                   loading={loading}/>
                                }
                                {
                                    Boolean(deleting) &&
                                    <DeleteProjectDialog project={deleting} setProject={setDeleting} onDone={onDelete}
                                                         loading={loading}/>
                                }
                            </List>
                        </div>
                    </Grid>
                </Grid>
            }
        </div>
    );
}

function ArchiveDialog({unArchive, setUnArchive, onDone, loading}) {
    const classes = useStyles();
    const [archiveDialogDeleteArchive, archiveDialogCancel, archiveDialogOk, archiveDialogTitle] = useIntlId(['settings.archiveDialogDeleteArchive', 'settings.archiveDialogCancel', 'settings.archiveDialogOk', 'settings.archiveDialogTitle'])
    const handleClose = () => {
        setUnArchive(null)
    }
    const onOk = () => {
        onDone()
    }
    return (
        <Modal
            visible={Boolean(unArchive)}
            title={archiveDialogDeleteArchive}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CLFButtonSVG
                    onClick={handleClose}
                    disable={loading}
                    name={archiveDialogCancel}
                    className={classes.secondary}
                    minWidth={"100px"}
                />
                <CLFButtonSVG
                    name={archiveDialogOk}
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                    className={classes.primary}
                    minWidth={"100px"}
                />
            </div>}
        >
            {archiveDialogTitle}
        </Modal>
    )
}

function DeleteProjectDialog({project, setProject, onDone, loading}) {

    const [archiveDialogDeleteProject, archiveDialogDelete] = useIntlId(['settings.archiveDialogDeleteProject', 'settings.archiveDialogDelete'])
    const handleClose = () => {
        setProject(null)
    }
    const onOk = () => {
        onDone()
    }
    return (
        <Modal
            visible={Boolean(project)}
            title={archiveDialogDeleteProject}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={handleClose}
                    disable={loading}
                />
                <DeleteButton
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                />
            </div>}
        >
            {archiveDialogDelete}
        </Modal>
    )
}

function LogoutDialog({open, setOpen, loading}) {
    const {googleAuth} = useSnapshot(userProxy)
    const [logoutIntl, logoutAuthIntl, msgIntl] = useIntlId(['settingGoogleSync.title', 'settingGoogleSync.question', 'settingGoogleSync.text'])

    const handleClose = () => {
        setOpen(false)
    }
    const onOk = () => {
        onLogOut()
        handleClose()
    }
    return (
        <Modal
            visible={Boolean(open)}
            title={logoutIntl}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={handleClose}
                    disable={loading}
                />
                <OkButton
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                />
            </div>}
        >
            {logoutAuthIntl}
            <Typography color={"secondary"}>
                ( {msgIntl} {googleAuth?.email || ''})
            </Typography>
        </Modal>
    )
}


export default withRouter(GoogleList)
