import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PreviewHighLight from '../PreviewHighLight/PreviewHighLight';
import {useSnapshot} from 'valtio';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 1200,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
        // console.log("before change", currentSlide, nextSlide);
    }
};

function RecommendBar(props) {

    const classes = useStyles()
    const {loaded, bar} = useSnapshot(announcementProxy)
    if (!isNaN(bar?.rotationInterval)) {
        settings.autoplaySpeed = bar.rotationInterval * 1000
    }
    if (!loaded || (!bar?.displayBar)) return null;
    return (
        <Grid container className={'!w-full !rounded-none'} id={"recommentBar"}>
            <PreviewHighLight/>
        </Grid>
    )
}

export default React.memo(RecommendBar)

const useStyles = makeStyles(theme => ({
    w100: {
        width: "100%",
        margin: 0
    },
    fl: {
        display: 'flex'
    },
    recommendBarItem: {
        // padding: "0 20px",
    },
    ItemContent: {
        cursor: "pointer",
        backgroundColor: theme.props.primaryColor,
        color: "white",
        height: 50,
        overflow: "hidden",
        borderRadius: '10px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 2px #fff",
        "& p": {
            marginBlockEnd: "unset",
            fontWeight: 700,
            textAlign: 'left',
            flexGrow: 1,
            paddingLeft: 10,
            whiteSpace: 'nowrap',
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 16
        },
        "& a": {
            whiteSpace: 'nowrap'
        },
        padding: "10px 10px"
    },
    slideContainer: {
        // marginLeft: 30,
        width: "100%"
    },
    recommendImg: {
        // width: "100%",
        height: "100%",
        maxWidth: "200px",
        maxHeight: "50px",
        objectFit: "contain"
    },
    reLink: {
        marginLeft: 10,
        color: "#fff",
        fontSize: 15,
        fontWeight: 600,
        textDecoration: 'underline',
    },
    setting: {
        width: 30,
        height: 30,
        backgroundColor: 10,
        background: "#fff",
        borderRadius: 5,
        color: theme.props.primaryColor,
        marginLeft: 10,
        "&:hover": {
            backgroundColor: 10,
            background: "#fff",
            borderRadius: 5,
            color: theme.props.primaryColor,
        },
        "& svg": {
            width: 30,
            height: 30
        }

    },
    viewImg: {
        width: "300px",
        height: "100%",
        objectFit: "contain"
    },
    lockIcon: {
        marginLeft: 10,
        width: 30,
        height: 30
    },
    flex: {
        display: "flex",
    },
    flex1: {
        flex: 1
    }
}))
