import React, {createContext, useContext, useState} from 'react'
import Tutorial from '../components/Tutorial';
import {getStepsFromId} from '../common/tutorialData';

export const TutorialContext = createContext(null)

export const useTutorial = () => useContext(TutorialContext)


export const TutorialProvider = ({children}) => {

    const [playId, setPlayId] = useState(null)
    const [steps, setSteps] = useState([])
    const [initStep, setInitStep] = useState(0)

    // useEffect(() => {
    //     if (!playId) {
    //         setSteps([])
    //         return
    //     }
    //     setSteps(getStepsFromId(playId))
    //
    // }, [playId])
    const onPlay = (id, index = 0) => {
        if (!id) {
            setSteps([])
            setInitStep(0)
            return;
        }
        setInitStep(index)
        // console.log(id, getStepsFromId(id))
        setSteps(getStepsFromId(id))
    }

    return (
        <TutorialContext.Provider value={{playId, setPlayId, onPlay}}>
            {children}
            <Tutorial steps={steps} initStep={initStep}/>
        </TutorialContext.Provider>
    )
}

