import React from 'react'
import {useGlobal} from 'reactn'
import LoginTab from "./LoginTab";
import GoogleList from "./googleList";
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';


function SyncSetting(props) {
    const {googleAuth} = useSnapshot(userProxy)
    return (
        <div>
            {googleAuth && googleAuth?.email ?
                <GoogleList/>
                :
                <LoginTab/>
            }
        </div>
    )
}

export default SyncSetting
