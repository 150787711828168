import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';

import {getGlobal, setGlobal, useGlobal} from 'reactn'
import {AddGoogleEvent, updateGoogleEvent} from "../../actions/googleCalendar";
import {CalendarReloadOne, newEvent, updateEvent} from "../../actions/calendar";
import moment from "moment";
import {ggTokenChecked} from "../../config/auth";
import {cloneDeep, findIndex, isUndefined, omitBy} from 'lodash'
import {db} from '../../config/firebaseConfig'
import {v4 as uuidv4} from 'uuid'
import {CALENDAR_COLLECTION} from "../../config/constants";
import useIntlId from "../../hooks/useIntlId";
import {RRule} from 'rrule'
import {refetchSource} from "../../actions/refetchSourcce";
import setGlobalGGEventsWithDataAndInfo from "./handlers/setGlobalGGEventsWithDataAndInfo";
import {deleteZoom} from "../../actions/zoom";
import {msgError, msgSuccess} from '../../utils/msg';
import {genId} from 'cf-gen-id';
import {useHistory} from 'react-router-dom';
import {deleteRecurringEvent} from '../../common/events/recurringEvent';
import {snapshot, useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {eventsProxy} from '../../store/proxy/events.proxy';
import {searchProxy} from '../../store/proxy/search.proxy';

function UpdateRecuringPopup(props) {
    const [recurringOpen, setRecurringOpen] = useGlobal('recurringOpen')
    const [recurringEvent, setRecurringEvent] = useGlobal('recurringEvent')
    const {user} = useSnapshot(userProxy)
    const history = useHistory();
    const {reloadEventsInSearchPage} = useSnapshot(searchProxy)
    const [select, setSelect] = useState("this")
    const [loading, setLoading] = useState(false)
    const [editRecurring, onlyEvents, followingEvents, allEvents, createOk, createCancel, savedIntl] = useIntlId([
        'create.editRecurring',
        'create.onlyEvents',
        'create.followingEvents',
        'create.allEvents',
        'create.ok',
        'create.cancel',
        'toast.success.calendar.saved',
    ])
    const handleClose = () => {
        setRecurringOpen(false)
        setRecurringEvent({})
        CalendarReloadOne(window['i'])
    }
    const {deleteEvent} = useSnapshot(eventsProxy);
    useEffect(() => {
        if (!recurringEvent?.id)
            return
        if (recurringEvent?.isChangeRecurrence) {
            setSelect("following")
            return;
        }
        setSelect("this")
    }, [recurringEvent])

    const weekylyDay = (recurrence) => {
        if (!recurrence || !recurrence.includes("WEEKLY"))
            return null
        const options = RRule.parseString(recurrence)
        if (options.byweekday?.length === 1) {
            return options.byweekday[0]?.weekday;
        }
        return null
    }
    const getOldRrule = ({options, until, start}) => {
        if (options.count) {
            const nextOptions = {...options}
            nextOptions.until = until
            nextOptions.dtstart = new Date(start)
            const countRrule = new RRule(nextOptions)
            nextOptions.count = countRrule.count()
            if (nextOptions.count) {
                nextOptions.until = null
            } else {
                nextOptions.count = null
            }
            nextOptions.dtstart = null
            return new RRule(nextOptions)

        }
        return new RRule({...options, until})
    }
    const onUpdate = async () => {
        try {
            setLoading(true)
            const isCopiedSave = recurringEvent.isCopiedSave;
            delete recurringEvent.isCopiedSave

            let value = select
            if (recurringEvent.isStart && value === "following") {
                value = "all"
            }

            switch (value) {
                case "all":
                    if (recurringEvent.zoomMeetingId && recurringEvent.isRemoveZoom) {
                        deleteZoom(recurringEvent.zoomMeetingId).then()
                        recurringEvent.linkZoom = '';
                        recurringEvent.zoomMeetingId = '';
                        recurringEvent.conferenceData = '';
                    }
                    const duration = moment(recurringEvent.end).diff(recurringEvent.start, recurringEvent.allDay ? "days" : "hours", true)
                    const start = recurringEvent.isStart ?
                        moment(recurringEvent.start).format()
                        :
                        moment(recurringEvent.originStart.start).set({
                            hours: moment(recurringEvent.start).hours(),
                            minutes: moment(recurringEvent.start).minutes(),
                            seconds: 0
                        }).format()
                    const end = recurringEvent.isStart ?
                        moment(recurringEvent.end).format() :
                        moment(start).add(duration, recurringEvent.allDay ? "days" : "hours").format()
                    if (!recurringEvent.isChangeRecurrence && weekylyDay(recurringEvent.recurrence) !== null && weekylyDay(recurringEvent.recurrence) !== moment(recurringEvent.start).isoWeekday() - 1) {
                        const options = RRule.parseString(recurringEvent.recurrence)
                        options.byweekday = moment(recurringEvent.start).isoWeekday() - 1
                        const rule = new RRule(options)
                        recurringEvent.recurrence = rule.toString()
                    }
                    if (recurringEvent.is_google || recurringEvent.googleEvent) {
                        const eventModel = {...recurringEvent, start, end}

                        if (!isCopiedSave) {
                            delete recurringEvent.isRemoveZoom
                            const data = await updateGoogleEvent(eventModel, recurringEvent.recurringEventId, recurringEvent.ignoreTime)
                            if (!data) {
                                // error
                                refetchSource('google')
                                msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
                                return
                            }
                            msgSuccess(savedIntl)
                            setGlobalGGEventsWithDataAndInfo(data, recurringEvent)
                        } else {
                            delete eventModel.id;
                            delete eventModel.uuid;
                            const data = await AddGoogleEvent(eventModel)
                            if (!data) {
                                // error
                                refetchSource('google')
                                msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
                                return
                            }
                            msgSuccess(savedIntl)
                        }
                    } else {
                        delete recurringEvent.originStart
                        delete recurringEvent.oldRecurrence
                        delete recurringEvent.allowAll
                        delete recurringEvent.isChangeRecurrence
                        delete recurringEvent.isStart
                        delete recurringEvent.isRemoveZoom

                        if (isCopiedSave) {
                            newEvent({
                                ...recurringEvent,
                                id: genId({prefix: 'evt_', size: 8}),
                                recurringEventId: ""
                            })
                        } else {
                            updateEvent({
                                ...recurringEvent,
                                id: recurringEvent.recurringEventId,
                                recurringEventId: "",
                                start, end
                            }, true, recurringEvent.ignoreTime)
                        }

                        msgSuccess(savedIntl)
                    }
                    break
                case "this":
                    if (recurringEvent.zoomMeetingId && recurringEvent.isRemoveZoom) {
                        recurringEvent.linkZoom = '';
                        recurringEvent.zoomMeetingId = '';
                        recurringEvent.conferenceData = '';
                        delete recurringEvent.isRemoveZoom;
                    }
                    if (recurringEvent.is_google || recurringEvent.googleEvent) {
                        const eventModel = {...recurringEvent, recurrence: null};
                        isCopiedSave && delete eventModel.id;
                        isCopiedSave && delete eventModel.uuid;
                        const data = isCopiedSave ? await AddGoogleEvent(eventModel) : await updateGoogleEvent(eventModel, recurringEvent.id)
                        if (!data) {
                            // error
                            refetchSource('google')
                            msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
                            return
                        }
                        msgSuccess(savedIntl)
                    } else {
                        delete recurringEvent.oldRecurrence
                        delete recurringEvent.isChangeRecurrence
                        delete recurringEvent.is_google
                        delete recurringEvent.googleEvent
                        delete recurringEvent.isStart
                        delete recurringEvent.originStart
                        delete recurringEvent.allowAll
                        recurringEvent.isDeleted = false
                        recurringEvent.isTasks = false
                        recurringEvent.recurrence = null
                        if (isCopiedSave) {
                            newEvent({
                                ...recurringEvent,
                                id: genId({prefix: 'evt_', size: 8}),
                                recurringEventId: ""
                            })
                        } else {
                            updateEvent(recurringEvent)
                        }
                        msgSuccess(savedIntl)
                    }

                    break
                case "following":
                    if (recurringEvent.zoomMeetingId && recurringEvent.isRemoveZoom) {
                        recurringEvent.linkZoom = '';
                        recurringEvent.zoomMeetingId = '';
                        recurringEvent.conferenceData = '';
                        delete recurringEvent.isRemoveZoom;
                    }
                    let options = RRule.parseString(recurringEvent.isChangeRecurrence ? recurringEvent.oldRecurrence[0] : recurringEvent.recurrence[0])
                    const date = moment(recurringEvent?.id?.split("_")?.pop()).format("YYYY-MM-DD")
                    const ruleOldEvent = getOldRrule({
                        options,
                        until: new Date(moment(date).startOf("day").subtract(1, "seconds").utc().format()),
                        start: recurringEvent.originStart.start
                    })
                    if (!recurringEvent.isChangeRecurrence && weekylyDay(recurringEvent.recurrence) !== null && weekylyDay(recurringEvent.recurrence) !== moment(recurringEvent.start).isoWeekday() - 1) {
                        options.byweekday = moment(recurringEvent.start).isoWeekday() - 1
                    }
                    if (recurringEvent.isChangeRecurrence) {
                        options = RRule.parseString(recurringEvent.recurrence)
                    }
                    const nextUntil = moment(recurringEvent.start).subtract(1, "day").endOf("day").utc().format();

                    const ruleNextEvent = new RRule(options)
                    if (recurringEvent.is_google || recurringEvent.googleEvent) {
                        await ggTokenChecked()

                        const eventModel = {...recurringEvent, recurrence: ruleNextEvent.toString()}

                        if (!isCopiedSave) {
                            // add until option to recurrence
                            const request = window.gapi.client.calendar.events.patch({
                                "calendarId": recurringEvent.project_uuid,
                                "eventId": recurringEvent.recurringEventId,
                                "resource": {
                                    recurrence: [ruleOldEvent.toString()]
                                },
                            });
                            const {result, status} = await request.then()
                            if (status !== 200) {
                                // error
                                refetchSource('google')
                                msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
                                return
                            }
                            // add new event
                            const data = await AddGoogleEvent(eventModel)
                            if (!data) {
                                // error
                                refetchSource('google')
                                msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
                                return
                            }
                            msgSuccess(savedIntl)

                            const {googleEvents, setGoogleEvents} = snapshot(eventsProxy)
                            let _googleEvents = cloneDeep(googleEvents)
                            const events = _googleEvents[recurringEvent.project_uuid]?.events || []
                            let idx = findIndex(events, {id: recurringEvent.recurringEventId})
                            if (idx !== -1) {
                                events[idx] = {...result, project_uuid: recurringEvent.project_uuid}
                                events.push({...data, project_uuid: recurringEvent.project_uuid})
                                _googleEvents[recurringEvent.project_uuid].events = events
                                setGoogleEvents(_googleEvents)
                            }
                        } else {
                            delete eventModel.id;
                            delete eventModel.uuid;
                            const data = await AddGoogleEvent(eventModel)
                            if (!data) {
                                // error
                                refetchSource('google')
                                msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
                                return
                            }
                            msgSuccess(savedIntl)
                        }
                    } else {
                        const newId = `evt_${uuidv4()}`
                        delete recurringEvent.is_google
                        delete recurringEvent.googleEvent
                        // delete recurringEvent.recurringEventId
                        recurringEvent.isDeleted = false
                        recurringEvent.isTasks = false
                        recurringEvent.id = newId
                        recurringEvent.start = moment(recurringEvent.start).format()
                        recurringEvent.end = moment(recurringEvent.end).format()
                        delete recurringEvent.originStart

                        const batch = db.batch();

                        await deleteRecurringEvent(user, recurringEvent, nextUntil);

                        deleteEvent(recurringEvent, () => {
                            refetchSource('geniam')
                        }, (item => recurringEvent.recurringEventId === item.recurringEventId && moment(item.start).isAfter(nextUntil)))

                        if (isCopiedSave) {
                            const newRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${newId}`)
                            batch.set(newRef,
                                omitBy({...recurringEvent, recurrence: ruleNextEvent.toString()}, isUndefined)
                            )
                        } else {
                            const oldRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${recurringEvent.recurringEventId}`)
                            batch.set(oldRef, {
                                recurrence: ruleOldEvent.toString()
                            }, {merge: true})
                            const newRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${newId}`)
                            batch.set(newRef,
                                omitBy({...recurringEvent, recurrence: ruleNextEvent.toString()}, isUndefined)
                            )
                        }
                        await batch.commit()
                        msgSuccess(savedIntl)
                    }
                    break
                default:
                    break;
            }
            await reloadEventsInSearchPage(history.location);
        } catch (e) {
            console.log(e);
            refetchSource('google')
        } finally {
            await reloadEventsInSearchPage(history.location);
            setLoading(false)
            setRecurringOpen(false)
            setRecurringEvent({})
        }
    }
    // const getRecurence = (data) => {
    //     if (!data)
    //         return null
    //     if (!data.includes("UNTIL")) {
    //         return data
    //     }
    //     const valuesArr = data.split(";")
    //     remove(valuesArr, s => s?.includes("UNTIL"))
    //     return valuesArr.join(";")
    // }
    return (
        <div>

            <Dialog
                open={recurringOpen}
                onClose={handleClose}
                disableRestoreFocus={true}
            >
                <DialogTitle>{editRecurring}</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={select}
                            onChange={e => {
                                setSelect(e.target.value)
                            }}
                        >
                            {
                                !recurringEvent?.isChangeRecurrence ?
                                    <FormControlLabel value={"this"} control={<Radio/>} label={onlyEvents}/>
                                    : null
                            }
                            <FormControlLabel value={"following"} control={<Radio/>}
                                              label={followingEvents}/>
                            {
                                recurringEvent.allowAll ?
                                    <FormControlLabel value={"all"} control={<Radio/>} label={allEvents}/>
                                    :
                                    null
                            }
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={loading}>
                        {createCancel}
                    </Button>
                    <Button
                        onClick={onUpdate}
                        disabled={!select || loading}
                        color="primary" autoFocus>
                        {createOk}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UpdateRecuringPopup
