import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import ThickLineItem from "./ThickLineItem";
import {withRouter} from 'react-router-dom'
import {makeStyles} from "@material-ui/core";
import SaveButton from "../../Dialog/SaveButton";
import useIntlId, {getIntlId} from '../../../hooks/useIntlId';
import {msgSuccess} from '../../../utils/msg';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../../store/proxy/settingDialog.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';

function ThickLine(props) {
    const [data, setData] = useState([])
    const [viewThickLine] = useIntlId(['settings.viewThickLine'])
    const classes = useStyles()
    const {handleClose} = useSnapshot(settingDialogProxy)
    const {calendarUser, setCalendarUser} = useSnapshot(userProxy)

    useEffect(() => {
        setData(calendarUser?.thickLine || JSON.parse(localStorage.getItem('thickLine')) || [12, 18, null, null])
    }, [calendarUser?.thickLine])
    const handleSave = () => {
        const dataUpdate = {...calendarUser, thickLine: [...data]}
        setCalendarUser(dataUpdate)
        localStorage.setItem('thickLine', JSON.stringify(data))
        msgSuccess(getIntlId("toast.success.change"));
        // setOpenSetting(false)
        handleClose()
    }
    return (
        <div className={classes.root}>
            <div className={classes.title}>{viewThickLine}</div>
            {data.map((line, i) => {
                return (
                    <FormControl key={i}>
                        <ThickLineItem
                            index={i}
                            data={data}
                            setData={setData}
                            classes={classes}
                        />
                    </FormControl>
                )
            })}
            <SaveButton onClick={handleSave}/>
        </div>
    );
}

export default withRouter(ThickLine)
const useStyles = makeStyles((theme) => ({
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        // padding: "0px 15px",
        height: 33,
        background: "#f1f1f1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        width: 90,
        fontWeight: "bold",
        paddingLeft: 15,
        color: "#000",
        pointerEvents: "all",
    },
    iconSelect: {
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        pointerEvents: "none",
        // width: 26,
        // height: 26,
    },
    root: {
        background: "#fff",
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
        justifyContent: "space-between",
        borderRadius: "25px",
        alignItems: "center"
    },
    title: {
        color: theme.props.primaryColor,
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 12
    },
    btn: {
        minWidth: 94, minHeight: 35,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: 12,
        backgroundColor: theme.props.primaryColor,
        borderColor: theme.props.primaryColor
    }
}))
