import React from 'react';
import styled from 'styled-components';
import {Button} from "antd";
import TooltipCustom from "./TooltipCustom";

const DivList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const IconButton = styled(Button)`
    margin-right: 5px;
    margin-bottom: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    box-shadow: none;

    &:hover {
        background-color: #f1f1f1;
        color: unset
    }

    &:focus {
        background-color: #f1f1f1;
        color: unset
    }
`
export const TaskIconContainer = styled.div(props => ({
    display: "flex",
    width: "32px",
    height: "32px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: props.background || "",
    borderRadius: `${props.borderRadius} !important` || "50%",
    cursor: props?.disabled ? "no-drop" : "pointer"
}))
const IconCustom = styled.span`
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
    color: ${props => props.color ? props.color : ''}

`

const oldIcons = {
    "AccountCircle": "account_circle",
    "Group": "group",
    "AssignmentInd": "assignment_ind",
    "AssignmentTurnedIn": "assignment_turned_in"
}

const getIcon = (name) => {
    return oldIcons[name] || name
}


function TaskCustom({children, items = [], callBack, disabled, color, event, isGetPopup = false, borderRadius = true}) {

    const onClick = (item) => {
        if (callBack && typeof callBack === 'function')
            return callBack(item)
    }
    const isSelected = item => {
        if (item?.type === "file") {
            return item?.file?.id === event?.task
        }
        return event?.task === item.icon
    }
    return (
        <DivList>
            {
                items.map((item, index) => {
                    if (!item)
                        return null;
                    return (
                        <TooltipCustom
                            key={item.name + index}
                            title={item.name}
                            placement={'bottom'}
                            overlayClassName={'hover-show-tooltip hover-show-tooltip-2'}
                            getId={isGetPopup ? 'tool-tip-task' + item.name + index : null}
                        >
                            {
                                item?.type === "file" ?
                                    <TaskIconContainer
                                        disabled={disabled}
                                        borderRadius={borderRadius}
                                        background={isSelected(item) ? color : "#f1f1f1"}
                                        onClick={() => !disabled ? onClick(item) : ""}>
                                        <img alt={''} src={item?.file?.url} className={"taskImg"}/>
                                    </TaskIconContainer>
                                    :
                                    <IconButton
                                        key={index}
                                        id={'tool-tip-task' + item.name + index}
                                        shape={borderRadius === "50%" && "circle"}
                                        onClick={() => onClick(item)}
                                        disabled={disabled}
                                        icon={<IconCustom
                                            color={isSelected(item) ? color : ''}
                                        >
                                            {getIcon(item.icon)}
                                        </IconCustom>}
                                    />

                            }
                        </TooltipCustom>
                    )
                })
            }
            {children}
        </DivList>
    );
}

export default TaskCustom;
