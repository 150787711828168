import React, {useContext, useEffect} from 'react';
import 'react-overlay-loader/styles.css';
import {makeStyles} from "@material-ui/core/styles";
import ChangeTimeConfirmDialog from "../components/Meeting/ShareFreeTimeUrl/ChangeTimeConfirmDialog";
import ContentHomeApp from "../components/Application/ContentHomeApp";
import LeftHomeApp from "../components/Application/LeftHomeApp";
import MenuAppBar from "../components/MenuAppBar/MenuAppBar";
import UpdateRecuringPopup from "../components/Calendar/UpdateRecuringPopup";
import IconLeft from "../components/LeftRightExpan/IconLeft";
import HelperTipsDrawer from "../components/MenuAppBar/HelperTipsDrawer";
import {db} from '../config/firebaseConfig';
import {LanguageMessageContext} from '../context/LanguageMessageContext';
import {NavigationGlobal, RecommendAnnounceBar} from '@Andom-Geniam/git-globalnavi';
import {useMeasure} from 'react-use';
import RecommendBar from '../components/Application/RecommendBar';
import {snapshot, useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';
import {themesProxy} from '../store/proxy/themes.proxy';
import {onLogout, onOpenMyAccount} from '../common/account';
import {settingDialogProxy} from '../store/proxy/settingDialog.proxy';
import RightExpan from '../components/LeftRightExpan/RightExpan';
import {announcementProxy} from '../store/proxy/announcement.proxy';

function Home() {
    const classes = useStyles();
    const {user} = useSnapshot(userProxy)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {openLeftMenu} = useSnapshot(themesProxy)
    const [ref, {height}] = useMeasure()
    const {bar, lock, setBarAndBoard} = useSnapshot(announcementProxy);
    const intl = useContext(LanguageMessageContext)
    const path = window.location.pathname;

    useEffect(() => {
        if (user?.user_id) setBarAndBoard(user.user_id);
    }, [user?.user_id]);

    useEffect(() => {
        if (user?.user_id && path === '/settings/zoomAuth'){
            const {handleOpen} = snapshot(settingDialogProxy)
            handleOpen(3)
        }
    }, [user?.user_id, path]);


    return (
        <div className={classes.container}>
            <div className={"flex items-center justify-between bg-[#f1f1f1] max-w-full overflow-auto"}>
                <MenuAppBar/>
                {
                    user?.user_id &&
                    <NavigationGlobal
                        user={user}
                        db={db}
                        service={"calenview"}
                        onClickLogOut={onLogout}
                        switchAccount={onLogout}
                        onClickAccount={onOpenMyAccount}
                        intl={intl}
                        onClickSetting={() => {
                            handleOpen(11)
                        }}
                    />
                }

            </div>

            <RecommendAnnounceBar
                lock={lock}
                user={user}
                db={db}
                service={"calenview"}
                handleGotoSettings={() => {
                    handleOpen(6)
                }}
            />
            {
                bar?.highLightBar &&
                <RecommendBar/>
            }

            <div className={classes.root}>

                <div className={"flex h-full"}>
                    <IconLeft/>
                    <div className={!openLeftMenu ? "w-0 transition-all " : "w-[250px] transition-all min-w-[250px]"}>
                        <LeftHomeApp/>
                    </div>
                    <div className={"flex-grow-1"} ref={ref}>
                        <ContentHomeApp
                            // numOfLine={numOfLine}
                            calendarHeight={height}
                            // heightChartApp={heightChartApp}
                        />
                    </div>
                    <RightExpan/>
                </div>

                <UpdateRecuringPopup/>
                <ChangeTimeConfirmDialog/>
                <HelperTipsDrawer/>
            </div>
        </div>

    )
}

const useStyles = makeStyles(theme => ({
    container: {
        "&& img": {
            maxWidth: 'none'
        },
        height: "100vh",
        overflow: "auto"
    },

    hidden: {
        display: "none",
    },
    leftContent: {
        display: 'block',
        boxSizing: 'border-box',
        width: '250px',
        // float: 'left',
    },
    heightFull: {
        overflowY: 'auto',
        overflowX: 'hidden',
        // maxHeight: '92vh',
        display: 'block',
        boxSizing: 'border-box',
        float: `right`,
        width: `calc(100% - 250px)`,
        margin: 'auto',
        paddingLeft: 10,
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px 0 0 10px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px 0 0 10px",
            backgroundColor: '#ccc',
        },
    },
    content: {
        overflowY: 'auto',
        overflowX: 'hidden',
        // maxHeight: '92vh',
        display: 'block',
        boxSizing: 'border-box',
        float: `right`,
        width: `calc(100% - 250px)`,
        margin: 'auto',
        // paddingRight: 10,
        paddingLeft: 10,
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px 0 0 10px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px 0 0 10px",
            backgroundColor: '#ccc',
        },
    },
    contentFull: {
        display: 'block',
        boxSizing: 'border-box',
        float: `left`,
        width: `100%`,
        margin: 'auto',
        position: "relative",
    },
    root: {
        overflowY: 'visible',
        position: 'relative',
        height: '100%'
    },
    leftNav: {
        minWidth: 750
    },
    btnLeft: {float: "left"},
    btnRight: {float: "right"},
    centerNav: {
        margin: 'auto', position: "relative", minWidth: 750
    },
    rightBtn: {
        position: "fixed",
        right: 35,
        top: 127,
        zIndex: 99,
        width: 42,
        height: 42,
        alignItems: 'center',
        borderRadius: "50%",
        // backgroundColor: '#f1f1f1',
        "& .anticon svg": {
            width: 18,
            height: 18
        },
        "& img": {
            width: 18,
            height: 18
        },

    },
    rightBtn2: {
        position: "fixed",
        right: 35,
        top: 180,
        zIndex: 99,
        width: 42,
        height: 42,
        alignItems: 'center',
        borderRadius: "50%",
        // backgroundColor: '#f1f1f1',
        "& .anticon svg": {
            width: 18,
            height: 18
        },
        "& img": {
            width: 18,
            height: 18
        },
    }

}));

export default Home
