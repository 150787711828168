import React, {useState} from 'react';
import {useGlobal} from 'reactn';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {createZoom, deleteZoom, handleConnectZoomOAuth} from '../../actions/zoom';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import {IconButton} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button} from 'antd';
import {publicIcon} from '../Calendar/handlers/infoEvent';
import useIntlId from '../../hooks/useIntlId';
import {msgError, msgSuccess} from '../../utils/msg';

const copy = require('clipboard-copy');

function ZoomButton({classes, event, setEvent, onUpdate = null, linkZoom}) {
    const [zoomOAuth] = useGlobal('zoomOAuth');
    const [loading, setLoading] = useState(false);
    const [
        createZoomIntl,
        loginZoom,
        videoconferencingIntl,
        joinZoomIntl,
        delIntl,
        copyIntl,
        copiedIntl
    ] = useIntlId([
        'app.notification.createMeetingByZoom',
        'app.setting.loginToZoom',
        'app.profile.videoconferencing',
        'email.message.joinZoom',
        'app.notification.delete',
        'app.setting.copyTex',
        'common.copied'
    ])


    const handleCopy = async () => {
        await copy(linkZoom)
        msgSuccess(copiedIntl)
    };

    const handleCreateZoomMeeting = async () => {
        try {
            setLoading(true);
            const data = await createZoom(event);
            if (!data) return;
            const newEvent = {
                ...event,
                zoomMeetingId: data?.id,
                conferenceData: data
            }
            // console.log(newEvent)
            setEvent(newEvent);
        } catch (e) {
            msgError(e?.response?.data || "Create zoom event error! Please try again")
            console.log(e?.response?.data || "Create zoom event error! Please try again");
        } finally {
            setLoading(false)
        }
    };

    const handleDeleteZoomMeeting = async () => {
        try {
            if (event.recurrence && onUpdate && typeof onUpdate === 'function') {
                onUpdate(event, true, true);
                return;
            }
            let zoomId = event?.zoomMeetingId || '';
            event.linkZoom = '';
            event.zoomMeetingId = '';
            event.conferenceData = '';
            setEvent({...event});
            if (onUpdate && typeof onUpdate === 'function')
                onUpdate(event, true, false);

            if (zoomId) {
                deleteZoom(zoomId).then()
                // axios.post(API_ZOOM + '/delete', {
                //     type: 'meetings',
                //     meetingId: zoomId,
                // }).catch(e => console.log(e))
            }
        } catch (e) {
            console.log(e?.response?.data || "Delete zoom event error! Please try again");
        }
    };

    const handleOpenZoomMeeting = () => {
        window.open(linkZoom)
    };

    if (event && !event.is_holiday)
        return (
            <div className={classes.zoomMeeting}>
                {
                    linkZoom ?
                        <TextField
                            className={classes.copyField}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <Tooltip title={copyIntl}>
                                                <IconButton onClick={handleCopy}> <FileCopyIcon/></IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                        <InputAdornment position="start">
                                            <Tooltip title={joinZoomIntl}>
                                                <VideocamOutlinedIcon
                                                    className={classes.zoomJoin}
                                                    onClick={handleOpenZoomMeeting}
                                                    fontSize={'large'}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                        <InputAdornment position="start">
                                            <Tooltip title={delIntl}>
                                                <DeleteOutlineIcon
                                                    className={classes.zoomJoin}
                                                    onClick={handleDeleteZoomMeeting}
                                                    fontSize={'large'}
                                                />
                                            </Tooltip>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                            label={videoconferencingIntl}
                            InputLabelProps={{shrink: true}}
                            value={linkZoom}
                            // disabled={disabled}
                        />
                        :
                        (moment(event.end).isBefore(moment()) ? null :
                                (
                                    Boolean(zoomOAuth?.zoomOAuth) ?
                                        loading ? <CircularProgress size={25}/>
                                            :
                                            <>
                                                <IconButton className={classes.iconZoom}>
                                                    <img src={publicIcon('zoom.svg')} alt="zoom"/>
                                                </IconButton>
                                                <Button
                                                    type="primary"
                                                    shape="round"
                                                    onClick={handleCreateZoomMeeting}
                                                    disabled={!Boolean(event?.title)}
                                                >
                                                    {createZoomIntl}
                                                </Button>
                                            </>
                                        :
                                        <>
                                            <IconButton className={classes.iconZoom}>
                                                <img src={publicIcon('zoom.svg')} alt="zoom"/>
                                            </IconButton>
                                            <Button
                                                shape="round"
                                                type="primary"
                                                onClick={handleConnectZoomOAuth}
                                            >
                                                {loginZoom}
                                            </Button>
                                        </>
                                )
                        )

                }

            </div>
        );
    else
        return null;
}

export default ZoomButton;
