export default ({title, sender, link, content}) => {
    return `<!DOCTYPE html>
<html lang="ja">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,500;1,100;1,400&display=swap"
          rel="stylesheet">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style>
        @media screen and (min-width:768px) {
            .container {
            display: block;
            width: 799px;
            margin: auto;
            /*font-family: 'Montserrat', sans-serif;*/
            background-color: #EAF7FF;
            border-radius: 5px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .header {
            text-align: center;
            margin: auto;
            width: 40%;
        }

        .bg_invite {
            display: inline-flex;
        }

        .title_invite {
            text-align: start;
            /*font-family: 'Montserrat', sans-serif;*/
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 29px;
            text-transform: uppercase;
            color: #1790FF;
            padding-top: 20px;
            margin: 0px;
        }

        .icon_title {
            padding-right: 5px;
            padding-top: 18px;
            width: 28px;
        }

        .group_name {
            /*font-family: 'Montserrat', sans-serif;*/
            font-size: 14px;
            line-height: 17px;
            color: #696969;
            margin-bottom: 5px;
        }

        .title_sender {
            /*font-family: 'Montserrat', sans-serif;*/
            font-size: 14px;
            line-height: 17px;
            color: #696969;
            margin-bottom: 15px;
            margin-top: 0px;
        }

        .hint_click_button {
            margin-top: 60px;
            display: block;
            vertical-align: bottom;
            text-align: center;
            /*font-family: 'Montserrat', sans-serif;*/
            font-size: 14px;
            color: #1790FF;
            font-weight: 400;
            line-height: 17px;
        }

        .title_body_0 {
            /*font-family: 'Montserrat', sans-serif;*/
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: #000000;
            margin: 0px;
        }

        .title_sender_0 {
            /*font-family: 'Montserrat', sans-serif;*/
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
            color: #1790FF;
            margin: 0px;
        }

        .body_description {
            box-sizing: border-box;
            padding:2px 5px 2px 5px;
            width: 100%;
            display: block;
            background-color: #FFFFFF;
            border-radius: 5px;
            text-align: center;
        }

        .content_description {
            margin: 10px;
            text-overflow: clip;
            word-break: break-all;
            flex-wrap: wrap;
            padding-left: 5px;
            color: #444444;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: left;
        }

        .btn {
            display: block;
            height: 41px;
            margin: auto;
        }

        .btn a {
            height: 38px;
            width: 204px;
            text-decoration: none;
            color: #FFFFFF;
            font-weight: bold;
            display: block;
            margin: auto;
            background: #1790FF;
            border-radius: 5px;
            text-align: center;
            line-height: 38px;
        }

        .footer_invite_1 {
            margin-top: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .p_footer {
            /*font-family: 'Montserrat', sans-serif;*/
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: #1790FF;
            margin-top: 10px;
        }

        .copyright {
            margin-left: auto;
            margin-right: auto;
            display: block;
            /*font-family: 'Montserrat', sans-serif;*/
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #757575;
            width: 200px;
            padding-left: 30px;
            padding-bottom: 10px;
        }

        .img_footer {
            width: 28px;
            padding: 5px;
        }
       }
        @media screen and (max-width:600px) {
            .container {
                display: block;
                width: 80%;
                margin: auto;
                /*font-family: 'Montserrat', sans-serif;*/
                background-color: #EAF7FF;
                border-radius: 5px;
                padding-left: 20px;
                padding-right: 20px;
            }

            .header {
                text-align: center;
                margin: auto;
                width: 40%;
            }

            .bg_invite {
                display: inline-flex;
            }

            .title_invite {
                width: 100px;
                text-align: start;
                /*font-family: 'Montserrat', sans-serif;*/
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 29px;
                text-transform: uppercase;
                color: #1790FF;
                padding-top: 20px;
                margin: 0px;
            }

            .icon_title {
                padding-right: 5px;
                padding-top: 18px;
                width: 28px;
            }

            .group_name {
                /*font-family: 'Montserrat', sans-serif;*/
                font-size: 12px;
                line-height: 17px;
                color: #696969;
                margin-bottom: 5px;
            }

            .title_sender {
                /*font-family: 'Montserrat', sans-serif;*/
                font-size: 12px;
                line-height: 17px;
                color: #696969;
                margin-bottom: 15px;
                margin-top: 0px;
            }

            .hint_click_button {
                margin-top: 40px;
                display: block;
                vertical-align: bottom;
                text-align: center;
                /*font-family: 'Montserrat', sans-serif;*/
                font-size: 14px;
                color: #1790FF;
                font-weight: 400;
                line-height: 17px;
            }

            .title_body_0 {
                /*font-family: 'Montserrat', sans-serif;*/
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 17px;
                text-transform: uppercase;
                color: #000000;
                margin: 0px;
            }

            .title_sender_0 {
                /*font-family: 'Montserrat', sans-serif;*/
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 17px;
                text-transform: capitalize;
                color: #1790FF;
                margin: 0px;
            }

            .body_description {
                box-sizing: border-box;
                padding:2px 5px 2px 5px;
                width: 100%;
                display: block;
                background-color: #FFFFFF;
                border-radius: 5px;
                text-align: center;
            }

            .content_description {
                margin: 6px;
                text-overflow: clip;
                word-break: break-all;
                flex-wrap: wrap;
                padding-left: 5px;
                color: #444444;
                font-weight: 500;
                font-size: 12px;
                line-height: 17px;
                text-align: left;
            }

            .btn {
                display: block;
                height: 38px;
                margin: auto;
            }

            .btn a {
                height: 30px;
                width: 160px;
                text-decoration: none;
                color: #FFFFFF;
                font-weight: bold;
                display: block;
                margin-left: auto;
                margin-right: auto;
                background: #1790FF;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                padding-top: 7px;
            }

            .footer_invite_1 {
                margin-top: 20px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
            }

            .p_footer {
                /*font-family: 'Montserrat', sans-serif;*/
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 17px;
                text-transform: uppercase;
                color: #1790FF;
                margin-top: 10px;
            }

            .copyright {
                margin-left: auto;
                margin-right: auto;
                display: block;
                /*font-family: 'Montserrat', sans-serif;*/
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #757575;
                width: 200px;
                padding-left: 50px;
                padding-bottom: 10px;
            }

            .img_footer {
                width: 28px;
                padding: 5px;
            }
        }
    </style>
    <title>ご注文の確認</title></head>
<body>
<div class="container">
    <div class="header">
        <div class="bg_invite">
            <img class="icon_title" src="https://firebasestorage.googleapis.com/v0/b/geniam-c8d4c.appspot.com/o/calenview-logo.png?alt=media&token=0e1d2e9a-2159-45bf-a42e-64ce651ac6da" alt="favicon">
            <h2 class="title_invite">CALENVIEW EVENT</h2>
        </div>
    </div>
    <p class="group_name">Group: <span class="title_body_0">${title}</span></p>
    <p class="title_sender">From: <span class="title_sender_0">${sender}</span></p>
    <div class="body_description">
        <div class="content_description">${content}</div>
    </div>
    <p class="hint_click_button">Click the button to access event</p>
    <div class="btn"><a href="${link}">Go To Event</a>
    </div>
    <div class="header">
        <div class="footer_invite_1"><img class="img_footer"
                                          src="https://firebasestorage.googleapis.com/v0/b/geniam-c8d4c.appspot.com/o/Group.png?alt=media&token=a6b07d2e-7937-4749-aa4d-4b576ebd39e4"
                                          alt="favicon"> <span class="p_footer">Geniam</span></div>
    </div>
    <span class="copyright">© 2021 Andom.inc All Rights Reserved.</span>
</div>
</body>
</html>
`
}
