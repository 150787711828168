import {Grid, IconButton} from '@material-ui/core'
import {Settings} from '@material-ui/icons'
import React from "react"
import {makeStyles} from "@material-ui/core/styles";
import CalendarIcon from "../Calendar/CalendarIcon";
import AddCalendar from "../AddGroupCalendar/AddCalendar";
import GroupCalendar from "../GroupCalendar/GroupCalendar";
import GoogleProject from "../GroupCalendar/GoogleProject";
import useIntlId from "../../hooks/useIntlId";
import GoogleCalendar from '../MenuAppBar/Views/GoogleCalendar';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {userProxy} from '../../store/proxy/user.proxy';

const useStyles = makeStyles(theme => ({
    root: {
        // position:'fixed',
        overflow: 'auto',
        padding: '0px 0 5px 0',
        "@media (max-width: 960px)": {
            height: '88vh',
        }
    },
    divSetting: {
        position: 'relative',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "8px 0 0 0",
        marginRight: 5,
        background: "#f1f1f1",
        borderRadius: "10px 10px 0 0",
        padding: "10px 10px 0 10px"
    },
    divGrid: {
        width: '100%'
    },
    UserEmail: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: "bold"

    },
    iconSetting: {
        padding: 10
    },
    name: {
        maxWidth: "calc(100% - 38px)",
        fontWeight: "bold"
    },
    test: {
        margin: '10px 0 0 10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
        display: 'flex',
        flexDirection: 'column',
        // "@media (max-height: 900px)": {
        //     maxHeight: '450px',
        // },
        // "@media (max-height: 800px)": {
        //     maxHeight: '310px',
        // },
        // "@media (max-height: 700px)": {
        //     maxHeight: '220px',
        // },
        // "@media (max-height: 600px)": {
        //     maxHeight: '140px',
        // }
    },
    test2: {
        margin: '10px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
        display: 'flex',
        flexDirection: 'column',
        // "@media (max-height: 900px)": {
        //     maxHeight: '400px',
        // },
        // "@media (max-height: 800px)": {
        //     maxHeight: '295px',
        // },
        // "@media (max-height: 700px)": {
        //     maxHeight: '170px',
        // },
        // "@media (max-height: 600px)": {
        //     maxHeight: '120px',
        // }
    },
    childTest: {
        marginTop: 'auto'
    },
    textBtn: {
        fontWeight: "bold",
        fontSize: 16,
        textAlign: "start",
        marginLeft: 10,
        marginTop: 10,
        textTransform: "uppercase",
        cursor: "pointer"
    },
    box: {
        margin: '20px 10px 0 10px',
        padding: 10,
        color: '#fff',
        display: 'flex',
        fontSize: 17,
        background: `${theme.props.primaryColor} !important`,
        alignItems: 'center',
        fontWeight: 'bold',
        lineHeight: 1.1,
        borderRadius: 15,
        flexDirection: 'column',
        textTransform: 'uppercase',
        justifyContent: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
    },
    btnBox: {
        display: "flex",
        flexDirection: 'row',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        width: '100%',
        maxWidth: 200,
        padding: 5,
        borderRadius: 15,
        fontSize: 14,
        fontWeight: 'bold',
        margin: 5,
        background: '#fff !important',
        border: 'none',
        color: `${theme.props.primaryColor} !important`,
        transition: "all .5s ease",
        "&:hover": {
            cursor: 'pointer',
            background: '#fff',
            color: `${theme.props.primaryColor}`,
            transform: "scale(1.06)",
            textShadow: 'none'
        }
    }

}))

function LeftHomeApp() {
    const classes = useStyles()
    const {currentTheme} = useSnapshot(themesProxy)
    const {googleAuth} = useSnapshot(userProxy)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const [googleSyncSetting, googleSyncSettingTitleIntl] = useIntlId(['leftHomeApp.GoogleSync', 'leftHomeApp.GoogleSyncTitle'])
    const onOpenSetting = React.useCallback(() => {
        handleOpen(2)
    }, [])

    return (
        <Grid className={classes.root}>
            {
                currentTheme?.calendarLeftView ?
                    <GoogleCalendar/>
                    :
                    <CalendarIcon/>
            }
            <AddCalendar/>

            <div className={classes.test}>
                <div className={classes.childTest}>
                    <GroupCalendar/>
                    {
                        !googleAuth &&
                        <div className={classes.box}>
                            {googleSyncSettingTitleIntl}
                            <div className={classes.btnBox} onClick={onOpenSetting}>
                                {googleSyncSetting}
                            </div>
                        </div>
                    }

                    {
                        googleAuth &&
                        <div className={classes.divSetting}>
                            <div className={classes.name}>
                                <div>{googleAuth.name}</div>
                                <div
                                    className={classes.UserEmail}>({googleAuth.email})
                                </div>

                            </div>
                            <IconButton className={classes.iconSetting} disableRipple={true} onClick={onOpenSetting}
                                        id={'google-calendar-list-setting'}>
                                <Settings/>
                            </IconButton>
                        </div>
                    }
                    {
                        googleAuth &&
                        <GoogleProject/>
                    }
                </div>
            </div>
        </Grid>
    )
}

export default React.memo(LeftHomeApp)
