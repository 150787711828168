import {getGlobal} from 'reactn'
import moment from "moment";
import {snapshot} from 'valtio';
import {eventsProxy} from '../../../store/proxy/events.proxy';


export const isVoteTime = (start, end) => {
    let result = false
    const {voteEvents} = snapshot(eventsProxy)
    if (voteEvents.length === 0)
        return result
    voteEvents.forEach(evt => {
        if (moment(start).isBetween(evt.start, evt.end,) || moment(end).isBetween(evt.start, evt.end)) {
            result = true
        }
    })
    return result
}
