import React, {memo, Suspense} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, LinearProgress, List, Tab, Tabs} from '@material-ui/core';
import DisplayTimeSetting from './ListSetting/TimeSetting/DisplayTimeSetting';
import ProjectSettings from './ProjectSettings';
import CalendarEventSetting from './CalendarEventSetting';
import SyncSetting from './googleSync';
import IdentitySettings from './IdentitySettings';
import VideoConferencing from './VideoConferencing';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogCustom from '../Custom/DialogCustom';
import useIntlId from '../../hooks/useIntlId';
import AnnouncementBar from './AnnouncementBar/AnnouncementBar';
import AnnouncementBoard from './AnnouncementBoard/AnnouncementBoard';
import NavigationSettingTab from './Navigation/NavigationSettingTab';
import HighLightBar from './HighLightBar/HighLightBar';
import RemindBar from './RemindBar/RemindBar';
import {HX_CLOUD_DOMAIN} from '../../config/constants';
import CalenviewPlan from './CalenviewPlan';
import {RightOutlined} from '@ant-design/icons';
import MilestoneBar from './Milestone';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';

const styles = theme => ({
    rootLeft: {
        width: '100%',
        maxWidth: 350,
        padding: '0 12px 0 5px',
        borderRadius: "25px"
    },
    nested: {
        paddingLeft: theme.spacing(4),
        borderTopRightRadius: ' 40px',
        borderBottomRightRadius: '40px',
        backgroundColor: '#E3F2FD'
    },
    root: {
        zIndex: "1300 !important",
        '& .MuiPaper-root': {
            // paddingBottom: "15px",
            boxSizing: "content-box",
            overflow: "unset",
        },
        '& .MuiDialogTitle-root': {
            padding: 3,
            textAlign: "right",
        },
        "& .MuiDialog-paperFullWidth": {
            minWidth: 967,
            maxWidth: "auto",
            height: "calc(100% - 64px) !important",
            borderRadius: 15,
        },
        ' & .ListSetting-root-1176': {
            zIndex: 99
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "auto",
            }
        }
    },
    root2: {
        zIndex: 999,
        height: "100%",
        overflowY: "auto",
    },
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    textTabs: {
        position: 'relative',
        '& > span': {
            alignItems: 'flex-start !important'
        },
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: "bold",
        color: theme.props.primaryColor,
        lineHeight: 1.2,
        minHeight: "unset",
        minWidth: "unset",
        height: 42,
        // marginBottom: 4,
        borderBottom: '1px solid #f1f1f1'
    },
    tabIcon: {
        position: 'absolute',
        right: 6
    },
    hideTabIcon: {
        position: 'absolute',
        right: 6,
        display: 'none'
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    selected: {
        background: theme.props.primaryColor,
        color: "#fff",
        borderRadius: "10px"
    },
    header: {
        position: "relative",
        lineHeight: "42px",
        display: "block",
        width: "100%",
        fontSize: "1.7rem"
    },

    displayTime: {
        display: 'flex'
    },
    displayTypo: {
        lineHeight: '40px', margin: 0
    },
    divider: {
        margin: "15px 10px 15px 0px", height: 3
    },
    iconAdd: {
        marginLeft: '50%', fontSize: 30,
        marginTop: 10
    },
    settingBox: {
        width: '99%',
        border: '3px solid black',
        padding: '5px',
        margin: '5px',
        display: 'flex'
    },
    boxLeft: {
        width: '35%'
    },
    boxRight: {
        width: '75%'
    },
    divTime: {
        display: 'flex',
        padding: 5,
    },
    divTimeText: {
        fontWeight: "bold",
        width: '12.5%'
    },
    divTimeValue: {
        margin: '0 7px',
        minWidth: '30px',
        fontWeight: 700,
    },
    divTimeCancel: {
        fontWeight: 'bold',
        color: 'rgb(248, 147, 32)',
        display: 'flex',
        padding: 5,
        lineHeight: '30px'
    },
    divTimeCancelValue: {
        fontWeight: 700,
        color: 'rgb(248, 147, 32)',
        // marginRight: 10,
        letterSpacing: 0,
        whiteSpace: 'nowrap'
    },
    addDayOff: {
        border: "1px solid rgb(248, 147, 32)",
        color: 'rgb(248, 147, 32)',
        marginLeft: 'auto',
        height: "30px",
        lineHeight: '15px',
        borderRadius: 'inherit',
        minWidth: 75
    },
    buttonDelete: {
        color: 'rgb(248, 147, 32)',
        marginLeft: 'auto',
        height: "30px",
        lineHeight: '15px',
        borderRadius: 'inherit'
    },
    divExclude: {
        fontWeight: 'bold',
        display: 'flex',
        padding: 5,
        lineHeight: '40px'
    },
    remove: {
        padding: 10
    },
    removeDiv: {
        textAlight: 'center',
        padding: 10
    },
    removeBtn: {
        margin: 5
    },
    tabBox: {
        alignItems: 'flex-start !important',
        background: "#fff",
        borderRadius: "15px",
        padding: 6,
    },
    paperContent: {
        background: "#f1f1f1",
        borderRadius: "25px",
        // padding: 15,
    },
    containerPopup: {
        borderRadius: '10px',
        background: "#f1f1f1",
        minWidth: "967px",
        height: "100%",
        padding: "15px 20px 10px 20px",
        overflowY: "auto"
    },
    buttonLink: {
        "&:hover": {
            background: "#fff"
        },
        background: '#fff',
        position: 'absolute',
        bottom: 20,
        left: 40,
        maxWidth: 185,
    },
    gridSetting: {
        display: 'flex',
    },
    titleSetting: {
        fontSize: 24,
        fontWeight: 700,
        marginLeft: 6,
        marginBottom: 15,
        textTransform: 'uppercase',
        color: theme.props.primaryColor,
    }

});

function ListSetting(props) {
    const {classes} = props;
    const {open, handleClose, handleOpen, tab} = useSnapshot(settingDialogProxy)
    const [
        videoConfIntl,
        projectsIntl,
        idIntl,
        highLightBarIntl,
        announcementBoardIntl,
        workingHours,
        eventsSetting,
        googleSync,
        globalNavi,
        // highLight
        linkCalendar,
        listTabAnnouceBar,
        listTabRemindBar,
        plan,
        milestone,
        setting
    ] = useIntlId([
        'app.profile.videoconferencing',
        'app.profile.projects',
        'app.profile.identities',
        'settings.highLightBar',
        'settings.announcementBoard',
        'settings.workingHours',
        'settings.eventsSetting',
        'settings.googleSync',
        'settings.tab.globalNavi',
        'settings.tab.linkCalendar',
        'setting.listTab.annouceBar',
        'setting.listTab.remindBar',
        'setting.listTab.plan',
        'setting.listTab.milestone',
        "settings.title"
    ])

    const handleChange = (_, activeIndex) => {
        handleOpen(activeIndex)
    };


    const linkToCalendar = () => {
        window.open(`${HX_CLOUD_DOMAIN}/calenview`, "_blank")
    };

    return (
        <div>
            <DialogCustom
                open={open}
                onClose={handleClose}
                id={"dialogCustom"}
                fullWidth={true}
                maxWidth={"lg"}
                className={classes.root}

            >
                <Suspense fallback={<LinearProgress/>}>
                    <div className={classes.root2}>
                        <div className={classes.containerPopup}>
                            <div className={classes.titleSetting}>{setting}</div>
                            <div className={classes.gridSetting}>
                                <Grid item xs={3}>
                                    <div className={classes.paper}>
                                        <List
                                            component="nav"
                                            className={classes.rootLeft}
                                        >
                                            <VerticalTabs
                                                value={tab}
                                                onChange={handleChange}
                                                className={classes.tabBox}
                                            >
                                                {
                                                    [workingHours, eventsSetting, googleSync, videoConfIntl, projectsIntl, idIntl, listTabAnnouceBar, highLightBarIntl, listTabRemindBar, announcementBoardIntl, milestone, globalNavi, plan].map((item, index) => (
                                                        <Tab
                                                            icon={<RightOutlined
                                                                className={tab === index ? classes.tabIcon : classes.hideTabIcon}/>}
                                                            className={`${classes.textTabs + ' ' + (tab === index ? classes.selected : null)} fontSourceHanSans`}
                                                            label={item}
                                                            key={index}
                                                        />
                                                    ))
                                                }

                                            </VerticalTabs>
                                        </List>
                                        <Grid align="center" className={classes.rootLeft}
                                              style={{alignSelf: 'flex-end'}}>
                                            <Button
                                                className={`${classes.textTabs} ${classes.buttonLink} fontSourceHanSans`}
                                                onClick={linkToCalendar}>{linkCalendar}</Button>
                                        </Grid>
                                    </div>

                                </Grid>
                                <Grid item xs={9}>
                                    <div className={`${classes.paper} ${classes.paperContent}`}>
                                        {tab === 0 &&
                                            <DisplayTimeSetting/>
                                        }
                                        {tab === 1 &&
                                            <CalendarEventSetting/>}
                                        {tab === 2 &&
                                            <SyncSetting/>}
                                        {tab === 3 &&
                                            <VideoConferencing/>}
                                        {tab === 4 &&
                                            <ProjectSettings/>}
                                        {tab === 5 &&
                                            <IdentitySettings/>}
                                        {tab === 6 &&
                                            <AnnouncementBar/>}

                                        {tab === 7 &&
                                            <HighLightBar/>}
                                        {tab === 8 &&
                                            <RemindBar/>
                                        }
                                        {tab === 9 &&
                                            <AnnouncementBoard/>
                                        }
                                        {tab === 10 &&
                                            <MilestoneBar/>
                                        }
                                        {tab === 11 &&
                                            <NavigationSettingTab/>
                                        }
                                        {tab === 12 &&
                                            <CalenviewPlan/>
                                        }

                                    </div>
                                </Grid>
                            </div>
                        </div>

                    </div>
                </Suspense>
            </DialogCustom>
        </div>
    )
}

const VerticalTabs = withStyles({
    flexContainer: {
        flexDirection: 'column'
    },
    indicator: {
        display: 'none',
    }
})(Tabs)

// function TabContainer(props) {
//     return (
//         <Typography component="div" style={{padding: 20, textAlign: 'left'}}>
//             {props.children}
//         </Typography>
//     );
// }

export default memo(withStyles(styles)(ListSetting))
