import {isArray, isEmpty, isString} from 'lodash';
import store from 'store';
import {myInfo} from '../common/firebaseRef/calendar';
import {useContext} from 'react';
import {LanguageMessageContext} from '../context/LanguageMessageContext';

/**
 * @param {string|array} id
 * @param values
 */

const useIntlId = (id, values) => {

    const intl = useContext(LanguageMessageContext)
    // console.log(id)
    if (isEmpty(intl))
        return id
    return isArray(id) ?
        id.map(i => intl[i])
        : intl[id]
}

export default useIntlId

const getIntlId = (id) => {
    const language = myInfo('language')
    if (!id)
        return language

    const langMsg = store.get('langMsg')?.[language]
    if (!langMsg) return;

    const getOneIntl = (id) => {
        return langMsg[id]
    }
    if (isString(id))
        return getOneIntl(id)
    if (isArray(id))
        return id.map(i => getOneIntl(i));
}

const isJAJP = () => getIntlId() === 'ja-jp'
const isVIVN = () => getIntlId() === 'vi-vn'
const isENUS = () => getIntlId() === 'en-us'
const getUserLang = () => getIntlId()

export {
    getIntlId,
    isJAJP,
    isVIVN,
    isENUS,
    getUserLang
}
