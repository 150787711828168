import {useEffect} from 'react';
import {getVoteEvents} from '../components/Calendar/actions/syncEventMeeting';
import {isFunction} from 'lodash';
import {useSnapshot} from 'valtio';
import {eventsProxy} from '../store/proxy/events.proxy';

export const useVoteEvents = () => {
    const {setVoteEvents} = useSnapshot(eventsProxy)
    useEffect(() => {
        const votesub = getVoteEvents((querySnapshot) => {
            let events = []
            querySnapshot.forEach(function (doc) {
                if (!doc.data().isSync) {
                    let evt = {
                        ...doc.data(),
                        id: doc.id,
                        // holiday: true,
                        busy: true,
                        isEventVote: doc.data().isEventVote || false,
                        isAccepted: doc.data().isAccepted || false,
                        editable: !(doc.data().meetingId && !doc.data().isAccepted),
                    }
                    events.push(evt);
                }
            });
            setVoteEvents(events)
        })
        return () => {
            if (isFunction(votesub))
                votesub()
        }
    }, [])
}
