import styled from 'styled-components'

const CalendarContainer = styled.div(props => ({
    background: "#fff",
    borderRadius: "10px",
    // overflowY: "auto",
    height: "100%",
    position: "relative",
    '&::-webkit-scrollbar': {
        display: "none",
    },
    minHeight: props.height
}))

export default CalendarContainer
