import {concat, remove, uniqBy} from 'lodash'
import {getGlobal} from 'reactn'
import moment from "moment";
import {getEventsFromReccrence} from "./getGoogleEvents";
import {GCalendarEventFormat} from "../../actions/googleCalendar";
import getEventsInRange from "./getEventsInRange";
import {snapshot} from 'valtio';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {themesProxy} from '../../store/proxy/themes.proxy';

export const getWeekEvents = (events = {}, start, end) => {
    const {allProject} = getGlobal();
    let result = []

    allProject.forEach(pro => {
        if (!pro.selected)
            return

        result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end, pro, true))
    })

    return uniqBy(result, "id")
}

export const getSelectedEvents = (events = {}, numberId = 0) => {
    const {allProject, refetchDateNow} = getGlobal()
    let result = []
    const {start, end} = getDateRangeOfCal(refetchDateNow, numberId)
    allProject.forEach(pro => {
        if (!pro.is_google) {
            result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end))
        } else {
            let totalEvents = [...(events[pro.id]?.events || [])]
            const recurrenceEvents = remove(totalEvents, e => e.recurrence)
            const recurenceEdited = remove(totalEvents, e => e.recurringEventId)
            const recurring = getEventsFromReccrence(recurrenceEvents, recurenceEdited, end)
            const isHoliday = pro.accessRole === "reader" || pro.accessRole === "freeBusyReader"
            const formatedItems = GCalendarEventFormat(concat(totalEvents, recurring), pro.id, pro.backgroundColor, isHoliday)
            result = concat(result, getEventsInRange(formatedItems, start, end))
        }

    })
    return uniqBy(result, "id")
}
export const getSelectedEventsAllTime = (events = {}, timeMax) => {
    const {allProjects} = snapshot(projectsProxy)
    let result = []
    allProjects.forEach(pro => {
        if (!pro.is_google) {
            result = concat(result, events[pro.id]?.events || [])
        } else {
            let totalEvents = [...(events[pro.id]?.events || [])]
            const recurrenceEvents = remove(totalEvents, e => e.recurrence)
            const recurenceEdited = remove(totalEvents, e => e.recurringEventId)
            const recurring = getEventsFromReccrence(recurrenceEvents, recurenceEdited, timeMax)
            const isHoliday = pro.accessRole === "reader" || pro.accessRole === "freeBusyReader"
            const formatedItems = GCalendarEventFormat(concat(totalEvents, recurring), pro.id, pro.backgroundColor, isHoliday)
            result = concat(result, formatedItems)
        }

    })
    return uniqBy(result, "id")
}

export const getDateRangeOfCal = (date, numOfCal) => {
    const type = getType()
    const start = moment(date).add(numOfCal, type).startOf(type).utc().format()
    const end = moment(start).add(numOfCal, type).endOf(type).utc().format()
    return {
        start,
        end
    }
}

const getType = () => {
    const {view} = snapshot(themesProxy);

    let type
    switch (view) {
        case "CustomWeek":
            type = "week"
            break
        case "CustomDay":
            type = "day"
            break
        default:
            type = "month"
    }
    return type
}
