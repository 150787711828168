import {getGlobal, setGlobal} from 'reactn'
import {findIndex, remove, uniqBy,} from "lodash";


export const updateColor = (uuid, color) => {
    const {groups} = getGlobal()
    let idx = findIndex(groups, {'uuid': uuid})
    if (idx !== -1 && groups[idx].color !== color) {
        groups[idx].color = color
        setGlobal({groups: [...groups]})
    }
}
export const updateGroupName = (uuid, name) => {
    const {groups} = getGlobal()
    let idx = findIndex(groups, {'uuid': uuid})
    if (idx !== -1 && groups[idx].name !== name) {
        groups[idx].name = name
        setGlobal({groups: [...groups]})
    }
}

export const AddGroups = (group) => {
    const {groups} = getGlobal()
    let nextGroups = [...groups]
    nextGroups.push(group)
    setGlobal({groups: uniqBy(nextGroups, 'uuid')})
}


export const UpdateSync = (uuid, sync_id, sync_email, cal) => {
    const {projects, calendarSelected, googleCalendarList} = getGlobal()
    let nextGroups = [...projects]
    let index = findIndex(nextGroups, {'uuid': uuid})
    if (index !== -1) {
        nextGroups[index] = {...nextGroups[index], is_sync: true, sync_id, sync_email}
        calendarSelected.push(cal)
        googleCalendarList.unshift({...cal, checked: false})
        setGlobal({projects: nextGroups, calendarSelected: [...calendarSelected], googleCalendarList})
        localStorage.setItem('calendarSelected', JSON.stringify(calendarSelected))
    }
}
export const RemoveGroup = uuid => {
    const {groups, GChannel} = getGlobal()
    let nextGroups = [...groups]
    remove(nextGroups, g => {
        return g.uuid === uuid
    })
    setGlobal({groups: nextGroups})
    remove(GChannel, g => g.calendar_id === uuid)
    setGlobal({GChannel: [...GChannel]})
    localStorage.setItem('GChannel', JSON.stringify([...GChannel]))
}

export const isReadOnly = (group) => {
    return !!(group.accessRole && group.accessRole === 'reader');

}
