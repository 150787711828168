import {proxy} from 'valtio/vanilla';
import moment from 'moment'
import isEqual from 'react-fast-compare';
import {snapshot} from 'valtio';
import {themesProxy} from './themes.proxy';
import {getGlobal} from 'reactn';
import {getTimeRangeMinMax} from '../../common/calendarView/getTimeRange';

const calenviewTimeRangeProxy = proxy({
    start: null,
    end: null,
    currentTime: {
        start: null,
        end: null
    },
    setTime: ({start, end}) => {
        if (!calenviewTimeRangeProxy.start || moment(start).isBefore(calenviewTimeRangeProxy.start))
            calenviewTimeRangeProxy.start = start
        if (!calenviewTimeRangeProxy.end || moment(end).isAfter(calenviewTimeRangeProxy.end))
            calenviewTimeRangeProxy.end = end
        if (!isEqual({start, end}, calenviewTimeRangeProxy.currentTime))
            calenviewTimeRangeProxy.currentTime = {
                start, end
            }
    },
    refreshTime: () => {
        const {currentTheme} = snapshot(themesProxy)
        const {refetchDateNow} = getGlobal()
        calenviewTimeRangeProxy.setTime(getTimeRangeMinMax(refetchDateNow, currentTheme))
    }
})

export {
    calenviewTimeRangeProxy
}
