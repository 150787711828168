import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import {ReactComponent as NewSVG} from "../../asSvg/new.svg";
import commonStyles from './commonStyles';

export default function AddButton({onClick, ...others}) {
    const classes = commonStyles()

    return (
        <CLFButtonSVG
            minHeight={"31px"}
            iconComponent={<NewSVG/>}
            className={classes.primary}
            onClick={onClick}
            {...others}
        />
    )
}
