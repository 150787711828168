import tinycolor from 'tinycolor2';
import {getIcon, getTaskUrl, isImageTask, isMaterialIcon} from './getIcon';
import {onChecked} from './toggleSuccess';
import moment from 'moment';
import {isAllDay, isHoliday, isMileStone, isSuccess, publicIcon, task} from './infoEvent';
import isURL from 'validator/es/lib/isURL';
import getExtendedProps from '../../../common/getExtendedProps';
import {iconNameList} from '../../../common/muiIcons';
import {addClassSlider, nextSlider} from '../../../common/sliderIcons';
import {snapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';

export const eventContent = (info, classes, numberId, focusColor) => {
    const {currentTheme} = snapshot(themesProxy)
    let showTitle = currentTheme?.showTitle
    if (typeof showTitle === "undefined") {
        showTitle = true
    }
    const event = info.event;
    const location = getExtendedProps(info, 'location')
    const taskInfo = getExtendedProps(info, 'task')
    const link = getExtendedProps(info, 'conferenceData.entryPoints[0].uri') || getExtendedProps(info, 'conferenceData.start_url')
    let duration = (moment(event.end).utc() - moment(event.start).utc()) / (60000 * 60)
    let checkAllDay = isAllDay(event)
    let checkBackgroundColor = tinycolor(event.backgroundColor).getLuminance() < 0.6
    const content = document.createElement('div')
    content.id = `event-${numberId}-${event.id}`
    content.className = `${classes.events} ${
        checkAllDay ?
            'inline-flex align-middle relative pb-0.5' :
            'clf-event-render'} justify-center w-full hover:font-bold ${duration < 1 ?
        'eventRenderCustom1' :
        `${showTitle ? 'eventRenderCustom2' : 'eventRenderCustom2Height'}`}`
    // content.style.overflow = "hidden"
    content.style.opacity = 1;

    const contentIcons = document.createElement('div');
    const contentDiv = document.createElement('div');

    contentIcons.className = 'event-content-icons';

    // create title event
    {
        showTitle && !checkAllDay && content.appendChild(getTitle(event.title, classes, checkBackgroundColor, true))
    }
    const cordemiaLink = getExtendedProps(info, 'description')
    // console.log(cordemiaLink?.replace(/<[^>]+>/g, ''));
    const detectURLs = (desc) => {
        const toString = desc?.replace(/<[^>]+>/g, '')
        const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return toString?.match(urlRegex)
    }

    const url = detectURLs(cordemiaLink)?.[0];
    {
        isURL(`${url}`) && contentIcons.appendChild(getLinkIcon(url, checkBackgroundColor))
    }
    // content.appendChild(getCopyButton(info))
    // content.appendChild(getTitle(event.title, classes, checkBackgroundColor))
    // content.appendChild(getCopyButton(info))

    //get Link Location Icon
    {
        location && contentIcons.appendChild(getLocationButton(location, checkBackgroundColor))
    }
    //get Link Zoom Icon
    {
        link && contentIcons.appendChild(getLinkZoomIcon(link))
    }
    Boolean(event?.title) || content.appendChild(getDateMouseMove(info.timeText, classes, checkBackgroundColor))

    if (checkAllDay && taskInfo && taskInfo !== 'AssignmentTurnedIn' && taskInfo !== 'mileStone') {
        contentIcons.appendChild(getIconAllDay(info, classes, checkBackgroundColor, taskInfo))
    }

    if (
        (
            !checkAllDay
            && task(event) !== 'AssignmentTurnedIn'
            || event
            && isMileStone(event)
            && task(event)
        )
        && isMaterialIcon(task(event))
    ) {
        if (
            event
            && isMileStone(event)
            && task(event)
        )
            contentIcons.appendChild(getIconDay(info, classes, checkBackgroundColor, true))
        else
            contentIcons.appendChild(getIconDay(info, classes, checkBackgroundColor))
    }

    if (
        (
            !checkAllDay
            && task(event) !== 'AssignmentTurnedIn'
            || event
            && isMileStone(event)
            && task(event)
        )
        && isImageTask(task(event))
    ) {
        if (
            event
            && isMileStone(event)
            && task(event)
        )
            contentIcons.appendChild(getImageTask(info, classes, true))
        else
            contentIcons.appendChild(getImageTask(info, classes))
    }

    if ((!checkAllDay && task(event) === 'AssignmentTurnedIn')) {
        content.appendChild(getCheckBoxDay(info, numberId))
        const span = document.createElement('span')
        content.appendChild(span)
    }

    if ((event && isMileStone(event) && task(event) === "mileStone")) {
        contentIcons.appendChild(getImageTaskMilsStone(checkBackgroundColor))
    }

    if (event && task(event) === "focus" && focusColor && event?.allDay) {
        contentIcons.appendChild(getImageTaskFocus(checkBackgroundColor))
    }
    if (!checkAllDay && 'timeBefore' in getExtendedProps(info) && getExtendedProps(info, 'timeBefore') !== 0) {
        const before = getBeforeTime(getExtendedProps(info, 'timeBefore') / 60, event.backgroundColor)
        if (before !== null) {
            content.appendChild(before)
        }
    }
    if (!checkAllDay && 'timeAfter' in getExtendedProps(info) && getExtendedProps(info, 'timeAfter') !== 0) {
        const after = getAfterTime(getExtendedProps(info, 'timeAfter') / 60, event.backgroundColor)
        if (after !== null) {
            content.appendChild(after)
        }
    }

    if (contentIcons.children.length >= 2) {
        addClassSlider(contentIcons);
        nextSlider(contentIcons);
        const startTimeout = () => {
            setTimeout(() => {
                nextSlider(contentIcons);
                startTimeout();
            }, 3000)
        }
        startTimeout();
    }

    if (contentIcons.children.length >= 1) {
        content.appendChild(contentDiv);
        content.appendChild(contentIcons);
    }
// create title all day
    {
        showTitle && checkAllDay && content.appendChild(getTitle(event.title, classes, checkBackgroundColor))
    }
    return {domNodes: [content]}
}

const getBeforeTime = (hours = 0.5, color) => {
    const hourHeight = document.querySelector('.fc-timegrid-slot')?.offsetHeight
    if (!hourHeight)
        return null
    const div = document.createElement('div')
    const timeHeight = hourHeight * hours
    div.style.height = timeHeight + "px"
    div.style.minHeight = timeHeight + "px"
    div.style.width = 'calc(100% + 7px)'
    div.style.backgroundColor = color
    div.style.opacity = 0.5
    div.style.position = "absolute"
    div.style.top = 0
    div.style.transform = `translateY(-${timeHeight + 3}px)`
    return div
}

const getAfterTime = (hours = 0.5, color) => {
    const hourHeight = document.querySelector('.fc-timegrid-slot')?.offsetHeight
    if (!hourHeight)
        return null
    const div = document.createElement('div')
    const timeHeight = hourHeight * hours
    div.style.height = timeHeight + "px"
    div.style.minHeight = timeHeight + "px"
    div.style.width = 'calc(100% + 7px)'
    div.style.backgroundColor = color
    div.style.opacity = 0.5
    div.style.position = "absolute"
    div.style.bottom = 0
    div.style.transform = `translateY(${timeHeight + 3}px)`
    return div
}

const getImageTaskMilsStone = (isDark) => {
    const imageDiv = document.createElement("div")
    imageDiv.className = 'eventRenderMile'
    const imageContainer = document.createElement('div')
    imageContainer.style = {
        display: "flex",
        width: "18px",
        height: "18px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        cursor: "pointer"
    }
    const image = document.createElement('img')
    image.src = isDark ? publicIcon('iconMilestoneLight.svg') : publicIcon('iconMilestone.svg')
    image.className = 'taskImgEvt'
    imageContainer.appendChild(image)
    imageDiv.appendChild(imageContainer)
    return imageDiv
}

const getImageTaskFocus = (isDark) => {
    const imageDiv = document.createElement("div")
    imageDiv.className = 'eventRenderMile'
    const imageContainer = document.createElement('div')
    imageContainer.style = {
        display: "flex",
        width: "18px",
        height: "18px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        cursor: "pointer"
    }
    const image = document.createElement('img')
    image.src = isDark ? publicIcon('iconFocusLight.svg') : publicIcon('iconFocus.svg')
    image.className = 'taskImgEvt'
    imageContainer.appendChild(image)
    imageDiv.appendChild(imageContainer)
    return imageDiv
}

const getTitle = (title, classes, isDark, notAllDay) => {
    const {currentTheme} = snapshot(themesProxy)
    let fontSize = currentTheme?.size || 9

    const div = document.createElement('div')
    div.className = classes.fontSize
    div.style.fontSize = `${fontSize}px`
    div.style.width = `${notAllDay && '100%'}`
    div.style.textAlign = `${notAllDay && 'center'}`
    div.style.overflow = `hidden`
    const span = document.createElement('span')
    span.className = isDark ? "colorWhite" : "colorBlack"
    span.innerHTML = title
    span.style.height = '100%'
    div.appendChild(span)
    return div
}
//
// const checkHeight = (id, numberId) => {
//     let elHeight = document.getElementById(`event-${numberId}-${id}`)?.clientHeight
//     if (elHeight >= 100) {
//         return true;
//     } else return false
//
// }

const getIconAllDay = (info, classes, isDark, taskInfo) => {
    const checkboxDiv = document.createElement("div")
    const span = document.createElement('span')
    if (iconNameList.includes(taskInfo)) {
        span.className = isDark ? "material-icons colorWhite scale-75 -top-1 !text-2xl absolute -ml-1" : " material-icons colorBlack scale-75 -top-1 !text-2xl absolute -ml-1"

        span.innerHTML = getIcon(taskInfo, isDark ? "#fff" : "#000")
    } else if (getTaskUrl(task(info.event))) {
        span.className = isDark ? "material-icons colorWhite scale-7 right-0 absolute" : " material-icons colorBlack scale-7 right-0 absolute"
        const image = document.createElement('img')
        image.src = getTaskUrl(task(info.event))
        image.className = 'taskImgEvt'
        span.appendChild(image)
    }
    checkboxDiv.appendChild(span)
    return checkboxDiv
}

const getIconDay = (info, classes, isDark, isMileStone) => {
    const icon = document.createElement("div")
    icon.className = !isMileStone ? 'eventRenderCheckBox' : 'eventRenderMile'
    const span = document.createElement('span')
    span.innerHTML = getIcon(task(info.event), isDark ? "#fff" : "#000")
    span.className = isDark ? "material-icons colorWhite" : " material-icons colorBlack"
    icon.appendChild(span)
    return icon
}

const getImageTask = (info, classes, isMileStone) => {
    const imageDiv = document.createElement("div")
    imageDiv.className = !isMileStone ? 'eventRenderCheckBox' : 'eventRenderMile'
    const imageContainer = document.createElement('div')
    imageContainer.style = {
        display: "flex",
        width: "18px",
        height: "18px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        cursor: "pointer"
    }
    const image = document.createElement('img')
    image.src = getTaskUrl(task(info.event))
    image.className = 'taskImgEvt'
    imageContainer.appendChild(image)
    imageDiv.appendChild(imageContainer)
    return imageDiv
}

const getCheckBoxDay = (info, numberId) => {
    const checkbox = document.createElement('input')
    checkbox.className = 'checkBoxRenderEvent'
    checkbox.type = "checkbox"
    checkbox.checked = isSuccess(info.event)
    checkbox.onclick = (e) => {
        e.stopPropagation()
        onChecked(info, numberId)
    }
    checkbox.style.cursor = "pointer"
    checkbox.disabled = isHoliday(info.event)
    return checkbox
}
// const copyInfo = (info) => {
//     if (!info?.event)
//         return
//     copyEvent(info.event).then(() => msgSuccess(getIntlId('toast.success.calendar.copied')))
// }
// const getLinkZoom = (event) => {
//
//     const startUrl = conferenceDataStartUrl(event)
//     if (startUrl) {
//         return startUrl
//     }
//     if (conferenceData(event)?.entryPoints) {
//         let idx = findIndex(conferenceData(event).entryPoints || [], {entryPointType: "video"})
//         if (idx === -1)
//             return ''
//         return conferenceData(event).entryPoints[idx]?.uri || ''
//     }
//     return ""
// }
// const getCopyButton = (info) => {
//     const btn = document.createElement('button')
//     btn.onclick = (event) => {
//         event.stopPropagation()
//         copyInfo(info)
//     }
//     btn.className = "copyEventButton"
//     const icon = document.createElement('span')
//     icon.innerText = "content_copy"
//     icon.className = "material-icons md-14"
//     btn.appendChild(icon)
//     return btn
// }

const getSizeIcon = () => {
    const {currentTheme} = snapshot(themesProxy)
    return currentTheme?.columns > 1 || currentTheme?.rows > 1 ? '!text-sm' : '!text-[18px]'
}

const isMonthViewManyColumns = () => {
    const {currentTheme} = snapshot(themesProxy)
    return currentTheme?.columns > 1 && currentTheme?.viewCalendar === "CustomMonth" ? 'flex-col' : ''

}
const getLocationButton = (location, isDark) => {
    const btn = document.createElement('a')
    const url = 'https://www.google.com/maps/search/?api=1'
    const query = encodeURIComponent(location)
    btn.onclick = () => {
        window.open(`${url}&query=${query}`, "blank");
    }
    btn.className = "locationIcon"
    const icon = document.createElement('span')
    icon.innerText = "location_on"
    icon.className = isDark ? `material-icons ${getSizeIcon()} colorWhite` : ` material-icons ${getSizeIcon()} colorBlack`
    btn.appendChild(icon)
    return btn
}

const getLinkZoomIcon = (link) => {
    const btn = document.createElement('a')
    btn.onclick = () => {
        window.open(`${link}`, "_blank");
    }
    btn.className = "linkZoomIcon"
    const icon = document.createElement('span')
    icon.innerText = "videocam"
    icon.className = `material-icons ${getSizeIcon()}`
    btn.appendChild(icon)
    return btn
}
const getLinkIcon = (link, isDark) => {
    const btn = document.createElement('a')
    btn.onclick = () => {
        window.open(`${link}`, "_blank");
    }
    btn.className = "linkIcon"
    const icon = document.createElement('span')
    icon.innerText = "link"
    icon.className = isDark ? `material-icons ${getSizeIcon()} colorWhite` : ` material-icons ${getSizeIcon()} colorBlack`
    btn.appendChild(icon)
    return btn
}


const getDateMouseMove = (timeText, classes, isDark) => {
    const {currentTheme} = snapshot(themesProxy)
    let fontSize = currentTheme?.size || 9
    const span = document.createElement('span')
    const div = document.createElement('div')
    div.className = classes.fontSize
    div.style.fontSize = `${fontSize}px`
    div.style.width = `100%`
    div.style.textAlign = `center`
    span.style.width = '100%'
    span.className = isDark ? "colorWhite" : "colorBlack"
    span.innerText = timeText.replace("-", "~")
    div.appendChild(span)
    return div
}
