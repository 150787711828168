import React from 'react';
import {Col, Row} from "antd";
import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";
import {publicIcon} from "../../Calendar/handlers/infoEvent";
import {getNumDayAdvanceView} from '../../../common/calendarView/getNumDayAdvanceView';
import TabDate from './TabDate';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';
import {cloneDeep} from 'lodash';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

const Box = styled.div(props => ({
    backgroundColor: props?.backgroundColor ? `${props?.backgroundColor}` : "#f1f1f1",
    height: "100%",
    borderRadius: "5px",
    width: props?.width ? `${props?.width}%` : "30px",
    border: "solid 1px #fff",
    display: "inline-flex",
}));

function ListTheme({setThemeCustom, editTheme}) {
    const classes = useStyles()
    const {list} = useSnapshot(themesProxy)
    const {plan} = useSnapshot(announcementProxy)
    const newArray = (number) => {
        let newArr = []
        for (let i = 0; i < number; i++) {
            newArr.push(i)
        }
        return newArr
    }
    return (
        <div className={classes.root}>
            <Row gutter={[4, 4]}>
                {
                    list.map((i, index) => {
                        let numColumns = i?.viewCalendar === "CustomDay" ? 1 : i?.columns
                        return (
                            <Col key={index} span={6} className={`mt-4`}>
                                <div className={classes.divTabDate}>
                                    <div className={classes.divChilTabDate}></div>
                                    {(Boolean(i?.tabCalenview) || i?.tabCalenview === 0) &&
                                        <TabDate
                                            themeItem={i}
                                            sizeMini={true}
                                            position={true}
                                        />
                                    }
                                </div>
                                <div className={classes.myWorkSpace}>
                                    <div className={classes.myWorkSpaceIndex}
                                         style={{color: i?.colorPrimary || "#9B939E"}}>{i?.id}</div>

                                    <button
                                        className={classes.myWorkSpaceRow}
                                        onClick={() => {
                                            let value = cloneDeep(list[index])
                                            if (Object.keys(value).length <= 1) {
                                                value = {
                                                    ...value,
                                                    "colorPrimary": "#1790FF",
                                                    "tabCalenview": 0,
                                                    "numsDay": 1,
                                                    "rightExpansion": false,
                                                    "rangeDay": null,
                                                    "size": 9,
                                                    "showTitle": true,
                                                    "tab": 0,
                                                    "columns": 1,
                                                    "advanceView": false,
                                                    "rows": 1,
                                                    "workingRange": 17,
                                                    "calendarLeftView": false,
                                                    "indexToday": 1,
                                                    "viewCalendar": "CustomMonth",
                                                    "settingToday": false
                                                }
                                            }
                                            setThemeCustom(value)
                                        }}
                                        style={{border: editTheme?.id === i?.id ? `solid 1px #ff0000` : "solid 1px #fff"}}
                                        disabled={plan.numOfView <= index}
                                    >
                                        {
                                            i?.rows && newArray(i?.rows || 3).map((row, index) => {
                                                let height = 100 / i?.rows || 100 / 3
                                                return (
                                                    <div key={index} style={{height: `${height}%`}}>
                                                        {
                                                            newArray(numColumns).map((col, idx) => {
                                                                let width = 100 / numColumns || 100 / 3
                                                                return (
                                                                    <Box key={idx}
                                                                         backgroundColor={i?.colorPrimary || "#f1f1f1"}
                                                                         width={width}/>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            !i?.viewCalendar ?
                                                <img src={publicIcon('view/plusBig.svg')}
                                                     alt=""/>
                                                :
                                                <span className={classes.viewCalendar}>
                                                        {i?.advanceView ? `${getNumDayAdvanceView(i)}`
                                                            :
                                                            ` ${i?.viewCalendar.substr(6, 1)}`
                                                        }
                                                    </span>

                                        }
                                        {plan.numOfView <= index ?
                                            <img className={classes.imgLock} src="/icons/iconKey.svg" alt=""/> : ''}
                                    </button>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    );
}

export default ListTheme;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        padding: "10px 10px 5px 10px",
        borderRadius: "10px",
        marginTop: 10
    },
    myWorkSpace: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 5,
    },
    myWorkSpaceIndex: {
        width: 50,
        fontWeight: 700,
        fontSize: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    myWorkSpaceRow: {
        width: "calc(100% - 50px)",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: 4,
        borderRadius: "5px",
        height: 100,
        overflow: "hidden",
        cursor: "pointer",
        position: "relative"
    },
    viewCalendar: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: 35,
        fontWeight: 700,
        WebkitTextStrokeWidth: "1px",
        WebkitTextStrokeColor: "#fff",
    },
    imgLock: {
        position: 'absolute',
        top: '34%',
        left: '43.4%',
        width: 30
    },
    divChilTabDate: {
        width: 65,
    },
    divTabDate: {
        display: "flex",
        flexDirection: "row",
    },
    colorUser: {
        backgroundColor: theme.props.primaryColor
    }
}))
