import {AddGoogleEvent, updateGoogleEvent} from "../../../actions/googleCalendar";
import {ChangeTimeEvent, newEvent} from "../../../actions/calendar";
import moment from "moment";
import {addEventSource, refetchSource} from "../../../actions/refetchSourcce";
import {getGlobal, setGlobal} from 'reactn'
import {cloneDeep, findIndex} from 'lodash'
import {createZoom, handleGetValue, handleUpdateZoomMeeting} from "../../../actions/zoom";
import {CHANGE_TIME_ACTION, CREATED_ACTION} from "../../../config/constants";
import setScheduleAdjustmentOfEvent from "./setScheduleAdjustmentOfEvent";
import {sendMailChangeCalendar} from '../../Mails/Actions';
import {genId} from 'cf-gen-id';
import {snapshot} from 'valtio';
import {eventsProxy} from '../../../store/proxy/events.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';

export async function EventDrop(info, changeTime, numberId, copy = false) {
    let task = info.event.extendedProps.task;
    let isMileStone = info.event.extendedProps.isMileStone;
    if (task === "mileStone" && !info.event.allDay) {
        task = ""
    }
    if (isMileStone && !info.event.allDay) {
        isMileStone = false
    }

    let event = {
        id: copy ? genId({prefix: 'evt_', size: 8}) : info.event.id,
        uuid: info.event.extendedProps.uuid,
        title: info.event.title,
        start: info.event.start,
        end: info.event.end,
        googleEvent: info.event.extendedProps.googleEvent || info.event.extendedProps.is_google,
        recurrence: info.event.extendedProps.recurrence || "",
        recurringEventId: info.event.extendedProps.recurringEventId || "",
        allDay: info.event.allDay,
        project_uuid: info.event.extendedProps.project_uuid,
        colorId: info.event.extendedProps.colorId,
        backgroundColor: info.event.backgroundColor,
        borderColor: info.event.borderColor,
        isSuccess: info.event.extendedProps.isSuccess,
        task: task,
        location: info.event.extendedProps.location,
        description: info.event.extendedProps.description,
        isEventVote: Boolean(info.event.extendedProps.isEventVote),
        isAccepted: Boolean(info.event.extendedProps.isAccepted),
        meetingId: info.event.extendedProps?.meetingId || null,
        conferenceData: info.event.extendedProps?.conferenceData || null,
        googleTasksID: info.event.extendedProps?.googleTasksID || null,
        originStart: info.event.extendedProps.originStart || null,
        email: info.event.extendedProps?.email || '',
        emailVoter: info.event.extendedProps?.emailVoter || '',
        name: info.event.extendedProps?.name || '',
        timeBefore: info.event.extendedProps?.timeBefore || 0,
        timeAfter: info.event.extendedProps?.timeAfter || 0,
        zoomMeetingId: info.event.extendedProps?.zoomMeetingId,
        isMileStone
    }

    let end
    let duration
    if (info.event.end) {
    } else {
        if (info.oldEvent.allDay) {
            duration = moment(info.oldEvent.end).diff(info.oldEvent.start, 'day', true)
            end = moment(info.event.start).add(duration, "day").format()
        } else {
            duration = moment(info.oldEvent.end).diff(info.oldEvent.start, 'hour', true)
            end = moment(info.event.start).add(duration, "hour").format()
        }
        if (event.allDay) {
            const diff = moment(info.oldEvent.end).diff(info.oldEvent.start, 'day', true)
            event.duration = Math.ceil(diff)
            event.end = moment(info.event.start).add(event.duration, 'day').format()
        } else {
            event.end = end
            event.duration = duration
        }

        info.event.remove()
        addEventSource(event)
    }

    if (event.recurringEventId) {
        // recurrence event -> request ask
        setGlobal({
            recurringOpen: true,
            recurringEvent: {
                ...event,
                id: info.event.id,
                isCopiedSave: copy,
                isChangeRecurrence: false,
                allowAll: moment(event.start).format("YYYY-MM-DD") === moment(info.oldEvent.start).format("YYYY-MM-DD"),
                isStart: info.event.extendedProps.originStart.isStart
            }
        })
        return
    }

    // schedule adjustment need confirm
    if (event.isEventVote && !event.meetingId) {
        if (event.googleEvent || event.project_uuid) {
            setScheduleAdjustmentOfEvent(event)
        } else {
            const {voteEvents} = snapshot(eventsProxy)
            let idx = findIndex(voteEvents || [], {id: event.id})
            if (idx !== -1) {
                setGlobal({
                    scheduleAdjustment: {
                        ...voteEvents[idx],
                        start: event.start,
                        end: event.end,
                        oldInfo: {
                            start: voteEvents[idx].start,
                            end: voteEvents[idx].end
                        }
                    }
                })
                return
            }
        }
    }
    // local changed

    // create link zoom when copy
    if (copy && event.zoomMeetingId) {
        const data = await createZoom(event);
        if (data) {
            event.zoomMeetingId = data?.id || '';
            event.conferenceData = data;
        } else {
            event.zoomMeetingId = '';
            event.conferenceData = '';
        }
    }
    // update to db
    if (event.googleEvent) {
        const data = copy ? await AddGoogleEvent(event) : await updateGoogleEvent(event, event.id)
        copy && (event.id = data.id);
        const {googleEvents, setGoogleEvents} = snapshot(eventsProxy)
        let ggEvents = cloneDeep(googleEvents)
        const events = ggEvents[event.project_uuid]?.events || []
        let idx = findIndex(events, {id: event.id})
        if (idx !== -1) {
            events[idx] = {...data, project_uuid: event.project_uuid}
            ggEvents[event.project_uuid].events = events
            setGoogleEvents(ggEvents)
        }
        // changeTime(data)
    } else {
        copy ? await newEvent(event) : await ChangeTimeEvent(event)
    }
    const {prevAction} = getGlobal()
    if (copy) {
        prevAction.push({
            type: CREATED_ACTION,
            event: event
        })
    } else {
        prevAction.push({
            type: CHANGE_TIME_ACTION,
            event: {
                start: info.oldEvent.start,
                end: info.oldEvent.end,
                allDay: info.oldEvent.allDay
            },
            newEvent: event
        })
    }

    setGlobal({
        prevAction: [...prevAction],
        isDragging: false
    })
    // update zoom meeting
    if (!copy && event?.conferenceData && info.event.start !== info.oldEvent.start) {
        const {user} = snapshot(userProxy)
        handleUpdateZoomMeeting(moment(event.start).format(), moment(event.end).format(), handleGetValue(event), user.user_id, event.title)
            .catch(e => console.log(e))
    }
    //
    // if (!event.googleEvent && event.isEventVote && event.googleTasksID && checkTimeTitleEvent(info.event, info.oldEvent)) {
    //     const {user} = getGlobal()
    //     handleUpdateGoogleTasksSchedule(event, user)
    // }
    if (!copy && event.isEventVote) {
        sendMailChangeCalendar(event)
    }
    await setGlobal({
        loading: false,
    })
}
