import React, {useEffect, useState} from 'react';
import {DayPickerRangeController} from 'react-dates';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import isSameDay from 'react-dates/src/utils/isSameDay';
import {css} from 'react-with-styles';
import "./styles.css"
import {HORIZONTAL_ORIENTATION, START_DATE} from 'react-dates/src/constants';
import ScrollableOrientationShape from 'react-dates/src/shapes/ScrollableOrientationShape';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';
import {useGlobal} from "reactn";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as ArrowDownBlueSVG} from "../../../asSvg/icons/arrowDownBlue.svg";
import styled from 'styled-components'
import TimeFromToCustom from "../../Custom/TimeFromToCustom";
import SaveButton from "../../Dialog/SaveButton"
import CancelButton from "../../Dialog/CancelButton"
import useIntlId from "../../../hooks/useIntlId";
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {themesProxy} from '../../../store/proxy/themes.proxy';

const ButtonLeft = styled.button({
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    width: 23,
    height: 23,
    cursor: "pointer",
    transform: "rotate(90deg)",
    "& svg": {
        width: 23,
        height: 23,
    }
})
const ButtonRight = styled.button({
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    width: 23,
    height: 23,
    cursor: "pointer",
    transform: "rotate(-90deg)",
    "& svg": {
        width: 23,
        height: 23,
    }
})
const propTypes = {
    autoFocusEndDate: PropTypes.bool,
    // initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    startDateOffset: PropTypes.func,
    endDateOffset: PropTypes.func,
    showInputs: PropTypes.bool,
    minDate: momentPropTypes.momentObj,
    maxDate: momentPropTypes.momentObj,

    keepOpenOnDateSelect: PropTypes.bool,
    minimumNights: PropTypes.number,
    isOutsideRange: PropTypes.func,
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    daysViolatingMinNightsCanBeClicked: PropTypes.bool,

    // DayPicker props
    enableOutsideDays: PropTypes.bool,
    numberOfMonths: PropTypes.number,
    orientation: ScrollableOrientationShape,
    verticalHeight: PropTypes.number,
    withPortal: PropTypes.bool,
    initialVisibleMonth: PropTypes.func,
    renderCalendarInfo: PropTypes.func,
    renderMonthElement: PropTypes.func,
    renderMonthText: PropTypes.func,

    navPrev: PropTypes.node,
    navNext: PropTypes.node,
    renderNavPrevButton: PropTypes.func,
    renderNavNextButton: PropTypes.func,

    onPrevMonthClick: PropTypes.func,
    onNextMonthClick: PropTypes.func,
    onOutsideClick: PropTypes.func,
    renderCalendarDay: PropTypes.func,
    renderDayContents: PropTypes.func,
    renderKeyboardShortcutsButton: PropTypes.func,
    renderKeyboardShortcutsPanel: PropTypes.func,

    // i18n
    monthFormat: PropTypes.string,

    isRTL: PropTypes.bool,
};

const defaultProps = {
    // example props for the demo

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 0,
    isDayBlocked: () => false,
    // isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
    // isOutsideRange: () => false,
    isDayHighlighted: () => false,
    enableOutsideDays: false,
    daysViolatingMinNightsCanBeClicked: false,

    // calendar presentation and interaction related props
    orientation: HORIZONTAL_ORIENTATION,
    verticalHeight: undefined,
    withPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    onOutsideClick() {
    },
    keepOpenOnDateSelect: false,
    renderCalendarInfo: null,
    isRTL: false,
    renderMonthText: null,
    renderMonthElement: null,
    renderKeyboardShortcutsButton: undefined,
    renderKeyboardShortcutsPanel: undefined,

    // navigation related props
    navPrev: null,
    navNext: null,
    renderNavPrevButton: null,
    renderNavNextButton: null,
    onPrevMonthClick() {
    },
    onNextMonthClick() {
    },

    // internationalization
    monthFormat: 'MMMM YYYY',

};

function DateRangerPicker({setEvent, styles, classes, setOpen, startDate, endDate, checkTimeSearch}) {

    const [focusedInput, setFocusedInput] = useState(START_DATE);
    const {colors} = useSnapshot(themesProxy)
    const {user} = useSnapshot(userProxy)
    const [timeChange, setTimeChange] = useState(null)
    const cls = useStyles()
    const presetStyles = usePresetStyles()
    const isOutsideRange = checkTimeSearch !== undefined ? () => false : day => !isInclusivelyAfterDay(day, moment());
    const [searchTime] = useIntlId(['datePicker.searchTime'])
    const [Next, Week] = useIntlId(['datePicker.Next', 'datePicker.Week'])

    useEffect(() => {
        if (startDate && endDate) {
            setTimeChange({
                start: startDate,
                end: endDate
            })
        }
        // eslint-disable-next-line
    }, [])

    const onDatesChange = ({startDate, endDate}) => {
        setTimeChange({
            start: moment(startDate),
            end: endDate ? moment(endDate) : moment(startDate)
        })
    }

    const handleChangeTime = () => {
        let timeStart = timeChange.start
        let timeEnd = timeChange.end
        if (setEvent && typeof setEvent === "function")
            setEvent({
                start: moment(timeStart).startOf("day").format(),
                end: moment(timeEnd).endOf("day").format()
            })

        setOpen(false)
        setTimeChange(null)
    }
    if (!timeChange) return null

    return (
        <div className={cls.datePicker}>
            <div className={cls.popupName}>
                <span>
                {checkTimeSearch !== undefined ? searchTime
                    :
                    "Event Time"
                }
                </span>
            </div>
            <div
                className={cls.detailsContainer}
            >
                <TimeFromToCustom
                    timeStart={timeChange.start}
                    timeEnd={timeChange.end}
                    colorIcon={colors?.primary}
                    format={'YYYY/MM/DD'}
                    width={'90%'}
                    margin={'auto'}
                    padding={'unset'}
                />

            </div>
            <DayPickerRangeController
                {...defaultProps}

                isOutsideRange={isOutsideRange}
                startDate={moment(timeChange.start)} // momentPropTypes.momentObj or null,
                endDate={moment(timeChange.end)} // momentPropTypes.momentObj or null,
                onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => {

                    let newFocusedInput = !focusedInput ? START_DATE : focusedInput
                    setFocusedInput(newFocusedInput)

                }} // PropTypes.func.isRequired,
                renderCalendarInfo={() => renderDatePresets(presetStyles, timeChange, setTimeChange, user?.language, Next, Week)}
                navPrev={<ButtonLeft className={cls.navPrev}><ArrowDownBlueSVG/></ButtonLeft>}
                navNext={<ButtonRight className={cls.navNext}><ArrowDownBlueSVG/></ButtonRight>}
                initialVisibleMonth={() => moment().add(2, "M")}
            />
            <div className={`${cls.flexMtop20}`}>
                <div className={cls.groupBtn}>
                    {/*<CLFButtonSVG*/}
                    {/*    name={"Cancel"}*/}
                    {/*    size={"default"}*/}
                    {/*    iconComponent={<CloseSVG/>}*/}
                    {/*    onClick={() => {*/}
                    {/*        setOpen(false)*/}
                    {/*        setTimeChange(null)*/}
                    {/*    }}*/}
                    {/*    className={cls.secondary}*/}
                    {/*/>*/}
                    <CancelButton
                        onClick={() => {
                            setOpen(false)
                            setTimeChange(null)
                        }}
                    />
                    {/*<CLFButtonSVG*/}
                    {/*    name={"Save"}*/}
                    {/*    key={"submit"}*/}
                    {/*    size={"default"}*/}
                    {/*    iconComponent={<SaveSVG/>}*/}
                    {/*    onClick={handleChangeTime}*/}
                    {/*    className={cls.primary}*/}
                    {/*/>*/}
                    <SaveButton
                        onClick={handleChangeTime}
                        key={"submit"}
                    />
                </div>
            </div>
        </div>

    );
}

function renderDatePresets(classes, timeChange, setTimeChange, language, Next, Week) {

    const today = moment();
    // const cls = useStyles
    const presets = [1, 2, 3, 4, 5, 6, 7, 8].map(value => {
        let numAddDay = value * 7 - 1
        let text
        if (language === "vi-vn") {
            text = `${value} ${Week} ${Next}`
        }
        if (language === "en-us") {
            if (value > 1) {
                text = `${Next} ${value} ${Week}s`
            } else {
                text = `${Next} ${value} ${Week}`
            }

        }
        if (language === "ja-jp") {
            text = `${Next}${value}${Week}`
        }
        return {
            text: text,
            start: today,
            end: moment().add(numAddDay, 'day'),
        }
    });
    return (
        <div {...css(classes.PresetDateRangePicker_panel)}>
            {presets.map(({text, start, end}) => {
                const isSelected = isSameDay(start, timeChange.start) && isSameDay(end, timeChange.end);
                return (
                    <button
                        key={text}
                        {...css(
                            classes.PresetDateRangePicker_button,
                            isSelected && classes.PresetDateRangePicker_button__selected,
                        )}
                        type="button"
                        onClick={() => {
                            setTimeChange({
                                start: start,
                                end: end
                            })
                        }}
                    >
                        {text}
                    </button>
                );
            })}
        </div>
    );
}

DateRangerPicker.propTypes = propTypes;
DateRangerPicker.defaultProps = defaultProps;

export default (DateRangerPicker);

const useStyles = makeStyles(theme => ({
    popupName: {
        textAlign: "center", marginTop: 20,
        "& span": {
            backgroundColor: "#f1f1f1",
            color: theme.props.primaryColor || "#1790FF",
            padding: "9px 25px", borderRadius: 24, fontWeight: 700
        }
    },
    detailsContainer: {
        padding: "5px 0px",
        width: "100%",
        maxWidth: 340,
        margin: '10px auto',
        background: "#f1f1f1",
        borderRadius: 24
    },
    navPrev: {
        fill: theme.props.primaryColor,
        position: "absolute",
        top: 25,
        left: 25
    },
    navNext: {
        fill: theme.props.primaryColor,
        position: "absolute",
        top: 25,
        right: 25
    },
    datePicker: {
        "& .CalendarMonth_caption": {
            fontWeight: 700,
            color: theme.props.primaryColor
        },
        "& .CalendarDay__selected_span": {
            background: theme.props.primaryColor,
            fontWeight: 700,
            color: "#fff !important",
            border: "1px solid #fff"
        },
        "& .CalendarDay__selected": {
            background: theme.props.primaryColor,
            fontWeight: 700,
            color: "#fff !important",
            border: "1px solid #fff"
        },
        "& .CalendarDay__blocked_out_of_range": {
            border: "none",
            background: "#f1f1f1",
            fontWeight: 700,
            color: "#000"
        },
        "& .CalendarDay__default": {
            fontWeight: 700,
            color: "#000"
        },
        "& .CalendarMonth ": {
            background: "#f1f1f1"
        },
        "& .CalendarMonth_table": {
            border: "2px solid #fff",
        },
        "& .CalendarMonth_table tr :nth-child(7)": {
            fontWeight: 700,
            color: "#970505 !important"
        },
        "& .DayPicker_weekHeader_li": {
            fontWeight: 700,
            color: "#000",
            textTransform: "uppercase"
        },
        "& .DayPicker_weekHeader_ul": {
            color: "#970505 !important",
            background: "#fff",
            borderRadius: 7,
            marginLeft: 2
        },
        "& .DayPicker_weekHeader_ul :nth-child(7)": {
            color: "#970505 !important",
        },
        "& .CalendarMonthGrid": {
            background: "#f1f1f1",
            borderRadius: 12,
            paddingBottom: 10
        }
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    },
    flexMtop20: {
        display: "flex",
        marginTop: 20
    },
    groupBtn: {
        display: 'flex', margin: 'auto', paddingBottom: '20px'
    },
}))

const usePresetStyles = makeStyles(theme => ({
    PresetDateRangePicker_panel: {
        // display: 'flex',
        padding: '0 22px 11px 22px',
    },

    PresetDateRangePicker_button: {
        position: 'relative',
        height: '100%',
        textAlign: 'center',
        background: 'none',
        border: `2px solid ${theme.props.primaryColor}`,
        color: theme.props.primaryColor,
        padding: '4px 12px',
        marginRight: 5,
        font: 'inherit',
        fontWeight: 700,
        lineHeight: 'normal',
        overflow: 'visible',
        boxSizing: 'border-box',
        cursor: 'pointer',
        marginBottom: 5,
        borderRadius: "50px",
        ':active': {
            outline: 0,
        },
    },

    PresetDateRangePicker_button__selected: {
        color: "#fff",
        background: theme.props.primaryColor,
    },

}))
