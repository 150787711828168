import {db} from '../config/firebaseConfig';
import {CALENDAR_COLLECTION, IDENTITY_COLLECTION} from '../config/constants';
import {setGlobal} from 'reactn';
import {getDataAsync} from './firebaseRef/meetings';
import {myCal} from './myCal';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';

export const getCalendarInfo = async (userId) => {
    if (!userId)
        return null
    try {
        const data = await getDataAsync(myCal())
        if (!data)
            return null
        setGlobal({
            focusText: data.focusText || [],
            tasks: data.tasks || [],
            times: data.times || {
                minTime: {
                    value: 6,
                    label: '06:00'
                },
                maxTime: {
                    value: 24,
                    label: '24:00'
                }
            }
        })

    } catch (e) {
        console.log(e);
    }
}

export const getProjects = (userId, callback) => {
    try {
        return db.collection(CALENDAR_COLLECTION)
            .doc(userId)
            .collection("projects")
            .where("is_deleted", "==", false)
            .onSnapshot(querySnapshot => {
                    if (callback) {
                        callback(null, querySnapshot)
                    }
                },
                error => {
                    if (callback) {
                        callback(error, null)
                    }
                }
            )
    } catch (e) {
        console.log(e);
        return null
    }
}
const timeWork = {
    minTime: {
        value: 7,
        label: '07:00'
    },
    maxTime: {
        value: 24,
        label: '24:00'
    }
}
const tasksDefault = {
    data: [
        {
            icon: "AccountCircle",
            index: 0,
            is_active: 1,
            is_default: 0,
            name: "AccountCircle",
        },
        {
            icon: "AssignmentTurnedIn",
            index: 1,
            is_active: 1,
            is_default: 0,
            name: "AssignmentTurnedIn"
        },
        {
            icon: "Group",
            index: 2,
            is_active: 1,
            is_default: 1,
            name: "Group"
        },
        {
            icon: "AssignmentInd",
            index: 3,
            is_active: 1,
            is_default: 0,
            name: "AssignmentInd"
        }
    ],
    default: 1
}

export async function initFireStoreData(userId, email = '') {
    try {
        if (!userId)
            return;

        const projectDefault = {
            color: "#3A3A3A",
            ja_name: "Calendar 1",
            name: "Calendar 1",
            code: 'Ca',
            created_at: moment().utc().format(),
            updated_at: moment().utc().format(),
            selected: true,
            isDefault: true
        }

        const identities = [
            {
                color: "#D50000",
                is_active: 1,
                name: "red",
                uuid: 'ide_' + uuidv4(),
                is_sync: false
            },

            {
                color: "#F6BF26",
                is_active: 1,
                name: "yellow",
                uuid: 'ide_' + uuidv4(),
                is_sync: false
            },

            {
                color: "#33B679",
                is_active: 1,
                name: "green",
                uuid: 'ide_' + uuidv4(),
                is_sync: false
            },

            {
                color: "#616161",
                is_active: 1,
                name: "gray",
                uuid: 'ide_' + uuidv4(),
                is_sync: false
            },

            {
                color: "#039BE5",
                is_active: 1,
                name: "blue",
                uuid: 'ide_' + uuidv4(),
                is_sync: false
            },

        ]

        const batch = db.batch()
        const userRef = db.collection(CALENDAR_COLLECTION)
            .doc(userId)
        // check user info again
        const userSnap = await userRef.get()
        if (userSnap.exists) {
            return
        }
        const themeRef = db.doc(`${CALENDAR_COLLECTION}/${userId}/listTheme/1`)
        const themeCustom = {
            calendarLeftView: false,
            colorPrimary: "#1790FF",
            columns: 1,
            id: null,
            rows: 1,
            rightExpansion: true,
            size: 9,
            viewCalendar: "CustomMonth",
            showTitle: true
        }
        batch.set(userRef, {
            focusText: [],
            tasks: tasksDefault,
            times: timeWork,
            uuid: userId,
            themeCustom: themeCustom,
            createdAt: moment().utc().format(),
            updatedAt: moment().utc().format(),
        })
        batch.set(themeRef, themeCustom)
        // batch set project default
        const uid = 'pro_' + uuidv4()
        const projectRef = db.collection(CALENDAR_COLLECTION)
            .doc(userId)
            .collection('projects')
            .doc(uid)
        batch.set(projectRef, {...projectDefault, uuid: uid, author: email, is_deleted: false})

        const identityRef = db.collection(IDENTITY_COLLECTION)
            .doc(userId)
        const identitySnap = await identityRef
            .get()
        if (!identitySnap.exists) {
            batch.set(identityRef, {
                identities
            })
        }
        // my free time
        const myfreetime = {
            startDay: moment().utc().format('YYYY-MM-DD'),
            endDay: moment().utc().add(14, "days").format('YYYY-MM-DD'),
            typeTimeSharing: "auto",
            timeRange: [0, 1],
            createdAt: moment().utc().format(),
            updatedAt: moment().utc().format(),
            workTime: {
                start: 6,
                end: 24
            },
            freeTime: 0,
            timeVote: 1,
            viewEvent: [
                {
                    checked: true,
                    field: "box"
                },
                {
                    checked: true,
                    field: "title"
                },
            ],
        }
        const myfreetimeRef = db.collection(CALENDAR_COLLECTION)
            .doc(userId)
            .collection("myFreeTime")
            .doc("myfreetime")
        batch.set(myfreetimeRef, myfreetime)
        await batch.commit()

    } catch (e) {
        console.log(e);
    }
}

