import {db} from "../../config/firebaseConfig";
import {APP_GLOBAL_ID, CALENDAR_COLLECTION, USER_COLL} from '../../config/constants';
import {get} from 'lodash';
import {getGlobal} from 'reactn';

export const getNaviCollRef = (userId) => {
    return db.collection(USER_COLL)
        .doc(userId)
        .collection('navigations')
        .doc(APP_GLOBAL_ID)
}

export const getURLCollRef = (userId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('addUrls')

}
export const getGlobalUrls = (userId) => {
    return db.collection(USER_COLL)
        .doc(userId)
        .collection('navigations')

}

export const getCheckedCollRef = (userId) => {
    return db.collection(USER_COLL)
        .doc(userId)
        .collection('geniamAppsCheck')
        .doc(APP_GLOBAL_ID)
}

export const getUserDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    if (!user_id)
        return null
    return db.collection(USER_COLL)
        .doc(user_id)
}


