import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core'
import TextInputCustom from "../../Custom/TextInputCustom";
import {db} from "../../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {useGlobal} from "reactn";
import {Popconfirm} from 'antd';
import useIntlId from "../../../hooks/useIntlId";
import TextInputURLCustom from "../../Custom/TextInputURLCustom";
import AddProjectUrl from "./AddUrl";
import EditButton from "../../Dialog/EditButton";
import DeleteButton from "../../Dialog/DeleteButton";
import {findIndex} from 'lodash';
import CreateIcon from '@material-ui/icons/Create';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {projectsProxy} from '../../../store/proxy/projects.proxy';

const ProjectInfo = ({project, setEdit, setProject}) => {
    const classes = useStyles()
    const {user, calendarUser} = useSnapshot(userProxy)
    const {geniamProjects, setProjectSelected} = useSnapshot(projectsProxy)
    const [loading, setLoading] = useState(false)
    const [otherURL, setOtherURL] = useState([])
    const [askDelete, name, yes, no] = useIntlId([
        'settings.askDelete',
        'app.profile.name',
        'settings.yes',
        'settings.no'
    ])
    const linkItems = [{
        name: "Slack",
        logo: "/icons/slackIcon.svg",
        id: "slack"
    }, {
        name: "Trello",
        logo: "/icons/trelloIcon.svg",
        id: "trello"
    }
    ]
    useEffect(() => {
        if (project?.otherURL?.length) {
            let arr = project.otherURL.filter(e => {
                return e !== null
            });
            setOtherURL(arr);
        } else {
            setOtherURL(linkItems)
        }
    }, [project])

    function setProjectSelect(sliceItem) {
        setProjectSelected({...geniamProjects, otherURL: sliceItem});
    }

    function cancel() {
        setLoading(false)
    }

    const onDeleteProjects = async () => {
        setLoading(true)
        if (!project?.id) return;
        try {
            const batch = db.batch()
            // delete Geniam project

            if (!project?.is_google) {
                const projectsRef = db.collection(CALENDAR_COLLECTION)
                    .doc(user?.user_id)
                    .collection('projects')
                    .doc(project?.id)

                batch.set(projectsRef, {is_deleted: true}, {merge: true})

                await batch.commit()
            }
            //delete Google project
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setProject({})
            setEdit(false)
        }
    }

    const delUrl = async (item) => {
        let sliceItem
        try {
            if (project.is_google) {
                sliceItem = otherURL.filter((e) => {
                    return e.id !== item.id
                })
                let googleSettings = calendarUser?.googleSettings || {}
                googleSettings[project.id].otherURL = sliceItem
                return
            }
            let idx = findIndex(geniamProjects, {id: project.id})
            if (idx === -1)
                return
            let projectInfo = geniamProjects[idx]
            sliceItem = projectInfo.otherURL.filter((e) => {
                return e.id !== item.id
            })
            setOtherURL(sliceItem);
            setProjectSelect(sliceItem);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <div className={`${classes.projectColor} clf-flex-center`}
                 style={{backgroundColor: `${project.color || project.backgroundColor}`}}>
                <div className={classes.wrapText}>
                    {project.name || project.summary}
                </div>
            </div>
            <div className={classes.titleColor}>

                <TextInputCustom
                    name={name}
                    contentName={<div className={classes.wrapText}>{project.name}</div>}
                />


                <div className={classes.textEdit}>
                    <div className={classes.colorCircle}
                         style={{backgroundColor: project.color}}
                    >
                        Color
                        <CreateIcon className={classes.iconColor} style={{color: project.color}}/>
                    </div>
                </div>
                {/*<TextInputCustom name={color} contentName={project.color} isColor={true}/>*/}

            </div>
            <div className={classes.listItems}>
                <div>
                    {
                        otherURL?.length > 0 && otherURL.map((item, index) => {
                            if (!item) return null;
                            return (
                                <Grid container key={index} className={classes.divGrid}>
                                    <TextInputURLCustom
                                        urlImg={item.logo}
                                        isURL={true}
                                        item={item}
                                        project={project}
                                        setProject={setProject}
                                        otherURL={otherURL}
                                        setOtherURL={setOtherURL}
                                        isEditComponent={false}
                                        titleMaxWidth={110}
                                        delUrl={delUrl}
                                        iconDel={false}
                                    />
                                </Grid>
                            )
                        })
                    }
                </div>
            </div>
            <AddProjectUrl project={project} setProject={setProject} setProjectSelected={setProjectSelect}/>
            <div className={`${classes.action} clf-flex-center`}>
                <EditButton
                    onClick={() => setEdit(true)}
                />
                {
                    !project?.is_google && !project?.isDefault &&
                    <Popconfirm
                        title={askDelete}
                        onConfirm={onDeleteProjects}
                        onCancel={cancel}
                        okText={yes}
                        cancelText={no}
                        overlayClassName={classes.confirmStyle}
                    >
                        <DeleteButton
                            loading={loading}
                        />
                    </Popconfirm>
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        marginTop: 40,
        padding: '30px 10px',
        borderRadius: 20,
        position: 'relative'
    },
    content: {
        textAlign: "center",
        fontSize: 14
    },
    divGrid: {
        marginBottom: 15
    },
    listItems: {
        marginTop: 20,
    },
    title: {
        fontWeight: "bold"
    },
    action: {
        marginTop: 20
    },
    itemHeader: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    textField: {
        marginLeft: 10
    },
    projectColor: {
        borderRadius: 24,
        maxWidth: 150,
        minHeight: 33,
        color: '#FFF',
        border: 'none',
        width: "calc(100% - 10px)",
        position: 'absolute',
        top: -15,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    wrapText: {
        width: '95%',
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: '1.2rem',
        fontWeight: 'bold',
        whiteSpace: "nowrap",
    },
    saveIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -45,
        marginRight: 10,
        marginTop: 1
    },
    deleteIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -25,
        marginRight: 10,
        marginTop: 1
    },
    confirmStyle: {
        zIndex: '9999 !important'
    },
    primaryColor: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            marginTop: 1
        }
    },
    secondaryColor: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            marginTop: 1
        }
    },
    colorCircle: {
        position: 'relative',
        cursor: 'pointer',
        textTransform: 'uppercase',
        color: '#fff',
        fontWeight: 700,
        display: "flex",
        paddingLeft: 10,
        alignItems: 'center',
        width: '100%',

        // minWidth: 25,
        height: 40,
        borderRadius: '24px',
        padding: 0,
        border: '1px solid #D5D5D5',
        margin: "auto"
    },
    iconColor: {
        width: 28,
        height: 28,
        background: '#fff',
        borderRadius: '50%',
        padding: 4,
        position: 'absolute',
        right: 3
        // '& .MuiSvgIcon-root':{
        //     fill:
        // }
    },
    titleColor: {
        display: "flex",
        justifyContent: "space-between"
    },
    textEdit: {
        paddingRight: 5,
        alignItems: 'flex-end',
        width: '100%',
        maxWidth: 120
    }
}))

export default ProjectInfo;
