import {db} from '../../config/firebaseConfig'
import {getGlobal} from 'reactn'
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import {
    CALENDAR_COLLECTION,
    MAIL_TEMPLATES_SEND_MAIL_DELETE_EVENT_VOTE_DOC,
    SUBDOMAIN_APPOINTMENT
} from "../../config/constants";
import {getAnonymousCollRef, getMeetingDocRef, getUsersCollRef} from "../../common/firebaseRef/meetings";
import {concat, isEmpty, remove} from 'lodash'
import {msgError, msgSuccess} from '../../utils/msg';
import {getEmailHtml} from '../Meeting/ShareFreeTimeUrl/common';
import momentTz from 'moment-timezone';
import axios from 'axios';
import {snapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';

const jwt = require('jsonwebtoken');

export const sentEmailInviteUsers = async (batch, meeting) => {
    const {user} = snapshot(userProxy)
    let userInvites = meeting?.userInvites || [];
    const messageId = "email.message.invite";
    await Promise.all(userInvites.map(async usr => {
        let language = usr?.language || 'ja-jp';
        const languageSnap = await db.doc(`intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        const content = `${user.nickname} ${languageData[messageId]}`;
        // sent email to user
        const subDomain = process.env.REACT_APP_ENV === 'production' ? 'calendar' : 'calendar-stg';

        const token = jwt.sign({
            id: meeting?.id,
            from: user.user_id
        }, 'geniamMeeting', {expiresIn: '1h'});
        const linkAnonymous = `https://${subDomain}.geniam.com/event?id=${meeting?.id}&from=${user.user_id}&tokenMeeting=${token}`

        const link = usr.isAnonymous ?
            linkAnonymous
            :
            `https://${subDomain}.geniam.com/event?id=${meeting.id}&by=${meeting.owner}&to=${usr.email}`;
        // for add email to emails collections
        const uuidEmail = `email_evt_meeting_${uuidv4()}`;

        const mailDocRef = db.doc(`mails/${uuidEmail}`);

        batch.set(mailDocRef, {
            to: usr.email,
            template: {
                name: 'inviteEvent',
                data: {
                    startDay: moment(meeting.startDay).utc().format("YYYY-MM-DD"),
                    endDay: moment(meeting.endDay).utc().format('YYYY-MM-DD'),
                    author: meeting.owner,
                    title: meeting.name !== undefined ? meeting.name : meeting.title,
                    subDomain: subDomain,
                    link: link,
                    content: content
                }
            },
            meetingId: meeting.id,
            createdAt: moment().utc().format()
        });

        if (!usr.isAnonymous) {
            // add notifications
            let dataNotice = {
                id: 'meeting_notice_' + uuidv4(),
                isRead: false,
                isClick: false,
                createdAt: moment().utc().format(),
                updatedAt: moment().utc().format(),
                meetingId: meeting.id,
                name: meeting.name !== undefined ? meeting.name : meeting.title,
                content,
                role: usr.role || (meeting.userInvites.length > 1 ? "viewer" : "admin"),
                from: user.user_id,
                by: meeting.owner,
                to: usr.email,
                messageId,
                nickname: user.nickname,
                avatar: user.avatar
            };

            let noticeDocRef = db.collection(CALENDAR_COLLECTION)
                .doc(usr.id)
                .collection('notifications')
                .doc(dataNotice.id);

            batch.set(noticeDocRef, dataNotice);
        }
        return usr
    }));

    return await batch.commit()
}

export const handleSentEmailSuccess = async (batch, meeting, url, messages, isRefer, isRequest, isCancel, isEmailOnly) => {
    try {
        const {user} = snapshot(userProxy)
        let nickname = "Calendar", avatar = "https://calenview.geniam.com/favicon.ico"
        if (!isEmpty(user)) {
            nickname = user.nickname
            avatar = user.avatar
        }
        let users = [];
        const messageId = isCancel ?
            'email.message.canceled'
            :
            isRefer ?
                'email.message.referEvent'
                :
                isRequest ?
                    'email.message.requestChangeVoteBox'
                    :
                    'email.message.accepted';
        const eventDocRef = getMeetingDocRef(meeting.id)
        const eventSnapshot = await eventDocRef.get();

        if (!eventSnapshot.exists)
            return await batch.commit()

        const userCollRef = getUsersCollRef(meeting.id)
        const snapshots = await userCollRef.get();

        const anonymousCollRef = getAnonymousCollRef(meeting.id)
        const anonymousSnapshots = await anonymousCollRef.get()

        if (!snapshots.size && !anonymousSnapshots.size)
            return await batch.commit()

        const usersData = snapshots.docs.map(doc => ({...doc.data(), id: doc.id}));
        const anonymousUsers = anonymousSnapshots.docs.map(doc => ({...doc.data(), id: doc.id}));

        concat(usersData, anonymousUsers).forEach(snap => {
            if (isRequest) {
                if ((snap.joined && snap.role === 'admin') ||
                    (snap.role === 'owner'))
                    users.push({...snap, id: snap.id});
            } else {
                if (snap.joined)
                    users.push({...snap, id: snap.id});
            }
        });

        remove(users, u => u.id === user.user_id);

        await Promise.all(users.map(async usr => {
            let language = usr?.language || 'ja-jp';

            const languageSnap = await db.doc(
                `intl/calendarIntl/langColl/${language}`).get();
            const languageData = languageSnap.exists ? languageSnap.data() : {};
            let content = isRequest ?
                `${usr.nickname}, ${user.nickname} ${languageData[messageId]} \
          Vote box: ${moment(meeting?.start).format('YYYY-MM-DD HH:mm')} ~ ${moment(
                    meeting?.end).format('YYYY-MM-DD HH:mm')}`
                :
                `${nickname} ${languageData[messageId]}`;

            const subDomain = process.env.REACT_APP_ENV === 'production' ? 'calendar' : 'calendar-stg';
            const token = jwt.sign({
                id: meeting?.id,
                from: user.user_id
            }, 'geniamMeeting', {expiresIn: '1h'});

            const linkAnonymous = `https://${subDomain}.geniam.com/event?id=${meeting?.id}&from=${user.user_id}&tokenMeeting=${token}`

            const link = usr.isAnonymous ?
                linkAnonymous
                : `https://${subDomain}.geniam.com/event?id=${meeting.id}`;

            // for add email to emails collections
            const uuidEmail = `email_evt_meeting_${uuidv4()}`

            const mailDocRef = db.doc(`mails/${uuidEmail}`)

            batch.set(mailDocRef, {
                // cc: isEmailOnly ? user.email : '',
                to: usr.email,
                template: {
                    name: 'sendMailSuccess',
                    data: {
                        title: eventSnapshot.data().name,
                        subDomain: subDomain,
                        link: link,
                        content: content,
                        messages: messages,
                        url: url,
                    }
                },
                meetingId: meeting.id,
                createdAt: moment().utc().format()
            });

            if (isEmailOnly) {
                return null;
            }

            if (!usr.isAnonymous) {
                // add notifications
                let dataNotice = {
                    id: 'meeting_notice_' + uuidv4(),
                    isRead: false,
                    isClick: false,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    meetingId: meeting.id,
                    name: eventSnapshot.data().name,
                    content,
                    messageId: isRequest ? null : messageId,
                    nickname,
                    avatar
                }
                let noticeDocRef = db.collection(CALENDAR_COLLECTION)
                    .doc(usr.id)
                    .collection('notifications')
                    .doc(dataNotice.id)

                batch.set(noticeDocRef, dataNotice)
            }
            return usr
        }));

        await batch.commit()
        return msgSuccess("Sent email successfully")
    } catch (e) {
        console.log(e.toString())
    }
}

export const handleSharedEmail = async (batch, meetingId, messageId) => {
    try {
        const {user} = snapshot(userProxy)
        const {nickname, avatar} = user;
        const meetingDocRef = getMeetingDocRef(meetingId);
        const snapshot = await meetingDocRef.get();

        if (!snapshot.exists)
            return await batch.commit();

        const userCollRef = getUsersCollRef(meetingId)
        const snapshots = await userCollRef.get();
        const anonymousCollRef = getAnonymousCollRef(meetingId)
        const anonymousSnapshots = await anonymousCollRef.get()

        if (!snapshots.size && !anonymousSnapshots.size)
            return await batch.commit();

        const eventData = snapshot.data();

        const users = snapshots.docs.map(doc => ({...doc.data(), id: doc.id}));
        const anonymousUsers = anonymousSnapshots.docs.map(doc => ({...doc.data(), id: doc.id}));

        const allUsers = concat(users, anonymousUsers)

        remove(allUsers, us => us.id === user.user_id);

        if (users.length === 0)
            return await batch.commit();

        await Promise.all(allUsers.map(async usr => {
            if (!usr.joined)
                return null;

            let language = usr?.language || 'ja-jp';

            const languageSnap = await db.doc(
                `intl/calendarIntl/langColl/${language}`).get();
            const languageData = languageSnap.exists ? languageSnap.data() : {};
            let content = messageId ?
                `${nickname} ${languageData[messageId]}` :
                'There has been a change from admin or owner, please check';
            const subDomain = process.env.REACT_APP_ENV === 'production' ? 'calendar' : 'calendar-stg';

            const token = jwt.sign({
                id: snapshot.data()?.id,
                from: user.user_id
            }, 'geniamMeeting', {expiresIn: '1h'});
            const linkAnonymous = `https://${subDomain}.geniam.com/event?id=${snapshot.data()?.id}&from=${user.user_id}&tokenMeeting=${token}`

            const link = usr.isAnonymous ?
                linkAnonymous
                :
                `https://${subDomain}.geniam.com/event?id=${meetingId}`;

            // for add email to emails collections
            const uuidEmail = `email_evt_meeting_${uuidv4()}`

            const mailDocRef = db.doc(`mails/${uuidEmail}`)

            batch.set(mailDocRef, {
                to: usr.email,
                template: {
                    name: 'sharedMail',
                    data: {
                        title: eventData.name,
                        subDomain: subDomain,
                        link: link,
                        content: content,
                    }
                },
                meetingId,
                createdAt: moment().utc().format()
            });

            if (!usr.isAnonymous) {
                let dataNotice = {
                    id: 'meeting_notice_' + uuidv4(),
                    isRead: false,
                    isClick: false,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    meetingId: meetingId,
                    name: eventData.name,
                    content: content || '',
                    messageId,
                    nickname: nickname || '',
                    avatar
                };

                let noticeDocRef = db.collection(CALENDAR_COLLECTION)
                    .doc(usr.id)
                    .collection('notifications')
                    .doc(dataNotice.id)

                batch.set(noticeDocRef, dataNotice)
            }
        }));

        await batch.commit()
        return true
    } catch (e) {
        console.log(e.toString())
        return false
    }
}

export const sendMailChangeCalendar = async (eventData) => {
    const batch = db.batch()
    const {user, googleAuth} = snapshot(userProxy);
    try {
        let zoneUser = user?.time_zone || 'Asia/Tokyo';

        const timeUser = `${momentTz(eventData.start).tz(zoneUser).format("HH:mm")} ~ ${momentTz(eventData.end).tz(zoneUser).format("HH:mm")}`
        let language = user?.language || 'ja-jp';
        let nickname = (user?.first_name || user?.last_name) ? `${user?.last_name || ''} ${user?.first_name || ''}` : user?.nickname

        const languageSnap = await db.doc(
            `intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        const dontRely = `${languageData['email.message.dontReply']}`;
        const btnGotoEvent = `${languageData['email.message.btn.gotoEvent']}`;
        const btnJoinZoom = `${languageData['email.message.joinZoom']}`;
        const afterChangeIntl = `${languageData['email.afterChange']}`
        const subjectIntl = `${languageData['email.userSharedTime']}`
        const uuidEmail = `meet_${uuidv4()}`;
        const mailDocRef = db.doc(`mails/${uuidEmail}`);
        await batch.set(mailDocRef, {
            from: `${nickname} <geniamevents@geniam.com>`,
            replyTo: googleAuth?.email,
            to: eventData?.email,
            message: {
                html: getEmailHtml({
                    title: subjectIntl?.replace('name', `${nickname}`) || '',
                    start: moment(eventData?.start).utc().format("YYYY-MM-DD"),
                    end: moment(eventData?.end).utc().format('YYYY-MM-DD'),
                    time: timeUser,
                    timeZone: `${zoneUser}  ${momentTz().tz(zoneUser).format("Z")}`,
                    dontRely: dontRely,
                    btnGotoEvent: btnGotoEvent,
                    btnJoinZoom: btnJoinZoom,
                    afterChange: afterChangeIntl,
                }),
                subject: subjectIntl?.replace('name', `${nickname}`) || ''
            }
        })
        await batch.commit()
    } catch (e) {
        console.log(e.toString())
        msgError(e.toString())
    } finally {

    }
}

export const sendMailDeleteEventVote = async (evt, uId, usr, meetingId) => {
    const {user} = snapshot(userProxy)

    const messageId = 'appy.notice.deleteVote';
    try {
        if (!user?.user_id || !usr || !evt || !usr.joined) return;
        let language = usr?.language || 'en-us';
        const languageSnap = await db.doc(
            `intl/calendarIntl/langColl/${language}`).get();
        const languageData = languageSnap.exists ? languageSnap.data() : {};
        const btnViewGroup = `${languageData['email.message.btnViewGroup']}`;
        const dontRely = `${languageData['email.message.dontReply']}`;
        const subjectDeleteVote = `${languageData['email.message.subject.deleteVote']}`.split("|");
        const subjectDeleteVoteToUser = `${languageData['email.message.subject.deleteVoteToUser']}`.split("|");
        const fullName = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname

        const link = `https://${SUBDOMAIN_APPOINTMENT}.geniam.com/event?id=${evt.meetingId}`;

        const {data: {shortLink}} = await axios.post('https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/dynamicLinksGenerate', {
                "domainUriPrefix": "https://appygeniam.page.link",
                "link": link,
                "androidPackageName": null,
                "iosBundleId": null
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )

        const groupName = `${evt.title}`
        const timeStart = `${moment(evt.start).format('HH:mm')}`
        const timeEnd = `${moment(evt.end).format('HH:mm')}`
        const time = `${timeStart} ~ ${timeEnd}`

        let startDay
        if (usr?.language) {
            if (usr?.language === 'ja-jp') {
                startDay = `${moment(evt.start).format("YYYY年MM月DD日")}`
            }
            if (usr?.language === 'en-us' || usr?.language === 'vi-vn') {
                startDay = `${moment(evt.start).format("DD-MM-YYYY")}`
            }
        } else {
            startDay = `${moment(evt.start).format("DD-MM-YYYY")}`
        }


        let subject
        if (usr?.language) {
            if (user?.user_id === uId) {
                if (usr.language === 'ja-jp') {
                    subject = subjectDeleteVoteToUser[0] + groupName + subjectDeleteVoteToUser[1] + startDay + subjectDeleteVoteToUser[2] + timeStart + subjectDeleteVoteToUser[3] + timeEnd + subjectDeleteVoteToUser[4]
                }
                if (usr.language === 'en-us') {
                    subject = subjectDeleteVoteToUser[0] + startDay + subjectDeleteVoteToUser[1] + timeStart + subjectDeleteVoteToUser[2] + timeEnd + subjectDeleteVoteToUser[3] + groupName + subjectDeleteVoteToUser[4]
                }
                if (usr.language === 'vi-vn') {
                    subject = subjectDeleteVoteToUser[0] + timeStart + subjectDeleteVoteToUser[1] + timeEnd + subjectDeleteVoteToUser[2] + startDay + subjectDeleteVoteToUser[3] + groupName + subjectDeleteVoteToUser[4]
                }
            } else {
                if (usr.language === 'ja-jp') {
                    subject = subjectDeleteVote[0] + fullName + subjectDeleteVote[1] + groupName + subjectDeleteVote[2] + startDay + subjectDeleteVote[3] + timeStart + subjectDeleteVote[4] + timeEnd + subjectDeleteVote[5]
                }
                if (usr.language === 'en-us') {
                    subject = subjectDeleteVote[0] + fullName + subjectDeleteVote[1] + startDay + subjectDeleteVote[2] + timeStart + subjectDeleteVote[3] + timeEnd + subjectDeleteVote[4] + groupName + subjectDeleteVote[5]
                }
                if (usr.language === 'vi-vn') {
                    subject = subjectDeleteVote[0] + fullName + subjectDeleteVote[1] + timeStart + subjectDeleteVote[2] + timeEnd + subjectDeleteVote[3] + startDay + subjectDeleteVote[4] + groupName + subjectDeleteVote[5]
                }
            }
        } else {
            subject = `${fullName} deleted the event in「${groupName}」group on ${startDay} at [${timeStart} ~ ${timeEnd}]`
        }

        const batch = db.batch()

        const uuidEmail = `email_delete_${uuidv4()}`
        const mailDocRef = db.doc(`mails/${uuidEmail}`)
        // const mailTemplates = process.env.REACT_APP_ENV === 'production' ? 'sendMailDeleteEventVote' : 'sendMailDeleteEventVote-stg'
        batch.set(mailDocRef, {
            to: usr.email,
            template: {
                name: MAIL_TEMPLATES_SEND_MAIL_DELETE_EVENT_VOTE_DOC,
                data: {
                    link: shortLink || '',
                    subject: subject || '',
                    content: subject || '',
                    time: time,
                    startDay: startDay,
                    groupName: groupName || '',
                    btnViewGroup: btnViewGroup,
                    dontRely: dontRely
                }
            },
        })
        let idNotice = 'meeting_notice_' + uuidv4();
        let dataNotice = {
            id: idNotice,
            isRead: false,
            isClick: false,
            createdAt: moment().utc().format(),
            updatedAt: moment().utc().format(),
            meetingId: meetingId,
            name: groupName,
            content: subject || "",
            nickname: user.nickname || "",
            last_name: user.last_name || "",
            first_name: user.first_name || "",
            avatar: user.avatar || "",
            start: evt.start || "",
            end: evt.end || "",
            messageId
        };

        const noticeDocRef = db.doc(`${CALENDAR_COLLECTION}/${usr.id}/notifications/${idNotice}`);
        batch.set(noticeDocRef, dataNotice);
        await batch.commit()
    } catch (e) {
        console.log(e);
    }

}

