import {getGlobal, setGlobal} from 'reactn'
import {db} from "../../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {isUndefined, omitBy} from "lodash";
import moment from "moment";
import {refetchSource} from "../../../actions/refetchSourcce";
import {snapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';

export const updateMeetingEvent = async (event, id) => {
    try {
        // console.log("event update", event);
        const {user} = snapshot(userProxy)
        if (!user.user_id)
            return
        const batch = db.batch()
        let data = {
            ...event,
            start: moment(event.start).utc().format(),
            end: moment(event.end).utc().format(),
            userId: user.user_id,
            uuid: event.id,
            updatedAt: moment().utc().format()
        }
        // console.log({data});
        const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/eventBoxMeeting/${event.id || event.uuid}`)

        batch.set(eventRef, omitBy(data, isUndefined), {merge: true})
        await batch.commit()
    } catch (e) {
        console.log(e);
    }
}

export const getVoteBoxList = async (userId, callback) => {
    try {
        if (!userId)
            return null
        return db.collection(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting`)
            .where("isDeleted", "==", false)
            .onSnapshot(querySnapshot => {
                const events = []
                querySnapshot.forEach(function (doc) {
                    if (!doc.data().isSync) {
                        let evt = {
                            ...doc.data(),
                            id: doc.id,
                            borderColor: "rgb(87, 170, 221)",
                            // holiday: true,
                            busy: true,
                            isEventVote: doc.data().isEventVote || false,
                            isAccepted: doc.data().isAccepted || false,
                            editable: !(doc.data().meetingId && !doc.data().isAccepted),
                        }
                        if (!evt.isAccepted) {
                            evt.backgroundColor = "rgb(87, 170, 221)"
                        }
                        events.push(evt);
                    }
                });
                setGlobal({
                    voteEvents: events
                }, () => {
                    refetchSource('vote')
                })
                if (callback) {
                    callback(events)
                }
            })
    } catch (e) {
        console.log(e);
        return null
    } finally {
    }
}

export const getVoteEvents = (callback) => {
    const {user} = snapshot(userProxy)
    if (!user.user_id) return
    return db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/eventBoxMeeting`)
        .where("isDeleted", "==", false)
        .onSnapshot(querySnapshot => {
            if (typeof callback === "function")
                callback(querySnapshot)
        })
}

