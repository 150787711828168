import React from 'react';
import {GridContextProvider, GridDropZone, GridItem, swap} from 'react-grid-dnd';
import {IconButton, makeStyles} from '@material-ui/core';
import {Checkbox} from 'antd';
import {APP_GLOBAL_ID} from '../../config/constants';
import {getGlobalUrls} from '../../common/firebaseRef/globalnavi';
import {useGlobal} from 'reactn';
import {isEmpty} from 'lodash';
import faviconFetch from 'favicon-fetch';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import useIntlId from '../../hooks/useIntlId';
import {db} from '../../config/firebaseConfig';
import firebase from 'firebase/compat';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

function DragGeniamUrl(props) {
    const classes = useStyles()
    const {user} = useSnapshot(userProxy)
    const [globalApps,] = useGlobal('userGeniamApps')
    const [userLinks,] = useGlobal('userLinks')
    const {lock} = useSnapshot(announcementProxy);

    const [otherUrl] = useIntlId(['calenview.otherUrl'])
    const {setItem, setOpenEdit, setOpenDelete} = props

    const handleClickOpenEdit = item => () => {
        setOpenEdit(true)
        setItem(item)
    };
    const handleClickConfirm = item => () => {
        setOpenDelete(item)
    };
    const onChange = async (sourceId, sourceIndex, targetIndex) => {
        try {
            const result = swap(globalApps, sourceIndex, targetIndex);
            const batch = db.batch()
            if (!user?.user_id) return;
            [...result].forEach((val, i) => {
                let ref = getGlobalUrls(user.user_id).doc(val.id)
                batch.set(ref, {
                    ...val, geniamApp: true, index: i,
                })
            })
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    };
    const onChangeUserUrl = async (sourceId, sourceIndex, targetIndex) => {
        try {
            const result = swap(userLinks, sourceIndex, targetIndex);
            const batch = db.batch()
            if (!user?.user_id) return;
            [...result].forEach((val, i) => {
                let ref = getGlobalUrls(user.user_id).doc(val.id)
                batch.set(ref, {
                    ...val, index: i,
                })
            })
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }
    const handleChangeGlobal = async (item, value, geniamApp) => {
        try {
            if (!item || !user?.user_id) return null;
            const ref = getGlobalUrls(user.user_id).doc(item.id)
            await ref.set({
                checked: firebase.firestore.FieldValue.delete(), global: value, geniamApp: geniamApp,
            }, {merge: true})
        } catch (e) {
            console.log(e)
        }
    };
    const handleFavoriteAppGlobal = async (item, value, geniamApp) => {
        try {
            if (!item || !user?.user_id) return null;
            const ref = getGlobalUrls(user.user_id).doc(item.id)
            await ref.set({
                show: firebase.firestore.FieldValue.delete(), favorite: {
                    calenview: value
                }, geniamApp: geniamApp,
            }, {merge: true})
        } catch (e) {
            console.log(e)
        }
    };
    const handleShortLinkAppGlobal = async (item, value, geniamApp) => {
        try {
            if (!item || !user?.user_id) return null;
            const ref = getGlobalUrls(user.user_id).doc(item.id)
            await ref.set({
                [APP_GLOBAL_ID]: firebase.firestore.FieldValue.delete(), shortLink: {
                    calenview: value
                }, geniamApp: geniamApp,
            }, {merge: true})
        } catch (e) {
            console.log(e)
        }
    };

    const heightGlobalUrl = globalApps.length * 50
    const heightUserUrl = userLinks.length * 50

    return (
        <>
            <div>
                <GridContextProvider onChange={onChange}>
                    <GridDropZone
                        id={"item"}
                        boxesPerRow={1}
                        rowHeight={50}
                        style={{height: heightGlobalUrl + 10}}
                    >
                        {
                            globalApps.map((item, index) => {
                                if (!item?.id)
                                    return null
                                return (
                                    <GridItem key={item.id}>
                                        <div className={classes.group}>
                                            <IconButton
                                                onClick={() => {
                                                    window.open(item.url, "_blank")
                                                }}>
                                                <img className={classes.iconAppy}
                                                     src={item.icon} alt={""}/>
                                            </IconButton>
                                            <p className={classes.name}>{item.name}</p>
                                            <p className={classes.text}
                                               onClick={() => {
                                                   window.open(item.url, "_blank")
                                               }}>
                                                {item.text}</p>
                                            <div className={classes.checkBoxsAppy}>
                                                <Checkbox
                                                    id={item.id}
                                                    checked={item?.shortLink?.[APP_GLOBAL_ID]}
                                                    onChange={() => handleShortLinkAppGlobal(item, !item.shortLink?.[APP_GLOBAL_ID], true)}
                                                    className={classes.checkBox}
                                                    disabled={lock}/>

                                                <Checkbox
                                                    id={item.id}
                                                    checked={item?.favorite?.[APP_GLOBAL_ID]}
                                                    onChange={() => handleFavoriteAppGlobal(item, !item.favorite?.[APP_GLOBAL_ID], true)}
                                                    className={classes.checkBox}
                                                    disabled={lock}
                                                />
                                                <Checkbox
                                                    id={item.id}
                                                    checked={item.global}
                                                    onChange={() => handleChangeGlobal(item, !item.global, true)}
                                                    className={classes.checkBox}/>
                                            </div>
                                        </div>
                                    </GridItem>
                                )
                            })
                        }
                    </GridDropZone>
                </GridContextProvider>

            </div>
            <div className={classes.titleServices}>{otherUrl}</div>
            {
                <GridContextProvider onChange={onChangeUserUrl}>
                    <GridDropZone
                        boxesPerRow={1}
                        rowHeight={50}
                        id={'url'}
                        style={{height: heightUserUrl + 10}}>
                        {
                            userLinks.map((item, index) => {
                                if (isEmpty(item))
                                    return null
                                return (
                                    <GridItem key={item.id}>
                                        <div className={classes.group} key={index}>
                                            <IconButton
                                                onClick={() => {
                                                    window.open(item.url, "_blank")
                                                }}>
                                                <img className={classes.iconAppy}
                                                     src={item?.url ? faviconFetch({uri: item.url}) : item?.icon}
                                                     alt={""}/>
                                            </IconButton>
                                            <p className={classes.name}>{item.name}</p>
                                            <p className={classes.text}
                                               onClick={() => {
                                                   window.open(item.url, "_blank")
                                               }}>
                                                {item.url}
                                            </p>
                                            <div className={classes.checkBoxContainer}>
                                                <Checkbox
                                                    id={item.id}
                                                    checked={item.shortLink?.[APP_GLOBAL_ID]}
                                                    onChange={() => handleShortLinkAppGlobal(item, !item.shortLink?.[APP_GLOBAL_ID], false)}
                                                    className={classes.checkBox + ' ' + classes.hidden}
                                                    disabled={true}
                                                />
                                                <Checkbox
                                                    id={item.id}
                                                    checked={item.favorite?.[APP_GLOBAL_ID]}
                                                    onChange={() => handleFavoriteAppGlobal(item, !item.favorite?.[APP_GLOBAL_ID], false)}
                                                    className={classes.checkBox}
                                                    disabled={lock}
                                                />
                                                <Checkbox
                                                    id={item.id}
                                                    checked={item.globalCallback}
                                                    onChange={() => handleChangeGlobal(item, !item.globalCallback, false)}
                                                    className={classes.checkBox + ' ' + classes.hidden}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className={classes.changeBtns}>
                                                <IconButton
                                                    variant="outlined"
                                                    onClick={handleClickOpenEdit(item)}
                                                    className={classes.editBtn}>
                                                    <img src={"/image/edit.svg"} alt=""/>
                                                </IconButton>
                                                <IconButton
                                                    variant="outlined"
                                                    onClick={handleClickConfirm(item)}
                                                    className={classes.deleteBtn}>
                                                    <DeleteSharpIcon/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </GridItem>
                                )
                            })
                        }
                    </GridDropZone>
                </GridContextProvider>
            }
        </>
    );
}

const useStyles = makeStyles(theme => ({
    iconAppy: {
        width: 25, height: 25
    }, name: {
        maxWidth: 160,
        padding: "0px 20px",
        minWidth: 160,
        marginBottom: "0px !important",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    }, text: {
        paddingRight: 20,
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "&:hover": {
            color: theme.props.primaryColor
        },
        cursor: "pointer",
        marginBottom: "0px !important"
    }, checkBoxsAppy: {
        display: "flex", paddingRight: 114
    }, checkBox: {
        marginLeft: "25px !important",
    }, group: {
        borderBottom: '1px solid #f1f1f1', alignItems: "center", display: "flex",
    }, titleServices: {
        textAlign: "center",
    },
    checkBoxContainer: {
        display: "flex",
        // marginRight: 97
        marginRight: 14
    },
    hidden: {
        visibility: "hidden"
    },
    changeBtns: {
        display: "flex"
    }, editBtn: {
        "& .MuiIconButton-label": {
            height: 25, width: 25,
        },
    }, deleteBtn: {
        height: 50, width: 50,
    }, divText: {
        display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", overflow: "hidden",
    },
}))
export default DragGeniamUrl;
