import React, {useEffect, useState} from 'react';
import {Button, IconButton, TextField, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import {Close, Email, PersonAdd} from '@material-ui/icons'
import isEmail from 'validator/lib/isEmail'
import {cloneDeep, findIndex, isArray} from 'lodash'
import useIntlId from '../../../hooks/useIntlId';
import {msgInfo} from '../../../utils/msg';
import {useGlobal} from "reactn";
import {updateGoogleEvent} from '../../../actions/googleCalendar';
import {updateEvent} from '../../../actions/calendar';
import {Autocomplete} from '@material-ui/lab'

function GuestEmail({event, setEvent, oldEvent = null}) {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [attendees, setAttendees] = useState([])
    const [addIntl, inviteByEmailIntl, emailWasInvitedIntl] = useIntlId(['app.setting.add', 'cal.inviteByEmail', 'cal.emailWasInvited'])
    const [ggListAttendees] = useGlobal('ggListAttendees')
    const [geListAttendees] = useGlobal('geListAttendees')
    const listAttendees = Array.from(new Set(geListAttendees.concat(ggListAttendees))).map(e => ({value: e}))
    useEffect(() => {
        if (event.attendees && isArray(event?.attendees)) {
            setAttendees(event?.attendees)
        }
    }, [event?.attendees])

    const handleAddEmail = () => {
        let _attendees = cloneDeep(attendees)
        let idx = findIndex(_attendees, {email: email})
        if (idx === -1) {
            _attendees.push({
                email,
            })
            setAttendees([..._attendees])
            setEvent({
                ...event,
                attendees: [..._attendees]
            })
            setEmail('')
            // updateEmail(attendees)
        } else {
            msgInfo(`${emailWasInvitedIntl}`)
            setEmail('')
        }
    }
    // const handleKeyDown = (e) => {
    //     if (e.keyCode === 13 && isEmail(email)) {
    //         let idx = findIndex(attendees, {email: email})
    //         if (idx === -1) {
    //             attendees.push({
    //                 email,
    //             })
    //             // setAttendees([...attendees])
    //
    //             setEvent({
    //                 ...event,
    //                 attendees: [...attendees]
    //             })
    //             setEmail('')
    //         } else {
    //             msgInfo(`${emailWasInvitedIntl}`)
    //             setEmail('')
    //         }
    //     }
    // }
    const handleRemove = (index) => {
        let _attendees = cloneDeep(attendees)

        _attendees.splice(index, 1)
        // setAttendees([...attendees])
        setEvent({
            ...event,
            attendees: [..._attendees]
        })
        // updateEmail(attendees)
    }

    const updateEmail = async (attendees) => {
        if (oldEvent) {
            let data = {
                ...oldEvent,
                attendees: [...attendees]
            }
            if (data.is_google) {
                await updateGoogleEvent(data, data.id)
            }
            if (data.project_uuid) {
                await updateEvent(data);
            }
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <IconButton
                    disableRipple={true}
                    className={classes.icon}
                >
                    <PersonAdd/>
                </IconButton>
                <div className={classes.editor}>
                    {/*<TextField*/}
                    {/*    fullWidth={true}*/}
                    {/*    variant={"outlined"}*/}
                    {/*    className={classes.emailInput}*/}
                    {/*    value={email}*/}
                    {/*    disabled={event.is_holiday}*/}
                    {/*    onChange={e => {*/}
                    {/*        setEmail(e.target.value)*/}
                    {/*        console.log(email);}}*/}
                    {/*    onKeyDown={handleKeyDown}*/}
                    {/*    placeholder={inviteByEmailIntl}*/}
                    {/*    InputProps={{*/}
                    {/*        endAdornment: <InputAdornment>*/}
                    {/*            <Button*/}
                    {/*                variant={"outlined"}*/}
                    {/*                className={classes.addButton}*/}
                    {/*                disabled={!isEmail(email)}*/}
                    {/*                onClick={handleAddEmail}*/}

                    {/*            >{addIntl}</Button>*/}
                    {/*        </InputAdornment>*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <div className={classes.emailContainer}>
                        {/*<Autocomplete*/}
                        {/*    dropdownClassName={classes.dropdown}*/}
                        {/*    className={classes.emailInput}*/}
                        {/*    value={email}*/}
                        {/*    options={listAttendees}*/}
                        {/*    placeholder={inviteByEmailIntl}*/}
                        {/*    filterOption={(inputValue, option) =>*/}
                        {/*        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1*/}
                        {/*    }*/}
                        {/*    onChange={e => setEmail(e)}*/}
                        {/*/>*/}
                        <div className="w-full mb-2">
                            <Autocomplete
                                disablePortal
                                freeSolo
                                id="hotkey-title-input"
                                options={listAttendees}
                                placeholder={inviteByEmailIntl}
                                getOptionLabel={option => option.value}
                                sx={{width: 300}}
                                className={classes.textEmail}
                                renderOption={(option) => (
                                    <Typography>{option.value}</Typography>
                                )}
                                renderInput={(params) => <TextField {...params} placeholder={inviteByEmailIntl}/>}
                                inputValue={email}
                                onInputChange={(e, newInputValue) => {
                                    setEmail(newInputValue);
                                }}
                            />
                        </div>
                        <Button
                            variant={"outlined"}
                            className={classes.addButton}
                            disabled={!isEmail(email)}
                            onClick={handleAddEmail}

                        >{addIntl}</Button>
                    </div>


                </div>
            </div>
            <div>
                {
                    attendees.map((item, index) => {
                        return (
                            <div className={classes.item} key={index}>
                                <Email/>
                                <Typography>{item.email}</Typography>
                                <IconButton
                                    onClick={() => handleRemove(index)}
                                    disabled={event.is_holiday}
                                >
                                    <Close/>
                                </IconButton>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        // display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.MuiIconButton-root": {
            padding: "3px !important"
        },
    },
    content: {
        display: "flex",
        width: "100%",
        alignItems: "center"
    },
    editor: {
        width: "100%"
    },
    emailContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between'
    },
    emailInput: {
        '& .ant-select-selector': {
            height: '40px !important'
        },
        width: '100%'
    },
    addButton: {
        position: 'absolute',
        right: 6,
        top: 7,
        borderRadius: 10,
        backgroundColor: theme.props.primaryColor,
        color: "#fff",
        fontWeight: 700,
        border: "none",

    },
    item: {
        margin: "10px ",
        background: "#f1f1f1",
        padding: 5,
        borderRadius: 5,
        "& p": {
            fontWeight: 700,
            fontSize: 12,
            marginLeft: 10,
            flexGrow: 1
        },
        display: "flex",
        alignItems: "center"

    },
    dropdown: {
        maxHeight: 150,
        overflowY: 'auto',
        height: 50,
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },
        width: '100%',
        '& input': {
            padding: '10px 15px'
        }
    },

    textEmail: {
        border: "1px solid #C4C4C4",
        padding: "6px 0",
        marginBottom: -4,
        fontSize: '16px',

        '&& .MuiAutocomplete-inputRoot': {
            padding: 0
        },
        '& .MuiInputBase-root:before': {
            display: 'none'
        },
        '& .MuiInputBase-root:after': {
            display: 'none'
        },
        "&& input": {
            border: 'none',
            padding: '6px 12px !important',
            transition: '300ms',
            borderRadius: '3px',
            fontSize: '14px',
            '&:focus': {
                outline: 'none',
                // borderColor: '#40a9ff',
                // boxShadow: '0 0 0 2px rgb(24 144 255 / 20%)',
            }
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        }
    },
}));

export default GuestEmail;
