import {proxy} from 'valtio/vanilla';
import {refetchSource} from '../../actions/refetchSourcce';
import {cloneDeep, findIndex, isFunction, remove} from 'lodash';
import {getGlobal} from 'reactn';
import moment from 'moment';


const eventsProxy = proxy({
    geniamEvents: {},
    googleEvents: {},
    voteEvents: [],
    setVoteEvents: (data) => {
        eventsProxy.voteEvents = data
    },
    lastTitle: [],
    setGeniamEvents: (data) => {
        eventsProxy.geniamEvents = data
        refetchSource('geniam')
    },
    setGoogleEvents: (data) => {
        eventsProxy.googleEvents = data
        refetchSource('google')
    },
    setLastTitle: (lastTitleGe, lastTitleGg) => {
        if (!lastTitleGg?.length && !lastTitleGe?.length) {
            eventsProxy.lastTitle = []
        } else {
            const newArr = lastTitleGg.concat(lastTitleGe);
            const result = newArr.sort((a, b) =>
                moment(a.created).diff(b.created))
                .reverse()
                .slice(0, 5)
                .map(e => e?.title)
            const newResult = Array.from(new Set(result)).map(e => ({
                value: e
            }))
            eventsProxy.lastTitle = newResult
        }
    },
    addEvent: (event) => {
        if (event.googleEvent) {
            const clonedEvents = cloneDeep(eventsProxy.googleEvents)
            const events = clonedEvents[event.project_uuid]?.events || []
            events.push(event)
            clonedEvents[event.project_uuid] = {
                ...clonedEvents[event.project_uuid],
                events
            }
            eventsProxy.googleEvents = clonedEvents
            refetchSource('google')
            return;
        }
        const clonedEvents = cloneDeep(eventsProxy.geniamEvents)
        const events = clonedEvents[event.project_uuid] || []
        events.push(event)
        clonedEvents[event.project_uuid] = events
        eventsProxy.geniamEvents = clonedEvents
        refetchSource('geniam')
    },
    createEvent: (event, clb = null) => {
        const {googleCalendarList} = getGlobal()
        let _googleEvents = cloneDeep(eventsProxy.googleEvents)
        let _geniamEvents = cloneDeep(eventsProxy.geniamEvents)
        const {project_uuid, is_google, googleEvent} = event
        if (is_google || googleEvent) {
            let idx = findIndex(googleCalendarList, {id: project_uuid})
            if (idx === -1)
                return;
            if (_googleEvents[project_uuid] && _googleEvents[project_uuid].events) {
                _googleEvents[project_uuid].events.push(event)
            } else {
                _googleEvents[project_uuid] = {
                    id: project_uuid,
                    events: [event],
                    name: googleCalendarList[idx].summary,
                    color: googleCalendarList[idx].backgroundColor
                }
            }
            eventsProxy.googleEvents = _googleEvents
            refetchSource('google')
            return
        }
        if (_geniamEvents[project_uuid]) {
            _geniamEvents[project_uuid].events.push(event)
        } else {
            _geniamEvents[project_uuid] = {
                id: project_uuid,
                events: [event]
            }
        }
        eventsProxy.geniamEvents = _geniamEvents
        refetchSource('geniam')
    },

    deleteEvent: (event, callback, identity = null) => {
        let ggEvents = cloneDeep(eventsProxy.googleEvents);
        let geEvents = cloneDeep(eventsProxy.geniamEvents);
        let voteEvents = cloneDeep(eventsProxy.voteEvents);
        const {is_google, id, googleEvent} = event;
        const compare = identity ? identity : {id: id}
        if (!event.project_uuid) {
            remove(voteEvents, compare)
            eventsProxy.voteEvents = voteEvents;
            return;
        }
        if (is_google || googleEvent) {
            Object.keys(ggEvents).forEach(key => {
                if (ggEvents[key]?.events?.length) {
                    remove(ggEvents[key].events, compare)

                }
            });
            eventsProxy.googleEvents = ggEvents;
            if (isFunction(callback)) {
                callback(event)
            }
            return
        }
        Object.keys(geEvents).forEach(key => {
            if (geEvents[key]?.events?.length) {
                remove(geEvents[key].events, compare)

            }
        })
        eventsProxy.geniamEvents = geEvents;
        if (isFunction(callback)) {
            callback(event)
        }
    }
})

export {
    eventsProxy
}
