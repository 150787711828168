import {get} from 'lodash'

const getExtendedProps = (info, field) => {
    if (!info?.event?.extendedProps)
        return null
    if (!field)
        return info?.event?.extendedProps
    return get(info?.event?.extendedProps, field)
}
export default getExtendedProps
