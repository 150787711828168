import {db} from '../../config/firebaseConfig';
import {SERVICE_COLLECTION} from '../../config/constants';

export const getMySerCollRef = () => {
    return db.collection(SERVICE_COLLECTION)
}

export const getMySerDocRef = (docId) => {
    return db.collection(SERVICE_COLLECTION)
        .doc(docId)
}

