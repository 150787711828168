import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useIntlId from '../../../hooks/useIntlId';
import {makeStyles} from '@material-ui/core/styles';

function HandleChangeView({viewCalendar, handleChangeView, itemTheme}) {
    const [ monthText, dayText, weekText] = useIntlId(['customView.month', 'customView.day', 'customView.week'])
    const classes = useStyles()
    return (
        <Tabs value={viewCalendar}
              onChange={handleChangeView}
              className={classes.tabs}
        >
            <Tab className={`${classes.tab} fontSourceHanSans`}
                 label={monthText}
                 value={"CustomMonth"}
                 style={{backgroundColor: viewCalendar === "CustomMonth" ? `${itemTheme?.colorPrimary}` : ""}}/>
            <Tab className={`${classes.tab} fontSourceHanSans`}
                 label={weekText}
                 value={"CustomWeek"}
                 style={{backgroundColor: viewCalendar === "CustomWeek" ? `${itemTheme?.colorPrimary}` : ""}}/>
            <Tab className={`${classes.tab} fontSourceHanSans`}
                 label={dayText}
                 value={"CustomDay"}
                 style={{backgroundColor: viewCalendar === "CustomDay" ? `${itemTheme?.colorPrimary}` : ""}}/>
        </Tabs>
    );
}

export default HandleChangeView;
const useStyles = makeStyles(theme => ({
    tab: {
        minWidth: "unset",
        width: "33.33%",
        textTransform: "uppercase",
        fontWeight: 700,
        borderRadius: 5,
        fontSize: 14,
        padding: 0,
        minHeight: "unset",
        height: 32,
        color: "#444",
    },
    tabs: {
        backgroundColor: "#fff",
        borderRadius: 7,
        padding: 3,
        margin: "0 3px",
        minHeight: "unset",
        '& .Mui-selected': {
            background: theme.props.primaryColor,
            color: "#fff"
        },
        "& .MuiTabs-scroller > span": {
            display: "none"
        }
    },
}))
