import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';
import {useDeferredValue, useEffect} from 'react';
import {getGeniamProjects} from '../context/common/getProjects';
import remove from 'lodash/remove'
import {projectsProxy} from '../store/proxy/projects.proxy';
import {refetchSource} from '../actions/refetchSourcce';

export const useGeniamProjects = () => {
    const {user} = useSnapshot(userProxy)
    const {setGeniamProjects, geniamProjects} = useSnapshot(projectsProxy)
    const deferredGeniamProjects = useDeferredValue(geniamProjects)
    useEffect(() => {
        if (!user?.user_id)
            return;
        const sub = getGeniamProjects(user.user_id, (err, snap) => {
            if (err)
                return;
            const data = snap.docs.map(doc => ({...doc.data(), id: doc.id}))
            remove(data, item => item.is_sync)
            setGeniamProjects(data)
        })

        return () => {
            if (sub)
                sub()
        }
    }, [user?.user_id])

    useEffect(() => {
        refetchSource('geniam')
    }, [deferredGeniamProjects])

}
