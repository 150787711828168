import React from 'react';
import {ReactComponent as CheckBoxActiveSVG} from "../../asSvg/icons/checkBoxActive.svg";
import {ReactComponent as CheckBoxSVG} from "../../asSvg/icons/checkBox.svg";
import {Typography} from "@material-ui/core";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import {makeStyles} from "@material-ui/core/styles";
import {useGlobal} from "reactn";
import {Button, Tooltip} from "antd";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import {APPOINTMENT_URL} from "../../config/constants";
import LinkOutlined from "@ant-design/icons/lib/icons/LinkOutlined";
import {isFunction} from 'lodash'
import useIntlId from '../../hooks/useIntlId';
import MessageOutlined from "@ant-design/icons/lib/icons/MessageOutlined";
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';

function TitleEventRender({
                              onChangeSuccess,
                              setEvent,
                              event,
                              handleBlur,
                              setEditTitle,
                              titleEvent,
                              handleKeyDown,
                              editTitle,
                              editInfo,
                              setIsEmailOnly,
                              setAcceptEvent,
                          }) {
    const classes = useStyle()
    const {colors} = useSnapshot(themesProxy);
    const [goToEventIntl, sendEmailIntl, gotoMessage] = useIntlId(['email.message.btn.gotoEvent', 'event.create.sendEmail', 'event.btnGoToMessage'])
    // console.log(event);
    return (
        <div className={classes.root}>
            <div className={classes.gridTitle}>

                {
                    event?.task === "AssignmentTurnedIn" && !event?.is_holiday ?
                        event?.isSuccess ?
                            <CheckBoxActiveSVG
                                style={{
                                    fill: event.backgroundColor !== '' ? event.backgroundColor : colors.primary,
                                    marginLeft: 4,
                                    marginRight: 10
                                }}
                                onClick={onChangeSuccess}
                            />
                            :
                            <CheckBoxSVG
                                style={{
                                    fill: event.backgroundColor !== '' ? event.backgroundColor : colors.primary,
                                    marginLeft: 4,
                                    marginRight: 10
                                }}
                                onClick={onChangeSuccess}
                            />
                        :
                        ''
                }
                {
                    editTitle ?
                        <input type="text"
                               id={"edit-title"}
                               autoFocus={true}
                               className={classes.textFieldName}
                               value={event?.title ? event.title : ''}
                               onChange={e => {
                                   setEvent({
                                       ...event,
                                       title: e.target.value
                                   })
                               }}
                               onKeyDown={(e) => handleKeyDown(e)}
                               onBlur={handleBlur}
                               disabled={event?.is_holiday}
                               placeholder={titleEvent}
                        />
                        :
                        <div className={classes.inputHeight}>
                            <Typography variant='h5' component='b' className={classes.typography}
                                        onClick={() => {
                                            if (event?.is_holiday)
                                                return
                                            setEditTitle(true)
                                        }}>
                                {event && event.title} {event?.is_holiday ?
                                <span
                                    className={classes.redText}> (read only) </span> : ""} {event && event?.isAccepted ?
                                <DoneRoundedIcon
                                    className={`${classes.checkbox} ${classes.doneRoundIconGreen}`}/>
                                :
                                ''
                            }
                            </Typography>
                        </div>

                }
            </div>
            {
                event?.users?.length &&
                <div>

                    <div className={classes.grounpBtn}>
                        <Tooltip
                            title={sendEmailIntl}
                            overlayStyle={{zIndex: 99999}}
                        >
                            <Button
                                className={classes.btnAnt}
                                type="primary"
                                onClick={() => {
                                    if (isFunction(setIsEmailOnly)) {
                                        setIsEmailOnly(true)
                                    }
                                    if (isFunction(setAcceptEvent)) {
                                        setAcceptEvent(event)
                                    }
                                }}
                                disabled={event && event.is_holiday}
                                icon={<MailOutlined/>}
                            >
                            </Button>
                        </Tooltip>
                        <Tooltip
                            overlayStyle={{zIndex: 99999}}
                            title={goToEventIntl}>

                            <Button
                                className={classes.btnAnt}
                                type="primary"
                                onClick={() => {
                                    window.open(`${APPOINTMENT_URL}/event?id=${editInfo.event.meetingId}`, "_blank")
                                }}
                                icon={<LinkOutlined/>}
                            >
                            </Button>
                        </Tooltip>
                        <Tooltip
                            overlayStyle={{zIndex: 99999}}
                            title={gotoMessage}>
                            <Button
                                className={classes.btnAnt}
                                type="primary"
                                onClick={() => {
                                    window.open(`${APPOINTMENT_URL}/event?id=${editInfo.event.meetingId}&&is_Open_Message`, "_blank",)
                                }}
                                icon={<MessageOutlined/>}
                            >
                            </Button>
                        </Tooltip>
                    </div>

                </div>
            }
        </div>
    );
}

export default TitleEventRender;
const useStyle = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        overflow: "hidden"
    },
    gridTitle: {
        alignItems: 'center',
        width: "100%",
    },
    textFieldName: {
        border: "none",
        fontSize: '1.5rem',
        borderBottom: "1px solid #C4C4C4",
        display: "block",
        width: "100%",
        padding: "6px 0",
        "&:focus": {
            outline: "none",
            borderBottomColor: theme.props.primaryColor
        }
    },
    inputHeight: {
        // height: 41,
        display: 'block',
        width: "100%",
    },
    typography: {
        cursor: "pointer",
        whiteSpace: "nowrap",
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    redText: {
        color: "red"
    },
    doneRoundIconGreen: {
        color: 'rgb(51, 153, 51)'
    },
    btnAnt: {
        margin: 5
    },
    grounpBtn: {
        display: "flex",
        flexDirection: "row"
    }
}))
