import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import CKEditor from "ckeditor4-react";
import useDebounce from 'react-use/lib/useDebounce';
import {IconButton} from '@material-ui/core';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ReactComponent as SaveSvg} from '../../../asSvg/saveBGPrimary.svg';
import {ReactComponent as EditSVG} from '../../../asSvg/icons/edit.svg';
import useIntlId from '../../../hooks/useIntlId';
import {useCtrlEnter} from 'use-hot-key-element';
import {isFunction} from 'lodash';
import {publicIcon} from "../handlers/infoEvent";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.MuiIconButton-root": {
            padding: "3px !important"
        },
        "& svg": {
            fill: theme.props.primaryColor
        }
    },
    content: {
        display: "flex",
        width: "100%",
        justifyContent: 'space-between'
    },
    editor: {
        width: "calc(100% - 30px)"
    },
    lineHeight: {
        lineHeight: "36px",
        cursor: "pointer",
        padding: "0px 12px",
        borderBottom: "1px solid #C4C4C4",
    },
    descrText: {
        cursor: "pointer",
        padding: "10px 35px 10px 10px",
        backgroundColor: "#F1F1F1",
        textAlign: 'left',
        position: "relative",
        "& p": {
            margin: 0,
        },
        "& .iconEdit": {
            fill: theme.props.primaryColor,
            position: "absolute",
            right: "3px",
            top: "50%",
            transform: "translateY(-50%)",
            opacity: 0
        },
        "&:hover": {
            "& .iconEdit": {
                opacity: "1 !important"
            }
        },
    },
    breakWord: {
        overflowWrap: 'break-word'
    }
}));

function Memo({event, setEvent, callBack = null}) {
    const classes = useStyles();
    const [description, setDescription] = useState(event?.description || null);
    const [open, setOpen] = useState(false)
    const [addDescriptionIntl] = useIntlId(['event.userDescription'])
    const [isQuickSaved] = useCtrlEnter('.ck.ck-content')

    useEffect(() => {
        if (!isQuickSaved) return;
        setDescription(description?.replace(new RegExp("<p>&nbsp;</p>" + '$'), '') || '')
        setOpen(!open)

        // eslint-disable-next-line
    }, [isQuickSaved])

    // useEffect(() => {
    // }, [description])
    // useEffect(() => {
    //     if (callBack && typeof callBack === 'function')
    //         setDescription(event?.description)
    //     // eslint-disable-next-line
    // }, [event?.description])

    useDebounce(() => {
        if (!event || event?.is_holiday || description === event.description)
            return null;
        setEvent({
            ...event, description: description || ''
        })
        if (isFunction(callBack)) callBack(description)
    }, 500, [description]);

    const onEditDescription = () => {
        setOpen(!open)
    }

    if (!event || event?.is_holiday)
        return null;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <IconButton
                    disableRipple={true}
                    onClick={onEditDescription}
                    className={classes.icon}>
                    {
                        open && <SaveSvg/>
                    }
                    {
                        !open &&
                        <img
                            src={publicIcon('addDescription.svg')}
                            alt="addDescription"
                        />
                    }
                </IconButton>

                <div className={classes.editor}>
                    {
                        open ?
                            <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log(data);
                                    setDescription(data)
                                }}
                                // onBlur={(event, editor) => {
                                //     setOpen(false)
                                // }}
                                // onFocus={(event, editor) => {
                                //     console.log('Focus.', {editor, event});
                                // }}
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                                    startupFocus: true,
                                }}
                                onReady={editor => {
                                    if (editor)
                                        editor.editing.view.focus();
                                }}
                            />
                            : (
                                description ?
                                    <div className={classes.descrText}>
                                        <div dangerouslySetInnerHTML={{__html: description}}
                                             className={classes.breakWord}/>
                                        <EditSVG onClick={() => setOpen(true)} className={`iconEdit`}/>
                                    </div>
                                    :

                                    <div className={classes.lineHeight}
                                         onClick={() => setOpen(true)}>{addDescriptionIntl}
                                    </div>
                            )
                    }

                </div>
            </div>
        </div>
    );
}

export default Memo;
