import React, {createContext, useContext, useEffect, useState} from 'react'
import {useGlobal} from 'reactn';
import {getMySerCollRef} from '../common/firebaseRef/serviceguide';
import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';

export const ServiceGuideContext = createContext(null)

export const useServiceGuide = () => useContext(ServiceGuideContext)

export const ServiceGuideProvider = ({children}) => {
    const value = useGuides()
    return (
        <ServiceGuideContext.Provider value={value}>
            {children}
        </ServiceGuideContext.Provider>
    )
}


const useGuides = () => {
    const [guides, setGuides] = useState([])
    const {user} = useSnapshot(userProxy)
    useEffect(() => {
        let sub
        if (!user?.user_id)
            return
        sub = getMySerCollRef()
            .where("isDeleted", "==", false)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({
                    ...doc.data(), id: doc.id
                }))
                setGuides(data)
            })

        return () => {
            if (sub) {
                sub()
            }
        }
        // eslint-disable-next-line
    }, [user?.user_id])

    return {guides, setGuides}
}
