import {useEffect} from 'react';
import {db} from "../config/firebaseConfig";
import {chunk, cloneDeep, isEqual} from "lodash";

import usePrevious from "react-use/lib/usePrevious";
import {useSnapshot} from 'valtio';
import {projectsProxy} from '../store/proxy/projects.proxy';

function useGoogleCalendarWatch2(callback) {

    const {googleProjects = []} = useSnapshot(projectsProxy)
    const emails = cloneDeep(googleProjects).map(e => e.id)
    const prevEmails = usePrevious(emails)


    useEffect(() => {
        // console.log({emails})
        if (!emails?.length) return;
        if (!isEqual(prevEmails, emails))
            return;
        const emailChunks = chunk(emails.filter(x => x), 10)
        // console.log({emailChunks})
        const unsubs = emailChunks.map(_10email => {
            return db.collection('googleCalendarWatch')
                .where('email', 'in', _10email)
                .onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type !== 'modified') return;
                        const data = change.doc.data()
                        // console.log(change.type, data.email)
                            if (callback) {
                                callback(data)
                            }
                        })
                    })
            }
        )
        return () => {
            unsubs?.length && unsubs.forEach(u => {
                // console.log('unsub', u);
                u()
            })
        }
        // eslint-disable-next-line
    }, [emails])
}

export default useGoogleCalendarWatch2;
