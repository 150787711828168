

export const cleanSpaceStr = (str) => {
    while (str.indexOf("\t") > -1) {
        str = str.replace("\t", " ");
    }
    while (str.indexOf("  ") > -1) {
        str = str.replace("  ", " ");
    }
    return str;
}
