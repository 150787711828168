import {getGlobal} from 'reactn'
import {cloneDeep, findIndex} from 'lodash'
import {getColorId} from "../../actions/googleCalendar";
import {getTaskUrl, isImageTask, isMaterialIcon} from "../../components/Calendar/handlers/getIcon";
import moment from 'moment';
import {snapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';

export function DataProject(props) {
    const {calendarUser} = snapshot(userProxy)
    const {geniamProjects, googleProjects} = snapshot(projectsProxy)
    // remove holiday in milestone
    const googleSettings = calendarUser?.googleSettings || {}

    let datas = []
    let hour = 0
    if (props.length === 0) return datas
    props.forEach((data) => {
        if (data && !Boolean(data.allDay)) {
            if (data.task === 'AssignmentTurnedIn') {
                let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)

                // Xử lý dữ liệu Service
                if (data.project_uuid) {
                    let sevice = [...geniamProjects].filter(s => s.uuid === data.project_uuid)[0]
                    if (sevice) {
                        const idx = findIndex(datas, {'uuid': sevice.uuid})
                        if (idx !== -1) {
                            datas[idx].value = data.isSuccess ? datas[idx].value + totalHour : datas[idx].value
                            datas[idx].sum = datas[idx].hour + totalHour
                            datas[idx].hour = datas[idx].hour + totalHour
                        } else {
                            datas.push({
                                uuid: sevice.uuid,
                                id: sevice.uuid,
                                label: sevice.name,
                                name: sevice.name,
                                value: data.isSuccess ? totalHour : 0,
                                hour: hour + totalHour,
                                color: data.borderColor,
                                sum: hour + totalHour
                            })
                        }
                    } else {
                        if (data.googleEvent) {
                            let cal_idx = findIndex(googleProjects, {'id': data.project_uuid})
                            if (cal_idx !== -1) {
                                const idx = findIndex(datas, {'uuid': data.project_uuid})
                                const linkInfo = googleSettings[data.project_uuid] || {}
                                if (idx !== -1) {
                                    datas[idx].value = data.isSuccess ? datas[idx].value + totalHour : datas[idx].value
                                    datas[idx].sum = datas[idx].hour + totalHour
                                    datas[idx].hour = datas[idx].hour + totalHour
                                } else {
                                    datas.push({
                                        id: data.project_uuid,
                                        uuid: data.project_uuid,
                                        label: linkInfo.code || googleProjects[cal_idx].summary.substr(0, 2),
                                        name: googleProjects[cal_idx].summary,
                                        value: data.isSuccess ? totalHour : 0,
                                        hour: hour + totalHour,
                                        color: data.borderColor,
                                        sum: hour + totalHour
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }

    })
    return formatTimeFromData(datas)

}

export function AllProjects(props) {
    const {geniamProjects, googleProjects} = snapshot(projectsProxy)
    // remove holiday in milestone

    let datas = []
    let hour = 0
    if (props.length === 0) return datas
    props.forEach((data, i) => {
        if (data && !Boolean(data.allDay)) {
            let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)
            // let isFetchService = false

            // Xử lý dữ liệu Service
            if (data.project_uuid) {
                let sevice = [...geniamProjects].filter(s => s.uuid === data.project_uuid)[0]
                let urlData = sevice?.otherURL || []
                if (sevice) {
                    const idx = findIndex(datas, {'uuid': sevice.uuid})
                    if (idx !== -1) {
                        datas[idx].value = data.isSuccess ? datas[idx].value + totalHour : datas[idx].value
                        datas[idx].sum = datas[idx].hour + totalHour
                        datas[idx].hour = datas[idx].hour + totalHour
                        datas[idx].otherURL = urlData
                    } else {
                        datas.push({
                            otherURL: urlData,
                            uuid: sevice.uuid,
                            id: sevice.uuid,
                            label: sevice.name,
                            name: sevice.name,
                            value: data.isSuccess ? totalHour : 0,
                            hour: hour + totalHour,
                            color: sevice.color,
                            sum: hour + totalHour
                        })
                    }
                } else {
                    if (data.googleEvent) {
                        let cal_idx = findIndex(googleProjects, {'id': data.project_uuid})
                        if (cal_idx !== -1) {
                            const {calendarUser} = snapshot(userProxy)
                            const googleSettings = calendarUser?.googleSettings || {}
                            const idx = findIndex(datas, {'uuid': data.project_uuid})
                            let urlData = googleSettings[data.project_uuid]?.otherURL || []
                            if (idx !== -1) {
                                datas[idx].value = data.isSuccess ? datas[idx].value + totalHour : datas[idx].value
                                datas[idx].sum = datas[idx].hour + totalHour
                                datas[idx].hour = datas[idx].hour + totalHour
                                datas[idx].otherURL = urlData
                            } else {
                                datas.push({
                                    id: data.project_uuid,
                                    uuid: data.project_uuid,
                                    label: googleProjects[cal_idx].summary,
                                    name: googleProjects[cal_idx].summary,
                                    value: data.isSuccess ? totalHour : 0,
                                    hour: hour + totalHour,
                                    color: data.borderColor,
                                    sum: hour + totalHour,
                                    otherURL: urlData,
                                    is_google: true
                                })
                            }
                        }
                    }
                }
            }

        }
    })
    return datas

}

export function MilestoneProjects(props) {
    let datas = []
    let hour = 0
    if (props.length === 0) return datas
    props.forEach((data, i) => {
        if (data.isMileStone && data.task === "mileStone" && data?.allDay) {
            let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)
            if (data.project_uuid) {
                const {calendarUser} = snapshot(userProxy)
                const googleSettings = calendarUser?.googleSettings || {}
                let urlData = googleSettings[data.project_uuid]?.otherURL || []
                let date1 = moment(new Date());
                let date2 = moment(new Date(data.end));
                let diff = date2.diff(date1, 'days');
                if (date2 - date1 > 0) {
                    datas.push({
                        id: data.project_uuid,
                        uuid: data.project_uuid,
                        label: data.title,
                        name: data.title,
                        value: data.isSuccess ? totalHour : 0,
                        hour: hour + totalHour,
                        sum: hour + totalHour,
                        otherURL: urlData,
                        is_google: true,
                        day: diff,
                        borderColor: data.borderColor,
                        backgroundColor: data.backgroundColor,
                    })
                }
            }
        }
    })
    return datas
}

export function FocusProjects(props) {
    let datas = []
    let hour = 0
    if (props.length === 0) return datas
    props.forEach((data, i) => {
        if (data.task === "focus") {
            let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)
            if (data.project_uuid) {
                const {calendarUser} = snapshot(userProxy)
                const googleSettings = calendarUser?.googleSettings || {}
                let urlData = googleSettings[data.project_uuid]?.otherURL || []
                datas.push({
                    id: data.project_uuid,
                    uuid: data.project_uuid,
                    label: data.title,
                    name: data.title,
                    value: data.isSuccess ? totalHour : 0,
                    hour: hour + totalHour,
                    borderColor: data.borderColor,
                    backgroundColor: data.backgroundColor,
                    sum: hour + totalHour,
                    otherURL: urlData,
                    is_google: Boolean(data.googleEvent),
                    start: data.start,
                    end: data.end,
                })
            }
        }
    })
    return datas

}

export function DataIdentity(props) {
    const {calendarUser} = snapshot(userProxy)
    const {GoogleColor} = getGlobal()
    const {identitySettings = []} = calendarUser
    let datas = []
    if (props.length === 0) return datas
    props.forEach((data) => {
        if (data && !Boolean(data.allDay)) {
            const colorId = data.colorId || getColorId(data.backgroundColor)
            // google event
            if (data.task === 'AssignmentTurnedIn' && colorId) {
                let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)
                // let isFetchIdentity = false
                let name = '(no title)'
                let settingIdx = findIndex(identitySettings, {id: parseInt(colorId)})
                if (settingIdx !== -1 && identitySettings[settingIdx]?.name) {
                    name = identitySettings[settingIdx].name
                }
                // Xử lý dữ liệu Identity

                let identity = {
                    id: parseInt(colorId),
                    label: name,
                    value: data.isSuccess ? totalHour : 0,
                    hour: totalHour,
                    color: GoogleColor[parseInt(colorId) - 1],
                    sum: totalHour,
                    name,
                    labelImgRate: process.env.PUBLIC_URL + './imgs/white.png'
                }

                let index = findIndex(datas, {'id': parseInt(colorId)})
                if (index !== -1) {
                    let rValue = datas[index].value
                    let rSum = datas[index].sum
                    let rHour = datas[index].hour
                    datas[index] = {
                        ...datas[index],
                        value: data.isSuccess ? rValue + totalHour : rValue,
                        sum: rSum + totalHour,
                        hour: rHour + totalHour,
                    }
                } else {
                    datas.push(identity)
                }


            }
        }

    })
    return formatTimeFromData(datas)
}

function formatTimeFromData(datas) {
    if (datas.length > 0) {
        datas.forEach(d => {
            d.value = Number.parseFloat(d.value).toFixed(1)
            d.hour = Number.parseFloat(d.hour).toFixed(1)
            d.sum = Number.parseFloat(d.sum).toFixed(1)
        })
    }
    return datas
}

export function AllIdentities(props) {
    const {GoogleColor} = getGlobal()
    const {calendarUser} = snapshot(userProxy)
    const {identitySettings = []} = calendarUser
    let datas = []
    if (props.length === 0) return datas
    props.forEach((data) => {
        if (data && !Boolean(data.allDay)) {
            const colorId = data.colorId || getColorId(data.backgroundColor)
            if (colorId) {
                let settingIdx = findIndex(identitySettings, {id: parseInt(colorId)})
                let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)
                if (identitySettings[settingIdx]?.isArchive)
                    totalHour = 0
                // let isFetchIdentity = false
                let name = '(no title)'
                let color = GoogleColor[parseInt(colorId) - 1]
                if (settingIdx !== -1 && identitySettings[settingIdx]?.name) {
                    name = identitySettings[settingIdx].name
                    if (!color) {
                        color = identitySettings[settingIdx].color
                    }
                }


                // Xử lý dữ liệu Identity


                let identity = {
                    id: parseInt(colorId),
                    label: name,
                    value: data.isSuccess ? totalHour : 0,
                    hour: totalHour,
                    color: color,
                    sum: totalHour,
                    name,
                    labelImgRate: process.env.PUBLIC_URL + './imgs/white.png'
                }

                let index = findIndex(datas, {'id': parseInt(colorId)})
                if (index !== -1) {
                    let rValue = datas[index].value
                    let rSum = datas[index].sum
                    let rHour = datas[index].hour
                    datas[index] = {
                        ...datas[index],
                        value: data.isSuccess ? rValue + totalHour : rValue,
                        sum: rSum + totalHour,
                        hour: rHour + totalHour,
                    }
                } else {
                    datas.push(identity)
                }


            }
        }
        // }

    })
    return datas
}

export function DataTask(props) {
    let datas = []
    if (props.length === 0) return datas
    props.forEach((data) => {
        if (data && !Boolean(data.allDay)) {
            let totalHour = (Date.parse(data.end) - Date.parse(data.start)) / (1000 * 60 * 60)
            // Xử lý dữ liệu Task
            if (data.task && isMaterialIcon(data.task)) {
                let idx = findIndex(datas, d => d.id === data.task)
                if (idx !== -1) {
                    let rHour = datas[idx].hour
                    datas[idx] = {
                        ...datas[idx],
                        hour: rHour + totalHour,
                    }
                } else {
                    datas.push({
                        id: data.task,
                        label: data.task,
                        hour: totalHour,
                    })
                }
            }
            if (data.task && isImageTask(data.task)) {
                let idx = findIndex(datas, d => d.id === data.task)
                if (idx !== -1) {
                    let rHour = datas[idx].hour
                    datas[idx] = {
                        ...datas[idx],
                        hour: rHour + totalHour,
                    }
                } else {
                    datas.push({
                        id: data.task,
                        label: data.task,
                        hour: totalHour,
                        type: "file",
                        url: getTaskUrl(data.task)
                    })
                }
            }
        }
    })
    return datas
}

