import React, {useEffect, useState} from 'react';
import moment from "moment";
import DateTimePicker from "./DateTimePicker";
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from "@material-ui/core";
import {publicIcon} from "../handlers/infoEvent";
import InputNumber from '../../Custom/InputNumber';
import {simpleDuration} from './AllDaySelectComponent';
import {zoneObj} from '../../../utils/time';


function TimeSelectComponent(props) {
    const {event, setEvent} = props;
    const classes = useStyles();
    const [showDate, setShowDate] = useState(true)
    const duration = moment(event.end).diff(event.start, event.allDay ? "day" : "hour", true);

    useEffect(() => {
        if(zoneObj(event.start).isSame(moment(event.end).add(-1), 'day')) {
            setShowDate(false)
        } else {
            setShowDate(true)
        }
    },[event.start,event.end])

    const handleChangeStart = (value) => {
        if (Boolean(event?.isConnectMetamors)) return;
        if (moment(value).isSameOrAfter(event.end)) {
            return setEvent({
                ...event,
                start: value,
                end: moment(value).add(duration, event.allDay ? "day" : "hour").format()
            })
        }
        setEvent({
            ...event,
            start: value,
            duration: moment(event.end).diff(value, event.allDay ? "day" : "hour", true)
        })

    }
    const handleChangeEnd = (value) => {
        if (Boolean(event?.isConnectMetamors)) return;
        if (moment(value).isSameOrBefore(event.start)) {
            return setEvent({
                ...event,
                end: value,
                start: moment(value).subtract(duration, event.allDay ? "day" : "hour").format()
            })
        }
        return setEvent({
            ...event,
            end: value,
            duration: moment(value).diff(event.start, event.allDay ? "day" : "hour", true)
        })
    }
    const onClickReduction = () => {
        if (duration > 0.25) {
            let num = (duration - 0.25);
            if (event.allDay) {
                num = (duration - 1) >= 1 ? (duration - 1) : 1;
            }
            let end = moment(event.start).add(num, event.allDay ? 'day' : 'hour').format()
            setEvent({
                ...event,
                duration: parseFloat(num),
                end: end
            })
        }
    }
    const onClickIncrease = () => {
        let num = event.allDay ? (duration + 1) : (duration + 0.25);
        let end = moment(event.start).add(num, event.allDay ? 'day' : 'hour').format();
        setEvent({
            ...event,
            duration: parseFloat(num),
            end: end
        })
    }
    const getEventDuration = (event) => {
        let durationStart = moment(event.start)
        let durationEnd = moment(event.end)
        let diff = durationEnd.diff(durationStart)
        let time = moment(durationEnd).diff(durationStart, "hours", true).toFixed(2)
        let dur = moment.duration(time, 'hours');
        let totaltime
        if (event.allDay) {
            totaltime = moment(durationEnd).diff(durationStart, "days", true).toFixed(2)
        }
        if (!event.allDay) {
            totaltime = Math.floor(dur.asHours()) + moment.utc(diff).format(":mm.SS");
        }
        return totaltime
    }
    if (!event)
        return null
    return (
        <div className={`timeCalendar ${classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <IconButton disableRipple={true} className={classes.icon}>
                <img src={publicIcon('AccessTime.svg')} alt=""/>
            </IconButton>
            <div className={classes.box}>
                <DateTimePicker allDay={event.allDay}
                                disabled={event.is_holiday || (event.isEventVote && event.meetingId) || Boolean(event?.isConnectMetamors)}
                                dateTime={zoneObj(event.start)}
                                onChange={handleChangeStart}/>
                <div className={classes.boxDuration}>
                    <InputNumber
                        type={'text'}
                        fill={"#000"}
                        background={"#f1f1f1"}
                        label={`${simpleDuration(getEventDuration(event))} ${event.allDay ? "d" : ""}`}
                        disabled={event.is_holiday || (event.isEventVote && event.meetingId) || Boolean(event?.isConnectMetamors)}
                        onChange={(type, event) => {
                            if (type === "degree") {
                                onClickReduction(event)
                            } else {
                                onClickIncrease(event)
                            }
                        }}
                    />
                </div>
                    <DateTimePicker allDay={event.allDay}
                                    disabled={event.is_holiday || (event.isEventVote && event.meetingId) || Boolean(event?.isConnectMetamors)}
                                    dateTime={zoneObj(event.end)}
                                    onChange={handleChangeEnd} isEnd={true} isShowDate = {showDate}/>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    box: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    polygon: {
        margin: 6,
    },
    iconSVG: {
        fill: theme.props.primaryColor
    },
    polygonSVG: {
        margin: 6,
        fill: theme.props.primaryColor
    },
    boxDuration: {
        margin: "0 5px"
    },
}));

export default TimeSelectComponent;
