import React from 'react';
import {ReactComponent as PolygonSVG} from "../../asSvg/icons/polygon.svg";
import {makeStyles} from "@material-ui/core/styles";

function FromTo(props) {
    const {from, to} = props
    const classes = useStyles()
    const classProps = props.className || ''
    return (
        <div  {...props} className={`${classes.root} ${classProps}`}>
            <div className={`${classes.content} FromToContent`}>{from}</div>
            <PolygonSVG className={`${classes.icon} ${classes.polygonIconSVG}`}/>
            <div className={`${classes.content} FromToContent`}>{to}</div>
        </div>
    );
}

export default FromTo;
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    content: {
        padding: "5px 10px",
        backgroundColor: "#fff",
        color: "#000",
        borderRadius: "999px",
        fontWeight: "bold"
    },
    icon: {
        margin: 5
    },
    polygonIconSVG: {
        fill: theme.props.primaryColor,
    }
}))
