import React from 'react';
import styled from "styled-components";
import {ReactComponent as SubtractSVG} from "../../asSvg/icons/subtract.svg";
import {ReactComponent as AddSVG} from "../../asSvg/icons/addTime.svg";
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';

const Inputs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f7f7f7;
    padding: 3px;
    font-weight: bold;
    border-radius: 24px;
    font-size: 12px;
`
const Button = styled.button(props => ({
    border: 'none',
    background: '#fff',
    margin: 0,
    width: 23,
    height: 23,
    cursor: "pointer",
    borderRadius: "50%",
    padding: 4
}))
const Content = styled.div`
    padding: 0 10px;
    font-size: 12px;
`

const StyledSubtractSVG = styled(SubtractSVG)`
    fill: ${props => props.colors};
    width: 100%;
    height: 100%;
`;
const StyledAddSVG = styled(AddSVG)`
    fill: ${props => props.colors};
    width: 100%;
    height: 100%;
`;

function InputNumberCustom({
                               value,
                               steps,
                               onChange,
                               min,
                               label = '',
                               disabled,
                               width = 100,

                           }) {
    const {colors} = useSnapshot(themesProxy);
    const onClickLeft = () => {
        if ((value - steps) >= min && !disabled)
            onChange(value - steps, 'degreement')
    }

    const onClickRight = () => {
        if (!disabled)
            onChange(value + steps, 'increment')
    }

    return (
        <Inputs width={width}>
            <Button
                onClick={onClickLeft}
            >
                <StyledSubtractSVG colors={disabled ? "#C5C5C5" : colors.primary}/>
            </Button>
            <Content>{value} <strong>{label}</strong></Content>

            <Button
                onClick={onClickRight}
            >
                <StyledAddSVG colors={disabled ? "#C5C5C5" : colors.primary}/>
            </Button>
        </Inputs>
    );
}

export default InputNumberCustom;
