import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";
import DialogCustom from "./DialogCustom";
import {v4 as uuidv4} from "uuid";
import {useGlobal} from "reactn";
import {CALENDAR_COLLECTION} from "../../config/constants";
import {db} from "../../config/firebaseConfig";
import {cloneDeep, findIndex} from 'lodash'
import useIntlId from '../../hooks/useIntlId';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import isURL from 'validator/es/lib/isURL';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';

const urls = [
    {
        id: "slack",
        name: "slack",
        url: "",
        logo: "/icons/slackIcon.svg"
    },
    {
        id: "trello",
        name: "trello",
        url: "",
        logo: "/icons/trelloIcon.svg"
    },
]

function DialogAddLinkProjects({project, openAdd, setOpenAdd}) {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const {user, calendarUser} = useSnapshot(userProxy)
    const {geniamProjects} = useSnapshot(projectsProxy)
    const [loading, setLoading] = useState(false)
    const [saveIntl, nameIntl, addUrlIntl] = useIntlId(['dialog.action.done', 'app.profile.name', 'create.addUrl'])
    const [isSaveUrl] = useKeyboardJs(['ctrl+enter', 'command+enter'])

    useEffect(() => {
        if (isSaveUrl && openAdd) {
            if (url) {
                onAdd()
            }
        }
        // eslint-disable-next-line
    }, [isSaveUrl])

    const onAdd = async () => {
        try {
            setLoading(true)
            const id = uuidv4()
            if (!url || !user?.user_id)
                return;
            // let logo = '/icons/linkURL.svg'
            // try {
            //     const URL = new window.URL(url)
            //     const origin = URL.origin
            //     await axios.get(`${origin}/favicon.ico`)
            //     logo = `${origin}/favicon.ico`
            // } catch (e) {
            //     console.log(e)
            // }
            if (project.is_google) {
                // google calendar
                let googleSettings = cloneDeep(calendarUser?.googleSettings || {})
                let projectInfo = googleSettings[project.id] || {}
                let otherURL = projectInfo?.otherURL.filter(e => {
                    return e !== null && e !== undefined
                }) || [];
                if (!otherURL.length) {
                    otherURL = [...urls, {
                        id,
                        name: name || "",
                        url: url || "",
                        logo: ""
                    }]
                } else {
                    otherURL = [...otherURL, {
                        id,
                        name: name || "",
                        url: url || "",
                        logo: ""
                    }]
                }
                projectInfo.otherURL = otherURL
                googleSettings[project.id] = projectInfo
                const ref = db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                await ref.set({
                    googleSettings
                }, {merge: true})
                return
            }
            let idx = findIndex(geniamProjects, {id: project.id})
            if (idx === -1)
                return
            let projectInfo = geniamProjects[idx]
            let {otherURL = []} = projectInfo
            if (!otherURL.length) {
                otherURL = [...urls, {
                    id,
                    name: name || "",
                    url: url || "",
                    logo: ""
                }]
            } else {
                otherURL = [...otherURL, {
                    id,
                    name: name || "",
                    url: url || "",
                    logo: ""
                }]
            }
            const ref = db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('projects')
                .doc(project.id)
            await ref.set({otherURL}, {merge: true})
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
            setName("")
            setUrl('')
            setOpenAdd(false)

        }

    }

    return (
        <div className={classes.divIconAddURL}>
            <DialogCustom open={openAdd}
                          onClose={() => setOpenAdd(false)}
                          title={addUrlIntl}
                          maxWidth={'xs'}
                          fullWidth={true}
                          propsClassRoot={classes.dialogBgColor}
                          actions={<Button
                              variant={"outline"}
                              className={classes.button}
                              disabled={!url || loading || !isURL(`${url}`,{protocols: ['http','https'], require_protocol: true})}
                              onClick={onAdd}
                          >
                              {saveIntl}
                          </Button>}
            >
                <div className={classes.item}>
                    <TextField
                        value={name}
                        label={nameIntl}
                        fullWidth={true}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        error={url ? !isURL(`${url}`,{protocols: ['http','https'], require_protocol: true}) : ''}
                        helperText={url ? (!isURL(`${url}`,{protocols: ['http','https'], require_protocol: true}) ? 'Invalid Url' : '') : ''}
                        value={url}
                        label={"URL"}
                        fullWidth={true}
                        onChange={e => setUrl(e.target.value)}
                    />
                </div>
            </DialogCustom>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    divIconAddURL: {
        marginLeft: '16%',
        textAlign: 'left',
    },
    listItems: {
        marginTop: 20,
    },
    item: {
        width: "100%",
        padding: 20
    },
    button: {
        background: theme.props.primaryColor,
        color: "#fff",
        fontWeight: 700,
        "&:hover": {
            background: theme.props.primaryColor,
            opacity: 0.7,
            color: "#fff",
        }
    },
    dialogBgColor: {
        '& .MuiBackdrop-root': {
            background: 'rgba(0,0,0,0.1) !important'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 3%), 0px 12px 24px 3px rgb(0 0 0 / 1%), 0px 9px 46px 8px rgb(0 0 0 / 1%)'
        }
    }
}))

export default DialogAddLinkProjects;
