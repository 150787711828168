import React, {useEffect, useState} from 'react';
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import {IconButton} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {handleGetValue} from '../../actions/zoom';
import {msgSuccess} from '../../utils/msg';

const copy = require('clipboard-copy');

function HangoutsMeet(props) {
    const {classes, event, setEvent, onUpdate = null} = props;
    const [linkConference, setLinkConference] = useState(null);

    useEffect(() => {
        handleGetValue(event, setLinkConference)
    }, [event])

    const handleCopy = async () => {
        try {
            if (!linkConference)
                return null;
            const link = linkConference || '';
            await copy(link)
            msgSuccess("share link copied")
        } catch (e) {
            console.log(e.toString())
        }
    };

    const handleDeleteConferenceMeeting = async () => {
        try {
            event.hangoutLink = '';
            event.conferenceData = '';
            setEvent({...event});
            if (onUpdate && typeof onUpdate === 'function')
                onUpdate(event, true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleOpenConference = () => {
        window.open(linkConference)
    };

    return (
        <div className={classes.zoomMeeting}>
            <TextField
                className={classes.copyField}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="start">
                                <Tooltip title={'Share this Hangout Meet'}>
                                    <IconButton onClick={handleCopy}> <FileCopyIcon/></IconButton>
                                </Tooltip>
                            </InputAdornment>
                            <InputAdornment position="start">
                                <Tooltip title={'Join Hangouts Meet'}>
                                    <VideocamOutlinedIcon
                                        className={classes.zoomJoin}
                                        onClick={handleOpenConference}
                                        fontSize={'large'}
                                    />
                                </Tooltip>
                            </InputAdornment>
                            <InputAdornment position="start">
                                <Tooltip title={'Remove Hangouts Meet'}>
                                    <DeleteOutlineIcon
                                        className={classes.zoomJoin}
                                        onClick={handleDeleteConferenceMeeting}
                                        fontSize={'large'}
                                    />
                                </Tooltip>
                            </InputAdornment>
                        </>
                    ),
                }}
                label={'Share this Hangouts Meet'}
                InputLabelProps={{shrink: true}}
                value={linkConference ? linkConference : ''}
                // disabled={disabled}
            />
        </div>
    )
}

export default HangoutsMeet;
