import {ToggleSuccess} from "../../../actions/calendar";
import {cloneDeep, findIndex} from "lodash";
import {refetchSource} from "../../../actions/refetchSourcce";
import {msgError} from '../../../utils/msg';
import {snapshot} from 'valtio';
import {projectsProxy} from '../../../store/proxy/projects.proxy';
import getEventInfo from '../../../common/getEventInfo';

export const onChecked = async (info, numberId) => {
    info.event.setExtendedProp('isSuccess', !info.event.extendedProps.isSuccess);
    let event = getEventInfo(info);
    const element = document.getElementById(`event-${numberId}-${event.id}`)
    if (element) {
        element.querySelector('.checkBoxRenderEvent')
    }
    // change success
    try {
        const data = await ToggleSuccess(event)
        if (event.googleEvent) {
            if (!data) {
                return refetchSource('google')
            }
            const {googleEvents, setGoogleProjects} = snapshot(projectsProxy)
            const newGoogleEvents = cloneDeep(googleEvents);
            const events = googleEvents[event.project_uuid]?.events || []
            let idx = findIndex(events, {id: event.id})
            if (idx !== -1) {
                events[idx] = {...data, project_uuid: event.project_uuid}
                newGoogleEvents[event.project_uuid].events = events
                setGoogleProjects(newGoogleEvents)
                return
            }
            if (data.recurringEventId) {
                events.push(data)
                newGoogleEvents[event.project_uuid].events = events
                setGoogleProjects(newGoogleEvents)
            }
        }
    } catch (e) {
        msgError(e.toString())
        if (element) {
            element.querySelector('.checkBoxRenderEvent')
        }
    }

}
