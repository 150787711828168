import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Spin} from "antd";

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        right: '50vh',
        marginBottom: 10,
        "& .ant-spin-dot-item": {
            backgroundColor: theme.props.primaryColor
        }
    }
}))

function SpinCustom(props) {
    const classes = useStyles()
    return (
        <Spin className={classes.root}/>
    );
}

export default SpinCustom;
