import React, {createContext, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import LangSetting from '../components/LangSetting';
import {get, isEmpty} from 'lodash';
import store from 'store';
import {fetchLangAsync} from '../common/firebaseRef/language';
import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';

export const LanguageMessageContext = createContext()

export const LanguageMessageProvider = ({children}) => {

    const msg = useMessages()

    if (isEmpty(msg))
        return <LangSetting/>
    return (
        <LanguageMessageContext.Provider value={msg}>
            {children}
        </LanguageMessageContext.Provider>
    )
}

const useMessages = () => {
    const languageDefault = 'ja-jp'
    const {user} = useSnapshot(userProxy)
    const {language} = user
    const localLang = language || languageDefault
    const msgLocal = store.get('langMsg')
    const [m, setM] = useState(get(msgLocal, localLang));

    useEffect(() => {
        if (!localLang) return
        fetchLangAsync(localLang)
            .then(langMsg => {
                setM(langMsg)
                store.set('langMsg', {[localLang]: langMsg, expired: moment().add(1, 'day')})
            }).catch(console.log)
    }, [localLang])
    return m
}

export const useLanguageMessage = () => useContext(LanguageMessageContext)
