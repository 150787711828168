import React, {useEffect, useState} from 'react';
import {ConfigProvider, DatePicker} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment'
import useIntlId from '../../../hooks/useIntlId';
import {IconButton} from '@material-ui/core';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import viVN from 'antd/lib/locale/vi_VN';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';

const {RangePicker} = DatePicker;

function SettingTimeView({
                             theme,
                             setTheme
                         }) {
    const [startDate, endDate, todayColum, viewToday] = useIntlId(['admin.startdate', 'admin.endtdate', 'calenview.settingTodayColumn', 'calenview.settingNumberOfDays'])
    const {
        indexToday,
        numsDay,
        settingToday,
        rangeDay
    } = theme
    const classes = useStyles()
    const {user} = useSnapshot(userProxy)


    const [locale, setLocale] = useState(enUS)
    const setIndexToday = (num) => {
        setTheme({...theme, indexToday: num})
    }
    const setNumsDay = (num) => {
        setTheme({...theme, numsDay: num})
    }
    useEffect(() => {
        if (user.language === "en-us") {
            setLocale(enUS)
        }
        if (user.language === "ja-jp") {
            setLocale(jaJP)
        }
        if (user.language === "vi-vn") {
            setLocale(viVN)
        }
    }, [user.language])

    const handleNumber = (type) => {
        let dataCheck = type && type === "add" ? Number(numsDay) + 1 : type && type === "remove" ? Number(numsDay) - 1 : Number(numsDay);
        if (dataCheck < 1) {
            setNumsDay(numsDay)
            return null
        }
        if (dataCheck > 99) {
            setNumsDay(numsDay)
            return null
        }
        return setNumsDay(dataCheck)
    }

    const handleColumns = (type) => {
        let dataCheck = type && type === "add" ? Number(indexToday) + 1 : type && type === "remove" ? Number(indexToday) - 1 : Number(indexToday);
        if (dataCheck < 1) {
            setIndexToday(indexToday)
            return null
        }
        if (dataCheck > numsDay) {
            setIndexToday(indexToday)
            return null
        }
        return setIndexToday(dataCheck)
    }

    const onChange = (date, dateString) => {
        setTheme({...theme, rangeDay: dateString, viewCalendar: "CustomDay"})
    }
    return (
        <div className={classes.root}>

            {
                settingToday ?
                    <div className={"flex justify-between min-h-unset items-center mb-4"}>
                        <div className={classes.numOfDate}>
                            <div>{viewToday}</div>
                            <div className={classes.number}>
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={() => handleNumber('remove')}>
                                    <img src='/imgs/newSvgs/-.svg' alt=''
                                         className={`clf-cursor-pointer ${classes.img2}`}/>
                                </IconButton>

                                <input
                                    disabled
                                    min={indexToday}
                                    max={99}
                                    value={numsDay}
                                    className={`clf-no-border ${classes.input}`}
                                />
                                <IconButton className={classes.iconButton} onClick={() => handleNumber('add')}>
                                    <img src='/imgs/newSvgs/+.svg' alt=''
                                         className={`clf-cursor-pointer ${classes.img2}`}/>
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.numOfDate}>
                            <div>{todayColum}</div>
                            <div className={classes.number}>
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={() => handleColumns('remove')}>
                                    <img src='/imgs/newSvgs/-.svg' alt=''
                                         className={`clf-cursor-pointer ${classes.img2}`}/>
                                </IconButton>

                                <input
                                    disabled
                                    min={1}
                                    max={numsDay}
                                    value={indexToday}
                                    className={`clf-no-border ${classes.input}`}
                                />
                                <IconButton className={classes.iconButton} onClick={() => handleColumns('add')}>
                                    <img src='/imgs/newSvgs/+.svg' alt=''
                                         className={`clf-cursor-pointer ${classes.img2}`}/>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={"min-h-unset items-center mb-5"}>
                        <ConfigProvider locale={locale}>
                            <RangePicker
                                format={'YYYY-MM-DD'}
                                defaultValue={[
                                    rangeDay && rangeDay[0] ? moment(rangeDay[0], 'YYYY-MM-DD') : startDate,
                                    rangeDay && rangeDay[1] ? moment(rangeDay[1], 'YYYY-MM-DD') : endDate
                                ]}
                                dropdownClassName={classes.dropdownTime}
                                className={classes.rangePicker}
                                showTime
                                onChange={(date, dateString) => onChange(date, dateString)}
                            />
                        </ConfigProvider>
                    </div>
            }
        </div>
    );
}

export default SettingTimeView;
const useStyles = makeStyles(theme => ({
    root: {},
    numOfDate: {
        display: "flex",
        height: 40,
        width: "49%",
        backgroundColor: "#fff",
        borderRadius: 5,
        textTransform: "uppercase",
        justifyContent: "space-evenly",
        alignItems: "center",
        fontWeight: 700,
        fontSize: 12,
        "& .ant-switch-checked": {
            backgroundColor: `${theme.props.primaryColor} !important`
        }
    },
    number: {
        display: 'flex',
        borderLeft: "1px solid #D8D8D8",
        alignItems: 'center',
        paddingLeft: 5
    },
    iconButton: {
        width: 20,
        height: 20,
    },
    input: {
        width: 25,
        fontSize: 15,
        textAlign: "center",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        backgroundColor: 'transparent'
    },
    switch: {
        "& .ant-switch-checked": {
            backgroundColor: `${theme.props.primaryColor} !important`
        }
    },
    rangePicker: {
        width: "100%",
        '& .ant-picker-input > input': {
            textAlign: "center"
        },
    },
    dropdownTime: {
        zIndex: "9999 !important",
        "& .ant-picker-time-panel": {
            display: "none"
        }
    },
    img2: {
        minWidth: 15
    }
}))
