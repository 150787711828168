import React, {useEffect, useState} from 'react';
import AddColumn from './AddColumn';
import AddRow from './AddRow';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';


const BoxCalendar = styled.div(props => ({
    backgroundColor: props?.backgroundColor ? `${props?.backgroundColor}` : "#f1f1f1",
    height: props?.height ? `${props?.height}px` : "50px",
    borderRadius: "5px",
    width: props?.width ? `${props?.width}%` : "30px",
    border: "solid 1px #fff",
    display: "inline-flex",
}));
const derfaultRangeDay = [moment().format('YYYY-MM-DD'), moment().add(7).format('YYYY-MM-DD')]

function PreviewCalendar({
                             rangeDay = derfaultRangeDay,
                             theme,
                             setTheme,
                         }) {
    const {colors} = useSnapshot(themesProxy)
    const classes = useStyles()
    const [days, setDays] = useState(1)

    useEffect(() => {
        if (rangeDay?.length) {
            return setDays(moment(rangeDay[1]).diff(moment(rangeDay[0]), 'days') + 1)
        }
        return setDays(0)
    }, [rangeDay])
    const newArray = (number) => {
        let newArr = []
        for (let i = 0; i < number; i++) {
            newArr.push(i)
        }
        return newArr
    }
    const setRows = (num) => {
        setTheme({...theme, rows: num})
    }
    const setColumns = (num) => {
        setTheme({...theme, columns: num})
    }
    if (!theme)
        return null;
    return (
        <div>
            {
                theme.advanceView ?
                    <div className={classes.handleView}>
                        <div className={classes.rows}>
                            {
                                theme.settingToday ?
                                    <div className={"w-full flex"}>
                                        <div className={classes.handleView}>
                                            <div className={classes.addRow}>
                                                <AddRow item={theme} rows={theme.rows} setRows={setRows}/>
                                            </div>
                                            {
                                                newArray(theme.rows).map((row, index) => {
                                                    const {numsDay = 1, indexToday = 0} = theme
                                                    return (
                                                        <div className={classes.rows} key={index}>
                                                            {
                                                                newArray(numsDay).map((column, idx2) => {
                                                                    let width = 100 / numsDay
                                                                    let height = 320 / theme.rows
                                                                    return (
                                                                        <BoxCalendar
                                                                            width={width}
                                                                            height={height}
                                                                            key={idx2}
                                                                            backgroundColor={(index === 0 && indexToday === idx2 + 1) ? theme?.colorPrimary || colors.primary : "#E3E3E3"}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                    :
                                    <div className={"w-full flex"}>
                                        {
                                            newArray(days).map((column, idx2) => {
                                                let width = 100 / days
                                                let height = 320
                                                return (
                                                    <BoxCalendar
                                                        width={width}
                                                        height={height}
                                                        key={idx2}
                                                        backgroundColor={theme?.colorPrimary || colors.primary}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <div>
                        <div className={classes.addColumn}>
                            <AddColumn item={theme}
                                       viewCalendar={theme.viewCalendar}
                                       columns={theme.columns}
                                       setColumns={setColumns}/>
                        </div>
                        <div className={classes.addRow}>
                            <AddRow item={theme} rows={theme.rows} setRows={setRows}/>
                        </div>
                        <div className={classes.handleView}>
                            {
                                newArray(theme.rows).map((row, index) => {
                                    return (
                                        <div className={classes.rows} key={index}>
                                            {
                                                newArray(theme.columns).map((column, idx2) => {
                                                    let width = 100 / theme.columns
                                                    let height = 320 / theme.rows
                                                    return (

                                                        <BoxCalendar
                                                            width={width}
                                                            height={height}
                                                            key={idx2}
                                                            backgroundColor={theme?.colorPrimary || colors.primary}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </div>
    );
}

export default PreviewCalendar;
const useStyles = makeStyles(theme => ({
    handleView: {
        maxHeight: 320,
        width: "100%"
    },
    rows: {
        display: "flex",
        flexDirection: "rows",
    },
    addColumn: {
        position: "ABSOLUTE",
        left: "50%",
        transform: "translateX(-50%)",
        marginTop: "-9px",
    },
    addRow: {
        position: "ABSOLUTE",
        top: "50%",
        transform: "translateY(-50%)",
        marginLeft: "-9px",
    },
}))
