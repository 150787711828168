export const styles = theme => ({
    month: {
        width: 65,
        height: 30,
        backgroundColor: '#3A3A3A',
        position: 'absolute',
        zIndex: 100,
        color: 'white'
    },
    events: {
        textAlign: 'center',
        height: '100%',
        // minHeight: '1em',
        position: 'relative'
    },
    icon: {
        top: '50%',
        position: 'absolute',
        left: '50%',
        margin: 0,
        transform: 'translate(-50%, -50%)',
        marginRight: '-50%',
        zIndex: 0
    },
    checkbox: {
        bottom: 3,
        // position: 'absolute',
        // left: '50%',
        margin: 0,
        padding: 0,
        // transform: 'translateX(-50%)',
    },
    checkboxAllDay: {
        top: '60%',
        position: 'absolute',
        right: -5,
        margin: 0,
        padding: 0,
        transform: 'translate(-50%, -50%)',
        marginRight: 'auto',
    },
    colorCheckbox: {
        color: 'black',
        '&$checked': {
            color: 'black',
        },
    },
    headerHtml: {
        fontSize: '0.95rem',
        // padding: 5,
        lineHeight: "21px",
        minHeight: "21px",
        boxSizing: "border-box",
        margin: "2px auto",
        [theme.breakpoints.down('md')]: {
            fontSize: '7px',
        },
    },
    isToday: {
        backgroundColor: theme.props.primaryColor,
        color: "white",
        borderRadius: "50%",
        width: 21,
        height: 21,
        margin: "2px auto",
    },
    headerHtmlDay: {
        padding: 5,
        fontSize: '1rem',
    },
    headerHtmlSmallDay: {
        fontSize: '0.8rem',
        padding: 2,
    },
    headerHr: {
        height: 1,
        width: '100%',
        backgroundColor: '#ccc',
        position: 'absolute',
        left: -1,
        [theme.breakpoints.down('md')]: {
            width: '110%',
        },
    },
    headerHtmlSmall: {
        fontSize: "1rem !important",

    },
    fontSize: {
        fontSize: '0.6vw',
        textAlign: 'left',
        [theme.breakpoints.up('xl')]: {
            fontSize: '11px',
        },
    },
    tooltip: {
        backgroundColor: 'black',
        color: 'white',
        fontSize: 12,
        margin: 2,
    },
    heightCalendar: {
        height: '100%'
    },
    checked: {},
    relative: {
        position: "relative",
        fontWeight: "bold",
    },
    colorYes: {
        color: 'rgb(248, 147, 32)'
    },
    colorRed: {
        color: 'red'
    },
    colorNo: {
        color: 'rgb(51, 153, 51)'
    }
})
