import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTheme, withStyles} from '@material-ui/core/styles';
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HeaderCenter from "../MenuAppBar/HeaderCenter";
import {Tooltip} from 'antd';
import AddIcon from '@material-ui/icons/Add';
import {openCreateEvent} from '../../common/openCreateEvent';
import useIntlId from '../../hooks/useIntlId';

const styles = theme => ({
    root: {
        backgroundColor: "#f1f1f1",
        minWidth: 20,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            background: "#fff"
        },
        display: "flex"
    },
    root2: {
        backgroundColor: "#f1f1f1",
        minWidth: 20,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            background: "#fff"
        },
        display: "flex"
    },
    tab: {
        minWidth: 0,
        padding: '6px 6px 6px'
    },
    boxMenu: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('md')]: {
            display: "none"
        }

    },
    boxMenuEvent: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }

    },
    buttonToday: {
        display: "flex",
    },
    btnMenu: {
        display: "none",
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            display: "block",
            color: "#000"
        }
    },
    btnMenuEvent: {
        display: "none",
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            display: "block",
            color: '#000'
        }
    },

    img: {
        width: "100%",
        maxWidth: 35,
        height: 21
    },
    imgToday: {
        width: 48
    },
    imgEvent: {
        cursor: 'pointer',
        maxWidth: 35,
        height: 21,
        width: '100%',
        border: '2px solid white',
        boxSizing: "border-box",
        display: "inline-block",
        verticalAlign: "middle",
        [theme.breakpoints.down('md')]: {
            background: "#3a3a3a"
        }
    },
    relative: {
        position: "relative",
    },
    activeTab: {
        border: "solid 2px red",
        padding: 3,
        display: "flex",
        marginLeft: 5
    },
    disableTab: {
        border: "solid 2px transparent",
        padding: 3,
        display: "flex"
    },
    ml5: {
        marginLeft: 5
    },
    menuPopoverContainer: {
        display: "flex",
        flexDirection: "row",
        background: "#fff",
        [theme.breakpoints.down('md')]: {
            color: '#000',
            background: "transparent",
        },
    },
    iconAntd: {
        fontSize: 25, fontWeight: 'bold'
    },
    popoverMd: {
        '& .MuiPaper-rounded': {
            backgroundColor: '#F1F1F1',
            padding: 10
        }
    },
    buttonCreate: {
        borderRadius: "50% !important",
        width: "37px !important",
        height: "37px !important",
        zIndex: 999,
        marginRight: 5,
        marginTop: 2,
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,

        // boxShadow: '0px 0px 0px 2px rgba(0, 0, 0, 0.2)',
        "& svg": {
            width: "35px !important",
            height: "35px !important",
            fill: "#fff"
        }
    },
    flex: {
        display: "flex",
        flexDirection: 'row'
    },


});


function SimpleBottomNavigation(props) {
    const [openMenu, setOpenMenu] = useState(null)
    const open = Boolean(openMenu);
    const id = open ? 'menuleft-popover' : undefined;
    const {classes} = props
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [createEvent] = useIntlId(['milestone.creatEvent'])

    const handleClick = event => {
        setOpenMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenMenu(null);
    };
    useEffect(() => {
        if (matches) {
            // setTab(2)
            setOpenMenu(null)
        }
        // eslint-disable-next-line
    }, [matches])

    const isPathnameOk = () => {
        return window.location.pathname === '/'
    }

    return (
        <div className={classes.relative}>
            <div className={classes.flex}>
                <IconButton className={isPathnameOk() ? classes.btnMenu : classes.btnMenuEvent}
                            onClick={handleClick}>
                    <MenuIcon/>
                </IconButton>
                {window.location.pathname !== '/search' &&
                    <Tooltip
                        color={theme?.props?.primaryColor}
                        style={{opacity: "0.5 !important"}}
                        title={createEvent}
                        placement={"bottomRight"}
                    >
                        <IconButton
                            className={classes.buttonCreate}
                            onClick={openCreateEvent}
                        >
                            <AddIcon/>
                        </IconButton>

                    </Tooltip>}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    root: classes.popoverMd
                }}
            >
                <HeaderCenter/>
            </Popover>
        </div>

    );
}


SimpleBottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default React.memo(withStyles(styles)(SimpleBottomNavigation))
