import moment from "moment";
import {getGlobal} from "reactn";
import {concat, uniqBy} from "lodash";
import getEventsInRange from "../../../context/common/getEventsInRange";

export function headerRender(info, classes,) {
    const isToday = moment(info).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    const isSat = moment(info).isoWeekday() === 6
    const isSun = moment(info).isoWeekday() === 7
    const style = isToday ?
        `style="background-color: black; color:white;font-size: 2.5em"`
        :
        isSun ?
            `style="color:#ff867f;font-size: 2.5em"`
            :
            isSat ?
                `style="color:#1B97F0;font-size: 2.5em"`
                :
                `style="font-size: 2.5em"`


    const style1 = isSun ?
        `style="color:#ff867f"`
        :
        isSat ?
            `style="color:#1B97F0"`
            :
            ''
    return `<div style="position: relative; border: 2px solid transparent;">
                               <div class="${classes.headerHtmlDay}  " ${style1}>${moment(info).format('ddd')}</div>
                               <div class="${classes.headerHr}"></div>
                               <div class="${classes.headerHtml} " ${style} >
                                    ${info.getDate()}
                               </div>
                            </div>`
}


export const getGoogleSelectedEvents = (events = {}, start, end) => {
    const {googleCalendarList} = getGlobal()
    let result = []
    googleCalendarList.forEach(pro => {
        if (pro.selected)
            result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end))
    })
    return uniqBy(result, "id")
}


export const getChangeTimeScheduleAdjustmentEmail = ({
                                                         oldTime,
                                                         newTime,
                                                         linkZoom,
                                                         timeZone,
                                                         timeZoneIntl,
                                                         beforeChange,
                                                         afterChange,
                                                         linkEvent,
                                                         dontRely,
                                                         btnGotoEvent,
                                                         btnJoinZoom,
                                                         zoomMeetingIntl,
                                                         content
                                                     }) => {
    return `<!DOCTYPE html>
<html lang="ja">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,500;1,100;1,400&display=swap"
          rel="stylesheet">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style>          .container {
        width: 80%;
        margin: auto;
        /*font-family: 'Montserrat', sans-serif;*/
    }

    .body_invite {
        padding: 30px 35px;
        background: #F1F1F1;
        border-radius: 12px;
    }

    .body {
        /*font-family: 'Montserrat', sans-serif;*/
        background: #F1F1F1;
        border-radius: 7px;
        margin: 0px 5px 5px 5px;
        padding: 10px;
        text-align: center;
    }

    .body_name {
        /*font-family: 'Montserrat', sans-serif;*/
        background: #F1F1F1;
        border-radius: 7px;
        padding: 10px;
        text-align: center;
    }

    .body_btn { /*display: inline-flex;*/
        justify-content: center !important;
        margin-top: 20px;
    }

    .btn {
        display: block;
        height: 30px;
        text-align: center;
        margin-right: 10px;
    }

    .btn a {
        text-decoration: none;
        color: #FFFFFF;
        font-weight: bold;
        display: inline-block;
        padding: 5px 30px;
        background: #1790FF;
        border-radius: 12px;
        text-transform: uppercase;
    }

    .footer_invite {
        padding: 10px;
        background: #F1F1F1;
        border-radius: 12px;
        text-align: center;
        margin-top: 10px;
    }

    .footer_invite_1 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .p_node {
        text-align: center;
        margin-top: 50px;
    }

    .p_footer {
        /*font-family: 'Montserrat', sans-serif;*/
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #1790FF;
    }

    .p_invite {
        background: #F1F1F1;
        border-radius: 7px;
        padding: 5px 10px;
        margin: 0px 5px;
        /*text-align: center;*/
    }

    .roomName {
        font-weight: bold;
        font-size: 20px;
    }

    .img_footer {
        width: 28px;
    }      </style>
    <title>ご注文の確認</title></head>
<body>
<div class="container">
    <div class="body_invite">
        <div class="body">
            <p class="roomName">${content}</p>
            <p class="p_invite">${beforeChange}: <span class="font_bold"> ${oldTime}</span></p><br/>
            <p class="p_invite">${afterChange}: <span class="font_bold"> ${newTime}</span></p><br/>
            <p class="p_invite">${timeZoneIntl}: <span class="font_bold"> ${timeZone}</span></p>
            <div>
                <div class="body_btn">
                    <div class="btn"><a href="${linkEvent}">${btnGotoEvent}</a></div>
                    ${linkZoom ?
        `<p class="p_btn">${zoomMeetingIntl}</p>
                    <div class="btn"><a href="${linkZoom}">${btnJoinZoom}</a></div>
                    `
        :
        ""
    }
                </div>
                <p class="p_node">${dontRely}</p>
                <div class="footer_invite">
                    <div class="footer_invite_1"><img class="img_footer"
                                                      src="https://firebasestorage.googleapis.com/v0/b/geniam-c8d4c.appspot.com/o/Group.png?alt=media&token=a6b07d2e-7937-4749-aa4d-4b576ebd39e4"
                                                      alt="favicon"> <span class="p_footer">Geniam</span></div>
                    <br> <span class="copyright">© 2021 Andom.inc All Rights Reserved.</span></div>
            </div>
        </div>
    </div>
</body>
</html>
`
}


export const validStartFreeTime = (times = []) => {
    return times.map(time => {
        if (moment(time.start).minutes() > 0 && moment(time.start).minutes() < 30) {
            return {
                ...time,
                start: moment(time.start).set({
                    minutes: 30
                }).format()
            }
        }
        if (moment(time.start).minutes() > 30) {
            return {
                ...time,
                start: moment(time.start).set({
                    minutes: 0
                }).add(1, "hours").format()
            }
        }
        return time
    })
}

export const getEmailHtml = ({
                                 title,
                                 start,
                                 end,
                                 time,
                                 modifyUrl,
                                 linkZoom,
                                 dontRely,
                                 btnGotoEvent,
                                 btnJoinZoom,
                                 timeZone,
                                 afterChange
                             }) => {
    return `<!DOCTYPE html>
<html lang="ja">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,500;1,100;1,400&display=swap"
          rel="stylesheet">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style>          .container {
        width: 80%;
        margin: auto;
        /*font-family: 'Montserrat', sans-serif;*/
    }

    .body_invite {
        padding: 30px 35px;
        background: #F1F1F1;
        border-radius: 12px;
    }

    .body {
        /*font-family: 'Montserrat', sans-serif;*/
        background: #F1F1F1;
        border-radius: 7px;
        margin: 0px 5px 5px 5px;
        padding: 10px;
        text-align: center;
    }


    .body_btn {
        /*display: inline-flex;*/
        justify-content: center !important;
        margin-top: 10px;

    }

    .btn {
        display: block;
        height: 30px;
        text-align: center;
        margin-right: 10px;
    }

    .btn a {
        text-decoration: none;
        color: #FFFFFF;
        font-weight: bold;
        display: inline-block;
        padding: 5px 30px;
        background: #1790FF;
        border-radius: 12px;
        text-transform: uppercase;
    }

    .footer_invite {
        padding: 10px;
        background: #F1F1F1;
        border-radius: 12px;
        text-align: center;
        margin-top: 10px;
    }

    .footer_invite_1 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .p_footer {
        /*font-family: 'Montserrat', sans-serif;*/
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #1790FF;
    }


    .p_node {
        text-align: center;
        margin-top: 50px;
    }

    .img_footer {
        width: 28px;
    }      </style>
    <title>ご注文の確認</title></head>
<body>
<div class="container">
    <div class="body_invite">
        <div class="body">
            <p>${title}</p>
            <p>${afterChange} : <span class="font_bold">${start} [ ${time} ]</span></p>
            <p>${timeZone}</p>
        </div>
        <div>
            <div class="body_btn">
                ${modifyUrl ?
        `
                <div class="btn"><a href="${modifyUrl}">${btnGotoEvent}</a></div>
                ` : ""}

                ${linkZoom ?
        `
                <div class="btn"><a href="${linkZoom}">${btnJoinZoom}</a></div>
                ` : ""}

            </div>
            <p class="p_node">${dontRely}</p>
            <div class="footer_invite">
                <div class="footer_invite_1"><img class="img_footer"
                                                  src="https://firebasestorage.googleapis.com/v0/b/geniam-c8d4c.appspot.com/o/Group.png?alt=media&token=a6b07d2e-7937-4749-aa4d-4b576ebd39e4"
                                                  alt="favicon"> <span class="p_footer">Geniam</span></div>
                <br> <span class="copyright">© 2021 Andom.inc All Rights Reserved.</span></div>
        </div>
    </div>
</div>
</body>
</html>
`
}
