import {getGlobal, setGlobal} from 'reactn';
import {db} from '../config/firebaseConfig';
import {CHANGE_TIME_ACTION, CREATED_ACTION, DELETED_ACTION, UPDATED_ACTION} from '../config/constants';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import {AddGoogleEvent, changeTimeGoogleEvent, parseResource, updateGoogleEvent} from './googleCalendar';
import {refetchOneSource, refetchSource} from './refetchSourcce';
import {ggTokenChecked} from '../config/auth';
import updateWatchChannelMyself from '../common/updateWatchChannelMyself';
import {checkTimeTitleEvent, handleDeleteGoogleTasksSchedule} from './googleTasks';
import {deleteZoom, handleGetValue, handleUpdateZoomMeeting} from './zoom';
import {concat, findIndex, isUndefined, omitBy, uniqBy} from 'lodash';
import {Button, notification} from 'antd';
import React from 'react';
import setGlobalGGEventsWithDataAndInfo from '../components/Calendar/handlers/setGlobalGGEventsWithDataAndInfo';
import {msgError, msgSuccess} from '../utils/msg';
import {getIntlId} from "../hooks/useIntlId";
import {myEventDocRef, myEventsCollRef} from "../common/firebaseRef/meetings";
import {isAccepted} from "../components/Calendar/handlers/infoEvent";
import {zoneObj} from '../utils/time';
import {snapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';

export const CalendarReloadOne = (i) => {
    if (window['calendar' + i]) {
        window['calendar' + i].refetchEvents()

    }
}

export const PrevActionPopup = (key = `updatable`, placement = 'bottomLeft') => {
    const onCancel = async (k) => {
        notification.close(k)
        await onRestore()
    }
    notification.success({
        message: getIntlId('common.saving'),
        bottom: 0,
        key,
        placement,

    });
    setTimeout(() => {
        notification.success({
            message: getIntlId('common.saved'),
            duration: 3,
            description: <div className={'popNotification'}>
                <Button onClick={() => onCancel(key)} type='text'>
                    {getIntlId('dialog.cancel')}
                </Button>
            </div>,
            placement,
            bottom: 0,
            key,
        });
    }, 1500);
};


export const ChangeTimeEvent = async (event, numberId) => {
    const {start, end, allDay, isMileStone, task} = event

    try {
        const time = moment().utc().format()
        const data = {
            start: moment(start).utc().format(),
            end: moment(end).utc().format(),
            allDay,
            duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
            updated_at: time,
            isMileStone: Boolean(isMileStone),
            task: task || ''
        }
        await myEventDocRef(event)
            .set(data,
                {merge: true}
            )
        return event
    } catch (e) {
        msgError(e.toString())
        return null
    }

}

export const updateEvent = async (event, isDeleteRecurrenceId = false, ignoreTime = false,) => {
    try {
        let start, end
        if (event.allDay) {
            start = zoneObj(event.start).startOf("day").utc().format()
            end = zoneObj(event.end).startOf("day").utc().format()
        } else {
            start = moment(event.start).utc().format()
            end = moment(event.end).utc().format()
        }
        event = addEventCreator(event)
        const time = moment().utc().format()
        const batch = db.batch()
        const data = {
            ...event,
            colorId: null,
            start,
            end,
            duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
            updated_at: time
        }
        const eventRef = myEventDocRef(event)
        // remove meeting info
        delete event.members
        delete event.users
        batch.set(
            eventRef,
            omitBy(data, isUndefined),
            {merge: true}
        )
        if (isDeleteRecurrenceId) {
            const snap = await myEventsCollRef()
                .where("recurringEventId", "==", event.id)
                .get()
            delete data.start
            delete data.end
            delete data.duration
            delete data.recurrence
            data.recurringEventId = event.id
            snap.docs.forEach(doc => {
                const ref = myEventDocRef({...doc.data(), id: doc.id})
                if (ignoreTime) {
                    const dataDoc = doc.data()
                    if (dataDoc?.status === "canceled")
                        return;
                    batch.set(
                        ref,
                        omitBy(data, isUndefined),
                        {merge: true}
                    )
                    return
                }
                batch.delete(ref)
            })
        }
        await batch.commit()
        return event
    } catch (e) {
        console.log(e);
        msgError(getIntlId(['event.toast.updateEventError']), {autoClose: 2000})
        return null
    }
}

export const newEvent = async (event) => {
    try {
        if (!event || !event?.start || !event?.end) return null
        const time = moment().utc().format()
        const colorId = event?.colorId || null
        const identity = event?.identity || null
        delete event?.originStart

        let _event = addEventCreator(event)
        const data = {
            ..._event,
            colorId,
            identity,
            start: moment(event.start).utc().format(),
            end: moment(event.end).utc().format(),
            duration: (moment(event.end).utc() - moment(event.start).utc()) / (60000 * 60),
            created_at: time,
            updated_at: time,
            isEventVote: event?.isEventVote || false,
            isAccepted: false,
            isDeleted: false,
            isSuccess: false,
            isTasks: false,
        }
        // console.log('data', data);
        const eventRef = myEventDocRef(event)
        await eventRef.set(omitBy(data, isUndefined))
        return event
    } catch (e) {
        console.log(e);
        msgError(getIntlId(['event.toast.addEventError']), {autoClose: 2000})
        return null
    } finally {

    }

}

export function removeURLParameter(parameter, url) {
    const fragment = url.split('#');
    const urlparts = fragment[0].split('?');

    if (urlparts.length >= 2) {
        const urlBase = urlparts.shift(); //get first part, and remove from array
        const queryString = urlparts.join("?"); //join it back up

        const prefix = encodeURIComponent(parameter) + '=';
        const pars = queryString.split(/[&;]/g);
        for (let i = pars.length; i-- > 0;) {               //reverse iteration as may be destructive
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {   //idiom for string.startsWith
                pars.splice(i, 1);
            }
        }
        url = urlBase + (pars.length > 0 ? '?' + pars.join('&') : '');
        if (fragment[1]) {
            url += "#" + fragment[1];
        }
    }
    return url;
}

export const applyDrag = (arr, dragResult) => {
    const {removedIndex, addedIndex, payload} = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        if (result.length > 1) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }
    return uniqBy(result, 'uuid')
}


export const eventShiftCopy = async (event, numberId, copyingEvent) => {
    try {
        if (!copyingEvent)
            return;
        const {allDay = false, start, end, title} = copyingEvent
        const {
            backgroundColor,
            borderColor,
            extendedProps: {
                project_uuid,
                identity = null,
                isSuccess = false,
                task = '',
                googleEvent,
                location = '',
                description = ''
            }
        } = event

        const batch = db.batch()
        // gerenate event
        const numOfEvents = moment(event.start).date() - moment(start).date()
        if (numOfEvents === 0)
            return
        const time = moment().utc().format()
        let events = []
        for (let i = 1; i <= Math.abs(numOfEvents); i++) {
            const id = `evt_${uuidv4()}`
            const evt = {
                id,
                uuid: id,
                isDeleted: false,
                isSuccess: isSuccess,
                isTasks: false,
                allDay,
                identity,
                task,
                title,
                project_uuid,
                backgroundColor,
                borderColor,
                start: moment(start).add(numOfEvents > 0 ? i : -i, "days").utc().format(),
                end: moment(end).add(numOfEvents > 0 ? i : -i, "days").utc().format(),
                duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
                location: location || null,
                description: description || null,
                created_at: time,
                updated_at: time,
                // isEventVote: Boolean(info.event.extendedProps.isEventVote),
                isAccepted: Boolean(event.extendedProps.isAccepted),
                // meetingId: info.event.extendedProps?.meetingId || null,
                // isCopy: true,
            }
            events.push(evt)

        }
        if (googleEvent) {
            if (events.length === 0) {
                // refresh events : reset eventState
                const {ggEvents} = getGlobal()
                setGlobal({
                    ggEvents: {...ggEvents}
                })
                return
            }

            const batch = window.gapi.client.newBatch()
            events.forEach(evt => {
                const evtParsed = parseResource(evt)
                const request = window.gapi.client.calendar.events.insert({
                    'calendarId': project_uuid,
                    'resource': evtParsed
                })
                // await AddGoogleEvent(evt)
                batch.add(request)
            })
            try {
                const {result, status} = await batch.then()
                // local updated
                if (status !== 200) {
                    return msgError(getIntlId(['event.toast.unableCopy']))
                }
                let success = []
                Object.keys(result).forEach(key => {
                    if (result[key]?.status === 200) {
                        success.push(result[key].result)
                    }
                })
                if (!success.length)
                    return
                const {ggEvents} = getGlobal()
                if (!ggEvents[project_uuid])
                    return
                ggEvents[project_uuid].events = concat(ggEvents[project_uuid]?.events || [], success)
                setGlobal({
                    ggEvents: {...ggEvents}
                }, () => {
                    refetchSource("google")
                    msgSuccess(getIntlId('common.saved'))
                })
            } catch (e) {
                console.log(e);
                msgError(e.toString())
            }

        } else {
            if (events.length === 0) {
                // refresh events : reset eventState
                const {geEvents} = getGlobal()
                setGlobal({
                    geEvents: {...geEvents}
                })
                return
            }
            events.forEach(evt => {
                batch.set(myEventDocRef(evt), omitBy(evt, isUndefined))
            })
            await batch.commit()
            msgSuccess(getIntlId('common.saved'))
        }

    } catch (e) {
        console.log(e.toString())
        return null
    }

}

export const eventAltCopy = async (event, numberId, copyingEvent) => {
    try {
        if (!copyingEvent)
            return;
        let {
            start,
            end,
            title,
            allDay,
            backgroundColor,
            borderColor,
            extendedProps: {
                project_uuid,
                identity,
                isSuccess = false,
                task,
                googleEvent,
                is_google,
                location,
                description
            }
        } = event
        // let evt = {start, end, allDay, uuid, calendar_uuid, identity, isSuccess, task}
        if (!end) {
            if (!allDay) {
                end = moment(start).add(copyingEvent.extendedProps.duration, "hour").utc().format()
            } else {
                end = moment(start).add(Math.ceil(copyingEvent.extendedProps.duration / 24), "day").utc().format()
            }
        }
        const id = `evt_${uuidv4()}`
        const time = moment().utc().format()
        const newEvent = {
            id,
            uuid: id,
            isDeleted: false,
            isSuccess: Boolean(isSuccess),
            isTasks: false,
            allDay,
            identity: identity || "",
            task: task || "",
            title: title || "",
            project_uuid,
            backgroundColor: backgroundColor || "",
            borderColor: borderColor || "",
            start: moment(start).utc().format(),
            end: moment(end).utc().format(),
            duration: (moment(end).utc() - moment(start).utc()) / (60000 * 60),
            location: location || null,
            description: description || null,
            created_at: time,
            updated_at: time,
            // isEventVote: Boolean(info.event.extendedProps.isEventVote),
            isAccepted: Boolean(isAccepted(event)),
            // meetingId: info.event.extendedProps?.meetingId || null,
            // isCopy: true,
        }
        if (googleEvent || is_google) {
            const data = await AddGoogleEvent(newEvent)
            if (data) {
                const {ggEvents, googleCalendarList} = getGlobal()
                const {project_uuid} = newEvent
                const idx = findIndex(googleCalendarList, {id: project_uuid})
                if (idx === -1)
                    return;
                if (ggEvents[project_uuid]) {
                    ggEvents[project_uuid].events.push({...data, project_uuid})
                } else {
                    ggEvents[project_uuid] = {
                        id: project_uuid,
                        events: [{...data, project_uuid}],
                        name: googleCalendarList[idx].summary,
                        color: googleCalendarList[idx].backgroundColor
                    }
                }
                setGlobal({
                    ggEvents: {...ggEvents}
                }, () => {
                    refetchOneSource("google", numberId)
                    msgSuccess(getIntlId('common.saved'))
                })

            }
            return
        }
        await myEventDocRef(newEvent)
            .set(newEvent)
        msgSuccess(getIntlId('common.saved'))
    } catch (e) {
        console.log(e.toString())
        return null
    }
}


export const onRestore = async () => {
    const {restoring, user, prevAction} = getGlobal()
    if (prevAction && prevAction.length !== 0) {
        if (!restoring) {
            let length = prevAction.length
            const action = prevAction[length - 1]
            const {event, type} = action
            if (!event || !type)
                return;
            document.body.style.cursor = 'progress';
            await setGlobal({restoring: true})
            const {is_google, conferenceData, googleTasksID, googleEvent} = event
            switch (type) {
                case CREATED_ACTION:
                    // just delete event created:

                    if (is_google || googleEvent) {
                        await deleteGoogleEvent(event)
                    } else {
                        await archiveGeniamEvent(event, user, true)
                    }
                    prevAction.splice(length - 1, 1);
                    await setGlobal([...prevAction])
                    msgSuccess(`${getIntlId('event.toast.archiveEvent')} '${event.title}'`);
                    if (conferenceData) {
                        console.log('delete');
                        deleteZoom(event?.zoomMeetingId).then();
                        // axios.post(API_ZOOM + '/delete', {
                        //     type: 'meetings',
                        //     meetingId: event?.zoomMeetingId,
                        // }).catch(e => console.log(e))
                    }
                    if (googleTasksID) {
                        handleDeleteGoogleTasksSchedule(event.googleTasksID).catch(e => console.log(e))
                    }
                    break;
                case UPDATED_ACTION:
                    const {newEvent} = action
                    //1 just up date google
                    if (is_google && newEvent.is_google) {
                        // update old State to event
                        await updateGoogleEvent(event, event.id)
                    }
                    //2. geniam event
                    if (!is_google && !newEvent.is_google) {
                        updateEvent(event);
                        // update zoom meeting if exists
                        updateZoomData(newEvent, event, user)
                    }
                    //3. geniam event to google event
                    if (!is_google && newEvent.is_google) {
                        //3.1 delete google event
                        await deleteGoogleEvent(newEvent);
                        //3.2 enable geniam event
                        await archiveGeniamEvent(event, user, false);
                        updateZoomData(newEvent, event, user)
                    }
                    //4. google event to geniam event
                    if (is_google && !newEvent.is_google) {
                        //4.1 delete geniam event
                        await archiveGeniamEvent(newEvent, user, true)
                        //4.2 enable google event
                        await unArchiveGoogleEvent(event)
                    }
                    prevAction.splice(length - 1, 1)
                    await setGlobal([...prevAction])
                    msgSuccess(`${getIntlId('event.toast.restored')} '${event.title}'`)
                    break;
                case CHANGE_TIME_ACTION:
                    let newInfo = action.newEvent
                    let oldInfo = action.event
                    if (newInfo.googleEvent || newInfo.is_google) {
                        // update googleEvent
                        const data = await changeTimeGoogleEvent({
                            allDay: oldInfo.allDay,
                            start: oldInfo.start,
                            end: oldInfo.end,
                            id: newInfo.id || newInfo.uuid,
                            project_uuid: newInfo.project_uuid
                        })
                        setGlobalGGEventsWithDataAndInfo(data, newInfo)
                    } else {
                        // updateGeniam event
                        await ChangeTimeEvent({
                            allDay: oldInfo.allDay,
                            start: oldInfo.start,
                            end: oldInfo.end,
                            id: newInfo.id || newInfo.uuid
                        });
                        updateZoomData(newInfo, oldInfo, user)
                    }
                    prevAction.splice(length - 1, 1)
                    await setGlobal([...prevAction])
                    msgSuccess(`${getIntlId('event.toast.restored')} '${newInfo.title}'`)
                    break;
                case DELETED_ACTION:
                    if (is_google) {
                        await unArchiveGoogleEvent(event)
                    } else {
                        await archiveGeniamEvent(event, user, false)
                    }
                    prevAction.splice(length - 1, 1)
                    await setGlobal([...prevAction])
                    msgSuccess(`${getIntlId('setting.unarchive')} '${event.title}'`)
                    break
                default:
                    break
            }
            await setGlobal({restoring: false})
            document.body.style.cursor = ''
        }
    }
}

export const updateZoomData = (newEvent, oldEvent, user) => {
    let meetingZoom = handleGetValue(newEvent) || newEvent?.linkZoom;
    if (meetingZoom && checkTimeTitleEvent(oldEvent, newEvent)) {
        handleUpdateZoomMeeting(moment(oldEvent.start).format(), moment(oldEvent.end).format(), meetingZoom, user.user_id, oldEvent.title)
            .catch(e => console.log(e))
    }
}
const deleteGoogleEvent = async (event) => {
    try {
        await ggTokenChecked()
        const request = window.gapi.client.calendar.events.delete({
            "calendarId": event.project_uuid,
            "eventId": event.id
        })
        await request.then()
        updateWatchChannelMyself(event.project_uuid)
    } catch (e) {
        msgError(e.toString())
    }
}
const unArchiveGoogleEvent = async (event) => {
    try {
        await ggTokenChecked()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": event.id || event.uuid,
            "status": "confirmed"
        })
        await request.then()
        updateWatchChannelMyself(event.project_uuid)
    } catch (e) {
        msgError(e.toString())
    }
}
const archiveGeniamEvent = async (event, user, archive) => {
    try {
        await myEventDocRef(event)
            .set({
                isDeleted: archive
            }, {merge: true})

    } catch (e) {
        console.log(e);
    }
}
export const ToggleSuccess = async (event) => {
    if (event.zoomMeetingId && event.isRemoveZoom) {
        event.linkZoom = '';
        event.zoomMeetingId = '';
        event.conferenceData = '';
        delete event.isRemoveZoom;
    }
    if (event.is_google || event.googleEvent) {
        const eventModel = {...event, recurrence: null};
        const data =  await updateGoogleEvent(eventModel, event.id);
        if (!data) {
            // error
            await refetchSource('google')
            msgError("UPDATE EVENT ERROR. TRY AGAIN LATE")
        }
    } else {
        delete event.oldRecurrence
        delete event.isChangeRecurrence
        delete event.is_google
        delete event.googleEvent
        delete event.isStart
        delete event.originStart
        delete event.allowAll
        event.isDeleted = false
        event.isTasks = false
        event.recurrence = null
        await updateEvent(event)
    }
}

export const addEventCreator = (event) => {
    const {user} = snapshot(userProxy)
    // const user = store.get('userInfo')
    const condition = !event?.isEventVote || !event?.creator?.email
    if (condition) {
        return {...event, creator: {email: user.email}}
    }
    return event;
}
