import {useEffect} from 'react';
import {useGlobal} from "reactn";
import {db} from "../config/firebaseConfig";
import {isFunction} from 'lodash'
import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';

function useGoogleEmailWatch() {
    const {googleAuth} = useSnapshot(userProxy)
    useEffect(() => {
        if (!googleAuth?.email)
            return;
        const sub = db.collection('googleEmailWatch')
            .doc(googleAuth.email)
            .onSnapshot(snapshot => {
                if (!snapshot.exists)
                    return
                // GCalendarList()
            })

        return () => {
            if (isFunction(sub))
                sub()
        }
        // eslint-disable-next-line
    }, [googleAuth])


}

export default useGoogleEmailWatch;
