import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DateRangePickerWrapper from "./DateRangePickerWraper/DateRangePickerWraper";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    },
}))

function DialogPickTimeRange({
                                 open,
                                 setOpen,
                                 openDayOff,
                                 setOpenDayOff,
                                 data,
                                 setData,
                                 eventSetting,
                                 setEventSetting,
                                 eventUpdate,
                                 publicHoliDays
                             }) {
    const classes = useStyles();

    const onClose = () => {
        setOpen(false)
        setOpenDayOff(false)
        if (setEventSetting)
            setEventSetting(null)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DateRangePickerWrapper
                setOpen={setOpen}
                openDayOff={openDayOff}
                setOpenDayOff={setOpenDayOff}
                eventSetting={eventSetting}
                eventUpdate={eventUpdate}
                setEventSetting={setEventSetting}
                data={data}
                setData={setData}
                onClose={onClose}
                publicHoliDays={publicHoliDays}
                clx={classes}
            />
        </Dialog>
    );
}

export default DialogPickTimeRange;
