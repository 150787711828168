import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useIntlId from '../../../hooks/useIntlId';

function ConflictVoteTime({open, setOpen, onDone}) {
    const [cancelIntl] = useIntlId(['dialog.cancel'])
    const handleClose = () => {
        setOpen(false)
    }
    const onOk = () => {
        handleClose()
        if (onDone) {
            onDone()
        }
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">{"Vote event"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This time has a vote time event. Do you want to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {cancelIntl}
                    </Button>
                    <Button onClick={onOk} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConflictVoteTime;
