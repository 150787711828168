import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {InputNumber} from 'antd';
import {IconButton} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        // width: '100%',
        alignItems: 'center',
        height: '25px',
        // minHeight: 40,
        justifyContent: "right",
        padding: 2,
    },
    number: {
        display: 'flex',
        // width: '100%',
        maxWidth: 'calc(100% - 175px)',
        minWidth: 100,
        height: '100%',
        backgroundColor: '#fff',
        borderLeft: "1px solid #D8D8D8",
        // borderRadius: '40px',
        // justifyContent: 'space-around',
        alignItems: 'center',
        paddingLeft: 5
    },
    img: {},
    img2: {
        minWidth: 17
    },
    input: {
        width: "100%",
        textAlign: "center",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        backgroundColor: 'transparent',
        fontSize: "18px !important",
        "&input": {
            color: theme.props.primaryColor,
            fontWeight: "bold",
        }
    },
    iconButton: {
        width: 20,
        height: 20,
    }
}))

function NumberComponent({value, setValue, handleBlur}) {
    const classes = useStyles();

    return (
        <div className={`${classes.root} clf-flex NumberComponent-root`}>
            <div className={`${classes.number}`}>
                <IconButton
                    className={`${classes.iconButton}`}
                    onClick={() => handleBlur('remove')}>
                    <img src='/imgs/newSvgs/-.svg' alt='' className={`clf-cursor-pointer ${classes.img2}`}/>
                </IconButton>

                <input
                    disabled
                    min={1}
                    max={36}
                    value={value}
                    onChange={value => {
                        if (value > 0) {
                            setValue(value)
                        }
                    }}
                    className={`clf-no-border ${classes.input} hiddenArrowInput`}
                />
                <IconButton
                    className={`${classes.iconButton}`}
                    onClick={() => handleBlur('add')}>
                    <img src='/imgs/newSvgs/+.svg' alt='' className={`clf-cursor-pointer ${classes.img2}`}/>
                </IconButton>
            </div>
        </div>
    );
}

export default NumberComponent;
