import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useIntlId from "../../../hooks/useIntlId";

function DialogSaveOrDeleteEvent(props) {
    const {openAccept, setOpenAccept, OnAdd, handleClose, loading} = props;
    const [dialogDelete, dialogSave, dialogTitle] = useIntlId(['dialog.action.delete', 'dialog.action.save', 'dialog.action.titleSave'])

    const onClose = () => {
        setOpenAccept(false)
    };

    const onDone = () => {
        onClose();
        if (OnAdd)
            OnAdd()
    };

    return (
        <div>
            <Dialog
                open={openAccept}
                onClose={onClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogTitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        loading ? <CircularProgress color={"primary"} size={25}/>
                            :
                            <Button onClick={onDone} color="primary">
                                {dialogSave}
                            </Button>
                    }

                    <Button onClick={handleClose} color="primary">
                        {dialogDelete}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogSaveOrDeleteEvent;
