import React, {useEffect, useState} from 'react';
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

function AddColumn({item, viewCalendar, columns, setColumns}) {
    const classes = useStyles();
    const [maxInput, setMaxInput] = useState(1)
    useEffect(() => {
        if (viewCalendar === 'CustomMonth')
            return setMaxInput(3)
        if (viewCalendar === 'CustomWeek')
            return setMaxInput(15)
        if (viewCalendar === 'CustomDay')
            return setMaxInput(100)
    }, [viewCalendar])
    const handleBlur = (type) => {
        let dataCheck = type && type === "add" ? Number(columns) + 1 : type && type === "remove" ? Number(columns) - 1 : Number(columns);
        if (dataCheck < 1) {
            setColumns(columns)
            return null
        }
        if (dataCheck > maxInput) {
            setColumns(columns)
            return null
        }
        return setColumns(dataCheck)

    }
    return (
        <div className={`${classes.root} clf-flex hiddenArrowInput`}>
            {/*<div className={classes.bgGrayAdd}>*/}
            {/*    <AddColumnSVG className={classes.iconSize} style={{fill: `${item?.colorPrimary}`}}/>*/}
            {/*</div>*/}
            <div className={`${classes.number}`}>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => handleBlur('remove')}>
                    <img src='/imgs/newSvgs/-.svg' alt='' className={`clf-cursor-pointer ${classes.img2}`}/>
                </IconButton>

                <input
                    disabled
                    min={1}
                    max={maxInput}
                    value={columns}
                    onChange={value => {
                        if (value > 0) {
                            setColumns(value)
                        }
                    }}
                    // onStep={(value, info) => {
                    //     if (info.type === 'up') {
                    //         handleBlur('add')
                    //     } else {
                    //         handleBlur('remove')
                    //     }
                    //     // setValue(value)
                    // }}
                    // onBlur={() => handleBlur('auto')}
                    className={`clf-no-border ${classes.input} hiddenArrowInput`}
                />
                <IconButton className={classes.iconButton} onClick={() => handleBlur('add')}>
                    <img src='/imgs/newSvgs/+.svg' alt='' className={`clf-cursor-pointer ${classes.img2}`}/>
                </IconButton>
            </div>
        </div>
    );
}

export default AddColumn;
const useStyles = makeStyles(theme => ({
    root: {
        width: "94px",
        alignItems: 'center',
        height: '40px',
        justifyContent: "space-around",
        padding: 5,
        borderRadius: "5px",
        backgroundColor: "#fff",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
    number: {
        display: 'flex',
        width: '100%',
        // maxWidth: '100',
        backgroundColor: "#fff",
        borderRadius: '5px',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    img: {},
    img2: {
        minWidth: 14
    },
    input: {
        width: "100%",
        textAlign: "center",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        "& input": {
            color: theme.props.primaryColor,
            fontWeight: "bold",
        },
        backgroundColor: "#fff"
    },
    iconButton: {
        width: 30,
        height: 30,
    },
    iconSize: {
        fill: theme.props.primaryColor,
        margin: 5
    },
    bgGrayAdd: {
        backgroundColor: "#fff",
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        padding: 1,
        height: 30
    }
}))
