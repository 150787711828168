import React, {useEffect, useState} from 'react';
import {Dialog, Fade, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {addEventCreator, newEvent} from '../../actions/calendar';
import {AddGoogleEvent} from '../../actions/googleCalendar';
import {useGlobal} from 'reactn';
import useDebounce from 'react-use/lib/useDebounce';
import 'react-overlay-loader/styles.css';
import {addEventSource, refetchOneSource, removeEvent} from '../../actions/refetchSourcce';
import {isUndefined} from 'lodash';
import ZoomButton from '../ZoomButton/ZoomButton';
import useIntlId from '../../hooks/useIntlId';
import axios from 'axios';
import {API_ZOOM, CALENDAR_COLLECTION} from '../../config/constants';
import Popover from '@material-ui/core/Popover';
import {getPosition} from '../../common/element';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import TagList from './popup/TagList';
import AllDaySelectComponent from './popup/AllDaySelectComponent';
import TimeSelectComponent from './popup/TimeSelectComponent';
import Recurrent from './popup/recurrent';
import Memo from './popup/Memo';
import ProjectList from './popup/ProjectList';
import IdentityList from './popup/identity';
import UrlProject from './UrlProject';
import ConflictVoteTime from './WarningDialog/ConflictVoteTime';
import DialogSaveOrDeleteEvent from './WarningDialog/DialogSaveOrDeleteEvent';
import {isVoteTime} from './actions/voteInfo';
import LocationAutoComplete from './LocationAutoComplete';
import {rrulestr} from 'rrule';
import {db} from '../../config/firebaseConfig';
import {omitByDeep} from '../../common/exLodash';
import SaveButton from './SaveButton';
import {copyEvent} from './actions/copyEvent';
import {msgError, msgInfo, msgSuccess} from '../../utils/msg';
import {useCtrlEnter, useCtrlShiftWithKey, useShiftWithKey} from 'use-hot-key-element';
import {conferenceDataJoinUrl, publicIcon} from './handlers/infoEvent';
import {createZoom, deleteZoom, handleGetValue} from "../../actions/zoom";
import GuestEmail from './popup/GuestEmail';
import Reminders from './popup/reminders';
import SelectFreeTime from './popup/selectFreeTime';
import {Autocomplete} from '@material-ui/lab'
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {eventsProxy} from '../../store/proxy/events.proxy';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
})

function CreateEvent({clickInfo, setClickInfo, numberId}) {
    const classes = useStyles()
    const [event, setEvent] = useState(null)
    const [loading, setLoading] = useState(false)
    const {user} = useSnapshot(userProxy)
    const {currentTheme} = useSnapshot(themesProxy)
    const {addEvent, lastTitle} = useSnapshot(eventsProxy)

    const [autoCompleteValue, setAutoCompleteValue] = useState([]);
    const view = currentTheme?.viewCalendar
    // global State

    const [element, setElement] = useState(null)
    const [open, setOpen] = useState(false)
    const [loadZoom, setLoadZoom] = useState(false)
    const [openAccept, setOpenAccept] = useState(false);

    const [click, setClick] = useState(null)

    const [isSave] = useCtrlEnter('#hotkey-ctrlenter-key')
    const [isQuickSave] = useCtrlEnter('#hotkey-title-input')

    const [isCopiedSave] = useShiftWithKey('enter', '#hotkey-ctrlenter-key')
    const [isQuickCopiedSave] = useShiftWithKey('enter', '#hotkey-title-input')

    const [isCreateZoomSave] = useCtrlShiftWithKey('enter', '#hotkey-title-input')
    const [isQuickCreateZoomSave] = useCtrlShiftWithKey('enter', '#hotkey-title-input')

    const [titleEvent, savedIntl, copiedIntl, errorIntl, savedAndCopiedZoomIntl, locationIntl] = useIntlId(
        [
            'create.titleEvent',
            'toast.success.calendar.saved',
            'common.copied',
            'app.setting.error',
            'toast.success.calendar.savedAndCopiedZoom',
            'create.location'
        ]
    )
    const [zoomOAuth] = useGlobal('zoomOAuth');
    const linkZoom = conferenceDataJoinUrl(event)

    useEffect(() => {
        if (lastTitle.length) {
            lastTitle.forEach(item => {
                setAutoCompleteValue(prev => [...prev, item.value]);
            })
        }
    }, [lastTitle])


    useEffect(() => {
        if (event?.title) {
            if (isQuickSave) onAdd()
            // eslint-disable-next-line
        }
        // eslint-disable-next-line
    }, [isQuickSave, event?.title])

    useEffect(() => {
        if (isCopiedSave || isQuickCopiedSave) onCopy()
        // eslint-disable-next-line
    }, [isCopiedSave, isQuickCopiedSave])

    useEffect(() => {
        if (isCreateZoomSave || isQuickCreateZoomSave) zoomUrl()
        // eslint-disable-next-line
    }, [isCreateZoomSave, isQuickCreateZoomSave])

    useEffect(() => {
        if (!clickInfo) {
            setEvent(null)
            setElement(null)
            return;
        }
        setEvent(clickInfo)

        // eslint-disable-next-line
    }, [clickInfo])

    useDebounce(
        () => {
            if (window['calendar' + numberId] && event) {
                // console.log(event);
                removeEvent(event.id)
                addEventSource(event)
                // for remove event vote in calendar update element domRect
                let el = document.getElementById(`event-${numberId}-${event.id}`)
                if (el) {
                    setElement(getPosition(el))

                }
                if (window['calendar' + numberId]) {
                    window['calendar' + numberId].unselect()
                }
            }
        },
        500,
        [event]
    )
    useEffect(() => {
        if (isSave && clickInfo) {
            if (event.title && event.project_uuid) {
                onAdd({noToast: true})
            } else {
                msgError('title & project Required !')
            }
        }
        // eslint-disable-next-line
    }, [isSave]);

    const handleClose = () => {
        if (event.zoomMeetingId)
            deleteZoom(event.zoomMeetingId).then()
        if (window['calendar' + numberId] && event.id) {
            removeEvent(event.id)
        }
        setClickInfo(null);
        setEvent(null)
        setElement(null)
        setOpenAccept(false);
        if (event?.conferenceData) {
            deleteZoom(handleGetValue(event)).then()
        }
    };

    async function onAdd(config = {notif: true}) {
        try {
            if (loading || !event) return;
            await setLoading(true)


            event.creator = addEventCreator(event).creator
            const lastSelect = {
                project_uuid: event.project_uuid,
                is_google: event.is_google,
                backgroundColor: event.backgroundColor,
                colorId: event?.colorId || null,
            }
            if ((event?.conferenceData && event?.recurrence && event?.conferenceData?.type !== 3) || (event?.conferenceData && !event?.recurrence && event?.conferenceData?.type !== 2)) {
                // delete not recurrence zoom
                deleteZoom(event.zoomMeetingId).then();
                //create new recurrence zoom
                const data = await createZoom(event);
                if (data) {
                    event.zoomMeetingId = data?.id || '';
                    event.conferenceData = data;
                } else {
                    event.zoomMeetingId = '';
                    event.conferenceData = '';
                }
            }
            if (event.googleEvent) {
                const userDocRef = db.collection(CALENDAR_COLLECTION).doc(user?.user_id)
                await userDocRef.set(omitByDeep({
                    lastProjectSelect: lastSelect
                }, isUndefined), {merge: true})

                rruleApplyEvent(event)

                const data = await AddGoogleEvent(event)
                removeEvent(event.id)
                if (!data) {
                    // error
                    refetchOneSource('google', window['i'])
                    msgError("CREATE EVENT ERROR. TRY AGAIN LATE")
                    return
                }
                addEvent({...data, googleEvent: true, project_uuid: event.project_uuid})

            } else {
                rruleApplyEvent(event)
                //add lastProjectSelect to database
                const userDocRef = db.collection(CALENDAR_COLLECTION).doc(user?.user_id)
                await userDocRef.set(omitByDeep({
                    lastProjectSelect: lastSelect
                }, isUndefined), {merge: true})
                await newEvent({...event, editable: true})

            }
            setClickInfo(null)
            if (config.notif) msgSuccess(savedIntl)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setClick(null)
        }
    }

    const onCopy = async () => {
        setClickInfo(null)
        if (!(event && event.title && event.project_uuid) || loading) {
            setClickInfo(null);
            if (window['calendar' + numberId]) {
                window['calendar' + numberId].refetchEvents()
            }
            return
        }
        if (isVoteTime(event.start, event.end)) {
            setClick("copy")
            return setOpen(true)
        }
        try {
            await copyEvent(event)
            msgInfo(copiedIntl)
        } catch (e) {
            msgError(errorIntl)
        } finally {
            await onAdd({notif: true})
        }
    }

    const zoomUrl = async () => {
        setClickInfo(null)
        await setLoadZoom(true)
        const data = await createZoom(event);
        if (data) {
            event.zoomMeetingId = data?.id || '';
            event.conferenceData = data;
        } else {
            event.zoomMeetingId = '';
            event.conferenceData = '';
        }
        if (isVoteTime(event.start, event.end)) {
            setClick("zoom")
            setLoadZoom(false)
            return setOpen(true)
        }
        // create zoom link
        await onAdd({notif: false})
        await copyEvent(event)
        setClickInfo(null)
        msgSuccess(savedAndCopiedZoomIntl)
        setLoadZoom(false)
    }
    const onConflictVoteTimeDone = async () => {
        if (click === "save") {
            setClick(null)
            onAdd({noToast: true})
            return
        }
        if (click === "zoom") {
            setClick(null)
            zoomUrl()
            return
        }
        if (click === "copy") {
            await copyEvent(event)
            setClick(null)
            onAdd({noToast: true})

        }

    }

    const checkConfirmClose = (name) => {
        if (!event?.title && name === 'close') {
            return handleClose()
        }
        if (event?.title && name === 'close')
            return setOpenAccept(true)
    }
    const saveClick = () => {
        setClickInfo(null)
        if (isVoteTime(event.start, event.end)) {
            setClick("save")
            return setOpen(true)
        }
        onAdd({noToast: true})
    }


    const handleCreateZoomMeeting = async () => {
        try {
            setLoading(true);
            const {data} = await axios.post(API_ZOOM + '/create', {
                type: 'meetings',
                timeStart: moment(event.start).format(),
                timeEnd: moment(event.end).format(),
                schedule: {
                    ...event,
                    service_name: 'Calendar Meetings',
                    title: event.title,
                },
                timeZone: user?.time_zone || 'Asia/Tokyo'
            });
            if (data) {
                const newEvent = {...event}
                newEvent.zoomMeetingId = data?.id || '';
                newEvent.conferenceData = data;
                setEvent(newEvent);
                // return onAdd({notif: false})
            }
        } catch (e) {
            msgError(e?.response?.data || "Create zoom event error! Please try again")
            console.log(e?.response?.data || "Create zoom event error! Please try again");
        } finally {
            setLoading(false)
        }
    };

    const isShowZoomButton = event => {
        return event && !event.is_holiday && !moment(event.end).isBefore(moment())
    }
    const DialogCustom = view === 'CustomYear' ? Dialog : Popover
    if (!element && view !== 'CustomYear')
        return null

    return (
        <div>

            {
                Boolean(event) &&
                <DialogCustom
                    open={Boolean(event)}
                    anchorReference="anchorPosition"
                    anchorPosition={element}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    TransitionComponent={Transition}
                    disableRestoreFocus
                    className={view !== 'CustomYear' ? classes.popover : classes.dialog}
                    disableEnforceFocus
                >
                    <span id={'hotkey-ctrlenter-key'}/>
                    <IconButton disableRipple={true} className={classes.closeIcon}
                                onClick={() => checkConfirmClose('close')}>
                        <img src={publicIcon('close.svg')} alt="close"/>
                    </IconButton>
                    {clickInfo &&
                        <ClickAwayListener onClickAway={() => checkConfirmClose('listen')}>
                            <div>

                                <div className={classes.root}>
                                    <LoadingOverlay>
                                        <Loader loading={loading} text={''}/>
                                        <Autocomplete
                                            disablePortal
                                            freeSolo
                                            id="hotkey-title-input"
                                            options={autoCompleteValue}
                                            placeholder={titleEvent}
                                            sx={{width: 300}}
                                            className={classes.textFieldName2}
                                            renderInput={(params) => <TextField {...params} placeholder={titleEvent}
                                                                                onKeyDown={e => {
                                                                                    if (e.code === 'enter' && e.target.value) {
                                                                                        setAutoCompleteValue(autoCompleteValue.concat(e.target.value));
                                                                                    }
                                                                                }}
                                                                                autoFocus={true}
                                            />}
                                            onInputChange={(e, newInputValue) => {
                                                setEvent({
                                                    ...event,
                                                    title: newInputValue
                                                })
                                            }}
                                        />
                                        <div className={classes.spaceTitle}/>
                                        <AllDaySelectComponent
                                            event={event}
                                            setEvent={setEvent}
                                        />
                                        <TimeSelectComponent
                                            event={event}
                                            setEvent={setEvent}
                                        />
                                        <SelectFreeTime event={event} setEvent={setEvent}/>
                                        <Recurrent event={event} setEvent={setEvent}/>
                                        <Reminders event={event} setEvent={setEvent}/>
                                        <LocationAutoComplete
                                            locationIntl={locationIntl}
                                            setLocation={(location) => setEvent({...event, location})}
                                            location={event.location}
                                        />
                                        {
                                            isShowZoomButton(event) &&
                                            <ZoomButton classes={classes} event={event} setEvent={setEvent}
                                                        linkZoom={linkZoom}/>
                                        }
                                        <Memo event={event} setEvent={setEvent}/>
                                        <GuestEmail event={event} setEvent={setEvent}/>


                                        <ProjectList
                                            event={event}
                                            setEvent={setEvent}
                                            onClose={handleClose}
                                            isCreate={true}
                                        />
                                        <IdentityList
                                            event={event}
                                            setEvent={setEvent}
                                            onClose={handleClose}
                                            create={true}
                                        />
                                        <TagList
                                            event={event}
                                            setEvent={setEvent}
                                            onClose={handleClose}
                                        />
                                        <SaveButton
                                            saveClick={saveClick}
                                            copyTime={onCopy}
                                            zoomUrl={zoomUrl}
                                            disable={!(event && event.title && event.project_uuid) || loading}
                                            disiableZoom={!event || event.is_holiday || moment(event.end).isBefore(moment()) || event.isMileStone || loadZoom || (!zoomOAuth?.zoomOAuth && !linkZoom)}
                                        />
                                        <UrlProject
                                            event={event}
                                        />

                                    </LoadingOverlay>
                                </div>
                            </div>
                        </ClickAwayListener>
                    }
                    <ConflictVoteTime open={open} setOpen={setOpen} onDone={onConflictVoteTimeDone}/>
                    {
                        openAccept &&
                        <DialogSaveOrDeleteEvent openAccept={openAccept} setOpenAccept={setOpenAccept} OnAdd={onAdd}
                                                 handleClose={handleClose} loading={loading}/>
                    }

                </DialogCustom>

            }

        </div>
    )
}

export default CreateEvent

export function rruleApplyEvent(event) {
    if (event.recurrence && event.recurrence.includes("FREQ=WEEKLY")) {
        const rule = rrulestr(event.recurrence)
        rule.options.dtstart = new Date(moment(event.start).startOf('day').format())
        const dateStart = rule.all(function (date, i) {
            return i < 1
        })
        const duration = moment(event.end).diff(event.start, "hour", true)
        if (dateStart.length && moment(dateStart[0]).format('YYYY-MM-DD') !== moment(event.start).format('YYYY-MM-DD')) {
            event.start = moment(event.start).set({
                years: moment(dateStart[0]).year(),
                month: moment(dateStart[0]).month(),
                date: moment(dateStart[0]).date()
            }).format()
            event.end = moment(event.start).add(duration, "hour").format()
        }
    }
}

const useStyles = makeStyles(theme => ({
    typography: {
        margin: theme.spacing(2),
    },
    dropDown: {
        zIndex: 9999,
        "&:focus": {
            outline: "none !important"
        },
        "&& .ant-select-focused": {
            border: "none !important",
            outline: "none !important",
        },
    },
    root: {
        minWidth: 700,
        padding: '25px',
        maxWidth: "800px",
        overflow: 'auto',
        maxHeight: "97vh",
        background: "#FFFFFF",
        borderRadius: 18,
        [theme.breakpoints.down('md')]: {
            maxWidth: 530
        },
        [theme.breakpoints.down('xs')]: {
            width: 340,
            minWidth: "auto"
        },
        "&& img": {
            maxWidth: 'none'
        }
    },
    element: {
        paddingTop: '10px'
    },
    closeIcon: {
        padding: 9,
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        backgroundColor: "#fff",
        color: "#970505",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 10,
        "&:hover": {
            backgroundColor: "#fff",

        }
    },
    popper: {
        zIndex: 1000, backgroundColor: "white",
        transition: "transform 0.2s",
        borderRadius: 18,
        boxShadow: "7px 10px 20px rgba(0, 0, 0, 0.2)",

    },
    btnContainer: {
        display: 'flex', margin: 'auto', paddingBottom: '20px',
        fontWeight: "bold",
    },
    btn: {
        width: '100%',
        marginTop: '10px',
        backgroundColor: 'green',
        color: 'white',
        "&:hover": {
            backgroundColor: 'green',
            color: "white"
        }
    },
    clear: {
        clear: "both"
    },
    pt10: {
        paddingTop: 10
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#0e72ed',
        textTransform: 'none',
        fontSize: '12px',
        minWidth: 130,
        '&:hover': {
            backgroundColor: '#0e72ed',
            opacity: 0.7,
        },
    },
    zoomMeeting: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
    },
    iconZoom: {
        fontSize: 18,
        marginRight: 5,
        padding: 5,
    },
    copyField: {
        width: "100%",
        margin: "10px 0px",
        // "& ."
        "&& .MuiOutlinedInput- root": {
            borderRadius: 0
        },
        "&& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "&& .MuiOutlinedInput-adornedEnd": {
            height: "40px",
            padding: 0
        },
        "&& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid black'
        },
        "& .MuiInputAdornment-positionStart": {
            margin: 0
        }
    },
    zoomJoin: {
        marginRight: 10,
        cursor: 'pointer'
    },
    pTop10: {
        paddingTop: 10,
    },
    alignRight: {
        textAlign: "right"
    },
    textFieldName: {
        border: "none",
        borderBottom: "1px solid #C4C4C4",
        display: "block",
        width: "100%",
        padding: "6px 0",
        marginBottom: 12,
        "&::focus": {
            outline: "none",
            borderBottomColor: theme.props.primaryColor
        },
        "&& input:focus": {
            outline: "none",
        },
        "&& .ant-select-focused": {
            outline: "none !important",
        },
        "&& .ant-select-selector": {
            outline: "none",
            border: "none",
            "&:focus": {
                outline: "none",
            },
            "&& .ant-select-selection-search-input": {
                border: "none",
                "&:focus": {
                    outline: "none",
                },
            }
        },
        "&& .ant-select-open": {
            outline: "none",
        }
    },
    textFieldName2: {
        border: "none",
        borderBottom: "1px solid #C4C4C4",
        padding: "6px 0",
        marginBottom: -9,

        '&& .MuiAutocomplete-inputRoot': {
            padding: 0
        },
        '& .MuiInputBase-root:before': {
            display: 'none'
        },
        '& .MuiInputBase-root:after': {
            display: 'none'
        },
        "&& input": {
            border: 'none',
            padding: '6px 12px !important',
            transition: '300ms',
            borderRadius: '3px',
            fontSize: '16px',
            '&:focus': {
                outline: 'none',
                borderColor: '#40a9ff',
                boxShadow: '0 0 0 2px rgb(24 144 255 / 20%)',
            }
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        },
        '& .MuiAutocomplete-popper': {
            marginTop: '-10px !important'
        }
    },
    popover: {
        "& .MuiPopover-paper": {
            overflow: "inherit"
        }
    },
    dialog: {
        "& .MuiDialog-paper": {
            overflow: "inherit"
        },
        "& .MuiPopover-paper": {
            overflow: "inherit"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: '1200px'
        },
        "& .MuiPopover-root .MuiIconButton-root": {
            top: '0px !important',
            right: '0px !important'
        },
    },
    btnSave: {
        fontWeight: "bold",
        margin: "10px 0",
        border: "none",
        background: `${theme.props.primaryColor} !important`,
        color: '#FFF !important',
        '&:hover': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            border: "none",
            color: '#FFF'
        },
        '&:focus': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            border: "none",
            color: '#FFF'
        }
    },
    iconSVG: {
        fill: theme.props.primaryColor,
        marginLeft: 7,
        width: 32,
        height: 32,
        cursor: "pointer",
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    spaceTitle: {
        width: '100%',
        marginTop: '14px'
    }
}));

