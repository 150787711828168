import {lazy} from 'react';

export const MenuAppBar = lazy(() => import('./MenuAppBar/MenuAppBar'))
lazy(() => import('./Navigation/NavApp'));
export const Setting = lazy(() => import('./MenuAppBar/Setting'))
lazy(() => import('./MenuAppBar/AccountPopup'));

//setting
lazy(() => import('./MenuAppBar/CalendarEventSetting'));
lazy(() => import('./MenuAppBar/googleSync'));
export const TextSelectFrom = lazy(() => import('./MenuAppBar/TextSelectFrom'))
export const TextSelectTo = lazy(() => import('./MenuAppBar/TextSelectTo'))
lazy(() => import('./MenuAppBar/ListSetting'));
export const SelectCalendarPopup = lazy(() => import('./Calendar/google/SelectCalendarPopUp'))
export const ProjectSettings = lazy(() => import('./MenuAppBar/ProjectSettings/index'))
export const IdentitySettings = lazy(() => import('./MenuAppBar/IdentitySettings/index'))
lazy(() => import('./MenuAppBar/VideoConferencing'));

// home
export const Home = lazy(() => import('../Page/Home'))
lazy(() => import('./Application/ContentHomeApp'));
lazy(() => import('./Application/LeftHomeApp'));
// export const TitleShow = lazy(() => import('./Calendar/Tooltip'))
lazy(() => import('./Calendar/CalendarIcon'));
export const AddCalendar = lazy(() => import('./AddGroupCalendar/AddCalendar'))
export const UpdateRecuringPopup = lazy(() => import('./Calendar/UpdateRecuringPopup'))
lazy(() => import('./GroupCalendar/GoogleProject'));
lazy(() => import('./GroupCalendar/GroupCalendar'));

// calendar
export const CalendarComponent = lazy(() => import('./Calendar/CalendarComponent'))
export const AddIdentity = lazy(() => import('./Calendar/AddIdentityDialog'))
export const CalendarYear = lazy(() => import('./Calendar/CalendarYear'))
// project
export const AddProjectPopup = lazy(() => import('./AddProjectPopup/AddProjectPopup'))

// Search
export const Search = lazy(() => import('../Page/Search/index'))


//Habits

// export const HabitsHome = lazy(() => import('../Page/HabitsHome'))
