import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION, MEETING_COLLECTION} from '../../config/constants';
import {myInfo} from './calendar';

export const getMeetingDocRef = (meetingId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}`)
}
// export const myMeeting = getMeetingDocRef;
export const getDocsAsync = async (collRef) => {
    return (await collRef.get())?.docs?.map(doc => ({...doc.data(), id: doc.id}))
}
export const getDataAsync = async (docRef) => {
    return (await docRef.get())?.data();
}

export const getMeetingCollRef = () => {
    return db.collection(`${MEETING_COLLECTION}`)
}


export const getVoteBoxCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl`)
}
export const getVoteBoxDocRef = (meetingId, voteBoxId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}`)
}
export const getUserVoteCollRef = (meetingId, voteBoxId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}/voteColl`)
}
export const getUserVoteDocRef = (meetingId, voteBoxId, userId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}/voteColl/${userId}`)
}

export const getUsersCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/users`)
}


export const getAnonymousCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/anonymousColl`)
}
export const getAnonymousDocRef = (meetingId, email) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/anonymousColl/${email}`)
}


export const getMessagesCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/messages`)
}

export const getMessagesDocRef = (meetingId, messageId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/messages/${messageId}`)
}

export const getUserDocRef = (meetingId, userId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/users/${userId}`)
}

export const scheduleAdjustmentRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/scheduleAdjustment`)
}
export const getBusyCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/busyColl`)
}
export const getBusyDocRef = (meetingId, busyId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/busyColl/${busyId}`)
}

export const getEventCalendarDocRef = (userId, voteId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${voteId}`)
}

export const updateEventBoxMeetingRef = async (userId, eventId, data) => {
    return await db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${eventId}`)
        .set(data, {merge: true})
}

export const updateEventsRef = async (userId, eventId, data) => {
    return await db.doc(`${CALENDAR_COLLECTION}/${userId}/events/${eventId}`)
        .set(data, {merge: true})
}

export const getEventCalendarCollRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting`)
}
export const getUserProjectsColl = userId => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/projects`)
}

export const getScheduleAdjustmentRef = (userId, eventId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${eventId}`)
}

export const myEventDocRef = (event) => {
    const COLL = (event?.isEventVote && !event?.project_uuid) ? "eventBoxMeeting" : "events"
    return db.collection(CALENDAR_COLLECTION)
        .doc(myInfo('user_id'))
        .collection(COLL)
        .doc(event?.id || event?.uuid)
}

export const myVoteBoxDocRef = (event) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(myInfo('user_id'))
        .collection('eventBoxMeeting')
        .doc(event.id || event.uuid)
}

export const myEventsCollRef = () => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(myInfo('user_id'))
        .collection("events")
}

export const myProjectsCollRef = () => {
    return db.collection(`${CALENDAR_COLLECTION}/${myInfo('user_id')}/projects`)
}
export const myProjectDocRef = (projectId) => {
    return myProjectsCollRef().doc(projectId)
}

export const getEventsDocRef = (userId, eventId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('events')
        .doc(eventId)
}
