import React from 'react';
import {Tooltip} from 'antd';
import {IconButton} from '@material-ui/core';
import {CaretLeftOutlined, CaretRightOutlined} from '@ant-design/icons';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import useIntlId from '../../hooks/useIntlId';
import store from 'store';
import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import {userProxy} from '../../store/proxy/user.proxy';
import {makeStyles} from '@material-ui/core/styles';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

function RightExpan(props) {
    const {currentTheme} = useSnapshot(themesProxy)

    const {user} = useSnapshot(userProxy)
    const classes = useStyles()
    const [hideIntl, showIntl] = useIntlId(['milestone.hide', 'lefthomeapp.show'])
    const {bar} = useSnapshot(announcementProxy)
    const barDisplay = bar?.announcementBar === undefined ? true : bar?.announcementBar
    const handleShowFocus = () => {
        store.set('rightExpansion', !currentTheme?.rightExpansion)
        try {
            if (!user?.user_id) return
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            userRef.update({"themeCustom.rightExpansion": !currentTheme?.rightExpansion})
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <div>
            {currentTheme?.viewCalendar !== "CustomYear" && (
                <Tooltip
                    title={currentTheme?.rightExpansion ? hideIntl : showIntl}
                    placement="bottom"
                >
                    <IconButton
                        onClick={handleShowFocus}
                        className={!barDisplay ? classes.rightBtn : classes.rightBtn2}
                    >
                        {currentTheme?.rightExpansion
                            ? <CaretRightOutlined/>
                            : <CaretLeftOutlined/>
                        }
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    rightBtn: {
        position: "fixed",
        right: 35,
        top: 127,
        zIndex: 99,
        width: 42,
        height: 42,
        alignItems: 'center',
        borderRadius: "50%",
        // backgroundColor: '#f1f1f1',
        "& .anticon svg": {
            width: 18,
            height: 18
        },
        "& img": {
            width: 18,
            height: 18
        },

    },
    rightBtn2: {
        position: "fixed",
        right: 35,
        top: 180,
        zIndex: 99,
        width: 42,
        height: 42,
        alignItems: 'center',
        borderRadius: "50%",
        // backgroundColor: '#f1f1f1',
        "& .anticon svg": {
            width: 18,
            height: 18
        },
        "& img": {
            width: 18,
            height: 18
        },
    }
}))

export default React.memo(RightExpan)
