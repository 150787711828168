import {updateGoogleEvent} from "../../../actions/googleCalendar";
import {ChangeTimeEvent} from "../../../actions/calendar";
import moment from "moment";
import {getGlobal, setGlobal} from "reactn";
import {cloneDeep, findIndex} from "lodash";
import {handleGetValue, handleUpdateZoomMeeting} from "../../../actions/zoom";
import {CHANGE_TIME_ACTION} from "../../../config/constants";
import setScheduleAdjustmentOfEvent from "./setScheduleAdjustmentOfEvent";
import {sendMailChangeCalendar} from '../../Mails/Actions';
import momentTz from 'moment-timezone';
import {snapshot} from 'valtio';
import {eventsProxy} from '../../../store/proxy/events.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';

export async function EventResize(info, changeTime, numberId) {
    const {user} = snapshot(userProxy)
    let event = {
        id: info.event.id,
        uuid: info.event.extendedProps.uuid,
        title: info.event.title,
        start: info.event.start,
        end: info.event.end,
        googleEvent: info.event.extendedProps.googleEvent || info.event.extendedProps.is_google,
        recurrence: info.event.extendedProps.recurrence || "",
        recurringEventId: info.event.extendedProps.recurringEventId || "",
        allDay: info.event.allDay,
        project_uuid: info.event.extendedProps.project_uuid,
        colorId: info.event.extendedProps.colorId,
        backgroundColor: info.event.backgroundColor,
        borderColor: info.event.borderColor,
        isSuccess: info.event.extendedProps.isSuccess,
        task: info.event.extendedProps.task,
        location: info.event.extendedProps.location,
        description: info.event.extendedProps.description,
        isEventVote: Boolean(info.event.extendedProps.isEventVote),
        isAccepted: Boolean(info.event.extendedProps.isAccepted),
        meetingId: info.event.extendedProps?.meetingId || null,
        conferenceData: info.event.extendedProps?.conferenceData || null,
        originStart: info.event.extendedProps.originStart || null,
        email: info.event.extendedProps?.email || '',
        emailVoter: info.event.extendedProps?.emailVoter || '',
        name: info.event.extendedProps?.name || '',

    }

    let end
    if (info.event.end) {
        end = info.event.end
    } else {
        if (info.event.allDay) {
            end = moment(info.event.start).add(Math.ceil(parseInt(info.event.extendedProps.duration) / (24)), "day").format()
        } else {
            end = moment(info.event.start).add(parseInt(info.event.extendedrops.duration), "hour").format()
        }
    }
    event.end = end;
    if (event.recurringEventId) {
        // recurrence event -> request ask
        const dateRecurrence = moment(info.event.extendedProps.originStart.start).format('YYYY-MM-DD')
        setGlobal({
            recurringOpen: true,
            recurringEvent: {
                ...event,
                isChangeRecurrence: false,
                allowAll: moment(event.start).format("YYYY-MM-DD") === moment(info.oldEvent.start).format("YYYY-MM-DD"),
                isStart: moment(info.oldEvent.start).format("YYYY-MM-DD") === dateRecurrence
            }
        })
        return
    }
    // schedule adjustment need confirm
    if (event.isEventVote && !event.meetingId) {
        if (event.googleEvent || event.project_uuid) {
            setScheduleAdjustmentOfEvent(event)
        } else {
            const {voteEvents} = snapshot(eventsProxy)
            let idx = findIndex(voteEvents || [], {id: event.id})
            if (idx !== -1) {
                setGlobal({
                    scheduleAdjustment: {
                        ...voteEvents[idx],
                        start: event.start,
                        end: event.end,
                        oldInfo: {
                            start: voteEvents[idx].start,
                            end: voteEvents[idx].end
                        },
                    }
                })
                return
            }
        }
    }

    if (event.googleEvent) {
        const data = await updateGoogleEvent(event, event.id)
        const {googleEvents, setGoogleEvents} = snapshot(eventsProxy)
        const ggEvents = cloneDeep(googleEvents)
        const events = ggEvents[event.project_uuid]?.events || []
        let idx = findIndex(events, {id: event.id})
        if (idx !== -1) {
            events[idx] = {...data, project_uuid: event.project_uuid}
            ggEvents[event.project_uuid].events = events
            setGoogleEvents({...ggEvents})
        }
    } else {
        await ChangeTimeEvent(event)
    }
    const {prevAction} = getGlobal()
    prevAction.push({
        type: CHANGE_TIME_ACTION,
        event: {
            start: info.oldEvent.start,
            end: info.oldEvent.end,
            allDay: info.oldEvent.allDay,
        },
        newEvent: event
    })
    setGlobal({
        prevAction: [...prevAction]
    })
    if (!event.googleEvent && event?.conferenceData) {
        handleUpdateZoomMeeting(moment(event.start).format(), moment(event.end).format(), handleGetValue(event), user.user_id, event.title)
            .catch(e => console.log(e))
    }
    let zoneUser = user?.time_zone || 'Asia/Tokyo';
    let oldEventEnd = momentTz(info?.oldEvent?.end).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
    let oldEventStart = momentTz(info?.oldEvent?.start).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
    let eventEnd = momentTz(event?.end).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
    let eventStart = momentTz(event?.start).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
    if (oldEventEnd !== eventEnd || oldEventStart !== eventStart) {
        await sendMailChangeCalendar(event)
    }
}
