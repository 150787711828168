import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useIntlId from "../../hooks/useIntlId";
import OkButton from "../Dialog/OkButton";


function GoogleSyncPopup({open, setOpen, values, onAdd, classes}) {
    const [syncNotif, cancelIntl] = useIntlId(['calendar.create.syncNotif', 'dialog.cancel'])

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className={classes.root}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {syncNotif}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                        onAdd(values, false)
                    }} color="primary">
                        {cancelIntl}
                    </Button>
                    <OkButton
                        onClick={() => {
                            console.log('sync for google calendar')
                            setOpen(false)
                            onAdd(values, true)
                        }}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

const styles = theme => ({
    root: {
        zIndex: "1360 !important"
    }
})

export default withStyles(styles)(GoogleSyncPopup)