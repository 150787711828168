import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useGlobal} from 'reactn';
import {getDateLink} from '../../Calendar/handlers/getDate';
import moment from 'moment';
import openNewTab from '../../../common/openNewTab';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';

function TabDate(prop) {
    const {
        sizeMini = false,
        position = false,
        themeItem = {},
    } = prop
    const classes = useStyles();
    const {viewCalendar} = themeItem
    const {currentTheme} = useSnapshot(themesProxy)
    const [, setRefetchDateNow] = useGlobal("refetchDateNow")

    const link = (e, dateStart) => {
        if (currentTheme?.viewCalendar === themeItem?.viewCalendar) {
            const url = window.location.origin + '/?dateStart=' + dateStart;
            const next = openNewTab(e, url)
            if (next) return;
            setRefetchDateNow(dateStart)
        }
    }
    const getDate = (ind, format) => {
        return moment(getDateLink(ind, viewCalendar).date).format(format)
    }
    const labelDate = (ind) => {
        const {rows = 1, columns = 1} = themeItem
        let fromInd = ind * columns * rows + 1
        let toInd = ind * columns * rows + columns * rows
        let fromY = getDate(fromInd, 'YYYY')
        let toY = getDate(toInd, 'YYYY')
        let fromD, toD
        if (viewCalendar === 'CustomMonth') {
            fromD = getDate(fromInd, 'MM')
            toD = getDate(toInd, 'MM')
        }
        if (viewCalendar === 'CustomWeek') {
            fromD = getDate(fromInd, 'WW')
            toD = getDate(toInd, 'WW')
        }
        if (viewCalendar === 'CustomDay') {
            fromD = getDate(fromInd, 'MM/DD')
            toD = getDate(toInd, 'MM/DD')
        }
        let year = fromY === toY ? fromY : fromY + ' - ' + toY
        let day = fromD === toD ? fromD : fromD + ' - ' + toD
        let dateStart = moment(fromY + '/' + fromD).format('YYYY-MM-DD')
        return [year, day, dateStart]
    }
    if (!themeItem || themeItem?.advanceView)
        return null
    return (
        <div className={classes.root}>
            <Tabs
                value={0}
                className={`${sizeMini ? classes.tabsDate : classes.tabsDateS} ${position ? classes.position : ''}`}
            >
                {[...Array(3).keys()].map((val, ind) => {
                    let [year, day, dateStart] = labelDate(ind);
                    return (
                        <Tab
                            value={ind}
                            key={ind}
                            className={`${sizeMini ? classes.tabDate : classes.tabDateS} `}
                            label={
                                <span className={`${position ? classes.spanFont : ''}`}>{year}<br/>{day}</span>
                            }
                            style={{color: `${themeItem.colorPrimary}`}}
                            onClick={(e) => link(e, dateStart)}
                        />)
                })}
            </Tabs>
        </div>
    );
}

export default TabDate;
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    tabDate: {
        minWidth: "unset",
        width: "33.33%",
        textTransform: "uppercase",
        fontWeight: 600,
        borderRadius: 2,
        padding: 9,
        fontSize: 6,
        minHeight: "unset",
        height: 5,
        backgroundColor: "#f1f1f1",
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    tabsDate: {
        width: "calc(100%)",
        borderRadius: 7,
        padding: 3,
        margin: "0 3px",
        minHeight: "unset",
        "& .MuiTabs-scroller > span": {
            display: "none"
        },
        background: "#fff",
    },
    tabsDateS: {
        borderRadius: 7,
        padding: 3,
        margin: "0 3px",
        minHeight: 28,
        "& .MuiTabs-scroller > span": {
            display: "none"
        },
        width: '100%',
        marginBottom: 10,
        background: "#fff",

    },
    tabDateS: {
        minWidth: "unset",
        width: "33.33%",
        textTransform: "uppercase",
        fontWeight: 700,
        borderRadius: 5,
        fontSize: 8,
        padding: 11,
        minHeight: "unset",
        height: 10,
        backgroundColor: "#f1f1f1",
    },
    position: {
        position: "absolute",
        top: -21,
        right: 2,
        width: "calc(71%) !important",
    },
    iconButtonR: {
        position: 'absolute',
        padding: 6,
        right: 1,
        top: 1,
    },
    iconButtonL: {
        padding: 6,
    },
    spanFont: {
        fontSize: 4,
        '&:hover': {
            transform: 'scale(1.5)',
        }
    },
}))
