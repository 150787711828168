import React from 'react'
import {makeStyles} from '@material-ui/core/styles';

const PreviewItem = ({item, highlightId, openSettingHighlight, alignItems}) => {
    const classes = useStyles();
    return (
        <div>
            <div
                onClick={() => openSettingHighlight(item, highlightId)}
                className={classes.ItemContent}
            >
                <div
                    style={{
                        alignItems: alignItems,
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <div
                        className={classes.title}>
                        {item?.title} {item?.content ? `- ${item?.content}` : "" || item?.focusText}
                    </div>
                    {
                        highlightId === "highLight" ?
                            <div
                                className={classes.subTitle}>Highlight</div>
                            :
                            highlightId === "remind" ?
                                <div
                                    className={classes.subTitle}>Remind</div>
                                :
                                <div
                                    className={classes.subTitle}>{item.date}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PreviewItem

const useStyles = makeStyles(theme => ({
    ItemContent: {
        cursor: "pointer",
        backgroundColor: theme.props.primaryColor,
        color: "white",
        position: 'relative',
        flex: 1,
        // height: 50,
        overflow: "hidden",
        justifyContent: "center",
        display: "flex !important",
        flexDirection: "column",
        padding: "5px 20px 8px 10px",
        "& a": {
            whiteSpace: 'nowrap'
        },
    },
    slideContainer: {
        width: "100%"
    },
    title: {
        marginBlockEnd: "unset",
        fontWeight: 700,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 16,
        lineHeight: "23px",
        maxWidth: "calc(100% - 10px)"
    },
    subTitle: {
        fontSize: 12,
        lineHeight: "13px",
        textAlign: "left"
    },
}));
