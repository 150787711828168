import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Community from './Community';
import {Col, Row, Upload} from 'antd';
import {findIndex, remove} from 'lodash'
import {storage} from '../../../../config/firebaseConfig';
import {msgError} from '../../../../utils/msg';
import {boardRef} from '../../../../common/firebaseRef/annoucement';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../../store/proxy/announcement.proxy';

function ListCommunity({updateAnnouncementBar}) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const {lock, board} = useSnapshot(announcementProxy)
    const {user} = useSnapshot(userProxy)

    // useEffect(() => {
    //     if (!board?.community?.length)
    //         return
    //     remove(board.community, (e) => {
    //         return !e.image
    //     })
    //
    //     // eslint-disable-next-line
    // }, [])

    const isMore = () => {
        return findIndex(board?.community, item => !item.image) === -1
    }
    // console.log(isMore)
    const onUpload = index => async (file) => {
        if (!file || !user?.user_id || loading)
            return;
        try {
            setLoading(true)
            const storageRef = storage.ref()
            const imgRef = storageRef.child(`announcement/${user.user_id}/${Date.now()}_${file.name}`)
            const snap = await imgRef.put(file)
            const url = await snap.ref.getDownloadURL()
            const nextCommunity = [...board?.community || '']
            nextCommunity[index || 0] = {image: url, content: ''}
            const dbRef = boardRef(user.user_id)
            await dbRef.set({
                ...board,
                community: nextCommunity,
            }, {merge: true})
        } catch (e) {
            msgError(e.toString())

        } finally {
            setLoading(false)
        }

    }
    return (
        <>
            <LoadingOverlay>
                <Loader loading={loading} text={''}/>
                <Row gutter={16}>

                    {
                        board?.community?.map((value, index) => {
                            return (
                                <Col key={index} className="gutter-row" span={6}>
                                    <Community updateAnnouncementBar={updateAnnouncementBar} index={index} value={value}
                                               lock={lock} onUpload={onUpload(index)}/>
                                </Col>

                            )
                        })
                    }

                    {
                        !lock && isMore() ?
                            <Col className="gutter-row" span={6}>
                                <div className={classes.item}>
                                    <Upload
                                        className={classes.upload}
                                        action={onUpload(board?.community?.length)}
                                        accept={"image/*"}
                                    >
                                        <img src={'/icons/view/noImgIcon.svg'} className={classes.img2} alt=""/>
                                    </Upload>
                                </div>

                            </Col>
                            :
                            null
                    }

                </Row>
            </LoadingOverlay>
        </>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: 5,
    },
    lock: {
        width: "100%",
        height: "100%",
    },
    upload: {
        '& .ant-upload-list-text': {
            display: 'none'
        },
        cursor: "pointer",
    },
    img: {
        position: "absolute",
        inset: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    img2: {
        objectFit: "cover",
        zIndex: 1,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 5,
        position: "absolute",
        borderRadius: 13,
        overflow: "hidden",
        maxHeight: "90%",
        maxWidth: "90%"
    },
    item: {
        padding: 5,
        borderRadius: 10,
        background: "#ffffff",
        marginTop: 10,
        position: "relative",
        paddingTop: "55%"
    }
}))

export default ListCommunity;
