import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import {InputNumber} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import {publicIcon} from '../handlers/infoEvent';
import styled from 'styled-components';

function SelectFreeTime({event, setEvent}) {
    const classes = useStyles()
    const [timeBeforeIntl,
        timeAfterIntl,
        minutesIntl] = useIntlId([
        'createEvent.timeBefore',
        'createEvent.timeAfter',
        'event.freebusy.time'
    ])
    const [valueBefore,setValueBefore] = useState(0)
    const [valueAfter,setValueAfter] = useState(0)
    const InputCustom = styled(InputNumber)`

    & .ant-input-number-handler-wrap {
        display: none;
    } ;
    & .ant-input-number-input-wrap {
        width: 75px;
        margin-right: 0px;
    } ;

    & input {
        text-align: center;
        font-size: 12px;
        background: #ffffff;
        border-radius: 25px;
        width: 85px;
        height:22px;
        margin-top: 2.5px;
        margin-left: 17px;
    }
`
    useEffect(() => {
        if('timeBefore' in event) {
            setValueBefore(event.timeBefore === null ? 0 : event.timeBefore)
        }
        // eslint-disable-next-line
    },[event.timeBefore])
    useEffect(() => {
        if('timeAfter' in event ) {
            setValueAfter(event.timeAfter === null ? 0 : event.timeAfter)
        }
        // eslint-disable-next-line
    },[event.timeAfter])
    useEffect(() => {
        if(event.allDay) {
           setEvent({
               ...event,
               timeBefore: 0,
               timeAfter: 0
           })
        }
        // eslint-disable-next-line
    },[event.allDay])

    if(event.allDay)
        return null;
    return (
        <div className={`${classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <IconButton disableRipple={true} className={classes.icon}>
                <img src={publicIcon('logo_freetime.svg')} alt="logo_freetime"/>
            </IconButton>
            <div className={classes.select}>
                <div className={classes.title}>{timeBeforeIntl}</div>

                <InputCustom
                    value={valueBefore}
                    max={120}
                    min={0}
                    bordered={false}
                    onBlur={(data) => {
                        if (Boolean(event?.isConnectMetamors)) return;
                        if (data.target.value || data.target.value === 0) {
                            if (parseInt(data.target.value) > 120) {
                                setEvent({
                                    ...event,
                                    timeBefore: 120
                                })
                            } else {
                                setEvent({
                                    ...event,
                                    timeBefore: parseInt(data.target.value)
                                })
                            }
                        }
                    }}
                    formatter={value => `${value} ${minutesIntl}`}
                    parser={value => value.replace(`${minutesIntl}`, '')}
                />
            </div>
            <div className={classes.select} style={{marginLeft:5}}>
                <div className={classes.title}>{timeAfterIntl}</div>
                <InputCustom
                value={valueAfter}
                max={120}
                bordered={false}
                onBlur={(data) => {
                    if (Boolean(event?.isConnectMetamors)) return;
                    if (data.target.value || data.target.value === 0) {
                        if (parseInt(data.target.value) > 120) {
                            setEvent({
                                ...event,
                                timeAfter: 120
                            })
                        } else {
                            setEvent({
                                ...event,
                                timeAfter: parseInt(data.target.value)
                            })
                        }
                    }
                }}
                formatter={value => `${value} ${minutesIntl}`}
                parser={value => value.replace(`${minutesIntl}`, '')}
            />
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    icon: {
        width: 39,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: 12,
        alignSelf: 'center'
    },
    content: {
        position: "relative",
    },
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        padding: "0px 15px",
        height: 27,
        flexDirection: "row",
        display: "flex",
        background: "#F1F1F1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        minWidth: 80,
    },
    iconArrow: {
        position: "absolute",
        right: 15,
        top: "50%",
        marginTop: -4
    },
    customBtn: {
        borderRadius: "16px !important",
        background: `${theme.props.primaryColor} !important`,
        color: "#fff !important",
        fontWeight: 700,
        marginLeft: 20,
        height: 32,
        border: "none !important",
        "&:hover": {
            background: `${theme.props.primaryColor} !important`,
            color: "#fff",
            opacity: 0.8
        },
        "&:active": {
            background: `${theme.props.primaryColor} !important`,
            color: "#fff",
            opacity: 0.8
        },
    },
    inputClass: {
        width: '80px',
        padding: '0px 0px !important',
        marginLeft: 5,
        border: "none !important",
        outline: "none !important",
        background: "#F1F1F1",
        borderRadius: 25,
        height: 27,
        fontSize: 12,
        "&:hover": {
            // backgroundColor: "rgb(230, 230, 230)"
            border: "none !important",
            boxShadow: "none !important"
        },
        "&:focus-within": {
            border: "none !important",
            boxShadow: "none !important"
            // borderColor: "rgb(230, 230, 230) !important"
        },
        "&::placeholder": {
            opacity: 1,
            color: "#000"
        },
        '& .ant-input-number-handler-wrap': {
            top: '10% !important',
            height: '80% !important',
            right: '8px',
            border: 'none'
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-up': {
            background: "#F1F1F1",
            border: 'none',
            height: '12px !important',
            backgroundImage: `url(${publicIcon('arrow_up.svg')})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'inherit',
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-down': {
            background: "#F1F1F1",
            border: 'none',
            height: '12px !important',
            backgroundImage: `url(${publicIcon('arrow_down.svg')})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'inherit',
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-up .ant-input-number-handler-up-inner': {
            display: 'none'
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-down .ant-input-number-handler-down-inner': {
            display: 'none'
        },
        '& .ant-input-number-input': {
            height: 27,
        }
    },
    textBefore: {
        marginLeft: 5,
    },
    buttonAdd: {
        border: 'none',
        color: '#5f6368',
        "&:hover": {
            backgroundColor: "#f9f9f9",
            color: '#5f6368',
            border: 'none',
        },
        "&:focus": {
            color: '#5f6368',
        },
        boxShadow: 'none',
        margin:"5px 0px"
    },
    buttonDelete: {
        border: 'none',
        marginLeft: 10,
        color: '#5f6368',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: "transparent",
            border: 'none',
        },
        "&:focus": {
            backgroundColor: "transparent",
            color: '#5f6368',
        },

    }
}))

export default SelectFreeTime;
