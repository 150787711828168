import React from 'react';
import DayPicker from 'react-day-picker';
import {makeStyles} from '@material-ui/core/styles';
import {setGlobal, useGlobal} from 'reactn';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import useIntlId from '../../../hooks/useIntlId';
import {myCalendarRef} from '../../../common/firebaseRef/calendar';

function GoogleCalendar(props) {
    const classes = useStyles();
    const [selectedDay] = useGlobal("selectedDay")
    const [calendarUser] = useGlobal("calendarUser")
    const [today] = useIntlId(["milestone.appbar.today"])
    const handleDayClick = async (day) => {
        if (calendarUser?.themeCustom?.advanceView) {
            const userRef = myCalendarRef()
            await userRef.update({
                "themeCustom.advanceView": !calendarUser?.themeCustom?.advanceView,
                "themeCustom.id": null,
                "themeCustom.rows": 1,
                "theme.columns": 1
            })
        }
        setGlobal({
            selectedDay: day,
            refetchDateNow: day,
        })

    }
    const backTodayClick = async (showToday) => {
        console.log(showToday)
        if (calendarUser?.themeCustom?.advanceView) {
            const userRef = myCalendarRef()
            await userRef.update({
                "themeCustom.advanceView": !calendarUser?.themeCustom?.advanceView,
                "themeCustom.id": null,
                "themeCustom.rows": 1,
                "theme.columns": 1
            })
        }
        setGlobal({
            selectedDay: showToday,
            refetchDateNow: showToday
        })
    }

    return (
        <div className={classes.root}>
            <div id={'tutorial-left-calendar'}>
                <DayPicker className={classes.container}
                           selectedDays={selectedDay}
                           onDayClick={handleDayClick}
                           todayButton={today}
                           onTodayButtonClick={backTodayClick}
                           localeUtils={MomentLocaleUtils}
                           locale={moment.locale()}
                />
            </div>
        </div>
    );
}


export default GoogleCalendar;
const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        margin: '2px 10px 0 10px',
        height: 285,
    },
    container: {
        "& .DayPicker-wrapper > .DayPicker-Footer": {
            display: "flex",
            justifyContent: "center"
        },
        "& .DayPicker-wrapper > .DayPicker-Footer > button": {
            // marginLeft: 10,
            borderRadius: 6,
            backgroundColor: theme.props.primaryColor,
            color: "#fff",

            //fontSize: '1px',
            fontWeight: 'bold !important'
        },
        borderRadius: "20px",
        background: "#f1f1f1",
        '& .DayPicker-Months': {
            width: 230,
            // height: 250,
            // alignItems: 'center'
            '& .DayPicker-Caption >div': {
                paddingLeft: 37,
                fontSize: '14px !important',
                fontWeight: 'bold !important',
            }
        }
    }

}))
