import React from 'react';
import {Tooltip} from "antd";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import useIntlId from '../../hooks/useIntlId';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

const useStyles = makeStyles(theme => ({
    IconLeft: {
        position: 'absolute',
        top: -5,
        left: -3,
        zIndex: 99,
        "& img": {
            width: 18,
            height: 18
        },
        [theme.breakpoints.down('md')]: {
            position: 'fixed',
        }
    },
    IconLeftOff: {
        position: 'fixed',
        top: 127,
        // background: "#f1f1f1",
        // position: 'absolute',
        // top: 5,
        left: 5,
        zIndex: 999,
        "& img": {
            width: 18,
            height: 18
        },
        // "&:hover": {
        //     opacity: 0.6,
        //     background: "#fff"
        // },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
        }
    },
    IconLeftOff2: {
        position: 'fixed',
        top: 180,
        // background: "#f1f1f1",
        // position: 'absolute',
        // top: 5,
        left: 5,
        zIndex: 999,
        "& img": {
            width: 18,
            height: 18
        },
        // "&:hover": {
        //     opacity: 0.6,
        //     background: "#fff"
        // },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
        }
    },
    iconRevert: {
        transform: 'rotate(180deg)'
    }
}));

function IconLeft() {
    const classes = useStyles();
    const [hideIntl, showIntl] = useIntlId(['milestone.hide', 'lefthomeapp.show'])
    const {openLeftMenu, onToggleLeftMenu} = useSnapshot(themesProxy)
    const {bar} = useSnapshot(announcementProxy)
    const barDisplay = bar?.announcementBar === undefined ? true : bar?.announcementBar

    return (
        <React.Fragment>
            {
                (window.location.pathname === '/') &&
                <Tooltip
                    title={openLeftMenu ? hideIntl : showIntl}
                    placement="bottom"
                >
                    <IconButton
                        id={'tutorial-left-button-hide'}
                        onClick={onToggleLeftMenu}
                        className={!barDisplay ? classes.IconLeftOff : classes.IconLeftOff2}
                    >
                        {
                            openLeftMenu ?
                                <CaretLeftOutlined/>
                                :
                                <CaretRightOutlined/>
                        }
                    </IconButton>
                </Tooltip>
            }
        </React.Fragment>
    );
}

export default React.memo(IconLeft)
