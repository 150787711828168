import {proxy} from 'valtio/vanilla';

const futureEventsProxy = proxy({
    geniam: {},
    google: {},
    setGeniam: (data) => {
        futureEventsProxy.geniam = data
    },
    setGoole: (data) => {
        futureEventsProxy.google = data
    },
})

export {
    futureEventsProxy
}
