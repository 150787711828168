// @ts-ignore
import Cookies from 'js-cookie'
import {IS_PRODUCTION} from '../config/constants';

const domain = IS_PRODUCTION ? '.geniam.com' : null

const expires = 1826 //days

export function getCookie(name, options = {}) {
    return Cookies.get(name, {domain, ...options})
}

export function setCookie(name, value, options = {}) {
    if (!value) return;

    // options.HttpOnly = true;
    // options.path = '/';
    // options.Secure = true;
    return Cookies.set(name, value, {domain, expires, ...options})
}

export function removeCookie(name, options = {}) {
    return Cookies.remove(name, {domain, ...options})
}

export function getJSONCookie(name, options = {}) {
    return Cookies.getJSON(name, {domain, ...options});
}

export const setCookieRefreshToken = (refreshToken) => {
    const opts = {expires: 1826};
    if (IS_PRODUCTION)
        opts.domain = '.geniam.com'
    setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refreshToken, opts);
}
const {REACT_APP_ENV} = process.env

export const COOKIE_GENIAM_ACCESS_TOKEN_KEY = `ge_${REACT_APP_ENV}_accessToken`
export const COOKIE_GENIAM_REFRESH_TOKEN_KEY = `ge_${REACT_APP_ENV}_refreshToken`


