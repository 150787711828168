import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Popconfirm, Upload} from 'antd';
import {cloneDeep} from 'lodash';
import useIntlId from '../../../../hooks/useIntlId';
import {useSnapshot} from 'valtio';
import {announcementProxy} from '../../../../store/proxy/announcement.proxy';

function Community({value, lock, onUpload, index, updateAnnouncementBar}) {
    const classes = useStyles();
    const {board} = useSnapshot(announcementProxy)
    const [deleteConfirmIntl, yesIntl, noIntl] = useIntlId(['dialog.content.deleteImage', 'settings.yes', 'settings.no'])

    const onDelete = () => {
        let newCommunity = cloneDeep(board?.community)
        newCommunity.splice(index, 1)
        if (newCommunity?.length < 5) {
            newCommunity.push({content: '', image: ''})
        }
        updateAnnouncementBar(newCommunity)
    }
    const cancel = () => {

    }
    return (
        <div className={classes.root}>
            {
                !lock ?
                    <>
                        {
                            value.image ?
                                <>
                                    <Upload
                                        className={classes.upload}
                                        action={onUpload}
                                        // onChange={onUpload}
                                        accept={"image/*"}
                                    >
                                        <img className={classes.imgAbsolute} src={'/icons/view/iconUpload.svg'} alt=""/>
                                    </Upload>
                                    <img src={value.image} className={classes.img} alt=""/>
                                    <Popconfirm
                                        title={deleteConfirmIntl}
                                        onConfirm={onDelete}
                                        onCancel={cancel}
                                        okText={yesIntl}
                                        cancelText={noIntl}
                                    >
                                        <img src="/icons/view/deleteIcon.svg"
                                             className={classes.deleteIcon} alt=""/>
                                    </Popconfirm>

                                </>

                                :
                                <>
                                    <Upload
                                        className={classes.upload}
                                        action={onUpload}
                                        accept={"image/*"}
                                    >
                                        <img src={'/icons/view/noImgIcon.svg'} className={classes.img2} alt=""/>
                                    </Upload>

                                </>
                        }


                    </>
                    :
                    <>
                        <Upload
                            disabled={true}
                            className={classes.upload}
                            accept={"image/*"}
                        >
                            <img src={'/icons/view/nothing.svg'} className={classes.img2} alt=""/> </Upload>

                    </>

            }
        </div>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: 5,
        borderRadius: 10,
        background: "#ffffff",
        marginTop: 10,
        position: "relative",
        paddingTop: "55%"
        // height: "100%"
    },
    title: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 10,
        right: 0,
        margin: "auto"
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: 1,
        inset: 0,
        padding: 5,
        position: "absolute",
        borderRadius: 13,
        overflow: "hidden"
    },
    img2: {
        objectFit: "cover",
        zIndex: 1,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 5,
        position: "absolute",
        borderRadius: 13,
        overflow: "hidden",
        maxHeight: "90%",
        maxWidth: "90%"
    },
    imgAbsolute: {
        position: "absolute",
        top: 5,
        left: 5,
        right: 0,
        zIndex: 2
    },
    upload: {
        '& .ant-upload-list-text': {
            display: 'none'
        },
        cursor: "pointer",
    },
    inputText: {
        borderCollapse: "collapse",
        border: 5,
        outline: 5
    },
    deleteIcon: {
        position: "absolute",
        top: 5,
        right: 5,
        zIndex: 2,
        padding: "5px 6px",
        borderRadius: "6px",
        backgroundColor: "rgba(0,0,0,0.6)",
        cursor: "pointer"
    }
}))

export default Community;
