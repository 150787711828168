import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn'
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import WeekDay from "../../../Meeting/Home/LeftHomeApp/WeekDay";
import {concat, filter, findIndex, max, remove} from "lodash";
import {TextSelectFrom, TextSelectTo} from "../../../splitting";
import useIntlId, {getIntlId} from "../../../../hooks/useIntlId";
import ExcludeDayTime from "./ExcludeDayTime";
import {CALENDAR_COLLECTION} from "../../../../config/constants";
import {db} from '../../../../config/firebaseConfig'
import {v4 as uuid} from 'uuid';
import {Tooltip} from 'antd';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as PolygonSVG} from "../../../../asSvg/icons/polygon.svg";
import {useStylesDisplaySettting} from "../../DisplaySettingStyle";
import {ReactComponent as AddSVG} from "../../../../asSvg/icons/add.svg";
import CancelButton from "../../../Dialog/CancelButton";
import SaveButton from "../../../Dialog/SaveButton";
import {publicIcon} from "../../../Calendar/handlers/infoEvent";
import {msgError, msgSuccess} from '../../../../utils/msg';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';
import {useMyCalSettings} from '../../../../actions/user';

function DialogAddDisplaySetting({
                                     classes,
                                     data,
                                     setData,
                                     isOpen,
                                     setIsOpen,
                                     setOpenTime,
                                     setOpenDayOff,
                                     publicHoliDays,
                                     setPubLicHolidays
                                 }) {
    const {user} = useSnapshot(userProxy);
    const [times] = useGlobal('times');
    const [displaySettings] = useMyCalSettings()
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [dateRange] = useIntlId(['event.date.range']);
    const [addNewRangeText, addDayOffText, excludeDayTimeText, nonWorkingDayText, operationTimeText, timeInstalledIntl, infiniteIntl] = useIntlId(['setting.addnewrange', 'setting.addholiday', 'setting.excludedaytime', 'setting.nonworkingdays', 'setting.operatetime', 'toast.error.timeInstalled', 'event.infinite']);
    const clx = useStylesDisplaySettting()

    useEffect(() => {
        if (data?.timeDisplay?.minTime?.value >= data?.timeDisplay?.maxTime?.value || data?.timeDisplay?.maxTime?.value < data?.timeDisplay?.minTime?.value) {
            setCheck(true)
        } else {
            setCheck(false)
        }
    }, [data])

    useEffect(() => {
        if (displaySettings.length !== 0) {
            let endOfEndDay = max(displaySettings.map(setting => (setting.endDay)));
            if (endOfEndDay)
                setData({...data, startDay: moment(endOfEndDay).add(1, 'day').startOf('day').format(), endDay: null})
        }
        // eslint-disable-next-line
    }, []);
    const onClose = () => {
        setLoading(false);
        setIsOpen(false);
        setOpenDayOff(false);
        setData({
            startDay: moment().format(),
            endDay: null,
            hiddenDays: [],
            timeDisplay: {
                minTime: times ? times.minTime : {
                    label: "00:00",
                    value: 0
                },
                maxTime: times ? times.maxTime : {
                    label: "24:00",
                    value: 24
                }
            },
            dayOffs: [],
            excludeTime: {
                label: '00:00',
                value: '0'
            }
        })
    };

    const checkDayIsSame = (value) => {
        let result = false;
        displaySettings.forEach(setting => {
            if (moment(setting.startDay).isSame(value.startDay, 'day') && !value.endDay && !setting.endDay)
                result = true
        });
        return result
    };

    const onSave = async () => {
        setLoading(true);

        if (checkDayIsSame(data)) {
            msgError(`${timeInstalledIntl}`)
            setLoading(false);
            return
        }
        try {
            const now = moment().format();
            const batch = db.batch();
            const UID = 'display_setting_' + uuid();
            let dayOffs = concat(publicHoliDays, data.dayOffs.map(item => ({...item, myself: true})))
            data.updatedAt = now;
            const displaySettingDocRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/settings/${UID}`);
            batch.set(displaySettingDocRef, {...data, dayOffs, id: UID}, {merge: true});

            const settingContinue = filter(displaySettings, setting => setting.startDay && !setting.endDay);

            // console.log(settingContinue);
            if (settingContinue.length === 0)
                return await batch.commit();

            // update continue display setting
            if (data.startDay && data.endDay && moment(data.startDay).isSameOrAfter(settingContinue[0].startDay, 'day')) {
                let startDay = moment(data.endDay).add(1, 'day').startOf('day').format();
                // console.log(settingContinue[0]);
                const settingDocRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/settings/${settingContinue[0].id}`);
                batch.set(settingDocRef, {startDay, updatedAt: now}, {merge: true})
            }

            await batch.commit()
            msgSuccess(getIntlId('toast.success.calendar.saved'))
        } catch (e) {
            console.log(e.toString())
        } finally {
            onClose()
        }
    };

    const handleChange = (label) => {
        let hiddenDays = data.hiddenDays;
        let idx = findIndex(hiddenDays, hidden => hidden === label);
        if (idx !== -1) {
            remove(hiddenDays, hidden => hidden === label);
            data.hiddenDays = hiddenDays;
            setData({...data})
        } else {
            if (hiddenDays.length === 6)
                return;
            let nextValue = [...hiddenDays];
            nextValue.push(label);
            data.hiddenDays = nextValue;
            setData({...data})
        }
    };

    const onRemove = (value, index, holiday = false) => {
        if (!holiday) {
            let dayOffs = data.dayOffs || [];
            remove(dayOffs, dayOffs[index]);
            data.dayOffs = dayOffs;
            setData({...data})
            return
        }
        publicHoliDays.splice(index, 1)
        setPubLicHolidays([...publicHoliDays])

    };

    if (!isOpen)
        return null;
    return (
        <Dialog
            open={Boolean(isOpen)}
            onClose={onClose}
            className={classes.dialogAddSetting}
        >
            <div className={classes.dialogTitleAdd}>
                <span>{addNewRangeText}</span>
            </div>
            <div className={classes.divFlexBox}>
                <div className={classes.divTimeAdjustment}>
                    <div className={classes.addDialogLabel}>{dateRange}</div>
                    <div
                        className={classes.timeAdjustment}
                        onClick={() => {
                            setOpenTime(true)
                        }}>
                        <span>{moment(data.startDay).format("YYYY/MM/DD")}</span>
                        <PolygonSVG className={`${classes.iconTo2} ${clx.polygonIconSvg}`}/>
                        <span>{data.endDay ? moment(data.endDay).format("YYYY/MM/DD") : infiniteIntl}</span>
                    </div>
                </div>
                <div className={classes.divTimeAdjustment}>
                    <div className={classes.addDialogLabel}>{operationTimeText}</div>
                    <div className={classes.divFlexCol}>
                        <TextSelectFrom classes={clx} dataTime={data} setDataTime={setData}/>
                        <PolygonSVG className={`${classes.iconTo2} ${clx.polygonIconSvg}`}/>
                        <TextSelectTo classes={clx} dataTime={data} setDataTime={setData}/>
                    </div>
                </div>
                <div className={clx.excludeDay}>
                    <div className={clx.excludeDayTitle}>{excludeDayTimeText}</div>
                    <ExcludeDayTime dataTime={data} setDataTime={setData} classes={clx}/>
                </div>
            </div>


            <WeekDay handleChange={handleChange} hiddenDays={data.hiddenDays}/>

            <div className={classes.divTimeCancel} style={{borderRadius: 15, padding: 15}}>
                <div className={classes.cancelLabel}>{nonWorkingDayText}</div>
                <div className={classes.listTimeCancel}>
                    {
                        data?.dayOffs.map((value, index) => {
                            return (
                                <div key={index} className={classes.divTimeCancelValue}
                                     onClick={() => onRemove(value, index)}>
                                    {moment(value.start).day() === moment(value.end).day() ? moment(value.start).format('MM/DD') : `${moment(value.start).format('MM/DD')} ~ ${moment(value.end).format('MM/DD')}`}
                                    <img className={classes.iconCloseSmall}
                                         src={publicIcon('closeSmall.svg')}
                                         alt=""/>
                                </div>
                            )
                        })
                    }
                    {
                        publicHoliDays.map((value, index) => {
                            return (
                                <div key={index} className={classes.divTimeCancelValue}
                                     onClick={() => onRemove(value, index, true)}>
                                    {moment(value.start).day() === moment(value.end).day() ? moment(value.start).format('MM/DD') : `${moment(value.start).format('MM/DD')} ~ ${moment(value.end).format('MM/DD')}`}
                                    <img className={classes.iconCloseSmall}
                                         src={publicIcon('closeSmall.svg')}
                                         alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
                <Tooltip title={addDayOffText}>
                    <IconButton
                        className={classes.addDayOff}
                        onClick={(e) => {
                            setOpenTime(true);
                            setOpenDayOff(true)
                        }}
                    >
                        <AddSVG className={classes.addIconSVGTimeSetting}/>
                    </IconButton>
                </Tooltip>
            </div>


            <div className={'clf-flex-center'}>
                <CancelButton
                    onClick={onClose}
                    disable={loading}
                />
                <SaveButton
                    onClick={onSave}
                    disable={check}
                    loading={loading}
                />
            </div>
        </Dialog>
    );
}

export default DialogAddDisplaySetting;

