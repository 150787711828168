import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import useIntlId from '../../hooks/useIntlId';
import {ReactComponent as RemoveSVG} from '../../asSvg/icons/remove.svg';
import commonStyles from './commonStyles';

export default function DeleteButton({icon = true, onClick, ...others}) {
    const classes = commonStyles()
    const [delIntl] = useIntlId(['app.notification.delete'])
    return (
        <CLFButtonSVG
            minHeight={"31px"}
            name={delIntl}
            iconComponent={icon ? <RemoveSVG/> : null}
            className={classes.secondary}
            onClick={onClick}
            {...others}
        />
    )
}
