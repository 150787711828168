import React from 'react';
import {findIndex} from "lodash"
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import {useSnapshot} from 'valtio';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';

const linkItems = [{
    name: "Slack",
    logo: "/imgs/slack_icon.png",
    id: "slack"
}, {
    name: "Trello",
    logo: "/imgs/trello_icon.png",
    id: "trello"
}
]

function UrlProject({event}) {
    const {allProjects} = useSnapshot(projectsProxy)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const idx = findIndex(allProjects, {id: event.project_uuid});
    const projectSelect = allProjects[idx]
    const classes = useStyles()
    ;
    const onOpen = () => {
        handleOpen(4)
    }
    if (event)
        return null
    return (
        <div>
            {
                (!projectSelect?.trello || !projectSelect?.slack) ?
                    <IconButton disableRipple={true} className={classes.iconAdd} onClick={onOpen}>
                        <AddIcon/>
                    </IconButton>
                    :
                    <div className={classes.listItems}>
                        {
                            linkItems.map(item => {
                                return (
                                    <Grid container key={item.id}>
                                        <Grid item xs={3} className={classes.itemHeader}>
                                            <img src={item.logo} className={classes.icon} alt={item.id}/>
                                            {item.name}
                                        </Grid>
                                        <Grid item xs={9} className={classes.itemHeader}>
                                            <Typography>{projectSelect[item.id]}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </div>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listItems: {
        marginTop: 20
    },
    itemHeader: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    urlTitle: {
        marginTop: 10
    },
    iconAdd: {
        // padding: '12px 12px 0 0'
        marginLeft: '-15px'
    }
}))
export default UrlProject;
