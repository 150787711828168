import moment from 'moment';

export const getTimeRangeMinMax = (date, theme) => {
    let type = 'month'
    let num = 2
    let start
    let end
    if (!theme) {
        start = moment(date).startOf(type).subtract(1, type).utc().format()
        end = moment(start).add(num + 2, type).utc().format()
    }
    if (theme?.viewCalendar === "CustomYear") {
        start = moment().startOf("years").utc().format()
        end = moment().endOf("years").utc().format()
        return {
            start, end
        }
    }
    if (theme?.viewCalendar === "CustomDay") {
        num = theme?.rows * theme?.columns
        start = moment(date).startOf('day').utc().format()
        end = moment(start).add(num - 1, 'day').endOf('day').utc().format()
        if (theme?.advanceView && theme?.tab === 2) {
            start = moment(theme?.rangeDay[0]).startOf('day').utc().format()
            end = moment(theme?.rangeDay[1]).endOf('day').utc().format()
        }
        if (theme?.settingToday) {
            start = moment(date).add(num - theme?.indexToday, 'day').utc().format()
            end = moment(start).add(num + theme?.numsDay - 1, 'day').utc().format()
        }
    }
    if (theme?.viewCalendar === "CustomWeek") {
        num = theme?.rows * theme?.columns
        start = moment(date).startOf('week').utc().format()
        end = moment(start).add(num - 1, 'week').endOf('week').utc().format()
    }
    if (theme?.viewCalendar === "CustomMonth") {
        num = theme?.rows * theme?.columns
        start = moment(date).startOf('month').utc().format()
        end = moment(start).add(num - 1, 'month').endOf('month').utc().format()
    }
    return {
        start,
        end
    }
}
