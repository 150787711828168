import React from 'react';
import {Tooltip} from "antd";

function AntdTooltipComponent({id, title, componentMain, matches, color, placement}) {

    return (
        <Tooltip
            title={matches ? '' : title}
            placement={placement}
            arrowPointAtCenter
            getPopupContainer={() => document.getElementById(id)}
            color={color}
        >
            {componentMain}
        </Tooltip>
    );
}

export default AntdTooltipComponent;
