/* eslint-disable react/prop-types, react/jsx-handler-names */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {listTime} from "../../constant";
import {Timestyles} from "../../selectTimeStyles";
import {ReactComponent as ArrowDownBlueSVG} from '../../../../asSvg/icons/arrowDownBlue.svg'

function ExcludeDayTime(props) {
    const {dataTime, setDataTime} = props
    const [time, setTime] = useState(null)
    const {classes} = props;

    useEffect(() => {
        if (dataTime?.excludeTime) {
            setTime(dataTime.excludeTime)
        }
    }, [dataTime])

    const handleChange = (data) => {
        setTime(data)
        dataTime.excludeTime = data
        setDataTime({...dataTime})
    }


    if (!time) return null

    return (
        <div className={classes.selectTime}>
            <ArrowDownBlueSVG className={classes.iconSelect}/>
            {/*<img className={classes.iconSelect} src={process.env.PUBLIC_URL + '/icons/arrowDownBlue.svg'} alt=""/>*/}
            <select className={classes.select}
                    onChange={(e) => handleChange(JSON.parse(e.target.value))}
            >
                {
                    listTime.map((item, index) => {
                        return (
                            <option key={index} value={JSON.stringify(item)}
                                    selected={time === item.value && "selected"}>{item.label}
                            </option>
                        )
                    })
                }
            </select>
        </div>

    )
}

ExcludeDayTime.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default (withStyles(Timestyles, {withTheme: true})(ExcludeDayTime))
