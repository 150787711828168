import React, {useState} from 'react'
import {useGlobal} from 'reactn'
import RateOfTimeTask from "../RateOfTime/RateOfTimeTask";
import {makeStyles} from "@material-ui/core/styles";
import LineChart from "../Custom/LineChart";
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';
import {Grid} from '@material-ui/core';
import {Popover} from 'antd';
import useIntlId from '../../hooks/useIntlId';
import tinycolor from 'tinycolor2';

function FocusChartApp(props) {
    // Get props
    const classes = useStyles()
    const {
        idFocus,
        calendarheight,
        data,
    } = props
    const {calendarUser} = useSnapshot(userProxy)
    const [googleCalendarList] = useGlobal("googleCalendarList")
    const [googleIdentities] = useGlobal("googleIdentities")
    const {plan, bar} = useSnapshot(announcementProxy)
    const [DaysLeft] = useIntlId(['setting.daysLeft'])
    const [dataIdentities, setDataIdentities] = useState([])
    const maxHeight = calendarheight - 20 || 300
    // useEffect(() => {
    //     if (!data?.identities)
    //         return;
    //     if (!isEmpty(googleIdentities)) {
    //         let temp = data?.identities?.filter(i => {
    //             return find(googleIdentities, v => v.id === i.id && !i.isDeleted && !i.isArchive)
    //         })
    //         setDataIdentities(temp)
    //     }
    //     refetchSource('google')
    // }, [googleIdentities, data]);

    // useEffect(() => {
    //     if (!data?.projects)
    //         return
    //     let totalProjects
    //     const googleSettings = calendarUser?.googleSettings || {}
    //     const {googleList = []} = calendarUser
    //     const geniamCals = filter(projects, p => !p.is_deleted && !p.is_sync)
    //     const googleCals = filter(googleCalendarList, g => !g?.id?.includes("#holiday")).map(p => {
    //         const linkInfo = googleSettings[p.id] || {}
    //         return {...p, ...linkInfo, is_google: true, name: p.summary, color: p.backgroundColor,}
    //     })
    //     let allCals = concat(geniamCals, googleCals)
    //     if (!googleList.length) {
    //         totalProjects = allCals
    //         return
    //     }
    //     let nextProject = []
    //     googleList.forEach(id => {
    //         let idx = findIndex(allCals, {id})
    //         if (idx !== -1) {
    //             nextProject.push(allCals[idx])
    //             allCals.splice(idx, 1)
    //         }
    //     })
    //     totalProjects = concat(nextProject, allCals)
    //     if (!totalProjects?.length)
    //         return
    //     data?.projects.forEach((e) => {
    //         totalProjects.forEach((item) => {
    //             if (e?.id === item?.id)
    //                 e = Object.assign(e, item)
    //         })
    //         return e
    //     })
    // }, [calendarUser, projects, googleCalendarList, data])

    return (
        <div
            className={classes.root} id={`focus-${idFocus}`}
            style={{maxHeight: `${maxHeight}px`}}
        >
            <div className={classes.containerFocus}>
                {
                    data?.task?.length !== 0 ?
                        <div className={classes.identityBox}>
                            <RateOfTimeTask
                                data={data?.task || []}
                                isMilestone={false}
                            />
                        </div> : ''
                }
                <LineChart data={data.projects || []}
                           allDay={false}
                           isProject={true}
                           isIdentity={true}
                           showName={true}
                           calendarheight={calendarheight}
                />
                <LineChart rowMode={true}
                           data={data.identities || []}
                           allDay={false}
                           isIdentity={true}
                           showName={false}
                           calendarheight={calendarheight}
                />
                {
                    data?.milestone && bar?.milestone?.checked && plan?.info?.milestone ?
                        <Grid container className={classes.identityBox}>
                            {data?.milestone.map((item, index) => {
                                let name = item.label.length > 20 ? item.label.slice(0, 20) + '...' : item.label
                                let itemColor = bar?.focusColor === 'identity' ? item?.backgroundColor : item?.borderColor
                                let isDark = tinycolor(itemColor).isDark()

                                return (
                                    <Grid key={index} item xs={bar?.milestone?.column === 2 ? 6 : 12}
                                          className={classes.itemMilestone}>
                                        <Popover
                                            content={<Grid
                                                style={{
                                                    backgroundColor: `${itemColor}`,
                                                    padding: '2px 7px',
                                                    borderRadius: 6,
                                                }}>{name}</Grid>}
                                            placement="top"
                                            overlayClassName={classes.popover}
                                        >
                                            <Grid
                                                className="flex flex-row whitespace-nowrap overflow-hidden text-ellipsis font-bold items-center text-center cursor-pointer w-full rounded-2xl m-1.5"
                                                style={{
                                                    backgroundColor: `${itemColor}`
                                                }}>
                                                <Grid item>
                                                    <p className={classes.time}
                                                       style={{color: 'black'}}>{DaysLeft ? DaysLeft.replace('**', item.day) : 'あと**日間'.replace('**', item.day)}</p>
                                                </Grid>
                                                <Grid item
                                                      className={`${classes.label} ${bar?.milestone?.column === 2 ? classes.styleItemLabel : ''}`}
                                                      style={isDark ? {color: 'white'} : {color: 'black'}}>{name}</Grid>
                                            </Grid>
                                        </Popover>
                                    </Grid>
                                )
                            })}
                        </Grid> : ''
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    containerFocus: {
        background: "#f1f1f1",
        padding: 6,
        borderRadius: "10px",
    },
    root: {
        borderRadius: '10px',
        overflowY: 'auto',
        width: "100%",
        "& .sc-iwyWTf": {
            paddingTop: 5,
            borderRadius: "10px !important",
            backgroundColor: '#F1F1F1 !important',
        }
    },
    box: {
        backgroundColor: "#F1F1F1",
        borderRadius: "10px",
        marginBottom: 5,
        padding: 5
    },
    identityBox: {
        backgroundColor: "#F1F1F1",
        borderRadius: 10,
        paddingRight: 5,
    },
    itemMilestone: {
        fontSize: 10,
        fontWeight: 'bold',
        fontStyle: 'normal',
        padding: '0 2.5px',
    },
    styleItemLabel: {
        "@media (max-width: 1380px)": {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    time: {
        backgroundColor: '#fff',
        borderRadius: 20,
        margin: 3,
        padding: 3,
        color: '#000',
        minWidth: 80,
        textAlight: 'center',
        fontWeight: 'bold'
    },
    label: {
        margin: 'auto 0',
    },
    popover: {
        '& .ant-popover-inner': {
            borderRadius: 6,
        },
        '& .ant-popover-inner-content': {
            padding: 0,
            color: '#fff',
        }
    },
}))
export default React.memo(FocusChartApp)

