import React from 'react';
import moment from 'moment';
import {ReactComponent as CheckBoxActiveSVG} from '../../../asSvg/icons/checkBoxActive.svg';
import {ReactComponent as CheckBoxSVG} from '../../../asSvg/icons/checkBox.svg';
import useIntlId from '../../../hooks/useIntlId';
import {makeStyles} from '@material-ui/core/styles';

function AllDaySelectComponent({event, setEvent, oldEvent = null}) {
    const classes = useStyles()
    const [allDay] = useIntlId(['create.allDay']);

    const handleChangeAllDay = () => {
        if (Boolean(event?.isConnectMetamors)) return;
        if (event?.isEventVote)
            return;
        if (event?.allDay) {
            let start = moment(event.start).hour(10).format()
            let end = moment(event.end).hour(11).format()
            if (oldEvent && !oldEvent?.allDay) {
                start = oldEvent?.start || start
                end = oldEvent?.end || end
            }
            if (moment(end).isSameOrBefore(start)) {
                end = moment(start).add(1, "hour").format()
            }
            setEvent({
                ...event,
                start,
                allDay: false,
                end,
                duration: moment(end).diff(start, 'hour', true)
            })
            return
        }
        let start = moment(event.start).startOf("day").format('YYYY-MM-DD')
        let end = moment(event.end).endOf("day").format('YYYY-MM-DD')
        if (moment(end).isSameOrBefore(start)) {
            end = moment(start).endOf("day").add(1, "day").format('YYYY-MM-DD')
        }
        setEvent({
            ...event,
            start,
            allDay: true,
            end,
            duration: moment(end).diff(start, 'day', true)
        })
    }

    if (!event)
        return null
    return (
        <div className={`${classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <div className={classes.allDay} onClick={handleChangeAllDay}>
                {
                    event?.allDay ? <CheckBoxActiveSVG className={classes.checkBoxSVG}/>
                        :
                        <CheckBoxSVG className={classes.checkBoxSVG}/>
                }
                {allDay}
            </div>
            {/*{'Thời gian trước sự kiện'}*/}
        </div>
    );
}

export const simpleDuration = duration => {
    const durationStr = `${duration}`
    if (durationStr.endsWith('0')) {
        return simpleDuration(durationStr.substr(0, durationStr.length - 1))
    } else if (durationStr.endsWith('.')) {
        return durationStr.substr(0, durationStr.length - 1)
    }
    return durationStr;
}

export default AllDaySelectComponent

const useStyles = makeStyles(theme => ({

    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    button: {
        width: 21,
        height: 21,
        border: "none",
        backgroundColor: "#444444",
        minWidth: "unset",
        color: "#fff",
        borderRadius: "50%",
        padding: 0,
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            backgroundColor: "#000"
        }
    },
    allDay: {
        display: "flex",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            marginRight: 6
        },
        cursor: "pointer"
    },
    checkBoxSVG: {
        fill: theme.props.primaryColor,
        marginRight: 4
    }
}))
