import React from 'react';
import {Typography} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import useIntlId from '../../../hooks/useIntlId';

function Creator({event, user}) {
    const [by] = useIntlId(['event.by'])
    const classes = useStyles()
    let email = event?.emailVoter ? event?.emailVoter : (event?.creator?.email ? event?.creator?.email : '')
    if (!email) return null
    return (
        <div className={classes.root}>
            <Typography className={classes.emailText}>
                {by} : <a href={`mailto:${email}`}>{email}</a>
            </Typography>
        </div>
    );

}

const useStyles = makeStyles(theme => ({
    root: {
        // width: '90%',
        marginBottom: 10,
    },
    emailText: {
        color: '#1890ff'
    }
}))
export default Creator;
