import {db} from '../../config/firebaseConfig';
import {getDataAsync} from './meetings';
import {myInfo} from './calendar';

const langCollRef = () => {
    return db.collection(`/intl/calendarIntl/langColl`)
}
/**
 *
 * @param langId: ja-jp,en-us,vi-vn
 */
const fetchLangAsync = async (langId) => {
    // const lang = get(myInfo(), 'language')
    return await getDataAsync(langCollRef().doc(langId))
}
const fetchMyLangAsync = async () => {
    const langId = myInfo('language')?.toLowerCase()
    console.log('lang id', langId)
    return await fetchLangAsync(langId)
}

export {
    langCollRef,
    fetchLangAsync,
    fetchMyLangAsync,
}
