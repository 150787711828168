import {proxy} from 'valtio/vanilla';
import calcFocus from '../../common/focus/calcFocus'

const chartProxy = proxy({
    data: {},
    timer: {},
    calenviewIdChange: (numberId) => {
        if (chartProxy.timer[numberId]) {
            clearTimeout(chartProxy.timer)
        }
        chartProxy.timer[numberId] = setTimeout(() => {
            if (window['calendar' + numberId]) {
                const events = window['calendar' + numberId].getEvents().map(item => ({
                    ...item.toJSON(),
                    ...item.toJSON().extendedProps,
                    allDay: item.allDay
                }))
                chartProxy.data[numberId] = calcFocus(events)
                // chartProxy.data = {
                //     ...chartProxy.data,
                //     [numberId]: nextData
                // }
            }
        }, 1000)

    }
})

export {
    chartProxy
}
