import {findIndex} from 'lodash';
import {getGlobal} from 'reactn';
import {swap} from 'react-grid-dnd';
import {CALENDAR_COLLECTION} from '../../config/constants';
import {db} from '../../config/firebaseConfig';
import {getIntlId} from '../../hooks/useIntlId';
import {msgSuccess} from '../../utils/msg';
import {snapshot,} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';

export const onChangeIdentities = (lists, sourceIndex, targetIndex, setNewLists, setLists, newLists) => {
    let sourceIdx = findIndex(newLists, {id: lists[sourceIndex]?.id})
    let targetIdx = findIndex(newLists, {id: lists[targetIndex]?.id})
    if (sourceIdx !== -1 && targetIdx !== -1) {
        let newArr = []
        const nextIdentities = swap(newLists, sourceIdx, targetIdx)
        nextIdentities.map((item, idx) => newArr.push({...item, index: idx}))
        setNewLists([...newArr])
    }
    const nextState = swap(lists, sourceIndex, targetIndex)
    let arr = []
    nextState.map((item, idx) => arr.push({...item, index: idx}))
    setLists([...arr])
}

export const saveIdentities = async (newLists) => {
    const {user} = snapshot(userProxy)
    try {
        if (!user.user_id) return
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
        await userRef.set({
            identitySettings: newLists
        }, {merge: true})
        msgSuccess(getIntlId('common.saved'))
    } catch (e) {
        console.log(e);
    } finally {
    }
}
