import React from 'react';
import {Modal} from "antd";
import CancelButton from "../../Dialog/CancelButton";
import DeleteButton from "../../Dialog/DeleteButton";
import useIntlId from "../../../hooks/useIntlId";


function DialogDeleteZoomMeeting(props) {
    const {open, setOpen, onDelete, loading} = props;
    const [delConfirmIntl] = useIntlId(['milestone.isDelete'])

    const onClose = () => {
        setOpen(null)
    };

    const onDone = () => {
        onClose();
        if (onDelete)
            onDelete()
    };

    return (
        <Modal
            visible={Boolean(open)}
            title={open?.topic}
            onCancel={onClose}
            zIndex={9000}
            footer={[
                <div className={'clf-flex-center'}>
                    <CancelButton
                        onClick={onClose}
                    />
                    <DeleteButton
                        onClick={onDone}
                        loading={loading}
                        disable={loading}
                    />

                </div>
            ]}
        >
            {delConfirmIntl}
        </Modal>
    );
}

export default DialogDeleteZoomMeeting;
