import {useSnapshot} from 'valtio';
import {projectsProxy} from '../store/proxy/projects.proxy';
import {useEffect} from 'react';
import {eventsProxy} from '../store/proxy/events.proxy';
import {getEventsFromReccrence, getGoogleEvents} from '../context/common/getGoogleEvents';
import {cloneDeep, concat, isEmpty, remove} from 'lodash';
import {calenviewTimeRangeProxy} from '../store/proxy/calenviewTimeRange.proxy';
import {futureEventsProxy} from '../store/proxy/futureEvents.proxy';
import moment from 'moment';
import {GCalendarEventFormat} from '../actions/googleCalendar';


export const useGoogleEvents = () => {
    const {googleProjects} = useSnapshot(projectsProxy)
    const {setGoogleEvents, googleEvents} = useSnapshot(eventsProxy)
    const {start, end} = useSnapshot(calenviewTimeRangeProxy)
    const {currentTime} = useSnapshot(calenviewTimeRangeProxy)
    const {setGoole} = useSnapshot(futureEventsProxy)
    const fetchGoogleEvents = async (start, end) => {
        if (!start || !end || !googleProjects.length) {
            setGoogleEvents({})
            return;
        }
        try {
            const {events} = await getGoogleEvents(start, end)
            if (isEmpty(events)) {
                setGoogleEvents({})
                return;
            }
            setGoogleEvents(events)
        } catch (e) {

        }
    }
    useEffect(() => {
        fetchGoogleEvents(start, end)
    }, [googleProjects.length, start, end])
    useEffect(() => {
        //calc next event in time
        let result = {}
        Object.keys(googleEvents).forEach(projectId => {
            const events = cloneDeep(googleEvents[projectId]?.events || [])
            const recurrence = remove(events, item => item.recurrence)
            const recurrenceEdited = remove(events, e => e.recurringEventId)
            const recurring = getEventsFromReccrence(recurrence, recurrenceEdited, currentTime.end)
            const singleEvents = GCalendarEventFormat(concat(events, recurring))
            result[projectId] = singleEvents.filter(event => !event.allDay && moment().isBefore(event.start) && moment(event.start).isBefore(currentTime.end)).length
        })
        setGoole(result)

    }, [googleEvents, currentTime])
}
