import {getGlobal, setGlobal} from "reactn";
import {updateGoogleEvent} from "../../../actions/googleCalendar";
import {findIndex} from "lodash";
import {ChangeTimeEvent,} from "../../../actions/calendar";
import moment from "moment";
import {CHANGE_TIME_ACTION} from "../../../config/constants";
import {handleGetValue, handleUpdateZoomMeeting} from "../../../actions/zoom";
import {refetchSource} from "../../../actions/refetchSourcce";
import {msgError} from '../../../utils/msg';
import {sendMailChangeCalendar} from '../../Mails/Actions';
import {snapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {eventsProxy} from '../../../store/proxy/events.proxy';

export async function EventReceive(info, changeTime) {
    const uuid = info.event.extendedProps.uuid ? info.event.extendedProps.uuid : (info.event.id ? info.event.id : info.event.extendedProps.event_uuid)
    let event = {
        id: info.event.id,
        start: info.event.start,
        end: info.event.end,
        title: info.event.title,
        task: info.event.extendedProps.task || "",
        group_uuid: info.event.extendedProps.group_uuid,
        project_uuid: info.event.extendedProps.project_uuid,
        identity: info.event.extendedProps.identity,
        service: info.event.extendedProps.service,
        isSuccess: info.event.extendedProps.isSuccess,
        is_tasks: info.event.extendedProps.is_tasks,
        uuid,
        googleEvent: info.event.extendedProps.googleEvent || info.event.extendedProps.is_google,
        colorId: info.event.extendedProps.colorId,
        allDay: info.event.allDay,
        backgroundColor: info.event.backgroundColor,
        borderColor: info.event.borderColor,
        recurrence: info.event.extendedProps.recurrence,
        recurringEventId: info.event.extendedProps.recurringEventId,
        priority_uuid: info.event.extendedProps.priority_uuid,
        category_uuid: info.event.extendedProps.category_uuid,
        description: info.event.extendedProps.description,
        location: info.event.extendedProps.location,
        lng: info.event.extendedProps.lng,
        lat: info.event.extendedProps.lat,
        task_uuid: uuid,
        isEventVote: Boolean(info.event.extendedProps.isEventVote),
        isAccepted: Boolean(info.event.extendedProps.isAccepted),
        meetingId: info.event.extendedProps?.meetingId || null,
        conferenceData: info.event.extendedProps?.conferenceData || '',
        googleTasksID: info.event.extendedProps?.googleTasksID || '',
        email: info.event.extendedProps?.email || '',
        emailVoter: info.event.extendedProps?.emailVoter || '',
        name: info.event.extendedProps?.name || '',
    }
    let end
    if (info.event.end) {
    } else {
        if (info.event.allDay) {
            end = moment(info.event.start).add(1, "day").format()
        } else {
            end = moment(info.event.start).add(1, "hour").format()
        }
        event.end = end
    }
    if (event.isEventVote) {
        sendMailChangeCalendar(event)
    }
    if (event.isEventVote && !event.meetingId) {
        const {voteEvents} = snapshot(eventsProxy)
        let idx = findIndex(voteEvents || [], {id: event.id})
        if (idx !== -1) {
            setGlobal({
                scheduleAdjustment: {
                    ...voteEvents[idx],
                    start: event.start,
                    end: event.end,
                    oldInfo: {
                        start: voteEvents[idx].start,
                        end: voteEvents[idx].end
                    }
                }
            })
            info.event.remove()
            return
        }
    }
    if (info.event.extendedProps.googleEvent) {

        try {
            const data = await updateGoogleEvent(event, event.id)
            const {ggEvents} = getGlobal()
            const events = ggEvents[event.project_uuid]?.events || []
            let idx = findIndex(events, {id: event.id})
            info.event.remove()
            if (idx !== -1) {
                events[idx] = {...data, project_uuid: event.project_uuid}
                ggEvents[event.project_uuid].events = events
                setGlobal({
                    ggEvents: {...ggEvents}
                }, () => {
                    refetchSource('google')
                })
            }
            // changeTime(data)

            // addEventSource(event)
        } catch (e) {
            msgError(e.toString)
        }

    } else {

        await ChangeTimeEvent(event)
        // changeTime(event)
        info.event.remove()
    }
    const {prevAction, EventEditting} = getGlobal()
    prevAction.push({
        type: CHANGE_TIME_ACTION,
        event: {
            start: EventEditting.start,
            end: EventEditting.end,
            allDay: EventEditting.allDay
        },
        newEvent: event
    })
    setGlobal({
        prevAction: [...prevAction]
    })
    if (!event.googleEvent && event?.conferenceData) {
        const {user} = snapshot(userProxy);
        handleUpdateZoomMeeting(moment(event.start).format(), moment(event.end).format(), handleGetValue(event), user.user_id, event.title)
            .catch(e => console.log(e))
    }
    //
    // if (!event.googleEvent && event.isEventVote && event.googleTasksID) {
    //     const {user} = getGlobal();
    //     handleUpdateGoogleTasksSchedule(event, user)
    // }
}

