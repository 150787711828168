import React from 'react'
import {CirclePicker} from "react-color"
import {Grid, Popover, withStyles} from '@material-ui/core'

function SelectColor(props) {
    const {values, setValues, setAnchorEl, anchorEl, classes} = props
    const open = Boolean(anchorEl)
    const arrColor = [
        "#88304e", "#533546", "#311d3f",
        "#f44336", "#e91e63", "#9c27b0",
        "#673ab7", "#3f51b5", "#2196f3",
        "#03a9f4", "#00bcd4", "#009688",
        "#4caf50", "#8bc34a", "#cddc39",
        "#ffeb3b", "#ffc107", "#ff9800",
        "#ff5722", "#795548", "#607d8b",
        "#616161", "#424242", "#212121",
    ]
    const handleMenuClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }
    const handleChangeColor = (value) => {
        setValues({...values, projectColor: value.hex})
        handleMenuClose()
    }


    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className={classes.root}
        >
            <Grid container className={"m-4"} alignItems={"center"}>
                <CirclePicker
                    circleSize={20}
                    circleSpacing={10}
                    color={values.projectColor}
                    colors={arrColor}
                    onChangeComplete={(color) => handleChangeColor(color)}
                />
            </Grid>
        </Popover>
    )
}

const styles = theme => ({
    root: {
        zIndex: "1360 !important"
    }
})

export default withStyles(styles)(SelectColor)

