import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useIntlId, {getIntlId} from '../../../hooks/useIntlId';
import {calenviewProd} from '../../../common/calenviewPlan';
import {find} from 'lodash';
import {useSnapshot} from 'valtio';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function MyPlan(props) {
    const {plan} = useSnapshot(announcementProxy)
    const [usingPlan,plans] = useIntlId(['setting.plan.notification.usingplan','settingPlan.notification'])
    const classes = useStyles()
    const calPlan = calenviewProd()
    const name = find(calPlan, p => p.id === plan.info.id)?.name
    if (!plan?.info)
        return null
    return (
        <div className={classes.root}>
            <Typography className={[classes.plan, 'italic']}>
                {`${usingPlan}`.replace('~', ' ')}
                <span style={{color: plan.info.color}}>{name}</span>{`${plans}`.replace('~', '')}
            </Typography>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: "20px 0"
    },
    plan: {
        fontWeight: 700,
        fontSize: 30,
        textAlign: "center",
    }
}))

export default React.memo(MyPlan)
