import React, {useEffect, useState} from 'react';
import Tab from '@material-ui/core/Tab';
import {useGlobal} from 'reactn';
import moment from 'moment';
import useIntlId from '../../hooks/useIntlId';
import {makeStyles} from '@material-ui/core/styles';
import MenuItemContainer from './MenuItemContainer';
import InputNumber from '../Custom/InputNumber';
import TabsCustom from '../Custom/TabsCustom';
import SelectMonthPopover from './SelectMonthPopover';
import openNewTab from '../../common/openNewTab';
import ListCustomView from './ListCustomView';
import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import qs from 'query-string';
import {useHistory} from 'react-router-dom';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {userProxy} from '../../store/proxy/user.proxy';
import {searchProxy} from '../../store/proxy/search.proxy';

function HeaderCenter(props) {
    const classes = useStyles();
    const {currentTheme, setTheme} = useSnapshot(themesProxy)
    const {user} = useSnapshot(userProxy)
    const [refetchDateNow, setRefetchDateNow] = useGlobal("refetchDateNow")
    const {openSearch} = useSnapshot(searchProxy);
    const [yearText, monthText, weekText, formatMoth, formatDay, formatYear, today, formatWeek] = useIntlId(['appbar.year', 'appbar.month', 'appbar.week', 'appbar.formatAppbar.month',
        'appbar.formatAppbar.day', 'appbar.formatAppbar.year', 'appbar.day', 'appbar.weeks.format'])
    const [value, setValue] = useState(0);
    const [openSelectMonth, setOpenSelectMonth] = useState(null)
    const {viewCalendar, idTheme} = qs.parse(window.location.search)
    const history = useHistory()

    const getViewType = (value) => {
        let type = "month"
        let name = "CustomMonth"
        switch (value) {
            case 0:
                type = 'year'
                name = 'CustomYear'
                break;
            case 1:
                type = 'month'
                name = 'CustomMonth'
                break;
            case 2:
                type = 'week'
                name = 'CustomWeek'
                break;
            case 3:
                type = 'day'
                name = 'CustomDay'
                break;
            default:
        }
        return {type, name}
    }
    const handleChangeView = async (event, newValue) => {
        if (!moment().startOf('month').isSame(refetchDateNow)) {
            setRefetchDateNow(moment().format('YYYY-MM-DD'))
        }
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
        const {type, name} = getViewType(newValue)
        const link = `${window.location.origin}?viewCalendar=${type}`
        const isOpenNewTab = openNewTab(event, link)
        if (isOpenNewTab)
            return;
        if (viewCalendar || idTheme) {
            history.push('/')
        }
        const data = {
            id: null,
            size: currentTheme?.size || 9,
            viewCalendar: name,
            columns: 1,
            rows: 1,
            colorPrimary: currentTheme?.colorPrimary || "#1790FF",
            rightExpansion: Boolean(currentTheme?.rightExpansion),
            calendarLeftView: Boolean(currentTheme?.calendarLeftView),
            showTitle: Boolean(currentTheme?.showTitle),
            numsDay: null,
            indexToday: null,
            rangeDay: null,
            settingToday: null,
            advanceView: null,
            tab: null
        }
        try {
            await userRef.set({themeCustom: data}, {merge: true})
            setTheme(data)
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        if (currentTheme?.viewCalendar === 'CustomYear')
            return setValue(0)
        if (currentTheme?.viewCalendar === 'CustomMonth')
            return setValue(1)
        if (currentTheme?.viewCalendar === 'CustomWeek')
            return setValue(2)
        if (currentTheme?.viewCalendar === 'CustomDay')
            return setValue(3)
    }, [currentTheme?.viewCalendar])

    const onChangeMonth = (number) => {
        let newDate

        if (currentTheme?.advanceView && currentTheme?.settingToday && currentTheme?.viewCalendar === "CustomDay") {
            let newT = moment(refetchDateNow).startOf("day").add(number, "day").format();
            newDate = moment(newT).startOf("day").add( number*(currentTheme?.numsDay - 1), "day").format();
        } else {
            if (currentTheme?.viewCalendar === "CustomYear") {
                newDate = moment(refetchDateNow).startOf("year").add(number, "year").format()

            } else if (currentTheme?.viewCalendar === "CustomMonth") {
                newDate = moment(refetchDateNow).startOf("month").add(number, "month").format()

            } else {
                if (currentTheme?.viewCalendar === "CustomWeek") {
                    newDate = moment(refetchDateNow).startOf("isoWeeks").add(number, "week").format()
                } else
                    newDate = moment(refetchDateNow).startOf("day").add(number, "day").format()
            }
        }
        setRefetchDateNow(newDate)
    }

    const titleCalendar = (() => {
        let t

        if (currentTheme?.advanceView && currentTheme?.settingToday && currentTheme?.viewCalendar === "CustomDay") {
            let newT = moment(refetchDateNow).format("YYYY-MM-DD")
            t = moment(newT).add( 1 - currentTheme?.indexToday, "day").format(formatDay !== "appbar.formatAppbar.day" ? formatDay : "YYYY年MM月DD日");
        } else {
            switch (currentTheme?.viewCalendar) {
                case 'CustomYear':
                    t = moment(refetchDateNow).format(formatYear !== "appbar.formatAppbar.year" ? formatYear : "YYYY年")
                    break;
                case 'CustomDay':
                    t = moment(refetchDateNow).format(formatDay !== "appbar.formatAppbar.day" ? formatDay : "YYYY年MM月DD日")
                    break;
                case 'CustomWeek':
                    t = moment(refetchDateNow).format(formatWeek !== "appbar.weeks.format" ? formatWeek : "YYYY年第WW週目")
                    break;
                default:
                    t = moment(refetchDateNow).format(formatMoth !== "appbar.formatAppbar.month" ? formatMoth : "YYYY年MM月")
            }
        }
        return t
    })();

    const onChangeInput = (type, event) => {
        if (type === "degree") {
            onChangeMonth(-1)
        } else {
            onChangeMonth(1)
        }
    }
    const onSelectMonth = (e) => {
        setOpenSelectMonth(e.currentTarget)
    }
    return (
        <div className={classes.headerCenter} id={'tutorial-top-menu'}>
            <div id={'tutorial-top-date'}>
                {
                    window.location.pathname === '/' ?
                        <InputNumber
                            type={'text'}
                            label={titleCalendar}
                            size={40}
                            onChange={onChangeInput}
                            onSelect={onSelectMonth}
                        />
                        : null
                }
            </div>
            {
                (openSearch && window.location.pathname !== '/search') ? ""
                    :
                    <div className={`clf-flex-center ${classes.marginTop}`}>
                        {
                            (window.location.pathname === '/' || window.location.pathname === '/mileStone') &&
                            <MenuItemContainer classMarginBottom={classes.mgBottom10}
                                               classView={user?.language !== 'ja-jp' ? `${classes.view}` : ''}>
                                <TabsCustom
                                    value={value}
                                    onChange={handleChangeView}
                                    id={'tutorial-top-view'}
                                    className={classes.changeMinWidth}
                                >
                                    <Tab label={yearText}/>
                                    <Tab label={monthText}/>
                                    <Tab label={weekText}/>
                                    <Tab label={today} id={'tutorial-top-today'}/>
                                </TabsCustom>
                            </MenuItemContainer>

                        }

                        <div className={`clf-flex-center fw700 px14 ${classes.mgBottom10}`}>
                            <ListCustomView/>
                        </div>

                    </div>
            }
            <SelectMonthPopover open={openSelectMonth} setOpen={setOpenSelectMonth}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    view: {
        width: '100%',
        // minWidth: 310,

    },
    iconSvg: {
        marginRight: 5
    },
    buttonAntd: {
        textTransform: 'uppercase',
        backgroundColor: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        "& svg": {
            marginLeft: 5
        }
    },
    navButton: {
        float: 'left',
        color: '#000',
        margin: "0 20px",
        fontSize: '1.6rem',
        // padding: 5,
        lineHeight: '26px',
        fontWeight: "bold",
        [theme.breakpoints.down('md')]: {
            color: "#000"
        },
    },
    paper: {
        ...theme.spacing(2),
        width: 'fit-content',
        float: 'left',
        backgroundColor: '#fff !important',
        padding: "0px !important",
        // [theme.breakpoints.down('md')]: {
        //     backgroundColor: '#fff !important',
        // },

    },
    headerCenter: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        // marginRight: -40,
        // fontFamily: "Montserrat, sans-serif !important",
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            flexWrap: "wrap",
            padding: "0 10px",
            justifyContent: 'center'
        }
    },
    titleCalendar: {
        margin: "0 5px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            color: "#000",
            marginBottom: 5,
        }
    },
    handleView: {
        alignItems: "center",
        // border: "solid 1px #fff",
        boxSizing: "border-box",
        // padding: "0 5px",
        "&.MuiTabs-root": {
            minHeight: "unset !important",
        },
        "& .MuiTab-root": {
            color: "#000",
            minWidth: "auto",
            padding: "5px 15px",
            minHeight: "auto",
            fontWeight: 400,
            fontSize: "1.2rem",
            borderRadius: "20px"
        },
        "& .Mui-selected": {
            background: theme.props.primaryColor,
            color: "#fff",
            fontWeight: "bold",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: 'initial'
        },
        // [theme.breakpoints.down('md')]: {
        //     // border: "solid 1px #000",
        //     marginBottom: 5,
        //     "& .MuiTab-root": {
        //         color: "#000",
        //     },
        //     // "& .Mui-selected": {
        //     //     color: "#ff0000",
        //     // },
        // }
    },
    button: {
        minWidth: "auto",
        width: 30,
        height: 30,
        background: "#ebebeb"
    },
    numCalendar: {
        display: 'flex',
        alignItems: "center",
        border: "solid 1px #fff",
        margin: "0 5px",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            padding: "9px 0",
            border: "solid 1px #000",
            marginBottom: 5,
        },

    },
    numOfLine: {
        display: 'flex',
        alignItems: "center",
        border: "solid 1px #fff",
        boxSizing: "border-box",
        fontSize: "1.3rem",
        fontWeight: "bold",
        "&>span": {
            margin: "0 5px",
            display: "block",
            color: "#fff"
        },
        [theme.breakpoints.down('md')]: {
            border: "solid 1px #000",
            justifyContent: "center",
            marginBottom: 5,
            padding: "9px 0",
            "&>span": {
                fontSize: 11,
                color: "#000"
            }
        },
    },
    changeFontSize: {
        display: 'flex',
        alignItems: "center",
        border: "solid 1px #fff",
        boxSizing: "border-box",
        marginLeft: 5,
        fontSize: "1.3rem",
        fontWeight: "bold",
        "&>span": {
            margin: "0 5px",
            display: "block",
            color: "#fff"
        },
        [theme.breakpoints.down('md')]: {
            border: "solid 1px #000",
            justifyContent: "center",
            marginBottom: 5,
            padding: "9px 0",
            "&>span": {
                fontSize: 11,
                color: "#000"
            }
        },
    },
    calendarDay: {
        minWidth: 120,
        textAlign: "center",
        padding: 0,
        // fontFamily: "Montserrat, sans-serif !important"
    },
    iconArrow: {
        background: theme.props.primaryColor,
        color: "#fff",
        padding: 0,
        "& .MuiSvgIcon-root": {
            fontSize: 35
        },
        [theme.breakpoints.down('md')]: {
            color: "#000"
        }
    },
    textField: {
        backgroundColor: '#dddddd',
        borderRadius: 4,
        width: 40,
        height: 30,
        float: 'left',
        marginTop: 8,
        "& .MuiOutlinedInput-input": {
            padding: 0
        },
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
            minWidth: 20,
            margin: 0,
            height: 27,
        },
    },
    segment: {
        backgroundColor: 'white',
        width: "80px !important",
        // color: "#42a5f5",
        // borderRadius: 6,
        margin: '0 !important',
        "& label": {
            height: "27px !important",
            lineHeight: "27px !important",
            padding: "0 !important"
        }
    },
    segmentContainer: {
        margin: "0 0 0 5px"
    },
    router: {
        backgroundColor: "#f1f1f1",
        // minWidth: 250,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            // minWidth: 210,
            // background: "#fff"
        },
        display: "flex",
    },
    tabBtn: {
        height: 40
    },
    router2: {
        backgroundColor: "#f1f1f1",
        // minWidth: 250,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            // minWidth: 210,
            // background: "#fff"
        },
        display: "flex",
    },
    mgBottom10: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px !important'
        }
    },
    marginTop: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px !important'
        }
    },
    customView: {
        "& .ant-popover-inner-content": {
            padding: 10,
        }
    },
    changeMinWidth: {
        "& button": {
            minWidth: "60px",
        },
    }
}))

export default HeaderCenter;

