import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AnnouncementCheck from '../../Custom/AnnouncementCheck';
import ListCommunity from './Communities/ListCommunity';
import {db} from '../../../config/firebaseConfig';
import {boardRef} from '../../../common/firebaseRef/annoucement';
import {setGlobal} from 'reactn'
import {Button} from 'antd';
import PaymentList from '../../Cart/PaymentList';
import useIntlId from '../../../hooks/useIntlId';
import {snapshot, useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function AnnouncementBoard(props) {
    const {user} = useSnapshot(userProxy)
    const classes = useStyles()
    const {lock, board} = useSnapshot(announcementProxy);
    const [openPurcharse, setOpenPurcharse] = useState(false)
    const [visualBoard, rotationIntl, unlockIntl] = useIntlId([
        'setting.visualBoard',
        'settings.announcement.rotation',
        'settings.announcement.unlock',
    ])

    const updateAnnouncementBar = fieldName => async (value) => {
        if (!user?.user_id)
            return;
        try {
            const batch = db.batch()
            const AnnouncementBar = boardRef(user?.user_id)
            const {board} = snapshot(announcementProxy)
            batch.set(AnnouncementBar, {
                ...board,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <AnnouncementCheck lock={lock} check={board?.display} setCheck={updateAnnouncementBar('display')}
                                   name={visualBoard}/>
                <AnnouncementCheck lock={lock} name={rotationIntl} value={board?.rotationInterval || 1} label={"s"}
                                   setValue={updateAnnouncementBar('rotationInterval')}/>
            </div>

            {/*<AnnouncementCheck lock={lock} check={board?.displayTips} setCheck={updateAnnouncementBar('displayTips')}*/}
            {/*                   name={"Calendar Tips"}/>*/}
            {/*<AnnouncementCheck lock={false} check={board?.displayCommunity}*/}
            {/*                   setCheck={updateAnnouncementBar('displayCommunity')}*/}
            {/*                   name={"Announcement from the community"}/>*/}
            <ListCommunity updateAnnouncementBar={updateAnnouncementBar('community')}/>
            {
                lock && <Button
                    onClick={() => setGlobal({
                        openSetting: true,
                        settingTab: 12
                    })}
                    className={classes.btnUnlock}>
                    <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                    {unlockIntl}</Button>
            }
            <PaymentList open={openPurcharse} setOpen={setOpenPurcharse}/>
        </div>
    );
}

export default AnnouncementBoard;
const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 15px',
        background: '#fff',
        padding: '0 20px',
        borderRadius: 15,
        alignItems: 'flex-start'
    },
    box: {
        background: '#fff',
        borderRadius: 15,
        // padding: 15,
    },
    btnUnlock: {
        margin: "auto",
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    iconButton: {
        marginRight: 10,
    }
}))
