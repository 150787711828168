import {proxy} from 'valtio/vanilla';
import {findIndex} from 'lodash';
import {getTimeRangeMinMax} from '../../common/calendarView/getTimeRange';
import {getGlobal} from 'reactn';
import {snapshot} from 'valtio';
import {calenviewTimeRangeProxy} from './calenviewTimeRange.proxy';
import store from 'store'
import {mergeProxy} from './common/mergeProxy';

const formatList = (data = []) => {
    if (data.length === 8) {
        return data
    }
    return new Array(8).fill(0).map((item, index) => {
        let idx = findIndex(data, i => parseInt(i.id) === index + 1)
        if (idx === -1) {
            return {
                id: index + 1
            }
        }
        return data[idx]
    })
}
const themesProxy = proxy({
    list: [],
    colors: store.get('colors') || {primary: '#1790FF', secondary: '#690003'},
    currentTheme: store.get("currentTheme") || {},
    themeCustom: store.get('themeCustom') || {},
    view: 'CustomMonth',
    fontSize: store.get('fontSize') || 9,
    calendarTab1: store.get('calendarTab1') ? store.get('calendarTab1') : 2,
    calendarTab2: store.get('calendarTab2') ? store.get('calendarTab2') : 2,
    setList: (data) => {
        if (Array.isArray(data)) {
            themesProxy.list = formatList(data)
        }
    },
    setTheme: (theme) => {
        mergeProxy(themesProxy.currentTheme, theme)
        const {refetchDateNow} = getGlobal()
        store.set("currentTheme", theme)
        const {setTime} = snapshot(calenviewTimeRangeProxy)
        setTime(getTimeRangeMinMax(refetchDateNow, theme))
    },
    openLeftMenu: true,
    onToggleLeftMenu: () => {
        themesProxy.openLeftMenu = !themesProxy.openLeftMenu
    },
    setColors: (data) => {
        themesProxy.colors = data
    },
    setView: (data) => {
        themesProxy.view = data
    },
    setFontSize: (data) => {
        themesProxy.fontSize = data
    },
    setCalendarTab1: (data) => {
        themesProxy.calendarTab1 = data
    },
    setCalendarTab2: (data) => {
        themesProxy.calendarTab2 = data
    },
})

// subscribe(themesProxy, (ops) => {
//     console.log("theme change", ops, themesProxy)
// })

export {
    themesProxy
}
