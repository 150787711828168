export const tutorialData = {
    "tutorial_main_2": {
        steps: [
            {
                title: 'TOPメニューの機能を知る',
                content: 'TOPメニューの機能を紹介します',
                el: '#tutorial-top-menu',
                placement: 'bottom'
            },
            {
                title: '2-1.他のGeniamアプリへアクセスするには？',
                content: '他のGeniamアプリを表示したい場合、アイコンをクリックします。',
                // el: '#tutorial-top-route',
                el: '#tutorial-top-menu',
                placement: 'bottom',
                note: [
                    {
                        img: "/healperTips/iconMileStone.svg",
                        text: "マイルストーン"
                    },
                    {
                        img: "/healperTips/appointmentGroup.svg",
                        text: "アポイントメント(グループ)"
                    },
                    {
                        img: "/healperTips/appointment1on1.svg",
                        text: "アポイントメント(1on1)"
                    },
                ]
            },
            {
                title: '2-2.カレンダーの表示期間を変更するには？',
                content: 'カレンダーに表示される期間を前後したい場合、アイコンをクリックします',
                el: '#tutorial-top-date',
                placement: 'bottom',
                note: [
                    {
                        img: "/healperTips/arrowLeft.svg",
                        text: "前月・前週・前日を表示"
                    },
                    {
                        img: "/healperTips/arrowRight.svg",
                        text: "翌月・翌週・翌日を表示"
                    },
                ]
            },
            {
                title: '2-3.カレンダーの月・週・日表示を切り替えるには？',
                content: 'カレンダーに表示される月・週・日表示を変更したい場合、アイコンをクリックします',
                el: '#tutorial-top-view',
                placement: 'bottom',
                note: [
                    {
                        img: "/healperTips/month.svg",
                        text: "月表示に変更"
                    },
                    {
                        img: "/healperTips/week.svg",
                        text: "週表示に変更"
                    },
                    {
                        img: "/healperTips/day.svg",
                        text: "週表示に変更"
                    },
                ]
            },
            {
                title: '2-4.今日の予定を表示するには？',
                content: '今日の予定を表示したい場合、アイコンをクリックします',
                el: '#tutorial-top-today',
                placement: 'bottom',
                // note: [
                //     {
                //         img: "/healperTips/view.svg",
                //         text: "今日の予定を表示"
                //     },
                // ]
            },
            {
                title: '2-5.期間の行・列の表示方法やテーマカラー、文字サイズを変更するには？',
                content: 'カレンダーの表示形式を変更したい場合、「ビュー」ボタンをクリックします。',
                el: '#calendar-viewbox-popover-setting',
                placement: 'bottom'
            },
            {
                title: '2-6.ビュー機能の操作方法とは？',
                content: '<div>①月、週、日、どの単位でビューをカスタマイズしたいか選択してください<br/>②カレンダーで表示する行や列の数を変更する場合は、「＋」「ー」をクリックしてください<br/>③文字の大きさを変更する場合は「＋」「ー」をクリックしてください<br/>④テーマ色を変更する場合は、お好みの色をクリックしてください<br/>⑤設定したビューを保存せず利用したい場合は、「設定を利用する」をクリックしてください<br/>⑥設定したビューを保存したい場合は、「１～８」の任意のテンプレートをクリックした上で<br/> 「設定の保存」をクリックしてください </div>',
                el: '#calendar-viewbox-popover-setting',
                img: "/image/tutorials/view.png",
                placement: 'bottom'
            },
            {
                title: '2-7.保存したビューを呼び出すには？',
                content: '<div>ビューを保存すると、TOPメニューのビューアイコンをクリックすると<br/>ビューを呼び出すことができます。<br/>ビューアイコンに表示されるアルファベットの意味は下記の通りです。<br/>M：月単位ビュー<br/>W：週単位ビュー<br/>D：日単位ビュー<br/> </div>',
                el: '#calendar-viewbox-popover-setting',
                img: "/image/tutorials/view.png",
                placement: 'bottom'
            },

        ]
    },
    "tutorial_main_3": {
        steps: [
            {
                title: '3.オプションメニューの機能を知る',
                content: 'オプションメニューの機能を紹介します。',
                el: '#calendar-top-bar-right',
                placement: 'bottom'
            },
            {
                title: '3-1.過去や未来の予定を検索するには？',
                content: '虫めがねボタンをクリックすると、検索フォームが表示されます。',
                el: '#calendar-top-bar-right-search',
                placement: 'bottom',
                img: "/image/tutorials/path311.png"
            },
            {
                title: '3-2.アプリの操作方法を確認するには？',
                content: '<div>アプリの操作方法は「？」ボタンをクリックし、<br/>知りたい情報をクリックしてください。</div>',
                el: '#calendar-top-bar-right-help',
                placement: 'bottom',
                img: '/image/tutorials/path321.png',
                contentBottom: 'ヘルプを見てもわからないアプリの操作方法やに関するお問い合わせは、下記ページからお問い合わせください。',
            },
            {
                title: '3-3.予定調整の結果の通知を確認するには？',
                content: '<div>Geniamの予定調整アプリ<br/>「GeniamAppointment」での予定調整結果を<br/>ベルボタンから確認することができます。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path331.png',
                contentBottom: '※予定調整アプリの利用方法は後日掲載させていただきます'
            },
            {
                title: '3-4.カレンダーの全般的な設定をするには？',
                content: '<div>歯車マークをクリックすることで、<br/>GoogleカレンダーやZOOMとの同期など、<br/>カレンダーの全般的な設定を行う画面を表示できます。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path341.png',
                contentBottom: null
            },
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path351.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path361.png',
                contentBottom: null
            },
            {
                title: '3-7.表示する時間帯を設定するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path371.png',
                contentBottom: null
            },
            {
                title: '3-8.カレンダーの強調する時間を設定するには？',
                content: '<div>カレンダー上で強調する時間の区切り線を作成することができます。<br/>この時間の設定方法を紹介します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'top',
                img: '/image/tutorials/path381.png',
                contentBottom: null
            },
            // {
            //
            //     title: '3-9.予定（イベント）のタグを管理するには？',
            //     content: '<div>タグを新しく作成するためには<br/>イベントタグ「＋」ボタンをクリックします。</div>',
            //     el: '#calendar-top-bar-right-setting',
            //     placement: 'bottom',
            //     img: '/image/tutorials/path391.png',
            //     contentBottom: null
            // },
            {
                title: '3-10.予定（イベント）のタグを管理するには？',
                content: '<div>タグを新しく作成するためには<br/>イベントタグ「＋」ボタンをクリックします。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path391.png',
                contentBottom: null
            },

        ]
    },
    "tutorial_main_3_1": {
        steps: [
            {
                title: '3-1.過去や未来の予定を検索するには？',
                content: '虫めがねボタンをクリックすると、検索フォームが表示されます。',
                el: '#calendar-top-bar-right-search',
                placement: 'bottom',
                img: '/image/tutorials/path311.png',
            },
            {
                title: '3-1.過去や未来の予定を検索するには？',
                content: '<div>フォーム右の<br/>▼ボタンをクリックすると、検索範囲を指定できます。</div>',
                el: '#calendar-top-bar-right-search',
                placement: 'bottom',
                img: '/image/tutorials/path312.png',
            },
            {
                title: '3-1.過去や未来の予定を検索するには？',
                content: '<div>フォーム右の<br/>▼ボタンをクリックすると、検索範囲を指定できます。</div>',
                el: '#calendar-top-bar-right-search',
                placement: 'bottom',
                img: '/image/tutorials/path313.png',
            },
            {
                title: '3-1.過去や未来の予定を検索するには？',
                content: '<div>フォームに検索したいイベント名を入力し、<br/>キーを押すと該当するイベントの一覧が表示されます。</div>',
                el: '#calendar-top-bar-right-search',
                placement: 'bottom',
                img: '/image/tutorials/path314.png',
            },
            {
                title: '3-1.過去や未来の予定を検索するには？',
                content: '<div>イベントの一覧のイベント名をクリックすると、<br/>イベントの詳細な情報を確認することができます</div>',
                el: '#calendar-top-bar-right-search',
                placement: 'bottom',
                img: '/image/tutorials/path315.png',
            },
        ]
    },
    "tutorial_main_3_2": {
        steps: [
            {
                title: '3-2.アプリの操作方法を確認するには？',
                content: '<div>アプリの操作方法は「？」ボタンをクリックし、<br/>知りたい情報をクリックしてください。</div>',
                el: '#calendar-top-bar-right-help',
                placement: 'bottom',
                img: '/image/tutorials/path321.png',
                contentBottom: 'ヘルプを見てもわからないアプリの操作方法やに関するお問い合わせは、下記ページからお問い合わせください。'
            },
        ]
    },
    "tutorial_main_3_3": {
        steps: [
            {
                title: '3-3.予定調整の結果の通知を確認するには？',
                content: '<div>Geniamの予定調整アプリ<br/>「GeniamAppointment」での予定調整結果を<br/>ベルボタンから確認することができます。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path331.png',
                contentBottom: '※予定調整アプリの利用方法は後日掲載させていただきます'
            },
            // {
            //     title: '3-3.予定調整の結果の通知を確認するには？',
            //     content: '<div>Geniamの予定調整アプリ<br/>「GeniamAppointment」での予定調整結果を<br/>ベルボタンから確認することができます。</div>',
            //     el: '#calendar-top-bar-right-notice',
            //     placement: 'bottom',
            //     img: '/image/tutorials/path331.png',
            //     contentBottom: '※予定調整アプリの利用方法は後日掲載させていただきます'
            // },
            // {
            //     title: '3-3.予定調整の結果の通知を確認するには？',
            //     content: '<div>Geniamの予定調整アプリ<br/>「GeniamAppointment」での予定調整結果を<br/>ベルボタンから確認することができます。</div>',
            //     el: '#calendar-top-bar-right-notice',
            //     placement: 'bottom',
            //     img: '/image/tutorials/path331.png',
            //     contentBottom: null
            // },
        ]
    },
    "tutorial_main_3_4": {
        steps: [
            {
                title: '3-4.カレンダーの全般的な設定をするには？',
                content: '<div>歯車マークをクリックすることで、<br/>GoogleカレンダーやZOOMとの同期など、<br/>カレンダーの全般的な設定を行う画面を表示できます。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path341.png',
                contentBottom: null
            },
        ]
    },
    "tutorial_main_3_5": {
        steps: [
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path351.png',
                contentBottom: null
            },
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>設定メニューの「Google と同期」をクッリクします</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path352.png',
                contentBottom: null
            },
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>「Google でログインする」をクリックします</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path353.png',
                contentBottom: null
            },
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>Google アカウントのログインウィンドウが表示されます。<br/>自身のアカウントを選択するか、<br/>ID・パスワードを入力しログインしてください。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path354.png',
                contentBottom: null
            },
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>Geniam カレンダーから<br/>Google アカウントへのアクセスを求める画面が表示されます。<br/>同期したい場合、「Continue」をクリックします</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path355.png',
                contentBottom: null
            },
            {
                title: '3-5. Google カレンダーと同期するには？',
                content: '<div>Geniam カレンダーと Google アカウントの同期に成功すると同期している<br/>Google アカウントメールアドレスや、Google カレンダーのプロジェクト名が表示されます。</div>',
                el: '#calendar-top-bar-right-notice',
                placement: 'bottom',
                img: '/image/tutorials/path356.png',
                contentBottom: null
            },
        ]
    },
    "tutorial_main_3_6": {
        steps: [
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path351.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>設定メニューの「ZOOMと同期」をクリックします</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path362.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>「ZOOMに接続」をクリックします</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path363.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>ZOOMアカウントのログインウィンドウが表示されます。<br/>ID・パスワード入力、またはSNS連携でログインしてください。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path364.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>Geniamカレンダーから<br/>ZOOMアカウントへのアクセスを求める画面が表示されます。<br/>同期したい場合、「Authorize」をクリックします</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path365.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>GeniamカレンダーとZOOMアカウントの同期に成功すると<br/>同期しているZoomアカウントメールアドレスや、<br/>Zoomの打ち合わせ予定が表示されます。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path366.png',
                contentBottom: null
            },
            {
                title: '3-6.ZOOMと同期するには？',
                content: '<div>GeniamカレンダーとZOOMアカウントの同期に成功すると<br/>予定作成時に「ZOOMミーティング作成」ボタンをクリックすると、<br/>自動的にZOOMURLを作成することができます。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path367.png',
                contentBottom: null
            },
        ]
    },
    "tutorial_main_3_7": {
        steps: [
            {
                title: '3-7.表示する時間帯を設定するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path371.png',
                contentBottom: null
            },
            {
                title: '3-7.表示する時間帯を設定するには？',
                content: '<div>設定メニューの「勤務時間」が青ハイライトになっていることを確認します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path372.png',
                contentBottom: null
            },
            {
                title: '3-7.表示する時間帯を設定するには？',
                content: '<div>「表示時間の範囲」で表示する開始時間、終了時間を<br/>「矢印ボタン」をクリックして選択します</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path373.png',
                contentBottom: null
            },
            {
                title: '3-7.表示する時間帯を設定するには？',
                content: '<div>表示時間帯を設定したら、「保存ボタン」をクリックすると<br/>表示する時間帯を変更することができます。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path374.png',
                contentBottom: null
            },
        ]
    },
    "tutorial_main_3_8": {
        steps: [
            {
                title: '3-8.カレンダーの強調する時間を設定するには？',
                content: '<div>カレンダー上で強調する時間の区切り線を作成することができます。<br/>この時間の設定方法を紹介します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'top',
                img: '/image/tutorials/path381.png',
                contentBottom: null
            },
            {
                title: '3-8.カレンダーの強調する時間を設定するには？',
                content: '<div>歯車マークをクリックし設定画面を表示します。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path382.png',
                contentBottom: null
            },
            {
                title: '3-8.カレンダーの強調する時間を設定するには？',
                content: '<div>「太線」の設定にて、太線を設定したい時間を指定し「保存」<br/>をクリックすることで強調する時間を設定できます。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path383.png',
                contentBottom: null
            },
        ]
    },
    "tutorial_main_3_10": {
        steps: [
            {
                title: '3-10.予定（イベント）のタグを管理するには？',
                content: '<div>タグを新しく作成するためには<br/>イベントタグ「＋」ボタンをクリックします。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path3101.png',
                contentBottom: null
            },
            {
                title: '3-10.予定（イベント）のタグを管理するには？',
                content: '<div>アイコンテンプレートを利用する場合は、<br/>「アイコンの挿入」ページで「アイコン名」を入力し、<br/>利用するアイコンを検索しクリックします。<br/>最後に「保存」をクリックしてください。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path3102.png',
                contentBottom: null
            },
            {
                title: '3-10.予定（イベント）のタグを管理するには？',
                content: '<div>アイコン画像をアップロードする場合は、<br/>「画像アップロード」をクリックし、<br/>「アップロードする」で画像を選択し、<br/>「アイコン名」で入力、「保存」をクリックしてください。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path3103.png',
                contentBottom: null
            },
            {
                title: '3-10.予定（イベント）のタグを管理するには？',
                content: '<div>タグの名称変更、削除を行うには、<br/>タグの上にマウスポイントを置き、<br/>名称変更「鉛筆ボタン」、削除「ゴミ箱ボタン」を<br/>クリックします。</div>',
                el: '#calendar-top-bar-right-setting',
                placement: 'bottom',
                img: '/image/tutorials/path3104.png',
                contentBottom: null
            },
        ]
    },
    "tutorial_main_4": {
        steps: [
            {
                title: '左メニューの機能を知る',
                content: '左メニューの機能を紹介します',
                el: '#tutorial-left-bar',
                placement: 'right'
            },
            {
                title: '4-1.今日の日付を確認するには？',
                content: '今日の日付を確認できます。',
                el: '#tutorial-left-calendar',
                placement: 'right'
            },
            {
                title: '4-2.左メニューを隠す・表示するには？',
                content: '左メニューの表示・非表示をしたい時、表示・隠すボタンをクリックします。',
                el: '#tutorial-left-button-hide',
                placement: 'right',
                img: '/image/tutorials/path42.png'
            },
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: 'カレンダーのプロジェクトを追加したい場合、ボタンをクリックします。',
                el: '#add-new-project',
                placement: 'right'
            },
            {
                title: '4-4.Geniamカレンダープロジェクトを表示・非表示するには？',
                content: '<div>「Geniamカレンダー」では、<br/>Geniamアプリで作成・保存されたカレンダープロジェクトの<br/>「✓」をクリックすることで表示、非表示を設定できます。</div>',
                el: '#geniam-calendar-list',
                placement: 'right',
                img: '/image/tutorials/path44.png'
            },
            {
                title: '4-5.Geniamカレンダープロジェクトの名前・色等を変更するには？',
                content: '<div>歯車マークをクリックすると、各プロジェクトの名前や色を変更できる<br/>設定画面を表示することができます。</div>',
                el: '#geniam-calendar-list-setting',
                placement: 'right',
                img: '/image/tutorials/path45.png'
            },
            {
                title: '4-6.Geniamカレンダープロジェクトの一覧を表示・非表示するには？',
                content: '<div>^ボタンをクリックすると、左メニューのGeniamカレンダープロジェクト一覧が<br/>非表示になります。<br/>もう一度クリックすると、一覧が表示されます。</div>',
                el: '#geniam-calendar-list-expand',
                placement: 'right',
                img: '/image/tutorials/path46.png'
            },
            {
                title: '4-7.Googleカレンダープロジェクトを表示・非表示するには？',
                content: '<div>「Googleカレンダー」では、<br/>Googleカレンダーに保存されたカレンダープロジェクトの<br/>「✓」をクリックすることでカレンダーの表示、非表示を設定できます。</div>',
                el: '#google-calendar-list',
                placement: 'right',
                img: ''
            },
            {
                title: '4-8.Googleカレンダープロジェクトの同期を設定するには？',
                content: '歯車マークをクリックすると、Googleカレンダーとの同期設定画面を表示できます。',
                el: '#google-calendar-list-setting',
                placement: 'right',
                img: '/image/tutorials/path48.png'
            },
            {
                title: '4-9.Googleカレンダープロジェクトの一覧を表示・非表示するには？',
                content: '<div>^ボタンをクリックすると、左メニューのGoogleカレンダープロジェクト一覧が非表示になります。<br/>もう一度クリックすると、一覧が表示されます。</div>',
                el: '#google-calendar-list-expand',
                placement: 'right',
                img: '/image/tutorials/path49.png'
            },

        ]
    },
    "tutorial_main_4_3": {
        steps: [
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: 'カレンダーのプロジェクトを追加したい場合、ボタンをクリックします。',
                el: '#add-new-project',
                placement: 'right'
            },
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: 'プロジェクト名を入力します。',
                el: '#add-new-project',
                placement: 'right',
                img: '/image/tutorials/path432.png'
            },
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: 'テーマカラーボタンをクリックすると、テーマカラーを選択できます。',
                el: '#add-new-project',
                placement: 'right',
                img: '/image/tutorials/path433.png'
            },
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: '入力が終わったら「保存」ボタンをクリックします。',
                el: '#add-new-project',
                placement: 'right',
                img: '/image/tutorials/path4332.png'
            },
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: '作成したプロジェクトをGoogleカレンダーと同期したい場合は「OK」をクリックします。同期しない場合は「CANCEL」をクリックします。',
                el: '#add-new-project',
                placement: 'right',
                img: '/image/tutorials/path434.png'
            },
            {
                title: '4-3.カレンダープロジェクトを追加するには？',
                content: 'プロジェクトが追加されます。',
                el: '#add-new-project',
                placement: 'right',
                img: '/image/tutorials/path435.png'
            },
        ]
    },
    "tutorial_main_5": {
        steps: [
            {
                title: '5-1.基本的な予定を作成するには？',
                el: '#calendarDiv0',
                placement: 'bottom',
            },
            {
                title: '5.2. 基本的な予定を編集するには？',
                el: '#calendarDiv0',
                placement: 'bottom'
            },
            {
                title: '5.3. 予定を削除するには？',
                el: '#calendarDiv0',
                placement: 'bottom'
            },
            {
                title: '5.4. 繰り返し予定を作成するには？',
                el: '#calendarDiv0',
                placement: 'bottom'
            },
            {
                title: '5.5. 繰り返し予定を編集するには？',
                el: '#calendarDiv0',
                placement: 'bottom'
            },
            {
                title: '5.6. 繰り返し予定を削除するには？',
                el: '#calendarDiv0',
                placement: 'bottom'
            },

        ]
    },
    "tutorial_main_5_1": {
        steps: [
            {
                title: '5-1.基本的な予定を作成するには？',
                content: '<div>予定を入力したい時刻をクリックし、\n<br/>ドラッグしてください。予定枠を作成することができます。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/dragCreateEvent.svg"
            },
            {
                title: '5-1.基本的な予定を作成するには？',
                content: 'マウスのクリックを離すと予定入力画面が表示されます。',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/createEvent.svg"
            },
            {
                title: '5-1.基本的な予定を作成するには？',
                content: '<div>タイトルを入力します。<br/>必要に応じて、時間変更、場所や説明の入力を行います。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.1.3.svg"
            },
            {
                title: '5-1.基本的な予定を作成するには？',
                content: '<div>オンラインミーティングの場合、<br/>「Zoomミーティング作成」をクリックすることで<br/>ZoomミーティングURLを作成することが可能です。<br/>※事前に「3-6.Zoomと同期するには？」を実施してください</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.1.4.svg"
            },
            {
                title: '5-1.基本的な予定を作成するには？',
                content: '予定の入力が完了したら保存をクリックします。',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.1.5.svg"
            },
            {
                title: '5-1.基本的な予定を作成するには？',
                content: '予定の入力が完了したら保存をクリックします。',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.1.6.svg"
            },
        ]
    },
    "tutorial_main_5_3": {
        steps: [
            {
                title: '5-3.予定を削除するには？',
                content: '<div>削除したい予定をクリックすると<br/>予定の編集画面が表示されます。<br/>「ゴミ箱」ボタンをクリックしてください。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.3.1.svg"
            },
            {
                title: '5-3.予定を削除するには？',
                content: '<div>「削除」ボタンをクリックします。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.3.2.svg"
            },
            {
                title: '5-3.予定を削除するには？',
                content: '<div>予定が削除されます。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.3.3.svg"
            },
        ]
    },
    "tutorial_main_5_4": {
        steps: [
            {
                title: '5-4.繰り返し予定を作成するには？',
                content: '<div>予定を入力したい時刻をクリックし、<br/>ドラッグしてください。予定枠を作成することができます。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.4.1.svg"
            },
            {
                title: '5-4.繰り返し予定を作成するには？',
                content: '<div>マウスのクリックを離すと予定入力画面が表示されます。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.4.2.svg"
            },
            {
                title: '5-4.繰り返し予定を作成するには？',
                content: '<div>繰り返し予定を作成するときは、<br/>「繰り返しボタン」横の「繰り返さない」をクリックします。<br/>・毎日<br/>・平日<br/>・毎週同じ曜日」<br/>等の選択肢を選び<br/>「保存」することで繰り返し予定を保存できます</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.4.3.svg"
            },
            {
                title: '5-4.繰り返し予定を作成するには？',
                content: '<div>繰り返しの間隔や、終了日、繰り返す回数をカスタマイズ設定<br/>する場合は「繰り返し予定の設定」をクリックします。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.4.4.svg"
            },
            {
                title: '5-4.繰り返し予定を作成するには？',
                content: '<div>繰り返しの間隔や、終了日、繰り返す回数をカスタマイズ設定<br/>する場合は「繰り返し予定の設定」をクリックします。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.4.5.svg"
            },
        ]
    },
    "tutorial_main_5_5": {
        steps: [
            {
                title: '5-5.繰り返し予定を編集するには？',
                content: '<div>編集したい予定をクリックすると<br/>予定の編集画面が表示されます。<br/>変更を行い「保存」ボタンをクリックしてください。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.5.1.svg"
            },
            {
                title: '5-5.繰り返し予定を編集するには？',
                content: '<div>変更したい予定に合わせて選択肢を選び「OK」<br/>をクリックします。<br/>・この予定：選択した予定のみ変更し、他の日程を変更しない場合<br/>・これ以降のすべての予定：選択した予定以降の繰り返す予定を変更する場合<br/>・すべての予定：過去含め全ての繰り返し予定を変更する場合</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.5.2.svg"
            },
        ]
    },
    "tutorial_main_5_6": {
        steps: [
            {
                title: '5-6.繰り返し予定を削除するには？',
                content: '<div>削除したい予定をクリックすると<br/>予定の編集画面が表示されます。<br/>「ゴミ箱」ボタンをクリックしてください。</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.3.1.svg"
            },
            {
                title: '5-6.繰り返し予定を削除するには？',
                content: '<div>削除したい予定に合わせて選択肢を選び「OK」をクリックします。<br/>・この予定：選択した予定のみ削除し、他の日程を削除しない場合<br/>・これ以降のすべての予定：選択した予定以降の繰り返す予定を削除する場合<br/>・すべての予定：過去含め全ての繰り返し予定を削除する場合</div>',
                el: '#calendarDiv0',
                placement: 'bottom',
                img: "/healperTips/5.6.2.svg"
            },
        ]
    }
}


export const getStepsFromId = (id) => {
    if (tutorialData[id]) {
        return tutorialData[id].steps
    }
    return []
}
