import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'antd';
import {ReactComponent as SizeSVG} from '../../../asSvg/size.svg';
import {ReactComponent as EditWorkSpace} from '../../../asSvg/icons/editWorkSpace.svg';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import useIntlId from '../../../hooks/useIntlId';
import moment from 'moment';
import TabDate from '../MyWorkSpace/TabDate';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';

const Text = styled.div(props => ({
    color: props?.color ? `${props?.color}` : "#000",
    fontWeight: 700,
    textTransform: "uppercase"
}));
const Box = styled.div(props => ({
    backgroundColor: props?.backgroundColor ? `${props?.backgroundColor}` : "#f1f1f1",
    height: 40,
    borderRadius: "5px",
    border: "solid 1px #fff",
    width: props?.width ? `${props?.width}%` : "50%",
}));

function ContentHoverView({item, onEditTheme}) {
    const {colors} = useSnapshot(themesProxy)
    const classes = useStyles()
    const [monthText, dayText, weekText, editWorkspaceIntl] = useIntlId(['customView.month', 'customView.day', 'customView.week', 'edit.workspace.setting'])
    const [num, setNum] = useState(1)
    const [text, setText] = useState(null)
    const [numColumns, setNumColumns] = useState(1)
    useEffect(() => {
        setNumColumns(item?.viewCalendar === "CustomDay" ? 1 : item?.columns)
        if (item?.advanceView) {
            if (item?.settingToday) {
                setNum(item?.numsDay * item?.rows || 1)
                setText(`${dayText}`)
                return;
            }
            let rangeDay = (item?.rangeDay && item?.rangeDay[0] !== '' && item?.rangeDay[1] !== '') ? item?.rangeDay : []
            let start = moment(rangeDay[0]) || moment().startOf("day").format('YYYY-MM-DD')
            let end = moment(rangeDay[1]) || moment().add(7, 'days').endOf("day").format('YYYY-MM-DD')
            setNum((end?.diff(start, 'days') + 1) * item?.rows)
            setText(`${dayText}`)
            return
        }
        if (item?.viewCalendar === "CustomMonth") {
            setText(`${monthText}`)
            setNum(item?.rows * item?.columns)
            return;
        }
        if (item?.viewCalendar === "CustomWeek") {
            setText(`${weekText}`)
            setNum(item?.rows * item?.columns)
            return;

        }
        if (item?.viewCalendar === "CustomDay") {
            setText(`${dayText}`)
            setNum(item?.rows * item?.columns)
        }

        // eslint-disable-next-line
    }, [item])
    const newArray = (number) => {
        let newArr = []
        for (let i = 0; i < number; i++) {
            newArr.push(i)
        }
        return newArr
    }

    return (
        <div className={classes.contentContainer}>
            {
                item?.colorPrimary && <div>
                    <Row gutter={8}>
                        <Col span={24}>
                            {(Boolean(item?.tabCalenview) || item?.tabCalenview === 0) &&
                                <TabDate
                                    themeItem={item}
                                    sizeMini={true}
                                />
                            }
                        </Col>
                        <Col span={12}>
                            <div className={classes.item}>
                                <div className={classes.itemText}>
                                    <Text
                                        color={item?.colorPrimary}
                                    >
                                        {num} {text}
                                    </Text>
                                </div>
                            </div>

                        </Col>
                        <Col span={12}>
                            <div className={classes.item}>
                                <SizeSVG className={classes.icon}
                                         style={{fill: item?.colorPrimary ? `${item?.colorPrimary}` : "#000"}}/>
                                <div className={classes.itemText}>
                                    <Text color={item?.colorPrimary}>{item?.size}</Text>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <div className={classes.listBox}>
                        {
                            newArray(item?.rows).map((row, index) => {
                                return (
                                    <Row key={index}>
                                        {
                                            newArray(numColumns).map((col, index) => {
                                                let width = 100 / numColumns
                                                return (
                                                    <Box width={width} backgroundColor={item?.colorPrimary}
                                                         key={index}/>
                                                )
                                            })
                                        }
                                    </Row>
                                )
                            })
                        }

                    </div>
                </div>
            }

            <Button block
                    type="primary"
                    onClick={() => {
                        onEditTheme({...item})
                    }}
                    style={{backgroundColor: item?.colorPrimary ? `${item?.colorPrimary}` : `${colors.primary}`}}
                    className={classes.btnEditWorkSpace}>
                <EditWorkSpace
                    style={{fill: item?.colorPrimary ? `${item?.colorPrimary}` : `${colors.primary}`}}/>
                {editWorkspaceIntl}
            </Button>


        </div>
    );
}

export default ContentHoverView;
const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: 290,
        backgroundColor: "#fff"
    },
    item: {
        backgroundColor: "#f1f1f1",
        borderRadius: 7,
        padding: 6,
        alignItems: "center",
        display: "flex",
        flexDirection: "row"
    },
    itemText: {
        backgroundColor: "#fff",
        padding: 5,
        width: "100%",
        textAlign: "center",
        borderRadius: "5px"
    },
    icon: {
        marginRight: 5,
    },
    listBox: {
        backgroundColor: "#f1f1f1",
        padding: 7,
        borderRadius: "5px",
        margin: "8px 0"
    },
    btnEditWorkSpace: {
        position: "relative",
        fontWeight: 700,
        textTransform: "uppercase",
        color: "#fff",
        border: "none",
        outline: "none",
        height: 37,
        borderRadius: "7px",
        '&:hover': {
            border: "none",
            outline: "none",
        },
        '&:focus': {
            border: "none",
            outline: "none",
        },
        "& svg": {
            position: "absolute",
            left: 5,
            top: "50%",
            marginTop: -15
        }
    }
}))
