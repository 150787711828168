import {getGlobal} from "reactn";
import moment from "moment";
import {snapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {themesProxy} from '../../store/proxy/themes.proxy';

export const getHeader = (numberId) => {
    const {refetchDateNow} = getGlobal()
    const {user} = snapshot(userProxy)
    const {currentTheme} = snapshot(themesProxy)
    if (currentTheme?.advanceView) {
        let date, endDate
        if (currentTheme?.settingToday) {
            let numsDay = currentTheme?.numsDay || 1
            let indexToday = currentTheme?.indexToday || 1
            date = moment(refetchDateNow).startOf("day").subtract(indexToday - 1, "days").add(numberId * numsDay, "day").format('YYYY-MM-DD')
            endDate = moment(refetchDateNow).endOf("day").add(numsDay - indexToday, "days").add(numberId * numsDay, "day").format('YYYY-MM-DD')
            return `${moment(date).format("MM/DD")} - ${moment(endDate).format("MM/DD")}`
        }
        let rangeDay = currentTheme?.rangeDay || []
        let start = rangeDay[0] || moment().startOf("day").format('YYYY-MM-DD')
        let end = rangeDay[1] || moment().add(7, 'days').endOf("day").format('YYYY-MM-DD')
        date = moment(start).startOf("day").format('YYYY-MM-DD')
        endDate = moment(end).endOf("day").format('YYYY-MM-DD')
        return `${moment(date).format("MM/DD")} - ${moment(endDate).format("MM/DD")}`

    }
    if (currentTheme?.viewCalendar === "CustomDay") {
        let startAdd = numberId * currentTheme?.columns
        let endAdd = currentTheme?.columns - 1
        let timeStart = moment(refetchDateNow).startOf("day").add(startAdd, "day").format()
        let timeEnd = moment(timeStart).startOf("day").add(endAdd, "day").format()
        if (moment(timeStart).isSameOrAfter(moment(timeEnd).startOf("day"))) {
            return `${moment(timeStart).format("MM/DD")}`
        }
        return `${moment(timeStart).format("MM/DD")} - ${moment(timeEnd).format("MM/DD")}`
    }

    if (currentTheme?.viewCalendar === "CustomWeek") {
        let week = moment(refetchDateNow).add(numberId, "week").isoWeek();
        if (user?.language === "ja-jp")
            return week + " 週目 "
        if (user?.language === "vi-vn")
            return "Tuần " + week
        return "Week " + week
    } else {
        if (currentTheme?.viewCalendar === 'CustomMonth') {
            return moment(refetchDateNow).add(numberId, "months").format('MMMM')
        }
        return moment(refetchDateNow).add(numberId, "days").format("MM/DD")
    }
};
