import {v4 as uuidv4} from 'uuid';
import {snapshot} from 'valtio';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {userProxy} from '../../store/proxy/user.proxy';
import {cloneDeep, findIndex} from 'lodash';


const getDefaultProjects = () => {
    const {calendarUser} = snapshot(userProxy)
    const {lastProjectSelect = null, idCalenderHidden = []} = calendarUser
    const {allProjects} = snapshot(projectsProxy)
    if (!allProjects?.length)
        return null;
    const avaiableProjects = cloneDeep(allProjects).filter(item => !idCalenderHidden?.includes(item.id))
    if (!avaiableProjects.length)
        return null
    if (lastProjectSelect?.project_uuid) {
        let idx = findIndex(avaiableProjects, {id: lastProjectSelect.project_uuid})
        if (idx !== -1) {
            return {
                id: avaiableProjects[idx].id,
                color: avaiableProjects[idx].backgroundColor || avaiableProjects[idx].color,
                is_google: avaiableProjects[idx].is_google
            }
        }
    }
    return {
        id: avaiableProjects[0].id,
        color: avaiableProjects[0].backgroundColor || avaiableProjects[0].color,
        is_google: avaiableProjects[0].is_google
    }
}

const getIdentityDefault = () => {
    const {calendarUser} = snapshot(userProxy)
    const {lastProjectSelect = null, identitySettings = []} = calendarUser
    if (!identitySettings?.length)
        return null;
    if (!lastProjectSelect?.colorId)
        return null;
    let idx = findIndex(identitySettings, {id: lastProjectSelect.colorId})
    if (idx === -1)
        return null
    if (identitySettings[idx].isDeleted || identitySettings[idx].isArchive) {
        return null
    }
    return identitySettings[idx]
}
export const formatEventSelect = (info) => {
    let project = getDefaultProjects()
    let identity = getIdentityDefault()
    return {
        title: "",
        allDay: info.allDay,
        start: info.start,
        end: info.end,
        task: "",
        isMileStone: info.allDay,
        id: 'evt_' + uuidv4(),
        project_uuid: project?.id || "",
        borderColor: project?.color || "",
        googleEvent: project?.is_google,
        colorId: identity?.id || null,
        backgroundColor: identity?.color || project?.color
    }
}
