import React, {useContext, useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {DayPickerRangeController} from 'react-dates';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import {concat, findIndex} from 'lodash';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import isSameDay from 'react-dates/src/utils/isSameDay';
import {css, withStyles} from 'react-with-styles';
import "../../../../Meeting/DatePicker/styles.css"
import {HORIZONTAL_ORIENTATION, START_DATE} from 'react-dates/src/constants';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import ScrollableOrientationShape from 'react-dates/src/shapes/ScrollableOrientationShape';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';
import {UserContext} from "../../../../../context/UserContext";
import isAfterDay from "react-dates/esm/utils/isAfterDay";
import isBeforeDay from "react-dates/esm/utils/isBeforeDay";
import {CALENDAR_COLLECTION} from "../../../../../config/constants";
import Fire from "../../../../../config/firebaseConfig"
import CancelButton from "../../../../Dialog/CancelButton";
import SaveButton from "../../../../Dialog/SaveButton";
import useIntlId from '../../../../../hooks/useIntlId';
import {useMyCalSettings} from '../../../../../actions/user';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../../store/proxy/user.proxy';

const propTypes = {
    autoFocusEndDate: PropTypes.bool,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    startDateOffset: PropTypes.func,
    endDateOffset: PropTypes.func,
    showInputs: PropTypes.bool,
    minDate: momentPropTypes.momentObj,
    maxDate: momentPropTypes.momentObj,

    keepOpenOnDateSelect: PropTypes.bool,
    minimumNights: PropTypes.number,
    isOutsideRange: PropTypes.func,
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    daysViolatingMinNightsCanBeClicked: PropTypes.bool,

    // DayPicker props
    enableOutsideDays: PropTypes.bool,
    numberOfMonths: PropTypes.number,
    orientation: ScrollableOrientationShape,
    verticalHeight: PropTypes.number,
    withPortal: PropTypes.bool,
    initialVisibleMonth: PropTypes.func,
    renderCalendarInfo: PropTypes.func,
    renderMonthElement: PropTypes.func,
    renderMonthText: PropTypes.func,

    navPrev: PropTypes.node,
    navNext: PropTypes.node,
    renderNavPrevButton: PropTypes.func,
    renderNavNextButton: PropTypes.func,

    onPrevMonthClick: PropTypes.func,
    onNextMonthClick: PropTypes.func,
    onOutsideClick: PropTypes.func,
    renderCalendarDay: PropTypes.func,
    renderDayContents: PropTypes.func,
    renderKeyboardShortcutsButton: PropTypes.func,
    renderKeyboardShortcutsPanel: PropTypes.func,

    // i18n
    monthFormat: PropTypes.string,

    isRTL: PropTypes.bool,
};

const defaultProps = {
    // example props for the demo

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 0,
    isDayBlocked: () => false,
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
    isDayHighlighted: () => false,
    enableOutsideDays: false,
    daysViolatingMinNightsCanBeClicked: false,

    // calendar presentation and interaction related props
    orientation: HORIZONTAL_ORIENTATION,
    verticalHeight: undefined,
    withPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    onOutsideClick() {
    },
    keepOpenOnDateSelect: false,
    renderCalendarInfo: null,
    isRTL: false,
    renderMonthText: null,
    renderMonthElement: null,
    renderKeyboardShortcutsButton: undefined,
    renderKeyboardShortcutsPanel: undefined,

    // navigation related props
    navPrev: null,
    navNext: null,
    renderNavPrevButton: null,
    renderNavNextButton: null,
    onPrevMonthClick() {
    },
    onNextMonthClick() {
    },

    // internationalization
    monthFormat: 'MMMM YYYY',

};

function DateRangePickerWraper({
                                   styles,
                                   setOpen,
                                   openDayOff,
                                   setOpenDayOff,
                                   data, setData,
                                   onClose,
                                   eventSetting = null,
                                   setEventSetting = null,
                                   eventUpdate = null,
                                   publicHoliDays = [],
                                   clx
                               }) {
    const {user} = useSnapshot(userProxy)
    const [displaySettings = []] = useMyCalSettings()
    const [focusedInput, setFocusedInput] = useState(START_DATE);
    const [timeChange, setTimeChange] = useState(null)
    const [eventTime] = useIntlId(['event.dialog.create.field.date'])

    useEffect(() => {
        if (eventUpdate) {
            setData(eventUpdate)
        }

        if (eventSetting)
            setData(eventSetting)

        if (data && data.startDay) {
            setTimeChange({
                start: moment(eventSetting?.startDay ? eventSetting.startDay : eventUpdate?.startDay ? eventUpdate.startDay : data.startDay).startOf('day').format(),
                end: moment(eventSetting?.endDay ? eventSetting.startDay : eventUpdate?.startDay ? eventUpdate.startDay : data.startDay).endOf('day').format()
            })
        } else {
            setTimeChange({
                start: moment().startOf('day').format(),
                end: moment().endOf('day').format()
            })
        }
        // eslint-disable-next-line
    }, [])

    const onDatesChange = ({startDate, endDate}) => {
        // valid time
        let end = endDate
        if (end)
            displaySettings.forEach(item => {
                if (moment(startDate).isBefore(item.startDay) && moment(end).isAfter(item.endDay)) {
                    end = moment(item.endDay).subtract(1, "day").format()
                }
            })
        setTimeChange({
            start: moment(startDate),
            end: end ? moment(end) : moment(startDate)
        })
    }

    const handleChangeTime = () => {
        let timeStart = moment(timeChange.start).format()
        let timeEnd = moment(timeChange.end).format()

        if (!openDayOff) {
            setOpen(false)
            setOpenDayOff(false)
            setData({
                ...data,
                startDay: timeStart,
                endDay: timeEnd
            })
        }
        if (openDayOff && eventSetting) {
            updateSettingData(timeStart, timeEnd).catch(e => console.log(e.toString()))
        }

        if (openDayOff && !eventSetting) {
            let dayOffs = data.dayOffs || []
            dayOffs.push({start: timeStart, end: timeEnd, createdAt: moment().format()})
            data.dayOffs = dayOffs
            setData({...data})
        }
        setOpen(false)
        setOpenDayOff(false)
        setTimeChange(null)
        if (setEventSetting)
            setEventSetting(null)
    }

    const updateSettingData = async (timeStart, timeEnd) => {
        try {
            const settingDocRef = Fire.firestore().doc(`${CALENDAR_COLLECTION}/${user.user_id}/settings/${eventSetting.id}`)
            let dayOffs = eventSetting.dayOffs
            dayOffs.push({start: timeStart, end: timeEnd, createdAt: moment().format()})

            await settingDocRef.set({dayOffs, updatedAt: moment().format()}, {merge: true})

        } catch (e) {
            console.log(e.toString())
        }
    }

    const isDayBlocked = (day) => {
        let result = false
        if (openDayOff) {

            if (isBeforeDay(day, moment(data.startDay)) || (data.endDay ? isAfterDay(day, moment(data.endDay)) : false))
                result = true

            let dayOffs = data.dayOffs || []
            concat(dayOffs, publicHoliDays).forEach(dayOff => {
                if (isSameDay(day, moment(dayOff.start)) || isSameDay(day, moment(dayOff.end)) ||
                    moment(day).isBetween(dayOff.start, dayOff.end, null, "[]"))
                    result = true
            })
            let hiddenDays = data.hiddenDays
            if (hiddenDays.includes(moment(day).local().isoWeekday()))
                result = true

        } else {
            if (eventUpdate) {
                let index = findIndex(displaySettings, dis => dis.id === eventUpdate.id)
                if (index === -1)
                    return
                if (index < displaySettings.length - 1) {
                    if (moment(day).isSameOrAfter(moment(displaySettings[index + 1].startDay).startOf('day'))) {
                        result = true
                    }
                }
                if (index > 0) {
                    if (moment(day).isSameOrBefore(moment(displaySettings[index - 1].endDay).endOf('day')) || moment(day).isSameOrBefore(moment())) {
                        result = true
                    }
                }
                return result
            } else {
                displaySettings.forEach((setting, index) => {
                    if (isAfterDay(day, moment(setting.startDay).subtract(1, 'day')) && isBeforeDay(day, moment(setting.endDay).add(1, 'day')))
                        result = true

                    let hiddenDays = data.hiddenDays
                    if (hiddenDays.includes(moment(day).local().isoWeekday()))
                        result = true
                })
            }
        }
        return result
    }

    if (!timeChange) return null

    return (
        <div>
            <div
                style={{marginBottom: 10, marginTop: 10, padding: 10}}
            >
                <TextField
                    id="eventTime"
                    value={`${moment(timeChange.start).format("YYYY-MM-DD")} ~ ${moment(timeChange.end).format("YYYY-MM-DD")}`}
                    type={'text'}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment
                            position={"end"}>{<AccessAlarmIcon/>}</InputAdornment>
                    }}
                    label={eventTime}
                />
            </div>

            <DayPickerRangeController
                {...defaultProps}
                isDayBlocked={isDayBlocked}
                startDate={moment(timeChange.start)} // momentPropTypes.momentObj or null,
                endDate={moment(timeChange.end)} // momentPropTypes.momentObj or null,
                onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => {
                    let newFocusedInput = !focusedInput ? START_DATE : focusedInput
                    setFocusedInput(newFocusedInput)
                }}
                renderCalendarInfo={() => renderDatePresets(styles, timeChange, setTimeChange)}
            />
            <div className={'clf-flex-center'}>
                <CancelButton
                    onClick={() => {
                        setTimeChange(null)
                        onClose()
                    }}
                />
                <SaveButton
                    onClick={handleChangeTime}
                />
            </div>
        </div>

    );
}

function renderDatePresets(styles, timeChange, setTimeChange) {
    // const today = moment();
    // const presets = [1, 2, 3, 4, 5, 6, 7, 8].map(value => {
    //     let numAddDay = value * 7 - 1
    //     return {
    //         text: `Next ${value} Week`,
    //         start: today,
    //         end: moment().add(numAddDay, 'day'),
    //     }
    // });
    return (
        <div {...css(styles.PresetDateRangePicker_panel)}>
            {/*{presets.map(({text, start, end}) => {*/}
            {/*    const isSelected = isSameDay(start, timeChange.start) && isSameDay(end, timeChange.end);*/}
            {/*    return (*/}
            {/*        <button*/}
            {/*            style={{fontSize: '13px', minWidth: 110, marginTop: 10}}*/}
            {/*            key={text}*/}
            {/*            {...css(*/}
            {/*                styles.PresetDateRangePicker_button,*/}
            {/*                isSelected && styles.PresetDateRangePicker_button__selected,*/}
            {/*            )}*/}
            {/*            type="button"*/}
            {/*            onClick={() => {*/}
            {/*                setTimeChange({*/}
            {/*                    start: start,*/}
            {/*                    end: end*/}
            {/*                })*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {text}*/}
            {/*        </button>*/}
            {/*    );*/}
            {/*})}*/}
        </div>
    );
}

DateRangePickerWraper.propTypes = propTypes;
DateRangePickerWraper.defaultProps = defaultProps;
export default withStyles(({reactDates: {color}}) => ({
    PresetDateRangePicker_panel: {
        display: 'flex',
        padding: '0 22px 11px 22px',
    },

    PresetDateRangePicker_button: {
        position: 'relative',
        height: '100%',
        textAlign: 'center',
        background: 'none',
        border: `2px solid ${color.core.primary}`,
        color: color.core.primary,
        padding: '4px 12px',
        marginRight: 8,
        font: 'inherit',
        fontWeight: 700,
        lineHeight: 'normal',
        overflow: 'visible',
        boxSizing: 'border-box',
        cursor: 'pointer',

        ':active': {
            outline: 0,
        },
    },

    PresetDateRangePicker_button__selected: {
        color: color.core.white,
        background: color.core.primary,
    },
}))(DateRangePickerWraper);
