import React from 'react';
import {setGlobal, useGlobal} from 'reactn';
import useIntlId from '../../../hooks/useIntlId';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import AnnouncementCheck from '../../Custom/AnnouncementCheck';
import Remind from '../AnnouncementBar/Remind';
import AnnouncementSet from '../AnnouncementBar/AnnouncementSet';
import {Button} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import {cloneDeep, isUndefined} from 'lodash';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function RemindBar(props) {
    const classes = useStyles()
    const {user} = useSnapshot(userProxy)
    const {lock, bar} = useSnapshot(announcementProxy)
    const [
        RotationIntl,
        unlockIntl,
        remindDisplay
    ] = useIntlId([
        'settings.announcement.rotation',
        'settings.announcement.unlock',
        'setting.remindDisplay'
    ])
    const updateAnnouncementBar = fieldName => async (value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id);
            const newBar = cloneDeep({...bar});

            if(newBar?.dataList && newBar.dataList.length) {
                newBar.dataList.forEach((item, index) => {
                    if (isUndefined(item.rotation)) {
                        newBar.dataList[index].rotation = true;
                    }
                });
            }

            batch.set(AnnouncementBar, {
                ...newBar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit();
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <AnnouncementCheck lock={lock} check={bar?.remindBar || false}
                                   setCheck={updateAnnouncementBar("remindBar")}
                                   name={remindDisplay}/>
                <AnnouncementCheck lock={lock} name={RotationIntl} value={bar?.rotationInterval || 1} label={"s"}
                                   setValue={updateAnnouncementBar("rotationInterval")} borderBottom={'none'}/>
            </div>

            <Remind setReminds={updateAnnouncementBar("reminds")}/>
            <AnnouncementSet updateAnnouncementSet={updateAnnouncementBar("announcementSets")}/>
            {
                lock && <Button
                    onClick={() => setGlobal({
                        openSetting: true,
                        settingTab: 12
                    })}
                    className={classes.btnUnlock}>
                    <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                    {unlockIntl}</Button>
            }
        </div>
    );
}

export default RemindBar;
const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 15px'
    },
    box: {
        background: '#fff',
        borderRadius: 15,
        padding: 15,
    },
    btnUnlock: {
        margin: "auto",
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    iconButton: {
        marginRight: 10,
    }
}))
