import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import OperateTimeLabel from "./OperateTimeLabel";
import DialogAddDisplaySetting from "./DialogAddDisplaySetting";
import moment from "moment";
import PopoverDelete from "./PopoverDelete";
import SettingInfoBox from "./SettingInfoBox";
import DialogPickTimeRange from "./DialogPickTimeRange";
import DialogUpdateDisplaySetting from "./DialogUpdateDisplaySetting";
import axios from "axios"
import {CALENDAR_API_KEY, CALENDAR_COLLECTION} from "../../../../config/constants";
import TextSelectFrom from "../../TextSelectFrom";
import TextSelectTo from "../../TextSelectTo";
import useIntlId, {getIntlId} from "../../../../hooks/useIntlId";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddLargeSVG} from '../../../../asSvg/icons/addLarge.svg'
import FromTo from "../../../Custom/FromTo";
import {Loader} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import ThickLine from "../../Settings/ThickLine";
import SaveButton from "../../../Dialog/SaveButton";
import WeekStartDate from './WeekStartDate';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';
import {db} from '../../../../config/firebaseConfig';
import {msgSuccess} from '../../../../utils/msg';
import {useMyCalSettings} from '../../../../actions/user';
import {settingDialogProxy} from '../../../../store/proxy/settingDialog.proxy';
import {useGlobal} from 'reactn';

function DisplayTimeSetting(props) {
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const [displaySettings] = useMyCalSettings()
    const {user, calendarUser, setCalendarUser} = useSnapshot(userProxy)
    const {handleClose} = useSnapshot(settingDialogProxy)
    const [isOpen, setIsOpen] = useState(false)
    const [anchorDelete, setAnchorDelete] = useState(null)
    const [publicHoliDays, setPubLicHolidays] = useState([])
    const [displayTimeText, operationTimeText, addNewRangeText] = useIntlId(['setting.calendardisplaytime', 'setting.operatetime', 'setting.addnewrange']);
    const [times] = useGlobal('times');
    const [data, setData] = useState({
        startDay: moment().format(),
        endDay: null,
        hiddenDays: [],
        timeDisplay: {
            minTime: times ? times.minTime : {
                label: "00:00",
                value: 0
            },
            maxTime: times ? times.maxTime : {
                label: "24:00",
                value: 24
            }
        },
        dayOffs: [],
        excludeTime: {
            label: '00:00',
            value: '0'
        }
    })

    const [timeDisplayCustom, setTimeDisplayCustom] = useState(calendarUser?.times)
    const [checkTime, setCheckTime] = useState(false)
    useEffect(() => {
        if (timeDisplayCustom?.minTime?.value >= timeDisplayCustom?.maxTime?.value || timeDisplayCustom?.maxTime?.value < timeDisplayCustom?.minTime?.value) {
            setCheckTime(true)
        } else {
            setCheckTime(false)
        }
    }, [timeDisplayCustom])

    useEffect(() => {
        let mounted = true
        const timeMin = moment(data.startDay).startOf("day").utc().format()
        let timeMax = moment().endOf("year").utc().format()
        if (data.endDay) {
            timeMax = moment(data.endDay).endOf("day").utc().format()
        }
        const zone = moment().utcOffset()
        let country = "en.japanese"
        if (zone === 420) {
            country = "vi.vietnamese"
        }

        axios.get(`https://www.googleapis.com/calendar/v3/calendars/${country}%23holiday%40group.v.calendar.google.com/events?key=${CALENDAR_API_KEY}&timeMin=${timeMin}&timeMax=${timeMax}&maxResults=500`)
            .then(res => {
                if (mounted)
                    setPubLicHolidays(res.data.items.map(item => ({
                        start: item.start.date,
                        end: moment(item.end.date).subtract(1, "day").format("YYYY-MM-DD")
                    })))
            })
            .catch(e => {
                console.log(e);
            })
        return () => {
            mounted = false
        }
        // eslint-disable-next-line
    }, [data.startDay, data.endDay])

    const [openTime, setOpenTime] = useState(false)
    const [openDayOff, setOpenDayOff] = useState(false)
    const [eventSetting, setEventSetting] = useState(null)
    const [eventUpdate, setEventUpdate] = useState(null)

    const handleChange = async () => {
        if (!checkTime) {
            setLoading(true)
            try {
                if (!user.user_id) return;
                const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`);
                await userRef.set(
                    {times: timeDisplayCustom}, {merge: true}
                );
                setCalendarUser({...calendarUser, times: timeDisplayCustom})
                msgSuccess(getIntlId("toast.success.change"));
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false)
                handleClose()
            }
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.displayTimeText}>{displayTimeText}</div>
            <div className={classes.box}>
                <div className={classes.changeTime}>
                    <FromTo
                        className={classes.displayTime}
                        from={<TextSelectFrom classes={classes.from} changeTime={true}
                                              setTimeDisplayCustom={setTimeDisplayCustom}
                                              timeDisplayCustom={timeDisplayCustom}/>}
                        to={<TextSelectTo changeTime={true} setTimeDisplayCustom={setTimeDisplayCustom}
                                          timeDisplayCustom={timeDisplayCustom} className={classes.to}/>}
                    />
                    <SaveButton onClick={handleChange}
                                disable={checkTime}/>
                    <Loader loading={loading} text={'Saving'}/>

                </div>
                <WeekStartDate/>
                <ThickLine/>
            </div>


            <div className={classes.displayTimeText}>{operationTimeText}</div>
            <div className={classes.boxContentTime}>
                <OperateTimeLabel classes={classes}/>
                {
                    displaySettings && displaySettings.length !== 0 ? displaySettings.map((setting, index) => {
                            return (
                                <div className={classes.settingBox} key={index}>
                                    <SettingInfoBox
                                        classes={classes}
                                        setting={setting}
                                        setOpenTime={setOpenTime}
                                        setOpenDayOff={setOpenDayOff}
                                        setAnchorDelete={setAnchorDelete}
                                        setEventSetting={setEventSetting}
                                        setEventUpdate={setEventUpdate}
                                    />
                                </div>
                            )
                        })
                        :
                        <div className={classes.settingBox}>
                            {addNewRangeText}
                        </div>
                }
            </div>
            <div className={classes.divIconAddSVG}>
                <IconButton
                    disableRipple={true}
                    onClick={(e) => setIsOpen(true)}
                >
                    <AddLargeSVG className={classes.addIconSVGTimeSetting}/>

                </IconButton>
            </div>
            {
                isOpen &&
                <DialogAddDisplaySetting
                    classes={classes}
                    data={data}
                    setData={setData}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setOpenTime={setOpenTime}
                    setOpenDayOff={setOpenDayOff}
                    publicHoliDays={publicHoliDays}
                    setPubLicHolidays={setPubLicHolidays}
                />
            }

            {
                Boolean(eventUpdate) &&
                <DialogUpdateDisplaySetting
                    classes={classes}
                    data={data}
                    setData={setData}
                    eventUpdate={eventUpdate}
                    setEventUpdate={setEventUpdate}
                    setOpenTime={setOpenTime}
                    setOpenDayOff={setOpenDayOff}
                />
            }

            {
                openTime &&
                <DialogPickTimeRange
                    open={openTime}
                    setOpen={setOpenTime}
                    openDayOff={openDayOff}
                    setOpenDayOff={setOpenDayOff}
                    data={data}
                    setData={setData}
                    eventSetting={eventSetting}
                    setEventSetting={setEventSetting}
                    eventUpdate={eventUpdate}
                    setEventUpdate={setEventUpdate}
                    publicHoliDays={publicHoliDays}
                />
            }
            <PopoverDelete
                classes={classes}
                anchorDelete={anchorDelete}
                setAnchorDelete={setAnchorDelete}
            />
        </div>
    );
}

export default DisplayTimeSetting;

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 15px',
        fontSize: 12,
        fontWeight: 500,
        color: "#000",
    },
    displayTimeText: {
        textAlign: "flex-start",
        margin: '6px 0',
        textTransform: 'uppercase'
    },
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        // padding: "0px 15px",
        height: 39,
        background: "#fff",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        width: 121,
        fontWeight: "bold",
        paddingLeft: 32,
        color: "#000",
        pointerEvents: "all",
    },
    selectTime: {
        position: "relative",
    },
    iconSelect: {
        position: "absolute",
        right: 4,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        pointerEvents: "none"
    },
    displayTime: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: 5,
        "& .FromToContent": {
            padding: 0
        }
    },
    iconTo: {
        margin: "0 15px"
    },
    iconTo2: {
        margin: "0 4px"
    },
    iconTo2PolySVG: {
        fill: theme.props.primaryColor,
    },
    boxContentTime: {
        backgroundColor: "#fff",
        padding: 15,
        borderRadius: "10px",
    },
    box: {
        padding: '15px',
        width: '100%',
        alignItems: "flex-start",
        background: '#fff',
        borderRadius: '10px'
    },
    tableTime: {
        width: "100%",
        height: "22px",
        "& td": {
            textAlign: "center",
            width: "12.5%",
            fontWeight: "bold",
            borderRight: '1px solid #f1f1f1',
            '&:last-child': {
                borderRight: 'none',
            }

        }
    },
    tableHead: {
        backgroundColor: "#fff",
        borderRadius: "28px",
        marginBottom: 0,
        "& td": {
            textTransform: "uppercase",
            color: theme.props.primaryColor,
            padding: "9px 0",
        }
    },
    td: {
        borderRight: '1px solid #f1f1f1',
        '&:last-child': {
            borderRight: 'none',
        }
    },

    stageText: {
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        textAlign: "center"
    },
    rowTable: {
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "#F1F1F1",
        marginBottom: 6,
        paddingBottom: 10,
        borderBottom: '1px solid #f1f1f1'
    },
    timeSetting: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        "& span": {
            background: "#fff",
            padding: "5px 10px",
            borderRadius: "10px",
            fontWeight: "bold"
        }
    },
    divTimeCancelValue: {
        background: "#f1f1f1",
        minWidth: 61,
        height: 23,
        padding: "5px 4px 5px 8px",
        margin: "3px",
        borderRadius: "17px",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        justifyContent: "space-between",
        "& img": {
            marginLeft: 4,
        }
    },
    divTimeCancel: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        marginBottom: 6,
        borderBottom: '1px solid #f1f1f1',
        position: "relative",
        minHeight: 33,
    },
    listTimeCancel: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    addDayOff: {
        position: "absolute",
        right: 0,
        top: 0,
        padding: 5,
    },
    cancelLabel: {
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        padding: "0 5px 0 0px",
        minWidth: 160,
    },
    excludeDayTime: {
        background: "#f1f1f1",
        width: 61,
        height: 23,
        padding: 5,
        margin: "3px",
        borderRadius: "17px",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        justifyContent: "center",
    },
    excludeDayTimeLeft: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#F1F1F1",
        borderRadius: "10px",
        padding: "2px 4px 2px 0",
    },
    divExcludeDayTime: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    button: {
        padding: "2px 12px 2px 2px",
        minWidth: "unset",
        minHeight: "unset",
        height: 27,
        marginLeft: 4,
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
        backgroundColor: theme.props.primaryColor,
        border: "none",
        color: "#fff",
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.props.primaryColor,
            border: "none",
        },
        "&:focus": {
            color: "#fff",
            backgroundColor: theme.props.primaryColor,
            border: "none",
        },
        "& img": {
            marginRight: 8,
            marginBottom: 1
        },
        "& svg": {
            marginRight: 8,
        }
    },
    btnGroup: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    removePopup: {
        "& .MuiPaper-root": {
            background: "#FFFFFF",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
            borderRadius: "12px",
            padding: "4px 0 0px 0",
            textAlign: "center",
            minWidth: 170,
            fontSize: 12,
        }
    },
    popUpbutton: {
        margin: "6px 3px",
        textTransform: "uppercase",
        fontWeight: "bold",
        // height: 26,
        width: 74,
        textAlign: "center",
        color: "#fff",
        fontSize: 12,
        padding: 0,
        border: "none"
    },
    popUpCancel: {
        backgroundColor: "#970505",
        border: "none",
        "&:hover": {
            backgroundColor: "#970505",
            opacity: 0.9,
            color: "#fff",
            border: "none"
        }
    },
    popUpOk: {
        backgroundColor: "#1790FF",
        border: "none",
        textTransform: "uppercase",
        fontWeight: "bold",

        "&:hover": {
            backgroundColor: "#1790FF",
            opacity: 0.9,
            color: "#fff",
            border: "none"
        }
    },
    cancelUpdate: {
        backgroundColor: "#970505",
        border: "none",
        color: "#fff",
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: "bold",
        width: 100,
        textAlign: "left",
        padding: "0 1px",
        "& img": {
            marginRight: 5,
        },
        "&:hover": {
            backgroundColor: "#970505",
            opacity: 0.9,
            color: "#fff",
            border: "none"
        },
        "&:focus": {
            backgroundColor: "#970505",
            opacity: 0.9,
            color: "#fff",
            border: "none"
        }
    },
    saveUpdate: {
        backgroundColor: "#1790FF",
        border: "none",
        textAlign: "left",
        color: "#fff",
        fontSize: 12,
        padding: 0,
        textTransform: "uppercase",
        fontWeight: "bold",
        width: 100,
        "& img": {
            marginRight: 7,
        },
        "&:hover": {
            backgroundColor: "#1790FF",
            opacity: 0.9,
            color: "#fff",
            border: "none"
        },
        "&:focus": {
            backgroundColor: "#1790FF",
            opacity: 0.9,
            color: "#fff",
            border: "none"
        }
    },
    dialogAddSetting: {
        fontSize: 12,
        "& .MuiDialog-paperWidthSm": {
            minWidth: 840,
            padding: 15,
            background: '#f1f1f1',
        }
    },
    dialogTitleAdd: {
        textAlign: "center",
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        marginBottom: "18px",
        "& span": {
            // background: "#F1F1F1",
            borderRadius: "24px",
            padding: "9px 27px",
            display: "inline-block"
        }
    },
    addDialogLabel: {
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        marginRight: 15,
    },
    timeAdjustment: {
        backgroundColor: "#fff",
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 33,
        width: 232,
        justifyContent: "center",
        fontSize: 14,
        borderRadius: "24px",
    },
    divTimeAdjustment: {
        justifyContent: 'space-between',
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "3px 5px 5px 0px",

        borderBottom: '1px solid #f1f1f1'
    },
    divFlexBox: {
        background: '#fff',
        padding: 15,
        borderRadius: 15
    },
    divFlexCol: {
        display: "flex",
        alignItems: "center"
    },
    iconSvg: {
        fill: theme.props.primaryColor,
        marginLeft: -20,
        marginRight: 5,
        cursor: 'pointer',
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    },
    marginBottom20: {
        marginBottom: 20
    },
    addIconSVGTimeSetting: {
        fill: theme.props.primaryColor
    },
    marginTop10: {
        marginTop: 10
    },
    divIconAddSVG: {
        textAlign: "center"
    },
    changeTime: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5px",
        // margin: '0 10px',
        borderBottom: '1px solid #f1f1f1'
    },
    settingBox: {
        backgroundColor: "#fff",
        // padding: 10,
        marginBottom: 10,
        borderRadius: "10px"
    }
}))
