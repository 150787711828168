import React from 'react';
import {useGlobal} from 'reactn'
import {tutorialMenu} from '../../common/tutorialMenu';
import {isClickedPath, makeMultiPathOverlay} from '../../common/makeOverlayPath';
import {useTutorial} from '../../context/TutorialContext';

function TutorialTotal(props) {

    const [openTutorial, setOpenTutorial] = useGlobal('openTutorial')
    const {onPlay} = useTutorial()
    return (
        <div>
            {
                openTutorial ?
                    <svg style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        zIndex: 9000,
                        opacity: 0.5,
                        transition: 'all 0.5s',

                    }}
                         onClick={(e) => {
                             const {clientX, clientY} = e
                             const result = tutorialMenu.find(item => isClickedPath({
                                 pathId: item.id,
                                 x: clientX,
                                 y: clientY
                             }))
                             if (!result)
                                 return
                             console.log(result);
                             setOpenTutorial(false, () => {
                                 onPlay(result?.tutorialShow)
                             })

                         }}
                    >
                        <path
                            d={makeMultiPathOverlay(tutorialMenu.map(i => i.id))}
                            onClick={(event => {
                                setOpenTutorial(false)
                                // onPlay()
                            })}
                        />

                    </svg>
                    :
                    null
            }
        </div>
    )
}

export default TutorialTotal;
