import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {List, ListItem, ListItemText, Paper, Popover} from '@material-ui/core'
import {useGlobal} from 'reactn'
import moment from "moment";
import useIntlId from "../../hooks/useIntlId";
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {themesProxy} from '../../store/proxy/themes.proxy';

function SelectMonthPopover({open, setOpen}) {
    const classes = useStyles()
    const [date, setDate] = useGlobal("refetchDateNow")
    const {currentTheme} = useSnapshot(themesProxy)
    const [list, setList] = useState([])
    const {user} = useSnapshot(userProxy)
    const [formatYear, formatMonth, formatDay, formatWeek] = useIntlId(['appbar.formatAppbar.year', 'appbar.formatAppbar.month', 'appbar.formatAppbar.day', 'appbar.weeks.format'])
    useEffect(() => {
        if (!open)
            return;
        let list = []
        const type = getType(currentTheme?.viewCalendar)
        const today = new Date()
        const idxToday = today.getDay()
        let i
        for (i = -12; i <= 12; i++) {
            let time
            let firstDay

            if (currentTheme?.advanceView && currentTheme?.settingToday && currentTheme?.viewCalendar === "CustomDay") {
                let newTime = moment(date).add(i * currentTheme?.numsDay, type).format("YYYY-MM-DD")
                let newFirstDay = moment(date).add(i * currentTheme?.numsDay, type).format("YYYY-MM-DD")
                time = moment(newTime).add(1 - currentTheme?.indexToday, type).format("YYYY-MM-DD")
                firstDay = moment(newFirstDay).add(1 - currentTheme?.indexToday, type).format("YYYY-MM-DD")

                list.push({
                        label: formatItem(time, currentTheme?.viewCalendar),
                        value: newTime,
                        isToday: i === 0,
                        firstDay: firstDay,
                    }
                )
            } else {
                if (currentTheme?.viewCalendar === 'CustomYear') {
                    time = moment(date).add(i, type).format("YYYY-MM-DD")
                    firstDay = moment(date).add(i, type).format("YYYY-01-01")
                } else if (user?.startDate > 1 && currentTheme?.viewCalendar === 'CustomWeek') {
                    if (user?.startDate > idxToday) {
                        firstDay = moment(date).add(i, type).clone().weekday(user?.startDate - 8).format("YYYY-MM-DD")
                        time = moment(date).add(i, type).format("YYYY-MM-DD")
                    } else {
                        firstDay = moment(date).add(i, type).clone().weekday(user?.startDate - 1).format("YYYY-MM-DD")
                        time = moment(date).add(i, type).format("YYYY-MM-DD")
                    }
                } else {
                    time = moment(date).add(i, type).format("YYYY-MM-DD")
                    firstDay = moment(date).add(i, type).clone().weekday(user?.startDate - 1).format("YYYY-MM-DD")
                }
                list.push({
                        label: formatItem(time, currentTheme?.viewCalendar),
                        value: time,
                        isToday: i === 0,
                        firstDay: firstDay,
                    }
                )
            }
        }
        setList(list)
        // eslint-disable-next-line
    }, [open])
    useEffect(() => {
        if (!list.length)
            return
        const el = document.getElementById("time-today")
        if (el)
            el.scrollIntoView()
        // eslint-disable-next-line
    }, [list])
    const getType = (view) => {
        if (view === "CustomYear")
            return 'years'
        if (view === "CustomMonth")
            return 'months'
        if (view === "CustomWeek")
            return 'isoWeeks'
        return "days"
    }
    const formatItem = (time, view) => {
        if (view === "CustomYear")
            return moment(time).format(formatYear !== "appbar.formatAppbar.year" ? formatYear : "YYYY年")
        if (view === "CustomMonth")
            return moment(time).format(formatMonth !== "appbar.formatAppbar.month" ? formatMonth : "YYYY年MM月")
        if (view === "CustomWeek")
            return `${moment(time).format(formatWeek !== "appbar.weeks.format" ? formatWeek : "WW 週目")}`
        return moment(time).format(formatDay !== "appbar.formatAppbar.day" ? formatDay : "YYYY年MM月DD日")
    }

    return (
        <Popover
            open={Boolean(open)}
            onClose={() => setOpen(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            anchorEl={open}
        >
            <Paper className={classes.content}>
                <List>
                    {
                        list.map(item => {
                            return (
                                <ListItem key={item.value}
                                          button onClick={() => {
                                    setDate(item.value)
                                    setOpen(null)
                                }}
                                          className={classes.button}
                                          id={item.isToday ? "time-today" : ""}
                                >
                                    {currentTheme?.viewCalendar === 'CustomWeek' &&
                                        <ListItemText primary={item.firstDay}
                                                      className={item.isToday ? classes.todayText : item.text}/>}
                                    <ListItemText primary={item.label}
                                                  className={item.isToday ? classes.todayText : item.text}/>

                                </ListItem>
                            )
                        })
                    }
                </List>
            </Paper>
        </Popover>
    );
}

const useStyles = makeStyles(theme => ({
    content: {
        maxHeight: 300,
        width: 200,
        overflow: 'auto'
    },
    text: {
        "& span": {
            fontWeight: 700,
        }

    },
    todayText: {
        "& span": {
            fontWeight: 700,
            color: "#1790FF"
        }
    },
    button: {
        textAlign: "center"
    }
}))

export default SelectMonthPopover;
