import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {ggTokenChecked} from "../../config/auth";
import {db} from '../../config/firebaseConfig'
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import {CALENDAR_COLLECTION} from "../../config/constants";
import {refetchOne, refetchSource, removeEvent} from "../../actions/refetchSourcce";
import {RRule} from 'rrule'
import moment from "moment";
import useIntlId, {getIntlId} from "../../hooks/useIntlId";
import {useCalendarEvents} from "../../context/CalendarEventsContext";
import {findIndex} from "lodash";
import setGlobalGGEventsWithDataAndInfo from "./handlers/setGlobalGGEventsWithDataAndInfo";
import {deleteZoom} from "../../actions/zoom";
import {msgError, msgSuccess} from '../../utils/msg';
import {reloadGoogleEvents} from '../../context/common/getEventsSync';
import {useHistory} from 'react-router-dom';
import {deleteRecurringEvent} from '../../common/events/recurringEvent';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {searchProxy} from '../../store/proxy/search.proxy';

function DeleteRecurring({
                             eventDelete, setDeleteEvent, onClose = () => {
    }, numberId
                         }) {
    const [select, setSelect] = useState("this")
    const [loading, setLoading] = useState(false)
    const {user} = useSnapshot(userProxy)
    const {reloadEventsInSearchPage} = useSnapshot(searchProxy)
    const history = useHistory();
    const [googleStatus] = useGlobal("googleStatus")
    const [deleteRecurring, onlyEvents, followingEvents, allEvents, createOk, createCancel] = useIntlId([
        'create.deleteRecurring',
        'create.onlyEvents',
        'create.followingEvents',
        'create.allEvents',
        'create.ok',
        'create.cancel',
    ])
    const {deleteEvent} = useCalendarEvents()
    const handleClose = () => {
        setDeleteEvent(null)
        onClose(true)
    }
    const onOk = async () => {
        let value = select
        if (eventDelete?.originStart?.isStart && value === 'following') {
            value = 'all'
        }
        try {
            setLoading(true)
            switch (value) {
                case "this":
                    // delete one
                    await ggTokenChecked()
                    if (eventDelete.is_google || eventDelete.googleEvent) {
                        const request = window.gapi.client.calendar.events.delete({
                            "calendarId": eventDelete.project_uuid,
                            "eventId": eventDelete.id
                        })
                        await request.then()
                        // local remove
                        const {ggEvents} = getGlobal()
                        const events = ggEvents[eventDelete.project_uuid]?.events || []
                        let idx = findIndex(events, {id: eventDelete.id})
                        if (idx !== -1) {
                            events[idx] = {...events[idx], status: "cancelled"}
                            ggEvents[eventDelete.project_uuid].events = events
                            setGlobal({
                                ggEvents: {...ggEvents}
                            }, () => {
                                refetchSource('google')
                            })
                        } else {
                            events.push({
                                id: eventDelete.id,
                                recurringEventId: eventDelete.recurringEventId,
                                status: "cancelled"
                            })
                            ggEvents[eventDelete.project_uuid].events = events
                            setGlobal({
                                ggEvents: {...ggEvents}
                            }, () => {
                                refetchSource('google')
                            })
                        }
                    } else {
                        const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${eventDelete.id}`)
                        await eventRef.set({
                            project_uuid: eventDelete.project_uuid,
                            status: "canceled",
                            isTasks: false,
                            isDeleted: false,
                            recurringEventId: eventDelete.recurringEventId,
                            start: moment(eventDelete.start).local().format(),
                            end: moment(eventDelete.end).local().format(),
                        }, {merge: true})
                        // local remove
                        deleteEvent(eventDelete, () => {
                            refetchSource('geniam')
                        })
                    }

                    break
                case "all":
                    await ggTokenChecked()
                    if (eventDelete.zoomMeetingId) {
                        deleteZoom(eventDelete.zoomMeetingId).then();
                    }
                    if (eventDelete.is_google || eventDelete.googleEvent) {
                        const request = window.gapi.client.calendar.events.delete({
                            "calendarId": eventDelete.project_uuid,
                            "eventId": eventDelete.recurringEventId
                        })
                        await request.then()
                        deleteEvent(eventDelete, () => {
                            refetchSource('google')
                        }, (item => item.id === eventDelete.recurringEventId || eventDelete.recurringEventId === item.recurringEventId))
                    } else {
                        const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${eventDelete.recurringEventId}`)
                        const batch = db.batch()
                        batch.set(eventRef, {
                            isDeleted: true,
                            recurrence: null
                        }, {merge: true})
                        const editedSnap = await db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
                            .where("recurringEventId", "==", eventDelete.recurringEventId)
                            .get()
                        if (editedSnap.size) {
                            editedSnap.docs.forEach(doc => {
                                const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${doc.id}`)
                                batch.delete(ref)
                            })
                        }
                        await batch.commit()
                        deleteEvent(eventDelete, () => {
                            refetchSource('geniam')
                        }, (item => item.id === eventDelete.recurringEventId || eventDelete.recurringEventId === item.recurringEventId))
                    }
                    break;
                case "following":
                    const options = RRule.parseString(eventDelete.recurrence)
                    const nextUntil = moment(eventDelete.start).subtract(1, "day").endOf("day").utc().format()
                    options.until = new Date(nextUntil)

                    if (options.count) {
                        options.dtstart = new Date(eventDelete.originStart.start)
                        const countRule = new RRule(options)
                        options.count = countRule.count()
                        if (options.count) {
                            options.until = null
                        } else {
                            options.count = null
                        }
                        options.dtstart = null
                    }
                    const rrule = new RRule(options)
                    eventDelete.recurrence = rrule.toString()
                    if (eventDelete.is_google || eventDelete.googleEvent) {
                        await ggTokenChecked()
                        const request = window.gapi.client.calendar.events.patch({
                            "calendarId": eventDelete.project_uuid,
                            "eventId": eventDelete.recurringEventId,
                            "resource": {
                                recurrence: [eventDelete.recurrence]
                            },
                        });

                        const {result} = await request.then()
                        setGlobalGGEventsWithDataAndInfo(result, {
                            id: eventDelete.recurringEventId,
                            project_uuid: eventDelete.project_uuid
                        })
                    } else {
                        await deleteRecurringEvent(user, eventDelete, nextUntil);

                        deleteEvent(eventDelete, () => {
                            refetchSource('geniam')
                        }, (item => eventDelete.recurringEventId === item.recurringEventId && moment(item.start).isAfter(nextUntil)))
                    }
                    break
                default:
                    break
            }

            await db.collection(CALENDAR_COLLECTION).doc(user.user_id)
                .set({
                    updatedAt: moment().utc().format()
                }, {merge: true})
            removeEvent(eventDelete.id)
            refetchOne(numberId)

            await reloadEventsInSearchPage(history.location, googleStatus);

            msgSuccess(getIntlId('toast.error.calendar.deleted'))
        } catch (e) {
            if (e?.status === 410 && e?.result?.error?.message === 'Resource has been deleted') {
                reloadGoogleEvents(eventDelete.project_uuid)
                msgSuccess(getIntlId('calenview.notification.deleteResource'))
                return;
            }
            refetchSource('google')
            msgError("DELETE EVENT ERROR. TRY AGAIN LATE")
        } finally {
            setLoading(false)
            handleClose()
        }
    }
    return (
        <div>
            <Dialog
                open={Boolean(eventDelete)}
                onClose={handleClose}

            >
                <DialogTitle>{deleteRecurring}</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={select}
                            onChange={e => {
                                setSelect(e.target.value)
                            }}
                        >
                            <FormControlLabel value={"this"} control={<Radio/>} label={onlyEvents}/>
                            <FormControlLabel value={"following"} control={<Radio/>}
                                              label={followingEvents}/>
                            <FormControlLabel value={"all"} control={<Radio/>} label={allEvents}/>

                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={loading}>
                        {createCancel}
                    </Button>
                    <Button
                        onClick={onOk}
                        disabled={!select || loading}
                        color="primary" autoFocus
                    >
                        {createOk}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteRecurring;
