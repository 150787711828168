// =============================================================================
// i18n
// =============================================================================
import {fromPairs, zipObject} from 'lodash';
import moment from 'moment';

const shortDayNames = ['日', '月', '火', '水', '木', '金', '土']
const FREQmap = zipObject(
    ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY'],
    ['毎年', '毎月', '毎週', '毎日']
)
const BYDAYmap = zipObject(
    ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
    shortDayNames
)

const rruleJAJP = {
    dayNames: shortDayNames.map(y => `${y}曜日`),
    monthNames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(n => `${n}月`),
    tokens: {
        'SKIP': /^[ \r\n\t]+|^\.$/,
        'number': /^[1-9][0-9]*/,
        'numberAsText': '(一|二| 三)',
        'every': '毎',
        'day': '日',
        'weekday(s)': '平日',
        'hour(s)': '時',
        'minute(s)': '分',
        'week': '週',
        'month': '月',
        'year': '年',
        'on': '',
        // 'at': /''',
        'the': '',
        'first': '第1',
        '1st': '第1',
        'second': '第2',
        '2nd': '第2',
        'third': '第3',
        '3rd': '第3',
        // 'th': '第',
        'nth': '([1-9][0-9]*)(\.|第|第三|第二|第一)',
        'last': 'last',
        'for': 'for',
        'time': '回',
        'until': '(un)?til',
        'monday': 'mo(n(day)?)?',
        'tuesday': 'tu(e(s(day)?)?)?',
        'wednesday': 'we(d(n(esday)?)?)?',
        'thursday': 'th(u(r(sday)?)?)?',
        'friday': 'fr(i(day)?)?',
        'saturday': 'sa(t(urday)?)?',
        'sunday': 'su(n(day)?)?',
        'january': 'jan(uary)?',
        'february': 'feb(ruary)?',
        'march': 'mar(ch)?',
        'april': 'apr(il)?',
        'may': 'may',
        'june': 'june?',
        'july': 'july?',
        'august': 'aug(ust)?',
        'september': 'sep(t(ember)?)?',
        'october': 'oct(ober)?',
        'november': 'nov(ember)?',
        'december': 'dec(ember)?',
        'comma': '(,\s*|(and|or)\s*)+'
    },
}

export const parseRRuleToText = (rrule) => {
    if (!rrule) return '繰り返さない';
    const ruleObj = parseRRuleToObj(rrule)
    if (!ruleObj) return '';
    const {FREQ, INTERVAL, BYDAY, BYMONTH, BYMONTHDAY, UNTIL, COUNT} = ruleObj
    return parseFREQ(FREQ, INTERVAL) + parseBYDAY(BYDAY, FREQ) + parseBYMONTH(BYMONTH, BYMONTHDAY) + parseUNTIL(UNTIL) + parseCOUNT(COUNT);
}
export const parseRRuleToObj = (rule) => {
    if (!rule) return null;
    return fromPairs(
        rule.replace('RRULE:', '').split(';').map(r => r.split('='))
    )
}
const parseFREQ = (FREQ, INTERVAL) => {
    const str = FREQmap[FREQ]
    if (!INTERVAL) return str;
    const arr = str.split('')
    arr.splice(1, 0, INTERVAL)
    return arr.join('')
}
const parseBYDAY = (BYDAY, FREQ = 'MONTHLY') => {
    if (!BYDAY) return '';
    const days = /\D+/g.exec(BYDAY)?.[0] // ex: SU or MO,WE,TH
    const lanThu = /\d+/g.exec(BYDAY)?.[0] // ex: 3

    let dayStr = days.split(',').map(d => BYDAYmap[d]).join(',')//ex: T2,T3
    if (lanThu) {
        switch (FREQ) {
            case 'MONTHLY':
                dayStr += ',第' + lanThu + '週'
                break;
            case 'YEARLY':
                dayStr += ',第' + lanThu + '月'
                break;
        }
    }

    return ' ' + dayStr;
}
const parseBYMONTH = (BYMONTH, BYMONTHDAY) => {
    if (!BYMONTH) return ''
    return `${BYMONTH}月${BYMONTHDAY}日`//ex: 20/10
}
const parseUNTIL = UNTIL => {
    if (!UNTIL) return ''
    return moment(UNTIL).format('YYYY/MM/DD') + 'まで'
}
const parseCOUNT = COUNT => {
    if (!COUNT) return ''
    return ', ' + COUNT + '回'
}

export default rruleJAJP
