import React from 'react';
import {setGlobal, useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/core/styles';
import AnnouncementCheck from '../../Custom/AnnouncementCheck';
import {Button} from 'antd';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import useIntlId from '../../../hooks/useIntlId';
import AnnouncementCheckbox from '../../Custom/AnnouncementCheckbox';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function AnnouncementBar() {
    const classes = useStyles()
    const {user} = useSnapshot(userProxy)
    const {lock, bar} = useSnapshot(announcementProxy)
    const [
        announcementBarDisplayIntl,
        RotationIntl,
        newsIntl,
        unlockIntl,
        defaultRotation,
    ] = useIntlId([
        'settings.announcement.display',
        'settings.announcement.rotation',
        'settings.announcement.new',
        'settings.announcement.unlock',
        'settings.announcement.defaultRotation',
    ])
    const updateAnnouncementBar = fieldName => async (value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={classes.root}>
            <AnnouncementCheck lock={lock} check={bar?.announcementBar === undefined ? true : bar?.announcementBar}
                               setCheck={updateAnnouncementBar("announcementBar")}
                               name={announcementBarDisplayIntl}/>
            <AnnouncementCheck lock={lock} name={RotationIntl} value={bar?.rotationInterval || 1} label={"s"}
                               setValue={updateAnnouncementBar("rotationInterval")}/>
            <AnnouncementCheck lock={lock} check={bar?.geniamNews}
                               setCheck={updateAnnouncementBar("geniamNews")} name={newsIntl}/>
            <AnnouncementCheckbox lock={lock} check={bar?.defaultRotation}
                                  setCheck={updateAnnouncementBar('defaultRotation')} label={defaultRotation}/>
            {
                lock && <Button
                    onClick={() => setGlobal({
                        openSetting: true,
                        settingTab: 12
                    })}
                    className={classes.btnUnlock}>
                    <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                    {unlockIntl}</Button>
            }
            {/*<PaymentList open={openPurcharse} setOpen={setOpenPurcharse}/>*/}

        </div>
    );
}

export default AnnouncementBar;
const useStyles = makeStyles(theme => ({
    root: {
        background: '#fff',
        borderRadius: 15,
        padding: 20,
        margin: '0 15px'
    },
    btnUnlock: {
        margin: "auto",
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    iconButton: {
        marginRight: 10,
    }
}))
