import {addCallback, getGlobal, setGlobal} from 'reactn'
import moment from "moment";
import store from "store";
import qs from "query-string";
import {isEqual, isUndefined, omitBy} from 'lodash';
import {isMobileOnly} from 'react-device-detect';
import {snapshot} from 'valtio';
import {calenviewTimeRangeProxy} from '../store/proxy/calenviewTimeRange.proxy';

const calendarTab2 = store.get('calendarTab2')
const calendarTab1 = store.get('calendarTab1')
const fontSize = store.get('fontSize') || 9
const focus = store.get('focus') || {identity: true, project: true}
// const view = isMobileOnly ? 'CustomWeek' : store.get('view')
const segment = store.get('segment')
const projectSortSetting = store.get('projectSortSetting') || {isSort: false, timeView: 6}
const fullHeight = store.get('fullHeight') || ''
const showTip = store.get('showTip') || 'show'
const query = qs.parse(window.location.search)
const tabShareUrl = query?.tabShareUrl || store.get('tabShareUrl')
const gerenateFocus = (num) => {
    let result = {}
    for (let i = 0; i < num; i++) {
        result['focus-' + num] = true
    }
    return result
}
const timeStore = store.get('times')
const themeCustom = store.get('themeCustom')
const localUser = store.get('userInfo')

setGlobal({
    user: localUser,
    loading: false,
    calendarTab2: calendarTab2 ? calendarTab2 : 2,
    calendarTab1: calendarTab1 ? calendarTab1 : 2,
    // refetchDateNow: refetchDateNow ? refetchDateNow : Date.now(),
    refetchDateNow: moment().format('YYYY-MM-DD'),
    groups: [],
    groupsMs: [],  //  groups milestone
    view: 'CustomMonth',
    openNewEvent: null,
    EventAdding: {top: 0, left: 0},
    openEditingEvent: {
        open: false,
        anchorEl: null
    },
    // accountPopupAnchorEl: null,
    openDeleteEvent: false,
    EventEditting: {},
    focusText: [],
    listSettingShareUrl: [],
    tabShareUrl: tabShareUrl ? tabShareUrl : "live",
    tasks: {
        data: [],
        default: 0
    },
    tab: 1,
    segment: segment ? segment : 'one',
    googleStatus: {
        is_login: Boolean(store.get("googleAuth")),
        userProfile: store.get("googleAuth") || {},
        initialed: Boolean(store.get("googleAuth"))
    },
    googleCalendarList: store.get("GGList") || [],
    googleLibInit: false,
    openEventDetails: false,
    EventTask: [],
    times: timeStore || {
        minTime: {
            value: 7,
            label: '07:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },

    //Task
    timer: null,
    _events: [],
    newUser: false,
    identities: [],
    googleIdentities: [],
    priorities: [],
    projects: [],
    categories: [],

    // Popup Task
    newEvent: {},
    editEvent: {},
    popup: {
        newEventPopup: false,
        editEventPopup: false,
        top: 0,
        left: 0,
    },

    calendarID: {
        id: 0,
        name: ''
    },
    anchor: {
        top: 0,
        left: 0
    },
    initDialog: false,
    process: '',
    openIconList: false,
    addIdentityDialog: false,
    openAddProject: false,
    GoogleColor: [
        "#7986CB",
        "#33B679",
        "#8E24AA",
        "#E67C73",
        "#F6BF26",
        "#F4511E",
        "#039BE5",
        "#616161",
        "#3F51B5",
        "#0B8043",
        "#D50000"
    ],
    syncToken: [],
    syncEvents: [],
    // GChannel: localStorage.getItem('GChannel') ? JSON.parse(localStorage.getItem('GChannel')) : [],
    // LChannel: localStorage.getItem('LChannel') ? JSON.parse(localStorage.getItem('LChannel')) : {},
    RightClickEl: null,
    RightEditEvent: {},
    recurringOpen: false,
    recurringEvent: {},
    changeOption: '',
    deleted: [],
    restoring: false,
    restored: null,
    hoverEl: null,
    hoverEvent: {
        title: '',
        start: '',
        end: '',
        description: '',
    },
    syncProcess: '',
    insync: false,
    sync_uuid: '',
    emojis: [
        {
            name: 'AssignmentTurnedIn',
            emoji: "✅"
        },
        {
            name: 'Group',
            emoji: "👥"
        },
        {
            name: 'AccountCircle',
            emoji: "👤"
        },
        {
            name: 'AssignmentInd',
            emoji: "🔄"
        },
    ],
    openSelectCalendar: false,
    // calendarSelected: localStorage.getItem('calendarSelected') ? JSON.parse(localStorage.getItem('calendarSelected')) : [],
    allProject: [],
    allMilestoneProject: [],
    // height: '305px',
    // heightMap: {},
    // ReloadNeeded: false,
    thickLine: localStorage.getItem('thickLine') ? JSON.parse(localStorage.getItem('thickLine')) : [12, 18, null, null],
    treeData: [],
    openTime: new Date(),
    // focusEvent: {},
    packages: {},
    openPackageDialog: false,
    meetingTimes: {
        minTime: {
            value: 6,
            label: '06:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },
    arrayDataCheckedGlobal: [],
    showTitle: localUser?.themeCustom?.showTitle,
    showMilestone: localUser?.themeCustom?.showMilestone,
    calendarLeftView: localUser?.themeCustom?.calendarLeftView || null,
    selectedDay: undefined,
    startDate: 1,
    fontSize: fontSize,
    hiddenDays: [],
    apiCalendar: null,
    meetings: [],
    voteInfo: null,
    votesSelect: null,
    editVoteInfo: null,
    voteEvents: [],
    timeWarning: null,
    language: store.get("language") || "ja-jp",
    switchEmail: null,
    eventJoining: false,
    eventTransfer: false,
    editFreeSlot: null,
    notifications: [],
    myFreeTime: null,
    dateSelections: [],
    tabMeeting: 0,
    hoverVote: null,
    openSetting: false,
    hoverPos: null,
    voteBox: null,
    hoverEventStart: null,
    workTimeDisplay: [],
    geEvents: {},
    ggEvents: {},
    geListAttendees: [],
    ggListAttendees: [],
    ggEventsAllTime: store.get("ggEventsAllTime") || {},
    settingTab: 0,
    calendarUser: themeCustom ? {themeCustom: themeCustom} : {},
    themeCustom: themeCustom,
    clickInfo: null,
    nextSyncTokens: {},
    projectSortSetting: projectSortSetting,
    zoomOAuth: {},
    zoomMeetings: [],
    accessToken: null,
    firebaseToken: null,
    refreshToken: null,
    prevAction: [],
    eventContextMenu: null,
    listSearch: [],
    searchKeyHistory: store.get('searchKeyHistory') || [],
    searchKey: "",
    eventSearchKey: "",
    openFocusColor: [],
    colors: store.get('colors') || {primary: '#1790FF', secondary: '#690003'},
    focus: focus,
    focusView: gerenateFocus(store.get('numOfLine') || 2),
    fullHeight: fullHeight ? fullHeight : '',
    addProject: false,
    tour: null,
    showTip: showTip,
    openAddTask: false,
    editDialog: false,
    meetingPrimary: true,
    dateBusy: [],
    copyCreateEvent: null,
    isDragging: false,
    timeSearch: {
        start: moment(moment().subtract(30, 'days').format("YYYY/MM/DD")),
        end: moment().format("YYYY/MM/DD")
    },
    checkTimeSearch: true,
    sortColumn: true,
    visible: false,
    openSearch: false,
    ggEventLoaded: false,
    listTheme: [],
    editInfo: null,
    idCalenderHidden: [],
    leftExpansion: isMobileOnly ? false : store.get("leftExpansion") === undefined ? true : Boolean(store.get("leftExpansion")),
    rightExpansion: isMobileOnly ? false : store.get("rightExpansion") === undefined ? true : Boolean(store.get("rightExpansion")),
    openTutorial: false,
    globalApps: [],
    showFunctionApp: true,
    showDialogSelectCalendar: false,
    colHighLight: 1,
    projectSelected: null,
    numberCal: 3,
    dragAppList: [],
    userGeniamApps: [],
    favoriteGlobalLinks: [],
    favoriteUserLinks: [],
    userLinks: [],
    numCheckHighLight: 0,
    focusFromPreview: null,
    openDelete: null,
    openEdit: null,
    loadingView: true
});

let prevUser = {...localUser}
addCallback((global, dispatcherMap, stateChange) => {
    const newUserData = global.user;
    if (!isEqual(newUserData, prevUser)) {
        // console.log(diff(newUserData, prevUser))
        store.set('userInfo', newUserData)
        if (newUserData?.language) {
            setMomentLocale(newUserData.language)
        }
        prevUser = {...newUserData}
    }
    if (stateChange?.refetchDateNow) {
        const {refreshTime} = snapshot(calenviewTimeRangeProxy)
        refreshTime()
    }
});

// globalCallback()

// const diff = (o1, o2) => omit(o1, function (v, k) {
//     return o2[k] === v;
// })

export const mergeGlobalUser = (data) => {
    const gUser = getGlobal()?.user
    const omitData = omitBy(data, isUndefined)
    return setGlobal({
        user: {
            ...gUser,
            ...omitData
        }
    })
}

const setMomentLocale = (language) => {
    let locale = language.split('-')?.[0]
    if (locale === 'en')
        locale = 'en-gb'
    import(`moment/locale/${locale}`)
        .then(d => {
            moment.locale(locale)
        })
}
