export const tutorials = [
    {
        id: 'tutorial_main_1',
        title: 'Geniamカレンダーの操作方法を知る',
        subs: []
    },
    {
        id: 'tutorial_main_2',
        title: 'TOPメニューの機能を知る',
        subs: [
            {
                id: 'tutorial_main_2_1',
                title: '他のGeniamアプリへアクセスするには？'
            },
            {
                id: 'tutorial_main_2_2',
                title: 'カレンダーの表示期間を変更するには？'
            },
            {
                id: 'tutorial_main_2_3',
                title: 'カレンダーの月・週・日表示を切り替えるには？'
            },
            {
                id: 'tutorial_main_2_4',
                title: '今日の予定を表示するには？'
            },
            {
                id: 'tutorial_main_2_5',
                title: '期間の行・列の表示方法やテーマカラー、文字サイズを変更するには？'
            },
            {
                id: 'tutorial_main_2_6',
                title: 'ビュー機能の操作方法とは？'
            },
            {
                id: 'tutorial_main_2_7',
                title: '保存したビューを呼び出すには？'
            }
        ]
    },
    {
        id: 'tutorial_main_3',
        title: 'オプションメニューの機能を知る',
        subs: [
            {
                id: 'tutorial_main_3_1',
                title: '過去や未来の予定を検索するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_2',
                title: 'アプリの操作方法を確認するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_3',
                title: '予定調整の結果の通知を確認するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_4',
                title: 'カレンダーの全般的な設定をするには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_5',
                title: 'Googleカレンダーと同期するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_6',
                title: 'ZOOMと同期するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_7',
                title: '表示する時間帯を設定するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_8',
                title: 'カレンダーの強調する時間を設定するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_9',
                title: 'カレンダーの稼働日・稼働時間を設定するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_10',
                title: '予定（イベント）のタグを管理するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_11',
                title: 'カレンダーのプロジェクトの名称やテーマカラーを変更するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_3_12',
                title: 'アイデンティティの作成・編集・削除をするためには？',
                ignoreIndex: true
            }
        ]
    },
    {
        id: 'tutorial_main_4',
        title: '左メニューの機能を知る',
        subs: [
            {
                id: 'tutorial_main_4_1',
                title: '今日の日付を確認するには？'
            },
            {
                id: 'tutorial_main_4_2',
                title: '左メニューを隠す・表示するには？'
            },
            {
                id: 'tutorial_main_4_3',
                title: 'カレンダープロジェクトを追加するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_4_4',
                title: 'Geniamカレンダープロジェクトを表示・非表示するには？'
            },
            {
                id: 'tutorial_main_4_5',
                title: 'Geniamカレンダープロジェクトの名前・色等を変更するには？'
            },
            {
                id: 'tutorial_main_4_6',
                title: 'Geniamカレンダープロジェクトの一覧を表示・非表示するには？'
            },
            {
                id: 'tutorial_main_4_7',
                title: 'Googleカレンダープロジェクトを表示・非表示するには？'
            },
            {
                id: 'tutorial_main_4_8',
                title: 'Googleカレンダープロジェクトの同期を設定するには？'
            },
            {
                id: 'tutorial_main_4_9',
                title: 'Googleカレンダープロジェクトの一覧を表示・非表示するには？'
            }
        ]
    },
    {
        id: 'tutorial_main_5',
        title: 'メイン画面の機能を知る',
        subs: [
            {
                id: 'tutorial_main_5_1',
                title: '基本的な予定を作成するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_5_2',
                title: '基本的な予定を編集するには？',
                // ignoreIndex: true,
                index: 1
            },
            {
                id: 'tutorial_main_5_3',
                title: '予定を削除するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_5_4',
                title: '繰り返し予定を作成するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_5_5',
                title: '繰り返し予定を編集するには？',
                ignoreIndex: true
            },
            {
                id: 'tutorial_main_5_6',
                title: '繰り返し予定を削除するには？',
                ignoreIndex: true
            }
        ]
    },
    // {
    //     id: 'tutorial_main_6',
    //     title: '右メニュー（集計・分析）の機能を知る',
    //     subs: [
    //         {
    //             id: 'tutorial_main_6_1',
    //             title: '右メニューを表示・非表示するには？'
    //         },
    //         {
    //             id: 'tutorial_main_6_2',
    //             title: '分析・集計機能でできることは？'
    //         }
    //     ]
    // }
]

