import React, {memo} from 'react';
import {Paper, Popper, Typography} from '@material-ui/core';
import {setGlobal, useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/core/styles';
import {copyTimeFmt} from './actions/copyEvent';

function TitleShow() {
    const classes = useStyles()
    const [hoverEl] = useGlobal('hoverEl')
    const [event] = useGlobal('hoverEvent')

    const handleHoverOff = () => {
        setGlobal({
            hoverEvent: {
                title: '',
                start: '',
                end: '',
                description: '',
                isAccepted: false
            },
            hoverEl: null
        })
    }

    return (
        <div onMouseLeave={handleHoverOff}>
            {
                !!(hoverEl && event) &&
                    <Popper open={true}
                            anchorEl={hoverEl}
                            placement={"right-start"}
                            className={classes.popup}
                            keepMounted={false}>
                        <div className={classes.flex}>
                            <Paper className={classes.content}>
                                <div>
                                    <Typography className={classes.bold}>{event.title}</Typography>
                                    <div>
                                        <div className={classes.pre}>{copyTimeFmt({event,hover:true})}</div>
                                        {event.description && <div dangerouslySetInnerHTML={{__html: event?.description}}
                                                                   className={classes.text}/>}
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </Popper>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    popup: {
        zIndex: 1000,
        display: "block"
    },
    flex: {display: "flex"},
    bold: {fontWeight: 'bold'},
    pre: {
        whiteSpace: 'pre-wrap',
        '&:first-line': {
            lineHeight: 0
        }
    },
    text: {
        maxWidth: 250,
        fontWeight: 'lighter',
        overflowWrap: 'break-word'
    },
    content: {
        padding: 5,
        display: "flex",
        justifyItems: "center",
        alignItems: "center"
    },
    iconButton2: {
        marginLeft: '5px',
        "& img": {
            width: 18,
        }
    }
}))

export default memo(TitleShow)
