import React from 'react';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'antd';

import styled from "styled-components";

const Step = styled.div(props => ({
    width: 46,
    height: 14,
    backgroundImage: `url(${props.url})`,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
    color: props.color || "#000",
}))


function DialogTutorial({title, content, img, numberSteps = 3, onNext, onPrev, onClose, currentIndex, note = []}) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <IconButton
                onClick={onClose}
                className={classes.closeIcon}>
                <img src="/icons/closeGray.svg" alt=""/>
            </IconButton>
            <div className={classes.container}>
                {
                    title && <div className={classes.title}>{title}</div>
                }
                {
                    content && <div className={classes.content} dangerouslySetInnerHTML={{__html: content}}/>
                }
                {
                    img && <img className={classes.img} src={img} alt=""/>
                }
                {
                    note.length > 0 && note.map((item, index) => <div className={classes.note} key={index}>
                        <img className={classes.noteImg} src={item.img} alt=""/>
                        <div className={classes.noteText}>{item?.text}</div>
                    </div>)

                }
            </div>

            <div className={classes.bottom}>
                <Button onClick={onPrev} className={classes.back}>back</Button>
                <div className={classes.steps}>
                    {
                        Array(numberSteps).fill(1).map((v, idx) => {
                            let color = '#C4C4C4'
                            let url = './healperTips/step.svg'
                            if (idx === 0) {
                                url = '/healperTips/startStep.svg'
                                color = '#fff'
                            }
                            if (idx <= currentIndex && idx !== 0) {
                                url = './healperTips/stepsActive.svg'
                                color = '#fff'
                            }
                            if (idx === numberSteps - 1 && idx !== currentIndex && idx !== 0) {
                                url = './healperTips/endStep.svg'
                                color = '#C4C4C4'
                            }
                            if (idx === numberSteps - 1 && idx === currentIndex) {
                                url = './healperTips/endActiveStep.svg'
                                color = '#fff'
                            }
                            return (
                                <Step url={url} color={color} key={Math.random()}>{idx + 1}</Step>)

                        })
                    }
                </div>
                <Button className={classes.next} onClick={onNext} type="primary">{
                    currentIndex < numberSteps - 1 ? 'next' : 'finish'
                }</Button>

            </div>
        </div>

    );
}

export default DialogTutorial;
const useStyles = makeStyles(theme => ({
    root: {
        background: "#fff",
        position: "relative",
        maxWidth: 800,
        minWidth: 600,
        borderRadius: '4px',
        overflow: 'hidden'
    },
    container: {
        padding: "15px",
        maxHeight: "80vh",
        overflow: "auto"
    },
    title: {
        fontWeight: 700,
        fontSize: 18,
        textTransform: 'uppercase',
        color: '#1790FF',
        maxWidth: 'calc(100% - 30px)'
    },
    closeIcon: {
        position: "absolute",
        top: 0,
        right: 0
    },
    content: {
        marginBottom: 10,
    },
    img: {
        maxWidth: 500,
        margin: "0 auto",
        display: "block"
    },
    bottom: {
        display: "flex",
        backgroundColor: "#e1e1e1",
        borderRadius: "9px 9px 0 0",
        padding: 10,
        alignItems: "center",
        justifyContent: "space-between"
    },
    steps: {
        padding: "0 15px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "unset",
    },
    back: {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "bold",
        color: "#1790FF",
        backgroundColor: "#fff",
        border: "none",
        borderRadius: "7px",
        padding: "1px 15px",
        "&:hover, &:focus": {
            color: '#1790FF',
            backgroundColor: "#fff",
            border: "none",
        },
    },
    next: {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "bold",
        backgroundColor: '#1790FF',
        color: "#fff",
        border: "none",
        borderRadius: "7px",
        padding: "1px 15px",
        "&:hover, &:focus": {
            backgroundColor: '#1790FF',
            color: "#fff",
            border: "none",
        },
    },
    note: {
        display: "flex",
        padding: "0 30px 10px 30px",
        alignItems: "center"
    },
    noteImg: {
        maxWidth: 100,
        objectFit: "contain"
    },
    noteText: {
        paddingLeft: 20
    }
}))

