import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import moment from 'moment';
import {getGeniamEventsFromReccrence, getGoogleEvents} from './common/getGoogleEvents';
import {concat, findIndex, groupBy, isEmpty, isFunction, remove} from 'lodash';
import {getGeniamEvents} from './common/getGeniamEvents';
import {getGeniamProjects} from './common/getProjects';
import {swapProjectByOrder} from '../components/GroupCalendar/common';
import {refetchSource} from '../actions/refetchSourcce';
import {scheduleAdjustmentRef} from '../common/firebaseRef/meetings';
import {db} from '../config/firebaseConfig';
import {CALENDAR_COLLECTION, GOOGLE_REFRESH_TOKEN} from '../config/constants';
import axios from 'axios';
import {getVoteEvents} from '../components/Calendar/actions/syncEventMeeting';
import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';
import {themesProxy} from '../store/proxy/themes.proxy';
// import {getGoogleEventsSync} from "./common/getGoogleEventSync";
// import {eventDiff} from "./common/eventDiff";

export const CalendarEventsContext = createContext({})

export const useCalendarEvents = () => {
    return useContext(CalendarEventsContext)
}

export const deleteEventGoogle = async (projectId, userId, eventId) => {
    try {
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
        const docSnap = await userRef.get()
        if (!docSnap.exists)
            return null

        const data = docSnap.data()
        const {googleAuth} = data
        if (!googleAuth)
            return null
        const {refreshToken} = googleAuth
        if (!refreshToken)
            return null
        let res = await axios.get(`${GOOGLE_REFRESH_TOKEN}?refreshToken=${refreshToken}`)
        const {access_token} = res.data
        res = await axios.delete(`https://www.googleapis.com/calendar/v3/calendars/${projectId}/events/${eventId}`, {
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
        return res.data
    } catch (e) {
        console.log(e);
        return null
    }

}
export const CalendarEventsProvider = ({children}) => {
    const value = useEvents()
    return (
        <CalendarEventsContext.Provider value={value}>
            {children}
        </CalendarEventsContext.Provider>
    )
}

let refetchTimeout = null

/**
 *
 * @returns {{changeTime: changeTime, googleIdentities: *[], createEvent: createEvent, revertEvent: revertEvent, refresh: refresh, patchGoogleEvent: patchGoogleEvent, deleteEvent: deleteEvent, geEvents: any, updatedEvent: updatedEvent, ggEvents: any, reLoadEvents: ((function(): Promise<void>)|*)}}
 */
const useEvents = () => {
    const [refetchDateNow] = useGlobal("refetchDateNow")
    const [googleLibInit] = useGlobal("googleLibInit")
    const {user} = useSnapshot(userProxy)
    const {currentTheme} = useSnapshot(themesProxy)
    const [projects, setProjects] = useGlobal("projects")
    const [googleCalendarList] = useGlobal("googleCalendarList")
    const [geEvents, setGeEvents] = useGlobal("geEvents")
    const [ggEvents, setGGEvents] = useGlobal("ggEvents")
    const [, setNextSyncToken] = useGlobal("nextSyncTokens")
    const [googleList, setList] = useState([])
    const [googleIdentities] = useGlobal('googleIdentities')
    const googleTimeRef = useRef({})

    useEffect(() => {
        if (isListChange(googleList, googleCalendarList)) {
            setList([...googleCalendarList])
        }
        // eslint-disable-next-line
    }, [googleCalendarList])

    useEffect(() => {
        if (refetchTimeout) {
            clearTimeout(refetchTimeout)
        }
        if (!googleList.length || !googleLibInit) {
            setGGEvents({})
            googleTimeRef.current = {}
            return;
        }
        refetchTimeout = setTimeout(() => {
            reLoadEvents()
        }, 300)
        // reLoadEvents()
        // eslint-disable-next-line
    }, [refetchDateNow, googleList, googleLibInit, currentTheme])

    const reLoadEvents = async () => {
        const {start, end} = getTimeMinMax(refetchDateNow, currentTheme)
        if (googleTimeRef.current?.start && googleTimeRef.current?.end) {
            if (moment(start).isSameOrAfter(googleTimeRef.current.start) && moment(end).isSameOrBefore(googleTimeRef.current.end)) {
                return null
            }
        }
        googleTimeRef.current = {
            start, end
        }
        try {
            const {events, nextSyncToken} = await getGoogleEvents(start, end)
            if (isEmpty(events))
                return
            setNextSyncToken(nextSyncToken)
            setGGEvents(events, () => {
                refetchSource("google")
                setGlobal({
                    ggEventLoaded: true
                })
            })
        } catch (e) {
            console.log(e);
            // toast.error("load event google error")
            setGlobal({
                ggEventLoaded: true
            })
        }
    }

    const getIdentityColor = (colorId) => {
        if (!colorId || !googleIdentities?.length)
            return null

        let index = findIndex(googleIdentities, {id: colorId})
        if (index !== -1) {
            return googleIdentities[index].color
        }

        return null
    }

    const getProjectColor = (projectId) => {
        if (!projectId || !projects?.length)
            return null

        let index = findIndex(projects, {uuid: projectId})
        if (index !== -1) {
            return projects[index].color
        }

        return null
    }

    useEffect(() => {
        // if (!projects.length)
        //     return
        if (!user?.user_id)
            return;
        const sub = getGeniamEvents((snapshot) => {
            const {end} = getTimeMinMax(refetchDateNow, currentTheme)
            let data = snapshot.docs.map(doc => {
                const identityColor = doc.data().colorId
                const projectColor = doc.data().project_uuid
                if (doc.data()?.colorId && doc.data()?.isEventVote)
                    return {
                        ...doc.data(), id: doc.id,
                        backgroundColor: getIdentityColor(identityColor) || getProjectColor(projectColor),
                        borderColor: getProjectColor(projectColor)
                    }
                if (!doc.data()?.colorId && doc.data()?.isEventVote)
                    return {
                        ...doc.data(), id: doc.id,
                        borderColor: getProjectColor(projectColor),
                        backgroundColor: getProjectColor(projectColor)
                    }

                if (!doc.data()?.backgroundColor) {
                    return {
                        ...doc.data(), id: doc.id,
                        borderColor: getProjectColor(projectColor),
                        backgroundColor: getProjectColor(projectColor)
                    }
                }
                return {
                    ...doc.data(), id: doc.id,
                    borderColor: getProjectColor(projectColor),
                }
            })


            data = groupBy(data, "project_uuid")
            let result = {}
            Object.keys(data).forEach(key => {
                let value = data[key]
                // let idx = findIndex(projects, {id: key})
                // if (idx !== -1)
                const recurrence = remove(value, item => item.recurrence)
                const recurrenceEdited = remove(value, e => e.recurringEventId)
                const recurring = getGeniamEventsFromReccrence(recurrence, recurrenceEdited, end)
                result[key] = {
                    events: concat(value, recurring),
                    id: key,
                    // name: projects[idx].name
                }
            })
            // console.log({result});
            setGeEvents(result, () => {
                setTimeout(() => {
                    refetchSource("geniam")
                }, 500)
            })
        })

        const sub2 = scheduleAdjustmentRef(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                let arr = []
                data.forEach(item => {
                    if (item?.nameSetting && item?.nameSetting?.replace(/\s+/g, '') !== "" && !item?.isDelete) {
                        arr.push(item)
                    }
                })
                setGlobal({listSettingShareUrl: arr})

            })

        const votesub = getVoteEvents((querySnapshot) => {
            let events = []
            querySnapshot.forEach(function (doc) {
                if (!doc.data().isSync) {
                    let evt = {
                        ...doc.data(),
                        id: doc.id,
                        // holiday: true,
                        busy: true,
                        isEventVote: doc.data().isEventVote || false,
                        isAccepted: doc.data().isAccepted || false,
                        editable: !(doc.data().meetingId && !doc.data().isAccepted),
                        borderColor: getProjectColor(doc.data().project_uuid),
                        backgroundColor: getIdentityColor(doc.data().colorId)
                    }
                    // if (!evt.isAccepted) {
                    //     evt.backgroundColor = "rgb(87, 170, 221)"
                    // }
                    events.push(evt);
                }
            });
            setGlobal({
                voteEvents: events
            }, () => {
                refetchSource('vote')
            })
        })
        return () => {
            if (sub)
                sub()
            if (sub2)
                sub2()
            if (isFunction(votesub))
                votesub()
        }
        // eslint-disable-next-line
    }, [user?.user_id, projects, googleIdentities, currentTheme])

    useEffect(() => {
        if (!user?.user_id)
            return
        const sub = getGeniamProjects(user.user_id, (err, snap) => {
            if (err)
                return
            let data = snap.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
                isMilestoneSelected: doc.data().isMilestoneSelected !== undefined ? doc.data().isMilestoneSelected : true
            }));
            const {calendarUser} = getGlobal();
            const {googleList = []} = calendarUser;
            if (googleList.length) {
                data = swapProjectByOrder(data, googleList)
            }
            setProjects(data, () => {
                refetchSource('geniam')
            })
        });
        return () => {
            if (isFunction(sub))
                sub()
        }
        // eslint-disable-next-line
    }, [user?.user_id]);
    const isListChange = (prevList, nextList) => {
        if (prevList.length !== nextList.length)
            return true
        let result = false
        prevList.forEach((oldvalue) => {
            let idx = findIndex(nextList, {id: oldvalue.id})
            if (idx === -1) {
                result = true
            }
        })
        return result
    }
    const refresh = () => {
        setGeEvents({...geEvents})
    }
    const createEvent = (event, clb = null) => {
        const {ggEvents, geEvents} = getGlobal()
        const {project_uuid, is_google, googleEvent} = event
        if (is_google || googleEvent) {
            let idx = findIndex(googleCalendarList, {id: project_uuid})
            if (idx === -1)
                return;
            if (ggEvents[project_uuid] && ggEvents[project_uuid].events) {
                ggEvents[project_uuid].events.push(event)
            } else {
                ggEvents[project_uuid] = {
                    id: project_uuid,
                    events: [event],
                    name: googleCalendarList[idx].summary,
                    color: googleCalendarList[idx].backgroundColor
                }
            }
            setGlobal({
                    ggEvents: {...ggEvents}
                },
                () => {
                    // console.log({ggEvents})
                    if (typeof clb === "function") {
                        clb()
                    }
                }
            )
            return
        }
        if (geEvents[project_uuid]) {
            geEvents[project_uuid].events.push(event)
        } else {
            geEvents[project_uuid] = {
                id: project_uuid,
                events: [event]
            }
        }
        setGlobal({geEvents: {...geEvents}}, () => {
            // console.log({geEvents})
        })

    }

    const deleteEvent = (event, callback, identity = null) => {
        const {ggEvents, geEvents, voteEvents} = getGlobal()
        const {is_google, id, googleEvent} = event
        const compare = identity ? identity : {id: id}
        if (!event.project_uuid) {
            remove(voteEvents, compare)
            setGlobal({
                voteEvents: [...voteEvents]
            }, () => {
                refetchSource('vote')
            })
            return;
        }
        if (is_google || googleEvent) {
            Object.keys(ggEvents).forEach(key => {
                if (ggEvents[key]?.events?.length) {
                    remove(ggEvents[key].events, compare)

                }
            })
            setGlobal({
                ggEvents: {...ggEvents}
            }, () => {
                if (isFunction(callback)) {
                    callback(event)
                }
            })
            return
        }
        Object.keys(geEvents).forEach(key => {
            if (geEvents[key]?.events?.length) {
                remove(geEvents[key].events, compare)

            }
        })
        setGlobal({geEvents: {...geEvents}}, () => {
            if (isFunction(callback)) {
                callback(event)
            }
        })

    }
    const updatedEvent = (event, oldEvent) => {
        const {project_uuid, is_google, id, googleEvent} = event
        if (!event || !oldEvent)
            return;
        if (oldEvent.project_uuid !== project_uuid) {
            deleteEvent(oldEvent, () => {
                createEvent(event)
            })
            return
        }
        if ((is_google || googleEvent) && ggEvents[project_uuid]?.events?.length) {
            let idx = findIndex(ggEvents[project_uuid].events, {id})
            if (idx !== -1) {
                ggEvents[project_uuid].events[idx] = event
                setGGEvents({...ggEvents})
            }
            return;
        }
        if (geEvents[project_uuid]?.events?.length) {
            let idx = findIndex(geEvents[project_uuid].events, {id})
            if (idx !== -1) {
                geEvents[project_uuid].events[idx] = event
                setGeEvents({...geEvents})
            }

        }

    }
    const patchGoogleEvent = ({recurringEventId, project_uuid, googleEvent, is_google,}, params, clb) => {
        if (!recurringEventId || !project_uuid || isEmpty(params))
            return;
        if ((is_google || googleEvent) && ggEvents[project_uuid]?.events?.length) {
            ggEvents[project_uuid].events = ggEvents[project_uuid].events.map((event) => {
                if (event.recurringEventId === recurringEventId) {
                    return {...event, ...params}
                }
                return event
            })
            setGGEvents({...ggEvents}, () => {
                if (typeof clb === "function")
                    clb()
            })

        }

    }
    const changeProjectGoogleEvent = (eventData, oldProject, newProject) => {
        const {ggEvents} = getGlobal()
        const events = ggEvents[oldProject]?.events || []
        const idEvent = eventData.recurringEventId || eventData.id
        const instances = remove(events, e => e.id === idEvent || e.recurringEventId === idEvent)
        const destination = concat(ggEvents[newProject]?.events || [], instances)
        ggEvents[oldProject].events = events
        ggEvents[newProject].events = destination
        setGlobal({ggEvents}, () => {
            refetchSource('google')
        })
    }
    const changeTime = (event) => {
        const {ggEvents, geEvents} = getGlobal()
        const {project_uuid, is_google, id, googleEvent} = event
        const setGlobalGGEvents = (ggEvents, idx) => {
            if (idx !== -1) {
                ggEvents[project_uuid].events[idx].start = event.start
                ggEvents[project_uuid].events[idx].end = event.end
                ggEvents[project_uuid].events[idx].allDay = event.allDay
                setGlobal({
                    // googleEvents: {...ggEvents},
                    ggEvents: {...ggEvents}
                })
            }
        }
        if (is_google || googleEvent) {
            const idx = findIndex(ggEvents[project_uuid]?.events || [], {id});
            setGlobalGGEvents(ggEvents, idx)
            return
        }
        if (geEvents[project_uuid]?.events?.length) {
            const idx = findIndex(geEvents[project_uuid].events, {id});
            setGlobalGGEvents(ggEvents, idx)

        }

    }
    const revertEvent = (event, oldEvent) => {
        deleteEvent(event)
        createEvent(oldEvent)
    }

    return {
        geEvents,
        ggEvents,
        createEvent,
        deleteEvent,
        updatedEvent,
        revertEvent,
        changeTime,
        refresh,
        reLoadEvents,
        patchGoogleEvent,
        googleIdentities,
        changeProjectGoogleEvent
    }
}

export const getDateRange = (date, num) => {
    const type = 'month'
    const start = moment(date).startOf(type).subtract(1, type).utc().format()
    const end = moment(start).add(num + 2, type).utc().format()
    return {
        start,
        end
    }
}

export const getTimeMinMax = (date, theme) => {
    let type = 'month'
    let num = 2
    let start
    let end
    if (!theme) {
        start = moment(date).startOf(type).subtract(1, type).utc().format()
        end = moment(start).add(num + 2, type).utc().format()
    }
    if (theme?.viewCalendar === "CustomDay") {
        num = theme?.rows * theme?.columns
        start = moment(date).startOf('day').utc().format()
        end = moment(start).add(num - 1, 'day').endOf('day').utc().format()
        if (theme?.advanceView && theme?.tab === 2) {
            start = moment(theme?.rangeDay[0]).startOf('day').utc().format()
            end = moment(theme?.rangeDay[1]).endOf('day').utc().format()
        }
        if (theme?.settingToday) {
            start = moment(date).add(num - theme?.indexToday, 'day').utc().format()
            end = moment(start).add(num + theme?.numsDay - 1, 'day').utc().format()
        }
    }
    if (theme?.viewCalendar === "CustomWeek") {
        num = theme?.rows * theme?.columns
        start = moment(date).startOf('week').utc().format()
        end = moment(start).add(num - 1, 'week').endOf('week').utc().format()
    }
    if (theme?.viewCalendar === "CustomMonth") {
        num = theme?.rows * theme?.columns
        start = moment(date).startOf('month').utc().format()
        end = moment(start).add(num - 1, 'month').endOf('month').utc().format()
    }
    return {
        start,
        end
    }
}
//
// const getType = () => {
//     const {view} = getGlobal()
//     let type
//     switch (view) {
//         case "CustomWeek":
//             type = "week"
//             break
//         case "CustomDay":
//             type = "day"
//             break
//         default:
//             type = "month"
//     }
//     return type
// }
