import React, {useState} from 'react';
import {useGlobal} from 'reactn'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {refetchSource} from "../../../actions/refetchSourcce";
import {db} from "../../../config/firebaseConfig";
import {getScheduleAdjustmentRef} from "../../../common/firebaseRef/meetings";
import {v4 as uuidv4} from 'uuid'
import {getChangeTimeScheduleAdjustmentEmail} from "./common";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {handleGetValue, handleUpdateZoomMeeting} from "../../../actions/zoom";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {createGoogleTasksSchedule, handleDeleteGoogleTasksSchedule} from "../../../actions/googleTasks";
import momentTz from 'moment-timezone';
import {msgError, msgSuccess} from '../../../utils/msg';
import useIntlId from '../../../hooks/useIntlId';
import {Spin} from 'antd';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';

function ChangeTimeConfirmDialog(props) {
    const classes = useStyles()
    const [scheduleAdjustment, setScheduleAdjustment] = useGlobal("scheduleAdjustment")
    const {user} = useSnapshot(userProxy)
    const [loading, setLoading] = useState(false)
    const [yesIntl, noIntl, scheduleTitleIntl, askChangeTimeIntl, changeScheduleEmailIntl, updateErrorIntl, changeIntl] = useIntlId(['settings.yes', 'settings.no', 'cal.scheduleTitle', 'cal.askChangeTime', 'cal.changeScheduleEmail', 'event.toast.updateError', 'toast.success.change'])

    const handleClose = () => {
        if (scheduleAdjustment.googleEvent) {
            setScheduleAdjustment(null)
            refetchSource("google")
            return
        }
        if (scheduleAdjustment.project_uuid) {
            setScheduleAdjustment(null)
            refetchSource("geniam")
            return;
        }
        setScheduleAdjustment(null)
        refetchSource("vote")
    }

    const formatDay = (language, time) => {
        let day
        if (language) {
            if (language === 'ja-jp') {
                day = `${moment(time).format("YYYY年MM月DD日")}`
            }
            if (language === 'en-us' || language === 'vi-vn') {
                day = `${moment(time).format("DD-MM-YYYY")}`
            }
        } else {
            day = `${moment(time).format("DD-MM-YYYY")}`
        }
        return day
    }

    const handleChange = async () => {
        try {
            setLoading(true);
            let newGoogleTasksID;
            let oldGoogleTasksID = scheduleAdjustment?.googleTasksID || '';
            const zoneUser = scheduleAdjustment?.zoneUser || "Asia/Tokyo";
            const zoneUserShare = scheduleAdjustment?.zoneUserShare || "Asia/Tokyo";

            const users = [
                {
                    id: scheduleAdjustment.email,
                    email: scheduleAdjustment.email,
                    name: scheduleAdjustment.name,
                    timeZone: zoneUser
                },
                {
                    id: user.email,
                    email: user.email,
                    name: `${user.first_name} ${user.last_name}`,
                    timeZone: zoneUserShare
                }
            ];
            newGoogleTasksID = await createGoogleTasksSchedule(scheduleAdjustment, users)

            if (scheduleAdjustment.googleEvent) {
                // update google event
                const request = window.gapi.client.calendar.events.patch({
                    "calendarId": scheduleAdjustment.project_uuid,
                    "eventId": scheduleAdjustment.eventId,
                    "resource": {
                        start: {
                            dateTime: moment(scheduleAdjustment.start).utc().format(),
                            date: null
                        },
                        end: {
                            dateTime: moment(scheduleAdjustment.end).utc().format(),
                            date: null
                        },
                        googleTasksID: newGoogleTasksID
                    }
                });
                const data = await request.then()
                if (!data.status === 200) {
                    // update fail
                    msgError(`${updateErrorIntl}`)
                    handleClose()
                    return

                }
            }
            const batch = db.batch()
            if (!scheduleAdjustment?.googleEvent && scheduleAdjustment?.project_uuid) {
                // geniam
                const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${scheduleAdjustment.eventId}`)
                batch.set(ref, {
                    start: moment(scheduleAdjustment.start).utc().format(),
                    end: moment(scheduleAdjustment.end).utc().format(),
                    googleTasksID: newGoogleTasksID
                }, {merge: true})
            }

            const scheduleRef = getScheduleAdjustmentRef(user?.user_id, scheduleAdjustment?.id)

            // send email notification

            let btnIntl
            let joinZoomIntl
            let dontReplyIntl
            let subjectIntl
            let timeZoneIntl
            let beforeChangeIntl
            let afterChangeIntl
            let zoomMeetingIntl
            let nickname = (user.first_name || user.last_name) ? `${user.last_name || ''} ${user.first_name || ''}` : user.nickname

            if (scheduleAdjustment?.language) {
                let language = scheduleAdjustment.language || 'ja-jp';
                const languageSnap = await db.doc(`intl/calendarIntl/langColl/${language}`).get();
                const languageData = languageSnap.exists ? languageSnap.data() : {};

                btnIntl = `${languageData['email.message.btn.gotoEvent']}`;
                joinZoomIntl = `${languageData['email.message.joinZoom']}`;
                dontReplyIntl = `${languageData['email.message.dontReply']}`;
                subjectIntl = `${languageData['email.userSharedTime']}`
                timeZoneIntl = `${languageData['email.timeZone']}`
                beforeChangeIntl = `${languageData['email.beforeChange']}`
                afterChangeIntl = `${languageData['email.afterChange']}`
                zoomMeetingIntl = `${languageData['email.zoomMeeting']}`
            } else {
                btnIntl = 'Go to Event'
                joinZoomIntl = 'Join zoom'
                dontReplyIntl = 'This is an automated message, please do not reply.'
                subjectIntl = `${nickname} has changed meeting time`
                timeZoneIntl = 'TimeZone'
                beforeChangeIntl = 'Before change'
                afterChangeIntl = 'After change'
                zoomMeetingIntl = 'Meeting on Zoom'
            }

            let oldStart = formatDay(scheduleAdjustment.language, momentTz(scheduleAdjustment?.oldInfo?.start).tz(zoneUser))
            let oldEnd = formatDay(scheduleAdjustment.language, momentTz(scheduleAdjustment?.oldInfo?.end).tz(zoneUser))
            let oldTimeStart = momentTz(scheduleAdjustment?.oldInfo?.start).tz(zoneUser).format("HH:mm")
            let oldTimeEnd = momentTz(scheduleAdjustment?.oldInfo?.end).tz(zoneUser).format("HH:mm")

            let newStart = formatDay(scheduleAdjustment.language, momentTz(scheduleAdjustment?.start).tz(zoneUser))
            let newEnd = formatDay(scheduleAdjustment.language, momentTz(scheduleAdjustment?.end).tz(zoneUser))
            let newTimeStart = momentTz(scheduleAdjustment?.start).tz(zoneUser).format("HH:mm")
            let newTimeEnd = momentTz(scheduleAdjustment?.end).tz(zoneUser).format("HH:mm")

            let oldTime
            let newTime

            if (oldStart === oldEnd || newStart === newEnd) {
                oldTime = `${oldStart || oldEnd} (${oldTimeStart} ~ ${oldTimeEnd})`
                newTime = `${newStart || newEnd} (${newTimeStart} ~ ${newTimeEnd})`
            } else {
                oldTime = `${oldStart} (${oldTimeStart} ~ ${oldTimeEnd}) - ${oldEnd} (${oldTimeStart} ~ ${oldTimeEnd})`
                newTime = `${newStart} (${newTimeStart} ~ ${newTimeEnd}) - ${newEnd} (${newTimeStart} ~ ${newTimeEnd})`
            }

            const emailId = `meet_${uuidv4()}`
            const emailRef = db.doc(`mails/${emailId}`)
            let meetingLink = handleGetValue(scheduleAdjustment) || scheduleAdjustment?.linkZoom;

            batch.set(emailRef, {
                from: `${nickname} <geniamevents@geniam.com>`,
                replyTo: user?.email || "",
                to: scheduleAdjustment?.email || "",
                message: {
                    html: getChangeTimeScheduleAdjustmentEmail({
                        name: user.nickname || "",
                        title: scheduleAdjustment.title || "",
                        oldStart: momentTz(scheduleAdjustment?.oldInfo?.start).tz(zoneUser).format("YYYY-MM-DD HH:mm") || "",
                        oldEnd: momentTz(scheduleAdjustment?.oldInfo?.end).tz(zoneUser).format(" HH:mm YYYY-MM-DD") || "",
                        start: momentTz(scheduleAdjustment?.start).tz(zoneUser).format("YYYY-MM-DD HH:mm") || "",
                        end: momentTz(scheduleAdjustment?.end).tz(zoneUser).format(" HH:mm YYYY-MM-DD") || "",

                        oldTime: oldTime,
                        newTime: newTime,
                        linkZoom: meetingLink,
                        timeZone: `${zoneUserShare}  ${momentTz().tz(zoneUserShare).format("Z")}`,
                        btnGotoEvent: btnIntl,
                        dontRely: dontReplyIntl,
                        linkEvent: scheduleAdjustment.linkEvent || '',
                        btnJoinZoom: joinZoomIntl,
                        content: subjectIntl?.replace('name', `${nickname}`) || '',
                        timeZoneIntl: timeZoneIntl,
                        beforeChange: beforeChangeIntl,
                        afterChange: afterChangeIntl,
                        zoomMeetingIntl: zoomMeetingIntl
                    }),
                    subject: subjectIntl?.replace('name', `${nickname}`) || ''
                }
            });
            delete scheduleAdjustment.oldInfo;
            batch.set(scheduleRef, {
                start: moment(scheduleAdjustment?.start).format(),
                end: moment(scheduleAdjustment?.end).format(),
                googleTasksID: newGoogleTasksID
            }, {merge: true});
            await batch.commit();
            if (meetingLink && !scheduleAdjustment.googleEvent) {
                handleUpdateZoomMeeting(moment(scheduleAdjustment.start).format(), moment(scheduleAdjustment.end).format(), handleGetValue(scheduleAdjustment), user.user_id, scheduleAdjustment.title)
                    .catch(e => console.log(e))
            }
            if (oldGoogleTasksID) {
                console.log('deleting')
                handleDeleteGoogleTasksSchedule(oldGoogleTasksID).catch(e => console.log(e))
            }
            msgSuccess(`${changeIntl}`)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        } finally {
            setLoading(false)
            handleClose()
        }

    }

    if (!scheduleAdjustment) {
        return null
    }

    return (
        <Dialog open={Boolean(scheduleAdjustment)} onClose={handleClose}>
            <DialogTitle>{scheduleTitleIntl}</DialogTitle>
            <DialogContent className={classes.paper}>
                <Typography>
                    {changeScheduleEmailIntl.replace('email', `${scheduleAdjustment?.email}`)}
                </Typography>
                <Typography>
                    {askChangeTimeIntl}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Spin spinning={loading}>
                    <Button
                        disabled={loading}
                        onClick={handleChange}
                        variant={"outlined"}
                        color={"secondary"}
                    >
                        {yesIntl}
                    </Button>
                </Spin>
                <Button onClick={handleClose}
                        disabled={loading}
                        variant={"outlined"}
                        color={"inherit"}
                >
                    {noIntl}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 20
    }
}))

export default ChangeTimeConfirmDialog
