import React, {useEffect} from 'react';
import Setting from "./Setting";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderCenter from "./HeaderCenter";
import NavApp from "../Navigation/NavApp";
import {useGlobal} from 'reactn'
import {findIndex} from 'lodash';
import {publicIcon} from '../Calendar/handlers/infoEvent';
import ListSetting from './ListSetting';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

function MenuAppBar(props) {
    const classes = useStyles()
    const [globalApps] = useGlobal('userGeniamApps')
    const {plan} = useSnapshot(announcementProxy);
    const {handleOpen, open} = useSnapshot(settingDialogProxy);

    const idx1 = findIndex(globalApps, {"id": "calenview"})
    const idx2 = findIndex(globalApps, {"id": "milestone"})


    const openDialogSetting = () => {
        handleOpen(12)
    }

    return (
        <div className={classes.toolbar}>
            <div className={classes.divButton}>
                <div
                    className={classes.button2}
                    onClick={() => window.open(globalApps[idx1]?.url, "_blank")}
                >
                    <img className={"m-1"} src={publicIcon('calenview.svg')} alt=""/>
                </div>

                {
                    plan.info.serviceId >= 2
                        ? <div
                            className={classes.button1}
                            onClick={() => window.open(globalApps[idx2]?.url, "_blank")}
                        >
                            <img className={"m-1"} src={publicIcon('milestone1.svg')} alt=""/>
                        </div>
                        : <div
                            className={classes.button1}
                            onClick={() => openDialogSetting()}
                        >
                            <img className={"m-1"} src={publicIcon('icLockMilestone.svg')} alt=""/>
                        </div>
                }
                {
                    open && <ListSetting/>
                }
            </div>
            <div className={classes.headerLeft}>
                <NavApp/>
            </div>
            <div className={classes.flexHeader}>
                <div className={classes.menuCenter}>
                    <div className={classes.hiddeMd}>
                        {
                            window.location.pathname !== '/search' &&
                            <HeaderCenter/>
                        }
                    </div>
                </div>
                <Setting/>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: "flex",
        // overflowX: 'scroll',
        // overflowY: 'hidden',
        // position: "relative",
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'left',
        padding: "0 30px 0 5px",
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            padding: 0
        },
        "&::-webkit-scrollbar": {
            height: 0
        },
    },
    margin: {
        fontSize: 14,
        float: 'left',
        marginTop: 10,
        [theme.breakpoints.down('md')]: {
            width: 45,
            fontSize: 10,
            minWidth: 20,
        },
    },
    menuButton: {
        float: 'left',
    },
    paper: {
        ...theme.spacing(2),
        width: 'fit-content',
        float: 'left',
        backgroundColor: '#3A3A3A !important',
        padding: 0,

    },
    segment: {
        backgroundColor: 'white',
        width: "80px !important",
        color: "#42a5f5",
        borderRadius: 6,
        margin: '0 !important',
        "& label": {
            height: "27px !important",
            lineHeight: "27px !important",
            padding: "0 !important"
        }
    },

    headerRight: {
        textAlign: "right",
        // [theme.breakpoints.down('sm')]: {
        //     position: "absolute",
        //     top: 5,
        //     right: 5,
        // },
    },
    headerLeft: {
        textAlign: "left",
        // display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "flex"
        },
    },
    hiddeMd: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
    hiddenPC: {
        [theme.breakpoints.up('md')]: {
            display: "none"
        },
    },
    flexHeader: {
        display: "flex",
        // width: "calc(100% - 60px)",
        width: '100%',
        alignItems: "center",
        justifyContent: "space-between",
    },
    revertIcon: {
        transform: 'rotate(180deg)'
    },
    menuCenter: {
        // width:"80%",
        // maxWidth:700,
        // margin:"auto"
    },
    backBtn: {
        marginLeft: '10px !important',
        backgroundColor: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
    },

    divButton: {
        display: "flex",
        justifyContent: "space-evenly",
        background: "white",
        borderRadius: 20,
        margin: 5
    },
    button1: {
        display: "flex",
        justifyContent: 'center',
        background: '#fff',
        margin: 5,
        width: 50,
        height: 30,
        borderRadius: 15,
        alignItems: "center",
        cursor: 'pointer',
    },
    button2: {
        display: "flex",
        justifyContent: 'center',
        background: theme.props.primaryColor,
        margin: 5,
        width: 50,
        height: 30,
        borderRadius: 15,
        alignItems: "center",
        cursor: 'pointer',
    }
}))


export default React.memo(MenuAppBar)
