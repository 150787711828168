export const tutorialMenu = [
    {
        id: '#tutorial-top-menu',
        tutorialShow: 'tutorial_main_2'
    },
    {
        id: '#tutorial-left-bar',
        tutorialShow: 'tutorial_main_4'
    },
    {
        id: '#tutorial-settings',
        tutorialShow: 'tutorial_main_3'
    },
]
