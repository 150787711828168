// import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../apis/constants";
import {removeCookie} from "./cookies";
import {ACCESS_TOKEN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY} from "../config/constants";

export default () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    // localStorage.removeItem("ggToken");
    // localStorage.removeItem("langMsg");
    removeCookie(`geniam_${REFRESH_TOKEN_KEY}`)
    removeCookie(`geniam_${ACCESS_TOKEN_KEY}`)
    removeCookie(`geniam_${ID_TOKEN_KEY}`)

}
