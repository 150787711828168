import React from 'react';
import {useGlobal} from 'reactn';
import {withStyles} from '@material-ui/core/styles';
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons"
import AntdTooltipComponent from "../Custom/AntdTooltipComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useIntlId from '../../hooks/useIntlId';
import AddProjectPopup from '../AddProjectPopup/AddProjectPopup';

const styles = theme => ({
    root: {
        // width: '100%',
        marginTop: 20,
        position: 'relative',
        paddingLeft: 10,
        marginRight: 10,

    },
    label: {
        textTransform: 'capitalize',
        whiteSpace: "nowrap",
        height: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
        color: '#ffffff !important',
        '&:hover': {
            // background: '#3991e3 !important',
            background: theme.props.primaryColor,
            opacity: 0.8,
            borderColor: 'initial',
            color: '#FFF'
        },
        '&:focus': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            borderColor: 'initial',
            color: '#FFF'
        }
    },
    iconAntd: {
        marginRight: 5
    }
});

function AddCalendar(props) {
    const [addProject, setAddProject] = useGlobal('addProject')
    const {classes} = props
    const matches = useMediaQuery(`(min-width:1480px)`);
    const [AddCalendar] = useIntlId(['leftHomeApp.AddCalendar'])

    function ButtonTips() {
        return <Button
            block
            onClick={() => {
                return setAddProject(true)
            }}
            shape="round"
            icon={<PlusOutlined className={classes.iconAntd}/>}
            size={'default'}
            className={`${classes.label} clf-add-project`}
        >
            {AddCalendar}
        </Button>
    }

    return (
        <div id={'add-new-project'} className={`${classes.root}`}>
            <AntdTooltipComponent
                matches={matches}
                title={AddCalendar}
                placement={'bottom'}
                id={'add-new-project'}
                componentMain={<ButtonTips/>}
            />
            {addProject && <AddProjectPopup addProject={addProject} setAddProject={setAddProject}/>}
        </div>
    )
}

export default React.memo(withStyles(styles)(AddCalendar))
