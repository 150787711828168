import React, {useEffect, useState} from 'react';
import {IconButton, Popover} from '@material-ui/core';
import {setGlobal, useGlobal} from 'reactn'
import {useHistory, withRouter} from 'react-router-dom'
import "./Settings/styles.css"
import {makeStyles, useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSetting from "./ListSetting";
import {ReactComponent as HelpIcon} from "../../asSvg/helpIcon.svg";
import {ReactComponent as RollBack} from "../../asSvg/rollback.svg";
import SearchAutoComplete from "../SearchAutoComplete";
import openNewTab from "../../common/openNewTab";
import MenuIcon from '@material-ui/icons/Menu';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';

function Setting(props) {
    const [openSetting, setOpenSetting] = useGlobal('openSetting')
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(null)
    const open = Boolean(openMenu);
    const id = open ? 'menuright-popover' : undefined;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const history = useHistory()


    function goToSetting(event) {
        event.preventDefault()
        event.stopPropagation()
        const next = openNewTab(event, window.location.href)
        if (next) return
        handleOpen()
        setOpenMenu(null)
    }

    const handleClose = () => {
        setOpenMenu(null);
    };
    const handleClick = () => {
        setOpenMenu(!openMenu)
    }

    useEffect(() => {
        setOpenMenu(null)
    }, [matches])

    if (matches)
        return (
            <div className={classes.contentSettingMobile}>
                <SearchAutoComplete/>

                <IconButton
                    aria-describedby={id}
                    className={classes.btnMenu}
                    onClick={handleClick}>
                    <MenuIcon/>
                </IconButton>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={openMenu}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    className={classes.popoverAccount}
                >
                    <div className={classes.contentPopover}>
                        <IconButton onClick={() => setGlobal({visible: true})}>
                            <HelpIcon width={22} height={22}/>
                        </IconButton>

                        <IconButton
                            disableRipple={true}
                            aria-owns={'menu-appbar'}
                            aria-haspopup="true"
                            onClick={goToSetting}
                            color="inherit"
                        >
                            <img src='/imgs/newSvgs/setting.svg' alt=''
                                 className={window.location.pathname === '/' ? classes.SettingIcon : classes.SettingIconEvent}/>
                        </IconButton>
                    </div>
                </Popover>
                {
                    openSetting && <ListSetting setOpenSetting={setOpenSetting} openSetting={openSetting}/>
                }
            </div>
        )
    return (
        <div className={classes.root} data-html2canvas-ignore={true}>
            <div id={'calendar-top-bar-right'}
                 className={window.location.pathname === '/' ? classes.content : classes.contentEvent}>
                <div id={'tutorial-settings'} className={classes.fl}>

                    {history.location && history.location.pathname === '/search' && (
                        <IconButton id={'calendar-top-bar-right-rollback'}
                                    onClick={() => {
                                        history.push('/')
                                    }}>
                            <RollBack width={22} height={22}/>
                        </IconButton>
                    )}

                    <SearchAutoComplete/>

                    <IconButton id={'calendar-top-bar-right-help'}
                                onClick={() => {
                                    history.push('/')
                                    setGlobal({visible: true})
                                }}>
                        <HelpIcon width={22} height={22}/>
                    </IconButton>

                    <IconButton
                        id={'calendar-top-bar-right-setting'}
                        disableRipple={true}
                        aria-owns={'menu-appbar'}
                        aria-haspopup="true"
                        onClick={goToSetting}
                        color="inherit"
                    >
                        <img src='/imgs/newSvgs/setting.svg' alt=''
                             className={window.location.pathname === '/' ? classes.SettingIcon : classes.SettingIconEvent}/>
                    </IconButton>
                </div>
            </div>
            {
                openSetting && <ListSetting setOpenSetting={setOpenSetting} openSetting={openSetting}/>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    content: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    btnMenu: {
        display: "none",
        [theme.breakpoints.down('md')]: {
            display: "flex"
        }
    },
    contentEvent: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    SettingIcon: {
        color: '#000',
        [theme.breakpoints.down('md')]: {
            color: "#000"
        }
    },
    SettingIconEvent: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            color: "#000"
        }
    },
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    whiteText: {
        color: "black",
        fill: theme.props.primaryColor
    },
    blackText: {
        color: "black"
    },
    popoverAccount: {
        "& .MuiPaper-rounded": {
            minWidth: 160,
        }
    },

    buttonAntd: {
        textTransform: 'uppercase',
        fontWeight: 700,
        "& svg": {
            marginLeft: 5
        }
    },
    contentPopover: {
        display: "flex",
        flexDirection: "row",
        '& .MuiButtonBase-root': {
            margin: '0 10px'
        },
        alignItems: 'center'
    },
    contentSettingMobile: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    fl: {
        display: "flex",
        alignItems: "center"
    }
}));

export default withRouter(Setting)
