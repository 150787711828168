import React, {useEffect, useState} from 'react';
import {ClickAwayListener, IconButton, ListItemText, TextField} from "@material-ui/core";
import {useGlobal} from 'reactn'
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import {useDebounce, useLocalStorage, useSearchParam} from 'react-use'
import moment from "moment";
import {useHistory} from "react-router-dom";
import {findIndex, remove} from 'lodash'
import useIntlId from "../../hooks/useIntlId";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SettingSearch from "../../Page/Search/SettingSearch";
import DialogChangeTime from "../Meeting/NewMeeting/DialogChangeTime";
import EditEvent from "../Calendar/EditEvent";
import {cleanSpaceStr} from '../../utils/string'
import {useSnapshot} from 'valtio';
import {searchProxy} from '../../store/proxy/search.proxy';

function SearchAutoComplete(props) {
    const classes = useStyles()
    const history = useHistory()
    const [searchText, setSearchText] = useState('')
    const [googleStatus] = useGlobal("googleStatus")
    const [searchKeyHistory = [], setSearchKeyHistory] = useLocalStorage('searchKeyHistory')
    const [searchPlaceHolder] = useIntlId(['event.meeting.search'])
    const searchParams = useSearchParam('searchKey')
    const [settingSearch, setSettingSearch] = useState(null)
    const {
        timeSearch,
        setTimeSearch,
        checkTimeSearch,
        setCheckTimeSearch,
        listSearch,
        searchEvents,
        openSearch,
        setOpenSearch
    } = useSnapshot(searchProxy);
    const [openChangeDate, setOpenChangeDate] = useState(false)
    const [edit, setEdit] = useState(null)

    useEffect(() => {
        if (history.location.pathname === "/search" && searchParams) {
            setSearchText(searchParams);
            setOpenSearch(true);
        }
    }, [history.location.pathname, searchParams])
    const onItemClick = (event) => {
        setSearchText(event.title);
        if (event?.isSearchKeyHistory)
            return history.push("/search?searchKey=" + event.title)
        setEdit({event})
    }
    const onOpenChangeDate = () => {
        if (!checkTimeSearch)
            return setOpenChangeDate(true)
    }
    const closeSettingSearch = () => {
        setSettingSearch(null);
    };
    const isSearchPage = () => {
        return window.location.pathname === '/search'
    }

    const handleChange = (e) => {
        let value = e.target.value;
        setSearchText(value);
        if (window.location.pathname === '/search') {
            let value2 = cleanSpaceStr(value);
            history.push("/search?searchKey=" + value2);
        }
    }

    const handleKeyDown = (e) => {
        if (!searchText || !searchText.trim())
            return;
        if (e.keyCode === 13) {
            let idx = findIndex(searchKeyHistory, {title: searchText})
            if (idx === -1) {
                setSearchKeyHistory([...searchKeyHistory, {
                    title: searchText.trim(),
                    createdAt: moment().utc().format(),
                    isSearchKeyHistory: true
                }])
            }
            history.push("/search?searchKey=" + searchText)
        }
    }
    const onRemoveHistoryKey = (event) => {
        remove(searchKeyHistory, {title: event.title})
        setSearchKeyHistory([...searchKeyHistory])
    }
    const renderOption = (event, {selected}) => {
        return (
            <div className={classes.divOption} onClick={() => {
                onItemClick(event)
            }}>
                {
                    event?.isSearchKeyHistory &&
                    <div className={classes.removeSearchKey}>
                        <IconButton
                            disableRipple={true}
                            onClick={() => onRemoveHistoryKey(event)}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                }
                <ListItemText
                    style={{fontFamily: "source-han-sans-japanese"}}
                    primary={event.title}
                    secondary={!event.isSearchKeyHistory && `${moment(event.start).format("YYYY-MM-DD HH:mm")}
                    ~ ${moment(event.end).format("YYYY-MM-DD HH:mm")}`}
                />
            </div>
        )
    }
    const renderInput = (params) => (
        <TextField {...params}
                   autoFocus={true}
                   value={searchText}
                   onKeyDown={handleKeyDown}
                   onChange={handleChange}
                   placeholder={searchPlaceHolder}
                   variant="outlined"
                   id={"click-input-not-listener"}
        />
    )

    const handleClickBtnSearch = () => {
        setSearchText('');
        setOpenSearch(!openSearch);
    }

    const handleClickAway = (event) => {
        event.stopPropagation();
        if (settingSearch)
            return null;
        if (window.location.pathname !== "/search") {
            setOpenSearch(false);
        }
    };
    useDebounce(() => {
        searchEvents(searchText, !checkTimeSearch ? timeSearch.start : null, !checkTimeSearch ? timeSearch.end : null, checkTimeSearch, !googleStatus?.is_login)
    }, 500, [searchText, checkTimeSearch, googleStatus])
    return (
        <React.Fragment>
            {
                openSearch ?
                    <ClickAwayListener onClickAway={handleClickAway}>

                        <div className={classes.boxSearch}>
                            <Autocomplete
                                freeSolo
                                options={listSearch.length > 0 ? (!isSearchPage() ? listSearch : []) : searchKeyHistory}
                                className={classes.searchInput}
                                getOptionLabel={(event) => event.title}
                                disableListWrap={window.location.pathname === '/search'}
                                noOptionsText={''}
                                renderOption={renderOption}
                                inputValue={searchText}
                                renderInput={renderInput}

                            />

                            <div className={classes.absolute}>
                                {
                                    !checkTimeSearch &&
                                    <div className={classes.timeSearch}>
                                        <span>{moment(timeSearch.start).format('MM/DD',)}</span>
                                        -
                                        <span>{moment(timeSearch.end).format('MM/DD',)}</span>
                                    </div>
                                }

                                <IconButton
                                    disableRipple={true}
                                    onClick={e => {
                                        setSettingSearch(e.currentTarget)
                                    }}
                                >
                                    <ArrowDropDownIcon/>
                                </IconButton>
                                {
                                    searchText !== "" && <IconButton
                                        disableRipple={true}
                                        onClick={() => {
                                            setSearchText("")
                                        }}
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                }
                            </div>
                            {
                                settingSearch && <SettingSearch settingSearch={settingSearch}
                                                                setSettingSearch={setSettingSearch}
                                                                closeSettingSearch={closeSettingSearch}
                                                                onOpenChangeDate={onOpenChangeDate}
                                                                startDateSearch={timeSearch.start}
                                                                endDateSearch={timeSearch.end}
                                                                setCheckTimeSearch={setCheckTimeSearch}
                                                                checkTimeSearch={checkTimeSearch}
                                />
                            }
                            {
                                openChangeDate && !checkTimeSearch &&
                                <DialogChangeTime
                                    setEventTime={setTimeSearch}
                                    setOpen={setOpenChangeDate}
                                    open={openChangeDate}
                                    startDate={timeSearch.start}
                                    endDate={timeSearch.end}
                                    checkTimeSearch={checkTimeSearch}
                                />
                            }
                        </div>
                    </ClickAwayListener>
                    :
                    null
            }

            <IconButton id={'calendar-top-bar-right-search'} disableRipple={true} className={classes.whiteText}
                        onClick={handleClickBtnSearch}
            >

                {
                    openSearch ? <img src={'icons/searchActive.svg'} alt="searching"/>
                        :
                        <img src={'imgs/newSvgs/search.svg'} alt='search'/>
                }
            </IconButton>
            <EditEvent editInfo={edit} setEditInfo={setEdit}/>
        </React.Fragment>
    );
}


const useStyles = makeStyles(theme => ({
    whiteText: {
        color: "black",
        fill: theme.props.primaryColor
    },
    boxSearch: {
        width: "100%",
        maxWidth: "535px",
        position: "relative",
        display: "flex",
        backgroundColor: "#fff",
        borderRadius: 25
    },
    searchInput: {
        fontWeight: "bold",
        minWidth: 150,
        // width: 500,
        [theme.breakpoints.down('md')]: {
            width: 350,
        },
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
        [theme.breakpoints.down('xs')]: {
            width: 150,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "& .MuiFormControl-root": {
            background: "#fff",
            padding: "3px",
            borderRadius: "25px"
        },
        "& .MuiOutlinedInput-root": {
            padding: "0 !important"
        },
        "& .MuiSvgIcon-root": {
            display: "none"
        },
        "& input": {
            fontWeight: "bold",
            fontSize: 14,
            // fontFamily: 'Montserrat'
        }
    },
    divOption: {
        position: "relative",
        width: '100%'
    },
    absolute: {
        // position: "absolute",
        // right: "3px",
        // top: "50%",
        // transform: "translateY(-50%)",
        color: "#000 !important",
        display: "flex !important",
        alignItems: "center"
    },
    removeSearchKey: {
        position: "absolute",
        right: 5,
        top: '50%',
        transform: 'translateY(-50%)'
    },
    timeSearch: {
        fontSize: 12
    },
}))

export default SearchAutoComplete;
