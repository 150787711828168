import {db} from "../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../config/constants";
import {userProxy} from '../store/proxy/user.proxy';
import {snapshot} from 'valtio';

export const myCal = () => {
    const {user} = snapshot(userProxy)
    if (!user?.user_id) {
        return;
    }
    return db.collection(CALENDAR_COLLECTION)
        .doc(user.user_id)
}
