import React from 'react';
import {useGlobal} from "reactn"
import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'antd';
import {ReactComponent as CheckDaySVG} from "../../../../asSvg/icons/checkDay.svg";
import {ReactComponent as UnCheckDaySVG} from "../../../../asSvg/icons/unCheckDay.svg";
import useIntlId from '../../../../hooks/useIntlId';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';

const useStyles = makeStyles(theme => ({
    checkItem: {
        width: 73,
        height: 33,
        backgroundColor: "#f1f1f1",
        borderRadius: "24px",
        fontWeight: "bold",
        position: "relative",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        color: "#000 !important",
        marginRight: 3,
        padding: "0 !important",
        "&:hover": {
            backgroundColor: "#f1f1f1",
            color: "#000",
            border: "none",
        },
        "& svg": {
            marginLeft: 4,
        }
    },
    title: {
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        maxWidth: 130,
        margin: "0 10px 0 0px"
    },
    listCheckBox: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    root: {
        padding: '0 15px',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        minHeight: 39,
        backgroundColor: "#fff",
        borderRadius: "15px",
        margin: '18px 0',

    },
    rootEvent: {
        display: "flex",
        flexDirection: "column",
        fontSize: 10,
        marginBottom: 15,
        "& .WeekDayTitle": {
            color: theme.props.primaryColor,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "90%",
            display: "block",
            textTransform: "uppercase",
            fontWeight: "bold",
            margin: '0 !important',
            textAlign: "left",
        },
        "& .WeekDayList": {
            padding: "6px 7px 0 10px",
            background: "#f1f1f1",
            display: "flex",
            justifyContent: "flex-start",
            borderRadius: "0 10px 10px 0",
            marginTop: 7,
        },
        "& .WeekDayCkeckItem": {
            width: "calc(25% - 4px)",
            margin: "0 4px 6px 0",
            fontSize: 10,
            height: 25,
            padding: 0,
            "& svg": {
                width: 16,
                height: 16,
                marginLeft: 4,
            }
        }
    },
    checkDaySVG: {
        fill: theme.props.primaryColor,
    }

}));

const weekDay = {
    'ja-jp': ['月', '火', '水', '木', '金', '土', '日'],
    'en-us': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    'ko-kr': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    'vi-vn': ['Hai', 'Ba', 'Bốn', 'Năm', 'Sáu', 'Bảy', 'CN'],
}

function WeekDay(props) {
    const {handleChange, hiddenDays, meeting, addMeeting} = props
    const {user} = useSnapshot(userProxy)
    const classes = useStyles();
    const [weekday] = useIntlId(['event.weekday'])
    return (
        <div
            className={(typeof (meeting) != "undefined" || typeof (addMeeting) != "undefined") ? classes.rootEvent : classes.root}>
            <div className={`${classes.title} WeekDayTitle addCenter`}>{weekday}</div>
            <div className={`${classes.listCheckBox} WeekDayList`}>
                {
                    weekDay[user.language || "ja-jp"]?.map((label, index) => {
                        return (
                            <Button key={index} className={`${classes.checkItem} WeekDayCkeckItem`}
                                    onClick={() => handleChange(index + 1)}
                                    style={addMeeting ? {borderRadius: "10px", padding: "6px 10px 0 10px"} : {}}
                            >
                                {label}
                                {
                                    !hiddenDays?.includes(index + 1) ?
                                        <CheckDaySVG className={`${classes.icon} ${classes.checkDaySVG}`}/>
                                        :
                                        <UnCheckDaySVG className={`${classes.icon} ${classes.checkDaySVG}`}/>

                                }
                                {/*<Checkbox*/}
                                {/*    className={classes.checkBoxAnt}*/}

                                {/*    checked={!hiddenDays?.includes(index + 1)}*/}
                                {/*    onChange={() => handleChange(index + 1)}>*/}
                                {/*    {label}*/}
                                {/*</Checkbox>*/}
                            </Button>
                        )
                    })
                }
            </div>

        </div>
    );
}

export default WeekDay;
