import {notification} from 'antd';

const types = {
    error: 'error',
    info: 'info',
    success: 'success'
}
const notif = (message, {config} = {}) => {
    let placement = 'topRight';
    switch (config?.placement) {
        case 'br':
            placement = 'bottomRight';
            break;
        case 'bl':
            placement = 'bottomLeft';
            break;
        case 'tr':
            placement = 'topRight';
            break;
        case 'tl':
            placement = 'topLeft';
            break;
        default:
            break;
    }
    notification[config?.type || types.success](
        {message, placement});
};
const notifError = (msg, config) => {
    config = {...config, type: types.error}
    return notif(msg, config)
}
const notifInfo = (msg, config) => {
    config = {...config, type: types.info}
    console.log(config)
    return notif(msg, config)
}
const notifSuccess = (msg, config) => {
    config = {...config, type: types.success}
    return notif(msg, config)
}

export {
    notifSuccess,
    notifInfo,
    notifError,
}
export default notif;
