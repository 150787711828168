import {last} from 'lodash'
import moment from 'moment';
import {momentyyyyMMDDTHHmmssZ} from '../utils/time';

export const getRruleString = (rrule) => {
    if (Array.isArray(rrule)) {
        return rrule.find(item => item?.includes("RRULE:"))
    }
    if (typeof rrule !== "string")
        return null
    return rrule?.includes('RRULE:') ? rrule : null
}

export const getRrule = (rrule) => {
    if (Array.isArray(rrule)) {
        return rrule
    }
    if (typeof rrule !== "string")
        return null
    return rrule?.includes('RRULE:') ? rrule : null
}

export const getExDateString = (rrule) => {

    if (Array.isArray(rrule)) {
        return rrule.find(item => item?.includes("EXDATE"))
    }
    if (typeof rrule !== "string")
        return null
    return rrule?.includes('EXDATE') ? rrule : null
}


export const getExDatesFromString = (exDateString = '') => {
    if (!exDateString || typeof exDateString !== "string")
        return []
    const dateArr = exDateString.split(":")[1]
    if (!dateArr || typeof dateArr !== "string")
        return []
    return dateArr.split(",")

}

export const getExDatesFromRrule = (rrule) => {
    return getExDatesFromString(getExDateString(rrule))
}


export const removeExDates = (list = [], dates = []) => {
    if (!dates.length)
        return list
    const _dates = dates.map(e => {
        return removeSeconds(e)
    })
    return list.filter(item => {
        if (!item || !item.id || typeof item.id !== "string")
            return false
        const dateId = removeSeconds(last(item.id.split("_")))
        if (!dateId)
            return false
        return !_dates.includes(dateId)
    })
}

const removeSeconds = date => {
    return momentyyyyMMDDTHHmmssZ(moment(date).set({
        seconds: 0
    }))
}
