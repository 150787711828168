import React, {useEffect, useState} from 'react';
import {Grid, Popover, Radio, Typography} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import InputNumber from "../../Custom/InputNumber";
import Select from 'react-select'
import {ReactComponent as CheckBoxActiveSVG} from "../../../asSvg/icons/checkBoxActive.svg";
import {ReactComponent as CheckBoxSVG} from "../../../asSvg/icons/checkBox.svg";
import DateTimePicker from "./DateTimePicker";
import moment from "moment";
import {RRule} from 'rrule'
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import useIntlId from "../../../hooks/useIntlId";
import {isNumber} from 'lodash';
// import translate from "translate";

const dow = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"]

function CustomRecurrenceDialog({value, anchorEl, setAnchorEl, onDone, event}) {
    const oldRrule = RRule.parseString(value)
    const classes = useStyles()
    const [repeatSetting, EndDate, repeatInterval, none, repeat, day, week, month, year, times, accept, cancel, repeatOn, monthly] = useIntlId([
        'create.repeatSetting',
        'create.EndDate',
        'create.repeatInterval',
        'create.none',
        'create.repeat',
        'create.day',
        'create.week',
        'create.month',
        'create.year',
        'create.times',
        'create.accept',
        'create.cancel',
        'repeat.dow.label',
        'repeat.monthly'
    ])
    const options = [
        {value: 'day', label: day,},
        {value: 'week', label: week,},
        {value: 'month', label: month,},
        {value: 'year', label: year},
    ]
    const dfDayOfWeek = ["MO", "TU", "WE", "TH", "FR"]
    const [interval, setInterVal] = useState({
        range: 1,
        type: options[0]
    })
    const [dayOfWeek, setDayOfWeek] = useState(dfDayOfWeek)
    const [endType, setEndType] = useState('none')
    const [endDate, setEndDate] = useState(new Date())
    const [endTimes, setEndTimes] = useState(13)
    // const [user] = useGlobal('user')

    useEffect(() => {
        // calc default value
        setEndDate(moment(event.start).add(1, "month").format('YYYY-MM-DD'))
        setInterVal({
            range: oldRrule?.interval > 1 ? oldRrule?.interval : 1,
            type: isNumber(oldRrule?.freq) ? options[3 - oldRrule?.freq] : options[0]
        })
        setEndType(getEndTimeOld)
        setDayOfWeek(getWeekOld);
    }, [event])

    const getEndTimeOld = () => {
        let type = 'none'
        if (oldRrule?.until) {
            setEndDate(oldRrule?.until)
            type = 'date'
        }
        if (oldRrule?.count) {
            setEndTimes(oldRrule?.count)
            type = 'times'
        }
        return type
    }
    const getWeekOld = () => {
        if (oldRrule?.byweekday) {
            let weekday = []
            oldRrule.byweekday.map(i => {
                weekday.push(dow[i.weekday]);
            })
            return weekday
        }
        return dfDayOfWeek
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAccept = async () => {
        let options = {}
        let freq = interval?.type?.value

        if (!freq)
            return;
        if (interval?.range > 1)
            options.interval = interval?.range
        switch (freq) {
            case "day":
                options.freq = RRule.DAILY
                break;
            case "month":
                options.freq = RRule.MONTHLY
                break;
            case "week":
                options.freq = RRule.WEEKLY
                options.byweekday = dayOfWeek.map(i => RRule[i])
                break;
            case "year":
                options.freq = RRule.YEARLY
                break
            default:
                break
        }
        if (endType === "date") {
            options.until = new Date(moment(endDate).endOf('day').utc().format())
        }
        if (endType === "times") {
            options.count = endTimes
        }
        const rrule = new RRule(options)
        if (onDone && typeof onDone === "function") {
            // let label
            // if (user?.language === "vi-vn") {
            //     label = await translate(rrule.toText(), "vi")
            // }
            // if (user?.language === "en-us") {
            //     label = await translate(rrule.toText(), "en")
            //
            // }
            // if (user?.language === "ja-jp") {
            //     label = await translate(rrule.toText(), "ja")
            // }
            onDone({
                value: rrule.toString(),
                label: rrule.toText()
            })


        }
        // const text = await translate(rrule.toText(), "vi");


        handleClose()
    }

    return (
        <div>
            <Popover open={Boolean(anchorEl)}
                     anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'center',
                         horizontal: 'right',
                     }}
                     transformOrigin={{
                         vertical: 'center',
                         horizontal: 'left',
                     }}
                     onClose={handleClose}
                     className={classes.root}
            >
                <Typography variant={"h4"} className={classes.title}>{repeatSetting}</Typography>
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={4}>{repeatInterval}</Grid>
                        <Grid item xs={8}>
                            <div className={classes.flexBox}>
                                <div className={classes.intervalInput}>
                                    <InputNumber
                                        type={'text'}
                                        label={interval.range}
                                        size={35}
                                        onChange={(type, event) => {
                                            if (type === "degree" && interval.range > 1) {
                                                setInterVal({...interval, range: interval.range - 1})
                                            }
                                            if (type === "increment") {
                                                setInterVal({...interval, range: interval.range + 1})
                                            }
                                        }}
                                    />
                                </div>
                                <Select value={interval.type} className={classes.select} options={options}
                                        onChange={value => {
                                            setInterVal({...interval, type: value})
                                        }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    {
                        interval?.type?.value === "week" &&
                        <Grid container className={classes.typeContainer}>
                            <Grid item xs={4}>{repeatOn}</Grid>
                            <Grid item xs={8}>
                                <div className={classes.dow}>
                                    {
                                        dow.map((d, index) => {
                                            return (
                                                <div key={d} className={classes.dowItem}
                                                     onClick={() => {
                                                         let idx = dayOfWeek.indexOf(d)
                                                         if (idx === -1) {
                                                             dayOfWeek.push(d)
                                                             setDayOfWeek([...dayOfWeek])
                                                         } else {
                                                             dayOfWeek.splice(idx, 1)
                                                             setDayOfWeek([...dayOfWeek])
                                                         }
                                                     }}
                                                >
                                                    {moment().isoWeekday(index + 1).format("dd")}
                                                    {
                                                        dayOfWeek.includes(d) ?
                                                            <CheckBoxActiveSVG className={classes.checkBox}/> :
                                                            <CheckBoxSVG className={classes.checkBox}/>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                        </Grid>

                    }
                    {
                        interval?.type?.value === "month" &&
                        <Grid container className={classes.typeContainer}>
                            <Grid item xs={4}/>
                            <Grid item xs={8}>
                                <div className={classes.dow}>
                                    <Typography
                                        className={classes.dowItem}>{monthly} {moment(event?.start).format("DD")}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    }

                    <Grid container className={classes.typeContainer}>
                        <Grid item xs={4}>{EndDate}</Grid>
                        <Grid item xs={8}>
                            <div className={classes.endContainer}>
                                <div className={classes.endItem}>
                                    <Radio checked={endType === "none"} onChange={() => setEndType('none')}/>
                                    <Typography>{none}</Typography>
                                </div>
                                <div className={classes.endItem}>
                                    <Radio checked={endType === "date"} onChange={() => setEndType('date')}/>
                                    <Typography>{EndDate}</Typography>
                                    <div className={classes.datePicker}>
                                        <DateTimePicker allDay={true}
                                                        isEnd={false}
                                                        dateTime={endDate}
                                                        background={"#fff"}
                                                        onChange={value => {
                                                            setEndDate(value)
                                                        }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.endItem}>
                                    <Radio checked={endType === "times"} onChange={() => setEndType('times')}/>
                                    <Typography>{repeat} </Typography>
                                    <div className={classes.repeatTimes}>
                                        <InputNumber
                                            label={times}
                                            values={endTimes}
                                            min={1}
                                            steps={1}
                                            onChange={(type, value) => {
                                                setEndTimes(value)
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>


                        </Grid>
                    </Grid>
                    <div className={classes.actions}>
                        <CLFButtonSVG
                            name={cancel}
                            onClick={handleClose}
                            className={classes.cancelBtn}
                        />
                        <CLFButtonSVG
                            name={accept}
                            onClick={handleAccept}
                            className={classes.acceptBtn}
                        />
                    </div>
                </div>

            </Popover>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPopover-paper": {
            padding: 10,
            borderRadius: 10,
            minWidth: 450
        }
    },
    title: {
        textAlign: 'center',
        color: theme.props.primaryColor,
        fontWeight: 700
    },
    content: {
        background: "#f1f1f1",
        borderRadius: 10,
        padding: 10,
        marginTop: 20,
    },
    flexBox: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between"
    },
    select: {
        minWidth: 100
    },
    intervalInput: {
        width: 120
    },
    checkBox: {
        fill: theme.props.primaryColor,
        marginLeft: 5
    },
    typeContainer: {
        marginTop: 20
    },
    dow: {
        display: "flex",
        flexWrap: "wrap",
        borderRadius: 10,
        background: "#fff"

    },
    dowItem: {
        margin: "5px",
        padding: "2px 5px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        background: "#f1f1f1",
        borderRadius: 10
    },
    endContainer: {
        borderRadius: 10,
        background: "#fff",
        padding: 10
    },
    endItem: {
        background: "#f1f1f1",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        marginBottom: 5,
    },
    datePicker: {
        background: "#fff",
        borderRadius: 10,
        padding: 2,
        marginLeft: 5,
        minWidth: 150
    },
    repeatTimes: {
        marginLeft: 5
    },
    actions: {
        display: "flex",
        justifyContent: "center",
        marginTop: 30
    },
    acceptBtn: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        color: "#fff"
    },
    cancelBtn: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        color: "#fff"
    }
}))

export default CustomRecurrenceDialog;
