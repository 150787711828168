import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Input, Switch} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import {getGlobal, useGlobal} from 'reactn';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import PreviewHighLight from '../../PreviewHighLight/PreviewHighLight';
import ListTargetDays from '../HighLightBar/ListTargetDays';
import ListTargetWeeks from '../HighLightBar/ListTargetWeeks';
import ListTargetMonths from '../HighLightBar/ListTargetMonths';
import {filter} from 'lodash';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function HighLight({updateHighLight}) {
    const classes = useStyles()
    const {bar, lock} = useSnapshot(announcementProxy)
    const {user} = useSnapshot(userProxy)
    const [textHighLight, setTextHighLight] = useState('')
    const [editHighLight, setEditHighLight] = useState(false)
    const [
        highlightIntl,
        highlightTextIntl,
        titleTarget,
    ]
        = useIntlId(
        [
            'settings.announcement.highlight',
            'settings.announcement.highlightText',
            'settings.announcement.target',
        ])
    const [checkHighLight, setCheckHighLight] = useState(false)
    const [focusFromPreview, setFocusFromPreview] = useGlobal('focusFromPreview')
    const [, setNumCheckHighLight] = useGlobal('numCheckHighLight')
    useEffect(() => {
        if (typeof bar?.highlight !== "string") {
            setTextHighLight('')
            setCheckHighLight(false)
        } else {
            setTextHighLight(bar.highlight)
            setCheckHighLight(bar?.isShowHighLight)
        }
    }, [bar?.highlight, bar?.isShowHighLight])

    useEffect(() => {
        let numDaysCheck = filter(bar?.daysCheck || [true], item => item === true).length
        let weeksCheck = filter(bar?.weeksCheck || [true], item => item === true).length
        let monthsCheck = filter(bar?.monthsCheck || [true], item => item === true).length
        let numHighLight = bar?.isShowHighLight ? 1 : 0
        setNumCheckHighLight(numDaysCheck + weeksCheck + monthsCheck + numHighLight)

        // eslint-disable-next-line
    }, [bar?.isShowHighLight, bar?.weeksCheck, bar?.daysCheck, bar?.monthsCheck])
    const handleUpdateHighLight = () => {
        if (editHighLight) {
            updateHighLight(textHighLight || '')
            setEditHighLight(false)
            setFocusFromPreview(null)
        }
    }

    const handleCheckedHighLight = async (fieldName, value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        } finally {
            setFocusFromPreview(null)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.flexBox}>
                <div className={classes.title}>{highlightIntl}</div>
            </div>
            <div
                className={(editHighLight || focusFromPreview?.id === 'highLight') ? classes.itemFocus : classes.item}>
                <div className={classes.listText}>
                    <div className={classes.content}>
                        {
                            (editHighLight || focusFromPreview?.id === 'highLight') ?
                                <div className={`${classes.wrapHighLight}`}>
                                    <Input
                                        onPressEnter={handleUpdateHighLight}
                                        onBlur={handleUpdateHighLight}
                                        disabled={lock}
                                        className={classes.input} autoFocus={true} value={textHighLight}
                                        onChange={(e) => setTextHighLight(e.target.value)}/>
                                    <Switch
                                        className={classes.iconChecked}
                                        checked={checkHighLight}
                                        disabled={lock}
                                        onChange={() => handleCheckedHighLight('isShowHighLight', !checkHighLight)}
                                    />
                                </div>
                                :
                                <div className={`${classes.wrapHighLight}`}>
                                    <div onClick={() => {
                                        if (lock)
                                            return;
                                        setEditHighLight(!editHighLight)
                                    }}
                                         className={classes.textHighLight}>{textHighLight || highlightTextIntl}
                                    </div>
                                    <Switch
                                        className={classes.iconChecked}
                                        checked={checkHighLight}
                                        disabled={lock}
                                        onChange={() => handleCheckedHighLight('isShowHighLight', !checkHighLight)}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className={classes.flexBox}>
                <div className={classes.title}>{titleTarget}</div>
            </div>
            <div className={`${classes.wrapTargetHighLight} ${classes.containerTargetHighLight}`}>
                <ListTargetDays
                    handleCheckedHighLight={handleCheckedHighLight}
                />

                <ListTargetWeeks
                    handleCheckedHighLight={handleCheckedHighLight}

                />
                <ListTargetMonths
                    handleCheckedHighLight={handleCheckedHighLight}
                />
            </div>
            <div className={classes.wrapTargetHighLight}>
                <PreviewHighLight/>
            </div>
        </div>
    );
}

export default HighLight;
const useStyles = makeStyles(theme => ({
    root: {
        padding: "15px 0",
    },
    flexBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444"
    },
    titleTarget: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.props.primaryColor
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    item: {
        backgroundColor: "#fff",
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    itemFocus: {
        backgroundColor: '#fff',
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    number: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444",
        width: 40,
        textAlign: "center"
    },
    listText: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
    },
    textHighLight: {
        cursor: "pointer",
        paddingLeft: 10,
        width: "100%"
    },
    simulator: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        width: 40,
        "& svg": {
            fill: theme.props.primaryColor
        }
    },
    flex1: {
        flex: 1,
        display: "flex"
    },
    content: {
        // backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        fontSize: 12,
        color: "#444444",
        margin: 2,
        width: "100%",
        padding: "8px 45px 8px 5px",
        "& .ant-input:focus, .ant-input-focused": {
            backgroundColor: "#fff",
            border: "none",
            boxShadow: "none"
        }
    },
    input: {
        border: "none",
        backgroundColor: "transparent",
        padding: "0 0 0 10px",
        outline: "none",
        fontSize: 12,
        color: "#444444",
    },
    wrapHighLight: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    iconChecked: {
        // width: 28,
        // height: 28,
        position: "absolute",
        right: "-40px",
        display: "flex",
        alignItems: "center",
        // borderRadius: "50%",
        // border: `2px solid ${theme.props.primaryColor}`,
        // background: "#fff",
        // cursor: "pointer",
    },
    highLightIcon: {
        fill: theme.props.primaryColor,
        cursor: "pointer",
    },
    boxSimulator: {
        borderRadius: 10,
        padding: 8,
        color: theme.props.primaryColor,
        fontWeight: 600,
        fontSize: 16,
        border: "2px solid #1790ff",
    },
    simulatorHighLight: {
        marginRight: 10
    },
    showTime: {
        background: "#fff",
        color: theme.props.primaryColor,
        padding: "3px 10px",
        borderRadius: 9,
        fontWeight: 600,
        fontSize: 14,
        minWidth: 110,
        textAlign: "right",

    },
    wrapTargetHighLight: {
        background: '#fff',
        borderRadius: 15,
        position: "relative",
        padding: 15
    },
    selectDay: {
        display: "flex",
        alignItems: "center",
        "& > div:nth-child(1)": {
            marginRight: 10
        }
    },
    containerTargetHighLight: {
        marginBottom: 15,
        // maxHeight: 'calc(70vh - 200px)',
        // overflow: "auto"
    }
}))
