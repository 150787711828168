import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import store from 'store';

export const createThemeDefault = async (userId) => {
    try {
        if (!userId) return
        const batch = db.batch()
        const themeRef = db.doc(`${CALENDAR_COLLECTION}/${userId}/listTheme/1`)
        const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
        const data = {
            calendarLeftView: false,
            colorPrimary: "#1790FF",
            columns: 1,
            id: 1,
            rows: 1,
            rightExpansion: true,
            showTitle: true,
            size: 9,
            viewCalendar: "CustomMonth",
        }
        store.set("themeCustom", data)
        batch.set(themeRef, data, {merge: true})
        batch.set(userRef, {themeCustom: data}, {merge: true})
        await batch.commit()
        return data
    } catch (e) {
        console.log(e)
    }
}
