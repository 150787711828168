import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CirclePicker} from "react-color";
import useIntlId from '../../../hooks/useIntlId';

const colorsDefault = [
    '#FF1717',
    '#FF5C00',
    '#FF9900',
    '#E1CB02',
    '#7EE100',
    '#00E14D',
    '#00E1E1',
    '#1790FF',
    '#0029FF',
    '#7000FF',
    '#BE0072',
    '#BE0022',
    '#690003',
    '#634100',
    '#0E6300',
    '#002863',
    '#3D0063',
    '#292929',
    '#5C5C5C',
    '#939393'
]

function Theme({theme, setTheme}) {
    const [themeIntl] = useIntlId([
        'edit.workspace.theme'])
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.bgWhite}>
                <div className={classes.title}>{themeIntl}</div>
                <div className={classes.listColor}>
                    <CirclePicker
                        colors={colorsDefault}
                        color={theme.colorPrimary}
                        width={'100%'}
                        circleSize={19}
                        circleSpacing={7}
                        onChangeComplete={(color) => {
                            setTheme({...theme, colorPrimary: color.hex})
                        }}

                    />
                </div>
            </div>
        </div>
    );
}

export default Theme;
const useStyles = makeStyles(theme => ({
    root: {
        margin: "10px 0px 10px"
    },
    title: {
        fontWeight: 700,
        // backgroundColor: theme.props.primaryColor,
        color: `${theme.props.primaryColor}`,
        textTransform: "uppercase",
        textAlign: "center",
        padding: 5,
        // borderRadius: "5px",
        marginBottom: 7,
        borderBottom: "1px solid #D8D8D8"
    },
    bgWhite: {
        marginTop: 3,
        backgroundColor: "#fff",
        padding: 7,
        borderRadius: "7px"
    },
    listColor: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#fff",
        padding: "15px 0px 20px",
        "& .circle-picker": {
            display: 'flex',
            flexWrap: 'nowrap',
            rowGap: '20px',
            columnGap: '20px',
            maxWidth: 530,
            padding: '0 20px',
            marginRight: -7,
            marginLeft: -7,
            "& span": {
                width: 19,
                height: 19,
            }
        },
        // borderRadius: "7px"
    },
    listDays: {
        marginTop: 7
    },
    checked: {
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor
        }
    },
    hiddenEventAllDay: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 12,
        fontWeight: 700,
        backgroundColor: "#fff",
        borderRadius: 7,
        padding: "5px 10px",
        textTransform: "uppercase",
        margin: "10px 0",
    },
    btnSaveWorkSpace: {
        position: "relative",
        fontWeight: 700,
        textTransform: "uppercase",
        color: "#fff",
        border: "none",
        outline: "none",
        height: 37,
        borderRadius: "7px",
        backgroundColor: theme.props.primaryColor,
        minWidth: 300,
        '&:hover': {
            border: "none",
            outline: "none",
        },
        '&:focus': {
            border: "none",
            outline: "none",
        },
        "& svg": {
            position: "absolute",
            left: 5,
            top: "50%",
            marginTop: -15
        }
    },
    iconSave: {
        fill: theme.props.primaryColor,
    },

}))
