import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';


export const barRef = (userId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('announcement')
        .doc('bar')
}
export const boardRef = (userId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection('announcement')
        .doc('board')
}
