import {proxy} from 'valtio/vanilla';

const settingDialogProxy = proxy({
    open: false,
    tab: 0,
    handleOpen: (tab = 0) => {
        settingDialogProxy.open = true
        settingDialogProxy.tab = tab
    },
    handleClose: () => {
        settingDialogProxy.open = false
    }

})

export {settingDialogProxy}
