import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {Checkbox, Grid, IconButton, ListItem, ListItemText, TextField, withStyles} from '@material-ui/core';
import SelectColor from './SelectColor';
import GoogleSyncPopup from './GoogleSyncPopup';
import {CreateGoogleCalendar} from '../Calendar/actions/cal2';
import 'react-overlay-loader/styles.css';
import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextInputCustom from '../Custom/TextInputCustom';
import {cloneDeep, findIndex} from 'lodash';
import DialogCustom from '../Custom/DialogCustom';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import useIntlId, {getIntlId} from '../../hooks/useIntlId';
import {Spin} from 'antd';
import CancelButton from '../Dialog/CancelButton';
import SaveButton from '../Dialog/SaveButton';
import {msgError, msgSuccess} from '../../utils/msg';
import ButtonGroup from 'antd/es/button/button-group';
import styled from 'styled-components';
import {handleAddGoogleCalendarHoliday, handleDeleteGoogleCalendarHoliday} from '../../actions/googleTasks';
import {GCalendarList} from '../../actions/googleCalendar';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';


const styles = theme => ({
    root: {
        position: "relative",
    },
    dialogRoot: {
        zIndex: "1350 !important",
        "& .MuiPaper-rounded": {
            borderRadius: 14,
            padding: 20,
            backgroundColor: '#FFF'
        },
        '& .MuiPaper-root': {
            paddingBottom: "15px",
            boxSizing: "content-box",
            overflow: "unset",
        },
        '& .MuiDialogTitle-root': {
            padding: 3,
            textAlign: "right",
        },
        "& .MuiDialog-paperWidthSm": {
            minWidth: 600,
            maxWidth: "auto",
            borderRadius: 15,
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "auto",
            }
        }
    },
    content: {
        padding: "40px 40px 20px 40px",
    },
    formControl: {
        width: "100%",
        marginBottom: 10,
    },
    grow: {
        flexGrow: 1
    },
    margin: {
        margin: '0 5px',
        width: 130,
        color: '#3A3A3A',
        border: '1px solid #3A3A3A',
        backgroundColor: 'transparent',
    },
    error: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    selectColor: {
        width: 25,
        minWidth: 25,
        height: 25,
        borderRadius: '50%',
        padding: 0,
        border: '1px solid #D5D5D5',
    },
    btnClose: {
        margin: 4,
        width: 25,
        height: 25,
        minHeight: 0,
        backgroundColor: "transparent",
        boxShadow: "none",
        border: '1px solid #3A3A3A',
        position: "absolute",
        top: 5,
        right: 5,
        '&:hover': {
            backgroundColor: '#e91e63',
        },
    },
    btnAntd: {
        marginRight: 20,
        position: 'relative'
    },
    titleTooltip: {
        fontSize: '1rem',
        whiteSpace: 'nowrap'
    },
    titleText: {
        fontSize: "1.2rem",
        fontWeight: 'bold',
        color: theme.props.primaryColor,
        textTransform: 'uppercase'
    },
    textEdit: {
        width: '90%'
    },
    inputAdornment: {
        position: 'relative'
    },
    iconAdornment: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "-48px"
    },
    iconClose: {
        position: "absolute",
        right: -10,
        top: -10,
        background: "#fff",
        "&:hover": {
            background: "#fff",
        },
        "&:focus": {
            background: "#fff",
        },
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important'
    },
    textNotice: {
        width: '100%',
        display: "block",
        margin: "5px 0",
        fontSize: '1rem',
        padding: '5px 0px',
        textTransform: 'uppercase',
        color: theme.props.primaryColor
    },
    textNoticeError: {
        width: '100%',
        display: "block",
        margin: "5px 0",
        fontSize: '1rem',
        padding: '5px 0px',
        color: "red",
        textTransform: 'uppercase',
    },
    buttonActive: {
        backgroundColor: `${theme.props.primaryColor} !important`,
        '& div': {
            color: '#F1F1F1 !important'
        }
    },
    contentList: {
        width: '100%',
    }
})
const ButtonMenu = styled.button`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 30px;
    padding: 0 20px;
    height: 40px;
    margin: 0 0 20px 0;
    background: #F1F1F1;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
;
    border: none;
    color: #fff;
`

function AddProjectPopup(props) {

    const {classes, addProject, setAddProject} = props
    const {user, googleAuth} = useSnapshot(userProxy)
    const {setGoogleProjects, googleProjects} = useSnapshot(projectsProxy)
    const [isEnterPress] = useKeyboardJs('enter')
    const lang = user?.language.slice(0, 2) || 'ja'
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [tab, setTab] = useState(1)
    const [list, setList] = useGlobal('googleCalendarList')
    const [values, setValues] = useState({
        projectColor: '#e91e63',
        projectName: ""
    })

    const langHoliday = {ja: 'japanese#holiday', vi: 'vietnamese#holiday'}

    const [AddCalendar, projectName, addCalendarHoliday, intHolidaysVn, intHolidaysJa] = useIntlId(['leftHomeApp.AddCalendar', 'calendar.create.projectName', 'leftHomeApp.addCalendarHoliday', 'leftHomeApp.holidayVn', 'leftHomeApp.holidayJa'])
    const calendarId = [
        {summary: intHolidaysJa, id: lang + '.japanese#holiday@group.v.calendar.google.com'},
        {summary: intHolidaysVn, id: lang + '.vietnamese#holiday@group.v.calendar.google.com'}
    ]
    const [listHoliday, setListHoliday] = useState([])

    useEffect(() => {
        if (!isEnterPress)
            return;
        if (!values || !values.projectName) {
            document.getElementById("projectNameInput")?.focus()
            return
        }
        handleAdd(values, false)
        // eslint-disable-next-line
    }, [isEnterPress])

    function handleSelectColor(event) {
        let _event = event && event.currentTarget
        if (!event) {
            _event = document.getElementById('G_color')
        }
        setAnchorEl(_event)
    }

    const handleClose = () => {
        setValues({
            projectColor: '#e91e63',
            categoryName: 'Default',
        })
        setAddProject(false)
    }
    const handleChange = name => event => {
        if (name === 'code') {
            if (event.target.value.length < 4) {
                setValues({...values, [name]: event.target.value})
            }
        } else {
            setValues({...values, [name]: event.target.value})
        }
    }


    const handleAdd = async (values, is_sync = false) => {
        if (!user?.user_id)
            return;
        setLoading(true);

        if (loading) return;
        if (!values) return;
        const {projectName, projectColor} = values;
        let newProjectName = projectName.trim();
        if (newProjectName !== "") {
            try {
                let _project;
                const uid = "pro_" + uuidv4();
                const time = moment().utc().format();
                if (is_sync && googleAuth?.email) {
                    const data = await CreateGoogleCalendar(newProjectName, projectColor);
                    if (!data)
                        return msgError("Create google calendar fail");
                    const nextList = cloneDeep(googleProjects)
                    nextList.push(data)
                    setGoogleProjects(nextList)
                    msgSuccess(getIntlId("app.setting.success"));
                } else {
                    _project = {
                        id: uid,
                        uuid: uid,
                        name: newProjectName,
                        color: projectColor,
                        is_deleted: false,
                        created_at: time,
                        updated_at: time,
                        is_sync: false,
                        selected: true
                    };
                    await db
                        .collection(CALENDAR_COLLECTION)
                        .doc(user.user_id)
                        .collection("projects")
                        .doc(_project.id)
                        .set(_project);
                    msgSuccess(getIntlId("app.setting.success"));
                }
            } catch (e) {
                console.log(e.toString());
                msgError(e.toString());
            } finally {
                setAddProject(false);
            }
        } else {
            msgError("The Project Name field cannot be empty!");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (list.length) {
            let holidayJa = list.filter(item => item.id.includes(langHoliday.ja))
            let holidayVn = list.filter(item => item.id.includes(langHoliday.vi))
            if (holidayJa.length > 0 && holidayVn.length > 0) {
                holidayJa[0].checked = true
                holidayVn[0].checked = true
                setListHoliday([holidayJa[0], holidayVn[0]])
                return;
            }
            if (holidayJa.length > 0 && holidayVn.length === 0) {
                holidayJa[0].checked = true
                calendarId[1].checked = false
                setListHoliday([holidayJa[0], calendarId[1]])
                return;
            }
            if (holidayJa.length === 0 && holidayVn.length > 0) {
                holidayVn[0].checked = true
                calendarId[0].checked = false
                setListHoliday([calendarId[0], holidayVn[0]])
                return;
            }
            setListHoliday(calendarId)
        }
        // eslint-disable-next-line
    }, [list])

    const handleChangeChecked = id => async (e) => {
        setLoading(true)
        let idx = findIndex(listHoliday, {'id': id})
        if (idx !== -1) {
            if (!listHoliday[idx].checked) {
                await handleAddGoogleCalendarHoliday(id, user.user_id)
            } else {
                await handleDeleteGoogleCalendarHoliday(id, user.user_id)
            }
        }
        await GCalendarList()
        await setLoading(false)
    }

    return (
        <DialogCustom open={addProject} onClose={handleClose}
                      className={classes.dialogRoot}
        >
            <div>
                <Spin spinning={loading} tip={'Loading...'} delay={500}>
                    <Grid container justifyContent={"center"} className={classes.root}>
                        <ButtonGroup variant="contained" size='large' className={"gap-4"}>
                            <ButtonMenu
                                className={`${tab === 1 ? classes.buttonActive : ''}`}
                                onClick={() => setTab(1)}
                            >
                                <div className={classes.titleText}>
                                    {AddCalendar}
                                </div>
                            </ButtonMenu>
                            {googleAuth?.email &&
                                <ButtonMenu
                                    className={`${tab === 2 ? classes.buttonActive : ''} ml-1`}
                                    onClick={() => setTab(2)}
                                >
                                    <div className={classes.titleText}>
                                        {addCalendarHoliday}
                                    </div>
                                </ButtonMenu>
                            }
                        </ButtonGroup>
                        {
                            tab === 1 ?
                                <>
                                    <TextInputCustom
                                        name={projectName}
                                        contentName={values?.projectName ? values.projectName : ''}
                                        isEditComponent={true}
                                        titleMaxWidth={140}
                                        marginBottom={20}
                                        editComponent={
                                            <div className={`clf-flex-center ${classes.textEdit}`}>
                                                <TextField
                                                    autoFocus={false}
                                                    value={values?.projectName ? values.projectName : ''}
                                                    onChange={handleChange('projectName')}
                                                    fullWidth
                                                    id={"projectNameInput"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end"
                                                                            className={classes.inputAdornment}>
                                                                <IconButton
                                                                    id={'G_color'}
                                                                    aria-owns={Boolean(anchorEl) ? 'Geniam-appbar' : undefined}
                                                                    onClick={handleSelectColor}
                                                                    aria-haspopup="true"
                                                                    className={classes.iconAdornment}
                                                                >
                                                                    <div className={classes.selectColor}
                                                                         style={{backgroundColor: values.projectColor}}/>
                                                                </IconButton>
                                                                <SelectColor
                                                                    anchorEl={anchorEl}
                                                                    setAnchorEl={setAnchorEl}
                                                                    setValues={setValues}
                                                                    values={values}
                                                                />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </div>}
                                    />
                                    <div className={'clf-flex-center'}>
                                        <CancelButton onClick={handleClose}/>
                                        <SaveButton
                                            disable={!values.projectName}
                                            loading={loading}
                                            onClick={
                                                () => {
                                                    if (!values.projectName)
                                                        return;
                                                    if (googleAuth?.email) {
                                                        setOpen(true)
                                                        return;
                                                    }
                                                    handleAdd(values, false)
                                                }}
                                        />
                                    </div>
                                </>
                                :
                                <div className={classes.contentList}>
                                    {listHoliday.map((calendar, index) => {
                                        return (
                                            <ListItem key={index} button onClick={handleChangeChecked(calendar.id)}
                                                      className={classes.p0}>
                                                <Checkbox
                                                    checked={calendar.checked}
                                                />
                                                <ListItemText primary={calendar.summary}/>
                                            </ListItem>
                                        )
                                    })}
                                </div>


                        }
                    </Grid>
                    <GoogleSyncPopup open={open} setOpen={setOpen} values={values} onAdd={handleAdd}/>
                </Spin>
            </div>
        </DialogCustom>
    )
}

export default withStyles(styles)(AddProjectPopup)
