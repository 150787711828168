import {setGlobal} from "reactn";
import {db} from "../../config/firebaseConfig";
import store from 'store'
import {snapshot} from 'valtio';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {userProxy} from '../../store/proxy/user.proxy';

const googleStoredKey = ["LChannel", "GChannel", "calendarSelected", "googleAccessToken", "googleUser", "googleCalendars", "ggToken", "GGList", "ggEvents", "googleAuth"]
export const removeStorageGoogleUser = () => {
    googleStoredKey.forEach(key => {
        store.remove(key)
    })
}
export const RemoveGoogleCalendar = () => {
    const {setGoogleProjects} = snapshot(projectsProxy)
    setGoogleProjects([])
    setGlobal({
        googleCalendarList: []
    })
}

export const onLogOut = () => {
    const {setGoogleProjects} = snapshot(projectsProxy)
    const {setGoogleAuth, googleAuth} = snapshot(userProxy)
    try {
        const email = googleAuth?.email
        RemoveGoogleCalendar()
        removeStorageGoogleUser()
        setGoogleProjects([])
        removeStoredToken(email)
        setGoogleAuth(null)
        window.gapi.auth2.getAuthInstance().disconnect()
    } catch (e) {

    }
}
const removeStoredToken = async (email) => {
    try {
        if (!email)
            return
        // remove prod
        const batch = db.batch()
        const prodSnap = await db.collection("calendar")
            .where("googleAuth.email", "==", email)
            .get()
        prodSnap.docs.forEach(doc => {
            const docRef = db.doc(`calendar/${doc.id}`)
            batch.set(docRef, {
                googleAuth: null,
                listGgCalenviewShow: null,
                idCalenderHidden: null,
            }, {merge: true})
        })
        const stgSnap = await db.collection("calendar-stg")
            .where("googleAuth.email", "==", email)
            .get()
        stgSnap.docs.forEach(doc => {
            const docRef = db.doc(`calendar-stg/${doc.id}`)
            batch.set(docRef, {
                googleAuth: null,
                listGgCalenviewShow: null,
                idCalenderHidden: null,
            }, {merge: true})
        })
        await batch.commit()

    } catch (e) {
        console.log(e);
    }
}
