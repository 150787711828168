// import {GOOGLE_CALENDAR_WATCH} from "../config/apiUrls"
import {chunk, cloneDeep, concat, findIndex, get, isArray, remove} from 'lodash';
import moment from 'moment';
import {getGlobal, setGlobal, useGlobal} from 'reactn';
import {ggTokenChecked} from '../config/auth';
import updateWatchChannelMyself from '../common/updateWatchChannelMyself';
import momentTz from 'moment-timezone';
import store from 'store';
import {myEventsCollRef, myProjectDocRef} from '../common/firebaseRef/meetings';
import {refetchSource} from './refetchSourcce';
import {removeStorageGoogleUser} from '../common/googleCal/logOutGoogle';
import {useDeferredValue, useEffect} from 'react';
import {msgError} from '../utils/msg';
import {myCalendarRef, myEventRef} from '../common/firebaseRef/calendar';
import {getIntlId} from '../hooks/useIntlId';
import {zoneObj} from '../utils/time';
import {checkDifferenceArr} from '../common/calendarView/calInView';
import {getRrule} from '../common/rrule';
import {snapshot, useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';
import {projectsProxy} from '../store/proxy/projects.proxy';
import {eventsProxy} from '../store/proxy/events.proxy';
import {announcementProxy} from '../store/proxy/announcement.proxy';

export const RefreshGoogleToken = async () => {
    try {
        const res = await window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
        localStorage.setItem('googleAccessToken', res.access_token)
        return res.access_token
    } catch (e) {
        return null
    }
}

// const SetListWatch = (nextSyncToken) => {
//     const token = localStorage.getItem('googleAccessToken')
//     const request = window.gapi.client.calendar.calendarList.watch({
//         "id": v4(),
//         token,
//         "type": "web_hook",
//         "address": GOOGLE_CALENDAR_WATCH
//     })
//     request.then(onFulfilled => {
//             setGlobal({LChannel: {...onFulfilled.result, nextSyncToken}})
//             localStorage.setItem('LChannel', JSON.stringify({...onFulfilled.result, nextSyncToken}))
//         }, onRejected =>
//             console.log(onRejected)
//     )
// }

// export const GetChannelWatch = (calendar_id) => {
//     const {GChannel} = getGlobal()
//     let idx = findIndex(GChannel, {"calendar_id": calendar_id})
//
//     if (idx !== -1 && moment().add(1, "day").isBefore(parseInt(GChannel[idx].expiration))) {
//
//         return
//     }
//
//     const request = window.gapi.client.calendar.events.watch({
//         "calendarId": calendar_id,
//         "resource": {
//             "id": v4(),
//             "type": "web_hook",
//             "token": `userId=${getGlobal().user.user_id}&session=${v4()}`,
//             "address": GOOGLE_CALENDAR_WATCH,
//         }
//     })
//     request.then(onFulfilled => {
//         const {result} = onFulfilled
//         if (idx !== -1) {
//             GChannel[idx] = {...result, calendar_id: calendar_id}
//         } else {
//             GChannel.push({...result, calendar_id: calendar_id})
//         }
//         setGlobal({GChannel: uniqBy(GChannel, 'calendar_id')})
//         localStorage.setItem('GChannel', JSON.stringify(GChannel))
//     }, onRejected => {
//         // console.log(onRejected);
//     })
//
// }

// export const getChannelWatches = list => {
//     let next = [...list]
//     remove(next, g => {
//         return g.id.includes('#contacts@group.v.calendar.google.com') || g.id.includes('#holiday@group.v.calendar.google.com')
//     })
//     if (next.length !== 0) {
//         next.forEach(n => {
//             GetChannelWatch(n.id)
//         })
//     }
// }
export const useGGStatus = (data) => {
    // check user is sync with google
    const [googleLibInit] = useGlobal('googleLibInit')
    const {googleAuth} = useSnapshot(userProxy)
    const {googleProjects} = useSnapshot(projectsProxy)

    const deferredGoogleProjects = useDeferredValue(googleProjects)
    useEffect(() => {
        if (!googleLibInit || !googleAuth) {
            return;
        }
        if (googleAuth) {
            store.set("googleAuth", googleAuth)
            GCalendarList()
        } else {
            refetchSource('google')
            removeStorageGoogleUser()
        }
        // eslint-disable-next-line
    }, [googleAuth, googleLibInit])

    useEffect(() => {
        refetchSource('google')
    }, [deferredGoogleProjects])
}

export const GCalendarList = async () => {
    const {googleAuth} = snapshot(userProxy)
    const {setGoogleProjects} = snapshot(projectsProxy)
    if (googleAuth) {
        try {
            const checked = await ggTokenChecked()
            if (!checked)
                return
            const request = window.gapi.client.calendar.calendarList.list()
            const {status, result} = await request.then()
            if (status !== 200) {
                return
            }
            let groups = result.items
            remove(groups, g => {
                return g.id.includes('#contacts@group.v.calendar.google.com')
            })
            setGoogleProjects(groups)
        } catch (e) {
            setGoogleProjects([])
        }
    } else {
        console.log('No GGtoken be find')
        setGoogleProjects([])
    }
}

const setListCalendar = (list) => {
    setGlobal({
        googleCalendarList: list
    }, () => {
        refetchSource('google')
        refetchSource('geniam')
    })
}

const setListShow = async (calendarUser, listShow, newList, numOfCal) => {
    let storeGGList = await store.get("GGList")
    const arrDiffList = checkDifferenceArr(newList, storeGGList)
    if (arrDiffList.length > 0) {
        if (newList.length > storeGGList.length) {
            arrDiffList.forEach((val, i) => {
                let idx = findIndex(listShow, {id: val.id})
                if (idx === -1 && listShow.length < numOfCal) {
                    listShow.push({
                        id: val.id,
                        checkbox: true,
                        check: true,
                        index: i + 1 + listShow.length,
                    })
                }
            })
        } else {
            arrDiffList.forEach((val) => {
                remove(listShow, q => q.id === val.id)
            })
        }
        await store.set("GGList", newList)
        await setGlobal({
            googleCalendarList: newList,
            calendarUser: {...calendarUser, listGgCalenviewShow: listShow}
        })
        // await updateListGgCalenviewShow(listShow)
    }
    return listShow
}


export const setNewLists = async () => {
    const {calendarUser,} = snapshot(userProxy)
    const {googleProjects} = snapshot(projectsProxy)
    const {plan} = snapshot(announcementProxy);
    const {listGgCalenviewShow = []} = calendarUser
    let listShow = cloneDeep(listGgCalenviewShow)
    if (!googleProjects.length) return
    let _googleProjects = cloneDeep(googleProjects)
    const getLocalCheckedSate = (_googleProjects, listShow) => {
        return _googleProjects.map(proj => {
            let idx = findIndex(listShow, {id: proj.id})
            return {
                ...proj, check: idx === -1 ? undefined : listShow[idx].check
            }
        })

    }
    //1. list show not defind
    if (!listShow?.length) {
        const nextList = (chunk(_googleProjects, plan.numOfCal)[0])?.map(item => ({id: item.id, check: true}))
        await updateListGgCalenviewShow(nextList)
        setListCalendar(getLocalCheckedSate(_googleProjects, nextList))
        return _googleProjects
    }
    let isDownpackage = false
    // downgrade package
    if (listShow.length > plan.numOfCal) {
        listShow = chunk(listShow, plan.numOfCal)[0]
        isDownpackage = true
    }
    //2. compare save list & google return list
    //2.1 remove old project deleted
    const deleted = remove(listShow, item => findIndex(_googleProjects, {id: item.id}) === -1)
    let updateNeeded = (listShow.length < plan.numOfCal && listShow.length !== _googleProjects.length) || isDownpackage
    if (updateNeeded) {
        _googleProjects.forEach((proj) => {
            let idx = listShow.findIndex(item => item.id === proj.id)
            if (idx === -1 && listShow.length < plan.numOfCal) {
                listShow.push({id: proj.id, check: true})
            }
        })
    }
    if (deleted.length || updateNeeded) {
        await updateListGgCalenviewShow(listShow)
        setListCalendar(getLocalCheckedSate(_googleProjects, listShow))
        return _googleProjects
    }
    setListCalendar(getLocalCheckedSate(_googleProjects, listShow))
    return _googleProjects
}

export const updateListGgCalenviewShow = async (list) => {
    return await myCalendarRef()
        .set({
            listGgCalenviewShow: list
        }, {merge: true})
}

export const getGoogleEventsInstance = async ({project, iCalUID}) => {
    await ggTokenChecked()
    const request = window.gapi.client.calendar.events.list({
        calendarId: project,
        iCalUID,
        maxResults: 2500
    })
    const {result: {items}} = await request.then()
    return items
}

export const getGeniamEventsInstance = async ({eventId}) => {
    const evtRef = myEventRef(eventId)
    const snap = await evtRef.get()
    if (snap.exists) {
        const data = snap.data()
        // get edited
        const editSnap = await myEventsCollRef()
            .where('recurringEventId', '==', eventId)
            .get()
        let edited = []
        if (editSnap.size) {
            edited = editSnap.docs.map(doc => ({...doc.data(), id: doc.id}))
        }
        return {
            data,
            edited
        }
    }
    return null
}
// export const GCalendarEvents = async (id, color, start, end) => {
//     // const { syncToken = []} = getGlobal()
//     const max = 2500
//     let result = [], token = null
//     try {
//         let encode_id
//         let holiday = false
//         if (id.includes('#holiday')) {
//             encode_id = encodeURIComponent(id)
//             holiday = true
//         } else {
//             encode_id = id
//         }
//         const request = window.gapi.client.calendar.events.list({
//             'calendarId': id,
//             "singleEvents": true,
//             "timeMin": start,
//             "timeMax": end,
//             maxResults: max
//         })
//         const data = await request.then()
//         console.log(data)
//         // const res = await axios.get(GET_GOOGLE_CALENDAR_EVENTS + `/${encode_id}/events?timeMin=${start}&timeMax=${end}&maxResults=${max}&singleEvents=true`)
//         result = GCalendarEventFormat(data.result.items, id, color, holiday)
//         token = data.result.nextSyncToken
//
//     } catch (e) {
//         console.log(e)
//     }
//
//     return {events: result, token: token}
//
// }
//
// export const ListCalendar = async () => {
//     const {calendarSelected} = getGlobal()
//     let googleAccessToken = localStorage.getItem('googleAccessToken')
//     if (googleAccessToken) {
//         try {
//             const res = await axios.get(GET_GOOGLE_CALENDAR_LIST)
//             if (res.data && res.data.items && res.data.items.length !== 0) {
//                 let resuit = []
//                 let arr = res.data.items
//                 remove(arr, g => {
//                     return g.id.includes('#contacts@group.v.calendar.google.com')
//                 })
//                 arr.forEach(cal => {
//                     let index = findIndex(calendarSelected, {"id": cal.id})
//                     resuit.push({...cal, checked: index === -1 ? false : true})
//                 })
//                 setGlobal({googleCalendarList: resuit})
//             }
//         } catch (e) {
//             console.log(e)
//         }
//     }
// }

export const GCalendarEventFormat = (events, group_uuid, color, holiday = false, isPrimary) => {
    let result = []
    events.forEach(e => {
        let event = eventGoogleFormat(e, group_uuid, color, holiday, isPrimary)
        if (event)
            result.push(event)
    })
    return result
};
export const eventGoogleFormat = (e, group_uuid, color, holiday = false, isPrimary) => {
    const {GoogleColor} = getGlobal()
    const {googleAuth} = snapshot(userProxy)
    if (e.start && e.end) {
        if (!holiday && isPrimary && e?.creator?.email !== googleAuth?.email) {
            holiday = true
        }
        let allDay = !!e.start.date
        let start = e.start.dateTime || e.start.date
        let end = e.end.dateTime || e.end.date
        let resourceId = allDay ? group_uuid : null
        let title = removeEmoji(e.summary, e?.extendedProperties?.private?.task || null).trim(" ")
        let isEventVote = e?.extendedProperties?.private?.isEventVote === 'true' || Boolean(e?.extendedProperties?.private?.meetingId) || Boolean(e?.extendedProperties?.private?.shareUrl)
        let event = {
            ...e,
            id: e.id,
            uuid: e.id,
            start,
            end,
            title: title || (holiday ? "busy" : '(no title)'),
            colorId: e?.colorId ? Number(e.colorId) : null,
            backgroundColor: e?.colorId ? (GoogleColor[e.colorId - 1] || color) : color,
            borderColor: color,
            allDay,
            resourceId,
            duration: (moment(end) - moment(start)) / (60000 * 60), // minutes
            calendar_uuid: group_uuid,
            project_uuid: group_uuid,
            googleEvent: true,
            editable: !holiday,
            draggable: !holiday,
            holiday: holiday,
            task: e?.extendedProperties && e.extendedProperties?.private?.task ? e.extendedProperties.private.task : null,
            isSuccess: e?.extendedProperties && e?.extendedProperties?.private?.is_success ? Boolean(parseInt(e.extendedProperties.private.is_success)) : false,
            pro_uuid: e?.extendedProperties?.private?.project_uuid || null,
            isMileStone: Boolean(e?.extendedProperties?.private?.task === "mileStone"),
            isEventVote: isEventVote,
            linkZoom: e?.extendedProperties && e?.extendedProperties?.private?.linkZoom ? e.extendedProperties.private.linkZoom : null,
            zoomMeetingId: e?.extendedProperties && e?.extendedProperties?.private?.zoomMeetingId ? e.extendedProperties.private.zoomMeetingId : null,
            meetingId: e?.extendedProperties?.private?.meetingId || null,
            scheduleId: get(e, 'extendedProperties.private.scheduleId', null),
            isMilestoneHide: Boolean(e?.extendedProperties?.private?.isMilestoneHide === "true"),
            isMilestoneNextMonth: Boolean(e?.extendedProperties?.private?.isMilestoneNextMonth === "true"),
            conferenceData: e?.conferenceData || null,
            googleTasksID: e?.extendedProperties?.private?.googleTasksID || null,
            email: e?.extendedProperties?.private?.email || '',
            emailVoter: e?.extendedProperties?.private?.emailVoter || '',
            name: e?.extendedProperties?.private?.name || '',
            timeBefore: e?.extendedProperties?.private?.timeBefore || 0,
            timeAfter: e?.extendedProperties?.private?.timeAfter || 0,
        }
        event.recurrence = getRrule(event.recurrence)
        if (e.defaultReminders) {
            event.defaultReminders = e.defaultReminders
        }
        if (event.isEventVote && event.meetingId) {
            event.isAccepted = true
        }
        return event
    }
    return null
}

const removeEmoji = (title, task) => {
    const {emojis} = getGlobal()
    let result = title || ""
    if (task && title) {
        let index = findIndex(emojis, {'name': task})
        if (index !== -1) {
            result = title.split(emojis[index].emoji)[0]
        }
    }
    return result
}
const getEmoji = (task) => {
    const {emojis} = getGlobal()
    let result = ''
    let index = findIndex(emojis, {'name': task})
    if (index !== -1) {
        result = emojis[index].emoji
    }
    return result
}
export const RemoveGoogleCalendar = () => {
    const {groups} = getGlobal()
    let nextGroups = [...groups]
    remove(nextGroups, g => {
        return g.is_google === true
    })
    setGlobal({groups: nextGroups})

}

export const AddGoogleEvent = async (event) => {
    try {
        await ggTokenChecked()
        let calendarId = event.project_uuid
        let data = parseResource(event)
        const request = window.gapi.client.calendar.events.insert({
            'calendarId': calendarId,
            'resource': data,
            'conferenceDataVersion': 1,
            'sendUpdates': 'all'
        })
        let {result} = await request.then();
        updateWatchChannelMyself(calendarId).then()
        return result
    } catch (e) {
        console.log(e);
        return null
    }
};

export const updateGoogleEvent = async (event, id, ignoreTime = false) => {
    let data = parseResource(event, ignoreTime)
    try {
        await ggTokenChecked()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": id,
            "resource": data,
            'conferenceDataVersion': 1,
            'sendUpdates': 'all'
        });

        const {result} = await request.then();

        updateWatchChannelMyself(event.project_uuid)
        return result
    } catch (e) {
        console.log(e);
        msgError(getIntlId('event.toast.updateEventError'))
        return null
    }
}

export const changeProjectGoogleEvent = (eventData, oldProject, newProject) => {
    const {googleEvents, setGoogleEvents} = snapshot(eventsProxy)
    let _googleEvents = cloneDeep(googleEvents)
    const events = _googleEvents[oldProject]?.events || []
    const idEvent = eventData.recurringEventId || eventData.id
    const instances = remove(events, e => e.id === idEvent || e.recurringEventId === idEvent)
    const destination = concat(_googleEvents[newProject]?.events || [], instances)
    _googleEvents[oldProject].events = events
    _googleEvents[newProject].events = destination
    setGoogleEvents(_googleEvents)
}

export const changeTimeGoogleEvent = async (event) => {
    let data = parseResource(event)
    try {
        await ggTokenChecked()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": event.id,
            "resource": {
                start: data.start,
                end: data.end
            },
            "sendNotifications": true,
            "conferenceDataVersion": 1
        });

        const {result} = await request.then()
        updateWatchChannelMyself(event.project_uuid)
        // console.log('update ok')
        return result
    } catch (e) {
        // msgError("UPDATE GOOGLE EVENT ERROR ", {autoClose: 2000})
        return null
    }
}
export const getColorId = (color) => {
    let id = null
    if (color) {
        const {GoogleColor} = getGlobal()
        GoogleColor.forEach((value, index) => {
            if (value.toLowerCase() === color.toLowerCase()) {
                id = index + 1
            }
        })
    }
    return id
};

export const parseResource = (event, ignoreTime = false) => {
    let emoji = getEmoji(event.task)
    if (event.colorId && event.colorId > 11)
        event.colorId = null
    // event.isCopy = event?.isCopy || false;
    // console.log("Event Is Copy:",event?.isCopy)
    let data = {
        "summary": event.title + emoji,
        "colorId": event.colorId || getColorId(event.backgroundColor),
        "extendedProperties": {
            "private": {
                "task": event.task || '',
                "is_success": event.isSuccess ? 1 : 0,
                "meetingId": event?.meetingId || "",
                "isMilestoneHide": event?.isMilestoneHide ? 'true' : 'false',
                "isMilestoneNextMonth": event?.isMilestoneNextMonth ? 'true' : 'false',
                "linkZoom": event?.linkZoom || '',
                "zoomMeetingId": event?.zoomMeetingId || '',
                "googleTasksID": event?.googleTasksID || '',
                "scheduleId": event?.scheduleId || '',
                "isEventVote": event?.isEventVote ? 'true' : 'false',
                "email": event?.email || '',
                "emailVoter": event?.emailVoter || '',
                "name": event?.name || '',
                "timeBefore": event?.timeBefore || 0,
                "timeAfter": event?.timeAfter || 0
            }
        },
        "description": event.description || '',
        "location": event.location || '',
        "reminders": {}
    };
    if (event.attendees && isArray(event.attendees)) {
        data.attendees = event.attendees
    }
    if (event?.defaultReminders && event?.defaultReminders.length) {
        data.reminders.useDefault = false;
        data.reminders.overrides = event.defaultReminders || [];
    }
    if (event.allDay) {
        let startDate = zoneObj(event.start).format('YYYY-MM-DD')
        let endDate = zoneObj(event.end).format('YYYY-MM-DD')
        data.start = {
            date: startDate,
            dateTime: null
        }
        data.end = {
            date: endDate !== startDate ? endDate : moment(event.end).add(1, "day").format('YYYY-MM-DD'),
            dateTime: null
        }
    } else {
        let startDate = moment(event.start).format()
        let endDate = moment(event.end).format()
        data.start = {
            dateTime: startDate,
            date: null
        }
        data.end = {
            dateTime: endDate,
            date: null
        }
    }

    // recurring
    if (event.recurrence) {
        data.recurrence = Array.isArray(event.recurrence) ? event.recurrence : [event.recurrence]
        const timeZone = momentTz.tz.guess() || 'Asia/Tokyo';
        if (event.allDay) {
            data.start.timeZone = null
            data.end.timeZone = null
        } else {
            data.start.timeZone = timeZone
            data.end.timeZone = timeZone
        }
    } else {
        data.recurrence = null
    }
    // check if create new Zoom meeting then add to google event Zoom meeting data, not old
    if (event.conferenceData && event.conferenceData.join_url) {
        let zoomValue = event.conferenceData;
        data.conferenceData = {
            "entryPoints": [
                {
                    "entryPointType": "video",
                    "uri": zoomValue.join_url,
                    "label": zoomValue.join_url,
                    "meetingCode": zoomValue.id,
                    "password": zoomValue.password
                }
            ],
            "conferenceSolution": {
                "key": {
                    "type": "addOn"
                },
                "name": "Zoom Meeting",
                "iconUri": "https://lh3.googleusercontent.com/ugWKRyPiOCwjn5jfaoVgC-O80F3nhKH1dKMGsibXvGV1tc6pGXLOJk9WO7dwhw8-Xl9IwkKZEFDbeMDgnx-kf8YGJZ9uhrJMK9KP8-ISybmbgg1LK121obq2o5ML0YugbWh-JevWMu4FxxTKzM2r68bfDG_NY-BNnHSG7NcOKxo-RE7dfObk3VkycbRZk_GUK_1UUI0KitNg7HBfyqFyxIPOmds0l-h2Q1atWtDWLi29n_2-s5uw_kV4l2KeeaSGws_x8h0zsYWLDP5wdKWwYMYiQD2AFM32SHJ4wLAcAKnwoZxUSyT_lWFTP0PHJ6PwETDGNZjmwh3hD6Drn7Z3mnX662S6tkoPD92LtMDA0eNLr6lg-ypI2fhaSGKOeWFwA5eFjds7YcH-axp6cleuiEp05iyPO8uqtRDRMEqQhPaiRTcw7pY5UAVbz2yXbMLVofojrGTOhdvlYvIdDgBOSUkqCshBDV4A2TJyDXxFjpSYaRvwwWIT0JgrIxLpBhnyd3_w6m5My5QtgBJEt_S2Dq4bXwCAA7VcRiD61WmDyHfU3dBiWQUNjcH39IKT9V1fbUcUkfDPM_AGjp7pwgG3w9yDemGi1OGlRXS4pU7UwF24c2dozrmaK17iPaExn0cmIgtBnFUXReY48NI8h2MNd_QysNMWYNYbufoPD7trSu6nS39wlUDQer2V"
            },
            "conferenceId": zoomValue.id,
            "notes": "Passcode: " + zoomValue.password,
            "parameters": {
                "addOnParameters": {
                    "parameters": {
                        "realMeetingId": zoomValue.id,
                        "meetingCreatedBy": zoomValue.host_email,
                        "meetingUuid": zoomValue.uuid,
                        "meetingType": zoomValue.type
                    }
                }
            }
        }
    } else if (event.conferenceData && event.conferenceData.conferenceId) {
        data.conferenceData = event.conferenceData
    } else
        data.conferenceData = '';
    // console.log({data});
    if (ignoreTime) {
        delete data.start
        delete data.end
    }
    return data
}
const getEventForSync = async (uuid) => {
    try {
        // get all events of project
        const snapEvents = await myEventsCollRef()
            .where("project_uuid", "==", uuid)
            .where("isTasks", "==", false)
            .where("isDeleted", "==", false)
            .get()
        // const events = await axios.get(CAL_API + `/event/eventForSync?project=${uuid}`)
        return snapEvents.docs.map(doc => ({...doc.data(), id: doc.id}))
    } catch (e) {
        console.log(e)
        return null
    }
}
const updateProjectSync = async (projectId, googleCalendarId, email) => {
    return await myProjectDocRef(projectId)
        .set({
            sync_id: googleCalendarId,
            sync_email: email,
            is_sync: true
        }, {merge: true})
}
export const Sync = async uuid => {
    const {geniamProjects} = snapshot(projectsProxy)
    const {googleAuth} = snapshot(userProxy)
    let idx = findIndex(geniamProjects, {'uuid': uuid})
    if (idx !== -1) {
        try {
            setGlobal({insync: true});
            setGlobal({syncProcess: 'Create Calendar '});
            const backgroundColor = geniamProjects[idx].color;
            const eventList = await getEventForSync(geniamProjects[idx].id || geniamProjects[idx].uuid);
            await ggTokenChecked();
            let request = window.gapi.client.calendar.calendars.insert({
                summary: geniamProjects[idx].name
            });
            let data = await request.then();
            if (data.status !== 200) {
                msgError(getIntlId('event.toast.createGoogleCalendar'))
                return
            }
            const calendarId = data.result.id;
            request = window.gapi.client.calendar.calendarList.patch({
                "calendarId": calendarId,
                "colorRgbFormat": true,
                backgroundColor
            });

            setGlobal({syncProcess: 'Update calendar'})

            data = await request.then()
            if (data.status !== 200) {
                msgError(getIntlId('event.toast.colorFail'))
                return
            }
            // sync all events to google
            setGlobal({syncProcess: 'Sync events'})
            if (eventList.length === 0) {
                await updateProjectSync(geniamProjects[idx].uuid, calendarId, googleAuth && googleAuth.email ? googleAuth.email : null)
                setGlobal({syncProcess: 'complete...', insync: false})
                return
            }
            const batch = window.gapi.client.newBatch()
            eventList.forEach(event => {
                let data = parseResource(event);
                let request = window.gapi.client.calendar.events.insert({
                    'calendarId': calendarId,
                    'resource': data
                })
                batch.add(request)
            })
            const {status} = await batch.then()
            if (status === 200) {
                await updateProjectSync(geniamProjects[idx].uuid, calendarId, googleAuth && googleAuth.email ? googleAuth.email : null)
                await setGlobal({syncProcess: 'remove geniam calendar'})
                // globalCallback update status
                // UpdateSync(uuid, calendarId, googleAuth.email, data.result)
                // GCalendarList()
                setGlobal({syncProcess: 'complete...', insync: false})
                return
            }
            setGlobal({insync: false})
            msgError(getIntlId('milestone.syncProject.fail'))
        } catch (e) {
            console.log(e);
            setGlobal({insync: false})
            msgError(getIntlId('milestone.syncProject.fail'))
        } finally {
            GCalendarList()
        }
    }
}


export const checkedCalendar = async (calendarId, info) => {
    try {
        // console.log("info", info);
        const request = window.gapi.client.calendar.calendarList.update({
            "calendarId": calendarId,
            "summaryOverride": info.summaryOverride,
            "selected": info.selected
        })
        // console.log('request', request);
        const {status, result} = await request.then()
        if (status !== 200) {
            console.log(result)
            msgError(getIntlId('calendar.checkCal.fail'))
            return
        }
        return result
    } catch (e) {
        console.log(e);
    }
}

export const getAllPrj = () => {
    const {geniamProjects, googleProjects} = snapshot(projectsProxy)
    const {calendarUser} = snapshot(userProxy);
    const {idCalenderHidden = []} = cloneDeep(calendarUser);
    const {googleCalendarList} = getGlobal()

    let ge = cloneDeep(geniamProjects).filter(q => q.selected)
    let ggo = cloneDeep(googleProjects)
    let gg = googleCalendarList.filter(q => q.check)
    let newGg = gg.filter(q => !idCalenderHidden?.includes(q.id));
    newGg.forEach((q, ind) => {
        let index = ggo.findIndex(g => g.id === q.id)
        q.accessRole = ggo[index].accessRole
        q.color = ggo[index].backgroundColor
        q.is_sync = ggo[index].is_sync
        q.is_google = true
        q.isDeleted = ggo[index].isDeleted
        newGg[ind] = q
    })
    return [
        ...ge,
        ...newGg
    ]
}
