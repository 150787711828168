import {makeStyles} from "@material-ui/core";

export const useStylesDisplaySettting = makeStyles(theme => ({

    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        // padding: "0px 15px",
        height: 33,
        background: "#f1f1f1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        width: 100,
        fontWeight: "bold",
        paddingLeft: 20,
        color: "#000",
        pointerEvents: "all",

    },
    selectTime: {
        position: "relative",
    },
    iconSelect: {
        fill: theme.props.primaryColor,
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        pointerEvents: "none",
        // width: 26,
        // height: 26,
    },
    excludeDayTitle: {
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        marginRight: 10,
    },
    excludeDay: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "24px",
        alignItems: "center",
        // maxWidth: 252,
        padding: "3px 4px 3px 0px",
        // margin: "18px auto",
    },
    saveIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -35,
        marginRight: 13,
        marginTop: 1
    },
    saveIconSecondary: {
        fill: theme.props.secondaryColor,
        marginLeft: -20,
        marginRight: 13,
        marginTop: 1
    },
    polygonIconSvg: {
        fill: theme.props.primaryColor,
    }
}))
