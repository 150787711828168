import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';
import {useEffect} from 'react';
import {getGeniamEvents} from '../context/common/getGeniamEvents';
import {eventsProxy} from '../store/proxy/events.proxy';
import {cloneDeep, concat, groupBy, remove} from 'lodash';
import {calenviewTimeRangeProxy} from '../store/proxy/calenviewTimeRange.proxy';
import moment from 'moment';
import {getGeniamEventsFromReccrence} from '../context/common/getGoogleEvents';
import {futureEventsProxy} from '../store/proxy/futureEvents.proxy';


export const useGeniamEvents = () => {
    const {user} = useSnapshot(userProxy)
    const {setGeniamEvents, geniamEvents} = useSnapshot(eventsProxy)
    const {currentTime} = useSnapshot(calenviewTimeRangeProxy)
    const {setGeniam} = useSnapshot(futureEventsProxy)
    const userId = user?.user_id
    useEffect(() => {
        if (!userId) return;
        const sub = getGeniamEvents((snapshot) => {
            const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
            setGeniamEvents(groupBy(data, "project_uuid"))
        })
        return () => {
            if (sub) sub()
        }
    }, [userId])
    useEffect(() => {
        //calc next event in time
        let result = {}
        Object.keys(geniamEvents).forEach(projectId => {
            const events = cloneDeep(geniamEvents[projectId] || [])
            const recurrence = remove(events, item => item.recurrence)
            const recurrenceEdited = remove(events, e => e.recurringEventId)
            const recurring = getGeniamEventsFromReccrence(recurrence, recurrenceEdited, currentTime.end)
            const singleEvents = concat(events, recurring)
            result[projectId] = singleEvents.filter(event => !event.allDay && moment().isBefore(event.start) && moment(event.start).isBefore(currentTime.end)).length
        })
        setGeniam(result)

    }, [geniamEvents, currentTime])
}
