import React, {useDeferredValue, useEffect, useState, useTransition} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Collapse, Grid, IconButton, List, ListItem, ListItemText, Typography} from "@material-ui/core"
import {ExpandLess, ExpandMore, MoreVert} from '@material-ui/icons'
import {useGlobal} from 'reactn'
import {cloneDeep, findIndex} from 'lodash'
import {ItemCheckedCustom} from "item-checked-custom";
import MoreContent from "./MoreContent";
import useIntlId from '../../hooks/useIntlId';
import DialogSelectCalendar from './DialogSelectCalendar';
import {useSnapshot} from 'valtio';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {userProxy} from '../../store/proxy/user.proxy';
import {futureEventsProxy} from '../../store/proxy/futureEvents.proxy';
import {setNewLists, updateListGgCalenviewShow} from '../../actions/googleCalendar';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';
import {useDebounce} from 'react-use';
import {refetchSource} from '../../actions/refetchSourcce';

const styles = theme => ({
    root: {
        position: "relative",
        background: "#f1f1f1",
        borderRadius: "0 0 10px 10px",

        marginRight: 5
    },
    nav: {
        background: "#f1f1f1",
        padding: "0 0 0 10px",
        borderRadius: '0 0 10px 10px',
    },
    listItemText: {
        fontWeight: "bold",
        fontSize: 16,
    },
    list: {
        // overflowX: 'hidden',
        // overflowY: 'auto',
        // maxHeight: '18vh',
        padding: "0",
        "& .MuiListItem-root": {
            padding: "0px 0 0px 10px",
        },
        // "&::-webkit-scrollbar": {
        //     width: 8,
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     borderRadius: 10,
        //     backgroundColor: '#ccc',
        // },
    },
    itemList: {
        alignItems: "center"
    },
    freeLimit: {
        color: '#FFF',
        background: 'black',
        marginLeft: '-5px',
        cursor: "pointer"
    },

});

function GoogleProject({classes}) {
    const {plan, loaded} = useSnapshot(announcementProxy)
    const {googleProjects} = useSnapshot(projectsProxy)
    const {calendarUser, setCalendarUser} = useSnapshot(userProxy)
    const {google} = useSnapshot(futureEventsProxy)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {idCalenderHidden = [], listGgCalenviewShow = []} = calendarUser
    const [listExpanded, setListExpanded] = useState(false)
    const [moreInfo, setMoreInfo] = useState(null)
    const [googleCalendarList, setGoogleCalendarList] = useGlobal('googleCalendarList')
    const [GoogleCalendar, noticePlan] = useIntlId(['leftHomeApp.GoogleCalendar', 'settings.noticePlan'])
    const [showList, setShowList] = useState(cloneDeep(listGgCalenviewShow) || [])
    const [maxCal, setMaxCal] = useState(0)
    const [height, setHeight] = useState(0)
    const [isPending, startTransition] = useTransition();
    const [showDialogSelectCalendar, setShowDialogSelectCalendar] = useGlobal("showDialogSelectCalendar")

    const deferredGoogleProjects = useDeferredValue(googleProjects)

    const handleChange = (source) => {
        const next = cloneDeep(googleCalendarList)
        const show = cloneDeep(listGgCalenviewShow)
        const check = !source.check
        let index = findIndex(next, g => g.id === source.id);
        if (index !== -1) {
            next[index] = {...next[index], check: check}
            setGoogleCalendarList([...next])
            let indexShow = findIndex(show, s => s.id === source.id);
            show[indexShow] = {...show[indexShow], check: check}
            startTransition(() => {
            })
            setShowList([...show]);
            // update to google api
            // toggleSelected(source)
        }

    };
    useEffect(() => {
        let timer = setTimeout(() => {
            refetchSource('google')
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [googleCalendarList])

    useDebounce(() => {
        if (showList.length > 0) {
            setCalendarUser({...calendarUser, listGgCalenviewShow: [...showList]})
            updateListGgCalenviewShow([...showList])
        }
    }, 500, [showList])

    useEffect(() => {
        if (!loaded)
            return;
        setNewLists()
        // eslint-disable-next-line
    }, [deferredGoogleProjects, plan.numOfCal, loaded]);

    const sortGoogleCalendar = () => {
        let list = cloneDeep(googleCalendarList)
        const selected = list?.filter(g => g.check).sort((a, b) => google[b.id] - google[a.id])
        const noSelect = list?.filter(g => g.check === false && typeof g.check !== 'undefined').sort((a, b) => google[b.id] - google[a.id])
        const nonSelect = list?.filter(g => typeof g.check === 'undefined').sort((a, b) => google[b.id] - google[a.id])
        return [...selected, ...noSelect, {}, ...nonSelect]
    };

    return (
        <div className={`${classes.root} `} id={'google-calendar-list'}>
            <List component="nav" className={classes.nav}>
                <ListItem button onClick={() => setListExpanded(!listExpanded)}>
                    <ListItemText>
                        <Typography className={classes.listItemText}>
                            {GoogleCalendar}
                        </Typography>
                    </ListItemText>
                    {!listExpanded ? <ExpandLess id={'google-calendar-list-expand'}/> :
                        <ExpandMore id={'google-calendar-list-expand'}/>}
                </ListItem>
                {googleCalendarList &&
                    <Collapse in={!listExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.list}>
                            {sortGoogleCalendar().map((source, index) => {
                                if (!source?.id) return (
                                    <Grid key={index}>
                                        <Grid style={{height: `${height}px`}}></Grid>
                                        <Grid
                                            className={classes.freeLimit}
                                            onClick={() => {
                                                handleOpen(12)
                                            }}
                                            align="center">
                                            {noticePlan ? noticePlan.replace('**', plan.numOfCal) : ''}
                                        </Grid>
                                    </Grid>
                                )
                                if (!source?.id || idCalenderHidden?.indexOf(source.id) > -1)
                                    return null
                                let next = google[source.id]
                                return (
                                    <Grid className={classes.item} key={index}>
                                        <ListItem disableGutters={true} className={classes.item} key={index}>
                                            <Grid container className={classes.itemList}>
                                                <Grid item xs={10}>
                                                    <ItemCheckedCustom
                                                        id={'source-google-name' + source.id}
                                                        key={source.id}
                                                        name={source.summary}
                                                        color={typeof source.check === 'undefined' ? '#ccc !important' : source.backgroundColor}
                                                        checked={Boolean(source.check)}
                                                        isLeft={next !== 0}
                                                        leftNumber={next}
                                                        onClick={() => handleChange(source)}
                                                        disabled={typeof source.check === 'undefined'}
                                                        height={'33px'}
                                                        margin={'unset'}
                                                        hiddenAvatar={!next || next === 0}
                                                        offTooltip={true}
                                                        className={"ItemCheckedCustom"}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        className={classes.moreButton}
                                                        onClick={(e) => {
                                                            if (typeof source.check === 'undefined') return
                                                            setMoreInfo({
                                                                ...source,
                                                                el: e.currentTarget
                                                            })
                                                        }}
                                                    >
                                                        <MoreVert fontSize={"small"}/>
                                                    </IconButton>

                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </Grid>
                                )
                            })}
                        </List>
                        <DialogSelectCalendar
                            open={showDialogSelectCalendar}
                            setOpen={setShowDialogSelectCalendar}
                            classes={classes}
                            numOfCal={plan.numOfCal}
                        />
                    </Collapse>
                }
            </List>
            <MoreContent
                calendar={moreInfo}
                setCalendar={setMoreInfo}
                maxCal={maxCal}
            />
        </div>
    )
}

export default (withStyles(styles)(GoogleProject))
