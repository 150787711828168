import React from 'react';
import {setGlobal} from 'reactn'
import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'antd';
import {withRouter} from "react-router-dom";
import {ReactComponent as AddLargeSVG} from "../../../asSvg/icons/addLarge.svg";
import {ReactComponent as MileStoneSVG} from "../../../asSvg/icons/iconMilestone.svg";
import {ReactComponent as MileStoneSVGD} from "../../../asSvg/icons/iconMilestoneDisable.svg";
import {ReactComponent as FocusSVG} from "../../../asSvg/icons/iconFocus.svg";
import {ReactComponent as FocusSVGD} from "../../../asSvg/icons/iconFocusDisable.svg";
import IconButton from "@material-ui/core/IconButton";
import TaskCustom from "../../Custom/TaskCustom";
import useIntlId from "../../../hooks/useIntlId";
import {publicIcon} from "../handlers/infoEvent";
import TooltipCustom from '../../Custom/TooltipCustom';
import {Settings} from '@material-ui/icons';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {settingDialogProxy} from '../../../store/proxy/settingDialog.proxy';
import {themesProxy} from '../../../store/proxy/themes.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: '0 auto 15px auto',
        display: "flex",
        flexDirection: "column"
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    mileStoneImg: {
        width: 25,
        height: 25
    },
    buttonIcon: {
        marginRight: 5,
        marginBottom: 5,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f1f1f1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#f1f1f1",
            color: "unset"
        },
        "&:focus": {
            backgroundColor: "#f1f1f1",
            color: "unset"
        }
    },
    buttonIcon1: {
        marginLeft: 11,
        marginBottom: 5,
    },
    buttonIcon2: {
        marginLeft: 5,
        marginBottom: 5,
        border: "none !important",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff !important",
        boxShadow: "none",
        margin: '5px 0px 5px 5px',
        "& img": {
            // width: "100%",
            height: "100%",
            width: "18px"
        }
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    iconActive: {
        color: theme.props.primaryColor
    },
    flexWrap: {
        flexWrap: "wrap"
    },
    addIconSVG: {
        padding: 0,
        width: 28,
        height: 28,
        marginTop: 2,
        border: '1px solid #F1F1F1',
        "& svg": {
            fill: theme.props.primaryColor,
        },
    },
    listTagCustom: {
        display: "flex"
    },
    listTagFunction: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    flexDirection: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10
    },
    label: {
        marginBottom: 10,
        fontSize: 12,
    },
    tagActive: {
        fill: theme.props.primaryColor,
    },
    tagAuto: {
        fill: "#444"
    },
    tagAutoD: {
        fill: "#b8b8b8"
    },
    title: {
        fontWeight: "bold", fontSize: 14
    },
    tagAppy: {
        alignItems: "center",
        display: "flex",
    }
}))


function TagList(props) {
    const {calendarUser} = useSnapshot(userProxy)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {currentTheme} = useSnapshot(themesProxy)
    const {tasks = []} = calendarUser
    const {plan} = useSnapshot(announcementProxy);
    const {event, setEvent, onClose = null} = props
    const classes = useStyles();
    const listTagCustom = tasks.data.filter(item => item.icon !== "AssignmentTurnedIn")
    const listTagFunction = tasks.data.filter(item => item.icon === "AssignmentTurnedIn")
    const [tagEvent, tagFunciton, unlockMileIntl] = useIntlId(['event.tagEvent', 'event.tagFunciton', 'milestone.plan.notification'])
    let disabled = event?.is_holiday

    const onOpenSetting = React.useCallback(() => {
        setGlobal({
            openSetting: true,
            settingTab: 1,
            openAddTask: true
        })
    }, []);

    const onChoose = (item) => {
        if (Boolean(event?.isConnectMetamors)) return;
        if (item?.type !== "file") {
            if (event.task === item.icon) {
                return setEvent({
                    ...event,
                    task: ""
                })
            }
            return setEvent({
                ...event,
                task: item.icon,
                isMileStone: item.icon === "mileStone"
            })

        }

        return setEvent({
            ...event,
            task: (event.task === item?.file?.id) ? "" : item?.file?.id
        })
    }

    if (!event)
        return null
    return (
        <div className={`${classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <div>
                <div className={classes.title}>{tagEvent}</div>
                <div className={classes.flexDirection}>
                    <div className={classes.listTagCustom}>
                        <TaskCustom
                            items={listTagCustom}
                            callBack={(item) => onChoose(item)}
                            event={event}
                            disabled={disabled || event?.task === 'focus' || event?.task === 'mileStone'}
                            color={currentTheme?.colorPrimary}
                            isGetPopup={true}
                            borderRadius={"50%"}
                        />
                        {
                            !event?.isMileStone &&
                            <IconButton
                                disableRipple={true}
                                className={classes.addIconSVG}
                                onClick={onOpenSetting}
                            >
                                <AddLargeSVG/>
                            </IconButton>
                        }
                    </div>


                    <div className={classes.flexRow}>
                        <Button
                            className={classes.buttonIcon2}
                            disabled={disabled}
                            onClick={() => {
                                setEvent({
                                    ...event,
                                    task: null,
                                    isMileStone: false
                                })
                            }
                            }>
                            <img src={publicIcon('backspace.svg')} alt=""/>
                        </Button>
                        <IconButton
                            className={classes.buttonIcon1}
                            onClick={() => {
                                handleOpen(1)
                                if (onClose)
                                    onClose()
                            }}>
                            <Settings/>
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className={classes.label}>{tagFunciton}</div>
            <div className={classes.listTagFunction}>
                <div className={classes.tagAppy}>
                    <TaskCustom
                        items={listTagFunction}
                        callBack={(item) => onChoose(item)}
                        event={event}
                        disabled={disabled}
                        color={currentTheme?.colorPrimary}
                        isGetPopup={true}
                        borderRadius={"2px"}
                    />
                    {event?.allDay ?
                        <>
                            <Button
                                className={`${classes.buttonIcon}`}
                                disabled={disabled}
                                onClick={() => {
                                    if (event?.task === "mileStone") {
                                        setEvent({
                                            ...event,
                                            task: "",
                                            isMileStone: false
                                        })
                                    } else {
                                        setEvent({
                                            ...event,
                                            task: "mileStone",
                                            isMileStone: true,
                                            recurrence: ""
                                        })
                                    }

                                }
                                }
                                // shape="circle"
                                icon={disabled ? <MileStoneSVGD
                                        className={event.task === "mileStone" ? classes.tagActive : classes.tagAutoD}/> :
                                    <MileStoneSVG
                                        className={event.task === "mileStone" ? classes.tagActive : classes.tagAuto}/>}>
                            </Button>
                            <TooltipCustom
                                title={unlockMileIntl}
                                placement={'bottom'}
                                overlayClassName={`hover-show-tooltip hover-show-tooltip-2 max-width-content`}
                                getId={'tool-tip-task-focus'}
                            >
                                <Button
                                    id={'tool-tip-task-' + (!plan?.info?.focus ? 'focus' : '')}
                                    className={`${classes.buttonIcon}`}
                                    disabled={disabled}
                                    onClick={() => {
                                        if (!plan?.info?.focus)
                                            setGlobal({
                                                openSetting: true,
                                                settingTab: 12
                                            })
                                        if (event?.task === "focus") {
                                            setEvent({
                                                ...event,
                                                task: "",
                                                isMileStone: false
                                            })
                                        } else {
                                            setEvent({
                                                ...event,
                                                task: "focus",
                                                isMileStone: false
                                            })
                                        }

                                    }
                                    }
                                    icon={disabled || !plan?.info?.focus ? <FocusSVGD
                                            className={classes.tagAutoD}/> :
                                        <FocusSVG
                                            className={event.task === "focus" ? classes.tagActive : classes.tagAuto}/>}>
                                </Button>
                            </TooltipCustom>
                        </>
                        :
                        ''
                    }

                </div>

                <div className={classes.flexRow}>
                    <Button
                        className={classes.buttonIcon2}
                        disabled={disabled}
                        onClick={() => {
                            setEvent({
                                ...event,
                                task: "",
                                isMileStone: false
                            })
                        }
                        }>
                        <img src={publicIcon('backspace.svg')} alt=""/>
                    </Button>
                    <IconButton
                        className={classes.buttonIcon1}
                        onClick={() => {
                            handleOpen(1)
                            if (onClose)
                                onClose()
                        }}>
                        <Settings/>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default withRouter(TagList)
