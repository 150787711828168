import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as SaveSVG} from "../../asSvg/save.svg";
import {ReactComponent as CopySVG} from "../../asSvg/copy2.svg";
import {ReactComponent as ZoomSVG} from "../../asSvg/zoomIcon.svg";
import useIntlId from "../../hooks/useIntlId";

function SaveButton({saveClick, copyTime, zoomUrl, disable = false, disiableZoom = false, disableHoliday = false}) {
    const classes = useStyles()
    const [hoverZoom, setHoverZoom] = useState(false)
    const [saveIntl, copyIntl] = useIntlId(['dialog.action.save', 'app.setting.copyTex'])

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                className={`${classes.button} ${classes.saveHover} saveButon`}
                endIcon={<SaveSVG/>}
                onClick={saveClick}
                disabled={disable || disableHoliday}
            >
                {saveIntl}
            </Button>
            <Button
                variant="contained"

                className={`${classes.button} ${hoverZoom ? classes.copyHover : classes.copy} copyButon`}
                endIcon={<CopySVG/>}
                onClick={copyTime}
                disabled={disable}

            >
                {copyIntl}
            </Button>
            <Button
                variant="contained"
                className={`${classes.button} ${classes.zoom} zoomUrlButon`}
                endIcon={<ZoomSVG/>}
                onMouseMove={() => {
                    setHoverZoom(true)
                }}
                onMouseLeave={() => {
                    setHoverZoom(false)
                }}
                disabled={disable || disiableZoom || disableHoliday}
                onClick={zoomUrl}
            >
                zoom url
            </Button>
        </div>
    );
}

export default SaveButton;
const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: "25px",
        border: "solid 2px #fff",
        width: "calc(33.33% + 25px)",
        boxShadow: "none",
        marginLeft: "-25px",
        color: theme.props.primaryColor,
        fontWeight: "bold",
        position: "relative",
        "& .MuiButton-endIcon": {
            position: "absolute",
            right: 7,
            top: "50%",
            transform: "translateY(-50%)"
        },
        "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.props.primaryColor,
            color: "#fff"
        },
        "& svg": {
            fill: theme.props.primaryColor,
        }
    },
    save: {
        zIndex: 3,
    },
    saveHover: {
        zIndex: 3,
        boxShadow: "none",
        backgroundColor: theme.props.primaryColor,
        color: "#fff"
    },
    copy: {
        zIndex: 2,
    },
    copyHover: {
        zIndex: 2,
        boxShadow: "none",
        backgroundColor: theme.props.primaryColor,
        color: "#fff"
    },
    root: {
        position: "sticky",
        bottom: 0,
        marginLeft: "25px",
    },
}))
