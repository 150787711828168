import {db} from "../../config/firebaseConfig";
import store from "store";
import {CALENDAR_COLLECTION} from '../../config/constants';
import {snapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {isEmpty} from 'lodash';

const getUser = (prop) => {
    const u = snapshot(userProxy).user
    if (isEmpty(u)) {
        return null;
    }
    return prop ? u[prop] : u;
}
//
// const getUserId = () => getUser('user_id')
//

export const myEvent = (docId) => {
    return myEvent().doc(docId)
}

export const myLocalInfo = () => {
    return store.get('userInfo')
}
export const myAllInfo = () => {
    return {...getUser(), ...myLocalInfo()}
}
export const myInfo = getUser;


export const myEventRef = (docId) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(getUser('user_id'))
        .collection('events')
        .doc(docId)
}

export const myCalendarRef = () => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(getUser('user_id'))
}

export const getUserProjectsRef = id => {
    return db.doc(`${CALENDAR_COLLECTION}/${getUser('user_id')}/projects/${id}`)
}

