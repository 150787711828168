import React, {useEffect, useState, useTransition} from 'react';
import {useGlobal} from "reactn"
import {iconNameList} from "../../../common/muiIcons";
import {Grid, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as SearchSVG} from "../../../asSvg/icons/searchBGPrimary.svg";
import useIntlId from '../../../hooks/useIntlId';
import {Input, Spin} from 'antd';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import CalendarContainer from '../../Custom/calendarContainer';

function ListIconSearch({icon, setIcon}) {
    const classes = useStyles()
    const [search, setSearch] = useState('')
    const [list, setList] = useState([])
    const [isPending, startTransition] = useTransition();
    const [tasks] = useGlobal('tasks')
    const [searchIconIntl] = useIntlId(['icon.search'])

    useEffect(() => {
        getIconList()
        // eslint-disable-next-line
    }, [tasks, search])
    const handleSelectIcon = (name) => {
        setIcon(name)
    }

    const getIconList = () => {
        if (search) {
            startTransition(() => {
                setList(iconNameList.filter(item => item.includes(search) && !tasks?.data.map(task => task.icon).includes(item)));
            })
        } else {
            startTransition(() => {
                setList(iconNameList.filter(item => !tasks?.data.map(task => task.icon).includes(item)))
            })
        }
    }

    return (
        <div className={classes.root}>

            <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={searchIconIntl}
                variant="outlined"
                className={classes.searchInput}
                suffix={<IconButton className={classes.whiteText}>
                    <SearchSVG className={classes.iconSVG}/>
                </IconButton>}
                // InputProps={{
                //     endAdornment: <IconButton className={classes.whiteText}>
                //         <SearchSVG className={classes.iconSVG}/>
                //     </IconButton>,
                // }}
            />
            <LoadingOverlay>
                <Loader loading={isPending} text={'Loading...'}/>
                <Grid container className={classes.container}>
                    {
                        list.map(item => {
                            return (
                                <Grid xs={2} className={item === icon ? classes.selectItem : classes.item}
                                      onClick={() => handleSelectIcon(item)}
                                >
                                    <span className={"material-icons md-48"}>{item}</span>
                                    <span className={classes.nameIcon}>{item}</span>
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </LoadingOverlay>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        flexDirection: "column",
        alignItems: "center"
    },
    container: {
        marginTop: 10,
        borderRadius: 15,
        width: "100%",
        height: "calc(100vh - 400px)",
        minHeight: 300,
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: '#ccc',
        },

    },
    selectItem: {
        color: theme.props.primaryColor,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        flexDirection: "column",
        alignItems: "center"
    },
    root: {
        marginTop: 10,
    },
    nameIcon: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "80%",
        fontSize: 12,
        marginBottom: 10,
    },
    iconSVG: {
        fill: theme.props.primaryColor
    },
    whiteText: {
        fill: theme.props.primaryColor,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0
    },
    searchInput: {
        width: "100%",
        height: 41,
        borderRadius: 15,
        margin: 'auto',
        position: 'relative',

    },
    boxSearch: {
        backgroundColor: "#F1F1F1",
        borderRadius: "50px",
        padding: "5px",
        marginBottom: 10,
    }

}))

export default React.memo(ListIconSearch)
