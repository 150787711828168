import {db} from "../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";

export const getGeniamProjects = (userId, callback) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection("projects")
        .where("is_deleted", "==", false)
        .onSnapshot(querySnapshot => {
                if (callback) {
                    callback(null, querySnapshot)
                 }
            },
            error => {
                if (callback) {
                    callback(error, null)
                }
            }
        )

}
