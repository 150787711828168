import React, {memo, useEffect, useState} from 'react';
import {getGlobal, useGlobal} from 'reactn'
import {makeStyles} from "@material-ui/core/styles";
import AddProjectPopup from "../../AddProjectPopup/AddProjectPopup";
import EditProject from "./editProject";
import ProjectInfo from "./ProjectInfo";
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd";
import {db} from "../../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {cloneDeep, concat, filter, findIndex} from 'lodash'
import styled from "styled-components";
import {CheckOutlined} from "@ant-design/icons";
import useIntlId from "../../../hooks/useIntlId";
import SaveButton from "../../Dialog/SaveButton";
import AddButton from "../../Dialog/AddButton";
import {snapshot, useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {projectsProxy} from '../../../store/proxy/projects.proxy';

const rowHeight = 35
const isOrderChange = (prevSource = [], nexSource = []) => {
    if (prevSource.length !== nexSource.length)
        return true
    let result = false
    prevSource.forEach((item, index) => {
        if (item !== nexSource[index]) {
            result = true
        }
    })
    return result
}


const ProjectItem = styled.button`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
    border-radius: 25px;
    height: 30px;

    &:hover {
        cursor: pointer;
    }
;
    border: none;
    color: #fff;
    background-color: ${props => props?.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};

`
const ProjectItemActive = styled(ProjectItem)`
    border: ${props => props?.item ? `solid 2px ${props.item.color || props.item.backgroundColor}` : "none"};
    color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};
    background-color: #fff;
    padding: 0 15px;

`

function ProjectSettings() {
    const [totalProjects, setTotal] = useState([])
    const [addPro, setAddPro] = useState(false)
    const [selected, setSelected] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [isChangedOrder, setIsChangedOrder] = useState(false)
    const [loading, setLoading] = useState(false)
    const {calendarUser} = useSnapshot(userProxy)
    const {googleProjects, geniamProjects, projectSelected} = useSnapshot(projectsProxy)
    const classes = useStyles()
    const [proIntl, projectsIntl] = useIntlId(['setting.projectSettings', 'app.profile.projects'])
    // const [projectSelected] = useGlobal('projectSelected')
    const [otherURLOld, setOtherURLOld] = useState([])
    const linkItems = [{
        name: "Slack",
        logo: "/icons/slackIcon.svg",
        id: "slack"
    }, {
        name: "Trello",
        logo: "/icons/trelloIcon.svg",
        id: "trello"
    }
    ]

    useEffect(() => {
        const googleSettings = calendarUser?.googleSettings || {}
        const {googleList = []} = calendarUser
        const geniamCals = filter(geniamProjects, p => !p.is_deleted && !p.is_sync)
        const googleCals = filter(googleProjects, g => !g?.id?.includes("#holiday")).map(p => {
            const linkInfo = googleSettings[p.id] || {}
            return {...p, ...linkInfo, is_google: true, name: p.summary, color: p.backgroundColor,}
        })
        let allCals = concat(geniamCals, googleCals)
        if (!googleList.length) {
            setTotal(allCals)
            return
        }
        let nextProject = []
        googleList.forEach(id => {
            let idx = findIndex(allCals, {id})
            if (idx !== -1) {
                nextProject.push(allCals[idx])
                allCals.splice(idx, 1)
            }
        })
        setTotal(concat(nextProject, allCals))

    }, [geniamProjects, googleProjects, projectSelected])

    useEffect(() => {
        const {googleList = []} = calendarUser
        if (isOrderChange(googleList, totalProjects.map(cal => cal.id))) {
            // changed order . display save btn
            setIsChangedOrder(true)
        } else {
            setIsChangedOrder(false)
        }
    }, [totalProjects])
    const rows = Math.ceil(totalProjects.length / 4)
    const height = rows * rowHeight + 3
    const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
        const nextState = swap(totalProjects, sourceIndex, targetIndex)
        setTotal([...nextState])
    }

    useEffect(() => {
        if(!projectSelected?.id)
            return
        setSelected(cloneDeep(projectSelected))
    }, [projectSelected])

    const updateOrder = async () => {
        try {
            setLoading(true)
            const {user} = snapshot(userProxy)
            await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
                .set({
                    googleList: totalProjects.map(cal => cal.id)
                }, {merge: true})
            // .catch(console.log)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        let newData = cloneDeep(selected?.otherURL) || []
        setOtherURLOld(newData)
    }, [selected])
    return (
        <div className={classes.root}>
            <div className={classes.titleTop}>{proIntl}</div>
            <div className={classes.mt30}>
                <div className={classes.container}>
                    <GridContextProvider
                        onChange={onChange}
                    >
                        <GridDropZone
                            id="projects"
                            boxesPerRow={4}
                            rowHeight={rowHeight}
                            style={{height}}
                            // disableDrag={true}
                        >
                            {
                                totalProjects.map(pro => {
                                    return (
                                        <GridItem key={pro.id} className={classes.itemProject}>

                                            {
                                                selected?.id === pro.id ?
                                                    <div className={classes.relative}>
                                                        <ProjectItemActive
                                                            onClick={() => {
                                                                if (selected?.id !== pro.id)
                                                                    setIsEdit(false)
                                                                setSelected(pro)

                                                            }}
                                                            item={pro}
                                                        >
                                                            {pro.name || pro.summary}
                                                        </ProjectItemActive>
                                                        <CheckOutlined className={classes.iconActive}/>
                                                    </div>
                                                    :
                                                    <ProjectItem
                                                        onClick={() => {
                                                            if (selected?.id !== pro.id)
                                                                setIsEdit(false)
                                                            setSelected(pro)

                                                        }}
                                                        item={pro}
                                                    >
                                                        {pro.name || pro.summary}
                                                    </ProjectItem>
                                            }
                                        </GridItem>
                                    )
                                })
                            }
                        </GridDropZone>
                    </GridContextProvider>
                </div>
                <div className={`${classes.mt30} clf-flex-center`}>
                    <AddButton
                        onClick={() => setAddPro(true)}
                        name={projectsIntl}
                        margin={'0px 20px 0px 0px'}
                        width={'150px'}
                    />
                    <SaveButton
                        onClick={updateOrder}
                        disable={!isChangedOrder || loading}
                    />
                    {addPro && <AddProjectPopup addProject={addPro} setAddProject={setAddPro}/>}
                </div>

                {selected?.id ?
                    <div className={classes.divProject}>
                        {
                            isEdit ?
                                <EditProject
                                    project={selected}
                                    setProject={setSelected}
                                    setIsEdit={setIsEdit}
                                    otherURLOld={otherURLOld}
                                    linkItems={linkItems}
                                />
                                :
                                <ProjectInfo
                                    project={selected}
                                    setEdit={setIsEdit}
                                    setProject={setSelected}
                                    linkItems={linkItems}
                                />
                        }

                    </div>
                    :
                    null
                }

            </div>

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
        margin: "auto",
        textAlign: 'center',
        // paddingTop: 20
    },
    titleTop: {
        fontSize: '12px',
        fontWeight: 500,
        color: '#000',
        textTransform: 'uppercase',
        textAlign: "left"
    },
    container: {
        width: "100%", background: '#fff', borderRadius: 20,
        padding: 10,
    },
    divProject: {
        paddingBottom: 25
    },
    btnAdded: {},
    btn: {
        backgroundColor: 'white',
        border: 'none',
        margin: 5,
        padding: 4,
        width: "calc(100% - 10px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        '&:hover': {
            cursor: 'pointer'
        }
    },
    services: {
        backgroundColor: 'black',
        color: 'white',
        margin: 5,
        padding: 4,
        width: "calc(100% - 10px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        '&:hover': {
            cursor: 'pointer'
        }
    },
    dropZone: {
        minHeight: "400px", width: "100%"
    },
    mt30: {
        marginTop: 30
    },
    icon: {
        marginRight: 10
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            marginTop: 1,
            maxWidth: "25px !important"
        }
    },
    itemProject: {
        padding: "0 3px",
        boxSizing: "border-box"
    },
    relative: {
        position: "relative",
    },
    iconActive: {
        position: "absolute",
        top: "50%",
        width: 18,
        height: 18,
        marginTop: -7,
        right: 7,
        color: "#1790FF"
    },
    boxDnd: {
        alignItems: "center"
    }
}))
export default memo(ProjectSettings)
