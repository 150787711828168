import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Slider from 'react-slick';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';


function SliderBoard(props) {
    const {lock, board} = useSnapshot(announcementProxy)
    const classes = useStyle()
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const [listCommunity, setListCommunity] = useState([])
    const [autoplaySpeed, setAutoplaySpeed] = useState(1200)
    useEffect(() => {
        if (board?.community?.length) {
            let newList = []
            board?.community.forEach(item => {
                if (item?.image) {
                    newList.push({...item})
                }
            })
            setListCommunity(newList)
        }
    }, [board?.community])

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        verticalSwiping: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: autoplaySpeed,
        adaptiveHeight: true,
        arrows: false,
        beforeChange: function (currentSlide, nextSlide) {
            // console.log("before change", currentSlide, nextSlide);
        }
    };
    useEffect(() => {
        if (!isNaN(board?.rotationInterval)) {
            setAutoplaySpeed(board?.rotationInterval * 1000)
        }
    }, [board?.rotationInterval])

    const handleClick = () => {
        handleOpen(9)
    }
    if (!board?.display || !listCommunity?.length) return null
    return (
        <div className={classes.root}>
            {
                lock && <img className={classes.lock} src="/icons/keyLock.svg" alt=""/>
            }
            <img onClick={handleClick} className={classes.setting} src="/icons/settingWhite.svg" alt=""/>
            <Slider {...settings}>
                {
                    listCommunity.map((item, index) => {
                        return (
                            <img key={index} className={classes.img} src={item?.image} alt=""/>
                        )
                    })
                }
            </Slider>
        </div>
    );
}

export default SliderBoard;
const useStyle = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        marginTop: 15,
        position: "relative"
    },
    img: {
        width: "100%",
        // height: " 100%",
        objectFit: "contain",
        borderRadius: "15px",
        height: 250,
        backgroundColor: '#fff'
    },
    lock: {
        position: "absolute",
        top: 10,
        left: 10,
        zIndex: 2,
        padding: "6px 8px",
        borderRadius: "10px",
        backgroundColor: "rgba(0,0,0,0.6)"
    },
    setting: {
        position: "absolute",
        top: 10,
        right: 10,
        zIndex: 2,
        padding: "6px 8px",
        borderRadius: "10px",
        backgroundColor: "rgba(0,0,0,0.6)",
        cursor: "pointer"
    }
}))
