import axios from 'axios'
import {setGlobal, useGlobal} from 'reactn'
import {API_GENIAM, GET_USER_INFO, LOGIN_URL} from "../config/constants";
import Cookies from 'js-cookie'
import {
    COOKIE_GENIAM_ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_REFRESH_TOKEN_KEY,
    removeCookie,
    setCookieRefreshToken,
} from "../common/cookies";
import qs from "query-string";
import {auth} from "../config/firebaseConfig";
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {myCal} from '../common/myCal';
import {orderBy} from 'lodash';
import {refetchSource} from './refetchSourcce';
import {tokenProxy} from '../store/proxy/token.proxy';
import {snapshot, useSnapshot} from 'valtio'
import {userProxy} from '../store/proxy/user.proxy';

export const fetchApiUserData = async () => {
    const {accessToken, firebaseToken} = snapshot(tokenProxy)
    const {setUser} = snapshot(userProxy)
    // localStorage.removeItem('userInfo');
    // localStorage.removeItem('refreshToken');
    // localStorage.removeItem('zoomConnectData');
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('firebaseToken');

    if (!accessToken || !firebaseToken) await refreshToken()

    try {
        const {data} = await axios.get(GET_USER_INFO)
        setUser(data)
        return data
    } catch (e) {
        console.log(e)
        // msgError(e.message)
        localStorage.clear()
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null
    }
}

export const useFetchApiUserData = () => {
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();
    const {setToken} = useSnapshot(tokenProxy)
    useEffect(() => {
        const {
            token,
            refreshToken
        } = query;

        if (token || refreshToken) {
            setCookieRefreshToken(refreshToken)
            setToken({
                accessToken: token,
                refreshToken,
                firebaseToken: null
            }, () => {
                fetchApiUserData()
            })

            delete query.token;
            delete query.refreshToken;
            history.push(`${path}?${qs.stringify(query)}`)
        } else {
            fetchApiUserData()
        }
        // eslint-disable-next-line
    }, []);
}

export const refreshToken = async () => {
    const localToken = snapshot(tokenProxy)
    const refreshToken = Cookies.get(COOKIE_GENIAM_REFRESH_TOKEN_KEY) || localToken.refreshToken

    if (!refreshToken) {
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
    }
    try {
        const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
        const data = res.data;
        localToken.setToken({
            accessToken: data.accessToken,
            firebaseToken: data?.firebaseToken,
            refreshToken
        })
        return data;
    } catch (e) {
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null;
    }
}

export const signInFirebase = async () => {
    try {
        const data = await refreshToken()
        return await auth.signInWithCustomToken(data?.firebaseToken)
    } catch (e) {
        console.log(e);
    }
}

export const useMyCalSettings = () => {
    const [displaySettings, setDisplaySetting] = useGlobal("workTimeDisplay")
    const [settings] = useCollectionData(myCal()?.collection(`settings`))
    useEffect(() => {
        if (!settings)
            return;
        const data = orderBy(settings, 'endDay', 'asc')
        setDisplaySetting(data, () => {
            refetchSource("timeWork")
        })
        // eslint-disable-next-line
    }, [settings])

    return [displaySettings]
}

export const useMyThemeList = () => {
    const [themeList] = useCollectionData(myCal()?.collection(`listTheme`))
    useEffect(() => {
        if (themeList) {
            setGlobal({listTheme: themeList})
        }
    }, [themeList])

}
