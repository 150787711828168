import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import {Button, Col, InputNumber, Row, Tooltip} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import {publicIcon} from '../handlers/infoEvent';
import {ReactComponent as NotificationSVG} from "../../../asSvg/notification.svg";
import {getTimeList} from '../../../common/timeList';
import styled from 'styled-components';


const timeList = getTimeList()

function Reminders({event, setEvent}) {
    const classes = useStyles()
    const [emailIntl,
        notificationIntl,
        datePickerDaysIntl,
        datePickerWeekIntl,
        datePickerMinutesIntl,
        datePickerHoursIntl,
        beforeIntl,
        addNoticeIntl,
        tooltipDayIntl,
        tooltipWeekIntl,
        tooltipHoursIntl,
        tooltipMinuteIntl] = useIntlId([
        'settings.email',
        'calenview.noticeBell',
        'datePicker.days',
        'appbar.week',
        'event.freebusy.time',
        'event.timeOfVote.Adornment',
        'calenview.remind.before',
        'calenview.remind.addNotice',
        'calenview.tooltipDay',
        'calenview.tooltipWeek',
        'calenview.tooltipHours',
        'calenview.tooltipMinutes',
    ])
    const defaultRemind = {
        method: notificationIntl,
        time: event.allDay ? 1 : 10,
        optionTime: event.allDay ? datePickerDaysIntl : datePickerMinutesIntl,
        timeBefore: '00:15'
    }
    const defaultOptionsTypeNotification = [
        {
            value: emailIntl,
        },
        {
            value: notificationIntl,
        },

    ]
    const defaultOptionsTime = [
        {
            value: datePickerMinutesIntl,
        },
        {
            value: datePickerHoursIntl,
        },
        {
            value: datePickerDaysIntl,
        },
        {
            value: datePickerWeekIntl,
        },

    ]
    const defaultOptionsTimeAllDay = [
        {
            value: datePickerDaysIntl,
        },
        {
            value: datePickerWeekIntl,
        },

    ]
    const [optionsTypeNotification] = useState([...defaultOptionsTypeNotification])
    const [optionsTime, setOptionsTime] = useState([...defaultOptionsTime])
    const [listRemind, setListRemind] = useState([])
    const Content = styled.div``
    const [isDelete, setIsDelete] = useState(false)

    useEffect(() => {
        if (!event)
            return;
        if ('defaultReminders' in event && listRemind.length === 0 && !isDelete) {
            let data = event.defaultReminders.map((e) => {
                let remind = {}
                if (e.method === 'email') {
                    remind.method = emailIntl;
                } else {
                    remind.method = notificationIntl;
                }
                if (event.allDay) {
                    remind = optionAllDay(e, remind)
                } else {
                    remind = optionOther(e, remind)
                    remind.timeBefore = '00:15'
                }
                return remind;
            })
            if(data.length > 0) {
                setListRemind(data)
                return;
            }
        }
        let list = [];
        listRemind.forEach(e => {
            let object = {}
            let minutes = 0;
            if (e.method === emailIntl) {
                object.method = 'email'
            } else {
                object.method = 'popup'
            }
            if (event.allDay) {
                minutes = calculateMinutesAllDay(e)
            } else {
                minutes = calculateMinutesOther(e)
            }
            object.minutes = minutes;
            if(!list.find(e => {return e.minutes === object.minutes && e.method === object.method})) {
                list = [...list, object]
            }
        })
        if (list.length > 0 || isDelete) {
            setEvent({...event, defaultReminders: list})
        }
        setIsDelete(false)
        // eslint-disable-next-line
    }, [listRemind]);

    useEffect(() => {

        if (!event)
            return;
        if (event.allDay) {
            let list = listRemind.map((e, index) => {
                return {
                    ...e,
                    optionTime: datePickerDaysIntl
                }
            })
            setListRemind(list)
            setOptionsTime(defaultOptionsTimeAllDay)
        } else {
            let list = listRemind.map((e, index) => {
                return {
                    ...e,
                    optionTime: datePickerMinutesIntl
                }
            })
            setListRemind(list)
            setOptionsTime(defaultOptionsTime)
        }
        // eslint-disable-next-line
    }, [event.allDay]);

    const optionAllDay = (e, remind) => {
        if ((e.minutes + 1440) / 10080 > 1 && 10080 * Math.floor((e.minutes + 1440) / 10080) - e.minutes < 1440) {
            remind.optionTime = datePickerWeekIntl
            remind.time = Math.floor((e.minutes + 1440) / 10080)
            const remainderMinute = e.minutes - (remind.time * 7 - 1) * 1440
            const hoursInt = remainderMinute % 60 === 0 ? 24 - Math.floor(remainderMinute / 60) : 23 - Math.floor(remainderMinute / 60)
            const hoursString = hoursInt > 10 ? hoursInt : `0${hoursInt}`
            const minute = remainderMinute % 60 === 0 ? '00' : 60 - (remainderMinute - Math.floor(remainderMinute / 60) * 60)
            remind.timeBefore = hoursString + ':' + minute
        } else {
            remind.optionTime = datePickerDaysIntl
            remind.time = Math.floor(e.minutes / 1440) + 1
            const hoursInt = (e.minutes - (remind.time - 1) * 1440) % 60 === 0 ? 24 - Math.floor((e.minutes - (remind.time - 1) * 1440) / 60) : 23 - Math.floor((e.minutes - (remind.time - 1) * 1440) / 60)
            const hoursString = hoursInt > 10 ? hoursInt : '0' + hoursInt
            const minute = (e.minutes - (remind.time - 1) * 1440 - Math.floor((e.minutes - (remind.time - 1) * 1440) / 60) * 60) === 0 ? '00' : 60 - (e.minutes - (remind.time - 1) * 1440 - Math.floor((e.minutes - (remind.time - 1) * 1440) / 60) * 60)
            remind.timeBefore = hoursString + ':' + minute
        }
        return remind
    }

    const optionOther = (e, remind) => {
        if (e.minutes % 10080 === 0) {
            remind.optionTime = datePickerWeekIntl
            remind.time = e.minutes / 10080
        } else if (e.minutes % 1440 === 0) {
            remind.optionTime = datePickerDaysIntl
            remind.time = e.minutes / 1440
        } else if (e.minutes % 60 === 0) {
            remind.optionTime = datePickerHoursIntl
            remind.time = e.minutes / 60
        } else {
            remind.optionTime = datePickerMinutesIntl
            remind.time = e.minutes
        }
        return remind
    }

    const calculateMinutesAllDay = (e) => {
        if (e.optionTime === datePickerDaysIntl) {
            return  e.time > 28 ? 40320 : e.time * 24 * 60 - parseInt(e.timeBefore.slice(0, 2)) * 60 - parseInt(e.timeBefore.slice(3, 5))
        } else {
            return e.time > 4 ? 40320 : e.time * 7 * 24 * 60 - parseInt(e.timeBefore.slice(0, 2)) * 60 - parseInt(e.timeBefore.slice(3, 5))
        }
    }
    const calculateMinutesOther = (e) => {
        switch (e.optionTime) {
            case datePickerMinutesIntl:
                return e.time > 40320 ? 40320 : e.time;
            case datePickerHoursIntl:
                return e.time > 672 ? 40320 : e.time * 60;
            case datePickerDaysIntl:
                return e.time > 28 ? 40320 : e.time * 24 * 60;
            case datePickerWeekIntl:
                return e.time > 4 ? 40320 : e.time * 7 * 24 * 60;
            default :
                break
        }
    }

    const addReminds = () => {
        if (listRemind.length < 5) {
            let remindBackup = listRemind;
            remindBackup = [...remindBackup, defaultRemind];
            setListRemind(remindBackup)
        }
    }
    const removeReminds = (index) => {
        let list = listRemind.map((e) => {
            return e
        })
        list.splice(index, 1)
        setIsDelete(true)
        setListRemind(list)
    }

    const onChange = (field, value, idx) => {
        const list= listRemind.map((e, index) => {
            if (index === idx) {
                return {
                    ...e,
                    [field]: value
                }
            }
            return e
        })
        setListRemind(list)
    }
    return (
        <Row style={{marginBottom: 5}}>
            <Col span={1} style={{maxWidth: '5.166667%'}}>
                <IconButton disableRipple={true} className={classes.icon} style={{marginTop: -10}}>
                    <NotificationSVG/>
                </IconButton>
            </Col>
            <Col span={21} style={{marginTop: -5}}>
                {listRemind.length > 0 && listRemind.map((remind, idx) => {
                    let maxTime = 40320;
                    let tooltip = '';
                    switch (remind.optionTime) {
                        case datePickerMinutesIntl:
                            maxTime = 40320;
                            tooltip = tooltipMinuteIntl;
                            break
                        case datePickerHoursIntl:
                            maxTime = 672;
                            tooltip = tooltipHoursIntl;
                            break
                        case datePickerDaysIntl:
                            maxTime = 28;
                            tooltip = tooltipDayIntl;
                            break
                        case datePickerWeekIntl:
                            maxTime = 4;
                            tooltip = tooltipWeekIntl;
                            break
                        default :
                            break
                    }
                    let Container = remind.time > maxTime ? Tooltip : Content
                    return <div className={classes.root}>
                        <div className={classes.content}>
                            <img className={classes.iconArrow} src={publicIcon('arrowDown.svg')} alt="arrowDown"/>
                            <select
                                className={classes.select}
                                value={remind?.method}
                                onChange={(data) => {
                                    onChange('method', data.target.value, idx)
                                }}
                                style={{ minWidth: 120}}
                            >
                                {
                                    optionsTypeNotification.map((option, index) => {
                                            return (
                                                <option key={option.value} value={option.value}>
                                                    {option.value}
                                                </option>
                                            )
                                        }
                                    )

                                }
                            </select>
                        </div>
                        <Container className={remind.time <= maxTime && classes.inputClass} placement="bottom"
                                   title={tooltip}>

                            <InputNumber style={remind.time <= maxTime ? {marginLeft: 0} : {}}
                                         className={classes.inputClass} value={remind.time} min={0} max={maxTime}
                                         onBlur={data => {
                                             if(data.target.value > maxTime) {
                                                 onChange('time', maxTime, idx)
                                             } else  {
                                                 onChange('time', data.target.value, idx)
                                             }
                                         }}
                                         onChange={data => {
                                             if(Math.abs(data - listRemind[idx].time) === 1) {
                                                 onChange('time', data, idx)
                                             }
                                         }}
                            />
                        </Container>
                        <div className={classes.content}>
                            <img className={classes.iconArrow} src={publicIcon('arrowDown.svg')} alt="arrowDown"/>
                            <select
                                className={classes.select}
                                value={remind?.optionTime}
                                onChange={(data) => {
                                    onChange('optionTime', data.target.value, idx)
                                }}
                                style={{marginLeft: 5, minWidth: 90}}
                            >
                                {
                                    optionsTime.map((option, index) => {
                                            return (
                                                <option key={option.value} value={option.value}>
                                                    {option.value}
                                                </option>
                                            )
                                        }
                                    )

                                }
                            </select>
                        </div>
                        {event.allDay ? <div className={classes.textBefore}>{beforeIntl}</div> : <div/>}
                        {event.allDay ? <div className={classes.content}>
                            <img className={classes.iconArrow} src={publicIcon('arrowDown.svg')} alt="arrowDown"/>
                            <select
                                className={classes.select}
                                value={remind?.timeBefore}
                                onChange={(data) => {
                                    onChange('timeBefore', data.target.value, idx)
                                }}
                                style={{marginLeft: 5, minWidth: 85}}
                            >

                                {
                                    timeList.map(option => <option key={option.value} value={option.value}>
                                        {option}
                                    </option>)
                                }
                            </select>
                        </div> : <div/>}

                        <Button onClick={() => removeReminds(idx)} className={classes.buttonDelete}>
                            <img className={classes.iconArrow} src={publicIcon('closeSmall.svg')} alt="arrowDown"/>
                        </Button>
                    </div>
                })
                }

                <Button onClick={addReminds} className={classes.buttonAdd}>
                    {addNoticeIntl}
                </Button>
            </Col>


        </Row>


    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 12,
    },
    icon: {
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: -4,
    },
    title: {
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "38px",
        paddingLeft: 5
    },
    content: {
        position: "relative",
    },
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        padding: "0px 15px",
        height: 27,
        background: "#F1F1F1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        minWidth: 95,
    },
    iconArrow: {
        position: "absolute",
        right: 15,
        top: "50%",
        marginTop: -4
    },
    customBtn: {
        borderRadius: "16px !important",
        background: `${theme.props.primaryColor} !important`,
        color: "#fff !important",
        fontWeight: 700,
        marginLeft: 20,
        height: 32,
        border: "none !important",
        "&:hover": {
            background: `${theme.props.primaryColor} !important`,
            color: "#fff",
            opacity: 0.8
        },
        "&:active": {
            background: `${theme.props.primaryColor} !important`,
            color: "#fff",
            opacity: 0.8
        },
    },
    inputClass: {
        width: '80px',
        padding: '0px 0px !important',
        marginLeft: 5,
        border: "none !important",
        outline: "none !important",
        background: "#F1F1F1",
        borderRadius: 25,
        height: 27,
        fontSize: 12,
        "&:hover": {
            // backgroundColor: "rgb(230, 230, 230)"
            border: "none !important",
            boxShadow: "none !important"
        },
        "&:focus-within": {
            border: "none !important",
            boxShadow: "none !important"
            // borderColor: "rgb(230, 230, 230) !important"
        },
        "&::placeholder": {
            opacity: 1,
            color: "#000"
        },
        '& .ant-input-number-handler-wrap': {
            top: '10% !important',
            height: '80% !important',
            right: '8px',
            border: 'none'
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-up': {
            background: "#F1F1F1",
            border: 'none',
            height: '12px !important',
            backgroundImage: `url(${publicIcon('arrow_up.svg')})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'inherit',
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-down': {
            background: "#F1F1F1",
            border: 'none',
            height: '12px !important',
            backgroundImage: `url(${publicIcon('arrow_down.svg')})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'inherit',
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-up .ant-input-number-handler-up-inner': {
            display: 'none'
        },
        '& .ant-input-number-handler-wrap .ant-input-number-handler-down .ant-input-number-handler-down-inner': {
            display: 'none'
        },
        '& .ant-input-number-input': {
            height: 27,
        }
    },
    textBefore: {
        marginLeft: 5,
    },
    buttonAdd: {
        border: 'none',
        color: '#5f6368',
        "&:hover": {
            backgroundColor: "#f9f9f9",
            color: '#5f6368',
            border: 'none',
        },
        "&:focus": {
            color: '#5f6368',
        },
        boxShadow: 'none',
        margin:"5px 0px"
    },
    buttonDelete: {
        border: 'none',
        marginLeft: 10,
        color: '#5f6368',
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: "transparent",
            border: 'none',
        },
        "&:focus": {
            backgroundColor: "transparent",
            color: '#5f6368',
        },

    }
}))

export default Reminders;
