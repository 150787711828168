const styles = theme => ({
    root: {
        padding: '0 15px',
        width: '100%',
        display: 'flex',
        flexDirection: "column"
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minWidth: "95px",
        paddingTop: 5,
        paddingBottom: 5,
    },
    center: {
        textAlign: "center"
    },
    title: {
        padding: '0',
        fontSize: '14px',
        fontWeight: 500,
        color: '#000'
    },
    numText: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: '#000',
        padding: "0 8px",
    },
    iconSvg: {
        display: 'flex',
        alignItems: 'center',
        background: "#f1f1f1",
        padding: 7,
        borderRadius: "50%",
        cursor: "pointer",
        width: "38px",
        height: "38px",
    },
    addIconSVG: {
        padding: 0,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "&:hover": {
            backgroundColor: 'unset'
        },
        "&:focus": {
            backgroundColor: 'unset'
        },
        margin: "0 8px"
    },
    iconActive: {
        color: theme.props.primaryColor
    },
    listIconTask: {
        flexWrap: "wrap",
        height: "unset",
        padding: 10,
        flexDirection: "column",
        margin: 0
    },
    button: {
        margin: "5px 0",
        background: theme.props.primaryColor,
        color: "#fff",
        border: "none",
        "&:hover": {
            background: theme.props.primaryColor,
            color: "#fff",
            border: "none",
            opacity: 0.8
        },
        "&:focus": {
            background: theme.props.primaryColor,
            color: "#fff",
            border: "none",
            opacity: 0.8
        },

    },
    boxItemName: {
        maxWidth: "50px",
        overflow: "hidden",
        margin: 0,
        textOverflow: "ellipsis",
        fontWeight: "bold",
        fontSize: "12px",
        whiteSpace: "nowrap"
    },
    boxItemNameActive: {
        maxWidth: "50px",
        overflow: "hidden",
        margin: 0,
        textOverflow: "ellipsis",
        fontWeight: "bold",
        fontSize: "12px",
        whiteSpace: "nowrap",
        color: theme.props.primaryColor,
    },
    titleChange: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: 30,
        position: "relative",
    },
    IconEdit: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 0
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    contentPopover: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    taskImg: {
        width: 24,
        height: 24,
        borderRadius: "50%",
    },
    textField: {
        padding: "0 15px 15px 15px",
        "& input": {
            width: 300,
            marginBottom: 15,
        }
    },
    name: {
        marginRight: 15,
        maxWidth: 400,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    groupBtn: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    listTag: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 10,
    }
})
export default styles;
