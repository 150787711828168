import {Calendar} from '@fullcalendar/core';
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import copyPastePlugin from 'fullcalendar-copy-paste'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import {getGlobal, setGlobal} from 'reactn';
import {getDate} from './getDate';
import {ColumnHear} from './columnHear';
import moment from 'moment';
import {EventResize} from './eventResize';
import {eventAltCopy, eventShiftCopy, PrevActionPopup} from '../../../actions/calendar';
import {EventReceive} from './eventReceive';
import {EventDrop} from './eventDrop';
import {cloneDeep, concat, filter, findIndex, isEmpty, isNumber, pick, pickBy, remove, uniqBy} from 'lodash';
import {addOneCalendarEventSource, refetchAll, refetchSource,} from '../../../actions/refetchSourcce';
import gtm from '../../../common/gtm';
import {eventContent} from './eventContent';
import {formatGeniamEvents} from '../../../context/common/getGeniamEvents';
import getEventInfo from '../../../common/getEventInfo';
import {getEventsFromReccrence, getGeniamEventsFromReccrence} from '../../../context/common/getGoogleEvents';
import {GCalendarEventFormat} from '../../../actions/googleCalendar';
import {extendedProps, isAccepted, isAllDay, isEventVote} from './infoEvent';
import store from "store";
import {COPYING_EVENT_KEY} from "../../../config/constants";
import getUserTimeZone from '../../../common/getUserTimeZone';
import momentTz from 'moment-timezone';
import {snapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';
import {eventsProxy} from '../../../store/proxy/events.proxy';
import {projectsProxy} from '../../../store/proxy/projects.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';
import {chartProxy} from '../../../store/proxy/chart.proxy';
import {formatEventSelect} from '../../../common/calendarView/getSelectEvent';
import {CtrlKeyStateProxy} from '../../../store/proxy/CtrlKeyState.proxy';

export const getSelectedEvents = (events = {}, start, end) => {
    const {geniamProjects} = snapshot(projectsProxy)
    let result = []
    geniamProjects.forEach(pro => {
        if (pro.selected && !pro.is_sync && !pro.is_deleted) {
            const eventProj = events[pro.id] || []
            const evt = cloneDeep(eventProj)
            const recurrence = remove(evt, item => item.recurrence)
            const recurrenceEdited = remove(evt, e => e.recurringEventId)
            const recurring = getGeniamEventsFromReccrence(recurrence, recurrenceEdited, end)
            const singleEvents = concat(evt, recurring)
            result = concat(result, getEventsInRange(singleEvents, start, end))
        }

    })
    return uniqBy(result, "id")
}

const getSelectedVoteEvents = (events = []) => {
    const {projects} = getGlobal()
    let result = []
    events.forEach(e => {
        if (!e.project_uuid) result.push(e)
        else {
            const idx = findIndex(projects, {id: e.project_uuid})
            if (idx > -1 && projects[idx].selected) result.push(e)
        }
    })
    return uniqBy(result, "id")
}

export const getGoogleSelectedEvents = (events = {}, start, end) => {
    const {googleCalendarList} = getGlobal()
    const {calendarUser} = snapshot(userProxy)
    const hiddenList = calendarUser?.idCalenderHidden || []
    let result = []
    if (!googleCalendarList?.length)
        return result
    googleCalendarList.forEach(pro => {
        if (pro.check && !hiddenList.includes(pro.id)) {
            let totalEvents = [...(events[pro.id]?.events || [])]

            const recurrenceEvents = remove(totalEvents, e => e.recurrence)
            const recurenceEdited = remove(totalEvents, e => e.recurringEventId)
            const recurring = getEventsFromReccrence(recurrenceEvents, recurenceEdited, end)
            const isHoliday = pro.accessRole === "reader" || pro.accessRole === "freeBusyReader"
            const formatedItems = GCalendarEventFormat(concat(totalEvents, recurring), pro.id, pro.backgroundColor, isHoliday, pro.primary)
            result = concat(result, getEventsInRange(formatedItems, start, end))
        }

    })
    return uniqBy(result, "id")
}

const getEventsInRange = (events, start, end) => {
    let result = []
    events.forEach(event => {
        if (moment(event.start).isBetween(start, end, null, "[]") ||
            moment(event.end).isBetween(start, end, null, "[]") ||
            (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))) {

            // truong hop event meeting sync khong co color
            if (event?.meetingId && event?.project_uuid && !event?.borderColor) {
                event = formatGeniamEvents(event)
            }
            result.push(event)
        }
    })
    return result
}
const changeTime = (event) => {
    const {ggEvents, geEvents} = getGlobal()
    const {project_uuid, is_google, id, googleEvent} = event
    if (is_google || googleEvent) {
        let idx = findIndex(ggEvents[project_uuid]?.events || [], {id})
        if (idx !== -1) {
            ggEvents[project_uuid].events[idx].start = event.start
            ggEvents[project_uuid].events[idx].end = event.end
            ggEvents[project_uuid].events[idx].allDay = event.allDay
            ggEvents[project_uuid].events[idx].updated = event.updated
            setGlobal({
                ggEvents: {...ggEvents}
            }, () => {
                // refetchSource("google")
            })
        }
        return
    }
    if (geEvents[project_uuid]?.events?.length) {
        let idx = findIndex(geEvents[project_uuid].events, {id})
        if (idx !== -1) {
            geEvents[project_uuid].events[idx].start = event.start
            geEvents[project_uuid].events[idx].end = event.end
            geEvents[project_uuid].events[idx].allDay = event.allDay
            setGlobal({
                geEvents: {...geEvents}
            }, () => {
                // refetchSource("geniam")
            })
        }

    }
}

const checkAfter = (listCreated = [], created) => {
    let firstCreated = null
    for (let i = 0; i < listCreated?.length; i++) {
        if (!firstCreated && moment(created).isAfter(moment(listCreated[i]))) {
            firstCreated = {index: i, created: listCreated[i]}
            continue;
        }
        if (firstCreated && moment(firstCreated.created).isAfter(moment(listCreated[i]))) {
            firstCreated = {index: i, created: listCreated[i]}
        }
    }
    return firstCreated
}
const unique = (arr) => {
    let newArr = []
    newArr = arr.filter((item) => {
        return newArr.includes(item.title) ? '' : newArr.push(item.title)
    })
    return newArr
}

const getLastTitle = (events = []) => {
    const filteredEvents = unique(events)
    const result = []
    const listCreateAt = []
    filteredEvents?.forEach((e, idx) => {
        // console.log(e)
        e.created = e?.created || e?.created_at
        if (!e?.created) return null
        if (idx < 5) {
            result.push(e)
            listCreateAt.push(e.created)
            return null;
        }
        const checkCreated = checkAfter(listCreateAt, e.created)
        if (checkCreated) {
            listCreateAt.splice(checkCreated.index, 1, e.created)
            const index = result.findIndex(e => moment(e.created).isSame(checkCreated.created))
            result.splice(index, 1, e)
        }
    })
    const arr = result.map(e => ({title: e.title, created: e.created}))
    const newarr = arr.sort((a, b) => {
        return moment(a.created).diff(b.created)
    })
    return newarr.reverse();
}

const checkToday = (startDate) => {
    if (!isNumber(startDate)) startDate = 1
    const today = new Date()
    const idxToday = today.getDay()
    if (idxToday >= startDate) {
        return startDate + 7
    } else return startDate - 7
}

const onPressDelete = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
        if (Boolean(getGlobal().clickInfo)) {
            window.onkeydown = null;
            return;
        }
        const selectedEvent = {
            id: window['pressDeleteEvent'].id,
            ...window['pressDeleteEvent'].extendedProps
        }
        const {geEvents, ggEvents} = getGlobal()
        if (selectedEvent.project_uuid in ggEvents) {
            if (!ggEvents[selectedEvent.project_uuid].events.some(e => e.id === selectedEvent.id)) {
                window.onkeydown = null;
                return;
            }
        }
        if (selectedEvent.project_uuid in geEvents) {
            if (!geEvents[selectedEvent.project_uuid].events.some(e => e.id === selectedEvent.id)) {
                window.onkeydown = null;
                return;
            }
        }
        setGlobal({pressDeleteEvent: selectedEvent})
    }
}

export function CalendarInit(numberId, classes, setEditInfo, setVotesSelect, durationWeek, focusColor, setLastTitleGe, setLastTitleGg) {
    const {currentTheme} = snapshot(themesProxy)
    const {calendarUser} = snapshot(userProxy)
    const {viewCalendar = "CustomMonth"} = currentTheme
    const firstDay = checkToday(calendarUser?.startDate)

    const calendarEl = document.getElementById(`calendarDiv${numberId}`);
    const thickLine = calendarUser?.thickLine || JSON.parse(localStorage.getItem('thickLine')) || [12, 18, null, null]
    window['calendar' + numberId] = new Calendar(calendarEl, {
        plugins: [timeGridPlugin, interactionPlugin, momentPlugin, momentTimezonePlugin, copyPastePlugin],
        height: "100%",
        contentHeight: 'auto',
        expandRows: true,
        handleWindowResize: true,
        locale: 'ja-jp',
        timeZone: getUserTimeZone(),
        slotDuration: '01:00:00',
        snapDuration: '00:15:00',
        slotMinTime: calendarUser?.times?.minTime?.label || '07:00',
        slotMaxTime: calendarUser?.times?.maxTime?.label || '24:00',
        titleFormat: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric'
        },
        eventMinHeight: 1,
        slotLabelFormat: "HH:mm",
        eventDurationEditable: true,
        slotEventOverlap: false,
        initialView: `${viewCalendar}`,
        initialDate: getDate(numberId).date,
        columnHeaderFormat: {day: '2-digit', weekday: 'short', meridiem: false, omitCommas: false},
        headerToolbar: false,
        views: {
            CustomMonth: {
                type: 'timeGrid',
                duration: {month: 1},
            },
            CustomWeek: {
                type: 'timeGrid',
                duration: {week: 1},
            },
            CustomDay: {
                type: "timeGrid",
                duration: {day: durationWeek || 1}
            }
        },
        firstDay: firstDay,
        progressiveEventRendering: true,
        nowIndicator: true,
        droppable: true,
        selectable: true,
        selectMirror: true,
        editable: true,
        weekends: true,
        allDaySlot: true,
        allDayText: gtm(getUserTimeZone()),
        eventTextColor: 'black',
        // displayEventTime: true,
        eventResizableFromStart: true,
        dayHeaders: true,
        dayHeaderContent: function (args) {
            return {html: ColumnHear(args)}
        },
        eventContent: function (arg) {
            return eventContent(arg, classes, numberId, focusColor)
        },
        eventClassNames: function (arg) {
            return arg.isPast ? "pastEvent" : ""
        },
        eventSources: [
            {
                events: async function (fetchInfo, successCallback, failureCallback) {
                    const {start, end} = fetchInfo
                    try {
                        const {googleEvents} = snapshot(eventsProxy)
                        const {calenviewIdChange} = snapshot(chartProxy)
                        const zone = getUserTimeZone()
                        let ggevents = getGoogleSelectedEvents(googleEvents, start, momentTz(end).tz(zone).format('YYYY-MM-DD'))
                        successCallback(ggevents)
                        LineThick(numberId, thickLine)
                        calenviewIdChange(numberId)
                        setLastTitleGg(getLastTitle(ggevents))
                    } catch (e) {
                        console.log(e)
                        failureCallback(e)
                    }
                },
                id: 'google'
            },
            {
                events: async function (fetchInfo, successCallback, failureCallback) {
                    const {start, end} = fetchInfo
                    try {
                        const {geniamEvents} = snapshot(eventsProxy)
                        const {calenviewIdChange} = snapshot(chartProxy)
                        let result = getSelectedEvents(geniamEvents, start, end)
                        successCallback(result)
                        LineThick(numberId, thickLine)
                        calenviewIdChange(numberId)
                        setLastTitleGe(getLastTitle(result))

                    } catch (e) {
                        failureCallback(e)
                        console.log(e);
                    }

                },
                id: 'geniam'
            },
            {
                events: function (fetchInfo, successCallback, failureCallback) {
                    try {
                        const {voteEvents} = snapshot(eventsProxy)
                        let _voteEvents = getSelectedVoteEvents(voteEvents)
                        successCallback(filter(_voteEvents, v => !v.isSync))
                        LineThick(numberId, thickLine)
                    } catch (e) {
                        failureCallback(e)
                    }

                },
                id: 'vote'
            },
        ],
        previewCopy: false,
        eventCopy: async (trigger) => {
            if (!trigger.event.extendedProps?.recurringEventId) {
                PrevActionPopup()
            }
            await EventDrop(trigger, changeTime, numberId, trigger.type === 'copy' || trigger.type === 'duplicate')
            trigger.type === 'cut' && refetchSource('google');
        },
        eventLeave: (info) => {
            try {
                info.event.remove()
            } catch (e) {
                console.log(e)
            }

        },
        eventResize: async (info) => {
            window['i'] = numberId
            EventResize(info, changeTime, numberId)
            if (!info.event.extendedProps?.recurringEventId) {
                PrevActionPopup()
            }

        },
        select: (info) => {
            window['i'] = numberId
            const event = formatEventSelect(info)
            setGlobal({
                clickInfo: event
            })
        },
        eventDragStart: (info) => {

            const specialKeys = pickBy(pick(info.jsEvent, ['altKey', 'ctrlKey', 'metaKey', 'shiftKey']))
            const {ctrlKeyDown} = snapshot(CtrlKeyStateProxy)
            if (ctrlKeyDown) specialKeys['ctrlKey'] = true;

            if (!isEmpty(specialKeys)) {
                // window['calendar' + numberId].addEvent(getEventInfo(info))
                const event = getEventInfo(info)
                store.set(COPYING_EVENT_KEY, info.event)
                store.set(COPYING_EVENT_KEY + '_pressed', specialKeys)
                addOneCalendarEventSource(event, numberId)
            } else {
                removeCopyingDataFromLocalStorage()
            }
            window['i'] = numberId
            setGlobal({
                isDragging: true,
                hoverEvent: {
                    title: '',
                    start: '',
                    end: '',
                    isAccepted: false
                },
                hoverEl: null
            })
        },
        eventAllow: function (dropInfo, draggedEvent) {
            return !draggedEvent._def.extendedProps.isConnectMetamors?.eventId
        },
        eventDrop: async (info) => {
            window['i'] = numberId
            if (await dragCopyEventAsync(info.event, numberId)) {
                await setGlobal({
                    loading: false,
                })
                return;
            }
            if (!info.event.extendedProps?.recurringEventId) {
                PrevActionPopup()
            }
            return EventDrop(info, changeTime, numberId)
        },
        eventReceive: async (info) => {
            window['i'] = numberId

            if (await dragCopyEventAsync(info.event, numberId)) {
                info.event.remove()
                return;
            }

            if (!info.event.extendedProps?.recurringEventId) {
                PrevActionPopup()
            }
            EventReceive(info, changeTime, numberId)
            setGlobal({isDragging: false})
        },
        eventClick: async (info) => {
            const event = info.event;
            // console.log(event);
            if (event.source?.id === "background" || !event.title) {
                return;
            }
            if (event.extendedProps.source !== 'metamor' || isEventVote(event) || isAccepted(event)) {
                window['i'] = numberId
                setEditInfo({event: getEventInfo(info), el: info.el})
            }
            const {clickInfo} = getGlobal()
            if (clickInfo) {
                setGlobal({
                    clickInfo: null
                })
                refetchAll().catch(console.log)
            }
        },

        eventMouseEnter: info => {
            window['pressDeleteEvent'] = info.event
            window.addEventListener('keydown', onPressDelete)
            const event = info.event;
            const description = event?._def?.extendedProps?.description
            const exProps = extendedProps(event)
            if (exProps.background || exProps.timeWork) {
                return setGlobal({
                    hoverEventStart: event.start
                })
            }
            setGlobal({
                hoverEvent: {
                    title: event.title,
                    start: event.start,
                    end: event.end,
                    description: description,
                    isAccepted: isAccepted(event),
                    eventInfo: event,
                    allDay: isAllDay(event)
                },
                hoverEl: info.el,
                hoverEventStart: event.start
            })
        },
        eventMouseLeave: info => {
            window.removeEventListener('keydown', onPressDelete)
            const event = info.event;
            const description = event?._def?.extendedProps?.description
            setGlobal({
                hoverEvent: {
                    title: '',
                    start: '',
                    end: '',
                    description: description,
                    isAccepted: false
                },
                hoverEl: null,
            })
        },
    })
    // const container = document.getElementById("calendar-container" + numberId)
    window['calendar' + numberId].render()
    LineThick(numberId, thickLine)
    // thickLine render

}

export const LineThick = (numberId, thickLine) => {
    if (window['calendar' + numberId] && window['calendar' + numberId]?.el) {
        try {
            let trTag = window['calendar' + numberId].el.querySelectorAll('.fc-timegrid-slot-lane ')
            if (trTag && trTag.length !== 0) {
                for (let item of trTag) {
                    if (item.dataset.time && thickLine.includes(parseInt(item.dataset.time.split(':')[0]))) {
                        item.parentElement.style.borderTop = '2px solid #616161'
                        // item.parentElement.style.borderTopWidth = 'thick'

                    }
                }
            }
        } catch (e) {

        }
    }
}


export const headerHeight = (numberId) => {
    // if (window['calendar' + numberId]) {
    //     const el = window['calendar' + numberId]?.el.querySelector(".fc-day-grid")
    //     if (!el)
    //         return
    //     const height = el.offsetHeight
    //     const content = window['calendar' + numberId].el.querySelector(".fc-time-grid-container")
    //     if (!content) {
    //         return;
    //     }SelectIconsDialog.js
    //     content.style.maxHeight = `calc(100% - ${height + 14}px)`
    //     window['calendar' + numberId].updateSize()
    //
    // }
}

///helpers
// const eventStartEnd = (event) => {
//     return pick(event, ['start', 'end'])
// }
const removeCopyingDataFromLocalStorage = () => {
    store.remove(COPYING_EVENT_KEY)
    store.remove(COPYING_EVENT_KEY + '_pressed')
    const {setCtrlKeyState} = snapshot(CtrlKeyStateProxy)
    setCtrlKeyState(false)
}
const dragCopyEventAsync = async (event, numberId) => {
    const copyingEvent = store.get(COPYING_EVENT_KEY)
    const pressed = store.get(COPYING_EVENT_KEY + '_pressed') || {}
    removeCopyingDataFromLocalStorage()
    if (copyingEvent) {
        setGlobal({
            loading: true,
        })
        const {altKey, metaKey, ctrlKey, shiftKey} = pressed
        if (altKey || metaKey || ctrlKey) {
            // console.log('eventAltCopy')
            await eventAltCopy(event, numberId, copyingEvent)
        } else if (shiftKey) {
            // console.log('eventShiftCopy')
            await eventShiftCopy(event, numberId, copyingEvent)
        }
        setGlobal({
            loading: false,
        })
        return true
    }
    return false
}

