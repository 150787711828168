import {proxy} from 'valtio/vanilla';


const tokenProxy = proxy({
    accessToken: null,
    refreshToken: null,
    firebaseToken: null,
    setToken: (data, callback) => {
        tokenProxy.accessToken = data.accessToken
        tokenProxy.refreshToken = data.refreshToken
        tokenProxy.firebaseToken = data.firebaseToken
        if (typeof callback === "function") {
            callback()
        }
    },

})

export {
    tokenProxy
}
