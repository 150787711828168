import React, {useEffect, useLayoutEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {makeOverlayPath} from '../../common/makeOverlayPath';
import {createPopper} from '@popperjs/core';
import DialogTutorial from '../Custom/DialogTutorial';
import {useTutorial} from '../../context/TutorialContext';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';

function Tutorial({steps = [], initStep = 0}) {
    const [current, setCurrent] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(initStep)
    const {onPlay} = useTutorial()
    const [path, setPath] = useState('')
    const classes = useStyles()
    const el = document.querySelector(current?.el)
    const [isNextKey] = useKeyboardJs('right')
    const [isPrevKey] = useKeyboardJs('left')
    useEffect(() => {
        if (!steps?.length) {
            setCurrent(null)
            setCurrentIndex(0)
            return;
        }
        setCurrent(steps[initStep])
        setCurrentIndex(initStep)
        // eslint-disable-next-line
    }, [steps, initStep])

    const play = async (el) => {
        if (el) {
            if (current?.beforePlay) {
                await current.beforePlay()
            }
            setPath(makeOverlayPath(el.getBoundingClientRect()))
            const tooltip = document.querySelector('#tooltip');
            createPopper(el, tooltip, {
                placement: current?.placement || 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
        }
    }

    useEffect(() => {
        if (isNextKey && document.getElementById('tutorial-element')) {
            //next handle
            if (currentIndex >= steps.length - 1)
                return;
            handleNext()
        }
        // eslint-disable-next-line
    }, [isNextKey])
    useEffect(() => {
        if (isPrevKey && document.getElementById('tutorial-element')) {
            handlePrev()
        }
        // eslint-disable-next-line
    }, [isPrevKey])

    useLayoutEffect(() => {
        play(el)
        // eslint-disable-next-line
    }, [el])
    const handleNext = () => {
        if (currentIndex >= steps.length - 1)
            return handleClose()
        setCurrentIndex(currentIndex + 1)
        setCurrent(steps[currentIndex + 1])
    }

    const handlePrev = () => {
        if (currentIndex <= 0)
            return;
        setCurrentIndex(currentIndex - 1)
        setCurrent(steps[currentIndex - 1])
    }
    const handleClose = () => {
        onPlay(null)
    }
    return (
        <div>
            {
                el ?
                    <svg className={classes.overlaySvg} onClick={handleClose} id={'tutorial-element'}>
                        <path d={path}/>
                    </svg>
                    :
                    null
            }

            <div id="tooltip" role="tooltip"
                 className={current?.el === "#calendarDiv0" ? classes.tooltipFix : classes.tooltip}
                 style={el ? {display: "block"} : {display: 'none'}}>
                <DialogTutorial title={current?.title} content={current?.content} numberSteps={steps.length}
                                img={current?.img}
                                onNext={handleNext}
                                onPrev={handlePrev}
                                onClose={handleClose}
                                currentIndex={currentIndex}
                                note={current?.note}
                />
                <div id="arrow" data-popper-arrow/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    overlaySvg: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9000,
        opacity: 0.5,
        transition: 'all 0.5s',
        // pointerEvents: 'none'
    },
    tooltip: {
        backgroundColor: "#fff",
        zIndex: 9999,
        padding: 10
    },
    tooltipFix: {
        backgroundColor: "#fff",
        zIndex: 9999,
        padding: 10,
        // position: "fixed !important",
        bottom: "10px !important",
        inset: "unset !important",
        transform: "translateX(70%) !important",
        // left: "50%!important"
    },
}))

export default Tutorial;
