import React, {useState} from 'react';
import {useGlobal} from 'reactn'
import Popover from "@material-ui/core/Popover";
import {db} from "../../../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../../config/constants";
import useIntlId from "../../../../hooks/useIntlId";
import CancelButton from "../../../Dialog/CancelButton";
import DeleteButton from "../../../Dialog/DeleteButton";
import {msgSuccess} from '../../../../utils/msg';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';

function PopoverDelete({classes, anchorDelete, setAnchorDelete}) {
    const {user} = useSnapshot(userProxy)
    const [, setLoading] = useState(false)
    const [delConfirmIntl, deletedIntl] = useIntlId(['app.setting.confirmDeleteSetting', 'event.toast.deleted'])

    const handleDelete = async () => {
        setLoading(true)
        if (!user)
            return null
        try {
            const displaySettingsDocRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/settings/${anchorDelete.id}`)
            setAnchorDelete(null)
            await displaySettingsDocRef.delete()
            msgSuccess(`${deletedIntl}`)
        } catch (e) {
            console.log(e.toString())
        } finally {
            setLoading(false)
        }
    }
    const handleClose = () => {
        setAnchorDelete(null)
    }
    if (!anchorDelete)
        return null
    return (
        <Popover
            open={Boolean(anchorDelete)}
            anchorEl={anchorDelete?.currentTarget}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handleClose}
            className={`${classes.removePopup} ${classes.marginTop10}`}
        >
            <div>
                <div>{delConfirmIntl}</div>

                <div className={'clf-flex-center'}>
                    <CancelButton
                        onClick={handleClose}
                    />
                    <DeleteButton
                        onClick={handleDelete}
                    />
                </div>
            </div>
        </Popover>
    );
}

export default PopoverDelete;
