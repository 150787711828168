import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';
import {Popover} from 'antd';
import ContentHoverView from './Views/ContentHoverView';
import useIntlId from '../../hooks/useIntlId';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import {ReactComponent as EyeSVG} from '../../asSvg/eyes.svg';
import MyWorkSpace from './MyWorkSpace/MyWorkSpace';
import {publicIcon} from '../Calendar/handlers/infoEvent';
import {myCal} from '../../common/myCal';
import qs from 'query-string';
import {useHistory} from 'react-router-dom';
import openNewTab from '../../common/openNewTab';
import {getNumDayAdvanceView} from '../../common/calendarView/getNumDayAdvanceView';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {cloneDeep} from 'lodash';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

const Box = styled.div(props => ({
    backgroundColor: props?.backgroundColor ? `${props?.backgroundColor}` : "#f1f1f1",
    borderRadius: "2px",
    border: "solid 1px #fff",
    width: props?.width ? `${props?.width}%` : "50%",
    // height: props?.height ? `${props?.height}%` : "50%",
    height: "100%",
    display: "inline-flex"
}));

function ListCustomView(props) {
    const classes = useStyles()
    const {list, currentTheme} = useSnapshot(themesProxy)
    const [viewMode] = useIntlId(['milestone.viewMode'])
    const [themeCustom, setThemeCustom] = useState(null)
    const {viewCalendar, idTheme} = qs.parse(window.location.search)
    const history = useHistory()
    const {plan} = useSnapshot(announcementProxy)

    const onSelectSetting = async (event, item) => {
        if (!item?.colorPrimary) return
        try {
            const userRef = myCal()
            let link = `${window.location.origin}?idTheme=${item?.id}`
            const next = openNewTab(event, link)
            if (next) return
            if (viewCalendar || idTheme) {
                history.push('/')
                await userRef.set({
                    themeCustom: {
                        ...item,
                        calendarLeftView: Boolean(item.calendarLeftView)
                    }
                }, {merge: true})
            }
            if (currentTheme?.id !== item?.id && !idTheme) {
                await userRef.set({
                    themeCustom: {
                        ...item,
                        calendarLeftView: Boolean(item.calendarLeftView)
                    }
                }, {merge: true})

            }
        } catch (e) {
            console.log(e);
        }
    }
    const newArray = (number) => {
        let newArr = []
        for (let i = 0; i < number; i++) {
            newArr.push(i)
        }
        return newArr
    }
    const onClose = () => {
        setThemeCustom(null)
    }
    const onEditTheme = (item) => {
        setThemeCustom(item)
    }

    return (
        <div className={classes.containner}>
            <div id={'calendar-viewbox-popover-setting'}>
                <CLFButtonSVG
                    name={viewMode}
                    size={'large'}
                    iconComponent={<EyeSVG/>}
                    minWidth={'130px'}
                    minHeight={'40px'}
                    margin={"0 5px"}
                    background={currentTheme?.colorPrimary}
                    borderColor={currentTheme?.colorPrimary}
                    className={classes.buttonAntd}
                    onClick={() => {
                        onEditTheme(cloneDeep(currentTheme))
                    }}
                />
            </div>
            {
                list?.length > 0 &&

                <div className={classes.root}>
                    {
                        list.map((item, index) => {
                            if (plan.numOfView - 1 < index)
                                return null
                            if (!item.viewCalendar)
                                return null;
                            let numColumn = item?.viewCalendar === "CustomDay" ? 1 : item?.columns

                            return (
                                <Popover
                                    content={
                                        <ContentHoverView
                                            listCustom={list}
                                            item={item}
                                            onEditTheme={onEditTheme}
                                        />
                                    }
                                    key={item.id}
                                    destroyTooltipOnHide={{keepParent: false}}
                                    overlayClassName={classes.popover}>

                                    <div className={[classes.box, 'hover:scale-[1.2]']}
                                         onClick={(event) => onSelectSetting(event, item)}
                                    >
                                        <div className={classes.view}>

                                            {
                                                !viewCalendar && parseInt(item.id) === parseInt(currentTheme?.id) ?
                                                    <img className={classes.check}
                                                         src={publicIcon('view/checkActive.svg')}
                                                         alt=""/>
                                                    :
                                                    <span>
                                                        {item?.advanceView ? `${getNumDayAdvanceView(item)}`
                                                            :
                                                            ` ${item?.viewCalendar?.substr(6, 1)}`
                                                        }
                                                    </span>

                                            }
                                        </div>
                                        {
                                            newArray(item?.rows).map((row, index) => {
                                                let height = item?.rows > 4 ? 100 / 4 : 100 / item?.rows

                                                return (
                                                    <div key={index} style={{height: `${height}%`}}>
                                                        {
                                                            newArray(numColumn).map((col, index) => {
                                                                let width = (100 / numColumn)
                                                                // let height = 100 / item?.rows
                                                                return (
                                                                    <Box width={item?.columns > 4 ? 100 / 4 : width}
                                                                         backgroundColor={item?.colorPrimary}
                                                                        // height={height}
                                                                         key={index}/>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Popover>
                            )
                        })
                    }

                </div>
            }

            {
                Boolean(themeCustom) &&
                <MyWorkSpace listCustom={list}
                             itemTheme={themeCustom}
                             setItemTheme={setThemeCustom}
                             onClose={onClose}
                />
            }
        </div>
    );
}

export default React.memo(ListCustomView)
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        padding: "4px 15px",
        borderRadius: "25px"
    },
    popover: {
        "& .ant-popover-inner-content": {
            padding: 10
        },
        "& .ant-popover-content": {
            borderRadius: "10px",
            overflow: "hidden"
        }
    },
    box: {
        width: 45,
        height: 35,
        margin: "0 2px",
        position: "relative",
        cursor: "pointer",
        overflow: "hidden"
    },
    view: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "20px",
        fontWeight: 700,
        color: "#000",
        WebkitTextStrokeWidth: "1px",
        WebkitTextStrokeColor: "#fff",
    },
    check: {
        width: 40
    },
    buttonAntd: {
        textTransform: 'uppercase',
        backgroundColor: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        "& svg": {
            marginLeft: 5
        }
    },
    containner: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}))
