import React from 'react';
import {Grid, IconButton, withStyles} from "@material-ui/core";
import {isReadOnly} from "../../../actions/group";
import {CheckOutlined, Settings} from "@material-ui/icons";
import styled from "styled-components";
import useIntlId from "../../../hooks/useIntlId";
import {useSnapshot} from 'valtio';
import {projectsProxy} from '../../../store/proxy/projects.proxy';
import {settingDialogProxy} from '../../../store/proxy/settingDialog.proxy';
import {getGlobal} from 'reactn';
import {isFunction} from 'lodash';
import {getAllPrj} from '../../../actions/googleCalendar';

const ProjectItem = styled.button`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 10px;
    border-radius: 25px;
    height: 27px;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
        //transform: scale(1.1);
    }
;
    border: none;
    color: #fff;
    background-color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};

`
const ProjectItemActive = styled(ProjectItem)`
    border: ${props => props.item ? `solid 2px ${props.item.color || props.item.backgroundColor}` : "none"};
    color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};
    background-color: #fff;
    padding: 4px 15px 4px 10px;
`

const styleFlex = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}

function ProjectList(props) {
    const {
        classes,
        event,
        setEvent,
        isCreate,
        onClose
    } = props

    const [tagProject] = useIntlId(['app.profile.projects'])
    const {geniamProjects, googleProjects} = useSnapshot(projectsProxy)
    const {googleCalendarList} = getGlobal()
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const isReader = (role) => {
        return ['freeBusyReader', 'reader'].includes(role)
    }

    if (!event)
        return null
    return (
        <div className={`${classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <div style={{width: "100%"}}>
                <div className={classes.label} style={{fontWeight: "bold", fontSize: 14}}>{tagProject}</div>
                <div style={styleFlex}>
                    <Grid container>
                        {getAllPrj().map((item, index) => {
                            if ((isCreate && isReader(item?.accessRole)) || (!event?.is_holiday && isReader(item?.accessRole)))
                                return null
                            if ((!event.isEventVote || !event.project_uuid) && ((!Boolean(item.is_sync) && !isReadOnly(item)) || (!Boolean(item.is_sync) && event.is_holiday))) {
                                return (
                                    <Grid item key={index} xs={2} className={classes.itemProject}>
                                        {
                                            item.id === event.project_uuid ?
                                                <div className={classes.relative}>
                                                    <ProjectItemActive
                                                        disabled={event.is_holiday}
                                                        onClick={() => {
                                                            if (Boolean(event?.isConnectMetamors)) return;
                                                            setEvent({
                                                                ...event,
                                                                project_uuid: item.id,
                                                                borderColor: item.color,
                                                                is_google: Boolean(item.is_google),
                                                                googleEvent: Boolean(item.is_google),
                                                                ...(!event.colorId && event.borderColor === event.backgroundColor && {backgroundColor: item.color})
                                                            })
                                                        }}
                                                        item={item}
                                                    >
                                                        {item.name || item.summary}
                                                    </ProjectItemActive>
                                                    <CheckOutlined className={classes.iconActive}/>

                                                </div>
                                                :
                                                <ProjectItem
                                                    disabled={event.is_holiday}
                                                    onClick={() => {
                                                        if (Boolean(event?.isConnectMetamors)) return;
                                                        setEvent({
                                                            ...event,
                                                            project_uuid: item.id,
                                                            borderColor: item.color,
                                                            is_google: Boolean(item.is_google),
                                                            googleEvent: Boolean(item.is_google),
                                                            ...(!event.colorId && event.borderColor === event.backgroundColor && {backgroundColor: item.color})
                                                        })
                                                    }}
                                                    item={item}
                                                >
                                                    {item.name || item.summary}
                                                </ProjectItem>
                                        }
                                    </Grid>
                                )
                            }
                            if (event.isEventVote) {
                                return (
                                    <Grid item key={index} xs={2} className={classes.itemProject}>
                                        {
                                            item.uuid === event.project_uuid ?
                                                <div className={classes.relative}>

                                                    <ProjectItemActive
                                                        disabled={event.is_holiday}
                                                        onClick={() => {
                                                            if (Boolean(event?.isConnectMetamors)) return;
                                                            setEvent({
                                                                ...event,
                                                                project_uuid: item.uuid,
                                                                borderColor: item.color,
                                                                is_google: Boolean(item.is_google),
                                                                googleEvent: Boolean(item.is_google),
                                                                ...(!event.colorId && event.borderColor === event.backgroundColor && {backgroundColor: item.color})
                                                            })
                                                        }}
                                                        item={item}
                                                    >
                                                        {item.name || item.summary}
                                                    </ProjectItemActive>
                                                    <CheckOutlined className={classes.iconActive}/>
                                                </div>
                                                :
                                                <ProjectItem
                                                    disabled={event.is_holiday}
                                                    onClick={() => {
                                                        if (Boolean(event?.isConnectMetamors)) return;
                                                        setEvent({
                                                            ...event,
                                                            project_uuid: item.uuid,
                                                            borderColor: item.color,
                                                            is_google: Boolean(item.is_google),
                                                            googleEvent: Boolean(item.is_google),
                                                            ...(!event.colorId && event.borderColor === event.backgroundColor && {backgroundColor: item.color})
                                                        })
                                                    }}
                                                    item={item}
                                                >
                                                    {item.name || item.summary}
                                                </ProjectItem>
                                        }
                                    </Grid>
                                )
                            }
                            return null
                        })}
                    </Grid>
                    <div>
                        <IconButton disableRipple={true} onClick={() => {
                            handleOpen(4)
                            return isFunction(onClose()) ? onClose() : ''
                        }}>
                            <Settings/>
                        </IconButton>

                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = theme => ({
    btn: {
        backgroundColor: 'white',
        border: 'none',
        // width: "calc(25% - 8px)",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "4px 10px",
        borderRadius: "25px",
        height: 27,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    services: {
        backgroundColor: 'white',
        color: 'black',
        // width: "calc(25% - 8px)",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "4px 10px",
        borderRadius: "25px",
        height: 27,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    root: {
        display: 'flex', paddingBottom: 10,
        justifyContent: "space-between",
        fontSize: 12,
    },
    gridContainer: {
        width: '100%'
    },
    itemProject: {
        padding: 3,
        boxSizing: "border-box"
    },
    relative: {
        position: "relative",
    },
    iconActive: {
        position: "absolute",
        top: "50%",
        width: 18,
        height: 18,
        marginTop: -9,
        right: 5,
        color: "#1790FF"
    }
})


export default withStyles(styles)(ProjectList)
