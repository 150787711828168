import axios from 'axios';
import {IS_PRODUCTION, LOGIN_URL, LOGOUT_URL} from '../../config/constants';
import {auth} from '../../config/firebaseConfig';
import removeAllTokens from '../removeAllToken';
import removeLoginOrRegisterKeys from '../removeLoginOrRegisterKeys';

export const onLogout = async () => {
    try {
        await axios.post(LOGOUT_URL);
        await auth.signOut()
        removeAllTokens();
        removeLoginOrRegisterKeys()
        localStorage.clear()
        window.location = `${LOGIN_URL}?redirect_url=${window.location.href}`
    } catch (e) {
        console.log(e)
    }
};
export const onOpenMyAccount = () => {
    window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account`, '_blank')
}

