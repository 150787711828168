import {db} from '../../config/firebaseConfig'
import {CALENDAR_COLLECTION} from "../../config/constants";
import firebase from "firebase/compat/app";
import {findIndex} from 'lodash'

const pushServerPublicKey = "BDcF29uWfsv5hGDpgMXuih9geeCIArtjxCek2ZauX6yEogUbGz7CnGGYBOkXYhCzFKKUBHQuWImLu0x9PZEYYVM";

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

async function askUserPermission() {
    return await Notification.requestPermission();
}


function registerServiceWorker() {
    return navigator.serviceWorker.register("/calendar-service-worker.js");
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(pushServerPublicKey)
    });
}


function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
        .then(function (serviceWorker) {
            return serviceWorker.pushManager.getSubscription();
        })
        .then(function (pushSubscription) {
            return pushSubscription;
        });
}

async function subscriptionToDb(sub, userId) {
    const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
    const snap = await userRef.get()
    if (!snap.exists) {
        await userRef.set({
            subscriptions: firebase.firestore.FieldValue.arrayUnion(sub)
        }, {merge: true})
        return;
    }
    const data = snap.data()
    let idx = findIndex(data.subscriptions || [], item => item?.endpoint === sub?.endpoint)
    if (idx === -1) {
        await userRef.set({
            subscriptions: firebase.firestore.FieldValue.arrayUnion(sub)
        }, {merge: true})

    }

}

async function subscribeUserToPush(user) {
    try {
        const registration = await navigator.serviceWorker.register('/calendar-service-worker.js')

        let sub = await registration.pushManager.getSubscription()
        console.log(JSON.stringify(sub))
        // if(!sub){
        //     const subscribeOptions = {
        //         userVisibleOnly: true,
        //         applicationServerKey: urlBase64ToUint8Array(
        //             pushServerPublicKey
        //         )
        //     };
        //
        //      sub = await registration.pushManager.subscribe(subscribeOptions);
        //     console.log('Received PushSubscription: ', JSON.stringify(sub));
        // }
        // const subscribeOptions = {
        //     userVisibleOnly: true,
        //     applicationServerKey: urlBase64ToUint8Array(
        //         pushServerPublicKey
        //     )
        // };
        //
        // return registration.pushManager.subscribe(subscribeOptions)
        //
        // .then(function(pushSubscription) {
        //     console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
        //     return pushSubscription;
        // });
    } catch (e) {

    }

}

export {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    subscriptionToDb,
    subscribeUserToPush
};
