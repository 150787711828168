import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import axios from "axios";
import {API_ZOOM} from "../../../config/constants";
import DialogDeleteZoomMeeting from "./DialogDeleteZoomMeeting";
import {cloneDeep, remove} from 'lodash';
import {Grid} from "@material-ui/core";
import {ReactComponent as ZoomSvg} from "../../../asSvg/zoomIcon.svg";
import ClfLinearProgress from "../../Custom/CLFLinearProgress";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import DeleteButton from "../../Dialog/DeleteButton";
import {deleteZoom, findCalendarMeetingId} from "../../../actions/zoom";
import useIntlId from '../../../hooks/useIntlId';
import {zoneObj} from '../../../utils/time';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '10px',
        background: '#FFF',
        borderRadius: 14
    },
    ListTitle: {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',
        fontWeight: "bold",
        fontSize: '1.2rem',
        textAlign: 'center'
    },
    cardMeeting: {
        padding: '10px',
        background: '#dedede57',
        borderRadius: 14,
        marginBottom: 10
    },
    title: {
        fontWeight: 'bold',
        width: '80%',
    },
    nowTimeMeeting: {
        color: 'red',
        fontWeight: 'bold',
        marginLeft: '20px'
    },
    comingUp: {
        fontStyle: 'italic',
        marginLeft: '20px'
    },
    DateMeetingToDay: {
        color: 'red'
    },
    DateMeeting: {
        color: 'black'
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    },
    noData: {
        textAlign: 'center',
        margin: 'auto'
    }
}));

function Zooms(props) {
    const classes = useStyles();
    const [zoomOAuth] = useGlobal('zoomOAuth');
    const [zoomMeetings, setZoomMeetings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [open, setOpen] = useState(null);
    const [noZoomDataIntl,comingUp,mettingID,today] = useIntlId(['cal.noZoomData','setting.zoom.comingUp','app.notification.mettingId','milestone.today'])

    useEffect(() => {
        if (zoomOAuth?.zoomOAuth)
            getZoomMeetingList()
        // eslint-disable-next-line
    }, [zoomOAuth?.zoomOAuth]);

    const getZoomMeetingList = async () => {
        setLoading(true);
        try {
            const {data} = await axios.get(API_ZOOM + '/getMeetings')
            setZoomMeetings(data)
        } catch (e) {
            console.log(e.toString())
        } finally {
            setLoading(false)
        }
    };

    const onDelete = async () => {
        setLoadingDelete(true);
        const _zoomMeetings = cloneDeep(zoomMeetings)
        try {
            if (!open)
                return null;
            remove(zoomMeetings, meet => meet.id === open.id);
            setZoomMeetings([...zoomMeetings]);
            const calendarMeetingId = findCalendarMeetingId(open.id)
            await deleteZoom(open.id, calendarMeetingId);
            // await axios.post(API_ZOOM + '/delete', {
            //     type: 'meetings',
            //     meetingId: open.id,
            // });

        } catch (e) {
            setZoomMeetings(_zoomMeetings)
            console.log(e);
        } finally {
            setLoadingDelete(false)
        }
    };

    const onOpenLink = (meeting) => {
        window.open(meeting.join_url)
    }

    if (loading) return <ClfLinearProgress/>
    return (
        <div className={classes.root}>
            {
                zoomMeetings.length === 0 ?
                    <p className={classes.noData}>{noZoomDataIntl}</p>
                    :
                    <div>
                        {
                            zoomMeetings.map((meeting, index) => {
                                return (
                                    <div key={index} className={classes.cardMeeting}>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <div className={classes.title}>
                                                    {moment(meeting.start_time).isSame(moment(), 'day') ?
                                                        <div className={classes.DateMeetingToDay}>
                                                            {today}
                                                        </div>
                                                        :
                                                        <div className={classes.DateMeeting}>
                                                            {zoneObj(meeting.start_time).format('YYYY-MM-DD (ddd)').toUpperCase()}
                                                        </div>
                                                    }
                                                    <div>{meeting.topic}</div>
                                                </div>
                                                <div className={classes.timeInfo}>
                                                    {zoneObj(meeting.start_time).format('HH:mm')} ~ {zoneObj(meeting.start_time).add(meeting.duration, 'minutes').format('HH:mm')}
                                                    {
                                                        moment().isBetween(moment(meeting.start_time), moment(meeting.start_time).add(meeting.duration, 'minutes')) ?
                                                            <span className={classes.nowTimeMeeting}>Now</span>
                                                            :
                                                            <span
                                                                className={classes.comingUp}>{comingUp}</span>
                                                    }
                                                </div>
                                                <div className={classes.meetingId}>{mettingID}: {meeting.id}</div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CLFButtonSVG
                                                    minHeight={"31px"}
                                                    iconComponent={<ZoomSvg/>}
                                                    size={'default'}
                                                    name={'Start'}
                                                    onClick={() => onOpenLink(meeting)}
                                                    block={true}
                                                    margin={'10px 0px'}
                                                    className={classes.primary}
                                                />
                                                <DeleteButton
                                                    block={true}
                                                    margin={'10px 0px'}
                                                    onClick={() => setOpen(meeting)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })
                        }
                    </div>
            }
            <DialogDeleteZoomMeeting open={open} setOpen={setOpen} onDelete={onDelete} loading={loadingDelete}/>
        </div>
    );
}

export default Zooms;
