import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';

function AnnouncementCheckbox({check = false, setCheck, label = "", lock = false}) {
    const classes = useStyles()
    const setCheckBox = () => {
        setCheck(!check)
    }
    return (
        <div className={`${lock ? classes.rootLock : classes.root}`}>
            <FormControlLabel
                label={<Typography className={classes.name}>{label}</Typography>}
                control={
                    <Checkbox
                        className={`${classes.checked} ${!lock ? classes.checkOn : ''}`}
                        disabled={lock}
                        checked={!lock ? check : false}
                        onClick={setCheckBox}
                    />
                }/>
        </div>
    );
}

export default AnnouncementCheckbox;
const useStyles = makeStyles(theme => ({
    rootLock: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        padding: "10px 0",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#F1F1F1",
        borderBottom: "solid 1px #fff",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    root: {
        padding: "10px 0",
        borderBottom: '1px solid #f1f1f1',
        display: "inline-block",
        width: '100%',
        '& .MuiFormControlLabel-root': {
            float: "left",
        }
    },
    name: {
        fontWeight: '700 !important',
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444"
    },
    input: {
        display: "flex",
    },
    checked: {
        borderRadius: "25px",
        marginLeft: 6,
        padding: 3,
    },
    checkOn: {
        '&$checked': {
            color: theme.props.primaryColor,
        },
    },
}))
