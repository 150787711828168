import {getHeader} from "./getHeader";

export const renderHeaderAxis = (numberId) => {
    const widget = window['calendar' + numberId]?.el.querySelector('.fc-timegrid-axis')
    if (widget) {
        widget.innerHTML = getHeader(numberId)
        widget.style.color = "#000"
        widget.style.fontWeight = "bold"
        widget.style.fontSize = "10px"
        // widget.style.backgroundColor = "#000"
        widget.style.verticalAlign = "middle"
        widget.style.textAlign = "center"
    }
}
