import moment from 'moment';
import momentTz from 'moment-timezone'
import getUserTimeZone from '../common/getUserTimeZone';

export const isSameDate = (date1, date2) => {
    return moment(date1).isSame(date2, 'date')
}
export const subtractDate = (date, amount, unit) => {
    return moment(date).subtract(amount, unit)
}
export const mddd = date => {
    const zone = getUserTimeZone()
    return momentTz(date).tz(zone).format("ddd")
}
export const mMMDD = date => {
    const zone = getUserTimeZone()
    return momentTz(date).tz(zone).format("MM/DD")
}
export const mHHmm = date => {
    const zone = getUserTimeZone()
    return momentTz(date).tz(zone).format("HH:mm")
}

export const momentdd = date => {
    const zone = getUserTimeZone()
    return momentTz(date).tz(zone).format("dd")
}
export const momentMD = date => {
    const zone = getUserTimeZone()
    return momentTz(date).tz(zone).format("M/D")
}

export const momentHmm = date => {
    const zone = getUserTimeZone()
    return momentTz(date).tz(zone).format("H:mm")
}

export const zoneObj = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local()

    return momentTz.tz(time, getUserTimeZone())
}

export const momentyyyyMMDDTHHmmssZ = date => {
    return moment(date).utc().format("YYYYMMDDTHHmmssZ")
}
