import React, {useEffect, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddSVG} from "../../../asSvg/icons/add.svg";
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";
import DialogCustom from "../../Custom/DialogCustom";
import {v4 as uuidv4} from "uuid";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {db} from "../../../config/firebaseConfig";
import {cloneDeep, findIndex} from 'lodash'
import axios from "axios";
import useIntlId from '../../../hooks/useIntlId';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import isURL from "validator/es/lib/isURL";
import {useSnapshot} from 'valtio';
import {projectsProxy} from '../../../store/proxy/projects.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';

const urls = [
    {
        id: "slack",
        name: "slack",
        url: "",
        logo: "/icons/slackIcon.svg"
    },
    {
        id: "trello",
        name: "trello",
        url: "",
        logo: "/icons/trelloIcon.svg"
    },
]

function AddProjectUrl({project, setProject, setProjectSelected}) {
    const classes = useStyles()
    const [openAdd, setOpenAdd] = useState(false)
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const {geniamProjects} = useSnapshot(projectsProxy);
    const {user, calendarUser} = useSnapshot(userProxy);
    const [loading, setLoading] = useState(false)
    const [saveIntl, nameIntl, addUrlIntl] = useIntlId(['dialog.action.done', 'app.profile.name', 'create.addUrl'])
    const [isSaveUrl] = useKeyboardJs(['ctrl+enter', 'command+enter'])

    useEffect(() => {
        if (isSaveUrl && openAdd) {
            if (url) {
                onAdd().then()
            }
        }

        // eslint-disable-next-line
    }, [isSaveUrl])

    const openAddURL = () => {
        setOpenAdd(!openAdd)
    }
    const onAdd = async () => {
        try {
            setLoading(true)
            const id = uuidv4()
            if (!url || !user?.user_id)
                return;
            const URL = new window.URL(url);
            const origin = URL.origin;
            const logo =  origin ? `${origin}/favicon.ico` : '/icons/linkURL.svg';

            if (project.is_google) {
                // google calendar
                let googleSettings = cloneDeep(calendarUser?.googleSettings) || {}
                let projectInfo = cloneDeep(googleSettings[project.id]) || {}
                let otherURL = !projectInfo.otherURL ? [] : projectInfo?.otherURL.filter(e => {
                    return e !== null && e !== undefined
                });
                if (!otherURL.length) {
                    otherURL = [...urls, {
                        id,
                        name: name || "",
                        url: url || "",
                        logo
                    }]
                } else {
                    otherURL = [...otherURL, {
                        id,
                        name: name || "",
                        url: url || "",
                        logo
                    }]
                }
                projectInfo.otherURL = otherURL
                googleSettings[project.id] = projectInfo
                const ref = db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                await ref.set({
                    googleSettings
                }, {merge: true})
                setProject({...project, otherURL});
                setProjectSelected(otherURL);
                return
            }
            let idx = findIndex(geniamProjects, {id: project.id})
            if (idx === -1)
                return

            let projectInfo = geniamProjects[idx]
            let {otherURL = []} = projectInfo
            if (!otherURL.length) {
                otherURL = [...urls, {
                    id,
                    name: name || "",
                    url: url || "",
                    logo
                }]
            } else {
                otherURL = [...otherURL, {
                    id,
                    name: name || "",
                    url: url || "",
                    logo
                }]
            }
            const ref = db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('projects')
                .doc(project.id)
            await ref.set({otherURL}, {merge: true})
            setProject({...project, otherURL})
            setProjectSelected(otherURL)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setName('')
            setUrl('')
            setOpenAdd(false)

        }

    }

    return (
        <div className={classes.divIconAddURL}>

            <IconButton className={classes.iconBtnAddURL}>
                <AddSVG
                    onClick={openAddURL}
                    className={classes.iconAddURL}/>
            </IconButton>
            <DialogCustom open={openAdd}
                          onClose={() => setOpenAdd(false)}
                          title={addUrlIntl}
                          maxWidth={'xs'}
                          fullWidth={true}
                          actions={<Button
                              variant="outline"
                              className={classes.button}
                              disabled={!url || !isURL(`${url}`, {
                                  protocols: ['http', 'https'],
                                  require_protocol: true
                              }) || loading}
                              onClick={onAdd}
                          >
                              {saveIntl}
                          </Button>}
            >
                <div className={classes.item}>
                    <TextField
                        value={name}
                        label={nameIntl}
                        fullWidth={true}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        helperText={url ? (!isURL(`${url}`, {
                            protocols: ['http', 'https'],
                            require_protocol: true
                        }) ? 'Invalid Url' : '') : ''}
                        error={url ? !isURL(`${url}`, {protocols: ['http', 'https'], require_protocol: true}) : ''}
                        value={url}
                        label={"URL"}
                        fullWidth={true}
                        onChange={e => setUrl(e.target.value)}

                    />
                </div>
            </DialogCustom>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    divIconAddURL: {
        marginLeft: '16%',
        textAlign: 'left'
    },
    iconBtnAddURL: {
        padding: 0
    },
    iconAddURL: {
        fill: theme.props.primaryColor,
        transform: 'scale(1.5)',
    },
    listItems: {
        marginTop: 20,
    },
    item: {
        width: "100%",
        padding: 20
    },
    button: {
        background: theme.props.primaryColor,
        color: "#fff",
        fontWeight: 700,
        "&:hover": {
            background: theme.props.primaryColor,
            opacity: 0.7,
            color: "#fff",
        }
    }
}))

export default AddProjectUrl;
