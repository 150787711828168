
export const addClassSlider = (contentIcons) => {
    setTimeout(() => {
        let i = 1;
        const sliderContent = contentIcons.children;
        for (const slide of sliderContent) {
            slide.classList.add(`cf-slider__item__${i++}`);
        }
    }, 500);

    return contentIcons;
}

export const nextSlider = (contentIcons) => {
    const slider = contentIcons.children;
    if (slider.length) {
        for (const slide of slider) {
            for (let i = 1; i <= slider.length; i++) {
                if (slide.classList.contains(`cf-slider__item__1`)) {
                    slide.classList.replace(
                        `cf-slider__item__1`,
                        `cf-slider__item__${slider.length}`
                    );
                    break;
                }
                if (slide.classList.contains(`cf-slider__item__${i}`)) {
                    slide.classList.replace(
                        `cf-slider__item__${i}`,
                        `cf-slider__item__${i - 1}`
                    );
                    break;
                }
            }
        }
    }

    return contentIcons;
};
