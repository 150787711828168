import React from 'react';
import moment from "moment";
import useIntlId from "../../../../hooks/useIntlId";
import Grid from "@material-ui/core/Grid";

function OperateTimeLabel({classes}) {
    const [stageText] = useIntlId(['setting.stage']);

    return (
        <Grid container className={classes.tableHead} alignItems={"center"}>
            <Grid item xs={3}>
                <div className={classes.stageText}>{stageText}</div>
            </Grid>
            <Grid item xs={9}>
                <table className={`${classes.tableTime}`}>
                    <tr>
                        <td></td>
                        {
                            ['1', '2', '3', '4', '5', '6', '7'].map((value, index) => {
                                return (
                                    <td className={classes.td} key={index}>{moment().day(value).format('ddd')}</td>
                                )
                            })
                        }
                    </tr>
                </table>

            </Grid>
        </Grid>
    );
}

export default OperateTimeLabel;
