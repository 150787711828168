import moment from "moment";
import getExtendedProps from './getExtendedProps';
import {getRrule} from './rrule';
import {snapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';

export default function getEventInfo(editInfo) {
    const {user} = snapshot(userProxy)
    let duration = editInfo.event.allDay ? ((moment(editInfo.event.end) - moment(editInfo.event.start)) / (24 * 60 * 60 * 1000)) : ((moment(editInfo.event.end) - moment(editInfo.event.start)) / (60 * 60 * 1000))
    let isGoogleEvent = Boolean(getExtendedProps(editInfo, 'is_google')) || Boolean(getExtendedProps(editInfo, 'googleEvent'))
    let ggReminders = getExtendedProps(editInfo, 'reminders');
    let ggDefaultReminders = ggReminders?.overrides || [];

    return {
        id: editInfo.event.id,
        uuid: getExtendedProps(editInfo, '').uuid || editInfo.event.id,
        title: editInfo.event.title,
        allDay: editInfo.event.allDay,
        start: editInfo.event.start,
        end: editInfo.event.end,
        task: getExtendedProps(editInfo, 'task') || '',
        creator: isGoogleEvent ? getExtendedProps(editInfo, 'creator') || "" : getExtendedProps(editInfo, 'creator') ? getExtendedProps(editInfo, 'creator') : {email: user?.email || ""},
        project_uuid: getExtendedProps(editInfo, 'project_uuid'),
        borderColor: editInfo.event.borderColor,
        backgroundColor: editInfo.event.backgroundColor,
        colorId: getExtendedProps(editInfo, 'colorId'),
        is_google: isGoogleEvent,
        googleEvent: isGoogleEvent,
        identity: getExtendedProps(editInfo, 'identity') || '',
        duration,
        isConnectMetamors: Boolean(editInfo?.event?.extendedProps?.extendedProperties?.private?.isConnectMetamors) || '',
        eventIdMetamors: editInfo?.event?.extendedProps?.extendedProperties?.private?.eventIdMetamors || '',
        is_holiday: Boolean(getExtendedProps(editInfo, 'holiday')),
        isSuccess: Boolean(getExtendedProps(editInfo, 'isSuccess')),
        description: getExtendedProps(editInfo, 'description') || '',
        location: getExtendedProps(editInfo, 'location') || '',
        linkZoom: getExtendedProps(editInfo, 'linkZoom') || '',
        zoomMeetingId: getExtendedProps(editInfo, 'zoomMeetingId') || '',
        isEventVote: Boolean(getExtendedProps(editInfo, 'isEventVote')),
        isAccepted: Boolean(getExtendedProps(editInfo, 'isAccepted')),
        meetingId: getExtendedProps(editInfo, 'meetingId') || null,
        isMileStone: Boolean(getExtendedProps(editInfo, 'isMileStone')),
        conferenceData: getExtendedProps(editInfo, 'conferenceData') || '',
        googleTasksID: getExtendedProps(editInfo, 'googleTasksID') || '',
        recurrence: getRrule(getExtendedProps(editInfo, 'recurrence')) || '',
        defaultReminders: getExtendedProps(editInfo, 'defaultReminders') || ggDefaultReminders || [],
        timeBefore: getExtendedProps(editInfo, 'timeBefore') || 0,
        timeAfter: getExtendedProps(editInfo, 'timeAfter') || 0,
        recurringEventId: getExtendedProps(editInfo, 'recurringEventId') || '',
        originStart: getExtendedProps(editInfo, 'originStart') || null,
        name: getExtendedProps(editInfo, 'name') || '',
        email: getExtendedProps(editInfo, 'email') || '',
        scheduleId: getExtendedProps(editInfo, 'scheduleId') || null,
        iCalUID: getExtendedProps(editInfo, 'iCalUID') || null,
        attendees: getExtendedProps(editInfo, 'attendees') || [],
        emailVoter: getExtendedProps(editInfo, 'emailVoter') || "",
    }
}
