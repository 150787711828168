import React, {Suspense, useDeferredValue} from 'react';
import {Grid, LinearProgress, withStyles} from '@material-ui/core';
import {useGlobal} from 'reactn';
import CalendarComponent from '../Calendar/CalendarComponent';
import qs from 'query-string';
import LoadCalendar from './LoadCalendar'
import ErrorBoundary from '../ErrorBounDary';
import {CalendarYear} from '../splitting'
import CreateEvent from '../Calendar/CreateEvent';
import TitleShow from '../Calendar/TitleShow';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';

function ContentHomeApp(props) {
    const {classes, calendarHeight} = props
    const [clickInfo, setClickInfo] = useGlobal('clickInfo')
    const {currentTheme} = useSnapshot(themesProxy)
    const {viewCalendar} = qs.parse(window.location.search)
    const theme = useDeferredValue(currentTheme)
    return (
        <Grid item xs={12} container spacing={2} className={classes.w100}>
            {
                theme?.viewCalendar === "CustomYear" ?
                    <div
                        className={classes.gridContainerYear}
                        style={{
                            // maxHeight: window.innerHeight - (document.getElementById('root') ? document.getElementById('root').offsetHeight : 0),
                            overflow: "auto"
                        }}
                    >
                        <Suspense fallback={<LinearProgress/>}>
                            <CalendarYear/>
                        </Suspense>
                    </div>
                    :
                    <Grid container
                          className={classes.gridContainer}
                        // style={{height: calendarHeight ? `${calendarHeight + 101}px` : "unset"}}
                    >
                        <ErrorBoundary>
                            {
                                LoadCalendar(theme).map((i, index) => {
                                    if (!viewCalendar && theme?.columns && theme?.rows) {
                                        let widthCalendar = 100 / theme.columns
                                        let height = (theme.advanceView && !theme?.settingToday) ? calendarHeight : calendarHeight / theme.rows
                                        // let columns = currentTheme.columns || 1
                                        return (
                                            <div
                                                style={{width: theme?.viewCalendar === "CustomDay" ? "100%" : `${widthCalendar}%`}}
                                                className={'div-calendar'}
                                                key={index}
                                            >
                                                <CalendarComponent
                                                    numberId={index}
                                                    // numOfLine={numOfLine}
                                                    calendarheight={height}
                                                    // columns={columns}
                                                    // heightChartApp={heightChartApp}
                                                />
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div
                                                style={{width: "100%"}}
                                                className={'div-calendar'}
                                                key={index}
                                            >
                                                <CalendarComponent
                                                    numberId={index}
                                                    // numOfLine={numOfLine}
                                                    calendarheight={calendarHeight}
                                                    // heightChartApp={heightChartApp}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                        </ErrorBoundary>
                    </Grid>}
            {
                Boolean(clickInfo) &&
                <CreateEvent
                    clickInfo={clickInfo}
                    setClickInfo={setClickInfo}
                    numberId={clickInfo?.isCreateButton ? clickInfo.numCal : window['i']}
                />
            }
            <TitleShow/>
        </Grid>
    )
}

const styles = theme => ({
    hidden: {
        position: 'absolute',
        top: -9999,
        left: -9999,
    },
    button: {
        minWidth: "auto",
        width: 30,
        height: 30,
        background: "#ebebeb"
    },
    w100: {
        width: "100%",
        margin: 0,
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px 0 0 10px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: '#ccc',
        },
    },
    gridContainerTab2: {
        width: '100%',
        minHeight: 300,
        margin: 0,
        padding: '0px 0px 8px 8px',
    },
    gridContainer: {
        width: '100%',
        minHeight: 300,
        margin: 0,
        padding: 0,
    },
    gridContainerYear: {
        width: '100%',
        minHeight: '100vh',
        margin: 0,
        padding: 0,
    },
    SpinLoader: {
        "& .ant-spin-nested-loading": {
            marginTop: '5px !important',
        },
        "& .ant-alert-with-description.ant-alert-no-icon": {
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .ant-spin-nested-loading > div > .ant-spin": {
            maxHeight: 'unset'
        },
        "& .ant-alert-info": {
            backgroundColor: theme.props.primaryColor,
            border: `1px solid ${theme.props.primaryColor}`,
            borderRadius: 24,
            opacity: 0.6
        },
        "& .ant-alert-content": {
            marginTop: "50px"
        }
    },
    recommendBarItem: {
        padding: "0 20px",
    },
    ItemContent: {
        cursor: "pointer",
        backgroundColor: theme.props.primaryColor,
        color: "white",
        height: 40,
        overflow: "hidden",
        borderRadius: '10px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            marginBlockEnd: "unset",
            fontWeight: 700
        }
    },
    slideContainer: {
        // marginLeft: 30,
        width: "100%"
    },
    recommendImg: {
        width: "100%",
        height: "100%",
        maxWidth: "200px",
        maxHeight: "30px",
        objectFit: "contain"
    },
    reLink: {
        marginLeft: 10,
        color: "#fff"
    },
})

export default React.memo(withStyles(styles)(ContentHomeApp))
