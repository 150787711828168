import {iconNameList} from "../../../common/muiIcons";
import {findIndex} from 'lodash'
import {snapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';


const oldIcons = {
    "AccountCircle": "account_circle",
    "Group": "group",
    "AssignmentInd": "assignment_ind",
    "AssignmentTurnedIn": "assignment_turned_in"
}
const defaultIcon = {
    "account_circle": "account_circle",
    "group": "group",
    "assignment_ind": "assignment_ind",
    "assignment_turned_in": "assignment_turned_in"
}
export const getIcon = (name) => {
    return oldIcons[name] || name
}
export const getDefaultIcon = (name) => {
    return defaultIcon[name] || name
}
export const isMaterialIcon = name => {
    // let iconName = nameq
    // if(name === "AccountCircle"){
    //     iconName = "account_circle"
    // }
    // if(name === "Group"){
    //     iconName = "group"
    // }
    // if(name === "AssignmentInd"){
    //     iconName = "assignment_ind"
    // }
    // if(name === "AssignmentTurnedIn"){
    //     iconName = "assignment_turned_in"
    // }
    const {calendarUser} = snapshot(userProxy)
    const icons = calendarUser?.tasks?.data?.map(icon => icon.icon) || []
    return name && iconNameList.includes(getIcon(name)) && icons.includes(getDefaultIcon(name))
}

export const isImageTask = uid => {
    const {calendarUser} = snapshot(userProxy)
    // console.log(tasks)
    return findIndex(calendarUser?.tasks?.data || [], item => item?.type === "file" && item?.file?.id === uid) !== -1
}

export const getTaskUrl = uid => {
    const {calendarUser} = snapshot(userProxy)
    const {tasks = {}} = calendarUser
    // console.log("Task:",tasks)
    let idx = findIndex(tasks?.data || [], item => item?.type === "file" && item?.file?.id === uid)
    if (idx === -1)
        return null
    return tasks.data[idx]?.file?.url || ''
}
