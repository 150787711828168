import React, {memo, useDeferredValue, useEffect, useState} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import {useGlobal} from 'reactn';
import {styles} from "./handlers/styles"
import Focus from "../Application/Focus";
import {CalendarInit} from "./handlers/initCal";
import EditEvent from "./EditEvent";
import DeleteEvent from "./DeleteEvent"
import DialogVoteBoxHandleEmail from "../Meeting/EventsVote/DialogVoteBoxHandleEmail";
import {makeStyles} from "@material-ui/core/styles";
import CalendarContainer from "../Custom/calendarContainer";
import {renderHeaderAxis} from "../../common/calendarView/headerAxis";
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import useIntlId from '../../hooks/useIntlId';
import {useSnapshot} from 'valtio';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {getDate} from './handlers/getDate';
import moment from 'moment';
import {userProxy} from '../../store/proxy/user.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';
import {chartProxy} from '../../store/proxy/chart.proxy';
import {find} from 'lodash';
import {eventsProxy} from '../../store/proxy/events.proxy';
import {useMeasure} from 'react-use'

const useStyles = makeStyles(() => ({
    calendarContent: {
        // border: '0.3vw solid #3A3A3A',
        background: "#F1F1F1",
        borderRadius: "10px",
        overflowY: "auto",
        height: "100%",
        position: "relative",
        '&::-webkit-scrollbar': {
            display: "none",
        },
    },
    margin5: {
        margin: 5
    },
    paddingLeft16: {
        paddingLeft: 16
    },
    w100: {
        width: "100%"
    },
    marginTop55: {
        marginTop: -55
    }
}));


function CalendarComponent(props) {
    const {
        numberId, classes,
        calendarheight,
    } = props;
    const [refetchDateNow] = useGlobal('refetchDateNow');
    const {currentTheme, openLeftMenu} = useSnapshot(themesProxy)
    const {data} = useSnapshot(chartProxy)
    const chartIdData = data[numberId]
    const deferredChartData = useDeferredValue(chartIdData)
    // const {user} = useSnapshot(userProxy)
    // const [times] = useGlobal('times');
    // const [thickLine] = useGlobal('thickLine');
    // const [fontSize] = useGlobal('fontSize');
    const {setLastTitle} = useSnapshot(eventsProxy)
    const [lastTitleGe, setLastTitleGe] = useState([])
    const [lastTitleGg, setLastTitleGg] = useState([])
    const [loading] = useGlobal('loading');
    const cls = useStyles();
    const [editInfo, setEditInfo] = useGlobal('editInfo');
    const [pressDeleteEvent, setPressDeleteEvent] = useGlobal('pressDeleteEvent');
    const [events, setEvents] = useState([]);
    const [acceptEvent, setAcceptEvent] = useState(null);
    const [openChatting, setOpenChatting] = useState(null);
    const [, setVotesSelect] = useState(null);
    const [, setIsEmailOnly] = useState(false);
    const [durationWeek, setDurationWeek] = useState(1)
    const [copyNotice] = useIntlId(['calenview.noticeCopying'])
    const {calendarUser} = useSnapshot(userProxy)
    const [ref, {width}] = useMeasure()
    const {plan, bar} = useSnapshot(announcementProxy);

    useEffect(() => {
        setLastTitle(lastTitleGe, lastTitleGg);
    }, [lastTitleGe, lastTitleGg]);

    function CalendarRender() {
        if (window['calendar' + numberId]) {
            window['calendar' + numberId].destroy();
            window['calendar' + numberId] = null
        }
        CalendarInit(numberId, classes, setEditInfo, setVotesSelect, durationWeek, plan?.info?.focus, setLastTitleGe, setLastTitleGg)
    }

    useEffect(() => {
        if (currentTheme?.viewCalendar === "CustomYear")
            return
        if (currentTheme?.advanceView) {
            let rangeDay = currentTheme?.rangeDay
            if (currentTheme?.settingToday) {
                setDurationWeek(currentTheme?.numsDay)
                return
            }
            if (rangeDay?.length) {
                setDurationWeek(moment(rangeDay[1]).diff(moment(rangeDay[0]), 'days') + 1)
                return
            }
        }
        if (currentTheme?.viewCalendar === "CustomDay") {
            setDurationWeek(currentTheme?.columns)
            return
        }
        setDurationWeek(1)
        // eslint-disable-next-line
    }, [currentTheme?.numsDay, currentTheme?.advanceView, currentTheme?.rangeDay, currentTheme?.columns])
    //
    useEffect(() => {
        let timer = null
        if (window['calendar' + numberId]) {
            timer = setTimeout(() => {
                window['calendar' + numberId].refetchEvents()

            }, 500)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
        // eslint-disable-next-line
    }, [currentTheme?.fontSize, currentTheme?.showTitle, openLeftMenu, currentTheme?.rightExpansion, currentTheme?.columns, width]);
    useEffect(() => {
        if (!currentTheme?.viewCalendar || currentTheme?.viewCalendar === "CustomYear")
            return;
        CalendarRender();
        // fillDataColor(focusData)
        checkHeight()
        if (window['calendar' + numberId]) {
            renderHeaderAxis(numberId)
        }
        // eslint-disable-next-line
    }, [currentTheme?.viewCalendar, durationWeek, calendarUser?.times, calendarUser?.thickLine, calendarUser?.startDate]);

    useEffect(() => {
        setTimeout(() => {
            if (window['calendar' + numberId]) {
                window['calendar' + numberId].gotoDate(getDate(numberId).date)
                if (window['calendar' + numberId].view) {
                    window['calendar' + numberId].refetchEvents()
                    renderHeaderAxis(numberId)
                }
            }
        }, 300)
        // eslint-disable-next-line
    }, [refetchDateNow])

    useEffect(() => {
        checkHeight()
        // eslint-disable-next-line
    }, [calendarheight])
    const checkHeight = () => {
        if (!window['calendar' + numberId])
            return;
        const calendarEl = window['calendar' + numberId].el.querySelector('.fc-scrollgrid ')
        if (!calendarEl)
            return
        const body = calendarEl.querySelector('.fc-timegrid-slots')
        if (!body)
            return;
        const header = calendarEl.querySelector(".fc-scrollgrid-section-header")
        const headerHeight = header?.offsetHeight || 60
        body.firstChild.style.height = calendarheight - headerHeight - 10 + "px"
        window['calendar' + numberId].updateSize()
    }

    useEffect(() => {
        fillDataColor(deferredChartData?.focus)
        // eslint-disable-next-line
    }, [deferredChartData?.focus, bar?.focusColor])

    const fillDataColor = (focusData) => {
        const calEl = document.getElementById('calendar-container' + numberId)
        const calDates = Array.from(calEl.querySelectorAll('th[data-date]'))
        calDates.forEach(x => x.removeAttribute('style'))
        if (focusData && focusData.length > 0 && plan?.info?.focus) {
            let focusColor = bar?.focusColor
            focusData.forEach((val) => {
                let color = ''
                // let arrDate = val.is_google ?
                //     getDaysArray(moment(val.start, 'YYYY-MM-DD'), moment(val.end, 'YYYY-MM-DD'))
                //     :
                //     getDaysArray(moment(val.start).tz(getUserTimeZone()), moment(val.end).tz(getUserTimeZone()))
                let arrDate = getDaysArray(moment(val.start, 'YYYY-MM-DD'), moment(val.end, 'YYYY-MM-DD'))
                if (color === '') {
                    if (focusColor === 'identity') {
                        color = val.backgroundColor
                    }
                    if (focusColor === 'project') {
                        color = val.borderColor
                    }
                }
                arrDate.forEach((val) => {
                    const date = find(calDates, item => item.dataset?.date === val)
                    if (date) {
                        date.style.background = color
                    }
                })
            })
        }
    }

    const getDaysArray = (start, end) => {
        let dateArray = [];
        while (start < end) {
            dateArray.push(moment(start).format('YYYY-MM-DD'))
            start = moment(start).add(1, 'days');
        }
        return dateArray;
    }

    return (
        <div className={cls.margin5}>
            <Grid container>
                <Grid item xs={currentTheme?.rightExpansion ? 9 : 12} ref={ref}>
                    <LoadingOverlay>
                        <Loader loading={loading} text={copyNotice}/>
                        <CalendarContainer
                            id={'calendar-container' + numberId}
                            height={calendarheight}
                        >
                            <div id={`calendarDiv${numberId}`}/>
                        </CalendarContainer>
                    </LoadingOverlay>
                </Grid>
                {currentTheme?.rightExpansion &&
                    <Grid item xs={3} className={cls.paddingLeft16}>
                        <Focus
                            numberId={numberId}
                            deferredChartData={deferredChartData}
                        />
                    </Grid>
                }
            </Grid>
            {editInfo && window['i'] === numberId &&
                <EditEvent
                    editInfo={editInfo}
                    setEditInfo={setEditInfo}
                    events={events}
                    setEvents={setEvents}
                    numberId={numberId}
                    acceptEvent={acceptEvent}
                    setAcceptEvent={setAcceptEvent}
                    setIsEmailOnly={setIsEmailOnly}
                    openChatting={openChatting}
                    setOpenChatting={setOpenChatting}
                />
            }
            {
                Boolean(acceptEvent) &&
                <DialogVoteBoxHandleEmail
                    choose={acceptEvent}
                    setChoose={setAcceptEvent}
                />
            }
            {
                Boolean(pressDeleteEvent) &&
                <DeleteEvent open={pressDeleteEvent} setOpen={setPressDeleteEvent} setEditInfo={setEditInfo}
                             event={pressDeleteEvent}/>
            }
        </div>
    )

}

export default memo(withStyles(styles)(CalendarComponent))
