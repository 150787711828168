import React from 'react';
import styled from "styled-components";
import {Tooltip} from "antd";


const ToolTipCustom = styled(Tooltip)`

`

const MainTip = styled.div`
    color: #000;
    text-align: center;
`

function TooltipCustom({children, title, overlayClassName, placement, getId = null}) {

    const mainTip = (
        <MainTip>
            {title}
        </MainTip>
    )

    return (
        <ToolTipCustom
            title={mainTip}
            overlayClassName={overlayClassName}
            placement={placement}
            getPopupContainer={getId ? () => document.getElementById(getId) : ''}
        >
            {children}
        </ToolTipCustom>
    );
}

export default TooltipCustom;
