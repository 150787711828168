import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ButtonAdd from './ButtonAdd';
import AntdInput from '../../Custom/AntdInput';
import {CloseCircleFilled} from '@ant-design/icons';
import IconButton from '@material-ui/core/IconButton';
import {chunk, cloneDeep, findIndex} from 'lodash';
import {ConfigProvider, DatePicker, Input} from 'antd';
import {Grid} from '@material-ui/core';
import moment from 'moment';
import useIntlId from '../../../hooks/useIntlId';
import {useGlobal} from 'reactn';
import {ReactComponent as IconCalendarSVG} from '../../../asSvg/icons/iconCalendar.svg';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import viVN from 'antd/lib/locale/vi_VN';
import {proxy, useSnapshot} from 'valtio';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';
import {themesProxy} from '../../../store/proxy/themes.proxy';

function AnnouncementSet({updateAnnouncementSet}) {
    const {bar, lock} = useSnapshot(announcementProxy);
    const {user} = useSnapshot(userProxy);
    const {colors} = useSnapshot(themesProxy)
    const [focusFromPreview, setFocusFromPreview] = useGlobal('focusFromPreview')
    const classes = useStyles()
    const [listData, setListData] = useState([])
    const [locale, setLocale] = useState(enUS)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [remindSetIntl, timeIntl, dayIntl, noItemIntl, remindContentIntl] = useIntlId(['settings.remind.set', 'event.numOfVote.Adornment', 'time.day', 'settings.announcement.item', 'setting.remindContent'])
    const [barToday, setBarToday] = useState([])

    useEffect(() => {
        if (user.language === "en-us") {
            setLocale(enUS)
        }
        if (user.language === "ja-jp") {
            setLocale(jaJP)
        }
        if (user.language === "vi-vn") {
            setLocale(viVN)
        }
    }, [user.language])


    useEffect(() => {
        setListData(cloneDeep(bar?.announcementSets?.data))
        if (bar?.announcementSets) {
            const {data, startDate, times} = bar?.announcementSets;
            if (data?.length && times > 0) {
                const chunked = chunk(data, times);
                let dayDiff = moment().diff(startDate, "days") % (chunked.length ?? 1);

                dayDiff = dayDiff > 0 ? dayDiff : 0;

                if (chunked[dayDiff] && chunked[dayDiff]?.length) {
                    setBarToday(chunked[dayDiff].map((text, idx) => ({
                        title: text,
                        index: idx + dayDiff * times
                    })));
                } else {
                    setBarToday([]);
                }
            }
        }
    }, [bar?.announcementSets]);

    const handleAddAnnouncementSets = () => {
        let newData = cloneDeep(listData) || []
        newData.push("")
        let newAnnouncementSets = cloneDeep(bar?.announcementSets);
        if (bar?.announcementSets.data.length === 0) {
            newAnnouncementSets['startDate'] = moment(Date.now()).format('YYYY/MM/DD')
        }
        newAnnouncementSets['data'] = [...newData]
        updateAnnouncementSet(newAnnouncementSets)
        // setListData([...newData])
    }
    const onChangeTimes = (value) => {
        let newAnnouncementSet = {...bar?.announcementSets}
        newAnnouncementSet.times = value
        updateAnnouncementSet({...newAnnouncementSet})
    }
    const onChangeText = (e, index) => {
        let newData = proxy(listData) || []
        newData[index] = e.target.value
        setListData([...newData])
    }
    const onSaveContent = (index) => {
        let newData = cloneDeep(listData) || []
        if (newData[index] === "" || newData[index] === undefined) {
            setListData(cloneDeep(bar?.announcementSets?.data))
            return
        }
        onSave()
    }

    const onSave = (data) => {
        setFocusFromPreview(null)
        let newAnnouncementSets = proxy(bar?.announcementSets)
        if (bar?.announcementSets.data.length === 0) {
            newAnnouncementSets['startDate'] = moment(Date.now()).format('YYYY/MM/DD')
        }
        newAnnouncementSets['data'] = data ? data : [...listData]
        updateAnnouncementSet(newAnnouncementSets)
    }
    useEffect(() => {
        if (listData.length > 0) {
            if (!listData.slice(-1).pop()) return setDisabledBtn(true)
        }
        return setDisabledBtn(false)
    }, [listData])

    const handleChange = (date, dateStr) => {
        let newAnnouncementSets = proxy(bar?.announcementSets)
        if (dateStr) {
            newAnnouncementSets['startDate'] = moment(dateStr).format('YYYY/MM/DD')
        }
        updateAnnouncementSet(newAnnouncementSets)
    }

    return (
        <div className={classes.root}>
            <div className={`${classes.top} mb-5 space-between`}>
                <div className={classes.title}>{remindSetIntl}</div>
                <div className={classes.datePicker}>
                    <ConfigProvider locale={locale}>
                        {bar?.announcementSets.startDate && (
                            <DatePicker disabled={lock}
                                        defaultValue={moment(bar?.announcementSets.startDate, 'YYYY/MM/DD')}
                                        onChange={(date, dateStr) => handleChange(date, dateStr)}/>
                        )}
                    </ConfigProvider>
                    <IconCalendarSVG className={classes.iconCalendar}/>
                </div>
            </div>
            <Grid container className={classes.announcementContent}>
                <Grid item xs={1} className={classes.action}>
                    <AntdInput size={32} width={150} value={bar?.announcementSets?.times || 1} steps={1} min={1}
                               label={`${timeIntl}/${dayIntl}`}
                               onChange={(value) => onChangeTimes(value)}
                               disabled={lock}
                               isTransform={true}
                    />
                </Grid>
                <Grid xs={11} item className={classes.listContent}>
                    {
                        listData?.length > 0 ? listData.map((item, index) => {
                                return (
                                    <div className={classes.content} key={index}>
                                        {findIndex(barToday, b => b.index === index) !== -1 && item !== '' ? <div
                                            style={{backgroundColor: colors?.primary, width: 10, height: 43}}/> : ''}
                                        <Input
                                            disabled={lock}
                                            id={'announcementSet' + index}
                                            onPressEnter={() => {
                                                onSaveContent(index)
                                                const el = document.getElementById('announcementSet' + index)
                                                if (el) {
                                                    el.blur()
                                                }
                                            }}
                                            onBlur={() => onSaveContent(index)}
                                            className={classes.value} onChange={(e) => onChangeText(e, index)}
                                            placeholder={remindContentIntl}
                                            autoFocus={!item || (focusFromPreview?.index === index && focusFromPreview?.id === "remind")}
                                            value={item}/>
                                        <IconButton
                                            disabled={lock}
                                            onClick={() => {
                                                listData.splice(index, 1)
                                                onSave(listData)
                                            }}
                                            className={classes.remove}>
                                            <CloseCircleFilled/>
                                        </IconButton>
                                    </div>
                                )
                            })
                            :
                            <div>{noItemIntl}</div>
                    }
                    <div className={classes.actionCreate} align={'center'}>
                        <ButtonAdd onClick={handleAddAnnouncementSets} disabled={lock} disabledBtn={disabledBtn}/>
                    </div>
                </Grid>
            </Grid>

        </div>
    );
}

export default AnnouncementSet;

const useStyles = makeStyles(theme => ({
    '.ant-slide-up-enter-active': {
        opacity: '1 !important'
    },
    root: {
        padding: "15px 0",
        borderBottom: "solid 1px #fff"
    },
    top: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    action: {
        display: "flex",
        alignItems: "center"
    },
    actionCreate: {
        margin: 5,
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444"
    },
    today: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444",
        padding: "8px 15px 10px",
        backgroundColor: "#fff",
        borderRadius: "13px 13px 0 0"
    },
    announcementContent: {
        minHeight: 182
    },
    listContent: {
        backgroundColor: "#fff",
        borderRadius: "13px",
        padding: "5px 20px",
        "& > div:not(:last-child)": {
            borderBottom: "solid 1px #f1f1f1"
        },
    },
    content: {
        color: "#444",
        // padding: "13px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    btnGroup: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    value: {
        border: "none",
        borderRadius: "13px",
        fontSize: 14,
        padding: "5px 20px",
        marginRight: 6,
        cursor: "pointer"
    },
    datePicker: {
        "& .ant-picker-input": {
            flexDirection: "row-reverse"
        },
        "& .ant-picker-suffix": {
            display: "none"
        },
        "& .ant-picker": {
            border: "none",
            padding: 0
        },
        '& .ant-picker-input > input': {
            fontWeight: 700,
            fontSize: 14,
            color: "#444444",
            width: "unset",
            textAlign: "center",
            background: "#fff"
        },
        '& .ant-picker-input:hover .ant-picker-clear': {
            display: "none"
        },
        position: "relative",
        padding: "5px 10px 5px 30px",
        backgroundColor: "#fff",
        borderRadius: "15px"
    },
    iconCalendar: {
        position: "absolute",
        left: 10,
        top: "50%",
        transform: "translateY(-50%)",
        fill: theme.props.primaryColor
    },
}))
