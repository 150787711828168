import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {findIndex, isFunction, uniqBy} from 'lodash';
import {IconButton} from '@material-ui/core';
import {Button} from 'antd';
import CustomRecurrenceDialog from './CustomRecurrenceDialog';
import useIntlId, {isENUS, isVIVN} from '../../../hooks/useIntlId';
import {publicIcon} from '../handlers/infoEvent';
import moment from 'moment';
import {RRule} from 'rrule';
import {getRruleString} from '../../../common/rrule';

const defaultOptions = [
    {
        value: "",
    },
    {
        value: "RRULE:FREQ=DAILY",
    },
    {
        value: "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR",
    },

]

function Recurrent({event, setEvent}) {
    const classes = useStyles()
    const [options, setOptions] = useState([...defaultOptions])
    const [value, setValue] = useState(defaultOptions[0])
    const [anchorEl, setAnchorEl] = useState(null)
    const [repeatSetting] = useIntlId([
        'create.repeatSetting',
    ])

    useEffect(() => {
        if (!event)
            return;
        // console.log(event?.recurrence)
        const ruleString = getRruleString(event?.recurrence)
        if (ruleString) {
            let idx = findIndex(options, {
                value: event.recurrence || ""
            })
            if (idx !== -1) {
                setValue(options[idx])
                setOptions(generateOptionsDate(options, event?.start))
            } else {
                const newOptions = [...options]
                newOptions.push({
                    value: ruleString,
                })

                setValue({
                    value: ruleString,
                })
                setOptions(generateOptionsDate(newOptions, event?.start))
            }
        } else {
            setValue(defaultOptions[0])
            setOptions(generateOptionsDate(options, event?.start))
        }

        // eslint-disable-next-line
    }, [event?.recurrence]);

    const parseRRuleToText = isENUS() ?
        null :
        isVIVN() ?
            require('./rrule/vi-vn').parseRRuleToText :
            require('./rrule/ja-jp').parseRRuleToText

    const generateOptionsDate = (options, date) => {

        const day = moment(date).locale('en').local().format('dd').toUpperCase()
        const day1 = moment(date).locale('en').local().format('D').toUpperCase()
        const month = moment(date).locale('en').local().format('M').toUpperCase()
        let prefixes = [1, 2, 3, 4, 5];
        let weekOfMonth = prefixes[Math.floor(new Date(date).getDate() / 7)]
        const rrule = "RRULE:FREQ=WEEKLY;BYDAY=" + day
        const rrule2 = "RRULE:FREQ=MONTHLY;BYDAY=" + weekOfMonth + day
        const rrule3 = "RRULE:FREQ=YEARLY;BYMONTHDAY=" + day1 + ";BYMONTH=" + month
        let idx = findIndex(options, op => op.value === rrule)
        let idx2 = findIndex(options, op => op.value === rrule2)
        let idx3 = findIndex(options, op => op.value === rrule3)

        const newOptions = uniqBy(options, 'value')
        if (idx === -1) {
            newOptions.push({
                value: rrule,
                // label: (rrule)
            })
        }
        if (idx2 === -1) {
            newOptions.push({
                value: rrule2,
                // label: (rrule2)
            })
        }
        if (idx3 === -1) {
            newOptions.push({
                value: rrule3,
                // label: (rrule3)
            })
        }

        newOptions.forEach(opt => {
                opt.label = isFunction(parseRRuleToText) ?
                    parseRRuleToText(opt.value) :
                    opt.value === ''
                        ? 'no repeat' :
                        RRule.fromString(opt.value).toText();
            }
        )
        return newOptions
    }

    const onCustomRule = (data) => {
        if (Boolean(event?.isConnectMetamors)) return;
        let idx = findIndex(options, op => op.value === data.value)
        if (idx === -1) {
            const newOptions = [...options]
            newOptions.push(data)
            setOptions(generateOptionsDate(newOptions, event?.start))
            setValue(data)
            setEvent({...event, recurrence: data.value})
        } else {
            setValue(options[idx])
            setEvent({...event, recurrence: options[idx].value})
        }
    }

    if (event?.isEventVote)
        return null

    return (
        <div
            className={`${event.isMileStone === true && event.task === "mileStone" ? `${classes.root} opacity-40 cursor-not-allowed` : classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <IconButton disableRipple={true}
                        className={event.isMileStone === false ? classes.icon : `${classes.icon} pointer-events-none`}>
                <img src={publicIcon('repeat.svg')} alt="repeat"/>
            </IconButton>
            <div
                className={event.isMileStone === true && event.task === "mileStone" ? `${classes.content} pointer-events-none` : classes.content}>
                <img className={classes.iconArrow} src={publicIcon('arrowDown.svg')} alt="arrowDown"/>
                <select
                    className={classes.select}
                    value={value?.value}
                    onChange={(data) => {
                        if (Boolean(event?.isConnectMetamors)) return;
                        setEvent({...event, recurrence: data.target.value})
                    }}
                    disabled={event?.is_holiday}
                >
                    {
                        options.map((option, index) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                )
                            }
                        )

                    }
                </select>
            </div>
            {
                !event?.is_holiday &&
                <Button className={event.isMileStone === true && event.task === "mileStone" ? `${classes.customBtn} pointer-events-none` : classes.customBtn} onClick={e => setAnchorEl(e.target)}>{repeatSetting}</Button>
            }

            {
                Boolean(anchorEl) &&
                <CustomRecurrenceDialog
                    value={value?.value}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    event={event}
                    onDone={onCustomRule}
                />
            }

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "38px",
        paddingLeft: 5
    },
    content: {
        position: "relative",
    },
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        padding: "0px 15px",
        height: 27,
        background: "#F1F1F1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        minWidth: 195,
    },
    iconArrow: {
        position: "absolute",
        right: 15,
        top: "50%",
        marginTop: -4
    },
    customBtn: {
        borderRadius: "16px !important",
        background: `${theme.props.primaryColor} !important`,
        color: "#fff !important",
        fontWeight: 700,
        marginLeft: 20,
        height: 32,
        border: "none !important",
        "&:hover": {
            background: `${theme.props.primaryColor} !important`,
            color: "#fff",
            opacity: 0.8
        },
        "&:active": {
            background: `${theme.props.primaryColor} !important`,
            color: "#fff",
            opacity: 0.8
        },
    }
}))

export default Recurrent;
