import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Popover} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import {cloneDeep, remove} from 'lodash'
import store from "store";
import {refetchSource} from "../../actions/refetchSourcce";
import {db} from "../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import useIntlId from "../../hooks/useIntlId";
import CancelButton from "../Dialog/CancelButton";
import DeleteButton from "../Dialog/DeleteButton";
import {msgError, msgSuccess} from '../../utils/msg';
import PrimaryButton from '../Dialog/PrimaryButton';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {useGlobal} from 'reactn';
import {getUserProjectsRef} from '../../common/firebaseRef/calendar';
import {updateListGgCalenviewShow} from '../../actions/googleCalendar';
import {futureEventsProxy} from '../../store/proxy/futureEvents.proxy';

function MoreContent({calendar, setCalendar, maxCal}) {

    const classes = useStyles()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [loading, setLoading] = useState(false)
    const {calendarUser, user, setCalendarUser} = useSnapshot(userProxy)
    const {googleProjects, geniamProjects, setGoogleProjects, setGeniamProjects} = useSnapshot(projectsProxy)
    const [googleCalendarList, setGoogleCalendarList] = useGlobal('googleCalendarList')
    const {idCalenderHidden = [], listGgCalenviewShow = []} = calendarUser
    const {google} = useSnapshot(futureEventsProxy)

    const [displayOnlyIntl, delIntl, deleteProject, deletedIntl, hiddenProject] = useIntlId(['calendar.setting.displayThisOnly', 'app.notification.delete', 'calendar.deleteProject', 'common.deleted', 'calendar.setting.hiddenProject'])

    const onCancel = () => {
        setOpenConfirm(false)
        setCalendar(null)
    }
    const onDelete = async () => {
        try {
            if (!calendar.id)
                return
            setLoading(true)
            const request = window.gapi.client.calendar.calendarList.delete({
                calendarId: calendar.id
            })
            const result = await request.then()
            if (result.status !== 204) {
                // error
                msgError('delete fail')
                return;
            }
            // remove local cal
            const nextValue = [...googleProjects]
            remove(nextValue, {id: calendar.id})
            setGoogleProjects([...nextValue])
            store.set("GGList", [...nextValue])

            msgSuccess(`${deletedIntl}`)
            refetchSource('google')
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            onCancel()
        }
    }
    const onClose = () => {
        setCalendar(null)
    }

    const updateIdCalenderHidden = async (listId) => {
        try {
            if (!user?.user_id) return;
            await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
                .set({
                    idCalenderHidden: listId
                }, {merge: true})
        } catch (e) {

        } finally {
        }
    }

    const hiddenCalendar = (calendar) => {
        let arrIdCalenderHidden = cloneDeep(idCalenderHidden || []);
        if (arrIdCalenderHidden.indexOf(calendar.id) === -1) arrIdCalenderHidden.push(calendar.id);
        updateIdCalenderHidden(arrIdCalenderHidden);
        onCancel();
    }
    const displayThisOnly = async (cal) => {
        let _googleCalendarList = cloneDeep(googleCalendarList)
        let _geniamProjects = cloneDeep(geniamProjects)
        let _listShow = cloneDeep(listGgCalenviewShow || [])
        try {
            const id = cal.id
            setCalendar(null)
            // change ge
            const nextValueGe = _geniamProjects.map(item => ({...item, selected: false}))
            setGeniamProjects(nextValueGe)

            _geniamProjects.forEach(item => {
                getUserProjectsRef(item.id).set({selected: false}, {merge: true})
            })
            // change list gg
            const plan = _googleCalendarList?.filter(g => g.check === true || g.check === false).sort((a, b) => google[b.id] - google[a.id])
            const nonSelect = _googleCalendarList?.filter(g => g && typeof g.check === 'undefined').sort((a, b) => google[b.id] - google[a.id])

            const nextValue = plan.map(item => ({...item, check: item.id === id}))
            setGoogleCalendarList([...nextValue, ...nonSelect])

            // const batch = window.gapi.client.newBatch()
            // _googleCalendarList.forEach(item => {
            //     item.selected = item.id === id
            //     const request = window.gapi.client.calendar.calendarList.update({
            //         "calendarId": item.id,
            //         "selected": item.id === id
            //     })
            //     batch.add(request)
            // })
            // await batch.then()

            // change list show
            const listShow = _listShow.map(item => ({...item, check: item.id === id}))
            setCalendarUser({...calendarUser, listGgCalenviewShow: listShow})
            updateListGgCalenviewShow(listShow)

        } catch (e) {
            console.log(e);
        } finally {

        }
    };
    if (!calendar)
        return null
    return (
        <div>
            <Popover
                open={Boolean(calendar)}
                anchorEl={calendar?.el}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={onClose}
            >
                <div className={classes.content}>
                    <PrimaryButton
                        block
                        name={displayOnlyIntl}
                        onClick={() => displayThisOnly(calendar)}
                        borderRadius={3}
                        minHeight={'30px'}
                    />
                    {maxCal !== 1 &&
                        <PrimaryButton
                            block
                            name={hiddenProject}
                            onClick={() => hiddenCalendar(calendar)}
                            borderRadius={3}
                            minHeight={'30px'}
                        />
                    }

                    {!calendar.primary ?
                        <DeleteButton
                            block
                            name={delIntl}
                            onClick={() => setOpenConfirm(true)}
                            borderRadius={3}
                            minHeight={'30px'}
                            icon={false}
                        />
                        :
                        null
                    }

                </div>
            </Popover>
            <Dialog open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
            >
                <DialogTitle> {calendar?.summary} </DialogTitle>
                <DialogContent>
                    {deleteProject}
                </DialogContent>
                <DialogActions>
                    <CancelButton
                        onClick={onCancel}
                        disabled={loading}
                        loading={loading}
                    />
                    <DeleteButton
                        disabled={loading}
                        onClick={onDelete}
                        loading={loading}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    content: {
        padding: 10,
    },
    button: {
        color: "#fff",
        backgroundColor: theme.props.primaryColor,
        fontWeight: 700,
        borderColor: theme.props.primaryColor,
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.props.primaryColor,
            borderColor: theme.props.primaryColor,
            opacity: 0.8
        },
        marginBottom: 8
    },
    buttonDelete: {
        color: "#fff",
        backgroundColor: theme.props.secondaryColor,
        fontWeight: 700,
        borderColor: theme.props.secondaryColor,
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.props.secondaryColor,
            borderColor: theme.props.secondaryColor,
            opacity: 0.8
        },
    }
}))

export default MoreContent;
