import axios from "axios";
import {
    API_CALENDAR_LIST,
    API_GENIAM,
    CALENDAR_COLLECTION,
    CREATE_GOOGLE_TASKS,
    DELETE_GOOGLE_TASKS
} from "../config/constants";
import {updateGoogleEvent} from "./googleCalendar";
import moment from "moment";
import {isString} from 'lodash';
import {db} from '../config/firebaseConfig';

async function getUserAccessToken(userId) {
    const userRef = db.doc(`${CALENDAR_COLLECTION}/${userId}`)
    const docSnap = await userRef.get()
    if (!docSnap.exists)
        return null

    const data = docSnap.data()
    const {googleAuth} = data
    if (!googleAuth)
        return null
    const {refreshToken} = googleAuth
    if (!refreshToken)
        return null
    let res = await axios.get(`${API_GENIAM}/googleApi/refreshToken?refreshToken=${refreshToken}`)
    const {access_token} = res.data
    return access_token
}

export const createGoogleTasksSchedule = async (event, users) => {
    try {
        const {data} = await axios.post(CREATE_GOOGLE_TASKS, {
            payload: {
                ...event,
                start: moment(event.start).format(),
                end: moment(event.end).format(),
                users
            }
        });
        if (data && isString(data))
            return data.substring(data?.lastIndexOf('/') + 1);
        else
            return null
    } catch (e) {
        console.log(e);
        return null
    }
};

export const createGoogleTasksScheduleGoogleCalendar = async (event, user) => {
    try {
        const {data} = await axios.post(CREATE_GOOGLE_TASKS, {
            payload: {
                ...event, user: {
                    user_id: user.user_id,
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    time_zone: user.time_zone,
                }
            }
        });
        if (data && isString(data)) {
            // update GoogleTasksID To Event
            event.googleTasksID = data.substring(data?.lastIndexOf('/') + 1);
            updateGoogleEvent(event, event.id).catch(e => console.log(e))
        }
    } catch (e) {
        console.log(e)
    }
}


export const handleDeleteGoogleTasksSchedule = async (googleTasksID) => {
    try {
        await axios.post(DELETE_GOOGLE_TASKS, {
            payload: googleTasksID
        })
    } catch (e) {
        console.log(e);
    }
}

export const handleAddGoogleCalendarHoliday = async (id, userId) => {
    const access_token = await getUserAccessToken(userId)
    try {
        await axios.post(API_CALENDAR_LIST, {
            id: id
        }, {
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
    } catch (e) {
        console.log(e);
    }
}

export const handleDeleteGoogleCalendarHoliday = async (id, userId) => {
    const access_token = await getUserAccessToken(userId)
    try {
        await axios.delete(API_CALENDAR_LIST + `/${encodeURIComponent(id)}`, {
            headers: {
                Authorization: "Bearer " + access_token,
                Accept: 'application/json'
            }
        })
    } catch (e) {
        console.log(e);
    }
}

export const checkTimeTitleEvent = (event, oldEvent) => {
    return (event.start !== oldEvent.start || event.end !== oldEvent.end || event.title !== oldEvent.title)
}
