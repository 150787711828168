import {orderBy} from "lodash";
import {AllIdentities, AllProjects, DataTask, FocusProjects, MilestoneProjects} from "./ChartData";
import {getGlobal} from "reactn";
import {getWeekEvents} from "../../context/common/getSelectedEvents";
import {snapshot} from 'valtio';
import {calenviewTimeRangeProxy} from '../../store/proxy/calenviewTimeRange.proxy';
import avaiavleEvent from '../events/avaiavleEvent';
import moment from 'moment';

export const weekFocus = (start, end) => {
    const {geEvents, ggEvents} = getGlobal()
    return getWeekEvents({...geEvents, ...ggEvents}, start, end)
}

export default function (events) {
    const {start, end} = snapshot(calenviewTimeRangeProxy)

    events = events.map(e => {
        if (moment(e.start).isSameOrAfter(start) && moment(e.end).isSameOrBefore(end)) {
            return e
        }
        if (moment(e.start).isSameOrBefore(start) && moment(e.end).isSameOrAfter(end)) {
            return {...e, start: start, end: end}
        }
        if (moment(e.start).isBetween(start, end, null, "[]") || moment(e.end).isBetween(start, end, null, "[]")) {
            let startDate = moment(e.start).isSameOrAfter(start) ? moment(e.start).local().format() : start
            let endDate = moment(e.end).isSameOrBefore(end) ? moment(e.end).local().format() : end
            return {...e, startDate, endDate}
        }
        return null
    }).filter(x => x && avaiavleEvent(x))

    return {
        milestone: orderBy(MilestoneProjects(events), 'day', 'asc'),
        focus: FocusProjects(events),
        projects: AllProjects(events),
        task: orderBy(DataTask(events), 'hour', 'desc'),
        identities: AllIdentities(events),
    }
}
