import React, {useEffect, useState} from 'react';
import InputNumberCustom from '../../Custom/InputNumberCustom';
import TargetHighLight from '../AnnouncementBar/TargetHighLight';
import {makeStyles} from '@material-ui/core/styles';
import useIntlId from '../../../hooks/useIntlId';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import getTarget from '../../../common/getTarget';
import {Switch} from 'antd';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';
import {cloneDeep} from 'lodash';

function ListTargetDays({handleCheckedHighLight}) {
    const classes = useStyles()
    const {bar} = useSnapshot(announcementProxy)
    const {user} = useSnapshot(userProxy)

    const [
        todayTargetIntl,
        textToday,
        daysAgoIntl,
        daysLaterIntl,
        rotationIntl,
    ]
        = useIntlId(
        [
            'settings.announcement.targetToday',
            'settings.announcement.targetTodayText',
            'app.viewSetting.daysAgo',
            'app.viewSetting.daysLater',
            'calenview.settingHighlight.rotation'
        ])

    const [daysAgo, setDaysAgo] = useState(bar?.daysAgo || 0)
    const [daysLater, setDaysLater] = useState(bar?.daysLater || 0)
    const [dayRotation, setDayRotation] = useState(bar?.dayRotation !== false)
    const [daysCheck, setDayCheck] = useState(bar?.daysCheck || [true])

    useEffect(() => {
        setDaysLater(bar?.daysLater || 0)
        setDaysAgo(bar?.daysAgo || 0)
        setDayRotation(bar?.dayRotation !== false)
        setDayCheck(bar?.daysCheck || [true])
    }, [bar?.daysLater, bar?.daysAgo, bar?.daysCheck, bar?.dayRotation])

    const handleChangeRotation = async () => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                dataList: [],
                dayRotation: !dayRotation,
            }, {merge: true})
            await batch.commit()
            setDayRotation(!dayRotation);
        } catch (error) {
            console.log(error);
        }
    }

    const updateAgo = async (num) => {
        try {
            let newValue = daysAgo + num > 5 ? daysAgo : daysAgo + num
            let newDaysCheck = cloneDeep(daysCheck);
            setDaysAgo(newValue)
            if (num === -1) {
                if (daysAgo + num >= 0)
                    newDaysCheck.shift()
            } else {
                if (daysAgo + num <= 5)
                    newDaysCheck.unshift(false)
            }
            setDayCheck([...newDaysCheck])
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                daysCheck: newDaysCheck,
                daysAgo: newValue
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }


    const updateLater = async (num) => {
        try {
            let newValue = daysLater + num > 5 ? daysLater : daysLater + num
            let newDaysCheck = cloneDeep(daysCheck);
            if (num === -1) {
                if (daysLater + num >= 0)
                    newDaysCheck.pop()
            } else {
                if (daysLater + num <= 5)
                    newDaysCheck.push(false)
            }
            setDaysLater(newValue)
            setDayCheck([...newDaysCheck])
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                daysCheck: newDaysCheck,
                daysLater: newValue
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleTarget}>{todayTargetIntl}</div>
                <div className={classes.selectDay}>
                    <InputNumberCustom
                        size={23} width={200} value={daysAgo} steps={1} min={0} label={daysAgoIntl}
                        onChange={(va, type) => {
                            if (type === "increment") {
                                return updateAgo(1)
                            }
                            return updateAgo(-1)
                        }}
                    />
                    <InputNumberCustom
                        size={23} width={200} value={daysLater} steps={1} min={0} label={daysLaterIntl}
                        onChange={(va, type) => {
                            if (type === "increment") {
                                return updateLater(1)
                            }
                            return updateLater(-1)
                        }}
                    />
                </div>
            </div>
            {
                getTarget(daysAgo, daysLater, 'day').map((item, index) => {
                    return (
                        <TargetHighLight
                            index={index}
                            value={item}
                            key={item}
                            fieldName={'Today'}
                            handleCheckedHighLight={handleCheckedHighLight}
                            showTime={'CustomDay'}
                            checked={daysCheck[index]}
                            checkName={'day'}
                            Intl={textToday}
                            fieldFocusHighLight={'dayTarget'}
                        />
                    )
                })
            }
            {daysCheck.filter(item => item === true).length > 1 && (
                <div className={classes.wrapRotation}>
                    <div className={classes.contentRotation}>
                        <div className={classes.showText}><span>{rotationIntl}</span></div>
                        <Switch
                            className={classes.iconChecked}
                            checked={dayRotation}
                            onChange={handleChangeRotation}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListTargetDays;
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 20,
        borderBottom: "solid 2px #F1F1F1",
        paddingBottom: 10,
    },
    titleTarget: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.props.primaryColor
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 5
    },
    selectDay: {
        display: "flex",
        alignItems: "center",
        "& > div:nth-child(1)": {
            marginRight: 10
        }
    },
    wrapRotation: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end',
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    contentRotation: {
        background: '#f1f1f1',
        display: 'flex',
        padding: '5px 10px',
        borderRadius: 30,

    },
    showText: {
        marginRight: 10,
        fontWeight: 600,
        fontSize: 12,
        display: 'flex',
        alignItems: "center"
    },
    iconChecked: {
        display: "flex",
        alignItems: "center",
    },
}))
