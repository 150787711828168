import React from 'react';
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import {orderBy} from 'lodash'
import useIntlId from "../../../../hooks/useIntlId";
import {Tooltip} from 'antd';
import {ReactComponent as AddSVG} from "../../../../asSvg/icons/add.svg";
import FromTo from "../../../Custom/FromTo";
import DeleteButton from "../../../Dialog/DeleteButton";
import EditButton from "../../../Dialog/EditButton";
import {publicIcon} from "../../../Calendar/handlers/infoEvent";

function SettingInfoBox({
                            classes,
                            setting,
                            setOpenTime,
                            setAnchorDelete,
                            setEventSetting,
                            setOpenDayOff,
                            setEventUpdate
                        }) {
    const [
        nonWorkingText,
        excludeTimeText,
        infiniteIntl,
        addHolidayText,
        Start,
        End
    ] = useIntlId([
        'setting.nonworkingdays',
        'setting.excludedaytime',
        'event.infinite',
        'setting.addholiday',
        'event.timeStart',
        'event.timeEnd'

    ]);

    return (
        <div style={{marginTop: 10}}>
            <Grid container className={classes.rowTable}>
                <Grid item xs={3}>
                    <FromTo
                        from={moment(setting.startDay).format('MM/DD')}
                        to={setting?.endDay ? moment(setting.endDay).format('MM/DD') : infiniteIntl}
                    />
                </Grid>
                <Grid item xs={9}>
                    <table className={classes.tableTime}>
                        <tr>
                            <td>{Start}</td>
                            {
                                [1, 2, 3, 4, 5, 6, 7].map((value, index) => {
                                    if (setting?.hiddenDays?.indexOf(value) !== -1)
                                        return null
                                    return (
                                        <td key={index} className={classes.divTimeValue}>
                                            {setting.timeDisplay.minTime.label?.substr(0, 5)}
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </table>
                    <table className={classes.tableTime}>
                        <tr>
                            <td>{End}</td>
                            {
                                [1, 2, 3, 4, 5, 6, 7].map((value, index) => {
                                    if (setting?.hiddenDays?.indexOf(value) !== -1)
                                        return null
                                    return (
                                        <td key={index} className={classes.divTimeValue}>
                                            {setting.timeDisplay.maxTime.label?.substr(0, 5)}
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </table>
                </Grid>
            </Grid>
            <div className={classes.divTimeCancel}>
                <div className={classes.cancelLabel}>{nonWorkingText}</div>
                <div className={classes.listTimeCancel}>
                    {
                        orderBy(setting.dayOffs, 'start', 'asc').map((value, index) => {
                            return (
                                <div key={index} className={classes.divTimeCancelValue}
                                >
                                    {moment(value.start).day() === moment(value.end).day() ? moment(value.start).format('MM/DD') : `${moment(value.start).format('MM/DD')} ~ ${moment(value.end).format('MM/DD')}`}
                                    <img className={classes.iconCloseSmall}
                                         src={publicIcon('closeSmall.svg')}
                                         alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
                <Tooltip title={addHolidayText}>
                    <IconButton
                        disableRipple={true}
                        className={classes.addDayOff}
                        onClick={(e) => {
                            setOpenTime(true)
                            setOpenDayOff(true)
                            setEventSetting(setting)
                        }}
                    >
                        <AddSVG className={classes.addIconSVGTimeSetting}/>
                        {/*<img src={process.env.PUBLIC_URL + '/icons/add.svg'} alt=""/>*/}
                    </IconButton>
                </Tooltip>
            </div>

            <div className={classes.divExcludeDayTime}>
                <div className={classes.excludeDayTimeLeft}>
                    <div className={classes.cancelLabel}>{excludeTimeText}</div>
                    <div className={classes.excludeDayTime}
                    >
                        {setting?.excludeTime?.label}
                    </div>
                </div>
                <div className={classes.btnGroup}>
                    <EditButton
                        onClick={(e) => setEventUpdate(setting)}
                    />

                    <DeleteButton
                        onClick={(e) => setAnchorDelete({...setting, currentTarget: e.currentTarget})}
                    />
                </div>
            </div>

        </div>
    );
}

export default SettingInfoBox;
