/* eslint-disable react/prop-types, react/jsx-handler-names */
import React, {useEffect, useState} from 'react';
import {listTime} from './constant'
import {ReactComponent as ArrowDownBlueSVG} from "../../asSvg/icons/arrowDownBlue.svg";
import {makeStyles} from "@material-ui/core/styles";
import {msgError} from '../../utils/msg';
import {getIntlId} from '../../hooks/useIntlId';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';

function TextSelectTo(props) {
    const {dataTime = null, setDataTime = null, changeTime = false, timeDisplayCustom, setTimeDisplayCustom} = props
    const {calendarUser, setCalendarUser} = useSnapshot(userProxy)
    const [values, setValues] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        if (calendarUser?.times) {
            setValues({
                minTime: calendarUser?.times?.minTime,
                maxTime: calendarUser?.times?.maxTime
            })
        }
        if (dataTime) {
            setValues({
                minTime: dataTime?.timeDisplay?.minTime,
                maxTime: dataTime?.timeDisplay?.maxTime
            })
        }
        // eslint-disable-next-line
    }, [dataTime, calendarUser?.times])

    const handleChange = async (data) => {
        if (data?.value <= timeDisplayCustom?.minTime?.value || data?.value <= dataTime?.timeDisplay?.minTime?.value) {
            if (dataTime) {
                let timeDisplay = dataTime.timeDisplay
                timeDisplay.maxTime = data
                setDataTime({...dataTime, timeDisplay})
            } else {
                setTimeDisplayCustom({...timeDisplayCustom, maxTime: data})
            }
            return msgError(getIntlId('event.toast.startTimeError'))
        }
        if (changeTime) {
            setValues({...values, maxTime: data})
            setTimeDisplayCustom({...timeDisplayCustom, maxTime: data})
            return
        }
        if (dataTime && setDataTime && typeof setDataTime === 'function') {
            let timeDisplay = dataTime?.timeDisplay
            timeDisplay.maxTime = data
            setValues({...values, maxTime: data})
            return setDataTime({...dataTime, timeDisplay})
        }
        const dataUpdate = {...calendarUser, times: {maxTime: data}}
        setCalendarUser(dataUpdate)
    }
    if (!values) return null
    return (
        <div className={classes.selectTime}>
            {/*<img className={classes.iconSelect} src={process.env.PUBLIC_URL + '/icons/arrowDownBlue.svg'} alt=""/>*/}
            <ArrowDownBlueSVG className={`${classes.iconSelect} ${classes.arrowDownBlueSVG}`}
            />
            <select className={classes.select}
                    onChange={(e) => handleChange(JSON.parse(e.target.value))}
            >
                {
                    listTime.map((item, index) => {
                        return (
                            <option key={index} value={JSON.stringify(item)}
                                    selected={values?.maxTime?.value === item.value && "selected"}>{item.label}
                            </option>
                        )
                    })
                }
            </select>
        </div>
    )
}


export default TextSelectTo
const useStyles = makeStyles(theme => ({
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        // padding: "0px 15px",
        height: 33,
        background: "#f1f1f1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        width: 100,
        fontWeight: "bold",
        paddingLeft: 20,
        color: "#000",
        pointerEvents: "all",
    },
    selectTime: {
        position: "relative",
    },
    iconSelect: {
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        pointerEvents: "none"
    },
    arrowDownBlueSVG: {
        fill: theme.props.primaryColor
    }
}))
