import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {ReactComponent as ArrowDownBlueSVG} from "../../asSvg/icons/arrowDownBlue.svg";
import {useDebounce} from 'react-use'
import {makeStyles} from "@material-ui/core/styles";

const Inputs = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${props => props.background ? props.background : "#fff"};
    height: ${props => props.size ? `${props.size}px` : "25px"};
    padding: 0 3px;
    font-weight: bold;
    border-radius: 24px;
    font-size: 12px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
;

    input {
        width: 45px;
        border: none;
        outline: none;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
    }

,
`
const InputText = styled.span`
    white-space: nowrap;
    padding: 0 10px;
    font-size: ${props => props.size ? `${props.size / 2}px` : "12px"};
    color: #000 !important;
`
const ButtonLeft = styled.button(props => ({
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    width: props.size,
    height: props.size,
    cursor: "pointer",
    transform: "rotate(90deg)",
    "& svg": {
        width: props.size,
        height: props.size,
    }
}))
const ButtonRight = styled.button(props => ({
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    width: props.size,
    height: props.size,
    cursor: "pointer",
    transform: "rotate(-90deg)",
    "& svg": {
        width: props.size,
        height: props.size,
    }
}))
// const StyledArrowDownBlueSVG = styled(ArrowDownBlueSVG)`
//     fill: ${props => props.colors.primary || props.fill};
// `;

function InputNumber({
                         onKeyDown,
                         onChange,
                         onBlur,
                         values,
                         steps,
                         label,
                         field,
                         min,
                         max,
                         disabled,
                         type,
                         background,
                         size = 25,
                         isDisableLeft = false,
                         forwardedRef,
                         onSelect = null
                     }) {
    const [inputValue, setInputValue] = useState(Number(values || 0))
    const classes = useStyles()
    useDebounce(() => {
        if (Number(inputValue) !== Number(values || 0)) {
            onChange(field, inputValue)
        }

    }, 300, [inputValue])
    useEffect(() => {
        if (Number(inputValue) !== Number(values || 0)) {
            setInputValue(Number(values || 0))
        }
        // eslint-disable-next-line
    }, [values])

    const onClickLeft = (e) => {
        if (disabled || isDisableLeft)
            return;
        if (type === "text") {
            if (typeof onChange === "function")
                onChange("degree", e)
            return
        }
        if (Number(values - steps) >= (min || 0)) {
            setInputValue(Number(values - steps))

        }
    }

    const onClickRight = (e) => {
        if (disabled)
            return;
        if (type === "text") {
            if (typeof onChange === "function")
                onChange("increment", e)
            return
        }
        if (max && Number(values + steps) <= max) {
            setInputValue(Number(values + steps))

        } else {
            setInputValue(Number(values + steps))

        }
    }

    const onChangeNumber = (e) => {
        if (Number(e.target.value) >= 0)
            setInputValue(Number(e.target.value))
        else setInputValue(Number(values || 0))
    }

    return (
        <Inputs styles background={background} size={size}>
            <ButtonLeft
                size={size - 2}
                onClick={onClickLeft}
            >
                <ArrowDownBlueSVG className={classes.iconArrow}/>
            </ButtonLeft>
            <div>
                {
                    type === "text" ?
                        <InputText size={size}
                                   className={onSelect ? classes.text : null}
                                   onClick={(e) => {
                                       if (typeof onSelect === "function") {
                                           onSelect(e)
                                       }
                                   }}>{label}
                        </InputText>
                        :
                        <React.Fragment>
                            <input
                                ref={forwardedRef}
                                value={inputValue ? inputValue : 0}
                                step={steps}
                                onKeyDown={onKeyDown}
                                onChange={onChangeNumber}
                                onBlur={onBlur}
                                type={"number"}
                                min={min}
                                max={max}
                                disabled={disabled}
                            />
                            <span>{label}</span>
                        </React.Fragment>
                }

            </div>
            <ButtonRight
                size={size - 2}
                onClick={onClickRight}
            >
                <ArrowDownBlueSVG className={classes.iconArrow}/>
            </ButtonRight>
        </Inputs>
    );
}

const useStyles = makeStyles(theme => ({
    text: {
        cursor: "pointer"
    },
    iconArrow: {
        fill: theme.props.primaryColor
    }
}))

export default React.forwardRef((props, ref) => <InputNumber {...props} forwardedRef={ref}/>);

