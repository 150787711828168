import React, {useEffect, useState} from 'react';
import {setGlobal} from 'reactn';
import {chunk, cloneDeep, concat, filter, findIndex} from 'lodash';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd";
import {db} from '../../config/firebaseConfig';
import {barRef} from '../../common/firebaseRef/annoucement';
import Slider from 'react-slick';
import {Button} from 'antd';
import {AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined} from '@ant-design/icons';
import getTarget from '../../common/getTarget';
import PreviewItem from './PreviewItem';
import vector from "../../asSvg/Vector.svg"
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {announcementProxy} from '../../store/proxy/announcement.proxy';

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 1200,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
        // console.log("before change", currentSlide, nextSlide);
    }
};

function PreviewHighLight(props) {
    const {bar} = useSnapshot(announcementProxy)
    const {user, calendarUser} = useSnapshot(userProxy)
    // const [openSetting] = useGlobal('openSetting')
    const [alignItems, setAlignItems] = useState(bar?.alignItems || "flex-start")
    const classes = useStyles()
    const {handleOpen, open} = useSnapshot(settingDialogProxy)

    const [dataHighLight, setDataHighLight] = useState([
        {
            id: "highLight",
            rotation: true,
            data: [],
        },
        {
            id: "monthTarget",
            rotation: true,
            data: [],
        },
        {
            id: "weeksTarget",
            rotation: true,
            data: [],
        },
        {
            id: "dayTarget",
            rotation: true,
            data: [],
        },
        {
            id: "remind",
            rotation: true,
            data: []
        }
    ]);
    const [listHighLightFilter, setListHighLightFillter] = useState([]);

    const [listHighLightStt, setListHighLightStt] = useState([]);

    useEffect(() => {
        if (dataHighLight.length) {
            setListHighLightFillter([]);
            dataHighLight.forEach((highLight) => {
                if (highLight.data.length > 0) {
                    if (highLight.data.length === 1) {
                        setListHighLightFillter(prev => [
                            ...prev,
                            {
                                ...highLight,
                                ...highLight.data[0],
                                id: highLight.id,
                                idTarget: highLight.id
                            }
                        ])
                    } else {
                        if (highLight.rotation) {
                            setListHighLightFillter(prev => [
                                ...prev,
                                {
                                    ...highLight,
                                    id: highLight.id,
                                    idTarget: highLight.id
                                }
                            ]);
                        } else {
                            if (highLight.id === highLight?.date) {
                                setListHighLightFillter(prev => [
                                    ...prev,
                                    {
                                        ...highLight,
                                        id: highLight.id,
                                        idTarget: highLight.id
                                    }
                                ]);
                            } else {
                                highLight.data.forEach((item) => {
                                    setListHighLightFillter(prev => [
                                        ...prev,
                                        {
                                            ...item,
                                            ...highLight,
                                            id: item.date,
                                            idTarget: highLight.id
                                        }
                                    ])
                                })
                            }
                        }
                    }
                }
            })
        }
    }, [dataHighLight]);

    useEffect(() => {
        if (bar.dataList && bar.dataList.length) {
            let dataList = cloneDeep(bar.dataList);
            let newDataList = [];
            dataList.forEach((item) => {
                if (item.data) {
                    newDataList.push(item);
                }
            })
            let ind = 1;
            listHighLightFilter.forEach(highLight => {
                let idx = newDataList.findIndex(item => item.id === highLight.id);
                if (idx < 0) {
                    ind--;
                } else {
                    newDataList[idx] = highLight;
                }
            })

            if (ind <= 0) {
                setListHighLightStt(listHighLightFilter);
            } else {
                setListHighLightStt(newDataList);
            }
        } else {
            setListHighLightStt(listHighLightFilter);
        }
    }, [bar.dataList, listHighLightFilter]);


    useEffect(() => {
        if (!bar?.alignItems)
            return
        setAlignItems(bar?.alignItems)
    }, [bar?.alignItems])

    useEffect(() => {
        if (!isNaN(bar?.rotationInterval)) {
            settings.autoplaySpeed = bar.rotationInterval * 1000
        }
    }, [bar?.rotationInterval])

    //Get DataHighLight
    useEffect(() => {
        const data = calendarUser?.focusText || []
        let newList = []
        let month, day, week, listDaysTarget, listWeeksTarget, listMonthsTarget = []
        let weeksCheck = bar?.weeksCheck || [true]
        let daysCheck = bar?.daysCheck || [true]
        let monthsCheck = bar?.monthsCheck || [true]
        let dayRotation = bar?.dayRotation;
        let weekRotation = bar?.weekRotation;
        let monthRotation = bar?.monthRotation;
        let dataHighLight = [
            {
                id: "highLight",
                data: [],
                rotation: true,
            },
            {
                id: "monthTarget",
                data: [],
                rotation: bar?.monthRotation,
            },
            {
                id: "weeksTarget",
                data: [],
                rotation: bar?.weekRotation,
            },
            {
                id: "dayTarget",
                data: [],
                rotation: bar?.dayRotation,
            },
            {
                id: "remind",
                data: [],
                rotation: true,
            }
        ];
        if (bar?.highlight && bar.highlight !== "" && bar?.isShowHighLight) {
            newList.push({focusText: bar?.highlight})
        }
        day = getTarget(bar?.daysAgo || 0, bar?.daysLater || 0, 'day')
        week = getTarget(bar?.weeksAgo || 0, bar?.weeksLater || 0, 'week')
        month = getTarget(bar?.monthsAgo || 0, bar?.monthsLater || 0, 'month')
        listDaysTarget = day.map((item, index) => {
            let idx = findIndex(data, {date: item})
            if (idx !== -1 && data[idx]?.focusText && daysCheck[index]) {
                return data[idx]
            }
            return null
        }).filter(x => x)
        listWeeksTarget = week.map((item, index) => {
            let idx = findIndex(data, {date: item})
            if (idx !== -1 && data[idx]?.focusText && weeksCheck[index]) {
                return data[idx]
            }
            return null
        }).filter(x => x)
        listMonthsTarget = month.map((item, index) => {
            let idx = findIndex(data, {date: item})
            if (idx !== -1 && data[idx]?.focusText && monthsCheck[index]) {
                return data[idx]
            }
            return null
        }).filter(x => x)
        let announcementSets = []
        if (bar?.announcementSets) {
            const {data, startDate, times} = bar?.announcementSets
            if (data?.length && times > 0) {
                const chunked = chunk(data.filter(x => x), times)
                let dayDiff = moment().diff(startDate, "days") % (chunked.length ?? 1)
                dayDiff = dayDiff > 0 ? dayDiff : 0;
                if (chunked[dayDiff] && chunked[dayDiff]?.length) {
                    announcementSets = chunked[dayDiff].map((text, idx) => ({
                        title: text,
                        index: idx + dayDiff * times
                    }))
                }
            }
        }
        const todayRemind = filter(bar?.reminds || [], (item) => item.content && moment(item.time).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))
            .map(item => ({...item, title: item.content, content: ''}))
        let listRemind = concat(announcementSets, todayRemind)

        let indexHighLight = findIndex(dataHighLight, {id: "highLight"})
        if (indexHighLight !== -1 && bar?.highLightBar) {
            dataHighLight[indexHighLight].data = newList
            dataHighLight[indexHighLight].index = Number.isInteger(bar?.indexHighLight) ? bar?.indexHighLight : 0
        }
        let indexMonth = findIndex(dataHighLight, {id: "monthTarget"})
        if (indexMonth !== -1 && bar?.highLightBar) {
            dataHighLight[indexMonth].data = listMonthsTarget
            dataHighLight[indexMonth].index = Number.isInteger(bar?.indexMonthTarget) ? bar?.indexMonthTarget : 3
            dataHighLight[indexMonth].rotation = monthRotation
        }
        let indexWeeks = findIndex(dataHighLight, {id: "weeksTarget"})
        if (indexWeeks !== -1 && bar?.highLightBar) {
            dataHighLight[indexWeeks].data = listWeeksTarget
            dataHighLight[indexWeeks].index = Number.isInteger(bar?.indexWeeksTarget) ? bar?.indexWeeksTarget : 2
            dataHighLight[indexWeeks].rotation = weekRotation
        }
        let indexDay = findIndex(dataHighLight, {id: "dayTarget"})
        if (indexDay !== -1 && bar?.highLightBar) {
            dataHighLight[indexDay].data = listDaysTarget
            dataHighLight[indexDay].index = Number.isInteger(bar?.indexDayTarget) ? bar?.indexDayTarget : 1
            dataHighLight[indexDay].rotation = dayRotation
        }
        let indexRemind = findIndex(dataHighLight, {id: "remind"})
        if (indexRemind !== -1 && bar?.remindBar) {
            dataHighLight[indexRemind].data = listRemind
            dataHighLight[indexRemind].index = Number.isInteger(bar?.indexRemind) ? bar?.indexRemind : 4
        }
        setDataHighLight((prevHighLight) => {
            if (isDiffHighLight(prevHighLight, [...dataHighLight]))
                return [...dataHighLight]
            return prevHighLight
        })

    }, [calendarUser?.focusText, bar, open])

    useEffect(() => {
        setGlobal({
            colHighLight: filter(dataHighLight, i => i?.data?.length).length
        })
    }, [dataHighLight, bar, open]);

    const isDiffHighLight = (prev, next) => {
        if (prev?.length !== next?.length)
            return true
        let result = false
        prev.forEach((item, index) => {
            const nextItem = next[index]
            if (item.id !== nextItem.id ||
                item.index !== nextItem.index ||
                item?.data?.length !== nextItem?.data?.length ||
                item?.data[0]?.title !== nextItem?.data[0]?.title ||
                item?.data[0]?.date !== nextItem?.data[0]?.date ||
                item?.rotation !== nextItem?.rotation
            ) {
                result = true
            }
            if (item.id === "highLight" && item?.data[0] !== nextItem?.data[0]) {
                result = true
            }
        })
        return result
    }

    const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
        try {
            const nextState = swap(cloneDeep(listHighLightStt), sourceIndex, targetIndex)
            nextState.forEach((item, index) => {
                item.index = index
            });

            setDataHighLight([...nextState])
            let temp = nextState.length
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            let barNew = cloneDeep({...bar});
            let idxHighLight = findIndex(nextState, {id: "highLight"})
            if (idxHighLight !== -1) {
                barNew.indexHighLight = nextState[idxHighLight].index
            } else {
                barNew.indexHighLight = temp
                temp++
            }
            let indexDay = findIndex(nextState, {id: "dayTarget"})
            if (indexDay !== -1) {
                barNew.indexDayTarget = nextState[indexDay].index
            } else {
                barNew.indexDayTarget = temp
                temp++
            }
            let idxWeeks = findIndex(nextState, {id: "weeksTarget"})
            if (idxWeeks !== -1) {
                barNew.indexWeeksTarget = nextState[idxWeeks].index
            } else {
                barNew.indexWeeksTarget = temp
                temp++
            }
            let idxMonth = findIndex(nextState, {id: "monthTarget"})
            if (idxMonth !== -1) {
                barNew.indexMonthTarget = nextState[idxMonth].index
            } else {
                barNew.indexMonthTarget = temp
                temp++
            }
            let idxRemind = findIndex(nextState, {id: "remind"})
            if (idxRemind !== -1) {
                barNew.indexRemind = nextState[idxRemind].index
            } else {
                barNew.indexRemind = temp
                temp++
            }

            let dataList = [];

            nextState.forEach((item, index) => {
                dataList.push({
                    id: item.id,
                    index: index
                })
            });

            barNew.dataList = dataList;

            batch.set(AnnouncementBar, {
                ...barNew,
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }

    }

    const openSettingHighlight = (item, id) => {
        if (open) return
        let data = {}
        data.target = id
        data.time = item?.date
        data.id = id
        if (id === "remind") {
            handleOpen(8)
            setGlobal({
                focusFromPreview: {...item, id: id}
            })
            return;
        }
        if (id === "highLight") {
            handleOpen(7)
            setGlobal({
                focusFromPreview: {...data}
            })
            return;
        }
        handleOpen(7)
        setGlobal({
            focusFromPreview: {...data}
        })
    }

    const updateAlignItem = async (value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                alignItems: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    if (!bar.highLightBar && !bar.remindBar)
        return null
    return (
        <>
            {
                open &&
                <div className={classes.groupBtn}>
                    <Button
                        type={alignItems === "flex-start" ? "primary" : ""}
                        onClick={
                            () => {
                                setAlignItems("flex-start")
                                updateAlignItem("flex-start")
                            }
                        }
                    >
                        <AlignLeftOutlined/>
                    </Button>
                    <Button
                        type={alignItems === "center" ? "primary" : ""}

                        onClick={
                            () => {
                                setAlignItems("center")
                                updateAlignItem("center")
                            }
                        }
                    >
                        <AlignCenterOutlined/>
                    </Button>
                    <Button
                        type={alignItems === "flex-end" ? "primary" : ""}
                        onClick={
                            () => {
                                setAlignItems("flex-end")
                                updateAlignItem("flex-end")
                            }
                        }
                    >
                        <AlignRightOutlined/>
                    </Button>
                </div>
            }
            <div className={classes.root}>
                <div style={{width: "100%"}}>
                    <GridContextProvider onChange={onChange}>
                        <GridDropZone
                            id={'highlight'}
                            boxesPerRow={listHighLightStt.length}
                            rowHeight={50}
                            style={{height: 50}}
                            disableDrag={!open}
                            className={classes.gridDropZone}
                        >
                            {cloneDeep(listHighLightStt).map((highLight, idx) => {
                                return (highLight?.data?.length > 1 && highLight.id === highLight.idTarget && highLight?.rotation) ?
                                    (
                                        <GridItem key={highLight.id} className={classes.GridItem}>
                                            <Slider key={highLight.index} {...settings} className={classes.slider}>
                                                {highLight?.data.map((item, index) => {
                                                    return (
                                                        <PreviewItem
                                                            key={index}
                                                            item={item}
                                                            alignItems={alignItems}
                                                            highlightId={highLight?.id}
                                                            openSettingHighlight={openSettingHighlight}
                                                            showVector={idx < listHighLightFilter.length - 1}
                                                        />
                                                    )
                                                })}
                                            </Slider>
                                        </GridItem>
                                    ) : (
                                        <GridItem key={idx} className={classes.GridItem}>
                                            <PreviewItem
                                                item={highLight}
                                                alignItems={alignItems}
                                                highlightId={highLight?.id}
                                                openSettingHighlight={openSettingHighlight}
                                                showVector={idx < listHighLightFilter.length - 1}
                                            />
                                        </GridItem>
                                    )
                            })}
                        </GridDropZone>
                    </GridContextProvider>
                </div>
            </div>
        </>
    )
}

export default React.memo(PreviewHighLight)
const useStyles = makeStyles(theme => ({
    GridItem: {
        overflow: 'hidden',
        '& > div': {
            flex: 1
        }
    },
    root: {
        backgroundColor: theme.props.primaryColor,
        width: "100%",
        "& .ant-btn-primary": {
            borderColor: theme.props.primaryColor,
            background: theme.props.primaryColor,
            "&:hover": {
                color: "#fff !important"
            }
        },
        "&:hover": {
            "& .ant-btn:hover, .ant-btn:focus": {
                color: theme.props.primaryColor,
                borderColor: theme.props.primaryColor
            }
        }
    },
    groupBtn: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        '&& button': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    gridDropZone: {
        height: 50,
        display: 'flex',
        backgroundColor: theme.props.primaryColor,
        '& > div': {
            flex: 1,
            display: 'flex',
            position: 'relative',
            overflow: "hidden",
            '&:after': {
                content: `url(${vector})`,
                right: 0,
                minWidth: 20,
                top: -4,
                position: 'absolute',
                height: 60
            },
            '&:last-child:after': {
                display: 'none'
            }
        },
    },
    slider: {
        '&& .slick-list .slick-slide': {
            border: 'none',
            width: 'auto !important',
            minWidth: '100%'
        }
    }
}))
