export function makeOverlayPath({width, height, x = 0, y = 0, r = 0}) {
    const {innerWidth: w, innerHeight: h} = window;
    // console.log({width, height, x, y, r})
    return `M${w},${h}\
            H0\
            V0\
            H${w}\
            V${h}\
            Z\
            M${x + r},${y}\
            a${r},${r},0,0,0-${r},${r}\
            V${height + y - r}\
            a${r},${r},0,0,0,${r},${r}\
            H${width + x - r}\
            a${r},${r},0,0,0,${r}-${r}\
            V${y + r}\
            a${r},${r},0,0,0-${r}-${r}\
            Z`;
}


export function makeMultiPathOverlay(ids = []) {
    if (!ids.length)
        return ''
    const {innerWidth: w, innerHeight: h} = window;
    let result = `M${w},${h}\
            H0\
            V0\
            H${w}\
            V${h}\
            Z\
            `
    ids.forEach(id => {
        const el = document.querySelector(id)
        if (el) {
            result += elPath(el.getBoundingClientRect())
        }
    })
    return result
}

export const elPath = ({width, height, x = 0, y = 0, r = 0}) => {
    // const {innerWidth: w, innerHeight: h} = window;

    return `M${x + r},${y}\
            a${r},${r},0,0,0-${r},${r}\
            V${height + y - r}\
            a${r},${r},0,0,0,${r},${r}\
            H${width + x - r}\
            a${r},${r},0,0,0,${r}-${r}\
            V${y + r}\
            a${r},${r},0,0,0-${r}-${r}\
            Z\
            `
}

export const containerPath = () => {
    const {innerWidth: w, innerHeight: h} = window;
    return `M${w},${h}\
            H0\
            V0\
            H${w}\
            V${h}\
            Z\
            `
}

export const isClickedPath = ({x, y, pathId}) => {
    const el = document.querySelector(pathId)
    if (!el)
        return false
    const pos = el.getBoundingClientRect()
    if (pos.top <= y && (pos.top + pos.height) >= y && pos.left <= x && (pos.left + pos.width) >= x)
        return true
    return false
}
