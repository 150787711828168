import React from 'react';
import AnnouncementCheck from '../../Custom/AnnouncementCheck';
import HighLight from '../AnnouncementBar/HighLight';
import {Button} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import {setGlobal, useGlobal} from 'reactn';
import useIntlId from '../../../hooks/useIntlId';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function HighLightBar(props) {
    const classes = useStyles()
    const {user} = useSnapshot(userProxy)
    const {lock, bar} = useSnapshot(announcementProxy)
    const [
        RotationIntl,
        unlockIntl,
        displayHighLightBar
    ] = useIntlId([
        'settings.announcement.rotation',
        'settings.announcement.unlock',
        'setting.display.highLightBar'
    ])
    const updateAnnouncementBar = fieldName => async (value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <AnnouncementCheck lock={lock}
                                   check={bar?.highLightBar || false}
                                   setCheck={updateAnnouncementBar("highLightBar")}
                                   name={displayHighLightBar}/>
                <AnnouncementCheck
                    borderBottom={'none'}
                    lock={lock}
                    name={RotationIntl}
                    value={bar?.rotationInterval || 1} label={"s"}
                    setValue={updateAnnouncementBar("rotationInterval")}/>
            </div>


            <HighLight updateHighLight={updateAnnouncementBar("highlight")}/>

            {
                lock && <Button
                    onClick={() => setGlobal({
                        openSetting: true,
                        settingTab: 12
                    })}
                    className={classes.btnUnlock}>
                    <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                    {unlockIntl}</Button>
            }
            {/*<PaymentList open={openPurcharse} setOpen={setOpenPurcharse}/>*/}

        </div>
    );
}

export default HighLightBar;
const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 15px',
    },
    box: {
        background: '#fff',
        padding: '0 15px',
        borderRadius: 15,

    },
    btnUnlock: {
        margin: "auto",
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    iconButton: {
        marginRight: 10,
    }
}))
