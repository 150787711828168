import {useEffect} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth, db} from '../config/firebaseConfig';
import {signInFirebase, useFetchApiUserData} from '../actions/user';
import {fetchGlobalApps} from '../common/fetchGlobalApps';
import {myCal} from '../common/myCal';
import {useSnapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';
import {themesProxy} from '../store/proxy/themes.proxy';
import {useGGStatus} from '../actions/googleCalendar';
import {CALENDAR_COLLECTION} from '../config/constants';
import qs from 'query-string';
import {createThemeDefault} from '../common/themes/createDefault';
import {useGeniamProjects} from './useGeniamProjects';
import {useGeniamEvents} from './useGeniamEvents';
import {useGoogleEvents} from './useGoogleEvents';
import {initFireStoreData} from '../common/calendarInfo';
import {useConnectZoomAuth, useZoomData} from '../actions/zoom';
import {getGlobalUrls} from '../common/firebaseRef/globalnavi';
import {formatUserApps} from '../common/globalApp/formatUserApps';
import {setGlobal, useGlobal} from 'reactn';
import {useVoteEvents} from './useVoteEvents';

export const useUserData = () => {
    const [fUser, fUserLoading, fUserError] = useAuthState(auth)
    const {setCalendarUser, user} = useSnapshot(userProxy)
    const {setList, setTheme} = useSnapshot(themesProxy)
    const [globalApps] = useGlobal('globalApps')
    useFetchApiUserData()
    useGGStatus()
    useGeniamProjects()
    useGeniamEvents()
    useGoogleEvents()
    useVoteEvents()
    useZoomData()
    useConnectZoomAuth(user?.email)
    const {idTheme} = qs.parse(window.location.search)
    const getTheme = async () => {
        try {
            if (!idTheme)
                return
            const snap = await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/listTheme/${Number(idTheme)}`).get()
            if (!snap.exists)
                return
            const data = snap.data()
            setTheme(data)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        if (!user?.user_id)
            return;
        const subUser = myCal()
            .onSnapshot(snapshot => {
                if (!snapshot.exists) {
                    // no exists data
                    initFireStoreData(user.user_id, user.email)
                    return;
                }
                const data = snapshot.data()
                if (!data.themeCustom) {
                    createThemeDefault(user.user_id)
                }
                setCalendarUser(snapshot.data())
            })
        const subTheme = myCal().collection("listTheme")
            .onSnapshot(snapshot => {
                setList(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
            })
        getTheme()
        return () => {
            if (subUser)
                subUser()
            if (subTheme)
                subTheme()
        }
    }, [user?.user_id])

    useEffect(() => {
        if (!user?.user_id)
            return;
        const urlSub = getGlobalUrls(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                const result = formatUserApps(data, globalApps)
                setGlobal({
                    ...result
                })
            })
        return () => {
            if (urlSub)
                urlSub()
        }
    }, [user?.user_id, globalApps])

    useEffect(() => {
        if (fUserLoading)
            return;
        if (!fUser)
            signInFirebase()

    }, [fUser, fUserLoading])

    useEffect(() => {
        if (fUserError)
            alert(fUserError)
    }, [fUserError])
    useEffect(() => {
        fetchGlobalApps()
    }, [])


}
