import React from 'react';

function AddAppDialog({open, }) {

    if (!open)
        return null
    return (
        <div></div>
    );
}

export default AddAppDialog;
