import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import styled from 'styled-components';
import MenuAppBar from "../../components/MenuAppBar/MenuAppBar";
import LeftHomeApp from "../../components/Application/LeftHomeApp";
import useIntlId from "../../hooks/useIntlId";
import {CircularProgress} from '@material-ui/core'
import EditEvent from "../../components/Calendar/EditEvent";
import DialogVoteBoxHandleEmail from "../../components/Meeting/EventsVote/DialogVoteBoxHandleEmail";
import store from "store";
import {NavigationGlobal} from '@Andom-Geniam/git-globalnavi';
import {auth, db} from '../../config/firebaseConfig';
import axios from 'axios';
import {COGNITO_CLIENT_ID, COGNITO_LOGOUT, IS_PRODUCTION, LOGIN_URL, LOGOUT_URL} from '../../config/constants';
import removeAllTokens from '../../common/removeAllToken';
import removeLoginOrRegisterKeys from '../../common/removeLoginOrRegisterKeys';
import {LanguageMessageContext} from '../../context/LanguageMessageContext';
import UpdateRecuringPopup from '../../components/Calendar/UpdateRecuringPopup';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {userProxy} from '../../store/proxy/user.proxy';
import {searchProxy} from '../../store/proxy/search.proxy';

const StyledSpan = styled.button(props => ({
    backgroundColor: props.item?.backgroundColor ? `${props.item?.backgroundColor}` : "#3788d8",
    width: 15,
    height: 15,
    marginRight: 15,
    borderRadius: '50%',
    border: 'none'
}))


function Index(props) {
    const classes = useStyles(props);
    const [numOfLine, setNumOfLine] = useState(store.get('numOfLine') ? store.get('numOfLine') : 2)
    const [, setCalendarHeight] = useState((window.innerHeight - 120) / (numOfLine))
    const {listSearch, searching} = useSnapshot(searchProxy);
    const [edit, setEdit] = useState(null)
    const [acceptEvent, setAcceptEvent] = useState(null);
    const [, setLoading] = useState(false);
    const [notFound] = useIntlId(['search.notFound'])
    const intl = useContext(LanguageMessageContext)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {user} = useSnapshot(userProxy)

    const {year, month, date} = moment.locale() === 'en-gb' ?
        {year: ' -', month: " -", date: " -"}
        : moment.locale() === 'vi' ?
            {year: ' -', month: " -", date: " -"}
            :
            {year: '年', month: "月", date: "日"}


    const onLogout = async (e) => {
        setLoading(true);
        try {
            await axios.post(LOGOUT_URL);
            await auth.signOut()
            removeAllTokens();
            removeLoginOrRegisterKeys()
            localStorage.clear()
            const loginUrl = encodeURIComponent(`${LOGIN_URL}?redirect_url=${window.location.href}`)
            window.location = `${COGNITO_LOGOUT}?client_id=${COGNITO_CLIENT_ID}&logout_uri=${loginUrl}`
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    };
    const onClickAccount = () => {
        window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account`, '_blank')
    }

    const onClickSetting = () => {
        console.log("setting")
        handleOpen(11)
    };
    return (
        <div>

            <div className={"flex items-center justify-between bg-[#f1f1f1] max-w-full overflow-auto"}>
                <MenuAppBar
                    numOfLine={numOfLine}
                    setNumOfLine={setNumOfLine}
                    setCalendarHeight={setCalendarHeight}
                />
                <NavigationGlobal
                    user={user}
                    db={db}
                    service={"calenview"}
                    isProduction={false}
                    onClickLogOut={onLogout}
                    switchAccount={onLogout}
                    onClickAccount={onClickAccount}
                    intl={intl}
                    onClickSetting={onClickSetting}
                />
            </div>

            <div className={`${classes.root} clearfix`}>
                <div className={classes.left}>
                    <LeftHomeApp/>
                </div>
                <div className={classes.right}>
                    {searching ?
                        <CircularProgress/>
                        : null}
                    {
                        listSearch.length !== 0 ?
                            <div>
                                {
                                    listSearch.map((item, index) => {
                                        return (
                                            <div key={index} className={classes.itemSearch}
                                                 onClick={() => {
                                                     setEdit({event: {...item, allDay: Boolean(item.allDay)}})
                                                 }}
                                            >
                                                <div
                                                    className={classes.itemDay}>{moment(item?.start).format("MM/DD")}</div>
                                                <div className={classes.flexBox}>
                                                    {
                                                        moment(item?.start).date() === moment(item?.end).date() ?
                                                            <div className={classes.itemTime}>
                                                                {moment(item?.start).format("HH:mm")} ~ {moment(item?.end).format("HH:mm")} &nbsp;
                                                                {moment(item?.start).format(`YYYY${year} M${month} DD${date} ddd`)}
                                                            </div>
                                                            :
                                                            <div className={classes.itemTime}>
                                                                {moment(item?.start).format("HH:mm")} &nbsp; {moment(item?.start).format(`YYYY${year} M${month} DD${date} ddd`)}
                                                                &nbsp; ~&nbsp;
                                                                {moment(item?.end).format("HH:mm")} &nbsp; {moment(item?.end).format(`YYYY${year} M${month} DD${date} ddd`)}

                                                            </div>
                                                    }
                                                    <div className={classes.itemTitle}>
                                                        <StyledSpan item={item}/>{item.title}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                            </div>
                            :
                            <div>{!searching ? notFound : ""}</div>
                    }
                </div>
            </div>
            <EditEvent editInfo={edit} setEditInfo={setEdit} setAcceptEvent={setAcceptEvent}/>
            <UpdateRecuringPopup/>
            {
                Boolean(acceptEvent) &&
                <DialogVoteBoxHandleEmail
                    choose={acceptEvent}
                    setChoose={setAcceptEvent}
                />
            }
        </div>
    );
}

export default Index;
const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 60px)'
    },
    left: {
        padding: '10px 0px 0px 10px',
        display: 'block',
        boxSizing: 'border-box',
        width: '260px',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
        overflow: "auto",
        height: "100%"
    },
    right: {
        display: 'block',
        boxSizing: 'border-box',
        float: `left`,
        width: `calc(100% - 260px)`,
        margin: 'auto',
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        overflow: "auto",
        height: "calc(100% - 25px)"
    },
    itemSearch: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0 40px",
        padding: "8px 5px",
        borderBottom: "solid 1px #dadce0",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#dadce0"
        },
        [theme.breakpoints.down('sm')]: {
            margin: "0 15px",
        },
    },
    itemDay: {
        fontSize: 18,
        fontWeight: "bold",
        width: 100
    },
    itemTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
        },
    },
    itemTime: {
        minWidth: 410,
        textAlign: "left",
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            minWidth: "unset",
            marginBottom: 8
        },
    },
    flexBox: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
        },
    }
}))
