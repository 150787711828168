import React, {useEffect, useState} from 'react'
import {Typography, withStyles} from '@material-ui/core'
import {useGlobal} from 'reactn'
import {getIcon} from "../Calendar/handlers/getIcon";
import MenuItemContainer from "./MenuItemContainer";
import {ReactComponent as AddLargeSVG} from "../../asSvg/icons/addLarge.svg";
import IconButton from "@material-ui/core/IconButton";
import SelectIconsDialog from "./SelectIcons/SelectIconsDialog";
import {Input, Popover} from "antd";
import DialogCustom from "../Custom/DialogCustom";
import {cloneDeep, remove} from "lodash"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {myCal} from "../../common/myCal";
import useIntlId from "../../hooks/useIntlId";
import styles from './CalendarEventSettingStyle'
import CancelButton from "../Dialog/CancelButton";
import SaveButton from "../Dialog/SaveButton";
import DeleteButton from "../Dialog/DeleteButton";
import {msgError, msgSuccess} from '../../utils/msg';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';


function CalendarEventSetting({classes}) {
    const {calendarUser, setCalendarUser} = useSnapshot(userProxy)
    let tasks = calendarUser?.tasks
    const [values, setValues] = useState([])
    const [openAddTask, setOpenAddTask] = useGlobal('openAddTask')
    const [loading, setLoading] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [item, setItem] = useState({})
    const [editing, setEditing] = useState(false)
    const [name, setName] = useState("")
    const [tagEventIntl, tagFuncitonIntl, delIntl] = useIntlId(['event.tagEvent', 'event.tagFunciton', 'milestone.isDelete'])
    const [, setOpenSetting] = useGlobal('openSetting')
    const [toastChangeSuccessIntl, toastDeleteSuccessIntl, nameNullIntl, changeNameIntl] = useIntlId(['toast.success.change', 'toast.deleteUser', 'event.toast.nameEmpty', 'settings.tag.changeName'])
    const [taskOrder] = useIntlId(['settings.taskOrder'])
    useEffect(() => {
        if (!tasks?.data)
            return
        setValues(tasks?.data)

    }, [tasks])

    const handleAdd = async ({icon = '', name, type = '', file = null}) => {
        if (loading || !name || !type || (type === "file" && !file) || (type === "icon" && !icon))
            return
        const oldValue = {...tasks}
        const _tasks = cloneDeep(tasks)
        setLoading(true)
        try {
            // let index = tasks.data.length
            _tasks.data.push({
                // is_active: true,
                // is_default: false,
                icon,
                name,
                // index,
                file,
                type
            })
            await myCal().set({
                tasks: _tasks
            }, {merge: true})
            setCalendarUser({...calendarUser, tasks: _tasks})
        } catch (e) {
            console.log(e);
            msgError(e.toString())
            setCalendarUser({...calendarUser, tasks: oldValue})
        } finally {
            setLoading(false)
            setOpenAddTask(false)
            setOpenSetting(false)
        }
    }

    const handleUpdate = async () => {
        if (!name || loading || name === "" || name?.replace(/\s+/g, '') === "") {
            setEditing(false)
            setItem({})
            setName("")
            msgError(`${nameNullIntl}`)
            return
        }
        const oldValue = {...tasks}
        const _tasks = cloneDeep(tasks)
        setLoading(true)
        try {
            _tasks.data.forEach((task, idx) => {
                if (task.icon === item.icon && task?.file?.id === item?.file?.id) {
                    _tasks.data[idx].name = name
                }
            });
            await myCal().set({
                tasks: _tasks
            }, {merge: true})
            setCalendarUser({...calendarUser, tasks: _tasks})
            msgSuccess(`${toastChangeSuccessIntl}`)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
            setCalendarUser({...calendarUser, tasks: oldValue})
        } finally {
            setLoading(false)
            setEditing(false)
            setItem({})
            setName("")
        }
    }

    const onCloseDelete = () => {
        setOpenDelete(false)
        setItem({})

    }
    const onOpenDelete = (item) => {
        if (tasks?.data.length > 1) {
            setOpenDelete(true)
            setItem(item)
        }

    }
    const onCloseUpdate = () => {
        setEditing(false)
        setItem({})
        setName("")
    }
    const openUpdate = (item) => {
        // setOpenAddTask(true)
        setName(item.name)
        setEditing(true)
        setItem(item)

    }
    const handleDelete = async () => {
        const oldValue = {...tasks}
        const _tasks = cloneDeep(tasks)

        try {
            if (item?.type === "file") {
                remove(_tasks.data, e => e.file?.id === item?.file?.id)
            } else {
                remove(_tasks.data, {"icon": item.icon})
            }
            await myCal().set({
                tasks: _tasks
            }, {merge: true})
            msgSuccess(`${toastDeleteSuccessIntl}`)
            setCalendarUser({...calendarUser, tasks: _tasks})
        } catch (e) {
            console.log(e);
            setCalendarUser({...calendarUser, tasks: oldValue})
            msgError(e.toString())
        } finally {
            setOpenDelete(false)
            setItem({})
        }

    }
    const onChangeName = (e) => {
        setName(e.target.value)
    }
    const Content = ({item, onDelete}) => {
        return (
            <div className={classes.contentPopover}>
                <div className={classes.name}>
                    {item.name}
                </div>
                <div>
                    <IconButton
                        disableRipple={true}
                        onClick={() => openUpdate(item)}
                    >
                        <EditIcon/>
                    </IconButton>
                    {
                        onDelete && <IconButton
                            disableRipple={true}
                            onClick={() => onOpenDelete(item)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    }
                </div>
            </div>
        )
    }
    if (values.length === 0) return null
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                {taskOrder}
            </Typography>
            <MenuItemContainer className={classes.listIconTask}>
                <div className={classes.label}>{tagEventIntl}</div>
                <div className={classes.listTag}>
                    {
                        values.map((item, index) => {
                            if (item.icon === "AssignmentTurnedIn")
                                return null
                            return (
                                <Popover content={<Content item={item} onDelete={true}/>}
                                         overlayStyle={{minWidth: 220, zIndex: 9990}}
                                         onMouseLeave={() => {
                                             setEditing(false)
                                             setName("")
                                         }}
                                         placement="bottom"
                                         key={index}
                                         destroyTooltipOnHide={{keepParent: false}}
                                >
                                    <div key={index} className={classes.flex}>
                                        {/*<Typography className={classes.numText}>{index + 1}</Typography>*/}
                                        <div>
                                            <div className={classes.iconSvg}>
                                                {
                                                    item?.type === "file" ?
                                                        <div>
                                                            <img alt={item?.name} src={item.file?.url}
                                                                 className={classes.taskImg}/>
                                                        </div>
                                                        :
                                                        <span
                                                            className={`material-icons md-24`}>
                                        {getIcon(item.icon)}
                                                </span>
                                                }
                                            </div>
                                            <div
                                                className={classes.boxItemName}>{item.name}</div>

                                        </div>
                                    </div>
                                </Popover>

                            )
                        })
                    }
                    <IconButton
                        disableRipple={true}
                        className={classes.addIconSVG}
                        onClick={() => setOpenAddTask(true)}
                    >
                        <AddLargeSVG/>
                    </IconButton>
                </div>
                <div className={classes.label}>{tagFuncitonIntl}</div>
                <div className={classes.listTag}>

                    {
                        values.map((item, index) => {
                            if (item.icon !== "AssignmentTurnedIn")
                                return null
                            return (
                                <Popover content={<Content item={item} onDelete={false}/>}
                                         overlayStyle={{minWidth: 220, zIndex: 9990}}
                                         onMouseLeave={() => {
                                             setEditing(false)
                                             setName("")
                                         }}
                                         placement="bottom"
                                         key={index}
                                         destroyTooltipOnHide={{keepParent: false}}
                                >
                                    <div key={index} className={classes.flex}>
                                        {/*<Typography className={classes.numText}>{index + 1}</Typography>*/}
                                        <div>
                                            <div className={classes.iconSvg}>
                                                {
                                                    item?.type === "file" ?
                                                        <div>
                                                            <img alt={''} src={item.file?.url}
                                                                 className={classes.taskImg}/>
                                                        </div>
                                                        :
                                                        <span
                                                            className={`material-icons md-24`}>
                                        {getIcon(item.icon)}
                                                </span>
                                                }
                                            </div>
                                            <div
                                                className={classes.boxItemName}>{item.name}</div>

                                        </div>
                                    </div>
                                </Popover>

                            )
                        })
                    }
                </div>
            </MenuItemContainer>
            {
                openAddTask &&
                <SelectIconsDialog open={openAddTask} setOpen={setOpenAddTask} onAdd={handleAdd}
                                   loading={loading} item={item} setItem={setItem}/>
            }
            {
                editing &&
                <DialogCustom
                    open={editing}
                    onClose={onCloseUpdate}
                    title={changeNameIntl}

                >
                    <div className={classes.textField}>
                        <Input value={name}
                               onChange={(e) => onChangeName(e)}
                               onPressEnter={() => handleUpdate()}
                               autoFocus={true}
                        />
                        <div className={classes.groupBtn}>
                            <CancelButton
                                onClick={onCloseUpdate}
                            />
                            <SaveButton
                                onClick={() => handleUpdate()}
                            />
                        </div>
                    </div>
                </DialogCustom>
            }
            {
                openDelete &&
                <DialogCustom open={openDelete}
                              title={delIntl}
                              onClose={onCloseDelete}
                              handleDelete={handleDelete}
                >
                    <div className={'clf-flex-center'}>
                        <CancelButton
                            onClick={onCloseDelete}
                        />
                        <DeleteButton
                            onClick={handleDelete}
                        />
                    </div>
                </DialogCustom>
            }
        </div>
    )
}

export default withStyles(styles)(CalendarEventSetting)
