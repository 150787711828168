import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Grid, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {publicIcon} from "../Calendar/handlers/infoEvent";
import {Button} from 'antd';
import styled from 'styled-components';
import {useServiceGuide} from '../../context/ServiceGuideContext';
import {findIndex} from 'lodash';
import store from 'store';
import moment from 'moment';
import {useGlobal} from 'reactn';
import {db} from '../../config/firebaseConfig';
import {USER_COLL} from '../../config/constants';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';

const Step = styled.div(props => ({
    width: 46,
    height: 14,
    backgroundImage: `url(${props.url})`,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
    color: props.color || "#000",
}))

const triggerConditionType = {
    firstTime: 1,
    allTime: 2,
}

function ServiceGuide() {
    const classes = useStyles()
    const {guides} = useServiceGuide()
    const {user} = useSnapshot(userProxy)
    const [open, setOpen] = useState(false)
    const [guide, setGuide] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [numberSteps, setNumberSteps] = useState(0)
    const [contentStep, setContentStep] = useState({})
    const [timeLogin, setTimeLogin] = useState(false)

    useEffect(() => {
        let idx = findIndex(guides, {serviceId: "calenview"})
        if (idx === -1) {
            setGuide(null)
            return
        }
        setGuide(guides[idx])
        setContentStep(guides[idx]?.stepsList[currentIndex])
        // eslint-disable-next-line
    }, [guides])

    useEffect(() => {
        setNumberSteps(guide?.stepsList.length || 1)
        let ggToken = store.get("ggToken")
        checkTimeLogin()
        if (store.get("googleAuth") && guide?.cancelCondition === 'isGoogle') {
            setOpen(false)
        } else {
            if ((guide?.triggerCondition === triggerConditionType.allTime && moment(ggToken?.exp).format('HH:mm:ss YYYY-MM-DD') === moment().format('HH:mm:ss YYYY-MM-DD')) || (guide?.triggerCondition === triggerConditionType.firstTime && timeLogin)) {
                setOpen(true)
            }
        }
        // eslint-disable-next-line
    }, [guide])

    useEffect(() => {
        setContentStep(guide?.stepsList[currentIndex])
        // eslint-disable-next-line
    }, [currentIndex])

    const onNext = () => {
        if (currentIndex >= numberSteps - 1)
            return handleClose()
        setCurrentIndex(currentIndex + 1)
    }
    const onPrev = () => {
        if (currentIndex <= 0)
            return;
        setCurrentIndex(currentIndex - 1)
    }
    const handleClose = () => {
        setOpen(false)
        updateTimeLoginUser()
    }
    const updateTimeLoginUser = async () => {
        try {
            await db.doc(`${USER_COLL}/${user.user_id}`)
                .set({
                    timeLogin: moment().utc().format(),
                }, {merge: true})
        } catch (e) {
        } finally {
        }
    }
    const checkTimeLogin = async () => {
        try {
            const userRef = db.doc(`${USER_COLL}/${user.user_id}`)
            const docSnap = (await userRef.get()).data()
            setTimeLogin(!docSnap?.timeLogin)
        } catch (e) {
        } finally {
        }
    }

    if (!contentStep)
        return null
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.dialog}
            >
                <IconButton disableRipple={true} className={`${classes.closeIcon} DialogCustom-iconClose`}
                            onClick={handleClose}>
                    <img src={publicIcon('close.svg')} alt=""/>
                </IconButton>
                <DialogTitle>
                    {guide?.title ?
                        (guide?.title.length > 500 ? (guide?.title.slice(0, 500) + '...') : guide?.title) :
                        ''}
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid>{contentStep?.titleSteps}</Grid>
                    <div style={{textAlign: '-webkit-center'}}>
                        {
                            contentStep?.media?.url ?
                                <div>
                                    {contentStep?.media?.type.includes('image') ?
                                        <img src={contentStep?.media?.url} className={classes.mediaImage} alt=""/> :
                                        <video className={classes.media} src={contentStep?.media?.url} controls>
                                        </video>
                                    }
                                </div>
                                :
                                <img src={"icons/default_image.png"} className={classes.mediaImage} alt=""/>
                        }

                    </div>
                    <Grid>{contentStep?.subTitleSteps}</Grid>
                </DialogContent>
                <div className={classes.bottom}>
                    <Button onClick={onPrev} className={classes.back}>Back</Button>
                    <div className={classes.steps}>
                        {
                            Array(numberSteps).fill(1).map((v, idx) => {
                                let color = '#C4C4C4'
                                let url = './healperTips/step.svg'
                                if (idx === 0) {
                                    url = '/healperTips/startStep.svg'
                                    color = '#fff'
                                }
                                if (idx <= currentIndex && idx !== 0) {
                                    url = './healperTips/stepsActive.svg'
                                    color = '#fff'
                                }
                                if (idx === numberSteps - 1 && idx !== currentIndex && idx !== 0) {
                                    url = './healperTips/endStep.svg'
                                    color = '#C4C4C4'
                                }
                                if (idx === numberSteps - 1 && idx === currentIndex) {
                                    url = './healperTips/endActiveStep.svg'
                                    color = '#fff'
                                }
                                return (
                                    <Step url={url} color={color} key={Math.random()}>{idx + 1}</Step>)

                            })
                        }
                    </div>
                    <Button className={classes.next} onClick={onNext} type="primary">{
                        currentIndex < numberSteps - 1 ? 'next' : 'finish'
                    }</Button>

                </div>
                {guide?.triggerCondition === triggerConditionType.allTime &&
                <p className={classes.noteS}>※ このアナウンスは、Google カレンダーが設定されるまで、毎回表示されます。</p>}
            </Dialog>
        </div>
    );
}

export default ServiceGuide;
const useStyles = makeStyles(theme => ({
    dialog: {
        zIndex: 99,
        '& .MuiPaper-root': {
            overflow: "unset",
            background: "#fff",
            position: "relative",
            maxWidth: 800,
            minWidth: 600,
            borderRadius: 9,
            wordBreak: 'break-all'
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center'
        },
        '& .MuiTypography-root': {
            fontWeight: 'bold',
            // fontFamily: 'Montserrat',
            fontStyle: 'normal',
            textTransform: 'uppercase',
        },
    },
    closeIcon: {
        position: "absolute",
        top: -15,
        right: -15,
        backgroundColor: "#fff",
        cursor: "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 99,
        "&:hover": {
            backgroundColor: "#fff",
        },
        "&:focus": {
            backgroundColor: "#fff",
        }
    },
    content: {
        // fontFamily: 'Montserrat',
        fontStyle: 'normal',
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '6px 16px',
        fontWeight: 'bold',
    },
    noteS: {
        // fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontSize: 12,
        margin: 5,
    },
    media: {
        width: 500,
        height: 280,
    },
    mediaImage: {
        width: 500,
        height: 280,
        marginBottom: 6,
    },
    bottom: {
        display: "flex",
        backgroundColor: "#e1e1e1",
        borderRadius: "9px",
        padding: 10,
        alignItems: "center",
        justifyContent: "space-between"
    },
    steps: {
        padding: "0 15px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "unset",
    },
    back: {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "bold",
        color: "#1790FF",
        backgroundColor: "#fff",
        border: "none",
        borderRadius: "7px",
        padding: "1px 15px",
        "&:hover, &:focus": {
            color: '#1790FF',
            backgroundColor: "#fff",
            border: "none",
        },
    },
    next: {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "bold",
        backgroundColor: '#1790FF',
        color: "#fff",
        border: "none",
        borderRadius: "7px",
        padding: "1px 15px",
        "&:hover, &:focus": {
            backgroundColor: '#1790FF',
            color: "#fff",
            border: "none",
        },
    },
    note: {
        display: "flex",
        padding: "0 30px 10px 30px",
        alignItems: "center"
    },
    noteImg: {
        maxWidth: 100,
        objectFit: "contain"
    },
    noteText: {
        paddingLeft: 20
    }
}))
