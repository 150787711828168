import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import commonStyles from "./commonStyles";

export default function PrimaryButton({name, onClick, ...others}) {
    const classes = commonStyles()
    return (
        <CLFButtonSVG
            minHeight={"31px"}
            name={name}
            onClick={onClick}
            className={classes.primary}
            {...others}
        />
    )
}
