import React, {useEffect, useState} from "react";
import {ReactComponent as ArrowDownBlueSVG} from "../../../../asSvg/icons/arrowDownBlue.svg";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import SaveButton from "../../../Dialog/SaveButton";
import {FormControl} from "@material-ui/core";
import {db} from "../../../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../../config/constants";
import {msgSuccess} from "../../../../utils/msg";
import {Select} from "antd";
import useIntlId, {getIntlId} from "../../../../hooks/useIntlId";
import {isNumber} from "lodash";
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';

const WeekStartDate = (props) => {
    const [dateValue, setDateValue] = useState(1);
    const classes = useStyles();
    const {Option} = Select;
    const {user, calendarUser, setCalendarUser} = useSnapshot(userProxy);
    const dateString = moment.weekdays();
    const [titlteIntl] = useIntlId(["settings.worktime.weekDate"]);

    useEffect(() => {
        const val = !isNumber(calendarUser?.startDate) ? 1 : calendarUser?.startDate;
        setDateValue(val);
    }, [calendarUser?.startDate]);

    const handleChangeValue = (e) => {
        setDateValue(e);
    };

    const handleChangeStartDate = async () => {
        try {
            if (!user.user_id) return;
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`);

            let newValue = dateValue > 0 ? dateValue : 7;

            await userRef.set(
                {
                    startDate: newValue,
                },
                {merge: true}
            );
            setCalendarUser({...calendarUser, startDate: newValue})
            msgSuccess(getIntlId("toast.success.change"));
        } catch (e) {
            console.log(e);
        } finally {

        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>{titlteIntl}</div>
            <div className={classes.left}>
                <FormControl>
                    <div className={classes.selectDate}>
                        <Select
                            style={{ width: 120 }}
                            bordered={false}
                            value={dateValue !== 7 ? dateString[dateValue] : dateString[0]}
                            className={classes.select}
                            onChange={handleChangeValue}
                        >
                            {dateString.map((day, index) => {
                                return (
                                    <Option key={index} value={index}>
                                        {day}
                                    </Option>
                                );
                            })}
                        </Select>
                        <ArrowDownBlueSVG className={`${classes.iconSelect} ${classes.iconArrowDownBlueSVG}`} />
                    </div>
                </FormControl>
                <SaveButton onClick={handleChangeStartDate} />
            </div>
        </div>
    );
};
export default WeekStartDate;
const useStyles = makeStyles((theme) => ({
    root: {
        // background: "#fff",
        display: "flex",
        flexDirection: "row",
        paddingBottom: "5px",
        justifyContent: "space-between",
        // borderRadius: "25px",
        alignItems: "center",
        width: "100%",
        borderBottom: "1px solid #f1f1f1",
    },
    left: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    title: {
        color: theme.props.primaryColor,
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 12,
    },
    iconArrowDownBlueSVG: {
        fill: theme.props.primaryColor,
    },
    selectDate: {
        position: "relative",
    },
    select: {
        border: "none",
        cursor: "pointer",
        outline: "none !important",
        // padding: "0px 15px",
        height: 35,
        background: "#f1f1f1",
        borderRadius: "40px",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        width: 110,
        fontWeight: "bold",
        // paddingLeft: 15,
        color: "#000",
        pointerEvents: "all",
        "& .ant-select-selection-item": {
            height: 28,
            alignItems: "center",
        },
        "& .ant-select-selector": {
            padding: "0 15px !important",
            alignItems: "center",
        },
    },

    iconSelect: {
        fill: theme.props.primaryColor,
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        pointerEvents: "none",
        // width: 26,
        // height: 26,
    },
}));
