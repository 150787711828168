import moment from "moment";
import getUserTimeZone from '../../../common/getUserTimeZone';
import momentTz from 'moment-timezone';

export const isWorkSettingDay = (day, works) => {
    if (!works.length) {
        return false
    }
    let result = false
    works.forEach(work => {
        if (result)
            return
        if (!work.endDay) {
            if (moment(day).isSameOrAfter(moment(work.startDay).startOf("day"))) {

                result = !(work.hiddenDays && work.hiddenDays.length &&
                    work.hiddenDays.includes(moment(day).isoWeekday()));
                return;
            }
        }
        let start = moment(work.startDay).startOf("day")
        let end = moment(work.endDay).endOf("day")
        if (moment(day).isBetween(start, end, null, "[]")) {

            result = !(work.hiddenDays && work.hiddenDays.length &&
                work.hiddenDays.includes(moment(day).isoWeekday()));
        }
        let dayOffs = work.dayOffs || []
        if (dayOffs.length !== 0) {
            dayOffs.forEach(dayOff => {
                let start = moment(dayOff.start).startOf("day")
                let end = moment(dayOff.end).endOf("day")
                if (moment(day).isBetween(start, end, null, "[]")) {
                    result = false
                }
            })
        }
    })

    return result
}

export function ColumnHear(info) {
    const isToday = info.isToday
    // const isWorkTime = isWorkSettingDay(info, workTimeDisplay)
    const zone = getUserTimeZone()

    return `<div class="headerCalendar">
                                <div class="headerDay">${momentTz(info.date).tz(zone).format('ddd')}</div>
                                <div class="headerHtml ${isToday ? "isToday" : ""}" >
                                    ${momentTz(info.date).tz(zone).format('DD')}
                                </div>

                          </div>`


}


