import React, {useEffect, useState} from 'react';
import useIntlId from '../../../hooks/useIntlId';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import InputNumberCustom from '../../Custom/InputNumberCustom';
import TargetHighLight from '../AnnouncementBar/TargetHighLight';
import {makeStyles} from '@material-ui/core/styles';
import getTarget from '../../../common/getTarget';
import {Switch} from 'antd';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';
import {cloneDeep} from 'lodash';

function ListTargetWeeks({handleCheckedHighLight}) {
    const classes = useStyles()
    const {bar} = useSnapshot(announcementProxy)
    const {user} = useSnapshot(userProxy)
    const [
        weekTargetIntl,
        textWeek,
        weeksAgoIntl,
        weeksAfterIntl,
        rotationIntl
    ]
        = useIntlId(
        [
            'settings.announcement.targetThisweek',
            'settings.announcement.targetThisweekText',
            'app.viewSetting.weeksAgo',
            'app.viewSetting.weeksAfter',
            'calenview.settingHighlight.rotation'
        ])

    const [weeksAgo, setWeeksAgo] = useState(bar?.weeksAgo || 0)
    const [weeksLater, setWeeksLater] = useState(bar?.weeksLater || 0)
    const [weekRotation, setWeekRotation] = useState(bar?.weekRotation !== false)
    const [weeksCheck, setWeekCheck] = useState(bar?.weeksCheck || [true])

    useEffect(() => {
        setWeeksAgo(bar?.weeksAgo || 0)
        setWeeksLater(bar?.weeksLater || 0)
        setWeekRotation(bar?.weekRotation !== false)
        setWeekCheck(bar?.weeksCheck || [true])
    }, [bar?.weeksAgo, bar?.weeksLater, bar?.weeksCheck])

    const handleChangeRotation = async () => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                dataList: [],
                weekRotation: !weekRotation,
            }, {merge: true})
            await batch.commit()
            setWeekRotation(!weekRotation);
        } catch (error) {
            console.log(error);
        }
    }


    const updateAgo = async (num) => {
        try {
            let newValue = weeksAgo + num > 5 ? weeksAgo : weeksAgo + num
            let newWeeksCheck = cloneDeep(weeksCheck);
            setWeeksAgo(newValue)
            if (num === -1) {
                if (weeksAgo + num >= 0)
                    newWeeksCheck.shift()
            } else {
                if (weeksAgo + num <= 5)
                    newWeeksCheck.unshift(false)
            }
            setWeekCheck([...newWeeksCheck])
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                weeksCheck: newWeeksCheck,
                weeksAgo: newValue
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    const updateLater = async (num) => {
        try {
            let newValue = weeksLater + num > 5 ? weeksLater : weeksLater + num

            let newWeeksCheck = cloneDeep(weeksCheck);
            console.log({newWeeksCheck});

            if (num === -1) {
                if (weeksLater + num >= 0)
                    newWeeksCheck.pop()
            } else {
                if (weeksLater + num <= 5)
                    newWeeksCheck.push(false)
            }
            setWeeksLater(newValue)
            setWeekCheck([...newWeeksCheck])
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                weeksCheck: newWeeksCheck,
                weeksLater: newValue
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleTarget}>{weekTargetIntl}</div>
                <div className={classes.selectDay}>
                    <InputNumberCustom
                        size={23} width={200} value={weeksAgo} steps={1} min={0} label={weeksAgoIntl}
                        onChange={(va, type) => {
                            if (type === "increment") {
                                return updateAgo(1)
                            }
                            return updateAgo(-1)
                        }}
                    />
                    <InputNumberCustom
                        size={23} width={200} value={weeksLater} steps={1} min={0} label={weeksAfterIntl}
                        onChange={(va, type) => {
                            if (type === "increment") {
                                return updateLater(1)
                            }
                            return updateLater(-1)
                        }}
                    />
                </div>
            </div>
            {
                getTarget(weeksAgo, weeksLater, 'week').map((item, index) => {
                    return (
                        <TargetHighLight
                            index={index}
                            value={item}
                            key={item}
                            fieldName={'Week'}
                            handleCheckedHighLight={handleCheckedHighLight}
                            checked={weeksCheck[index]}
                            checkName={'week'}
                            Intl={textWeek}
                            fieldFocusHighLight={'weeksTarget'}
                        />
                    )
                })
            }
            {weeksCheck.filter(item => item === true).length > 1 && (
                <div className={classes.wrapRotation}>
                    <div className={classes.contentRotation}>
                        <div className={classes.showText}><span>{rotationIntl}</span></div>
                        <Switch
                            className={classes.iconChecked}
                            checked={weekRotation}
                            onChange={handleChangeRotation}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListTargetWeeks;
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 20,
        borderBottom: "solid 2px #F1F1F1",
        paddingBottom: 10,
    },
    titleTarget: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.props.primaryColor
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 5
    },
    selectDay: {
        display: "flex",
        alignItems: "center",
        "& > div:nth-child(1)": {
            marginRight: 10
        }
    },
    wrapRotation: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end',
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    contentRotation: {
        background: '#f1f1f1',
        display: 'flex',
        padding: '5px 10px',
        borderRadius: 30,

    },
    showText: {
        marginRight: 10,
        fontWeight: 600,
        fontSize: 12,
        display: 'flex',
        alignItems: "center"
    },
    iconChecked: {
        display: "flex",
        alignItems: "center",
    },
}))
