export let LOGIN_URL = process.env.REACT_APP_LOGIN_URL
export let IS_PRODUCTION = process.env.REACT_APP_ENV === 'production'

export const SUBDOMAIN_APPOINTMENT = IS_PRODUCTION ? 'appy' : 'appy-stg';
export const MAIL_TEMPLATES_SEND_MAIL_DELETE_EVENT_VOTE_DOC = IS_PRODUCTION ? 'sendMailDeleteEventVote' : 'sendMailDeleteEventVote-stg'

export let API_GENIAM = process.env.REACT_APP_API_GENIAM
export const GOOGLE_REFRESH_TOKEN = API_GENIAM + '/googleApi/refreshToken'
export let API_ZOOM = process.env.REACT_APP_API_ZOOM
export let API_CALENDAR_LIST = 'https://www.googleapis.com/calendar/v3/users/me/calendarList'
export let CREATE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/createTask';
export let DELETE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/deleteTask';

export let CALENDAR_COLLECTION = IS_PRODUCTION ? "calendar" : "calendar-stg"
export let ZOOM_COLLECTION = IS_PRODUCTION ? "metamors" : "metamors-stg"

export let GOOGLE_EMAIL_WATCH = "googleEmailWatch"
export let MEETING_COLLECTION = IS_PRODUCTION ? "meetings" : "meetings-stg"
export let IDENTITY_COLLECTION = IS_PRODUCTION ? "identities" : "identities-stg"
export let RECOMMEND_COLLECTION = IS_PRODUCTION ? "recommend" : "recommend-stg"
export let SERVICE_COLLECTION = IS_PRODUCTION ? "serviceguide" : "serviceguide-stg"
export const USER_COLL = IS_PRODUCTION ? "users" : "users-stg"
export const SUBSCRIPTION_COLL = 'subscriptions'
export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const ID_TOKEN_KEY = 'idToken';


export const USER_INFO_KEY = 'userInfo';
export const REDIRECT_URL_KEY = 'service_redirect_url';

export const LOGOUT_URL = API_GENIAM + "/logout";

export const APPOINTMENT_URL = process.env.REACT_APP_APPOINTMENT_URL
export let GET_USER_INFO = API_GENIAM + '/getuserdata'


export const CALENDAR_API_KEY = "AIzaSyB1nGsOlnZRfv5YPnVUPS_icNqgJ_Vk3xE"
export const COGNITO_LOGOUT = IS_PRODUCTION ? 'https://geniam.auth.ap-northeast-1.amazoncognito.com/logout' : 'https://geniam-dev.auth.ap-northeast-1.amazoncognito.com/logout'
export const COGNITO_CLIENT_ID = IS_PRODUCTION ? '4uak2jlcf653p4rh7hhftpp46a' : '5ihn71m5hb8jsh88kvmbbeejvo'
export const CREATED_ACTION = "CREATED"
export const UPDATED_ACTION = "UPDATED"
export const DELETED_ACTION = "DELETED"
export const CHANGE_TIME_ACTION = "CHANGE_TIME"

export const COPYING_EVENT_KEY = 'copyingEvent'
export const STRIPE_PUBLISH_KEY = IS_PRODUCTION ? 'pk_live_ndRdZMAryciJI126GNjn2wuE' : 'pk_test_P3e8GwkyphGqmyIk5dZgPp6J'

export const HX_CLOUD_DOMAIN = IS_PRODUCTION ? 'https://hx-cloud.geniam.com' : 'https://hx-cloud-stg.geniam.com'
export const APPOINTMENT_DOMAIN = IS_PRODUCTION ? 'https://appy.geniam.com' : 'https://appy-stg.geniam.com'
export const MILESTONE_DOMAIN = IS_PRODUCTION ? "https://milestone.geniam.com/" : "https://milestone-stg.geniam.com/"

// for zoom redirect uri - in app setting
export const ZOOM_REDIRECT_OAUTH = IS_PRODUCTION ? "https://calendar.geniam.com" : "https://calendar-stg.geniam.com"

export const GLOBAL_NAVI_APPS_COLL = IS_PRODUCTION ? "GlobalNavigation" : "GlobalNavigation-stg"
export const APP_GLOBAL_ID = "calenview"
export const SUBSCRIPTION_SERVICES = IS_PRODUCTION ? "https://myaccount.geniam.com/account/subscription" : "https://myaccount-stg.geniam.com/account/subscription"
export const METAMORS_REDIRECT = IS_PRODUCTION ? 'https://metamors.geniam.com/events' : 'https://metamors-stg.geniam.com/events'
