import {proxy} from 'valtio/vanilla';
import axios from 'axios';
import {API_GENIAM} from '../../config/constants';
import {uniq} from 'lodash';
import {getHxPlan} from '../../common/hxPlan/getHxPlan';
import getCalendarPlan, {plans} from '../../common/getCalendarPlan';
import {barDefault, boardDefault} from '../../common/initAnnouncement';
import {barRef, boardRef} from '../../common/firebaseRef/annoucement';

const announcementProxy = proxy({
    data: [],
    loaded: false,
    lock: false,
    bar: barDefault,
    board: boardDefault,
    plan: plans[0],
    getSubs: async () => {
        try {
            const {data} = await axios.get(`${API_GENIAM}/subscriptions/list`)
            if (data?.data?.length) {
                announcementProxy.lock = false;
                const prices = uniq(data.data.map(item => item?.plan?.id || '').filter(x => x))
                announcementProxy.data = data.data;
                const {type} = getHxPlan(data.data)
                if (type === 'year') {
                    announcementProxy.plan = {...plans[3], hxPlan: true, type};
                    return;
                }
                announcementProxy.plan = getCalendarPlan(prices)
            } else {
                announcementProxy.lock = true;
                announcementProxy.plan = getCalendarPlan([])
            }
        } catch (e) {
            console.log(e);
            announcementProxy.lock = true;
            announcementProxy.plan = getCalendarPlan([])
        } finally {
            announcementProxy.loaded = true;
        }
    },
    setBarAndBoard: async (user_id) => {
        if (!user_id) return;
        await announcementProxy.getSubs();

        const barSub = barRef(user_id)
            .onSnapshot(snapshot => {
                if (!snapshot.exists) return;

                const barData = snapshot.data();
                if (!barData.highlight) {
                    barData.highlight = barDefault.highlight
                }
                if (barData.geniamNews === undefined) {
                    barData.geniamNews = true
                }
                announcementProxy.bar = barData;
            });

        const boardSub = boardRef(user_id)
            .onSnapshot(snapshot => {
                if (!snapshot.exists) return;

                announcementProxy.board = snapshot.data();
            });

        return () => {
            if (barSub) barSub()
            if (boardSub) boardSub()
        }
    }
})

export {
    announcementProxy
}
