import moment from 'moment'
import {useEffect, useState} from "react";

export default function useDate() {
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [nextDateTimeOut, setNextDateTimeOut] = useState(moment(moment().add(1, "day").startOf('day')).diff(moment()))

    useEffect(() => {
        const timer = setTimeout(() => {
            setNextDateTimeOut(moment(moment().add(1, 'day')).startOf("day").diff(moment()))
            setDate(moment().format('YYYY-MM-DD'))
        }, nextDateTimeOut);
        return () => {
            if (timer)
                clearTimeout(timer)
        }
    }, [nextDateTimeOut])

    return [date]

}

