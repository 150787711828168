import React, {useEffect, useState} from 'react';
import {remove} from 'lodash'
import {ReactComponent as ArrowDownBlueSVG} from "../../../asSvg/icons/arrowDownBlue.svg";
import {makeStyles} from "@material-ui/core/styles";

const listTime = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]


function ThickLineItem(props) {
    const {index, data, setData, classes} = props
    const [value, setValue] = useState('')
    const [timeAble, setTimeAble] = useState(listTime)
    const cls = useStyles()

    useEffect(() => {
        setValue(data[index])
        ListOfTime(data)
        // eslint-disable-next-line
    }, [data])

    const ListOfTime = (thickLine) => {
        let newTimeAble = [...listTime]
        thickLine.forEach((line, i) => {
            if (line && i !== index) {
                let selectHour = line
                remove(newTimeAble, time => {
                    return ((time > selectHour - 4) && (time < selectHour + 4))
                })
            }
        })
        setTimeAble([...newTimeAble])
    }
    const handleChange = e => {
        let dataNew = [...data]
        if (e.target.value === "") {
            dataNew[index] = null
        } else {
            dataNew[index] = Number(e.target.value)
        }
        setData([...dataNew])
    }
    return (
        <div className={classes.selectTime}>
            <ArrowDownBlueSVG className={`${classes.iconSelect} ${cls.iconArrowDownBlueSVG}`}/>
            <select className={classes.select}
                    onChange={handleChange}
            >
                <option value="">None</option>
                {
                    timeAble.map((time, idx) => {
                        return (
                            <option key={time} value={time}
                                    selected={time === value && "selected"}>{`${time}:00`}
                            </option>
                        )
                    })
                }
            </select>
        </div>

    );
}

export default ThickLineItem

const useStyles = makeStyles(theme => ({
    iconArrowDownBlueSVG: {
        fill: theme.props.primaryColor
    }
}))
