import React from 'react';
import {setGlobal, useGlobal} from 'reactn';
import {Drawer} from 'antd';
import styled from 'styled-components';
import useIntlId from '../../hooks/useIntlId';
import {IS_PRODUCTION} from '../../config/constants';
import PrimaryButton from '../Dialog/PrimaryButton';
import ListCustom from '../Custom/ListCustom';
import {useTutorial} from '../../context/TutorialContext';
import {makeStyles} from '@material-ui/core/styles';

const DrawerCustom = styled(Drawer)`
    & .ant-drawer-content-wrapper {
        min-width: 350px !important;
    }

    & .ant-drawer-header {
        //margin: auto;
        font-weight: bold;
        text-transform: uppercase;
    }

    & .ant-drawer-mask {
        background-color: transparent;
    }

    & .ant-drawer-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;;
    }

    & .ant-menu-title-content {
        color: #1790FF;
    }

    & .ant-drawer-title {
        font-weight: bold;
    }
`;


function HelperTipsDrawer() {
    const [visible, setVisible] = useGlobal('visible')
    const [tipTitleIntl, btnReportBugs, btnContact] = useIntlId(['tips.tipsOfCalendar', 'tips.btnReportBugs', 'tips.btnContact'])
    const {onPlay} = useTutorial()
    const classes = useStyles()
    const onClose = () => {
        if (setVisible)
            setVisible(false)
    }
    const handleClickTip = (id, index = 0) => {
        setVisible(false)
        setGlobal({
            leftExpansion: true,
            rightExpansion: false
        }, () => {
            setTimeout(() => {
                if (id === 'tutorial_main_1') {
                    setVisible(false)
                    setGlobal({openTutorial: true})
                    return
                }
                onPlay(id, index)
            }, 500)
        })

    }

    const onReport = () => {
        window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account/inquiry?bug-or-improvement-plan`, '_blank')
    }

    const onContact = () => {
        window.open(`https://${IS_PRODUCTION ? 'myaccount' : 'myaccount-stg'}.geniam.com/account/inquiry`, '_blank')
    }

    if (!visible)
        return null;
    return (
        <DrawerCustom
            title={tipTitleIntl}
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
        >
            <ListCustom onClickItem={handleClickTip}/>
            <div className={classes.btnGroup}>
                <PrimaryButton
                    block
                    name={btnReportBugs}
                    onClick={onReport}
                />
                <PrimaryButton
                    block
                    name={btnContact}
                    onClick={onContact}
                />
            </div>
        </DrawerCustom>
    );
}

export default React.memo(HelperTipsDrawer)
const useStyles = makeStyles(theme => ({
    btnGroup: {
        padding: 10,
        '& button': {
            margin: '5px 0px !important'
        }
    }
}))
