// =============================================================================
// i18n
// =============================================================================
import {fromPairs, zipObject} from 'lodash';
import moment from 'moment';

const shortDayNames = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
const FREQmap = zipObject(
    ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY'],
    ['Hàng năm', 'Hàng tháng', 'Hàng tuần', 'Hàng ngày']
)
const BYDAYmap = zipObject(
    ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
    shortDayNames
)

const rruleVIVN = {
    dayNames: [
        'Chủ Nhật', ...[2, 3, 4, 5, 6, 7].map(n => `Thứ ${n}`)
    ],
    monthNames: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(n => `Tháng ${n}`),
    tokens: {
        'SKIP': /^[ \r\n\t]+|^\.$/,
        'number': /^[1-9][0-9]*/,
        'numberAsText': '(một|hai|ba)',
        'every': 'mỗi',
        'day(s)': 'ngày',
        'weekday(s)': 'thứ',
        'week(s)': 'tuần',
        'hour(s)': 'giờ',
        'minute(s)': 'phút',
        'month(s)': 'tháng',
        'year(s)': 'năm',
        'week': 'tuần',
        'month': 'tháng',
        'year': 'năm',
        'on': '(vào)',
        'at': '(lúc)',
        'the': '',
        'first': 'thứ nhất',
        'second': 'thứ hai',
        'third': 'thứ ba',
        'nth': '([1-9][0-9]*)(\.|th|nd|rd|st)',
        'last': 'cuối',
        'for': 'cho',
        'time(s)': 'lần',
        'until': 'đến khi',
        'monday': 't2',
        'tuesday': 't3',
        'wednesday': 't4',
        'thursday': 't5',
        'friday': 't6',
        'saturday': 't7',
        'sunday': 'cn',
        'january': 'thg1',
        'february': 'thg2',
        'march': 'thg3',
        'april': 'thg4',
        'may': 'thg5',
        'june': 'thg6',
        'july': 'thg7',
        'august': 'thg8',
        'september': 'thg9',
        'october': 'thg10',
        'november': 'thg11',
        'december': 'thg12',
        'comma': '(,\s*|(and|or)\s*)+'
    },
}

export const parseRRuleToText = (rrule) => {
    if (!rrule) return 'Không lặp lại';
    const ruleObj = parseRRuleToObj(rrule)
    if (!ruleObj) return '';
    const {FREQ, INTERVAL, BYDAY, BYMONTH, BYMONTHDAY, UNTIL, COUNT} = ruleObj
    return parseFREQ(FREQ, INTERVAL) + parseBYDAY(BYDAY, FREQ) + parseBYMONTH(BYMONTH, BYMONTHDAY) + parseUNTIL(UNTIL) + parseCOUNT(COUNT);
}
export const parseRRuleToObj = (rule) => {
    if (!rule) return null;
    return fromPairs(
        rule.replace('RRULE:', '').split(';').map(r => r.split('='))
    )
}
const parseFREQ = (FREQ, INTERVAL) => {
    const str = FREQmap[FREQ]
    if (!INTERVAL) return str;
    const arr = str.split(' ')
    arr.splice(1, 0, INTERVAL)
    return arr.join(' ')
}
const parseBYDAY = (BYDAY, FREQ = 'MONTHLY') => {
    if (!BYDAY) return '';
    const days = /\D+/g.exec(BYDAY)?.[0] // ex: SU or MO,WE,TH
    const lanThu = /\d+/g.exec(BYDAY)?.[0] // ex: 3

    let dayStr = days.split(',').map(d => BYDAYmap[d]).join(',')//ex: T2,T3
    if (lanThu) {
        switch (FREQ) {
            case 'MONTHLY':
                dayStr += ', tuần thứ ' + lanThu
                break;
            case 'YEARLY':
                dayStr += ', tháng thứ ' + lanThu
                break;
            default:
                break;
        }
    }

    return ' ' + dayStr;
}
const parseBYMONTH = (BYMONTH, BYMONTHDAY) => {
    if (!BYMONTH) return ''
    return ` ngày ${BYMONTHDAY}/${BYMONTH}`//ex: 20/10
}
const parseUNTIL = UNTIL => {
    if (!UNTIL) return ''
    return ' đến ' + moment(UNTIL).format('DD/MM/YYYY')
}
const parseCOUNT = COUNT => {
    if (!COUNT) return ''
    return ', ' + COUNT + ' lần'
}

export default rruleVIVN
