import {IS_PRODUCTION} from '../config/constants';
import  {getIntlId} from '../hooks/useIntlId';
export const calenviewProd = () => {
    return (
        IS_PRODUCTION ?
            [
                {
                    name: getIntlId('setting.plan.free'),
                    id: null,
                    price: {
                        month: null,
                        year: null
                    },
                    amount: {
                        month: 0,
                        year: 0,
                    },
                    Cal: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '3')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '1')}`,
                    future: `${getIntlId("setting.planFree.syncGoogle")} <br/> ${getIntlId("setting.planFree.syncZoom")} <br/> ${getIntlId("setting.planFree.statisticalChart")}`,
                    hiddenNews: null,
                    color: "#15CA48",
                    backgroundColor: "linear-gradient(91.53deg, #13F47B -0.82%, #15CA48 99.49%)",
                    serviceId: 0,
                    milestone: false,
                    focus: false,
                },
                {
                    name: getIntlId("setting.plan.basic"),
                    id: "prod_KeZS9J1C9UrBUG",
                    price: {
                        month: "price_1KGGCHKtz9s4YUGtMf2JLvay",
                        year: "price_1KAWUqKtz9s4YUGt6st7inzv"
                    },
                    amount: {
                        month: 98,
                        year: 980,
                    },
                    Cal: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '8')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '4')}`,
                    future: `${getIntlId("setting.planBasic.advancedView")} <br/> ${getIntlId("setting.planFree.syncZoom")} <br/> ${getIntlId("settings.announcementBoard")}
                    <br/> ${getIntlId("setting.listTab.remindBar")}`,
                    hiddenNews: getIntlId("setting.plan.chooseHideNews"),
                    color: "#15B6E9",
                    backgroundColor: "linear-gradient(91.53deg, #17D5FF -0.82%, #15B6E9 99.49%)",
                    serviceId: 1,
                    milestone: false,
                    focus: false,
                },
                {
                    name: getIntlId("setting.plan.standard"),
                    id: "prod_Kw8RutL1O2Xrhy",
                    price: {
                        month: "price_1KGGATKtz9s4YUGt6ZYEffvl",
                        year: "price_1KGGATKtz9s4YUGtOd6fK20D"
                    },
                    amount: {
                        month: 198,
                        year: 1980,
                    },
                    Cal: `${`${getIntlId('setting.plan.maximum')}`.replace('~', ' 16')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '8')}`,
                    future: `${getIntlId("setting.plan.manageOutput")} <br/> ${getIntlId("setting.plan.manageMilestones")} <br/> ${getIntlId("setting.plan.manageFocus")}`,
                    hiddenNews: getIntlId("setting.plan.chooseHideNews"),
                    color: "#9F2AFB",
                    backgroundColor: "linear-gradient(91.53deg, #9F2AFB -0.82%, #DF2D6D 99.49%)",
                    serviceId: 2,
                    milestone: true,
                    focus: true,
                },
                {
                    name: getIntlId("setting.plan.premium"),
                    id: "prod_Kw8RGyf8YJD1Xa",
                    price: {
                        month: "price_1KGGAdKtz9s4YUGtW4Uq6IJt",
                        year: "price_1KGGAdKtz9s4YUGtTgBTALTc"
                    },
                    amount: {
                        month: 398,
                        year: 3980,
                    },
                    Cal: `${`${getIntlId('setting.plan.maximum')}`.replace('~', ' 50')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '8')}`,
                    future: `${getIntlId("setting.plan.manageOutput")} <br/> ${getIntlId("setting.plan.manageMilestones")} <br/> ${getIntlId("setting.plan.manageFocus")}`,
                    hiddenNews: getIntlId("setting.plan.chooseHideNews"),
                    color: "#F29912",
                    backgroundColor: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%), linear-gradient(91.53deg, #17D5FF -0.82%, #15B6E9 99.49%)",
                    serviceId: 3,
                    milestone: true,
                    focus: true,
                }
            ]
            :
            [
                {
                    name: getIntlId("setting.plan.free"),
                    id: null,
                    price: {
                        month: null,
                        year: null
                    },
                    amount: {
                        month: 0,
                        year: 0,
                    },
                    Cal: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '3')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '1')}`,
                    future: `${getIntlId("setting.planFree.syncGoogle")} <br/> ${getIntlId("setting.planFree.syncZoom")} <br/> ${getIntlId("setting.planFree.statisticalChart")}`,
                    hiddenNews: null,
                    color: "#15CA48",
                    backgroundColor: "linear-gradient(91.53deg, #13F47B -0.82%, #15CA48 99.49%)",
                    serviceId: 0,
                    milestone: false,
                    focus: false,
                },
                {
                    name: getIntlId("setting.plan.basic"),
                    id: "prod_Kp1esAcpRs85yb",
                    price: {
                        month: "price_1KAWNBKtz9s4YUGtOoyC0ovi",
                        year: "price_1KAWTSKtz9s4YUGtR8ZZpI7q"
                    },
                    amount: {
                        month: 98,
                        year: 980,
                    },
                    Cal: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '8')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '4')}`,
                    future: `${getIntlId("setting.planBasic.advancedView")} <br/> ${getIntlId("setting.planFree.syncZoom")} <br/> ${getIntlId("settings.announcementBoard")}
                    <br/> ${getIntlId("setting.listTab.remindBar")}`,
                    hiddenNews: getIntlId("setting.plan.chooseHideNews"),
                    color: "#15B6E9",
                    backgroundColor: "linear-gradient(91.53deg, #17D5FF -0.82%, #15B6E9 99.49%)",
                    serviceId: 1,
                    milestone: false,
                    focus: false,
                },
                {
                    name: getIntlId("setting.plan.standard"),
                    // "スタンダード・<br/>プラン",
                    id: "prod_Kw6BWeMrUCH9qT",
                    price: {
                        month: "price_1KGDz4Ktz9s4YUGtZyxIN3AD",
                        year: "price_1KGDz4Ktz9s4YUGtzPJ8lX5a"
                    },
                    amount: {
                        month: 198,
                        year: 1980,
                    },
                    Cal: `${`${getIntlId('setting.plan.maximum')}`.replace('~', ' 16')}`,
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '8')}`,
                    future: `${getIntlId("setting.plan.manageOutput")} <br/> ${getIntlId("setting.plan.manageMilestones")} <br/> ${getIntlId("setting.plan.manageFocus")}`,
                    // "アウトプット管理<br/>マイルストーン管理<br/>フォーカス期間の管理",
                    hiddenNews: getIntlId("setting.plan.chooseHideNews"),
                    color: "#9F2AFB",
                    backgroundColor: "linear-gradient(91.53deg, #9F2AFB -0.82%, #DF2D6D 99.49%)",
                    serviceId: 2,
                    milestone: true,
                    focus: true,
                },
                {
                    name: getIntlId("setting.plan.premium"),
                    id: "prod_Kw6DQUu6YLu593",
                    price: {
                        month: "price_1KGE16Ktz9s4YUGtbgmi8lQd",
                        year: "price_1KGE16Ktz9s4YUGtSTGUQHZJ"
                    },
                    amount: {
                        month: 398,
                        year: 3980,
                    },
                    Cal: `${`${getIntlId('setting.plan.maximum')}`.replace('~', ' 50')}`,
                    // Cal: getIntlId(`${getIntlId("setting.plan.maximum")} ${getIntlId("setting.plan.quantum")}`),
                    // "最大5０個",
                    view: `${`${getIntlId('setting.plan.quantity')}`.replace('~', '8')}`,
                    future: `${getIntlId("setting.plan.manageOutput")} <br/> ${getIntlId("setting.plan.manageMilestones")} <br/> ${getIntlId("setting.plan.manageFocus")}`,
                    hiddenNews: getIntlId("setting.plan.chooseHideNews"),
                    color: "#F29912",
                    backgroundColor: "linear-gradient(91.53deg, #F29912 -0.82%, #FFD600 99.49%), linear-gradient(91.53deg, #17D5FF -0.82%, #15B6E9 99.49%)",
                    serviceId: 3,
                    milestone: true,
                    focus: true,
                }
            ]
    )
}
export const oldPlan = IS_PRODUCTION ? ['price_1JzGJvKtz9s4YUGtGUZ3KmgQ', 'price_1KAW5UKtz9s4YUGtUJEDNtu5'] : ['price_1Jz0iEKtz9s4YUGtpdTbCTQM']
