import './firebaseConfig';
import './globalState';
import GoogleAuth from './auth';
import axiosConfig from './axios';

export default () => {
    GoogleAuth()
    axiosConfig()
    // addTailwindScriptForDevelopment()
}
