import {db} from '../../config/firebaseConfig'
import {CALENDAR_COLLECTION} from "../../config/constants";
import {getGlobal} from 'reactn'
import {cloneDeep, filter, findIndex} from "lodash";
import {snapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';

export const getGeniamEvents = (callback) => {
    const {user} = snapshot(userProxy)
    if (!user.user_id)
        return null
    return db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
        .where("isDeleted", "==", false)
        .where("isTasks", "==", false)
        .onSnapshot((snapshot) => {
            if (typeof callback === "function")
                callback(snapshot)
        })
}

export const formatGeniamEvents = (event) => {
    const {GoogleColor} = getGlobal();
    const {geniamProjects} = snapshot(projectsProxy)
    const {calendarUser} = snapshot(userProxy)
    let projects = cloneDeep(geniamProjects)
    let {identitySettings = []} = calendarUser;

    let proIdx = findIndex(projects, {uuid: event.project_uuid})
    if (proIdx !== -1)
        event.borderColor = projects[proIdx].color
    if (event?.colorId) {
        if (!identitySettings?.length)
            identitySettings = GoogleColor.map((color, index) => {
                return {
                    id: index + 1,
                    color,
                    name: "",
                    isArchive: false,
                    isDeleted: false
                }
            })
        let colorIdx = findIndex(filter(identitySettings, i => !i.isDeleted && !i.isArchive), {id: event.colorId});
        if (colorIdx !== -1)
            event.backgroundColor = identitySettings[colorIdx].color
    }
    return {...event}
}
