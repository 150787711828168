import {db} from "../../../config/firebaseConfig";
import {API_GENIAM, CALENDAR_COLLECTION} from "../../../config/constants";
import axios from 'axios'
import {snapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {setGlobal} from 'reactn';

export default async (code, profile) => {
    try {
        const {data} = await axios.get(`${API_GENIAM}/googleApi/getRefreshToken?code=${code}&redirectUri=${window.location.origin}`)
        const {user, setGoogleAuth} = snapshot(userProxy)
        const ggAuth = {
            ...profile,
            refreshToken: data.refresh_token
        }
        await db.collection(CALENDAR_COLLECTION).doc(user.user_id)
            .set({
                googleAuth: ggAuth
            }, {merge: true})
        setGoogleAuth(ggAuth)
    } catch (e) {
        console.log(e);
    }
}
