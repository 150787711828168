import isEqual from 'react-fast-compare';

export const mergeProxy = (proxy, data) => {
    // proxy null || data null || data & proxy not same type || !object
    if (!proxy || !data || typeof data !== "object" || typeof proxy !== "object") {
        proxy = data
        return;
    }
    // empty data
    if (!Object.entries(data).length) {
        proxy = data
        return;
    }
    // remove key not exists in new data
    Object.keys(proxy).forEach(key => {
        if (!data.hasOwnProperty(key)) {
            delete proxy[key]
        }
    })
    // merge new key data
    Object.entries(data).forEach(([key, value]) => {
        if (!isEqual(proxy[key], value)) {
            if (proxy[key] && value && typeof proxy[key] === "object") {
                if (Array.isArray(value)) {
                    proxy[key] = value
                    return;
                }
                mergeProxy(proxy[key], value)
            } else {
                proxy[key] = value
            }
        }
    })
}
