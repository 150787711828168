import {v4 as uuid} from 'uuid';
import moment from 'moment';
import {getCalOfTime} from '../components/Calendar/handlers/getDate';
import {setGlobal} from 'reactn'

export const openCreateEvent = () => {
    const event = {
        id: 'evt_' + uuid(),
        allDay: false,
        start: new Date(moment().startOf('hour').add(1, "hour").format()),
        end: new Date(moment().startOf('hour').add(2, "hour").format()),
        jsEvent: null,
        view: null,
        startStr: moment().startOf('hour').add(1, "hour").format(),
        endStr: moment().startOf('hour').add(2, "hour").format(),
        isCreateButton: true,
    }
    const numCal = getCalOfTime()
    setGlobal({
        clickInfo: {...event, numCal}
    })
}
