import React, {useEffect} from 'react';
import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import IconIdentity from "../google/identityIcon";
import {Settings} from "@material-ui/icons";
// import {filter} from "lodash";
import useIntlId from "../../../hooks/useIntlId";
import {publicIcon} from '../handlers/infoEvent';
import {Button} from 'antd';
import {useSnapshot} from 'valtio';
import {settingDialogProxy} from '../../../store/proxy/settingDialog.proxy';
import {userProxy} from '../../../store/proxy/user.proxy';
import {cloneDeep} from 'lodash';


const styles = () => ({
    btn: {
        backgroundColor: 'white',
        border: "none",
        boxSizing: "border-box",
        margin: 6,
        padding: "3px 7px 0px 7px",
        '&:hover': {
            cursor: 'pointer'
        },
        width: 36
    },
    root: {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column"
    },
    disable: {
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: "none",
    },
    title: {
        fontWeight: "bold", fontSize: 14
    },
    buttonIcon2: {
        margin: "5px 0px 5px 5px",
        border: "none !important",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff !important",
        boxShadow: "none",
        "& img": {
            // width: "100%",
            height: "100%",
            width: "18px"
        }
    },
    wrapIdentity: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    btnSetting: {
        marginLeft: 11,
        marginBottom: 5,
    },
})
const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

function IdentityList(props) {
    const {
        classes,
        event,
        setEvent,
        create = false,
        // onClose = null
    } = props
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {calendarUser} = useSnapshot(userProxy)
    const {identitySettings} = calendarUser
    const [tagIdentity] = useIntlId(['app.profile.identities'])
    useEffect(() => {
        if (!identitySettings?.length || event.borderColor === event.backgroundColor)
            return;

        const colors = identitySettings.map(item => item.color)
        if (!colors.includes(event.backgroundColor)) {
            setEvent({
                ...event,
                backgroundColor: event.borderColor,
                colorId: null
            })
        }
        // eslint-disable-next-line
    }, [identitySettings, event])


    const onChoose = (item) => {
        let _event = cloneDeep(event)
        if (Boolean(_event?.isConnectMetamors)) return;
        if (_event?.colorId !== item.id) {
            setEvent({
                ..._event,
                colorId: item.id,
                backgroundColor: item.color
            })
        } else {
            setEvent({
                ..._event,
                colorId: '',
                backgroundColor: ''
            })
        }
    }
    if (!event || !identitySettings?.length)
        return null;

    return (
        <div className={`${classes.root} ${Boolean(event?.isConnectMetamors) && classes.disable}`}>
            <div className={classes.title}>{tagIdentity}</div>
            <div className={classes.wrapIdentity}>
                <div>
                    {identitySettings.map((item, index) => {
                        if (item.id > 11 || !item.name || item.isArchive) return null;

                        return (
                            <LightTooltip title={item.name} placement={"top"} key={item.id}>
                                <button
                                    className={classes.btn}
                                    onClick={() => {
                                        if (event.is_holiday)
                                            return
                                        onChoose(item)
                                    }}
                                    key={index}
                                    style={(((parseInt(event.colorId) === item.id || event?.backgroundColor === item.color))) ?
                                        {
                                            border: `solid 1px ${item.color}`
                                        }
                                        :
                                        {}}
                                >
                                    <IconIdentity
                                        color={item.color}
                                    />
                                </button>
                            </LightTooltip>
                        )
                    })}
                </div>
                <div className={classes.wrapIdentity}>
                    <Button
                        className={classes.buttonIcon2}
                        onClick={() => {
                            if (event.is_holiday)
                                return
                            setEvent({
                                ...event,
                                colorId: '',
                                backgroundColor: ''
                            })
                        }}
                    >
                        <img src={publicIcon('backspace.svg')} alt=""/>
                    </Button>
                    <div>
                        <IconButton className={classes.btnSetting} disableRipple={true} onClick={() => {
                            handleOpen(5)
                        }}>
                            <Settings/>
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(IdentityList)
