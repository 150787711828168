import {proxy} from 'valtio/vanilla';
import moment from 'moment';
import store from 'store';
import {snapshot} from 'valtio';
import {eventsProxy} from './events.proxy';
import {projectsProxy} from './projects.proxy';
import {getGoogleEventsAllTime} from '../../context/common/getGoogleEvents';
import {concat, orderBy} from 'lodash';
import {nonAccentVietnamese} from './common/characterProxy';
import {userProxy} from './user.proxy';

const searchProxy = proxy({
    listSearch: [],
    searching: false,
    openSearch: false,
    searchKey: "",
    timeSearch: {
        start: moment(moment().subtract(30, 'days').format("YYYY/MM/DD")),
        end: moment().format("YYYY/MM/DD")
    },
    checkTimeSearch: true,
    searchKeyHistory: store.get('searchKeyHistory') || [],
    eventSearchKey: "",

    setListSearch: (data) => {
        searchProxy.listSearch = data
    },
    setSearching: (data) => {
        searchProxy.searching = data
    },
    setOpenSearch: (data) => {
        searchProxy.openSearch = data
    },
    setSearchKey: (data) => {
        searchProxy.searchKey = data
    },
    setTimeSearch: (data) => {
        searchProxy.timeSearch = data
    },
    setCheckTimeSearch: (data) => {
        searchProxy.checkTimeSearch = data
    },
    searchEvents: async (keySearch, start = null, end = null, checkTimeSearch, googleStatus) => {
        try {
            if (!keySearch) {
                searchProxy.setSearching(false);
                searchProxy.setListSearch([]);
            } else {
                const {geniamEvents} = snapshot(eventsProxy)
                const {allProjects} = snapshot(projectsProxy)
                searchProxy.setSearching(true);
                let geEvents = [];
                let ggEvents = [];
                if (checkTimeSearch) {
                    geEvents = allProjects.filter(pro => !pro.is_deleted && !pro.is_sync).map(project => {
                        return geniamEvents[project.id] || []
                    }).flat()?.filter(e => nonAccentVietnamese(e.title || "")?.toLowerCase()?.toString().includes(keySearch?.toLowerCase().toString()))
                } else {
                    geEvents = allProjects.filter(pro => !pro.is_deleted && !pro.is_sync).map(project => {
                        return geniamEvents[project.id] || []
                    }).flat()?.filter(e => nonAccentVietnamese(e.title || "")?.toLowerCase()?.toString().includes(keySearch?.toLowerCase().toString()) &&
                        moment(e.start).isAfter(start) && moment(e.start).isBefore(end))
                }
                if (googleStatus) {
                    ggEvents = []
                } else {
                    const {events} = await getGoogleEventsAllTime(keySearch, start, end);
                    if (events) {
                        ggEvents = Object.keys(events).map(key => {
                            return events[key]?.events || []
                        }).flat()
                    }
                }
                const result = orderBy(concat(geEvents, ggEvents), item => moment().diff(item?.start))

                searchProxy.setSearching(false)
                searchProxy.setListSearch(result)
            }
        } catch (e) {
            console.log(e)
        }
    },
    reloadEventsInSearchPage: async (location) => {
        const {googleAuth} = snapshot(userProxy)
        if (location && location.pathname === '/search') {
            const keySearch = location.search.split('=')[1]
            if (keySearch !== '') {
                await searchProxy.searchEvents(keySearch, null, null, true, !googleAuth?.email);
            }
        }
    }
});

export {
    searchProxy
}
