import React, {useEffect, useState} from 'react';
import useIntlId from '../../../hooks/useIntlId';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import InputNumberCustom from '../../Custom/InputNumberCustom';
import TargetHighLight from '../AnnouncementBar/TargetHighLight';
import {makeStyles} from '@material-ui/core/styles';
import getTarget from '../../../common/getTarget';
import {Switch} from 'antd';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';
import {cloneDeep} from 'lodash';

function ListTargetMonths({handleCheckedHighLight}) {
    const classes = useStyles()
    const {bar} = useSnapshot(announcementProxy)
    const {user} = useSnapshot(userProxy)
    const [
        monthTargetIntl,
        textMonth,
        monthsAgoIntl,
        monthsAfterIntl,
        rotationIntl,
    ]
        = useIntlId(
        [
            'settings.announcement.targetThismonth',
            'settings.announcement.targetThismonthText',
            'app.viewSetting.monthsAgo',
            'app.viewSetting.monthsAfter',
            'calenview.settingHighlight.rotation'
        ])

    const [monthsAgo, setMonthsAgo] = useState(bar?.monthsAgo || 0)
    const [monthsLater, setMonthsLater] = useState(bar?.monthsLater || 0)
    const [monthRotation, setMonthRotation] = useState(bar?.monthRotation !== false)
    const [monthsCheck, setMonthsCheck] = useState(bar?.monthsCheck || [true])

    useEffect(() => {
        setMonthsAgo(bar?.monthsAgo || 0)
        setMonthsLater(bar?.monthsLater || 0)
        setMonthRotation(bar?.monthRotation !== false)
        setMonthsCheck(bar?.monthsCheck || [true])
    }, [bar?.monthsAgo, bar?.monthsLater, bar?.monthsCheck, bar?.monthRotation])

    const handleChangeRotation = async () => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id);
            batch.set(AnnouncementBar, {
                ...bar,
                dataList: [],
                monthRotation: !monthRotation,
            }, {merge: true})
            await batch.commit()
            setMonthRotation(!monthRotation);
        } catch (error) {
            console.log(error);
        }
    }

    const updateAgo = async (num) => {
        try {
            let newValue = monthsAgo + num > 5 ? monthsAgo : monthsAgo + num
            let newMonthsCheck = cloneDeep(monthsCheck);
            setMonthsAgo(newValue)
            if (num === -1) {
                if (monthsAgo + num >= 0)
                    newMonthsCheck.shift()
            } else {
                if (monthsAgo + num <= 5)
                    newMonthsCheck.unshift(false)
            }
            setMonthsCheck([...newMonthsCheck])
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                monthsCheck: newMonthsCheck,
                monthsAgo: newValue
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    const updateLater = async (num) => {
        try {
            let newValue = monthsLater + num > 5 ? monthsLater : monthsLater + num
            let newMonthsCheck = cloneDeep(monthsCheck);
            if (num === -1) {
                if (monthsLater + num >= 0)
                    newMonthsCheck.pop()
            } else {
                if (monthsLater + num <= 5)
                    newMonthsCheck.push(false)
            }
            setMonthsLater(newValue)
            setMonthsCheck([...newMonthsCheck])
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                monthsCheck: newMonthsCheck,
                monthsLater: newValue
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.titleTarget}>{monthTargetIntl}</div>
                <div className={classes.selectDay}>
                    <InputNumberCustom
                        size={23} width={200} value={monthsAgo} steps={1} min={0} label={monthsAgoIntl}
                        onChange={(va, type) => {
                            if (type === "increment") {
                                return updateAgo(1)
                            }
                            return updateAgo(-1)
                        }}
                    />
                    <InputNumberCustom
                        size={23} width={200} value={monthsLater} steps={1} min={0} label={monthsAfterIntl}
                        onChange={(va, type) => {
                            if (type === "increment") {
                                return updateLater(1)
                            }
                            return updateLater(-1)
                        }}
                    />
                </div>
            </div>
            {
                getTarget(monthsAgo, monthsLater, 'month').map((item, index) => {
                    return (
                        <TargetHighLight
                            index={index}
                            value={item}
                            key={item}
                            fieldName={'Month'}
                            handleCheckedHighLight={handleChangeRotation}
                            showTime={'CustomMonth'}
                            checked={monthsCheck[index]}
                            checkName={'month'}
                            Intl={textMonth}
                            fieldFocusHighLight={'monthTarget'}
                        />
                    )
                })
            }
            {monthsCheck.filter(item => item === true).length > 1 && (
                <div className={classes.wrapRotation}>
                    <div className={classes.contentRotation}>
                        <div className={classes.showText}><span>{rotationIntl}</span></div>
                        <Switch
                            className={classes.iconChecked}
                            checked={monthRotation}
                            onChange={handleChangeRotation}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListTargetMonths;
const useStyles = makeStyles(theme => ({
    root: {
        // marginBottom: 20
    },
    titleTarget: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.props.primaryColor
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 5
    },
    selectDay: {
        display: "flex",
        alignItems: "center",
        "& > div:nth-child(1)": {
            marginRight: 10
        }
    },
    wrapRotation: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end',
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    contentRotation: {
        background: '#f1f1f1',
        display: 'flex',
        padding: '5px 10px',
        borderRadius: 30,

    },
    showText: {
        marginRight: 10,
        fontWeight: 600,
        fontSize: 12,
        display: 'flex',
        alignItems: "center"
    },
    iconChecked: {
        display: "flex",
        alignItems: "center",
    },
}))

