import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {cloneDeep, isEqual, orderBy, sortBy} from 'lodash';
import tinycolor from 'tinycolor2';
import {ReactComponent as IdentityImage} from '../../asSvg/hubman.svg';
import {Tooltip} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import {simpleDuration} from '../Calendar/popup/AllDaySelectComponent';
import {setGlobal, useGlobal} from 'reactn';
import useIntlId from "../../hooks/useIntlId";
import DialogAddLinkProjects from './DialogAddLinkProjects';
import faviconFetch from 'favicon-fetch';
import isURL from 'validator/es/lib/isURL';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {themesProxy} from '../../store/proxy/themes.proxy';
import {settingDialogProxy} from '../../store/proxy/settingDialog.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';

const Box = styled.div(props => ({
    padding: props.padding || 6,
    background: "#f1f1f1",
    width: "100%",
    paddingLeft: 3,
    paddingRight: 3
}))
const Content = styled.div(props => ({
    background: props.background || "#f1f1f1",
    borderRadius: 10,
    padding: props.row ? "0px 10px 0px 10px" : "5px 5px 5px 0",
    display: props.row ? "flex" : "block",
    // overflowX: props.row ? "auto" : "hidden",
    // overflowY: props.row ? "hidden" : "auto",
    overflow: "hidden",
    maxHeight: props.calendarheight ? `calc(${props.calendarheight}px - 240px)` : "unset",
    justifyContent: props.row ? "center" : '',
    // alignItems: props.row ? "center" : '',
    minHeight: props.row ? "85px" : '',
    // "&:hover": {
    //     overflowX: props.row ? "auto" : "hidden",
    //     overflowY: props.row ? "hidden" : "auto",
    // },
    "&::-webkit-scrollbar": {
        width: 9,
        height: 9
    },
    "&::-webkit-scrollbar-track": {
        // background: '#f1f1f1',
        borderRadius: 10
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#ccc",
        borderRadius: 10
    },
}))
const Label = styled.div(props => ({
    paddingLeft: props.paddingLeft || "none",
    minWidth: props.width || 40,
    color: props.color,
    fontWeight: 700,
    fontSize: props.allDay ? 10 : 12,
    whiteSpace: "nowrap",
    margin: !props.row ? "4px 7px 0 6px" : "5px auto",
    position: props.allDay ? "absolute" : "unset",
    borderRadius: 10,
    display: 'flex',
    // background: props.row ? "transparent" : "#fff",
    zIndex: 1
}))

const PerCent = styled.div(props => ({
    width: `${props.percent && !props.allDay ? props.percent + 10 : 100}%`,
    background: props.color,
    borderRadius: 10,
    minWidth: 40,
    height: props.height || 25,
    marginLeft: props.row ? -15 : 0,
    zIndex: props.index || 0,
    borderRight: props.borderRight ? "1px solid #f1f1f1" : "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: props.row ? "flex-start" : '',
    alignItems: props.row ? "center" : '',
    '&:first-child': {
        marginLeft: 0
    }
}))

const Name = styled.div(props => ({
    color: props.color || "#000",
    textAlign: props.position || "left",
    paddingLeft: props.allDay ? 50 : 5,
    whiteSpace: "nowrap",
    fontSize: 10,
    fontWeight: 700,
    lineHeight: "23px",
    overflow: "hidden",
    textOverflow: 'ellipsis'
}))

const ShowIcon = styled.div(props => ({
    background: props.color || "#000",
    width: 4,
    borderRadius: 10,
    marginRight: 10
}))


function LineChart({
                       rowMode = false,
                       data = [],
                       padding,
                       allDay,
                       showName = false,
                       isIdentity = true,
                   }) {
    const classes = useStyles()
    const {calendarUser} = useSnapshot(userProxy)
    const {currentTheme, colors} = useSnapshot(themesProxy)
    const {handleOpen} = useSnapshot(settingDialogProxy)
    const {setProjectSelected} = useSnapshot(projectsProxy);
    const [addName] = useIntlId(['setttings.addLinkProject'])
    const [addPro, setAddPro] = useState(false)
    const [link, setLink] = useState(null)

    const [hiddenList, setHiddenList] = useState([]);
    const [projectsLink, setProjectsLink] = useState({});

    useEffect(() => {
        if (calendarUser?.idCalenderHidden) {
            setHiddenList(cloneDeep(calendarUser?.idCalenderHidden));
        }
        if (calendarUser?.googleSettings) {
            setProjectsLink(cloneDeep(calendarUser?.googleSettings));
        }
    }, [calendarUser]);


    const showTitle = currentTheme?.showTitle ?? true
    let key = 0;

    if (!data || !data.length)
        return null

    const sort = orderBy(data, "hour", 'desc').filter((e) => {
        return !hiddenList.includes(e.id)
    })
    const hours = sort.map(item => item.hour)
    const longestHours = Math.max(...hours)
    const total = sort.reduce((value, item) => {
        return value + Number(item.hour)
    }, 0)

    const handleClick = (item, listOtherURL) => {
        if (!item?.id)
            return
        let newItem = cloneDeep(item);
        if (listOtherURL) {
            newItem.otherURL = listOtherURL.otherURL;
        }
        setProjectSelected(newItem);
        handleOpen(4);
    }

    const handleAddLink = (item) => {
        setAddPro(true)
        setLink(item)
    }

    return (
        <div>
            <Content colors={colors.primary} background={rowMode ? "white" : ''} row={rowMode}>
                {sort.map((item, index) => {
                    let listOtherURL = projectsLink[item.id];
                    let otherURL = [];
                    if (item.is_google) {
                        otherURL = projectsLink[item.id]?.otherURL?.filter((e) => {
                            return e?.url && isURL(`${e.url}`, {
                                protocols: ['http', 'https'],
                                require_protocol: true
                            })
                        }) || [];

                    } else {
                        otherURL = item?.otherURL && (item?.otherURL.filter((e) => {
                            return e?.url && isURL(`${e.url}`, {
                                protocols: ['http', 'https'],
                                require_protocol: true
                            })
                        }) || []);
                    }

                    let isDark = tinycolor(data.color).isDark()
                    const nameColor = isDark ? "#fff" : "#000"
                    if (!rowMode) {
                        const percent = Number(item.hour) / longestHours * 100
                        const percentRate = Number(item.hour) / total * 100
                        const rowPercent = percent.toFixed(1)
                        const rowPercentRate = percentRate.toFixed(1)
                        key++
                        return (
                            <React.Fragment key={key}>
                                <Tooltip placement={"top"} title={item.name} color={item.color} mouseLeaveDelay={0}>
                                    <div className={classes.columnContainer}>
                                        <Label color={item.color} allDay={allDay}>
                                            {allDay ? `${simpleDuration((item.hour / 24).toFixed(1))}d` : ` ${simpleDuration(item.hour?.toFixed(1))}h`}
                                        </Label>
                                        <ShowIcon color={item.color} className={classes.icon}/>
                                        <PerCent percent={(item.hour === longestHours) ? 100 : rowPercent}
                                                 allDay={allDay} color={item.color}
                                                 className={`${classes.perCent}`}>
                                            {
                                                (showName || allDay) ?
                                                    <Name color={nameColor} allDay={allDay}>
                                                        {showTitle && item.name}
                                                    </Name>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                            isIdentity ?
                                                                <IdentityImage className={classes.columnIdentity}/>
                                                                :
                                                                null
                                                        }
                                                    </React.Fragment>
                                            }
                                        </PerCent>
                                        <div className={`${classes.wrapIcon} ${classes.icon}`}>
                                            {
                                                otherURL.length > 0 ?
                                                    otherURL.map((e, idx) => {
                                                        key++
                                                        return <div className={"text-left"} key={key}>
                                                            <a href={`${e.url}`}
                                                               className={classes.aTag}
                                                               rel={'noreferrer'} target={"_blank"}>
                                                                <img
                                                                    src={e?.url ? faviconFetch({uri: e?.url}) : e?.logo}
                                                                    alt=''
                                                                    className={"flex-wrap w-9 h-9 mx-2 p-1 rounded"}
                                                                />
                                                                <span className={`break-all`}>{e?.name}</span>
                                                            </a>
                                                        </div>
                                                    })
                                                    :
                                                    <div className={classes.addName}
                                                         onClick={() => handleAddLink(item)}>
                                                        <img alt={''}
                                                             className={classes.plusAddIcon}
                                                             src={'icons/plusAddIcon.svg'}/>
                                                        {addName}
                                                    </div>

                                            }
                                            <img onClick={() => handleClick(item, listOtherURL)}
                                                 className={`${classes.setting}`}
                                                 src={"icons/settingWhite.svg"} alt=""/>
                                            <DialogAddLinkProjects openAdd={addPro} setOpenAdd={setAddPro}
                                                                   project={link}/>
                                        </div>
                                        <Label color={item.color} allDay={allDay}>{rowPercentRate}%</Label>
                                    </div>
                                </Tooltip>
                            </React.Fragment>
                        )
                    }
                    const percent = Number(item.hour) / total * 100
                    const rowPercent = percent.toFixed(0)
                    if (percent === 0) return null
                    key++
                    return (
                        <React.Fragment key={key}>
                            <Tooltip placement={"top"} title={item.name} color={item.color}>
                                <PerCent height={50} index={sort.length - index} percent={percent} allDay={allDay}
                                         color={"transparent"} row={rowMode}>
                                    <Label
                                        color={item.color} row={true} className={classes.label} width={"none"}
                                        paddingLeft={10}>
                                        {rowPercent}%
                                    </Label>
                                    <PerCent color={item.color} borderRight={true}>
                                        {
                                            showName && !allDay ?
                                                <Name color={nameColor} position={"center"}>
                                                    {item.name}
                                                </Name>
                                                :
                                                <React.Fragment>
                                                    {
                                                        isIdentity ?
                                                            <IdentityImage className={classes.rowIdentity}/>
                                                            :
                                                            null
                                                    }
                                                </React.Fragment>
                                        }
                                    </PerCent>
                                    <Label color={"#000"} row={true} className={classes.label} width={"none"}
                                           paddingLeft={10}>
                                        {allDay ? `${simpleDuration((item.hour / 24).toFixed(1))}d` : `${simpleDuration(item.hour?.toFixed(1))}h`}
                                    </Label>
                                </PerCent>
                            </Tooltip>
                        </React.Fragment>
                    )
                })}

            </Content>
            <Box padding={padding}>
            </Box>
        </div>

    );
}

const areEqual = (prevProps, nextProps) => {
    return prevProps.rowMode === nextProps.rowMode &&
        prevProps.allDay === nextProps.allDay &&
        prevProps.showName === nextProps.showName &&
        prevProps.isIdentity === nextProps.isIdentity &&
        prevProps.padding === nextProps.padding &&
        prevProps.calendarheight === nextProps.calendarheight &&
        compareData(prevProps.data, nextProps.data)
}

export const compareData = (prevData = [], nexData = []) => {
    if (prevData.length !== nexData.length)
        return false

    if (orderBy(prevData, "hour", 'desc') !== orderBy(nexData, "hour", 'desc')) return false;

    let result = true
    prevData.forEach((item, index) => {
        if (item.color !== nexData[index].color ||
            item.hour !== nexData[index].hour ||
            item.sum !== nexData[index].sum ||
            item.name !== nexData[index].name ||
            !isEqual(sortBy(item.otherURL), sortBy(nexData[index].otherURL))
        ) {
            result = false
        }
    })
    return result
}
const useStyles = makeStyles(() => ({
    columnContainer: {
        display: "flex", marginBottom: 10, position: "relative",
        '&:hover': {
            zIndex: 9,
            "& $icon": {
                display: "flex !important",
                flexDirection: "row",
                flexWrap: "wrap"
            },
            "& $perCent": {
                display: "none"
            }
        }
    },
    columnIdentity: {
        fill: "#fff",
        height: "15px",
        marginTop: 4,
        textAlign: 'left',
        alignItems: 'left'
    },
    rowIdentity: {
        fill: "#fff",
        height: "15px",
        marginLeft: 7,
        marginTop: 4
    },
    noneDisplay: {
        display: "none !important"
    },
    showIcon: {
        background: "#fff",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start"
    },
    icon: {
        display: "none !important",
    },
    maxWidth: {
        width: "100% !important"
    },
    perCent: {},
    setting: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 2,
        padding: "3px 3px",
        borderRadius: "10px",
        backgroundColor: "rgba(0,0,0,0.6)",
        cursor: "pointer"
    },
    wrapIcon: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        position: "relative",
        paddingRight: 30
    },
    addName: {
        height: 25,
        width: "100%",
        textAlign: "center",
        cursor: "pointer",
        fontWeight: 600
    },
    aTag: {
        '&:hover': {
            '& $img': {
                background: "#fff"
            }
        }
    },
    label: {
        display: "block",
        width: "auto",
        textAlign: "center"
    },
    plusAddIcon: {
        marginRight: 5
    }
}))
export default React.memo(LineChart, areEqual)
