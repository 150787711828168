import {ggTokenChecked} from "../../config/auth";
import {cloneDeep, findIndex,} from 'lodash'
import {snapshot} from 'valtio';
import {eventsProxy} from '../../store/proxy/events.proxy';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {calenviewTimeRangeProxy} from '../../store/proxy/calenviewTimeRange.proxy';


export const getEventsSync = async (id) => {
    if (!id)
        return null;
    await reloadGoogleEvents(id)
    return null


}

export const reloadGoogleEvents = async (id) => {
    if (!id)
        return null;
    const {googleEvents, setGoogleEvents} = snapshot(eventsProxy)
    const {googleProjects} = snapshot(projectsProxy)
    const {start, end} = snapshot(calenviewTimeRangeProxy)

    try {
        let idx = findIndex(googleProjects, {id})
        if (idx === -1)
            return null
        const checked = await ggTokenChecked()
        if (!checked)
            return null

        const request = window.gapi.client.calendar.events.list({
            'calendarId': id,
            "timeMin": start,
            "timeMax": end,
            "maxResults": 2500,
        })
        const {result, status} = await request.then()
        if (status !== 200)
            return null
        const {items} = result
        const nextEvents = cloneDeep(googleEvents)
        nextEvents[id] = {
            id,
            events: items
        }
        setGoogleEvents(nextEvents)

    } catch (e) {

    }


}
