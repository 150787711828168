import React, {useEffect, useState} from 'react'
import {Input, Switch} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import {cloneDeep, findIndex} from 'lodash';
import {db} from '../../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../../config/constants';
import {msgError} from '../../../utils/msg';
import {getGlobal, useGlobal} from 'reactn';
import {barRef} from '../../../common/firebaseRef/annoucement';
import {publicIcon} from '../../Calendar/handlers/infoEvent';
import moment from 'moment';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';
import {themesProxy} from '../../../store/proxy/themes.proxy';

function TargetHighLight({
                             value,
                             fieldName,
                             checked,
                             setchecked,
                             checkName,
                             Intl,
                             fieldFocusHighLight,
                             index
                         }) {
    const classes = useStyles()
    // const [focusText, setFocusText] = useGlobal('focusText')
    const {bar, lock} = useSnapshot(announcementProxy)
    const [edit, setEdit] = useState(false)
    const [refetchDateNow] = useGlobal('refetchDateNow')
    const [textTarget, setTextTarget] = useState('')
    const {user, focusText, setFocusText} = useSnapshot(userProxy)
    const {view} = useSnapshot(themesProxy)
    const [numCheckHighLight] = useGlobal('numCheckHighLight')
    const [focusFromPreview, setFocusFromPreview] = useGlobal('focusFromPreview')
    const [dateCurrent, setDateCurrent] = useState('');

    useEffect(() => {
        setFocusText();
    }, []);


    useEffect(() => {
        switch (checkName) {
            case 'day':
                setDateCurrent(moment().locale(user.language).format('YYYY-MM-DD (dd)'));
                break;
            case 'week':
                setDateCurrent(`${moment().format('YYYY')}-W${moment().isoWeeks()}`);
                break;
            default:
                setDateCurrent(moment().format('YYYY-MM'));
        }
    }, [fieldName]);

    useEffect(() => {
        let index = findIndex(focusText, {'date': value})
        if (index !== -1 && focusText[index].focusText) {
            setTextTarget(focusText[index].focusText)
        } else {
            setTextTarget('')
        }
    }, [focusText, refetchDateNow, view, value])

    const handleUpdateTarget = async (fieldName) => {
        let newFocusText = cloneDeep([...focusText]);
        let date = value
        try {
            let idx = findIndex(focusText, {"date": date});
            if (focusText !== '') {
                if (idx === -1) {
                    newFocusText.push({
                        "focusText": textTarget,
                        "date": date
                    });
                } else {
                    newFocusText[idx] = {
                        "focusText": textTarget,
                        "date": date
                    }
                }
            } else {
                if (idx >= 0) {
                    newFocusText.splice(idx, 1);
                }
            }
            setFocusText([...newFocusText]);

            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .set({
                        focusText: newFocusText
                    },
                    {merge: true}
                )
            setEdit(false)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        } finally {
            setFocusFromPreview(numCheckHighLight)
        }

    }

    const handleCheckedHighLight = async (fieldName, val) => {
        try {
            let newCheck = []
            let fieldUpdate
            switch (fieldName) {
                case 'day':
                    fieldUpdate = 'daysCheck'
                    newCheck = cloneDeep([...bar?.daysCheck]) || [true]
                    newCheck[index] = val
                    break;
                case 'week':
                    fieldUpdate = 'weeksCheck'
                    newCheck = cloneDeep([...bar?.weeksCheck]) || [true]
                    newCheck[index] = val
                    break;
                default:
                    fieldUpdate = 'monthsCheck'
                    newCheck = cloneDeep([...bar?.monthsCheck]) || [true]
                    newCheck[index] = val
            }
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                dataList: [],
                [fieldUpdate]: newCheck
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        } finally {
            setFocusFromPreview(numCheckHighLight)
        }
    }

    return (
        <div
            className={(edit || (focusFromPreview?.id === fieldFocusHighLight && focusFromPreview.time === value)) ? classes.itemFocus : classes.item}>
            <div className={classes.listText}>
                <div className={classes.content}>
                    {
                        (edit || (focusFromPreview?.id === fieldFocusHighLight && focusFromPreview.time === value)) ?
                            <div className={`${classes.wrapHighLight}`}>
                                <div className={classes.iconTimeCurrent}>
                                    {value === dateCurrent && (
                                        <img src={publicIcon('Green_Arrow_Right.svg')} alt=""/>
                                    )}
                                </div>
                                <div
                                    className={classes.showTime}>{value}</div>
                                <Input
                                    disabled={lock}
                                    onPressEnter={() => handleUpdateTarget(fieldName)}
                                    onBlur={() => handleUpdateTarget(fieldName)}
                                    className={classes.input} autoFocus={true}
                                    value={textTarget}
                                    onChange={(e) => setTextTarget(e.target.value)}
                                />
                                <Switch
                                    className={classes.iconChecked}
                                    checked={checked}
                                    disabled={lock || (!checked && numCheckHighLight >= 5)}
                                    onChange={() => handleCheckedHighLight(checkName, !checked)}
                                />
                            </div>
                            :
                            <div className={`${classes.wrapHighLight}`}>
                                <div className={classes.iconTimeCurrent}>
                                    {value === dateCurrent && (
                                        <img src={publicIcon('Green_Arrow_Right.svg')} alt=""/>
                                    )}
                                </div>
                                <div
                                    className={classes.showTime}>{value}</div>
                                <div onClick={() => {
                                    if (lock)
                                        return;
                                    setEdit(!edit)

                                }}
                                     className={classes.textHighLight}>{textTarget || Intl}</div>
                                <Switch
                                    className={classes.iconChecked}
                                    checked={checked}
                                    disabled={lock || (!checked && numCheckHighLight >= 5)}
                                    onChange={() => handleCheckedHighLight(checkName, !checked)}
                                />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    item: {
        backgroundColor: "#fff",
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    iconTimeCurrent: {
        height: 28,
        width: 20,
        display: "flex",
        alignItems: 'center',
        position: "absolute",
        left: -20,
        top: 0,
        "&& img": {
            height: 'auto',
            width: 12
        }
    },

    itemFocus: {
        backgroundColor: theme.props.primaryColor,
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    content: {
        backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        fontSize: 12,
        color: "#444444",
        margin: 2,
        width: "100%",
        padding: "8px 45px 8px 5px",
        "& .ant-input:focus, .ant-input-focused": {
            border: "none",
            boxShadow: "none"
        }
    },
    listText: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
    },
    wrapHighLight: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    showTime: {
        background: "#fff",
        color: theme.props.primaryColor,
        padding: "3px 10px",
        borderRadius: 9,
        fontWeight: 600,
        fontSize: 14,
        minWidth: 135,
        textAlign: "center"
    },
    input: {
        border: "none",
        backgroundColor: "transparent",
        padding: "0 0 0 10px",
        outline: "none",
        fontSize: 12,
        color: "#444444",
    },
    iconChecked: {
        // width: 28,
        // height: 28,
        position: "absolute",
        right: "-40px",
        display: "flex",
        alignItems: "center",
        // borderRadius: "50%",
        // border: `2px solid ${theme.props.primaryColor}`,
        // background: "#fff",
        // cursor: "pointer",
    },
    textHighLight: {
        cursor: "pointer",
        paddingLeft: 10,
        width: "100%"
    },
}))

export default TargetHighLight
