import React, {useEffect, useState} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/core/styles';
import AnnouncementCheck from '../../Custom/AnnouncementCheck';
import {db} from '../../../config/firebaseConfig';
import {barRef} from '../../../common/firebaseRef/annoucement';
import useIntlId from '../../../hooks/useIntlId';
import ButtonGroup from 'antd/es/button/button-group';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';


const ProjectItem = styled.button`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px;
    border-radius: 30px;
    width: 250px;
    min-height: 40px;
    margin: 0 10px;
    text-align: -webkit-center;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
;
    border: none;
    color: #fff;
`

function MilestoneBar() {
    const classes = useStyles()
    const {user} = useSnapshot(userProxy)

    const {plan, bar} = useSnapshot(announcementProxy);
    const [column, setColumn] = useState(bar?.milestone?.column || 1)
    const [focusC, setFocusC] = useState(bar?.focusColor || 'project')
    const [
        unlockMileIntl,
        milestone,
        columnMilestone,
        focusInt,
        projectIntl,
        identityIntl,
    ] = useIntlId([
        'milestone.plan.notification',
        'setting.listTab.milestone',
        'setting.column',
        'milestone.focusColor',
        'appy.tooltip',
        'app.profile.identities',
    ])

    const updateMilestoneBar = fieldName => async (checked) => {
        try {
            const batch = db.batch()
            const MilestoneBar = barRef(user?.user_id)
            let data = fieldName === 'milestone' ? {checked: checked, column: column} : checked
            batch.set(MilestoneBar, {
                ...bar,
                [fieldName]: data
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (!bar?.milestone) {
            updateMilestoneBar('milestone')(false)
        } else {
            updateMilestoneBar('milestone')(bar?.milestone?.checked)
        }
        // eslint-disable-next-line
    }, [column])

    useEffect(() => {
        updateMilestoneBar('focusColor')(focusC)
        // eslint-disable-next-line
    }, [focusC])

    return (
        <>
            <div className={classes.root}>
                <div className={classes.contentCheck}>
                    <AnnouncementCheck
                        lock={!plan?.info?.milestone}
                        check={bar?.milestone?.checked}
                        setCheck={updateMilestoneBar("milestone")}
                        name={milestone}
                        isOnlyCheck={true}
                    />
                </div>
                {plan?.info?.milestone &&
                    <div className={classes.content} align='center'>
                        <ButtonGroup variant="contained" size='large'>
                            <ProjectItem
                                className={`${column === 1 ? classes.buttonActive : ''}`}
                                onClick={() => setColumn(1)}
                            >
                                <div className={classes.text}>1{columnMilestone}</div>
                                <div className={classes.textColumn}></div>
                            </ProjectItem>
                            <ProjectItem
                                className={`${column === 2 ? classes.buttonActive : ''}`}
                                onClick={() => setColumn(2)}
                            >
                                <div className={classes.text}>2{columnMilestone}</div>
                                <div className={classes.textColumnTwo}>
                                    <div className={classes.textColumn}
                                         style={{marginRight: 8}}></div>
                                    <div className={classes.textColumn}></div>
                                </div>
                            </ProjectItem>
                        </ButtonGroup>
                    </div>
                }
                <Grid xs={12} className={`mt-[15px]`}>
                    <div style={{display: 'flex'}}>
                        <div
                            className={`${classes.title} ${classes.contentCheck} ${!plan?.info?.focus && classes.textLock}`}>{focusInt}</div>
                        {!plan?.info?.focus && <img className={classes.imgKey} src="/icons/iconKey.svg" alt=""/>}
                    </div>
                    {
                        plan?.info?.focus &&
                        <div className={classes.content} align='center'>
                            <ButtonGroup variant="contained" size='large'>
                                <ProjectItem
                                    className={`${focusC === 'project' ? classes.buttonActive : ''}`}
                                    onClick={() => setFocusC('project')}
                                >
                                    <div
                                        className={`${focusC === 'project' ? classes.switchButton : classes.switchButton1}`}>{projectIntl}</div>
                                </ProjectItem>
                                <ProjectItem
                                    className={`${focusC === 'identity' ? classes.buttonActive : ''}`}
                                    onClick={() => setFocusC('identity')}
                                >
                                    <div
                                        className={`${focusC === 'identity' ? classes.switchButton : classes.switchButton1}`}>{identityIntl}</div>
                                </ProjectItem>
                            </ButtonGroup>
                        </div>
                    }
                </Grid>
            </div>
            {
                !plan?.info?.milestone && <Button
                    onClick={() => setGlobal({
                        openSetting: true,
                        settingTab: 12
                    })}
                    className={classes.btnUnlock}>
                    <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                    {unlockMileIntl}</Button>
            }
        </>
    );
}

export default MilestoneBar;
const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 15px',
        background: '#fff',
        padding: '15px',
        borderRadius: 15,
        alignItems: 'flex-start'
    },
    content: {
        paddingTop: 35,
    },
    contentCheck: {
        borderBottom: "1px solid #f1f1f1",
    },
    text: {
        color: 'black',
        minWidth: 234,
        position: "absolute",
        top: '-25px',
    },
    textLock: {
        background: '#f1f1f1',
    },
    textColumn: {
        background: '#fff',
        padding: 2,
        borderRadius: 30,
        color: 'black',
        height: 26,
        minWidth: 113,
    },
    textColumnTwo: {
        display: "flex"
    },
    btnUnlock: {
        margin: '15px auto',
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    buttonActive: {
        backgroundColor: `${theme.props.primaryColor} !important`,
    },
    iconButton: {
        marginRight: 10,
    },
    switchButton: {
        background: theme.props.primaryColor,
        alignItems: 'center',
        variant: "raised",
        color: "white",
        fontSize: 12,
        textTransform: 'uppercase',
    },
    switchButton1: {
        alignItems: 'center',
        variant: "raised",
        color: "black",
        fontSize: 12,
        textTransform: 'uppercase',
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444",
        padding: '10px 0',
        width: '100%',
    },
    imgKey: {
        position: 'absolute',
        right: 50,
        top: 155,
    },
    gridItem: {
        marginBottom: 15,
        "&:hover": {
            opacity: 0.8,
        },
        "& .svg-identity": {
            width: 20,
            marginLeft: 15,
        },
    },
}))
