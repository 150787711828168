import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import {CKEditor} from 'ckeditor4-react';
import {IS_PRODUCTION} from '../../../config/constants';
import {db} from '../../../config/firebaseConfig';
import {makeStyles} from '@material-ui/core/styles';
import sendEmailGroupTemplate from '../../../common/sendEmailGroupTemplate';
import useIntlId from '../../../hooks/useIntlId';
import {msgError, msgSuccess} from '../../../utils/msg';
import {genDateIdPrefix} from 'cf-gen-id';
import get from 'lodash/get'
import {notifError} from '../../../utils/notif';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';

function DialogVoteBoxHandleEmail({choose, setChoose}) {

    const {user} = useSnapshot(userProxy)
    // console.log({user})
    // const [, setClickInfo] = useGlobal("clickInfo")
    const classes = useStyles()
    const [messages, setMessages] = useState('')
    const [loading, setLoading] = useState(false)
    // const [url, setUrl] = useState(null)
    const [emailSentIntl, cancelIntl] = useIntlId(['toast.emalSent', 'dialog.cancel'])
    const handleClose = () => {
        setMessages('')
        setChoose(null)
    }

    const handleOwnerChooseVoteTime = async () => {
        try {
            if (!choose?.meetingId || !choose.users)
                return;
            setLoading(true)
            const batch = db.batch()
            // handle sent email from calendar
            // console.log('sent email')
            // sent mail to users
            const link = `https://appy${IS_PRODUCTION ? "" : "-stg"}.geniam.com/event?id=${choose?.meetingId}`
            const {users} = choose
            const senderEmail = get(user, 'email') || get(user, 'googleAuth.email')
            if (!senderEmail) {
                notifError('sender email not found')
                return;
            }
            users.forEach((u) => {
                // not send to me
                if (u?.id === user?.user_id)
                    return;
                const uuidEmail = genDateIdPrefix('email_evt_meeting_')
                const mailDocRef = db.doc(`mails/${uuidEmail}`)
                const htmlData = sendEmailGroupTemplate({
                    title: choose.title || "",
                    link: link,
                    sender: senderEmail,
                    content: messages,
                })
                batch.set(mailDocRef, {
                    to: u.email,
                    message: {
                        subject: choose.title || "Event Group",
                        html: htmlData
                    },
                    replyTo: senderEmail
                })
                // console.log('send to:', u.email, uuidEmail)
            })
            await batch.commit()
            msgSuccess(emailSentIntl)
        } catch (e) {
            console.log(e.toString())
            msgError(e.toString())
        } finally {
            setLoading(false)
            handleClose()
        }
    }

    return (
        <div>
            <Dialog
                open={Boolean(choose)}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogTitle className={classes.container}>
                        メールを送る
                    </DialogTitle>
                    <CKEditor data={messages} onChange={e => setMessages(e.editor.getData())}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {cancelIntl}
                    </Button>
                    <Button
                        onClick={handleOwnerChooseVoteTime}
                        color="primary"
                        variant={'contained'}
                        // className={'hover:font-bold'}
                        // autoFocus
                        disabled={loading}
                    >
                        メールを送る
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DialogVoteBoxHandleEmail;

const useStyles = makeStyles(theme => ({
    container: {
        padding: "0px",
        marginBottom: 15
    }
}))
