import {db} from '../../config/firebaseConfig';
import {ZOOM_COLLECTION} from '../../config/constants';
import {get} from 'lodash';
import {snapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';

export const myZoomDocRef = () => {
    const {user} = snapshot(userProxy)
    const user_id = get(user, 'user_id')
    if (!user_id)
        return;
    return db.doc(`${ZOOM_COLLECTION}/${user_id}`)
}
