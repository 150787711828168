import {getGlobal} from 'reactn'
import {msgError} from '../../../utils/msg';


export const renderCal = number => {
    for (let i = 0; i < 36; i++) {
        if (i === number - 1 || i === number || i === number + 1 || i === number + 2) {
            if (window['calendar' + i]) window['calendar' + i].render()
        } else {
            if (window['calendar' + i]) window['calendar' + i].destroy()
        }
    }
}

export const getTaskDefault = () => {
    const {tasks} = getGlobal()
    if (!tasks.data[tasks.default])
        return ''
    return tasks.data[tasks.default]?.icon || tasks.data[tasks.default]?.name
}

export const CreateGoogleCalendar = async (name, color) => {
    try {
        let request = window.gapi.client.calendar.calendars.insert({
            summary: name
        })
        let data = await request.then()
        if (data.status !== 200) {
            return null
        }
        const calendarId = data.result.id
        request = window.gapi.client.calendar.calendarList.patch({
            "calendarId": calendarId,
            "colorRgbFormat": true,
            backgroundColor: color
        })
        data = await request.then()
        if (data.status !== 200) {
            return
        }
        return data.result

    } catch (e) {
        msgError(e.toString())
        return null
    }

}


