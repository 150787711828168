import React, {Component} from 'react';
import {withScriptjs} from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import {isArray,} from 'lodash'
import {withStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import {publicIcon} from "./handlers/infoEvent";


class LocationAutoComplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',

        }
    }

    /**
     * Get the current address from the default map position and set those values in the state
     */

    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state;

    }

    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray) => {
        if (isArray(addressArray)) {
            let city = '';
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                    city = addressArray[i].long_name;
                    return city;
                }
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getArea = (addressArray) => {
        if (isArray(addressArray)) {
            let area = '';
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }

    };
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = (addressArray) => {
        if (isArray(addressArray)) {
            let state = '';
            for (let i = 0; i < addressArray.length; i++) {
                for (let i = 0; i < addressArray.length; i++) {
                    if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                        state = addressArray[i].long_name;
                        return state;
                    }
                }
            }
        }

    };
    /**
     * And function for city,state and address input
     * @param place
     */



    onPlaceSelected = (place) => {
        if (place.address_components) {
            const address = place.formatted_address,
                addressArray = place.address_components,
                city = this.getCity(addressArray),
                area = this.getArea(addressArray),
                state = this.getState(addressArray)
            // Set these values in the state.

            this.props.setLocation(address)
            this.setState({
                address: (address) ? address : '',
                area: (area) ? area : '',
                city: (city) ? city : '',
                state: (state) ? state : '',
            })
        } else {
            this.props.setLocation(place.name)
        }

    };


    render() {
        const {classes} = this.props

        const AsyncMap = withScriptjs(props => <Autocomplete
                id={'edit-location'}
                className={classes.root}
                defaultValue={this.props.location}
                onPlaceSelected={this.onPlaceSelected}
                placeholder={this.props.locationIntl}
                types={["geocode"]}
                onChange={e => {
                    this.props.setLocation(e.target.value)
                }}
            />
        );

        return (
            <div className={classes.box}>
                <IconButton disableRipple={true} className={classes.icon}>
                    <img src={publicIcon('location.svg')} alt=""/>
                </IconButton>
                <AsyncMap

                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCSEei0t0WoB_Zd6z-UTxu_wb0JBVmoCWA&libraries=places"
                    loadingElement={
                        <div className={classes.loading}/>
                    }
                    containerElement={
                        <div/>
                    }
                    mapElement={
                        <div/>
                    }
                />
            </div>
        )

    }
}

const styles = theme => ({
    root: {
        width: '100%',
        padding: '7px 15px',
        border: "none",
        outline: "none !important",
        background: "#F1F1F1",
        borderRadius: 25,
        minWidth: 195,
        // "&:hover": {
        //     backgroundColor: "rgb(230, 230, 230)"
        // },
        // "&:focus": {
        //     backgroundColor: "rgb(230, 230, 230)"
        // },
        "&::placeholder": {
            opacity: 1,
            color: "#000"
        }
    },
    loading: {
        height: `100%`
    },

    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    box: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
})


export default withStyles(styles)(LocationAutoComplete)
