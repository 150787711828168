import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useGlobal} from 'reactn'
import {refetchSource, removeEvent} from "../../actions/refetchSourcce";
import {db} from "../../config/firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import moment from "moment";
import {ggTokenChecked} from "../../config/auth";
import updateWatchChannelMyself from "../../common/updateWatchChannelMyself";
import useIntlId from "../../hooks/useIntlId";
import {deleteEventGoogle, useCalendarEvents} from "../../context/CalendarEventsContext";
import {handleDeleteGoogleTasksSchedule} from "../../actions/googleTasks";
import {Button} from 'antd'
import {msgError, msgInfo} from "../../utils/msg";
import {deleteZoom} from "../../actions/zoom";
import {concat} from 'lodash';
import {getEventCalendarDocRef, getEventsDocRef, getVoteBoxDocRef} from '../../common/firebaseRef/meetings';
import {sendMailDeleteEventVote} from '../Mails/Actions';
import {useHistory} from 'react-router-dom';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {eventsProxy} from '../../store/proxy/events.proxy';
import {searchProxy} from '../../store/proxy/search.proxy';

function DeleteEvent({open, setOpen, event, setEditInfo}) {

    // const [isDelete] = useKeyboardJs('backspace')
    const {user} = useSnapshot(userProxy)
    const {deleteEvent} = useSnapshot(eventsProxy)
    const {reloadEventsInSearchPage} = useSnapshot(searchProxy)
    const history = useHistory();
    const [googleStatus] = useGlobal("googleStatus")
    const [DialogTitleIntl, DialogDeleteIntl, DialogCancelIntl, delNotifIntl] =
        useIntlId(['milestone.isDelete', 'dialog.action.delete', 'dialog.action.cancel', 'common.deleted'])
    const handleClose = () => {
        setOpen(false)
    }

    const deleteEventVoteBoxMeeting = (event) => {
        if (!event) return null;
        const eventBoxMeetRef = db.doc(`${CALENDAR_COLLECTION}/${user?.user_id}/eventBoxMeeting/${event.id}`)
        eventBoxMeetRef.delete().catch(e => console.log(e))
    }

    const onDelete = async () => {
        if (event?.conferenceData) {
            console.log('delete');
            deleteZoom(event?.zoomMeetingId).then()
            // axios.post(API_ZOOM + '/delete', {
            //     type: 'meetings',
            //     meetingId: event?.zoomMeetingId,
            // }).catch(e => console.log(e))
        }
        if (event?.googleTasksID) {
            handleDeleteGoogleTasksSchedule(event.googleTasksID).catch(e => console.log(e))
        }
        if (event?.isEventVote || event?.meetingId) {
            deleteVoted(event).then()
        }
        setOpen(false)
        setEditInfo(null)
        removeEvent(event?.id)
        if (event?.is_google || event?.googleEvent)
            return deleteGoogleEvent(event)

        return deleteGeniamEvent(event)
    }
    const deleteGoogleEvent = async (event) => {
        try {
            await ggTokenChecked()
            const request = window.gapi.client.calendar.events.delete({
                "calendarId": event.project_uuid,
                "eventId": event.id
            })
            const {status} = await request.then()
            if (status !== 204) {
                // error
                refetchSource('google')
                msgError("DELETE EVENT ERROR. TRY AGAIN LATE")
                return;
            }
            await db.collection(CALENDAR_COLLECTION).doc(user.user_id)
                .set({
                    updatedAt: moment().utc().format()
                }, {merge: true})
            deleteEvent(event, () => {
                refetchSource('google')
                msgInfo(delNotifIntl)
            })
            await updateWatchChannelMyself(event.project_uuid);

            await reloadEventsInSearchPage(history.location, googleStatus);
        } catch (e) {
            if (e?.status === 410 && e?.result?.error?.message === 'Resource has been deleted') {
                // resource deleted by calendar api
                deleteEvent(event, () => {
                    refetchSource('google')
                    msgInfo(delNotifIntl)
                })
                return;
            }
            msgError(e.toString())
            refetchSource('google')
        }
    }
    const deleteGeniamEvent = async (event) => {
        try {
            if (event?.project_uuid) {
                const time = moment().utc().format()
                await db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                    .collection('events')
                    .doc(event.id)
                    .set({isDeleted: true, updated_at: time}, {merge: true})

            }
            deleteEvent(event, () => {
                refetchSource('geniam')
                msgInfo(delNotifIntl)
            })

            await reloadEventsInSearchPage(history.location, googleStatus);
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        }
    }

    const deleteEventSync = async (evt, uID) => {
        if (evt.eventGoogle)
            return deleteEventGoogle(evt.project_uuid, uID, evt.eventSyncId)
        const eventDocRef = getEventsDocRef(uID, evt?.eventSyncId)
        await eventDocRef.delete();
    }

    const deleteVoted = async (event) => {
        try {
            const eventSnap = await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('eventBoxMeeting')
                .where("eventId", "==", event.id)
                .get()
            const query2 = await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('eventBoxMeeting')
                .where("eventSyncId", "==", event.id)
                .get()

            if (!eventSnap.size && !query2.size) {
                deleteEventVoteBoxMeeting(event)
                return;
            }
            const events1 = eventSnap.docs.map(doc => doc.id)
            const events2 = query2.docs.map(doc => doc.id)
            const events = concat(events1, events2) || []
            const batch = db.batch()
            events.forEach(id => {
                const ref = db.collection(CALENDAR_COLLECTION)
                    .doc(user.user_id)
                    .collection('eventBoxMeeting')
                    .doc(id)
                batch.set(ref, {isDeleted: true}, {merge: true})
            })
            const userFind = event.users?.filter(e => e.id === user.user_id)
            if(userFind?.length && userFind?.[0]?.role !== 'viewer') {
                let checkBatch = true;
                await Promise.all(event.users.map(async (usr) => {
                    const uId = usr?.id || usr?.user_id
                    let eventDocRef = getEventCalendarDocRef(uId, events[0])
                    const snap = await eventDocRef.get()
                    if (!snap?.exists) return;
                    await eventDocRef.delete()
                    let evt = snap?.data()
                    if (!evt) return;
                    try {
                        await deleteEventSync(evt, uId, usr)
                        await sendMailDeleteEventVote(evt, uId, usr, event.meetingId)
                    } catch (e) {
                        if (user.user_id === uId) checkBatch = false
                    }
                }))
                if (checkBatch) {
                    const voteRef = getVoteBoxDocRef(event.meetingId, events[0])
                    await voteRef.delete()
                }
            }

            await batch.commit()
        } catch (e) {
            console.log(e);
            msgError(e.toString())
            return e
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        {DialogTitleIntl}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button shape="round" onClick={handleClose}>
                        {DialogCancelIntl}
                    </Button>
                    <Button
                        shape="round"
                        onClick={onDelete}
                        autoFocus
                    >
                        {DialogDeleteIntl}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default (DeleteEvent)
