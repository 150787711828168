import moment from "moment";
import {getGlobal, setGlobal} from 'reactn'
import {snapshot} from 'valtio';
import {themesProxy} from '../../../store/proxy/themes.proxy';

export function getDate(numberId) {
    const {refetchDateNow} = getGlobal()
    const {currentTheme} = snapshot(themesProxy)
    const {viewCalendar = "CustomMonth"} = currentTheme
    let date, endDate

    if (currentTheme?.advanceView && currentTheme?.viewCalendar === "CustomDay") {
        if(currentTheme?.settingToday) {
            date = moment(refetchDateNow).add( 1 - currentTheme?.indexToday, 'day').format("YYYY-MM-DD")
            endDate = moment(date).add( currentTheme?.numsDay + 1 - currentTheme?.indexToday , "day").format('YYYY-MM-DD')
            return {date, endDate}
        }
        let rangeDay = currentTheme?.rangeDay || []
        let start = rangeDay[0] || moment().startOf("day").add(numberId, "day").format('YYYY-MM-DD')
        let end = rangeDay[1] || moment().add(7, 'days').endOf("day").add(numberId, "day").format('YYYY-MM-DD')
        date = moment(start).startOf("day").format('YYYY-MM-DD')
        endDate = moment(end).endOf("day").format('YYYY-MM-DD')
        return {date, endDate}
    } else {
        if (viewCalendar === "CustomDay") {
            date = moment(refetchDateNow).startOf("day").add(currentTheme?.columns * numberId, "days").format('YYYY-MM-DD')
            endDate = moment(date).endOf("day").add(currentTheme?.columns, "days").format('YYYY-MM-DD')
            return {date, endDate}
        }
        if (viewCalendar === "CustomMonth") {
            date = moment(refetchDateNow).startOf("month").add(numberId, "month").format('YYYY-MM-DD')
            endDate = moment(refetchDateNow).endOf("month").add(numberId, "month").format('YYYY-MM-DD')
        } else {
            if (viewCalendar === "CustomWeek") {
                date = moment(refetchDateNow).startOf("isoWeek").add(numberId, "week").format('YYYY-MM-DD')
                endDate = moment(refetchDateNow).endOf("isoWeek").add(numberId, "week").format('YYYY-MM-DD')
            } else {
                date = moment(refetchDateNow).startOf("day").add(numberId, "day").format('YYYY-MM-DD')
                endDate = moment(refetchDateNow).endOf("day").add(numberId, "day").format('YYYY-MM-DD')
            }
        }
    }
    return {date, endDate}
}

export const getCalOfTime = () => {
    setGlobal({
        refetchDateNow: moment().format('YYYY-MM-DD')
    })
    window.scrollTo({
        top: 0,
        left: 0,
    });
    return 0
}

export function getDateLink(numberId, view) {
    let {refetchDateNow} = getGlobal()
    let date
    if (view === "CustomMonth") {
        date = moment(refetchDateNow).startOf("month").add(numberId, "month").add(-1, "day").format('YYYY-MM-DD')
    } else {
        if (view === "CustomWeek") {
            date = moment(refetchDateNow).startOf("isoWeek").add(numberId, "week").add(-1, "day").format('YYYY-MM-DD')
        } else {
            date = moment(refetchDateNow).startOf("day").add(numberId - 1, "day").format('YYYY-MM-DD')
        }
    }
    return {date}
}
