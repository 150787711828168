import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn'
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import WeekDay from "../../../Meeting/Home/LeftHomeApp/WeekDay";
import {cloneDeep, findIndex, remove} from "lodash";
import {TextSelectFrom, TextSelectTo} from "../../../splitting";
import useIntlId, {getIntlId} from "../../../../hooks/useIntlId";
import ExcludeDayTime from "./ExcludeDayTime";
import {CALENDAR_COLLECTION} from "../../../../config/constants";
import {db} from '../../../../config/firebaseConfig'
import {Tooltip} from 'antd';
import {ReactComponent as PolygonSVG} from "../../../../asSvg/icons/polygon.svg";
import {useStylesDisplaySettting} from "../../DisplaySettingStyle";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddSVG} from "../../../../asSvg/icons/add.svg";
import CancelButton from "../../../Dialog/CancelButton";
import SaveButton from "../../../Dialog/SaveButton";
import {publicIcon} from "../../../Calendar/handlers/infoEvent";
import {msgSuccess} from '../../../../utils/msg';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../../store/proxy/user.proxy';

function DialogUpdateDisplaySetting({classes, data, setData, eventUpdate, setEventUpdate, setOpenTime, setOpenDayOff}) {
    const {user} = useSnapshot(userProxy)
    const [times] = useGlobal('times')
    const [loading, setLoading] = useState(false)
    const [check, setCheck] = useState(false);
    const [dateRange] = useIntlId(['event.date.range'])
    const [updateRangeText, addDayOffText, excludeDayTimeText, nonWorkingDayText, operationTimeText, infiniteIntl] = useIntlId(['setting.updatetimerange', 'setting.addholiday', 'setting.excludedaytime', 'setting.nonworkingdays', 'setting.operatetime', 'event.infinite']);
    const clx = useStylesDisplaySettting()

    useEffect(() => {
        if (data?.timeDisplay?.minTime?.value >= data?.timeDisplay?.maxTime?.value || data?.timeDisplay?.maxTime?.value < data?.timeDisplay?.minTime?.value) {
            setCheck(true)
        } else {
            setCheck(false)
        }
    }, [data])

    useEffect(() => {
        if (eventUpdate) {
            setData(cloneDeep(eventUpdate))
        }

        // eslint-disable-next-line
    }, [])

    const onClose = () => {
        setLoading(false)
        setOpenDayOff(false)
        setEventUpdate(null)
        setData({
            startDay: moment().format(),
            endDay: null,
            hiddenDays: [],
            timeDisplay: {
                minTime: times ? times.minTime : {
                    label: "00:00",
                    value: 0
                },
                maxTime: times ? times.maxTime : {
                    label: "24:00",
                    value: 24
                }
            },
            dayOffs: [],
            excludeTime: {
                label: '00:00',
                value: '0'
            }
        })
    }

    const onSave = async () => {
        setLoading(true)
        try {
            const batch = db.batch()
            data.updatedAt = moment().format()
            const displaySettingDocRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/settings/${data.id}`)
            batch.set(displaySettingDocRef, data, {merge: true})
            await batch.commit()
            msgSuccess(getIntlId('toast.success.calendar.saved'))
        } catch (e) {
            console.log(e.toString())
        } finally {
            onClose()
        }
    }

    const handleChange = (label) => {
        let hiddenDays = data.hiddenDays
        let idx = findIndex(hiddenDays, hidden => hidden === label)
        if (idx !== -1) {
            remove(hiddenDays, hidden => hidden === label)
            data.hiddenDays = hiddenDays
            setData({...data})
        } else {
            if (hiddenDays.length === 6)
                return
            let nextValue = [...hiddenDays]
            nextValue.push(label)
            data.hiddenDays = nextValue
            setData({...data})
        }
    }

    const onRemove = (value, index) => {
        let dayOffs = data.dayOffs || []
        remove(dayOffs, dayOffs[index])
        data.dayOffs = dayOffs
        setData({...data})
    }
    if (!eventUpdate)
        return null

    return (
        <Dialog
            open={Boolean(eventUpdate)}
            onClose={onClose}
            className={classes.dialogAddSetting}
        >
            <div className={classes.dialogTitleAdd}>
                <span>{updateRangeText}</span>
            </div>
            <div className={classes.divFlexBox}>
                <div className={classes.divTimeAdjustment}>
                    <div className={classes.addDialogLabel}>{dateRange}</div>
                    <div
                        className={classes.timeAdjustment}
                        onClick={() => {
                            setOpenTime(true)
                        }}>
                        <span>{moment(data.startDay).format("YYYY/MM/DD")}</span>
                        <PolygonSVG className={`${classes.iconTo2} ${classes.iconTo2PolySVG}`}/>
                        <span>{data.endDay ? moment(data.endDay).format("YYYY/MM/DD") : infiniteIntl}</span>
                    </div>
                </div>
                <div className={classes.divTimeAdjustment}>
                    <div className={classes.addDialogLabel}>{operationTimeText}</div>
                    <div className={classes.divFlexCol}>
                        <TextSelectFrom dataTime={data} setDataTime={setData}/>
                        <PolygonSVG classes={clx} className={`${classes.iconTo2} ${classes.iconTo2PolySVG}`}/>
                        <TextSelectTo dataTime={data} setDataTime={setData}/>
                    </div>
                </div>
                <div className={clx.excludeDay}>
                    <div className={clx.excludeDayTitle}>{excludeDayTimeText}</div>
                    <ExcludeDayTime dataTime={data} setDataTime={setData} classes={clx}/>
                </div>
            </div>
            <WeekDay handleChange={handleChange} hiddenDays={data.hiddenDays}/>
            <div className={classes.divTimeCancel} style={{borderRadius: 15, padding: 15}}>
                <div className={classes.cancelLabel}>{nonWorkingDayText}</div>
                <div className={classes.listTimeCancel}>
                    {
                        data?.dayOffs.map((value, index) => {
                            return (
                                <div key={index} className={classes.divTimeCancelValue}
                                     onClick={() => onRemove(value, index)}>
                                    {moment(value.start).day() === moment(value.end).day() ? moment(value.start).format('MM/DD') : `${moment(value.start).format('MM/DD')} ~ ${moment(value.end).format('MM/DD')}`}
                                    <img className={classes.iconCloseSmall}
                                         src={publicIcon('closeSmall.svg')}
                                         alt=""/>
                                </div>
                            )
                        })
                    }
                    <Tooltip title={addDayOffText}>
                        <IconButton
                            className={classes.addDayOff}
                            onClick={(e) => {
                                setOpenTime(true);
                                setOpenDayOff(true)
                            }}
                        >
                            <AddSVG className={classes.addIconSVGTimeSetting}/>
                            <img src={process.env.PUBLIC_URL + '/icons/add.svg'} alt=""/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className={'clf-flex-center'}>
                <CancelButton
                    onClick={onClose}
                    disable={loading}
                />
                <SaveButton
                    onClick={onSave}
                    disable={check}
                    loading={loading}
                />
            </div>
        </Dialog>
    );
}

export default DialogUpdateDisplaySetting
