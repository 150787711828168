import axios from 'axios';
import {refreshToken} from '../actions/user';
import {includes} from 'lodash';
import localToken from '../common/localToken';
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie} from '../common/cookies';

const axiosConfig = () => {
    //REQUEST
    axios.interceptors.request.use(
        (config) => {
            // const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (includes(config.url, "https://asia-northeast1-geniam-c8d4c.cloudfunctions.net"))
                return config
            const {token} = localToken();
            if (token) {
                if (includes(config.url, 'https://www.googleapis.com')) {
                    // if (!includes(config.url, CALENDAR_API_KEY)) {
                    //     const googleAccessToken = localStorage.getItem('googleAccessToken')
                    //     config.headers['Authorization'] = `Bearer ${googleAccessToken}`;
                    // }
                } else {
                    config.headers['Authorization'] = `Bearer ${token}`;
                    // config.headers['Strict-Transport-Security'] = `max-age=31556926 ; includeSubDomains`;
                    // config.headers['X-Frame-Options'] = `SAMEORIGIN`;
                    // config.headers['X-Content-Type-Options'] = `nosniff`;
                    // config.headers['X-Permitted-Cross-Domain-Policies'] = `none`;
                    // config.headers['X-XSS-Protection'] = `1; mode=block`;
                }
            }
            return config;
        },
        error => {
            if (error && error.request) {
                console.log(error);
                console.log(error.request);
                // toast.error(<ToastFormat error={error}/>)
            }
            return Promise.reject(error);
        });

    //RESPONSE
    let refreshTriedTimes = 0
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            // const query = qs.parse(window.location.search);
            if (!error.response) {
                // toast.error(<ToastFormat error={error}/>)
                return Promise.reject(error)
            }

            const {status, data: {error: customError, error_code, error_message}} = error.response;
            if (
                (status === 400 && error_code === 'NotAuthorizedException')
                || (status === 401 && customError === 'login required')
            ) {
                if (
                    [
                        'Invalid Refresh Token',
                        'Refresh Token has been revoked',
                        'Refresh Token has expired'
                    ].includes(error_message)
                ) {
                    removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
                    removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)

                    // window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
                    return Promise.reject(error)
                }
                const data = await refreshToken()
                ++refreshTriedTimes;
                if (data && refreshTriedTimes < 3) {
                    originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
                    return axios(originalRequest)
                } else {
                    return Promise.reject(error)
                }

            }
            return Promise.reject(error);
        }
    );
}

export default axiosConfig;

// function ToastFormat({error}) {
//     const {url} = error.config
//     const msg = error.toString()
//     return (
//         <div>
//             <div>
//                 {url}
//             </div>
//             <div>{msg}</div>
//         </div>
//     )
// }
