import {getGlobal, setGlobal} from "reactn";
import {findIndex} from "lodash";
import {snapshot} from 'valtio';
import {eventsProxy} from '../../../store/proxy/events.proxy';

export default function setScheduleAdjustmentOfEvent(event) {

    const {voteEvents} = snapshot(eventsProxy)
    let idx = findIndex(voteEvents || [], {eventId: event.id})
    if (idx !== -1) {
        setGlobal({
            scheduleAdjustment: {
                ...voteEvents[idx],
                googleEvent: event.googleEvent,
                project_uuid: event.project_uuid,
                start: event.start,
                end: event.end,
                oldInfo: {
                    start: voteEvents[idx].start,
                    end: voteEvents[idx].end
                },
            }
        });
    }

}
