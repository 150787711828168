import {getGlobal} from 'reactn';
import {ggTokenChecked} from '../config/auth';
import {getDateRangeOfCal} from '../context/common/getSelectedEvents';
import moment from 'moment';
import {headerHeight} from '../components/Calendar/handlers/initCal';
import {snapshot} from 'valtio';
import {themesProxy} from '../store/proxy/themes.proxy';

export const RefetchGG = async () => {
    const calendar = getNumOfCals()
    await ggTokenChecked()
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById('google')
            if (source)
                source.refetch()
        }
    }
    // getNumOfProject()
}

export const RefetchOneGG = (i) => {
    if (window['calendar' + i] && window['calendar' + i].view) {
        let source = window['calendar' + i].getEventSourceById('google')
        if (source)
            source.refetch()
    }
}
export const RefetchGeniam = () => {
    const calendar = getNumOfCals()
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById('geniam')
            if (source)
                source.refetch()
        }
    }
}

export const refetchOne = (i) => {
    if (window['calendar' + i] && window['calendar' + i].view) {
        window['calendar' + i].refetchEvents()
    }
}

export const refetchAll = async () => {
    const calendar = getNumOfCals()
    await ggTokenChecked()
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            window['calendar' + i].refetchEvents()
        }
    }
    // getNumOfProject()
}

export const refetchOneSource = (name, i) => {
    if (name && window['calendar' + i] && window['calendar' + i].view) {
        let source = window['calendar' + i].getEventSourceById(name)
        if (source)
            source.refetch()
    }
}
const delay = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

export const refetchSource = async (name) => {
    let calendar = getNumOfCals()
    // if (name === "google") {
    //     await ggTokenChecked()
    // }
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            await delay(50 * i)
            let source = window['calendar' + i].getEventSourceById(name)
            if (source) {
                source.refetch()
                headerHeight(i)
            }

        }
    }
}

const getNumOfCals = () => {
    const {currentTheme} = snapshot(themesProxy)
    let numOfCal = 2
    if (currentTheme) {
        const {rows, columns} = currentTheme
        if (rows && columns) {
            numOfCal = rows * columns
        }
    }
    return numOfCal
}
export const addEventSource = (event) => {
    const sourceName = (event.is_google || event.googleEvent) ? 'google' : ((event.isEventVote && !event.project_uuid) ? "vote" : 'geniam')
    const {refetchDateNow} = getGlobal()
    let numOfCal = getNumOfCals()

    for (let i = 0; i < numOfCal; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById(sourceName)
            let {
                start,
                end
            } = window['calendar' + i]?.currentData?.dateProfile?.currentRange || getDateRangeOfCal(refetchDateNow, i)
            start = moment(start).startOf("day").format()
            end = moment(end).endOf("day").format()
            if (moment(event.start).isBetween(start, end, null, "[]") ||
                moment(event.end).isBetween(start, end, null, "[]") ||
                (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))
            ) {
                window['calendar' + i].addEvent(event, source)
            }
        }
    }
}
export const addOneCalendarEventSource = (event, numberId) => {
    const sourceName = (event.is_google || event.googleEvent) ? 'google' : ((event.isEventVote && !event.project_uuid) ? "vote" : 'geniam')
    const calendar = window['calendar' + numberId]
    if (calendar && calendar.view) {
        let source = calendar.getEventSourceById(sourceName)
        calendar.addEvent(event, source)
    }
}
export const removeEvent = id => {
    if (!id)
        return
    let numOfCal = getNumOfCals()
    for (let i = 0; i < numOfCal; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            const event = window['calendar' + i].getEventById(id)
            if (event)
                event.remove()
        }
    }
}


export const removeAllEvents = () => {
    const {allMilestoneProject, projectSortSetting} = getGlobal()
    let calendar = getNumOfCals()
    if (window.location.pathname === '/mileStone' && projectSortSetting.isSort)
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            window['calendar' + i].removeAllEvents()
        }
    }
}
export const refetchAllEvents = () => {
    const {allMilestoneProject, projectSortSetting} = getGlobal()
    let calendar = getNumOfCals()
    if (window.location.pathname === '/mileStone' && projectSortSetting.isSort)
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)

    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            window['calendar' + i].refetchEvents()
        }
    }
}
