import React, {useEffect} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom'
import Home from './Page/Home'
import 'moment/locale/ja'
import usePushNotifications from "./hooks/usePushNotifications";
import Search from "./Page/Search"
import NotFound from "./components/NotFound/NotFound";
import useGoogleCalendarWatch2 from "./hooks/useGoogleCalendarWatch2";
import {getEventsSync} from "./context/common/getEventsSync";
import "./common/disableCtrlKey"
import {IS_PRODUCTION} from "./config/constants";
import useGoogleEmailWatch from './hooks/useGoogleEmailWatch';
import TutorialTotal from './components/Tutorial/TutorialTotal';
import {useUserData} from './hooks/useUser';
import {snapshot} from 'valtio';
import {settingDialogProxy} from './store/proxy/settingDialog.proxy';
import {CtrlKeyStateProxy} from './store/proxy/CtrlKeyState.proxy';

function App() {

    useUserData()
    usePushNotifications();
    useGoogleEmailWatch()
    useGoogleCalendarWatch2((data) => {
        getEventsSync(data.email)
    })

    useEffect(() => {
        const openSetting = window.location.search
        if (openSetting.includes('is_open_setting')) {
            const {handleOpen} = snapshot(settingDialogProxy)
            handleOpen(11)
        }
        document.title = IS_PRODUCTION ? 'CalenView' : `[${process.env.REACT_APP_ENV}] CalenView`

    }, [])

    useEffect(() => {
        document.addEventListener('keydown', ev => {
            if (ev.key === 'Control') {
                const {setCtrlKeyState} = snapshot(CtrlKeyStateProxy)
                setCtrlKeyState(true)
            }
        })
        document.addEventListener('keyup', ev => {
            const {setCtrlKeyState} = snapshot(CtrlKeyStateProxy)
            setCtrlKeyState(false)
        })
        return () => {
            document.removeEventListener('keydown', ev => {

            })
            document.removeEventListener('keyup', ev => {

            })
        }
    }, [])

    return (
        <div className="App">
            <TutorialTotal/>
            <Switch>
                <Route exact path={'/'} component={Home}/>
                <Route exact path={'/settings/zoomAuth'} component={Home}/>
                <Route path={'/search'} component={Search}/>
                <Route component={NotFound}/>
            </Switch>
        </div>
    )
}

export default React.memo(App)
