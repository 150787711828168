import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {makeStyles} from "@material-ui/core/styles";
import DialogCustom from "../../Custom/DialogCustom";
import ListIconSearch from "./ListIconSearch";
import Tab from "@material-ui/core/Tab";
import TabsCustom from "../../Custom/TabsCustom";
import {Button, Input, Upload,} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {storage} from '../../../config/firebaseConfig'
import CancelButton from "../../Dialog/CancelButton";
import SaveButton from "../../Dialog/SaveButton";
import useIntlId, {getIntlId} from '../../../hooks/useIntlId';
import {msgError} from '../../../utils/msg';
import {trim} from 'lodash'
import {useSnapshot} from 'valtio';
import {userProxy} from '../../../store/proxy/user.proxy';

function SelectIconsDialog({onAdd, loading, item, setItem}) {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [icon, setIcon] = useState('')
    const [, setIndex] = useState(null)
    const [tab, setTab] = useState(0)
    const [openAddTask, setOpenAddTask] = useGlobal('openAddTask')
    const {user} = useSnapshot(userProxy)
    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [, setOpenSetting] = useGlobal('openSetting')
    const [editInfo] = useGlobal('editInfo');

    const [updateIconIntl, addIconIntl, selectIconIntl, upLoadImageIntl, nameIntl, clickToUploadIntl] =
        useIntlId(['event.setting.uploadTaskIcon', 'event.setting.addTaskIcon', 'icon.select',
            'icon.Upload', 'icon.name', 'icon.clickToUpload'])
    useEffect(() => {
        setName(item?.name)
        setIcon(item?.icon)
        setIndex(item?.index)
        if (item?.type === "file") {
            setTab(1)
            setFile(item?.file || null)
        }
    }, [item])
    const handleClose = () => {
        setOpenAddTask(false)
        setItem({})
        if (editInfo) {
            setOpenSetting(false)
        }
    }
    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    }
    const handleUpload = async file => {
        try {

            if (!user.user_id)
                return
            if (!file.type || !file.type?.includes("image/")) {
                msgError(getIntlId('event.toast.notImageFile'))
                return
            }

            setUploading(true)
            const fileName = `${new Date().getTime()}_${file.name}`
            const ref = storage.ref().child(`images/${user.user_id}/tasks/${fileName}`)
            const snap = await ref.put(file)
            const url = await snap.ref.getDownloadURL()
            setFile({
                id: file.uid,
                status: "done",
                url,
                name: fileName
            })
        } catch (e) {

        } finally {
            setUploading(false)
        }
    }
    const fileList = file ? [file] : []
    return (
        <DialogCustom
            open={openAddTask}
            onClose={handleClose}
            title={item?.name !== undefined ? updateIconIntl : addIconIntl}
            fullWidth={true}
            maxWidth={'xl'}
            actions={<React.Fragment>
                <CancelButton
                    onClick={handleClose}
                />
                <SaveButton
                    onClick={() => {
                        if (onAdd && typeof onAdd === "function" && item?.name === undefined) {
                            onAdd({name, icon})
                            if (tab === 1)
                                return onAdd({name, type: "file", file})
                            onAdd({name, icon, type: "icon"})
                        }
                    }}
                    loading={loading}
                    disable={loading || (tab === 0 ? (trim(name) === '' || trim(icon) === '') : (trim(name) === '' || file == null))}
                />
            </React.Fragment>}
        >
            <TabsCustom value={tab}
                        onChange={handleChangeTab}
                        className={classes.tabsBox}
            >
                <Tab label={selectIconIntl} className={classes.tab}/>
                <Tab label={upLoadImageIntl} className={classes.tab}/>
            </TabsCustom>
            <div className={classes.root}>
                <div style={{display: tab === 0 ? 'block' : 'none'}}>

                    <Input
                        className={classes.input}
                        onChange={e => setName(e.target.value)}
                        value={name}
                        autoFocus={true}
                        placeholder={nameIntl}
                    />

                    <ListIconSearch icon={icon} setIcon={setIcon}/>
                </div>
                <div style={{display: tab === 1 ? 'block' : 'none'}}>
                    <div className={classes.uploadBox}>

                        <Upload
                            name={"file"}
                            action={handleUpload}
                            listType="picture-card"
                            fileList={fileList}
                            maxCount={1}
                            onRemove={file => {
                                setFile(null)
                            }}
                            onChange={info => {
                                if (info.file.type || !info.file.type?.includes("image/")) {
                                    info.fileList = []
                                }
                            }}
                        >
                            {!file && !uploading ?
                                <Button icon={<UploadOutlined/>}>{clickToUploadIntl}</Button>
                                : null}

                        </Upload>

                    </div>

                    <Input
                        className={classes.input}
                        onChange={e => setName(e.target.value)}
                        value={name}
                        autoFocus={true}
                        placeholder={nameIntl}
                    />

                </div>
            </div>
        </DialogCustom>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: "10px 0",
        minHeight: 455,
    },
    tabsBox: {
        width: '100%',

        background: "#f1f1f1"
    },
    tab: {
        // background: "#fff",
        width: "50%"
    },
    fieldName: {
        backgroundColor: "#F1F1F1",
        borderRadius: "50px",
        padding: "5px",
        marginBottom: 10,
        "& input": {
            border: "none",
            outline: "none !important",
            background: "#fff",
            height: "32px",
            width: "100%",
            borderRadius: "50px",
            padding: "0 10px",
            color: "#000",
            fontSize: 14,
            // fontFamily: 'Montserrat'
        }
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`
    },
    uploadBox: {
        width: 150,
        margin: "auto"
    },
    input: {
        borderRadius: 15,
        height: 41
    }

}))
export default SelectIconsDialog;
