import React, {useEffect, useState} from 'react';
import {Dialog, Fade, IconButton, Typography} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import moment from 'moment-timezone';
import momentTz from 'moment-timezone';
import {withStyles} from '@material-ui/core/styles';
import {newEvent, updateEvent} from '../../actions/calendar';
import Popover from '@material-ui/core/Popover';
import {
    AddGoogleEvent,
    changeProjectGoogleEvent,
    GCalendarEventFormat,
    getAllPrj,
    getGeniamEventsInstance,
    getGoogleEventsInstance,
    updateGoogleEvent
} from '../../actions/googleCalendar';
import {setGlobal, useGlobal} from 'reactn';
import TagList from './popup/TagList';
import ProjectList from './popup/ProjectList';
import IdentityList from './popup/identity';
import TimeSelectComponent from './popup/TimeSelectComponent'
import AllDaySelectComponent from './popup/AllDaySelectComponent';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import useDebounce from 'react-use/lib/useDebounce';
import {concat, findIndex, isEqual, orderBy, remove, trim} from 'lodash';
import DeleteEvent from './DeleteEvent';
import Creator from './popup/Creator';
import {addEventSource, refetchAll, refetchOne, refetchSource, removeEvent} from '../../actions/refetchSourcce';
import {API_GENIAM, API_ZOOM, CALENDAR_COLLECTION, METAMORS_REDIRECT} from '../../config/constants';
import {db} from '../../config/firebaseConfig';
import {updateMeetingEvent} from './actions/syncEventMeeting';
import {isVoteTime} from './actions/voteInfo';
import ConflictVoteTime from './WarningDialog/ConflictVoteTime';
import {getPosition} from '../../common/element';
import Memo from './popup/Memo';
import {
    getAnonymousCollRef,
    getDataAsync,
    getDocsAsync,
    getMeetingDocRef,
    getUsersCollRef,
    myVoteBoxDocRef,
    updateEventBoxMeetingRef,
    updateEventsRef,
} from '../../common/firebaseRef/meetings';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import {ggTokenChecked} from '../../config/auth';
import UrlProject from './UrlProject';
import LocationAutoComplete from './LocationAutoComplete';
import ZoomButton from '../ZoomButton/ZoomButton';
import {deleteZoom, handleGetValue, handleUpdateZoomMeeting} from '../../actions/zoom';
import useIntlId from '../../hooks/useIntlId';
import HangoutsMeet from '../ZoomButton/HangoutsMeet';
import {checkTimeTitleEvent} from '../../actions/googleTasks';
import Recurrent from './popup/recurrent';
import DeleteRecurring from './deleteRecurring';
import SaveButton from './SaveButton';
import axios from 'axios';
import setGlobalGGEventsWithDataAndInfo from './handlers/setGlobalGGEventsWithDataAndInfo';
import TitleEventRender from './TitleEventRender';
import {isSameDate} from '../../utils/time';
import {copyEvent} from './actions/copyEvent';
import {useBackSpace, useCtrlEnter, useCtrlShiftWithKey, useShiftWithKey} from 'use-hot-key-element';
import {msgError, msgSuccess} from '../../utils/msg';
import {conferenceDataJoinUrl, conferenceDataStartUrl, publicIcon} from './handlers/infoEvent';
import {MessageOutlined} from '@ant-design/icons';
import {v4 as uuidv4} from 'uuid';
import {myEventRef} from '../../common/firebaseRef/calendar';
import GuestEmail from './popup/GuestEmail';
import {sendMailChangeCalendar} from '../Mails/Actions';
import Reminders from './popup/reminders';
import SelectFreeTime from './popup/selectFreeTime';
import {useHistory} from 'react-router-dom';
import {useSnapshot} from 'valtio';
import {userProxy} from '../../store/proxy/user.proxy';
import {eventsProxy} from '../../store/proxy/events.proxy';
import {searchProxy} from '../../store/proxy/search.proxy';
import getUserTimeZone from '../../common/getUserTimeZone';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
})

function EditEvent({
                       editInfo,
                       setEditInfo,
                       events,
                       setEvents,
                       numberId,
                       classes,
                       setAcceptEvent,
                       setOpenChatting,
                       setIsEmailOnly
                   }) {
    const [event, setEvent] = useState(null)
    const [oldEvent, setOldEvent] = useState(null)
    const {user} = useSnapshot(userProxy)
    const {createEvent, deleteEvent} = useSnapshot(eventsProxy)
    // const {allProjects} = useSnapshot(projectsProxy)
    const {reloadEventsInSearchPage} = useSnapshot(searchProxy)
    const history = useHistory();
    const [openDelete, setOpenDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteRecurrence, setDeleteRecurrence] = useState(null)
    const [element, setElement] = useState(null)
    const [open, setOpen] = useState(false)
    const [oneMeeting, setOneMeeting] = useState(null);
    const [editTitle, setEditTitle] = useState(false)
    const [loadZoom, setLoadZoom] = useState(false)
    const [click, setClick] = useState(null)
    const zone = getUserTimeZone()
    const [titleEvent, savedIntl, savedAndCopiedIntl, savedAndCopiedZoomIntl, locationIntl, createZoomErrorIntl, updateErrorIntl, changeErrorIntl, editEventMetamorsIntl] = useIntlId(
        [
            'create.titleEvent',
            'toast.success.calendar.edit',
            'toast.success.calendar.savedAndCopied',
            'toast.success.calendar.savedAndCopiedZoom',
            'create.location',
            'event.toast.createZoomError',
            'event.toast.updateEventError',
            'event.toast.changeEventError',
            'message.edit.eventMetamors',
        ]
    )
    const [isDeletePressed, setIsDeletePressed] = useBackSpace('#hotkey-del-key')
    const [isHKSaved] = useCtrlEnter('#hotkey-ctrlEnter')
    const [isHKCopySaved] = useShiftWithKey('enter', '#hotkey-ctrlEnter')
    const [isHKZoomSaved] = useCtrlShiftWithKey('enter', '#hotkey-ctrlEnter')
    const ContainerEdit = !editInfo?.el ? Dialog : Popover
    const linkZoom = conferenceDataJoinUrl(event)
    const [zoomOAuth] = useGlobal('zoomOAuth');
    useEffect(() => {
        if (!openDelete) {
            setIsDeletePressed(false)
        }
        if (isDeletePressed) onOpenDelete()
        // eslint-disable-next-line
    }, [isDeletePressed, openDelete])

    useEffect(() => {
        if (isHKCopySaved) onCopy();
        // eslint-disable-next-line
    }, [isHKCopySaved])

    useEffect(() => {
        if (isHKZoomSaved) zoomUrl();
        // eslint-disable-next-line
    }, [isHKZoomSaved])

    useEffect(() => {
        // console.log(editInfo)
        if (editInfo?.event) {
            let {event, el} = editInfo
            setEvent(event)
            setOldEvent(event)
            if (el)
                setElement(getPosition(el))
            if (event.isEventVote) {
                getDataGroupEvent(event).then((data) => {
                    if (!data)
                        return;
                    event.message = data.message || '';
                    event.users = data.users || [];
                    event.members = data.members || [];
                    if (!event.project_uuid) {
                        let idx = findIndex(data.users, {id: user.user_id})
                        if (idx !== -1 && data.users[idx]?.project?.id)
                            event.project_uuid = data.users[idx].project.id
                    }
                    setEvent({...event});
                    // setOldEvent(event)
                }).catch(e => {
                    console.log(e.toString())
                })
            }
            if (event.name && event.email) {
                setOneMeeting({
                    name: event.name,
                    email: event.email
                })
            }
        } else {
            setEvent(null)
            setElement(null)
            setOneMeeting(null)
        }
        // eslint-disable-next-line
    }, [editInfo])
    useDebounce(
        () => {
            // console.log({event, oldEvent});
            if (window['calendar' + numberId] && event && !event.is_holiday) {
                removeEvent(event.id)
                addEventSource(event)
            }
        },
        300,
        [event]
    )
    useEffect(() => {
        // console.log({hotkeyEvent})
        if (!editInfo) return;
        const {event} = editInfo;
        if (isHKSaved && event) {
            if (event.title && event.project_uuid) {
                onUpdate()
            } else {
                msgError('title & project Required !')
            }
        }
        // eslint-disable-next-line
    }, [isHKSaved, editInfo])

    const getDataGroupEvent = async (event) => {
        const {meetingId} = event;
        if (!meetingId)
            return
        setLoading(true)
        try {
            const meetDocRef = getMeetingDocRef(meetingId);
            const userCollRef = getUsersCollRef(meetingId)
            const anonymousCollRef = getAnonymousCollRef(meetingId)
            const [{message, members}, userData, anonymousList] = await Promise.all([
                getDataAsync(meetDocRef),
                getDocsAsync(userCollRef),
                getDocsAsync(anonymousCollRef)
            ])
            // try get new avatar. if error do no thing
            try {
                const userIds = userData.map(u => u.id)
                const res = await axios.post(`${API_GENIAM}/getGroupUserAvatar`, {
                    userIds
                })
                members.forEach(mem => {
                    let idx = findIndex(res?.data || [], {user_id: mem.id})
                    if (idx !== -1) {
                        mem.avatar = res.data[idx]?.avatar || ''
                    }
                })
            } catch (e) {

            }

            const users = orderBy(concat(userData, anonymousList), 'joined', 'asc')

            setLoading(false)
            return {message, users, members: concat(members, anonymousList)}
        } catch (e) {
            console.log(e.toString())
            setLoading(false)
            return null
        }
    }
    const onChangeSuccess = () => {
        setEvent({...event, isSuccess: !event.isSuccess})
    }
    const handleClose = (force = false) => {
        if (event.zoomMeetingId && !oldEvent.zoomMeetingId)
            deleteZoom(event.zoomMeetingId).then()
        if (window['calendar' + numberId]) {
            if (!event.is_holiday && !force) {
                removeEvent(event.id)
                addEventSource(oldEvent)
            }
        }
        setEvent(null)
        setEditInfo(null)
        setElement(null)
    }
    const isMoved = () => {
        let allProjects = getAllPrj()
        let idex = findIndex(allProjects, {'id': event.project_uuid})
        let old_idx = findIndex(allProjects, {'id': oldEvent.project_uuid})
        if ((idex !== -1) && (old_idx !== -1)) {
            return Boolean(allProjects[idex].is_google) !== Boolean(allProjects[old_idx].is_google);

        }
        return false
    }
    const isIgnoreTime = (oldEvent, event) => {
        return moment(event.start).isSame(oldEvent.start) && moment(event.end).isSame(oldEvent.end)
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (trim(e.target.value) === "") {
                setEvent({
                    ...event,
                    title: editInfo.event.title
                })
            }
            setEditTitle(false)
            return
        }
        if (e.key === "Escape") {
            setEditTitle(false)
        }
    }
    const handleBlur = (e) => {
        if (trim(e.target.value) === "") {
            setEvent({
                ...event,
                title: editInfo.event.title
            })
        }
        setEditTitle(false)
    }
    const onCopy = async () => {
        setEditInfo(null)
        // if (isVoteTime(event.start, event.end)) {
        //     setClick("copy")
        //     return setOpen(true)
        // }
        // console.log(event)
        copyEvent(event)
        if (!isEqual(event, oldEvent)) {
            await onUpdate()
        }

        await reloadEventsInSearchPage(history.location);

        msgSuccess(savedAndCopiedIntl)
    }

    const updateEventVote = async (eventData, oldEvent,) => {
        let meetingZoom = handleGetValue(event) || event?.linkZoom;
        if (!eventData.project_uuid) {
            await updateMeetingEvent(eventData)
            if (meetingZoom && checkTimeTitleEvent(eventData, oldEvent)) {
                handleUpdateZoomMeeting(moment(eventData.start).tz(zone).format(), moment(eventData.end).tz(zone).format(), meetingZoom, user.user_id, eventData.title)
                    .catch(e => console.log(e))
            }
            refetchSource('vote')
            return
        }

        // sync to project
        await myVoteBoxDocRef(eventData)
            .set({isDeleted: true}, {merge: true})
        ///1. google project
        if (eventData.is_google || eventData.googleEvent) {
            const data = await AddGoogleEvent(eventData)
            removeEvent(event.id)
            if (!data) {
                // error
                refetchAll()
                msgError("CREATE EVENT ERROR. TRY AGAIN LATER")
                await myVoteBoxDocRef(eventData)
                    .set({isDeleted: false}, {merge: true})
                return
            }
            if (meetingZoom && checkTimeTitleEvent(eventData, oldEvent)) {
                handleUpdateZoomMeeting(moment(eventData.start).tz(zone).format(), moment(eventData.end).tz(zone).format(), meetingZoom, user.user_id, eventData.title)
                    .catch(e => console.log(e))
            }
            createEvent({...data, googleEvent: true, project_uuid: event.project_uuid}, () => {
                refetchAll()
            })
            return
        }
        //2. geniam event
        await newEvent({...eventData, id: uuidv4()})
        if (meetingZoom && checkTimeTitleEvent(eventData, oldEvent)) {
            handleUpdateZoomMeeting(moment(eventData.start).tz(zone).format(), moment(eventData.end).tz(zone).format(), meetingZoom, user.user_id, eventData.title)
                .catch(e => console.log(e))
        }
        refetchAll()
    }

    const updateEventIdEventBoxMeeting = async (eventData, newEvent) => {
        if (eventData?.id === newEvent?.id || !eventData?.isEventVote) return null
        const eventSnap = await db.collection(CALENDAR_COLLECTION)
            .doc(user?.user_id)
            .collection('eventBoxMeeting')
            .where("eventId", "==", eventData.id)
            .get()
        if (!eventSnap.size) return null;
        const events = eventSnap.docs.map(doc => doc.id).filter(x => x)
        // console.log(events)
        const batch = db.batch()
        events.forEach(id => {
            const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/eventBoxMeeting/${id}`)
            batch.set(ref, {eventId: newEvent.id}, {merge: true})
        })
        await batch.commit()
    }

    const moveInstance = async (eventData, oldEvent, ignoreTime) => {
        if (oldEvent.is_google) {
            //1. move from google to geniam
            //1.1. get all data of instance
            const items = await getGoogleEventsInstance({
                project: oldEvent.project_uuid,
                iCalUID: oldEvent.iCalUID
            })
            //1.2 delete google event instance
            const request = window.gapi.client.calendar.events.delete({
                "calendarId": oldEvent.project_uuid,
                "eventId": eventData.recurringEventId
            })
            await request.then()
            //1.3 write data to geniam calendar
            const batch = db.batch()
            const deleted = remove(items, i => i.status === 'cancelled')
            const formated = GCalendarEventFormat(items, eventData.project_uuid, eventData.borderColor)
            deleteEvent(oldEvent, () => {
                refetchSource('google')
            }, (item => item.id === oldEvent.recurringEventId || oldEvent.recurringEventId === item.recurringEventId))
            concat(formated, deleted).forEach(evt => {
                const ref = myEventRef(evt.id)
                const data = {
                    ...evt, isDeleted: false, extendedProperties: null, isTasks: false, googleEvent: false,
                    project_uuid: eventData.project_uuid,
                    task: eventData.task || '',
                    colorId: eventData.colorId || null,
                    backgroundColor: eventData.backgroundColor || null,
                    title: eventData.title || '',
                    description: eventData.description || '',
                    location: eventData.location || '',
                }

                if (evt.recurrence) {
                    data.start = moment(eventData.start).utc().format()
                    data.end = moment(eventData.end).utc().format()
                }

                if (!evt.isEventVote && !evt?.creator?.email) {
                    data.creator = {email: user.email}
                }
                batch.set(ref, data)
            })
            await batch.commit()
            refetchOne(window['i'])
            return;
        }
        //2. move from geniam to google
        //2.1 get all instance
        const result = await getGeniamEventsInstance({
            eventId: eventData.recurringEventId
        })
        if (!result)
            throw Error("failed to load data try again later")
        //2.2 delete from geniam
        const eventRef = myEventRef(eventData.recurringEventId)
        await eventRef.set({
            isDeleted: true
        }, {merge: true})

        //2.3 add to google
        const {data, edited} = result
        const rootEvt = await AddGoogleEvent({
            ...data, project_uuid: eventData.project_uuid, task: eventData.task || '',
            colorId: eventData.colorId || null,
            title: eventData.title || '',
            description: eventData.description || '',
            location: eventData.location || '',
        })
        if (edited?.length && ignoreTime) {
            await Promise.all(edited.map(async item => {
                const ids = item.id.split("_")
                ids[0] = rootEvt.id
                const id = ids.join("_")
                if (item.status === 'cancelled' || item.status === 'canceled') {
                    //delete
                    const request = window.gapi.client.calendar.events.delete({
                        "calendarId": eventData.project_uuid,
                        "eventId": id
                    })
                    await request.then()
                } else {
                    // update
                    await updateGoogleEvent({
                        ...item, project_uuid: eventData.project_uuid, task: eventData.task || '',
                        colorId: eventData.colorId || null,
                        title: eventData.title || '',
                        description: eventData.description || '',
                        location: eventData.location || '',
                    }, id)
                }
            }))
        }
        refetchOne(window['i'])
    }
    const onUpdate = async (eventUpdate = null, close = false, isRemoveZoom = false) => {
        let eventData = event;
        if (eventUpdate)
            eventData = eventUpdate;

        if (eventData.is_holiday) {
            // read only event
            setEditInfo(null)
            return
        }
        let ignoreTime = isIgnoreTime(oldEvent, eventData)
        try {
            setLoading(true)
            // local update
            let meetingZoom = handleGetValue(event) || event?.linkZoom;
            if (isMoved()) {
                // move beetwen geniam and google calencar
                if (eventData?.recurringEventId) {
                    // update for new instance moved
                    const duration = moment(eventData.end).diff(eventData.start, eventData.allDay ? "days" : "hours", true)
                    const start = moment(eventData.originStart.start).set({
                        hours: moment(eventData.start).hours(),
                        minutes: moment(eventData.start).minutes(),
                        seconds: 0
                    }).format()
                    const end = moment(start).add(duration, eventData.allDay ? "days" : "hours").format()
                    eventData.start = start
                    eventData.end = end
                    setLoading(false)
                    await moveInstance(eventData, oldEvent, ignoreTime)
                    if (!close) {
                        setEditInfo(null)
                    }
                    return
                }
                if (oldEvent.is_google) {
                    // google to geniam calendar
                    await ggTokenChecked()
                    const batch = window.gapi.client.newBatch()
                    const request = window.gapi.client.calendar.events.delete({
                        "calendarId": oldEvent.project_uuid,
                        "eventId": eventData.id
                    })
                    batch.add(request)
                    await batch.then()

                    delete eventData.is_google
                    delete eventData.googleEvent
                    const newEventData = await newEvent(eventData)
                    // console.log({newEventData, eventData})
                    updateEventIdEventBoxMeeting(eventData, newEventData).then()

                    setLoading(false)
                    if (!close) {
                        setEditInfo(null)
                    }
                    return
                }

                const eventId = eventData.recurringEventId ? eventData.recurringEventId : eventData.id;

                await updateEventsRef(user.user_id, eventId, {isDeleted: true});

                await updateEventBoxMeetingRef(user.user_id, eventId, {isDeleted: true});

                const newEventData = await AddGoogleEvent(eventData)

                updateEventIdEventBoxMeeting(eventData, newEventData).then()

                setLoading(false)
                if (!close) {
                    setEditInfo(null)
                }
                return
            }

            if (!oldEvent.project_uuid) {
                // not project select => vote with out sync
                await updateEventVote(eventData, oldEvent)
                return;
            }
            // sự kiện của google
            if (eventData.is_google) {
                await ggTokenChecked()
                // trường hợp lặp lại và không thay đổi project => mở dialog lựa chọn
                if (eventData.recurringEventId && eventData.recurrence && eventData.project_uuid === oldEvent.project_uuid) {
                    const allowUpdateAll = isSameDate(eventData.start, oldEvent.start) && isSameDate(eventData.end, oldEvent.end)
                    setGlobal({
                        recurringOpen: true,
                        recurringEvent: {
                            ...eventData,
                            isChangeRecurrence: oldEvent.recurrence !== eventData.recurrence,
                            oldRecurrence: oldEvent.recurrence,
                            allowAll: allowUpdateAll,
                            isStart: oldEvent.originStart.isStart,
                            ignoreTime,
                            isRemoveZoom
                        }
                    })
                    handleClose()
                    return
                }
                // trường hợp move sang project khác
                if (oldEvent.project_uuid && eventData.project_uuid !== oldEvent.project_uuid) {
                    // move
                    const request = window.gapi.client.calendar.events.move({
                        "calendarId": oldEvent.project_uuid,
                        "eventId": eventData?.recurringEventId ? eventData?.recurringEventId : eventData.id,
                        "destination": eventData.project_uuid
                    });
                    await request.then()
                    //local move
                    changeProjectGoogleEvent(oldEvent, oldEvent.project_uuid, eventData.project_uuid)
                    //sync again
                    await db.collection(CALENDAR_COLLECTION).doc(user.user_id)
                        .set({
                            updatedAt: moment().utc().format()
                        }, {merge: true})
                    // nếu move là event lặp lại
                    if (eventData?.recurringEventId) {
                        // update for new instance moved
                        const duration = moment(eventData.end).diff(eventData.start, eventData.allDay ? "days" : "hours", true)
                        const start = moment(eventData.originStart.start).set({
                            hours: moment(eventData.start).hours(),
                            minutes: moment(eventData.start).minutes(),
                            seconds: 0
                        }).format()
                        const end = moment(start).add(duration, eventData.allDay ? "days" : "hours").format()
                        // cập nhật các trường khác sau khi move
                        await updateGoogleEvent({...eventData, start, end}, eventData?.recurringEventId, ignoreTime)
                        setLoading(false)
                        if (!close) {
                            setEditInfo(null)
                        }
                        return
                    }
                    // cập nhật các trường khác sau khi move
                    await updateGoogleEvent(eventData, eventData?.id, ignoreTime)
                    return
                }
                // trường hợp event còn lại
                // update các trường
                if (oldEvent.recurringEventId && !eventData.recurrence) {
                    eventData.id = eventData.recurringEventId
                }
                const data = await updateGoogleEvent(eventData, eventData.id)
                if (!data) {
                    refetchSource('google')
                    return
                }
                setGlobalGGEventsWithDataAndInfo(data, eventData)
                if (meetingZoom && checkTimeTitleEvent(eventData, oldEvent)) {
                    handleUpdateZoomMeeting(moment(eventData.start).tz(zone).format(), moment(eventData.end).tz(zone).format(), meetingZoom, user.user_id, eventData.title)
                        .catch(e => console.log(e))
                }
                return;
            }
            // geniam event
            if (eventData.project_uuid) {
                // lặp lại => mở dialog submit
                if (eventData.recurringEventId && oldEvent.project_uuid && eventData.project_uuid === oldEvent.project_uuid) {
                    const allowUpdateAll = isSameDate(eventData.start, oldEvent.start) && isSameDate(eventData.end, oldEvent.end)
                    setGlobal({
                        recurringOpen: true,
                        recurringEvent: {
                            ...eventData,
                            isChangeRecurrence: oldEvent.recurrence !== eventData.recurrence,
                            oldRecurrence: oldEvent.recurrence,
                            allowAll: allowUpdateAll,
                            isStart: oldEvent.originStart.isStart,
                            ignoreTime,
                            isRemoveZoom
                        }
                    })
                    handleClose()
                    return
                }

                // chuyển project
                if (oldEvent.project_uuid && eventData.project_uuid !== oldEvent.project_uuid) {
                    // move event
                    // delete event in old project

                    if (eventData?.recurringEventId) {
                        // update for new instance moved
                        const duration = moment(eventData.end).diff(eventData.start, eventData.allDay ? "days" : "hours", true)
                        const start = moment(eventData.originStart.start).set({
                            hours: moment(eventData.start).hours(),
                            minutes: moment(eventData.start).minutes(),
                            seconds: 0
                        }).format()
                        const end = moment(start).add(duration, eventData.allDay ? "days" : "hours").format()
                        eventData.start = start
                        eventData.end = end
                        eventData.id = eventData?.recurringEventId
                        eventData.recurringEventId = null
                        delete eventData.originStart
                    }

                    const eventId = eventData.id;

                    await updateEventsRef(user.user_id, eventId, {isDeleted: true});

                    await updateEventBoxMeetingRef(user.user_id, eventId, {isDeleted: true});

                    // add event for new project
                    await newEvent(eventData)
                    if (ignoreTime) {
                        const editedSnap = await db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
                            .where("recurringEventId", "==", eventData.id)
                            .get()
                        const edited = editedSnap.docs.map(doc => ({...doc.data(), id: doc.id}))
                        if (edited.length) {
                            let dbBatch = db.batch()
                            edited.forEach(item => {
                                let itemRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${item.id}`)
                                dbBatch.set(itemRef, {
                                    ...item,
                                    originStart: null,
                                    project_uuid: event.project_uuid,
                                    borderColor: eventData.borderColor || '',
                                    backgroundColor: eventData.backgroundColor || "",
                                    colorId: eventData.colorId || null,
                                    task: eventData.task || ""
                                }, {merge: true})
                            })
                            await dbBatch.commit()
                        }

                    }
                    setLoading(false)
                    if (!close) {
                        setEditInfo(null)
                    }

                    if (meetingZoom && checkTimeTitleEvent(eventData, oldEvent)) {
                        handleUpdateZoomMeeting(moment(eventData.start).tz(zone).format(), moment(eventData.end).tz(zone).format(), meetingZoom, user.user_id, eventData.title)
                            .catch(e => console.log(e))
                    }
                    return
                }

                await updateEvent(eventData);
                if (meetingZoom && checkTimeTitleEvent(eventData, oldEvent)) {
                    handleUpdateZoomMeeting(moment(eventData.start).tz(zone).format(), moment(eventData.end).tz(zone).format(), meetingZoom, user.user_id, eventData.title)
                        .catch(e => {
                            console.log(e)
                            msgError(`${updateErrorIntl}`, {autoClose: 2000})
                        })
                }


            }


        } catch (e) {
            console.log(e);
            msgError(`${changeErrorIntl.replace('|', event.title)}`, {autoClose: 2000});
            setLoading(false);
            setEditInfo(null)
            refetchOne(window['i'])
        } finally {
            setLoading(false);
            setEditInfo(null)
        }
    };
    const saveClick = async () => {
        setEditInfo(null)
        let zoneUser = user?.time_zone || 'Asia/Tokyo';
        let oldEventEnd = momentTz(oldEvent?.end).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
        let oldEventStart = momentTz(oldEvent?.start).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
        let eventEnd = momentTz(event?.end).tz(zoneUser).format(" HH:mm YYYY-MM-DD")
        let eventStart = momentTz(event?.start).tz(zoneUser).format(" HH:mm YYYY-MM-DD")

        if (isVoteTime(event.start, event.end)) {
            setClick("save")
            return setOpen(true)
        }
        await onUpdate(null, false)
        if (oldEventEnd !== eventEnd || oldEventStart !== eventStart) {
            await sendMailChangeCalendar(event)
        }

        await reloadEventsInSearchPage(history.location);
        msgSuccess(savedIntl.replace('|', event.title));
    }
    const handleCreateZoomMeeting = async () => {
        try {
            setLoading(true);
            const {data} = await axios.post(API_ZOOM + '/create', {
                type: 'meetings',
                timeStart: moment(event.start).tz(zone).format(),
                timeEnd: moment(event.end).tz(zone).format(),
                schedule: {
                    ...event,
                    service_name: 'Calendar Meetings',
                    title: event.title,
                },
                timeZone: user?.time_zone || 'Asia/Tokyo'
            });
            if (data) {
                event.zoomMeetingId = data?.id || '';
                event.conferenceData = data;
                setEvent({...event});
                await onUpdate(null, false)
            }
        } catch (e) {
            // msgError(e?.response?.data || "Create zoom event error! Please try again")
            msgError(`${createZoomErrorIntl}`)

        } finally {
            setLoading(false)
        }
    };
    // const haveZoomUrl = Boolean(event?.linkZoom);
    const zoomUrl = async () => {
        setEditInfo(null)
        await setLoadZoom(true)
        if (!event) return setLoadZoom(false);
        if (isVoteTime(event.start, event.end)) {
            setClick("zoom")
            setLoadZoom(false)
            return setOpen(true)
        }
        if (conferenceDataStartUrl(event)) {
            setEditInfo(null)
            await onUpdate()
            await copyEvent(event)
            msgSuccess(savedAndCopiedZoomIntl)
            setLoadZoom(false)
            return
        }

        // create zoom link
        await handleCreateZoomMeeting()
        await copyEvent(event)

        await reloadEventsInSearchPage(history.location);

        msgSuccess(savedAndCopiedZoomIntl)
    }
    const onConflictVoteTimeDone = async () => {
        if (click === "save") {
            setClick(null)
            onUpdate()
            return
        }
        if (click === "zoom") {
            setClick(null)
            zoomUrl()
            return
        }
        if (click === "copy") {
            await copyEvent(event)
            setClick(null)
            onUpdate()
        }
    }
    const onOpenDelete = () => {
        if (!oldEvent || oldEvent?.is_holiday) {
            return;
        }
        if (oldEvent?.recurringEventId) {
            setDeleteRecurrence(oldEvent)
            return
        }
        setOpenDelete(true)
    }
    return (
        <div>
            <ContainerEdit
                open={!editInfo?.el ? Boolean(editInfo?.event) : Boolean(element)}
                anchorReference="anchorPosition"
                anchorPosition={element}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                // TransitionComponent={Transition}
                disableRestoreFocus
                className={classes.popover}
                disableEnforceFocus
                maxWidth={'lg'}
            >
                <span id={'hotkey-ctrlEnter'}/>
                <span id={'hotkey-del-key'}/>

                <IconButton disableRipple={true}
                            onClick={() => handleClose()}
                            className={classes.closeIcon}
                >
                    <img src={publicIcon('close.svg')} alt=""/>
                </IconButton>
                {
                    Boolean(editInfo) && event &&
                    <div className={classes.root}
                    >
                        <LoadingOverlay>
                            <Loader loading={loading} text={''}/>

                            <div className={classes.titleRow}>
                                <TitleEventRender
                                    onChangeSuccess={onChangeSuccess}
                                    setEvent={setEvent}
                                    event={event}
                                    handleBlur={handleBlur}
                                    setEditTitle={setEditTitle}
                                    titleEvent={titleEvent}
                                    handleKeyDown={handleKeyDown}
                                    editTitle={editTitle}
                                    editInfo={editInfo}
                                    setIsEmailOnly={setIsEmailOnly}
                                    setAcceptEvent={setAcceptEvent}
                                    setOpenChatting={setOpenChatting}
                                />
                                <div className={classes.alignRight}>
                                    <IconButton
                                        disableRipple={true}
                                        className={classes.btnDelete}
                                        onClick={onOpenDelete}
                                        disabled={event && event.is_holiday}
                                    >
                                        <Delete/>
                                    </IconButton>

                                </div>
                            </div>
                            {
                                event?.members &&
                                <div className={`${classes.element} ${classes.leftFlex}`}>
                                    <AvatarGroup max={10}>
                                        {
                                            event?.members.map((usr, index) => {
                                                return (
                                                    <Tooltip title={usr?.email} placement="top" key={index}>
                                                        <Avatar src={usr?.avatar}
                                                                className={usr?.isPublish ? classes.avatar : classes.avatar1}> {usr?.email?.[0]?.toUpperCase()}</Avatar>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </AvatarGroup>
                                </div>
                            }

                            <Creator
                                event={editInfo.event}
                                user={user}
                            />
                            {
                                oneMeeting ?
                                    <div className={classes.element}>
                                        <Typography className={classes.meetingOne}>
                                            Meeting with {oneMeeting?.name} - {oneMeeting?.email}
                                        </Typography>
                                    </div>
                                    :
                                    null
                            }
                            {Boolean(event?.isConnectMetamors) && (
                                <a
                                    href={`${METAMORS_REDIRECT}?isConnectMetamors=${event?.isConnectMetamors}&eventIdMetamors=${event?.eventIdMetamors}`}
                                    target='_blank'
                                    className={classes.linkMetamors}>
                                    {editEventMetamorsIntl ? editEventMetamorsIntl : "This is an event in metamors, you can go to metamors to edit"}
                                </a>
                            )}
                            <AllDaySelectComponent
                                event={event}
                                setEvent={setEvent}
                                focus={false}
                                oldEvent={oldEvent}

                            />

                            <div className={classes.element}>
                                <TimeSelectComponent
                                    event={event}
                                    setEvent={setEvent}
                                />
                            </div>
                            <SelectFreeTime event={event} setEvent={setEvent}/>
                            <Recurrent event={event} setEvent={setEvent}/>
                            <Reminders event={event} setEvent={setEvent}/>
                            {
                                event && !event.is_holiday ?
                                    <LocationAutoComplete
                                        locationIntl={locationIntl}
                                        setLocation={(location) => setEvent({...event, location})}
                                        location={event.location}
                                    />
                                    :
                                    null
                            }
                            {
                                event?.conferenceData?.conferenceSolution?.key?.type === 'hangoutsMeet' ?
                                    <HangoutsMeet classes={classes} event={event} setEvent={setEvent}
                                                  onUpdate={onUpdate}/>
                                    :
                                    <ZoomButton classes={classes} event={event} setEvent={setEvent}
                                                onUpdate={onUpdate} linkZoom={linkZoom}/>
                            }
                            <Memo event={event} setEvent={setEvent}/>
                            <GuestEmail event={event} setEvent={setEvent} oldEvent={oldEvent}/>
                            {
                                event?.message &&
                                <div className={`${classes.element} ${classes.messBox}`}>
                                    <MessageOutlined className={classes.iconMess}/>
                                    <pre className={classes.contentMess}>{event?.message}</pre>
                                </div>
                            }

                            <UrlProject
                                event={event}
                            />

                            <ProjectList
                                event={event}
                                setEvent={setEvent}
                                onClose={handleClose}
                            />
                            <div className={classes.element}>
                                <IdentityList
                                    event={event}
                                    setEvent={setEvent}
                                />
                            </div>
                            <TagList
                                event={event}
                                setEvent={setEvent}
                            />
                            <SaveButton
                                saveClick={saveClick}
                                copyTime={onCopy}
                                zoomUrl={zoomUrl}
                                disable={!(event && event.title) || loading}
                                disiableZoom={!event || moment(event.end).isBefore(moment()) || event.isMileStone || loadZoom || (!zoomOAuth?.zoomOAuth && !linkZoom)}
                                disableHoliday={event.is_holiday}
                            />
                            {openDelete &&
                                <DeleteEvent
                                    event={oldEvent}
                                    setOpen={setOpenDelete}
                                    open={openDelete}
                                    events={events}
                                    setEvents={setEvents}
                                    setEditInfo={setEditInfo}
                                    loading={loading}
                                    setLoading={setLoading}
                                    numberId={numberId}
                                />
                            }
                        </LoadingOverlay>
                    </div>
                }
                <ConflictVoteTime onDone={onConflictVoteTimeDone} open={open} setOpen={setOpen}/>
                <DeleteRecurring eventDelete={deleteRecurrence} setDeleteEvent={setDeleteRecurrence}
                                 onClose={handleClose} numberId={numberId}/>
            </ContainerEdit>
        </div>
    )
}

const styles = theme => ({
    root: {
        minWidth: 700,
        padding: '25px',
        maxWidth: "800px",
        overflow: 'auto',
        maxHeight: "100vh",
        background: "#FFFFFF",
        borderRadius: 18,
        zIndex: "888 !important",
        [theme.breakpoints.down('md')]: {
            maxWidth: 530
        },
        [theme.breakpoints.down('xs')]: {
            width: 340,
            minWidth: "auto"
        },
        "&& img": {
            maxWidth: 'none'
        }
    },
    element: {
        // paddingTop: '10px'
    },
    linkMetamors: {
        color: 'red',
        '&:hover': {
            color: 'red',
            opacity: 0.8
        }
    },
    closeIcon: {
        padding: 8,
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        backgroundColor: "#fff",
        color: "#970505",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
        "&:hover": {
            backgroundColor: "#fff",
        }
    },
    avatar: {
        width: 40,
        height: 40,
        border: "2px solid #339933",
        margin: '2px'

    },
    disable: {
        opacity: 0.6,
        cursor: 'not-allowed !important',
        pointerEvents: "none",
    },
    avatar1: {
        width: 40,
        height: 40,
        border: "2px solid #666",
        margin: '2px'
    },
    clear: {
        clear: "both"
    },
    btnContainer: {
        display: 'flex', margin: 'auto', paddingBottom: '20px'
    },
    btnSave: {
        fontWeight: "bold",
        margin: "10px 0",
        border: "none",
        background: `${theme.props.primaryColor} !important`,
        color: '#FFF !important',
        '&:hover': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            border: "none",
            color: '#FFF'
        },
        '&:focus': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            border: "none",
            color: '#FFF'
        }
    },

    btnDelete: {
        marginLeft: 'auto'
    },
    btnEmail: {
        marginRight: 'auto',
        padding: 5,
    },

    doneRoundIconYellow: {
        color: 'rgb(248, 147, 32)'
    },
    doneRoundIconGreen: {
        color: 'rgb(51, 153, 51)'
    },
    mr10: {
        marginRight: 10
    },
    mr5: {
        marginRight: 5
    },
    leftFlex: {
        flex: "left"
    },
    meetingOne: {
        color: "red",
        margin: "10px auto",
        textAlign: "center"
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#0e72ed',
        textTransform: 'none',
        fontSize: '12px',
        minWidth: 130,
        '&:hover': {
            backgroundColor: '#0e72ed',
            opacity: 0.7,
        },
    },
    zoomMeeting: {
        display: "flex",
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10,
    },
    iconZoom: {
        fontSize: 18,
        marginRight: 5,
        padding: 5,
    },
    copyField: {
        width: "100%",
        margin: "10px 0px",
        // "& ."
        "&& .MuiOutlinedInput- root": {
            borderRadius: 0
        },
        "&& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "&& .MuiOutlinedInput-adornedEnd": {
            height: "40px",
            padding: 0
        },
        "&& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid black'
        },
        "& .MuiInputAdornment-positionStart": {
            margin: 0
        }
    },
    zoomJoin: {
        marginRight: 10,
        cursor: 'pointer'
    },

    messBox: {
        display: "flex",
        flexDirection: "row"
    },
    iconMess: {
        margin: "4px 7px 0 0px",
        padding: 5,
        fontSize: 18,
    },
    contentMess: {
        backgroundColor: "rgb(230, 230, 230)",
        width: "100%",
        padding: 10,
    },
    popover: {
        "& .MuiPopover-paper": {
            overflow: "inherit"
        },
        "& .MuiDialog-paper": {
            overflow: "inherit"
        }
    },

    alignRight: {
        textAlign: "right",
    },
    iconSVG: {
        fill: theme.props.primaryColor,
        marginLeft: 7,
        width: 32,
        height: 32,
        cursor: "pointer",
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    titleRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 5,
    }
});
export default withStyles(styles)(EditEvent)

