import {db} from '../../config/firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import moment from 'moment';

export const deleteRecurringEvent = async (user, event, nextUntil) => {
    const batch = db.batch();

    const eventRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${event.recurringEventId}`)
    const editedSnap = await db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
        .where("recurringEventId", "==", event.recurringEventId)
        .get()

    batch.set(eventRef, {
        recurrence: event.recurrence
    }, {merge: true})

    if (editedSnap.size) {
        editedSnap.docs.forEach(doc => {
            const id = doc.id
            const timeDate = id?.split("_")?.pop()
            // console.log(timeDate, moment(timeDate).format(), (moment(timeDate).isAfter(nextUntil)))
            if (!timeDate)
                return
            if (moment(timeDate).isAfter(nextUntil)) {
                // delete
                const ref = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${doc.id}`)
                batch.delete(ref)
            }
        })
    }
    await batch.commit()
}
