import React from 'react';
import styled from 'styled-components';
import {tutorials} from '../common/tutorials';
import {Menu} from 'antd';
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as DropDownSvg} from '../../asSvg/icons/dropDown.svg';
import {remove} from 'lodash';

const {SubMenu} = Menu;

const DivList = styled.div`
    //font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
    font-size: 1.2rem;
`

const DivSubItem = styled(Menu.Item)`
    margin-bottom: 0px !important;
    cursor: pointer;
"& .ant-menu-submenu-title": {
    border-bottom: 1px solid #f0f0f0;
}

    &:hover {
        color: blue;
    }
`


const Root = styled.div`
    display: flex;
    flex-direction: column;
`


function ListCustom({onClickItem}) {
    const classes = useStyles()
// submenu keys of first level
    const [openKeys, setOpenKeys] = React.useState([]);
    const onOpenChange = (id) => {
        // console.log(id);
        if (openKeys.includes(id)) {
            remove(openKeys, i => i === id)
            setOpenKeys([...openKeys])
            return
        }

        openKeys.push(id)
        setOpenKeys([...openKeys])

    };
    return (
        <DivList>
            {
                tutorials.map((tutorial, index) => {
                    return (
                        <Root>
                            <Menu
                                mode="inline"
                                onOpenChange={() => onClickItem(tutorial.id, 0)}
                                openKeys={openKeys}
                                className={classes.icon}
                                // style={{ width: 342}}
                                expandIcon={
                                    tutorial?.subs?.length > 0 ?
                                        <DropDownSvg onClick={(event) => onOpenChange(tutorial.id)}/>
                                        :
                                        null
                                }
                            >
                                <SubMenu
                                    key={tutorial.id} title={`${index + 1}. ${tutorial.title}`} className={classes.sub}>
                                    {
                                        tutorial?.subs?.length ? tutorial.subs.map((value, idx) => {
                                                return (
                                                    <DivSubItem
                                                        key={value.id + idx}
                                                        onClick={(event) => {
                                                            if (value.ignoreIndex) {
                                                                onClickItem(value.id, 0)
                                                                return
                                                            }
                                                            onClickItem(tutorial.id, value.index || idx + 1)
                                                        }}
                                                    >
                                                        {index + 1}.{idx + 1}. {value.title}
                                                    </DivSubItem>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </SubMenu>
                            </Menu>
                        </Root>

                    )
                })
            }
        </DivList>
    );
}

export default ListCustom;
const useStyles = makeStyles(theme => ({
    icon: {
        "& .ant-menu-submenu-title": {
            borderBottom: "1px solid #f0f0f0"
        },
        '& .ant-menu-submenu-arrow': {
            display: 'none'

        }
    },
    sub: {
        padding: "0 !important",
        '& .ant-menu-title-content': {
            overflow: 'unset',
            textOverflow: 'unset',
            whiteSpace: 'pre-wrap',
            lineHeight: '20px',
            margin: '0',
            //borderBottom: "1px solid #f0f0f0"
        },
        '& .ant-menu-submenu-expand-icon': {
            '&:hover': {
                right: 6,
                width: "30px",
                height: "30px",
                padding: "10px",
                background: "#f1f1f1",
                borderRadius: "50%",
                '& path': {
                    fill: '#1790FF',

                }
            }
        }
    },
    iconButton: {
        '& .MuiIconButton-label': {
            width: "0 !important"
        }
    }
}))
