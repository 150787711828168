import {proxy, snapshot} from 'valtio/vanilla';
import qs from 'query-string';
import {themesProxy} from './themes.proxy';
import isEqual from 'react-fast-compare'
import store from 'store'
import {getDataAsync} from '../../common/firebaseRef/meetings';
import {myCal} from '../../common/myCal';
import {mergeProxy} from './common/mergeProxy';


const userProxy = proxy({
    user: store.get("user") || {},
    calendarUser: {},
    focusText: [],
    setFocusText: async (data) => {
        if (data) {
            userProxy.focusText = data;
        } else {
            const dataUser = await getDataAsync(myCal());
            userProxy.focusText = dataUser.focusText;
        }
    },
    setCalendarUser: (data) => {
        mergeProxy(userProxy.calendarUser, data)
        const {idTheme} = qs.parse(window.location.search)
        if (!idTheme) {
            const {setTheme} = snapshot(themesProxy)
            setTheme(data?.themeCustom)
        }
        // userProxy.calendarUser = data
        if (!isEqual(userProxy.googleAuth, data?.googleAuth))
            userProxy.googleAuth = data?.googleAuth || null
    },
    setUser: (data) => {
        userProxy.user = data
        store.set("user", data)
    },
    googleAuth: store.get("googleAuth") || null,
    setGoogleAuth: (data) => {
        if (isEqual(data, userProxy.googleAuth))
            return;
        userProxy.googleAuth = data
        store.set("googleAuth", data)
    }
});


export {
    userProxy
}
