import moment from 'moment';
import {snapshot} from 'valtio';
import {userProxy} from '../store/proxy/user.proxy';

const getTarget = (ago, later, type = 'day') => {
    const {user} = snapshot(userProxy)
    let list = []
    switch (type) {
        case 'day':
            for (let i = -ago; i <= later; i++) {
                list.push(moment().add(i, "days").locale(user.language).format('YYYY-MM-DD (dd)'))
            }
            break;
        case 'week':
            for (let i = -ago; i <= later; i++) {
                list.push(`${moment().add(i, "weeks").format('YYYY')}-W${moment().add(i, "weeks").isoWeeks()}`)
            }
            break;
        default:
            for (let i = -ago; i <= later; i++) {
                list.push(moment().add(i, "months").format('YYYY-MM'))
            }
    }
    return list
}
export default getTarget
