import React, {useState} from 'react';
import {useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/core/styles';
import {handleConnectZoomOAuth} from '../../actions/zoom';
import Zooms from './Zooms/zooms';
import {ReactComponent as EmailSVG} from '../../asSvg/iconEmail.svg';
import {ReactComponent as SignOutSvg} from '../../asSvg/signOut.svg';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import axios from 'axios';
import {API_ZOOM} from '../../config/constants';
import {Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import useIntlId from '../../hooks/useIntlId';
import CancelButton from "../Dialog/CancelButton";
import OkButton from "../Dialog/OkButton";

const useStyles = makeStyles(theme => ({
    divRoot: {
        width: '100%',
        padding: '20px'
    },
    textTitle: {
        color: '#000',
        fontWeight: "bold",
        fontSize: '1.2rem',
        textAlign: 'center'
    },
    infoEmail: {
        padding: '20px 60px 0px 60px',
        textAlign: 'center',
        // display: 'flex',
        // justifyContent: 'center'
    },
    infoEmail2: {
        padding: '20px 60px 0px 60px',
        display: 'flex',
        justifyContent: 'center'
    },
    textInfo: {
        minHeight: 35,
        fontSize: '1rem',
        marginRight: 20,
        background: '#fff',
        borderRadius: 20,
        padding: '3px 20px'
    },
    titlePrimary: {
        fontWeight: 'bold',
        marginRight: 10
    },
    imgSvg: {
        width: 27,
        padding: 0,
        fill: theme.props.primaryColor,
        marginLeft: -12,
        marginRight: 7,
        cursor: 'pointer',
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        color: "#fff",
        fontWeight: 700,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        color: "#fff",
        fontWeight: 700,
        "& svg": {
            fill: theme.props.secondaryColor,
            maxWidth: "25px !important"
        }
    },
    noData: {
        textAlign: 'center',
        margin: 'auto',
        fontSize: 20,
        // fontWeight: 'bold',
        width: '100%',
        padding: '10px',
        background: '#FFF',
        borderRadius: 14,
        marginTop: 40
    },
    divTextAlign: {
        textAlign: 'center',
        margin: 'auto',
    }
}));

function VideoConferencing(props) {
    const classes = useStyles();
    const [zoomOAuth] = useGlobal('zoomOAuth');
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [videoConfIntl, unconnIntl, connIntl, zoomNotConnectIntl] = useIntlId(['app.profile.videoconferencing', 'setting.zoom.unconnect', 'app.notification.connectToZoom', 'cal.zoomNotConnect'])

    const handleRevokeZoomOAuth = async () => {
        try {
            setLoading(true)
            await axios.post(`${API_ZOOM}/removeZoomOAuth`)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setOpen(false)
        }
        // window.open('https://marketplace.zoom.us/user/installed')
    };

    return (
        <div className={`${classes.divRoot}`}>
            <div className={classes.textTitle}>{videoConfIntl}</div>
            {
                Boolean(zoomOAuth?.zoomOAuth) ?
                    <>
                        <div className={classes.infoEmail}>
                            <div className={`${classes.textTitle} ${classes.textInfo} clf-flex-center`}>
                                <span
                                    className={classes.titlePrimary}>{zoomOAuth?.zoomUserData ? `${zoomOAuth?.zoomUserData.first_name} ${zoomOAuth?.zoomUserData.last_name} ` : ''}</span>
                                <span> ({zoomOAuth?.zoomUserData ? zoomOAuth?.zoomUserData.email : zoomOAuth.email})</span>
                            </div>
                            <CLFButtonSVG
                                loading={loading}
                                iconComponent={<SignOutSvg/>}
                                size={'default'}
                                onClick={() => setOpen(true)}
                                name={unconnIntl}
                                margin={'12px auto'}
                                className={classes.primary}
                                width={'200px'}
                                minHeight={"31px"}
                            />
                        </div>
                        <Zooms/>
                    </>
                    :
                    <div className={classes.infoEmail2}>
                        <CLFButtonSVG
                            iconComponent={<EmailSVG/>}
                            size={'default'}
                            onClick={handleConnectZoomOAuth}
                            name={connIntl}
                            className={classes.primary}
                            minWidth={'200px'}
                            minHeight={"31px"}
                        />
                    </div>
            }

            {
                !zoomOAuth?.zoomOAuth &&
                <div className={classes.noData}>{zoomNotConnectIntl}</div>
            }


            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogContent>
                    <Typography className={classes.divTextAlign}>{unconnIntl}</Typography>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setOpen(false)}/>
                    <OkButton
                        disabled={loading}
                        onClick={handleRevokeZoomOAuth}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default VideoConferencing;
