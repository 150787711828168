import {getGlobal, setGlobal} from "reactn";
import {findIndex} from "lodash";
import {refetchSource} from "../../../actions/refetchSourcce";

export default function setGlobalGGEventsWithDataAndInfo(data, newInfo) {
    const {ggEvents} = getGlobal()
    const events = ggEvents[newInfo.project_uuid]?.events || []
    let idx = findIndex(events, {id: newInfo.id})
    if (idx !== -1) {
        events[idx] = {...data, project_uuid: newInfo.project_uuid}
        ggEvents[newInfo.project_uuid].events = events
        setGlobal({
            ggEvents: {...ggEvents}
        }, () => {
            refetchSource('google')
        })
    }
}
