import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import '../index.css';
import '../global.css';
import 'antd/dist/antd.css';
import "@Andom-Geniam/git-globalnavi/dist/index.css"
import qs from 'query-string';
import {snapshot, useSnapshot} from 'valtio';
import {themesProxy} from '../store/proxy/themes.proxy';
import {userProxy} from '../store/proxy/user.proxy';
import {cloneDeep} from 'lodash';

export const CustomThemeProviderContext = React.createContext({});

export const CustomThemeProvider = ({children}) => {
    const theme = useThemeContext();

    if (!theme)
        return null;

    return (
        <CustomThemeProviderContext.Provider value={theme}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </CustomThemeProviderContext.Provider>
    )
}

export const useThemeContext = () => {
    const {currentTheme, colors, themeCustom, setView, setFontSize, setCalendarTab1} = useSnapshot(themesProxy)
    const colorPrimary = currentTheme?.colorPrimary || "#1790FF"
    const [theme, setTheme] = useState(createTheme({
        props: {
            primaryColor: "#1790FF",
            secondaryColor: "#690003",
            // or add more props to handle theme
            MuiButtonBase: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        transitions: {
            // So we have `transition: none;` everywhere
            create: () => 'none !important',
        }
    }))
    const {viewCalendar} = qs.parse(window.location.search);

    useEffect(() => {
        if (colorPrimary && theme?.props?.primaryColor !== colorPrimary) {
            setTheme(createTheme({
                props: {
                    primaryColor: colorPrimary,
                    secondaryColor: "#690003",
                    // or add more props to handle theme
                    MuiButtonBase: {
                        disableRipple: true, // No more ripple, on the whole application 💣!
                    },
                },
                transitions: {
                    // So we have `transition: none;` everywhere
                    create: () => 'none !important',
                }
            }))
        }
    }, [colorPrimary])

    useEffect(() => {
        const newThemeCustom = cloneDeep(themeCustom);
        const tCustom = newThemeCustom || {}

        if (tCustom.size) {
            setFontSize(tCustom.size || 9)
        }
        if (newThemeCustom?.columns && newThemeCustom?.rows) {
            if (newThemeCustom?.viewCalendar === "CustomDay") {
                setCalendarTab1(newThemeCustom?.rows)
            } else {
                setCalendarTab1(newThemeCustom?.columns * newThemeCustom?.rows)
            }
        }
        if (viewCalendar === 'month')
            return setView('CustomMonth')

        if (viewCalendar === 'week')
            return setView('CustomWeek')

        if (viewCalendar === 'day')
            return setView('CustomDay')

        if (viewCalendar === 'year')
            return setView('CustomYear')

        if (tCustom.viewCalendar) {
            setView(tCustom?.viewCalendar)

        }
        // eslint-disable-next-line
    }, [colors, viewCalendar]);

    return theme
}
export const handleRevertColors = () => {
    const {calendarUser} = snapshot(userProxy);
    const {setColors} = snapshot(themesProxy);
    let {colors = {}} = calendarUser
    if (colors?.primary) {
        setColors(colors);
    } else {
        setColors({primary: '#1790FF', secondary: '#0029FF'});
    }
}
