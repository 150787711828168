import React, {memo} from 'react';
import Paper from '@material-ui/core/es/Paper';
import moment from 'moment';
import useDate from '../../hooks/useDate';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        root: {
            position: "relative",
            paddingTop: "111.77%",
            marginLeft: 10,
            marginRight: 10,
            marginTop: 5,
        },
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: `${theme.props.primaryColor}`,
            borderRadius: "20px",
            fontSize: "20px",
            lineHeight: 1.1,
            fontWeight: "bold",
            textTransform: "uppercase",
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.825rem',
            },
        },
        day: {
            fontSize: "100px",
            paddingTop: 5,
            [theme.breakpoints.down('sm')]: {
                fontSize: '8.375rem',
            },
        }
    })
)


function CalendarSmall() {
    const classes = useStyles()
    const [date] = useDate()
    return (
        <Paper elevation={0} className={classes.root} id={'tutorial-left-calendar'}>
            <div className={`${classes.container}`}>
                <div> {moment(date).format('dddd')}</div>
                <div className={`${classes.day} fontBebasNeue`}>{moment(date).format('DD')}</div>
                <div>{moment(date).format("MMMM, YYYY")}</div>
            </div>
        </Paper>
    )
}

export default memo(CalendarSmall)
