import moment from 'moment';

export const getNumDayAdvanceView = (item) => {
    let num = 0
    if (item?.advanceView) {
        if (item?.settingToday) {
            num = item?.numsDay * item?.rows || 1
        } else {
            let rangeDay = (item?.rangeDay && item?.rangeDay[0] !== '' && item?.rangeDay[1] !== '') ? item?.rangeDay : []
            let start = moment(rangeDay[0]) || moment().startOf("day").format('YYYY-MM-DD')
            let end = moment(rangeDay[1]) || moment().add(7, 'days').endOf("day").format('YYYY-MM-DD')
            num = (end?.diff(start, 'days') + 1) * item?.rows
        }
    }
    return num
}
