import React, {useEffect, useState, useTransition} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useGlobal} from 'reactn';
import useIntlId from '../../hooks/useIntlId';
import {Modal} from 'antd';
import {Grid, ListItem} from '@material-ui/core';
import {cloneDeep, findIndex} from 'lodash';
import {ItemCheckedCustom} from 'item-checked-custom';
import {GCalendarList, updateListGgCalenviewShow} from '../../actions/googleCalendar';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import {ReactComponent as SaveSvg} from '../../asSvg/copy.svg';
import {snapshot, useSnapshot} from 'valtio';
import {projectsProxy} from '../../store/proxy/projects.proxy';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import {futureEventsProxy} from '../../store/proxy/futureEvents.proxy';

function DialogSelectCalendar(props) {
    const {open, setOpen, numOfCal} = props
    const {setGoogleProjects} = snapshot(projectsProxy)
    const classes = useStyles()
    const [titleDialog, noticeOffDialogCalendar, ok] = useIntlId(['calenview. titleDialog', 'calenview.noticeOffDialogCalendar', 'settings.ok'])
    const [googleCalendarList] = useGlobal('googleCalendarList');
    const [listSelectCal, setListSelectCal] = useState([]);
    const [btn, setBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnSubmit, setBtnSubmit] = useState(false);
    const {google} = useSnapshot(futureEventsProxy)

    const handleChange = async (source) => {
        let arrSelectList = [...listSelectCal]
        let index = findIndex(arrSelectList, g => g.id === source.id);
        if (index !== -1) {
            let selected = !Boolean(arrSelectList[index].check)
            arrSelectList[index].check = selected;
            if (selected) {
                arrSelectList[index].check = true
            } else {
                delete arrSelectList[index].check
            }
            setListSelectCal([...arrSelectList])
            setCheckList()
        }
    };

    const updateList = async () => {
        setLoading(true)
        let _listSelectCal = cloneDeep(listSelectCal)
        let arr = []
        let i = 0
        if (_listSelectCal?.length) {
            await _listSelectCal.forEach((item) => {
                if (item.check) {
                    i++
                    if (i <= numOfCal) {
                        arr.push({
                            id: item.id,
                            check: true
                        })
                    }
                }
            })
        }
        await updateListGgCalenviewShow(arr)
        GCalendarList()
        setGoogleProjects(_listSelectCal)
        setLoading(false)
        setOpen(false)
    };

    const setCheckList = () => {
        let i = 0
        if (listSelectCal?.length) {
            listSelectCal.forEach((item) => {
                if (item.check) {
                    i++
                }
            })
        }
        if (i >= numOfCal || i === listSelectCal?.length) {
            setBtn(true)
        } else {
            setBtn(false)
        }
        if (i >= 1) {
            setBtnSubmit(true)
        } else {
            setBtnSubmit(false)
        }
    }

    const sortGoogleCalendar = () => {
        let _listSelectCal = cloneDeep(listSelectCal)
        const selected = _listSelectCal?.filter(g => g.check).sort((a, b) => google[b.id] - google[a.id])
        const nonSelect = _listSelectCal?.filter(g => !g.check).sort((a, b) => google[b.id] - google[a.id])
        return [...selected, ...nonSelect]
    };

    useEffect(() => {
        setListSelectCal(cloneDeep(googleCalendarList))
        setCheckList()
        setBtnSubmit(true)
        // eslint-disable-next-line
    }, [googleCalendarList]);

    return (
        <Modal
            visible={Boolean(open)}
            title={titleDialog ? titleDialog.replace('**', numOfCal) : ''}
            onCancel={() => {
                alert(noticeOffDialogCalendar)
            }}
            bodyStyle={{overflowY: 'auto'}}
            zIndex={9000}
            footer={<ListItem className={`clf-flex-center`}>
                <CLFButtonSVG
                    onClick={updateList}
                    iconComponent={<SaveSvg/>}
                    name={ok}
                    size={'default'}
                    className={classes.primary}
                    loading={loading}
                    minHeight={"31px"}
                    disable={!btnSubmit}
                />
            </ListItem>}
        >
            <LoadingOverlay>
                <Loader loading={!listSelectCal?.length} text={'Loading...'}/>
                <Grid className={classes.checkbox}>
                    {sortGoogleCalendar().map((source, index) => {
                        return (
                            <ListItem disableGutters={true} className={classes.item} key={index}>
                                <ItemCheckedCustom
                                    id={source.id}
                                    name={source.summary}
                                    color={btn && !source.check ? '#ccc' : source.backgroundColor}
                                    checked={Boolean(source.check)}
                                    onClick={() => btn && !source.check ? '' : handleChange(source)}
                                    disabled={btn && !source.check}
                                    height={'33px'}
                                    margin={'unset'}
                                    isTextColor={true}
                                    isRevert={true}
                                    hiddenAvatar={true}
                                    isBold={true}
                                    className={"ItemCheckedCustom"}
                                />
                            </ListItem>
                        )
                    })}
                </Grid>
            </LoadingOverlay>
        </Modal>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "unset"
        },
    },
    checkbox: {
        height: '60vh',
        "& .MuiListItem-root": {
            "& div": {
                maxWidth: "unset"
            }
        },
    },
    primary: {
        margin: 0,
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        }
    },
}))

export default DialogSelectCalendar;
