import React from 'react';
import IconIdentity from "../../Calendar/google/identityIcon";
import {Modal} from "antd";
import CancelButton from "../../Dialog/CancelButton";
import ArchiveButton from "../../Dialog/ArchiveButton";

function ArchivedIdentityDialog({identity, setIdentity, onSave, loading, classes}) {
    // const [archiveConfirmIntl, archiveIntl] = useIntlId(['app.notification.doYouWantArchive', 'app.setting.archive'])
    return (
        <Modal
            visible={Boolean(identity)}
            title={identity.name}
            onCancel={() => setIdentity(null)}
            zIndex={9999}
            footer={<div className={'clf-flex-center'}>
                <CancelButton
                    onClick={() => setIdentity(null)}
                    disable={loading}
                />
                <ArchiveButton
                    loading={loading}
                    onClick={() => onSave({...identity, isArchive: true})}
                    disable={loading}
                />
            </div>}
        >
            <IconIdentity color={identity?.color || null}/>
        </Modal>
    );
}

export default ArchivedIdentityDialog;
