import React, {useEffect, useState} from 'react';
import DialogCustom from '../../Custom/DialogCustom';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Col, Row, Switch} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import NumberComponent from '../Views/NumberComponent';
import Theme from './Theme';
import ListTheme from './ListTheme';
import {ReactComponent as EditWorkSpace} from "../../../asSvg/icons/editWorkSpace.svg";
import {db} from "../../../config/firebaseConfig";
import {isEmpty, isUndefined, omitBy} from 'lodash';
import getClassColor from '../../../common/getClassColor'
import HandleChangeView from './HandleChangeView';
import SettingTimeView from './SettingTimeView';
import PreviewCalendar from './PreviewCalendar';
import moment from 'moment';
import {myCalendarRef} from '../../../common/firebaseRef/calendar';
import TabDate from './TabDate';
import isEqual from 'react-fast-compare'
import {useSnapshot} from 'valtio';
import {announcementProxy} from '../../../store/proxy/announcement.proxy';

function MyWorkSpace({itemTheme, setItemTheme, onClose}) {
    const classes = useStyles()
    const [saveButtonIntl, useSettingIntl, titleIntl, monthWeekDay, scheduleBeforeAfter, rangeDayTab, modeTitle, changeSize] = useIntlId([
        'edit.workspace.save',
        'edit.workspace.useSetting',
        'event.express.title',
        'settingView.monthWeekDay',
        'settingView.scheduleBeforeAfter',
        'setting.advanceView.rangeDay',
        'calenview.viewSetting.mode',
        'home.fontsize'
    ])
    const [viewLeft, viewRight] = useIntlId(['setting.showMonthCalendar', 'setting.showtimeGraph'])
    const [tab, setTab] = useState(0)
    const {lock} = useSnapshot(announcementProxy)
    const [loading, setLoading] = useState(false)
    const [editData, setEditData] = useState(null)

    useEffect(() => {
        if (!itemTheme) {
            setTab(0)
            return;
        }
        setEditData({...itemTheme})
        if (itemTheme?.advanceView && !itemTheme?.settingToday && itemTheme?.viewCalendar === "CustomDay") {
            setTab(2)
            return;
        }
        if (itemTheme?.advanceView && itemTheme?.settingToday && itemTheme?.viewCalendar === "CustomDay") {
            setTab(1)
            return;
        }
        setTab(0)
    }, [itemTheme])

    const onChangeCalendarLeftView = (checked) => {
        setEditData({...editData, calendarLeftView: checked})
    }
    const onChangeRightExpansion = (checked) => {
        setEditData({...editData, rightExpansion: checked})
    }
    const onChangeShowTitle = (checked) => {
        setEditData({...editData, showTitle: checked})

    }

    const handleChangeView = (event, newValue) => {
        if (newValue === editData.viewCalendar)
            return
        setEditData({...editData, viewCalendar: newValue})
    };
    const handleChangeFontSize = (type) => {
        let dataCheck = type && type === "add" ? Number(editData.size) + 1 : type && type === "remove" ? Number(editData.size) - 1 : Number(editData.size);
        if (dataCheck < 5) {
            return
        }
        if (dataCheck > 15) {
            return
        }
        setEditData({...editData, size: dataCheck})
    }
    const onSave = async (useOnly = false) => {
        try {
            setLoading(true)
            const batch = db.batch()
            const userRef = myCalendarRef()
            const data = omitBy(editData, isUndefined)
            batch.set(userRef, {
                themeCustom: {
                    ...data,
                    id: useOnly ? null : (data?.id || null)
                }
            }, {merge: true})
            if (!useOnly && data?.id) {
                const themeRef = userRef.collection("listTheme")
                    .doc(`${data.id}`)
                batch.set(themeRef, data, {merge: true})
            }
            await batch.commit()
        } catch (e) {
            console.log(e);
        } finally {
            onClose()
            setLoading(false)

        }
    }
    if (isEmpty(editData))
        return null
    return (
        <DialogCustom className={classes.dialog} open={!isEmpty(editData)} onClose={onClose}>

            <div className={classes.root}>
                <div
                    className={`text-${getClassColor(editData?.colorPrimary)} uppercase font-bold text-3xl text-center mb-3`}>{modeTitle}</div>
                <div className={`bg-[#fff]rounded-lg flex text-xl mb-5`}>
                    <button
                        className={`border-0 ${tab === 0 ? `text-${getClassColor(editData?.colorPrimary)}` : ''} bg-[#fff] w-1/3  p-4 font-bold rounded-sm cursor-pointer relative`}
                        onClick={() => {
                            setTab(0)
                            setEditData({
                                ...editData,
                                advanceView: false,
                                settingToday: false,
                                viewCalendar: itemTheme?.viewCalendar || "CustomMonth",
                                rows: itemTheme.tab === 0 ? itemTheme.rows : 1,
                                columns: itemTheme.tab === 0 ? itemTheme.columns : 1,
                            })
                        }}
                    >{monthWeekDay}
                        {tab === 0 && <span
                            style={{backgroundColor: editData?.colorPrimary}}
                            className={`absolute inset-x-0 h-1 bg-${getClassColor(editData?.colorPrimary)} bottom-0 rounded-md`}/>}
                    </button>
                    <button
                        className={`border-0 ${tab === 1 ? `text-${getClassColor(editData?.colorPrimary)}  ` : ''} bg-[#fff] w-1/3  p-4 font-bold rounded-sm relative  ${lock ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={() => {
                            setTab(1)
                            setEditData({
                                ...editData,
                                advanceView: true,
                                settingToday: true,
                                viewCalendar: "CustomDay",
                                rows: itemTheme.tab === 1 ? itemTheme.rows : 1,
                                columns: itemTheme.tab === 1 ? itemTheme.columns : 1,
                                numsDay: itemTheme.tab === 1 ? itemTheme.numsDay : 1,
                                indexToday: itemTheme.tab === 1 ? itemTheme.indexToday : 1,
                            })
                        }}
                        disabled={lock}
                    >{scheduleBeforeAfter}
                        {lock && <img className={"absolute right-4"} src="/icons/iconKey.svg" alt=""/>}
                        {tab === 1 && <span
                            style={{backgroundColor: editData?.colorPrimary}}
                            className={`absolute inset-x-0 h-1 bg-${getClassColor(editData?.colorPrimary)} bottom-0 rounded-md`}/>}
                    </button>
                    <button
                        className={`border-0 ${tab === 2 ? `text-${getClassColor(editData?.colorPrimary)}  ` : ''} bg-[#fff] w-1/3  p-4 font-bold rounded-sm relative  ${lock ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={() => {
                            setTab(2)
                            setEditData({
                                ...editData,
                                advanceView: true,
                                settingToday: false,
                                viewCalendar: "CustomDay",
                                rows: itemTheme.tab === 2 ? itemTheme.rows : 1,
                                columns: itemTheme.tab === 2 ? itemTheme.columns : 1,
                                rangeDay: itemTheme.rangeDay ? itemTheme.rangeDay : [moment().format('YYYY-MM-DD'), moment().add(7, "days").format("YYYY-MM-DD")]
                            })
                        }}
                        disabled={lock}
                    >{rangeDayTab}
                        {lock && <img className={"absolute right-4"} src="/icons/iconKey.svg" alt=""/>}
                        {tab === 2 && <span
                            style={{backgroundColor: editData?.colorPrimary}}
                            className={`absolute inset-x-0 h-1 bg-${getClassColor(editData?.colorPrimary)} bottom-0 rounded-md`}/>}
                    </button>
                </div>
                <div className={classes.boxBackgroundGray}>
                    <Row gutter={[8, 10]}>
                        <Col span={12}>
                            {
                                tab === 0 ?
                                    <div>
                                        <TabDate
                                            themeItem={editData}
                                        />
                                        <HandleChangeView
                                            viewCalendar={editData.viewCalendar}
                                            handleChangeView={handleChangeView}
                                            itemTheme={editData}
                                        />
                                        <div className={'p-1 mt-5 bg-[#fff] rounded'}>
                                            <PreviewCalendar
                                                advanceView={editData.advanceView}
                                                theme={editData}
                                                setTheme={setEditData}
                                                rangeDay={editData.rangeDay}
                                            />

                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <SettingTimeView
                                            theme={editData}
                                            setTheme={setEditData}
                                        />
                                        <div className={'p-1 mt-0.4 bg-[#fff] rounded'}>
                                            <PreviewCalendar
                                                theme={editData}
                                                setTheme={setEditData}
                                                rangeDay={editData.rangeDay}
                                            />

                                        </div>
                                    </div>
                            }

                        </Col>
                        <Col span={12}>
                            <div className={classes.backgroundWhite}>

                                <div className={classes.switchContainer}>
                                    <div>
                                        {changeSize}
                                    </div>
                                    <NumberComponent
                                        value={editData.size}
                                        setValue={(size) => {
                                            setEditData({...editData, size: size})
                                        }}
                                        handleBlur={handleChangeFontSize}
                                        item={itemTheme}
                                    />
                                </div>
                                <div className={classes.switchContainer}>
                                    <div>
                                        {titleIntl}
                                    </div>
                                    <Switch
                                        defaultChecked={editData.showTitle}
                                        onChange={onChangeShowTitle}
                                        // onClick={onHandleClickTitle}
                                        className={classes.switch}/>
                                </div>
                                <div className={classes.switchContainer}>
                                    <div>
                                        {viewLeft}
                                    </div>
                                    <Switch
                                        defaultChecked={editData.calendarLeftView}
                                        onChange={onChangeCalendarLeftView}
                                        // onClick={onHandleClickLeftView}
                                    />
                                </div>
                                <div className={classes.switchViewRight}>
                                    <div>
                                        {viewRight}
                                    </div>
                                    <Switch
                                        defaultChecked={editData.rightExpansion}
                                        onChange={onChangeRightExpansion}
                                    />
                                </div>
                            </div>
                            <Theme
                                theme={editData}
                                setTheme={setEditData}
                            />
                            <div className={classes.btnAction}>
                                <Button
                                    disabled={isEqual(itemTheme, editData)}
                                    loading={loading}
                                    onClick={() => onSave(true)}
                                    type="primary"
                                    style={{backgroundColor: `${editData?.colorPrimary}`, width: "100%"}}
                                    className={classes.btnSaveWorkSpace}>
                                    <EditWorkSpace className={classes.iconSave}
                                                   style={{fill: `${editData?.colorPrimary}`}}/>
                                    {useSettingIntl}
                                </Button>
                            </div>

                        </Col>

                    </Row>
                </div>
                <ListTheme setThemeCustom={setItemTheme} editTheme={editData}/>
                <div className={classes.btnAction}>
                    <Button
                        loading={loading}
                        disabled={isEqual(itemTheme, editData) || !editData?.id}
                        onClick={() => onSave(false)}
                        type="primary"
                        style={{backgroundColor: `${editData?.colorPrimary}`}}
                        className={classes.btnSaveWorkSpace}>
                        <EditWorkSpace className={classes.iconSave} style={{fill: `${editData?.colorPrimary}`}}/>
                        {saveButtonIntl}
                    </Button>
                </div>
            </div>
        </DialogCustom>
    );
}

export default MyWorkSpace;
const useStyles = makeStyles(theme => ({
    flex: {
        display: "flex",
        flexDirection: "row",
        columnGap: 18
        // justifyContent: "space-between",
    },
    switchContainer: {
        maxHeight: 35,
        marginTop: 8,
        display: "flex",
        borderBottom: "1px solid #D8D8D8",
        textTransform: "uppercase",
        alignItems: "center",
        justifyContent: "space-between",
        // margin: "10px 0",
        padding: 10,
        fontWeight: 700,
        // fontSize: 14,
        "& .ant-switch-checked": {
            backgroundColor: `${theme.props.primaryColor} !important`
        }
    },
    switchViewRight: {
        maxHeight: 35,
        marginTop: 8,
        display: "flex",
        textTransform: "uppercase",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 10,
        fontWeight: 700,
        // fontSize: 14,
        "& .ant-switch-checked": {
            backgroundColor: `${theme.props.primaryColor} !important`
        }
    },
    dialog: {
        "& .MuiDialog-paperWidthSm": {
            backgroundColor: "#f1f1f1",
            maxWidth: "95%",
            overflow: "unset"
        }
    },
    root: {
        padding: 10,
        width: 900,
        "& .NumberComponent-number": {
            // borderRadius: "5px",
            // backgroundColor: "#fff",
            // height: "unset",
            // maxWidth: "calc(100% - 30px)",
            // justifyContent: "space-between"
        },
        "& .ant-input-number": {
            backgroundColor: "#fff"
        },
        "& .NumberComponent-root": {
            padding: 0,
            // justifyContent: "space-between",
            // minHeight: "unset"
        },
        "& .NumberComponent-icon": {
            width: "32px",
            height: "32px",
        }
    },
    title: {
        textAlign: "center",
        fontWeight: 700,
        textTransform: "uppercase",
        color: theme.props.primaryColor,
        "& span": {
            display: "inline-block",
            background: "#f1f1f1",
            padding: "5px 20px",
            borderRadius: "7px",
        }
    },
    boxBackgroundGray: {
        backgroundColor: "#f1f1f1",
        padding: 10,
        borderRadius: 10
    },
    boxBackgroundWhite: {
        backgroundColor: "#f1f1f1",
        padding: "3px 5px",
        borderRadius: 10,
        position: "relative"
    },
    backgroundWhite: {
        padding: "3px 5px ",

        borderRadius: 10,
        backgroundColor: "#fff",
    },

    iconSize: {
        margin: "0 10px",
        fill: theme.props.primaryColor,
    },
    btnSaveWorkSpace: {
        position: "relative",
        fontWeight: 700,
        textTransform: "uppercase",
        color: "#fff",
        border: "none",
        outline: "none",
        height: 37,
        borderRadius: "7px",
        backgroundColor: theme.props.primaryColor,
        minWidth: 300,
        '&:hover': {
            border: "none",
            outline: "none",
        },
        '&:focus': {
            border: "none",
            outline: "none",
        },
        "& svg": {
            position: "absolute",
            left: 5,
            top: "50%",
            marginTop: -15
        }
    },
    iconSave: {
        fill: theme.props.primaryColor,
    },
    btnAction: {
        marginTop: 10,
        textAlign: "center"
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'space-between'
    },
    tab: {
        minWidth: "unset",
        width: "33.33%",
        textTransform: "uppercase",
        fontWeight: 700,
        borderRadius: 5,
        fontSize: 14,
        padding: 0,
        minHeight: "unset",
        height: 32,
        color: "#444",
    },
    tabs: {
        backgroundColor: "#fff",
        borderRadius: 7,
        padding: 3,
        margin: "0 3px",
        minHeight: "unset",
        '& .Mui-selected': {
            background: theme.props.primaryColor,
            color: "#fff"
        },
        "& .MuiTabs-scroller > span": {
            display: "none"
        }
    },
    tabDate: {
        minWidth: "unset",
        width: "33.33%",
        textTransform: "uppercase",
        fontWeight: 700,
        borderRadius: 5,
        fontSize: 14,
        padding: 10,
        minHeight: "unset",
        height: 10,
        backgroundColor: "#f1f1f1",
    },
    tabsDate: {
        borderRadius: 7,
        padding: 3,
        margin: "0 3px",
        minHeight: "unset",
        "& .MuiTabs-scroller > span": {
            display: "none"
        },
        marginBottom: 10,
        background: "#fff",
    },

}))
