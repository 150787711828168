import {proxy} from 'valtio/vanilla';
// import {subscribe} from 'valtio';


const CtrlKeyStateProxy = proxy({
    ctrlKeyDown: false,
    setCtrlKeyState: (value) => {
        CtrlKeyStateProxy.ctrlKeyDown = value
    }
})

// subscribe(CtrlKeyStateProxy, () => {
//     console.log(CtrlKeyStateProxy)
// })

export {
    CtrlKeyStateProxy
}
