import {findIndex, isUndefined, orderBy} from 'lodash';


export const formatUserApps = (data, globalApps) => {
    const userGeniamApps = globalApps.map(item => {
        let idx = findIndex(data, {id: item.id})
        if (idx === -1) {
            return {...item, global: true, shortLink: {calenview: false}, favorite: {calenview: false}}
        }
        return {
            ...item, ...data[idx],
            global: !isUndefined(data[idx].global) ? data[idx].global
                : (isUndefined(data[idx].checked) ? true : data[idx].checked),

            shortLink: !isUndefined(data[idx].shortLink) ? data[idx].shortLink
                : {calenview: Boolean(data[idx].calenview)}
            ,

            favorite: !isUndefined(data[idx].favorite) ? data[idx].favorite
                : {calenview: Boolean(data[idx].show)}
            ,
        }
    })
    const otherLinks = data.filter(item => !item.geniamApp)
    const userLinks = otherLinks.map(item => {
        let idx = findIndex(data, {id: item.id})
        if (idx === -1) {
            return {...item, global: false, shortLink: {calenview: false}, favorite: {calenview: false}}
        }
        return {
            ...item,
            global: !isUndefined(data[idx].global) ? data[idx].global
                : (isUndefined(data[idx].checked) ? false : data[idx].checked),

            shortLink: !isUndefined(data[idx].shortLink) ? data[idx].shortLink
                : {calenview: Boolean(data[idx].calenview)}
            ,

            favorite: !isUndefined(data[idx].favorite) ? data[idx].favorite
                : {calenview: Boolean(data[idx].show)}
            ,
        }
    })
    const favoriteGlobalLinks = data.filter(item => item?.geniamApp && item?.favorite?.calenview)
    const favoriteUserLinks = data.filter(item => !item?.geniamApp && item?.favorite?.calenview)

    let orderGeniamApps = orderBy(userGeniamApps, "index", "asc")
    let orderUserLinks = orderBy(userLinks, "index", "asc")
    let oderFavoriteGlobalApps = orderBy(favoriteGlobalLinks, "favoriteGlobalApps.calenviewIdx", "asc")
    let oderFavoriteUserApps = orderBy(favoriteUserLinks, "favoriteUserLinks.calenviewIdx", "asc")
    return {
        userGeniamApps: orderGeniamApps,
        userLinks: orderUserLinks,
        favoriteGlobalLinks: oderFavoriteGlobalApps,
        favoriteUserLinks: oderFavoriteUserApps,
    }
}
