import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from "@material-ui/core/styles";

function TabsCustom(props) {
    const {value, onChange, children} = props
    const classes = useStyles()
    const classProps = props.className || ''
    return (
        <Tabs
            {...props}
            value={value}
            onChange={onChange}
            className={`${classes.tabs} ${classProps}`}
        >
            {children}
        </Tabs>
    );
}

export default TabsCustom;
const useStyles = makeStyles(theme => ({
    tabs: {
        backgroundColor: "#fff",
        minWidth: "unset",
        minHeight: "unset",
        maxWidth: "unset",
        maxHeight: "unset",
        borderRadius: "25px",
        alignItems: "center",
        width: '100%',
        margin: 'auto',
        "& button": {
            minWidth: "80px",
            minHeight: "unset",
            maxWidth: "unset",
            maxHeight: "unset",
            borderRadius: "25px",
            color: "#000",
            fontSize: 14,
            // fontFamily: "Montserrat, sans-serif !important",
        },
        "& > div:nth-child(1) > span": {
            display: "none"
        },
        "& .Mui-selected": {
            background: theme.props.primaryColor,
            color: "#fff",
            fontWeight: "bold"
        }
    },
    tab: {
        border: "none",
        color: "#000"
    }
}))
